import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentUploaded';
import { graphql } from 'react-apollo';

class Uploaded extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}

	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};

		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		filters["uploaded"] = event.target.value;
		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}
	renderUploaded(uploaded, label){
		let doesExist = false;
		let setCount = 0;
		this.props.data.getStudentUploaded.map(({count, uploadedByInstitution}) => {
			if(uploaded === uploadedByInstitution){
				doesExist = true;
				setCount = count;
			}
		})
		if(doesExist){
			return <option value={uploaded}>{label} ({setCount})</option>;
		}
		return <option value={uploaded} disabled>{label} ({setCount})</option>;
	}
	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			this.setState({
				value: filters["uploaded"]
			})
		}
		else{
			this.setState({
				value: ""
			})
		}
	}
	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
      return (
        <select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={-1}>Not selected</option>
					{this.renderUploaded(0, "No")}
					{this.renderUploaded(1, "Yes")}
        </select>
      )
  	}
  }
};

export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			CountryName: props.CountryID,
			InstitutionName: props.InstitutionID,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			AcademicYear: props.AcademicYear,
			StudyCycle: props.StudyCycle,
			SubjectArea: props.SubjectArea,
			TypeOfSigning: props.typeOfSigning,
			SignedBefore:  Number(props.SignedBefore),
			SignedDuring:  Number(props.SignedDuring)
		}
	}}
})(Uploaded);
