import React, { Component } from 'react';
import { query } from '../../../queries/getInstitutionBySearch';
import { graphql } from 'react-apollo';

class SearchInstitution extends Component {
	renderSearchResults(){
		if(this.props.data.institutionSearch.length === 0){
			return(
				<li>
					<p>No institutions found</p>
					<p className="subtitle">Please type another search query</p>
				</li>
			)
		}
		return this.props.data.institutionSearch.map((data) => {
			return(
				<li onClick={() => this.props.takeSearchResult(data)} key={data.ErasmusCode}>
					<p>{data.NameInLatinCharacterSet.toUpperCase()}</p>
					<p className="subtitle">{this.generateCityName(data)} - {this.generateCountryName(data)}</p>
				</li>
			)
		})
	}

	checkIfUniversityIsCorrectlyDefined(){
		this.props.data.institutionSearch.map((data) => {
			if(data.ErasmusCode === this.props.value.ErasmusCode){
				this.props.takeSearchResult(data);
			}
		})
	}

	generateCityName(data){
		try {
			return data.City.CityName
		}
		catch(error){
			return;
		}
	}

	generateCountryName(data){
		try {
			return data.City.Country.CountryName
		}
		catch(error){
			return;
		}
	}
	render() {
		if(this.props.data.loading){
			return (
	    		<div className={"search_box" + this.props.checkPopUp()}>
	    			<ul>
	    				<li>
	    					<p>Loading...</p>
	    					<p className="subtitle">Just a second</p>
	    				</li>
	    			</ul>
	    		</div>
	      	);
		}
		else{
			this.checkIfUniversityIsCorrectlyDefined();
    	return (
    		<div className={"search_box" + this.props.checkPopUp()}>
    			<ul>
    				{this.renderSearchResults()}
    			</ul>
    		</div>
      	);
    	}
    }

};


export default graphql(query, {
	options: (props) => {return {
		variables: { SearchValue: props.value}
	}}
})(SearchInstitution);
