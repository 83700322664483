import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentInstitutions';
import { graphql } from 'react-apollo';

class SelectInstitution extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}
	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		filters["institution"] = event.target.value;

		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}
	renderInstitutions(){
		return this.props.data.getStudentInstitutions.map(({InstitutionName, count}, index) => {
			let extractedInstitutionName = InstitutionName;
			if(extractedInstitutionName == ""){
				extractedInstitutionName = "Not found";
			}
			return <option key={index} value={extractedInstitutionName} >{extractedInstitutionName} ({count})</option>;
		})
	}
	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			this.setState({
				value: filters["institution"]
			})
		}
	}
	render() {
		if(this.props.CountryID == undefined){
			return <select><option value="">Select a country first</option></select>;
		}
		else if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
	    	return (
	    		<select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
	    			<option value="">Not selected</option>
	    			{this.renderInstitutions()}
	    		</select>
	      	);
      	}
    }

};

export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			CountryName: props.CountryID,
			SignedBy: props.SignedBy,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			Uploaded: props.Uploaded,
			AcademicYear: props.AcademicYear,
			StudyCycle: props.StudyCycle,
			SubjectArea: props.SubjectArea,
			SignedBefore:  Number(props.SignedBefore),
			SignedDuring:  Number(props.SignedDuring)
		}
	}}
})(SelectInstitution);
