import React, { Component } from 'react';
import { query } from '../../../queries/getInstitutions';
import { graphql } from 'react-apollo';

class SelectInstitution extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}

	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		this.props.getInstitution(event.target.value);
	}
	renderInstitutions(){
		let institutionsList = this.props.data.institutions;
		// sort list alphabetically
		institutionsList.sort(function(a, b){
		    if(a.NameInLatinCharacterSet < b.NameInLatinCharacterSet) { return -1; }
		    if(a.NameInLatinCharacterSet > b.NameInLatinCharacterSet) { return 1; }
		    return 0;
		});
		return institutionsList.map(data => {
			return <option key={data.ErasmusCode} value={data.ErasmusCode}>{data.NameInLatinCharacterSet}</option>;
		})
	}
	render() {
		if(this.props.cityLink != 0 && this.props.cityLink != undefined){
			if(this.props.data.loading){
				return <select><option>Loading...</option></select>;
			}
			else{
				return <select value={this.state.value} onChange={(event) => this.changeSelection(event)} ><option value={0} >Select institution</option>{this.renderInstitutions()}</select>;;
			}
			
		}      	
		return (
    		<select><option>(Institution) select a city first</option></select>
      	);
    	
    }

};

export default graphql(query, {
	options: (props) => {return {
		variables: { CityLink: props.cityLink}
	}}
})(SelectInstitution);
