import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {query as getNominations} from '../../../queries/getOutgoingNominations';
import { graphql, useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import NominationFilter from './Filter';
import Loader from 'react-loader-spinner';
// import Options from './Options';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from './../../../GlobalVar';

function NominationData(props) {
    // getData = getData.bind(this);
    let [optionalFiltersData, setOptionalFiltersData] = useState({});
    let [nameColumn,setNameColumn] = useState(true);
	  const handleOnChangeName = () => {
		setNameColumn(!nameColumn);
	  };
    let [emailColumn,setEmailColumn] = useState(false);
	  const handleOnChangeEmail = () => {
		setEmailColumn(!emailColumn);
	  };
    // let [esiColumn,setEsiColumn] = useState(false);
	  // const handleOnChangeEsi = () => {
		// setEsiColumn(!esiColumn);
	  // };
    let [eqfColumn,setEqfColumn] = useState(false);
	  const handleOnChangeEqf = () => {
		setEqfColumn(!eqfColumn);
	  };
    let [iscedColumn,setIscedColumn] = useState(false);
	  const handleOnChangeIsced = () => {
		setIscedColumn(!iscedColumn);
	  };
    let [academicTermColumn,setAcademicTermColumn] = useState(true);
	  const handleOnChangeAcademicTerm = () => {
		setAcademicTermColumn(!academicTermColumn);
	  };
    let [academicYearColumn,setAcademicYearColumn] = useState(true);
	  const handleOnChangeAcademicYear = () => {
		setAcademicYearColumn(!academicYearColumn);
	  };
    let [statusColumn,setStatusColumn] = useState(true);
	  const handleOnChangeStatus = () => {
		setStatusColumn(!statusColumn);
	  };
    const [statusValue,setStatusValue] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const [CreatedByValue,setCreatedByValue] = useState("");
    const [academicYearValue,setAcademicYearValue] = useState("");
    const [heiValue,setHeiValue] = useState("");
    const [countryValue,setCountryValue] = useState("");
    const [sortName,setSortName] = useState("");
    const [getSortName,setGetSortName] = useState("");
    const [sortLastName,setSortLastName] = useState("");
    const [sortEmail,setSortEmail] = useState("");
    // const [sortEsi,setSortEsi] = useState("");
    const [sortEqf,setSortEqf] = useState("");
    const [sortIsced,setSortIsced] = useState("");
    const [sortAcademicYear,setSortAcademicYear] = useState("");
    const [sortAcademicTerm,setSortAcademicTerm] = useState("");
    const [sortStatus,setSortStatus] = useState("");
    const [sortInstitution,setSortInstitution] = useState("");
    const [sortStartMobility,setSortStartMobility] = useState("");
    const [sortEndMobility,setSortEndMobility] = useState("");
    let [sortBy, setSortBy] = useState({
        "sortFirstName": "",
        "sortLastName": "",
        "sortStartMobility": "",
        "sortEndMobility": "",
        "sortStatus": "",
        "sortInstitution": ""
    });
    let [hasNext, setHasNext] = useState(true);
    let [loadingState, setLoadingState] = useState(true);
    const { firstYear, secondYear } = useParams();
    const history = useHistory();
    const [page, setPage] = useState(0);

    const renderHeader = () => {
        const institutionType = (props.typeOfStudent === 1) ? "Receiving Institution" : "Sending Institution";
        const institutionClass = (props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution";

        const headerColumns = [
		<div>{nameColumn && (

                <div>Name</div>)}</div>, 
		<div>
		{emailColumn && (
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortEmail === "" || sortEmail === "desc"){
                    setSortEmail("asc");
                    //setSortStartMobility("");
                    //setSortEndMobility("");
                    setSortName("");
					setSortStatus("");
					// setSortEsi("");
					setSortEqf("");
					setSortIsced("");
					setSortAcademicYear("");
					setSortAcademicTerm("");
                    //setSortInstitution("");
                }

                if(sortEmail === "asc") {
                    setSortEmail("desc");
                }
            }}>
            <div>Email</div>
            <div>
                {(sortEmail === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortEmail === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
		</div>)}</div>,
		<div>
		{eqfColumn && (
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortEqf === "" || sortEqf === "desc"){
                    setSortEqf("asc");
                    //setSortStartMobility("");
                    //setSortEndMobility("");
                    setSortName("");
					setSortEmail("");
					// setSortEsi("");
					setSortStatus("");
					setSortIsced("");
					setSortAcademicYear("");
					setSortAcademicTerm("");
                    //setSortInstitution("");
                }

                if(sortEqf === "asc") {
                    setSortEqf("desc");
                }
            }}>
            <div>EQF</div>
            <div>
                {(sortEqf === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortEqf === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
        </div>)}</div>,
		<div>
		{iscedColumn && (
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortIsced === "" || sortIsced === "desc"){
                    setSortIsced("asc");
                    //setSortStartMobility("");
                    //setSortEndMobility("");
                    setSortName("");
					setSortEmail("");
					// setSortEsi("");
					setSortEqf("");
					setSortStatus("");
					setSortAcademicYear("");
					setSortAcademicTerm("");
                    //setSortInstitution("");
                }

                if(sortIsced === "asc") {
                    setSortIsced("desc");
                }
            }}>
            <div>ISCED</div>
            <div>
                {(sortIsced === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortIsced === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
        </div>)}</div>,
		<div>
		{academicYearColumn && (
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortAcademicYear === "" || sortAcademicYear === "desc"){
                    setSortAcademicYear("asc");
                    //setSortStartMobility("");
                    //setSortEndMobility("");
                    setSortName("");
					setSortEmail("");
					// setSortEsi("");
					setSortEqf("");
					setSortIsced("");
					setSortStatus("");
					setSortAcademicTerm("");
                    //setSortInstitution("");
                }

                if(sortAcademicYear === "asc") {
                    setSortAcademicYear("desc");
                }
            }}>
            <div>Academic Year</div>
            <div>
                {(sortAcademicYear === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortAcademicYear === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
        </div>)}</div>,
		<div>
		{academicTermColumn && (

        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortAcademicTerm === "" || sortAcademicTerm === "desc"){
                    setSortAcademicTerm("asc");
                    //setSortStartMobility("");
                    //setSortEndMobility("");
                    setSortName("");
					setSortEmail("");
					// setSortEsi("");
					setSortEqf("");
					setSortIsced("");
					setSortAcademicYear("");
					setSortStatus("");
                    //setSortInstitution("");
                }

                if(sortAcademicTerm === "asc") {
                    setSortAcademicTerm("desc");
                }
            }}>
            <div>Academic Term</div>
            <div>
                {(sortAcademicTerm === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortAcademicTerm === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
        </div>)}</div>,
		<div>
		{statusColumn && (
            <div>Status</div>)}</div>]
    
        const renderedHeader = headerColumns.map((column, index) => (
            <th className={["th_student column media_links"].join(" ")}
                    //(column === headerColumns[headerColumns.length-1] ? institutionClass : null),
                    //(column === headerColumns[headerColumns.length-2]  ? "status" : null)].join(" ")} 
                    key={index} role="columnheader">
                <h3>{column}</h3>
            </th>
        ));
        return (
            renderedHeader
        );
    }

    const renderRows = (outgoingNominations) => {
        if (outgoingNominations ) {
            return outgoingNominations.map((nomination) =>
              <>
			<div>

			{/*{console.log (Object.keys(nomination.field_mobility_details).length)}*/}
      {console.log (nomination.hasFiles)}
			</div>
			<tr className={"tr_student row"} role="row">
		  {nameColumn &&
            <td className={"td_student column bold"} role="cell">
                  <p>{nomination.field_student_name}</p>
		  </td>}
		  {emailColumn &&
			<td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_email}</p>
            </td>}
		  {eqfColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_eqf}</p>
            </td>}
		  {iscedColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_isced}</p>
            </td>}
		  {academicYearColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_academic_year}</p>
            </td>}
		  {academicTermColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_academic_term}</p>
            </td>}
			
		  {statusColumn &&
            <td className={"td_student column"} role="cell">
				{nomination.field_nomination_status === "rejected_by_student" &&
				<p className="capitalize">Rejected by student</p>}
        {(nomination.field_nomination_status === "submitted" && nomination.field_mobility_details.some(mStatus => mStatus.field_mobility_details_status === "rejected")) &&
        <p className="capitalize">Rejected/Open</p>}
        {(nomination.field_nomination_status === "submitted" && (nomination.field_mobility_details.some(mStatus => mStatus.field_mobility_details_status === "rejected"))==false) &&
        <p className="capitalize">Submitted</p>}
				{nomination.field_nomination_status === "accepted_by_student" &&
				<p className="capitalize">Accepted by student</p>}
				{nomination.field_nomination_status === "rejected_by_receiving" &&
				<p className="capitalize">Rejected by receiving</p>}
				{nomination.field_nomination_status === "accepted_by_receiving" &&
				<p className="capitalize">Accepted by receiving</p>}
				{nomination.field_nomination_status === "rejected_by_sending" &&
				<p className="capitalize">Rejected by sending</p>}
				{nomination.field_nomination_status === "accepted_by_sending" &&
				<p className="capitalize">Accepted by sending</p>}
				{nomination.field_nomination_status === "waiting_list" &&
				<p className="capitalize">Waiting list</p>}
				{nomination.field_nomination_status === "cancelled" &&
				<p className="capitalize">Cancelled</p>}
            </td>}
            <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" to={{pathname:`/outgoing-student/application/${nomination.id}`}}>
                    Open Application
                  </Link>
              </p>
            </td>
		{nomination.hasFiles ? (
			 <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" to={{pathname:`/outgoing-student/exportzip/${nomination.id}`}}>
                    Export documents
                  </Link>
              </p>
            </td>
			) : (
			 <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}}>
                    Export documents
                  </Link>
              </p>
            </td>
    )
		}
          </tr>
		  </>
            );
        }
        return <p>No results</p>;
    }

    const triggerInput = (enteredName, enteredValue) => {
        const input = document.getElementById(enteredName);
        if(input != null){
            const lastValue = input.value;
            input.value = enteredValue;
            const event = new Event("change", { bubbles: true });
            const tracker = input._valueTracker;
            if (tracker) {
                tracker.setValue(lastValue);
            }
            input.dispatchEvent(event);
        }
    }

    const resetFilters = (val) => {
            setSearchValue("");
            setStatusValue("");
            setAcademicYearValue("");
            setSortName("");
            setSortStatus("");

            triggerInput("filter-search", "");
            triggerInput("status-filter", "");
            triggerInput("filter-academic-year", "");
        
    }

    const handleFilterSearch = (val) => {
        setSearchValue(val);
    }
    const handleFilterStatus = (val) => {
        setStatusValue(val);
    }
    const handleFilterAcademicYear = (val) => {
        setAcademicYearValue(val);
    }


    const sortNameRows = (rows, property) => {
        if (sortName === 'asc' || sortName === '') {
            rows.sort(function (a, b) {
                if (a[property] !== null && b[property] !== null) {
                    return a[property].localeCompare(b[property]);
                }
            });
        } else if (sortName === 'desc') {
            rows.sort(function (a, b) {
                if (a[property] !== null && b[property] !== null) {
                    return b[property].localeCompare(a[property]);
                }
            });
        }
        return rows
    }

    const sortStatusRows = (rows, property) => {
        if (sortStatus === 'asc' || sortStatus === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortStatus === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }
    const sortEmailRows = (rows, property) => {
        if (sortEmail === 'asc' || sortEmail === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortEmail === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }
    // const sortEsiRows = (rows, property) => {
        // if (sortEsi === 'asc' || sortEsi === '') {
            // rows.sort(function(a,b){
                // if (a[property] !== null && b[property] !== null) {
                // return a[property].localeCompare(b[property]);}
            // });
        // } else if (sortEsi === 'desc') {
            // rows.sort(function(a,b){
                // if (a[property] !== null && b[property] !== null) {
                // return b[property].localeCompare(a[property]);}
            // });
        // }
        // return rows
    // }
    const sortEqfRows = (rows, property) => {
        if (sortEqf === 'asc' || sortEqf === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortEqf === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }	
    const sortIscedRows = (rows, property) => {
        if (sortIsced === 'asc' || sortIsced === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortIsced === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortAcademicYearRows = (rows, property) => {
        if (sortAcademicYear === 'asc' || sortAcademicYear === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortAcademicYear === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }
    const sortAcademicTermRows = (rows, property) => {
        if (sortAcademicTerm === 'asc' || sortAcademicTerm === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortAcademicTerm === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }
    // const [enabled, setEnabled] = useState(false)
    const { data, status, loading, error, fetchMore, refetch } = useQuery(
      getNominations, {
        variables: {
            erasmusCode: localStorage.getItem("ErasmusCode"),
            //typeOfStudent: props.typeOfStudent,
            page: page,
            optionalFilters: optionalFiltersData
        }
    });

    function getData(val){
        // do not forget to bind getData in constructor
        setOptionalFiltersData(val)
        setPage(0)
        console.log("optional filters value changed")
        console.log(JSON.stringify(optionalFiltersData));
        refetch({
            erasmusCode: localStorage.getItem("ErasmusCode"),
            //typeOfStudent: props.typeOfStudent,
            page: 0,
            optionalFilters: optionalFiltersData
        })
    }
    // refetches after optionalFiltersData value changes
    // useEffect(() => {
    //     // refetch() // This is be executed when `loading` state changes
    // }, [optionalFiltersData])

    // function changeFilterState(){
    //     this.sendOptionalFiltersData();
    //     refetch();
    // }
    // useEffect(() => {
        // if(data != null) {
            // if(data.outgoingNominations !== []){
                // const { nextLink, page } = data.outgoingNominations;
                // let isNext = (nextLink !== "") ? true : false;
                // setHasNext(isNext);

                // if(hasNext && page != null){
                    // const { data } = fetchMore({
                        // variables: { 
                            // erasmusCode: props.erasmusCode,
                            // //typeOfStudent: props.typeOfStudent,
                            // page: page
                        // },
                        // updateQuery: (prev, { fetchMoreResult }) => {
                            // if (!fetchMoreResult) {
                                // return prev;
                            // }
                            // return {
                                // outgoingNominations: {
                                    // ...prev.outgoingNominations,
                                    // ...fetchMoreResult.outgoingNominations,
                                    // nominationList: [
                                        // ...prev.outgoingNominations.nominationList,
                                        // ...fetchMoreResult.outgoingNominations.nominationList,
                                    // ],
                                // },
                            // };
                        // }
                    // });
                // } else {
                    // setLoadingState(false);
                // }
            // }
        // }
    // });

    /*****************************/
    /******** RENDER PART ********/
    /*****************************/
    if (error) console.log(error);

    // if(loading) {
    //     return (
    //         <div className="white_background">
    //             <Loader
    //             className="spinner"
    //             type="TailSpin"
    //             color="#3986df"
    //             height={70}
    //             width={70} />
    //         </div>
    //     )
    // }

    const header = renderHeader();
    let rows = data ? data.outgoingNominations.nominationList: null;
    if (rows !== undefined && rows !== null) {
        rows = Array.prototype.slice.call(rows);
        if (sortName) {
            rows = sortNameRows(rows, "field_student_name");
        }
        if (sortEmail) {
            rows = sortEmailRows(rows, "field_student_email");
        }
        // if (sortEsi) {
            // rows = sortEsiRows(rows, "field_student_esi");
        // }
        if (sortEqf) {
            rows = sortEqfRows(rows, "field_student_eqf");
        }
        if (sortIsced) {
            rows = sortIscedRows(rows, "field_student_isced");
        }
        if (sortAcademicYear) {
            rows = sortAcademicYearRows(rows, "field_academic_year");
        }
        if (sortAcademicTerm) {
            rows = sortAcademicTermRows(rows, "field_academic_term");
        }

        if (sortStatus) {
            rows = sortStatusRows(rows, "field_mobility_details.field_mobility_details_status");
        }

    }

    if (searchValue) {
        rows = rows.filter((data) => {
            return (data.field_student_name !== null && data.field_student_name.toLowerCase().includes(searchValue.toLowerCase())) ||
                //(data.field_student_lastname !== null && data.field_student_lastname.toLowerCase().includes(searchValue.toLowerCase()))||
                (data.field_student_email !== null && data.field_student_email.toLowerCase().includes(searchValue.toLowerCase())) ||
                //(data.sending_institution_faculty !== null && data.sending_institution_faculty.toLowerCase().includes(searchValue.toLowerCase()))||
                //(data.receiving_institution_faculty !== null && data.receiving_institution_faculty.toLowerCase().includes(searchValue.toLowerCase()))||
                (data.field_start_date !== null && data.field_start_date.includes(searchValue)) ||
                (data.field_end_date !== null && data.field_end_date.includes(searchValue))
        })
    }
    if (academicYearValue) {
        rows = (rows.filter((data) => {
            return academicYearValue.includes(data.field_academic_year)
        }))
    }

    if (statusValue) {
       rows = (rows.filter((data) => {
            return data.field_la_status === statusValue}))
    }
    if (CreatedByValue){
        if(CreatedByValue.includes("Created By Student")){
            rows = (rows.filter((data) => {
                return data.field_dashboard_student_email === null}))
        }
        if(CreatedByValue.includes("Created By Institution")){
            rows=(rows.filter((data) => {
                return data.field_dashboard_student_email !== null}))
            }

    }

    return (
        <div className={"content student_list"}>
            <div className="division_25">
                <div className="card card_full_width card_full_height">
					  <div className="card">
                <NominationFilter sendData={getData} />
					  </div>
					  	{/*						<div className="row">*/}
							{/*	<div className="column_long" id=""><button onClick={() => refetch()}>Search</button></div>*/}
							{/*</div>*/}
                    <div className="card">
                        <div className="sub_content">
                            <h2 style={{ color: '#4a90e2' }}>Columns</h2>
							<table>
								<tr>
                                    <td>
										Email
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Email" checked={emailColumn} onChange={handleOnChangeEmail} />
                                    </td>
								</tr>
								<tr>
                                    <td>
										EQF
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Eqf" checked={eqfColumn} onChange={handleOnChangeEqf} />
                                    </td>
								</tr>
                                <tr>
                                    <td>
										ISCED
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Isced" checked={iscedColumn} onChange={handleOnChangeIsced} />
                                    </td>
								</tr>
								<tr>
                                    <td>
										Academic Year
                                    </td>
                                    <td>
                                        <input type="checkbox" id="AcademicYear" checked={academicYearColumn} onChange={handleOnChangeAcademicYear} />
                                    </td>
								</tr>
                                <tr>
                                    <td>
										Academic Term
                                    </td>
                                    <td>
                                        <input type="checkbox" id="AcademicTerm" checked={academicTermColumn} onChange={handleOnChangeAcademicTerm} />
                                    </td>
								</tr>
							</table>
                        </div>
                    </div>
                </div>
            </div>
                {!loading
                  ?
                  <div className="card card_full_width card_full_height">
                      <table className={"table1_student custom_table"} role="table">
                          <thead className={"thead_student"} role="rowgroup">
                          <tr className={"tr_student row"} role="row">
                              {nameColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>Name</h3>
                              </th>}
                              {emailColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>Email</h3>
                              </th>}
                              {eqfColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>EQF</h3>
                              </th>}
                              {iscedColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>ISCED</h3>
                              </th>}
                              {academicYearColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>Academic Year</h3>
                              </th>}
                              {academicTermColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>Academic Term</h3>
                              </th>}
                              {statusColumn &&
                              <th className={"th_student column"} role="cell">
                                  <h3>Status</h3>
                              </th>}
                          </tr>
                          </thead>
                          <tbody className={"tbody_student"} role="rowgroup">
                          {renderRows(rows)}
                          <tr>
                          </tr>
                          <tr>
                              <button className={"nominationPage_button"}
                                      onClick={() => setPage((old) => Math.max(old - 1, 0))}
                                      disabled={page === 0}
                              >
                                  Previous page
                              </button>
                              <span>    </span>
                              <button className={"nominationPage_button"}
                                      onClick={() => setPage(page + 1)}
                                      disabled={data.outgoingNominations.nextLink === ""}
                              >
                                  Next page
                              </button>
                              <span>  Current Page: {page + 1} </span>
                          </tr>
                          </tbody>
                      </table>
                  </div>
                  :
                  <div className="white_background">
                      <Loader
                        className="spinner"
                        type="TailSpin"
                        color="#3986df"
                        height={70}
                        width={70}/>
                  </div>
                }
        </div>
    )
    /*****************************/
    /****** END RENDER PART ******/
    /*****************************/
}

export default NominationData;