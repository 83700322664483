import React, { Component } from 'react';
import { query } from '../../../queries/getFrequentlyAskedQuestions';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import FaqRow from './FaqRow'

class FrequentlyAskedQuestions extends Component {
    constructor(props) {
		super(props);
		this.state = {
			questionInput: "",
            answerInput: ""
		}
	}
	generateQuestions(){
		return this.props.data.faq.map((faq) => {
			    return <FaqRow key={faq.id} faq={faq}/>;   
		})
	}
  
	handleSubmit(event){
		event.preventDefault();
		this.props.AddFaq({
			variables:{
				"Question": this.state.questionInput,
				"Answer": this.state.answerInput
			},
            refetchQueries: [{query}]
		})
	}
    render() { 
        if(this.props.data.loading){	    
			return (
	    		<div className="card card_half_width card_fixed_height">
	    			
	    		</div>
	      	);
		}
		else {  	
	    	return (
	    		<div className="content faqs">
                	<div className="card">
                    	{this.generateQuestions()}
                    </div>	
	    		
		    		<form onSubmit={(event) => this.handleSubmit(event)}>
		    		  <div className="round-button-circle"><button className="round-button">+</button></div>
	                </form> 
                </div>
	      	);
	    }   
    }
};

const addFaq = gql`
  mutation AddFaq( $Question: String!, $Answer: String!){
    addFaq(Question: $Question, Answer: $Answer){
      id
    }
  }
`
export default compose(
	graphql(query),
	graphql(addFaq , { name: 'AddFaq' })
)(FrequentlyAskedQuestions);