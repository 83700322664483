import gql from 'graphql-tag';

const query = gql`
	query getSingleStudent($encryptedOlaID: String){
		singleStudent(encryptedOlaID: $encryptedOlaID){
			olaID
			changesOlaID
	    studentID
			firstname
			name
			email
			sex
			birthyear
			birthday
			birthmonth
			nationality
			phone
			erasmusCodeReceiving
			erasmusCodeSending
			status
			subjectArea
			subjectAreaCode
			facultySend
			departmentSend
			facultyRecive
			departmentRecive
			cycle
			receiveContactname
			receiveEmail
			receivePhone
			receiveFunction
			sendContactname
			sendEmail
			sendPhone
			sendFunction
			language
			languagelevel
			sendResName
			sendResFun
			sendResPhone
			sendResEmail
			recResName
			recResFun
			recResPhone
			recResEmail
			mobilityFromMonth
			mobilityFromYear
			mobilityToMonth
			mobilityToYear
			encryptedID
	  	countrySend
			countryRecive
			uniNameRecive
			uniNameSend
			changesOlaID
			changesStatus
			AbroadCoursesBefore{
	      code
	      title
	      ECTS
	      semester
	    }
	    HomeCoursesBefore{
	      code
	      title
	      ECTS
	      semester
	    }
			AbroadCoursesDuring{
	      code
	      title
	      ECTS
	      semester
				reason_int
				reason_txt
				isdeleted
	    }
	    HomeCoursesDuring{
	      code
	      title
	      ECTS
	      semester
				reason_int
				reason_txt
				isdeleted
	    }
		}
	}
`
export { query };
