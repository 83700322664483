import React, { Component } from 'react';

class Title extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h2>{this.props.text}</h2>
            </div>
        );
    }
}

export default Title;

/*
Props attributes:
    * h2
        - text
*/