import React, { Component } from 'react';
import gql from 'graphql-tag';
import axios from 'axios';
import querystring from 'querystring';
import { graphql, gql as gqlM } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';

import GlobalVar from '../../../GlobalVar';
import Title from '../common/Title';
import Validator from '../common/Validator';

import { query as getLanguages } from '../../../queries/getLanguages';
import NoAccess from "../list/NoAccess";

class ImportIIA extends Component {
	constructor(props) {
        super(props);

        this.state = {
            reloadActions: false,
            data: null,
            totalAgreements: 0,
            uploadedAgreements: 0,
            importAllActive: false,
            isThereInvalidAgreements: false,
            tcCheckboxImport: false,
            creatorOUList: [],
            partnerOUList: [],
            creatorEWPStatus: -10,
            partnerEWPStatus: -10
        }

        this.maxIIA2Import = 51;        // always add 1 more to the actual limit, because the first row is the CSV header
        this.allIIAs = null;
        this.agreementsImported = new Array();

        this.upload = this.upload.bind(this);
        this.importIIAs = this.importIIAs.bind(this);
    }

    render() {
        console.log(this.props)
        if(this.props.isIIAManagedByEWPLocal?.loading){
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg"/>Loading Data...</p>
                    </div>
                </div>
            );
        }
        if(this.props.isIIAManagedByEWPLocal?.isIIAManagedByEWP?.Success===2){
        if (this.state.data != null) {

            if (!sessionStorage.getItem(("iiaSubmited"))) { this.initializeIIASubmited(false); }

            let uploadingAllIIAsCode = "";
            if (this.state.importAllActive) {
                if (this.state.uploadedAgreements === this.state.totalAgreements) { 
                    this.initializeIIASubmited(true);
                    uploadingAllIIAsCode = <p id="statusFinished">All agreements imported. </p>;
                }
                else if (this.state.isThereInvalidAgreements) { uploadingAllIIAsCode = <p>{ this.state.uploadedAgreements } of { this.state.totalAgreements } agreements imported successfully.</p>; }
                else { uploadingAllIIAsCode = <p>{ this.state.uploadedAgreements } of { this.state.totalAgreements } agreements imported...<img className="spinner" src="/icons/spinner.svg" /></p>; }
            }

            return (
                <div  className="content student_list">
                    <div className="card card_full_width card_full_height">
                        <Title text="Upload CSV file to import Inter-Institutional Agreements" />
                        <div className="custom_table">
                            <hr/>
                            <div style={{ marginTop: "10px", marginBottom: "5px", width: "95%" }}>
                                <p>The Inter-Institutional Agreement import funcionality now supports the CSV upload. Upon entering the data you can overview the agreements one-by-one to make sure all the 
                                information is correct and submit the data. This action will trigger a creation of pre-filled Inter-Institutional Agreement and a notification for the partner inviting it
                                to edit or sign the document. It is also possible to submit all agreements at the same time by clicking on the 'Import all' button. The format of the CSV file can be 
                                verified through the use of a template spreadsheet made available on the EWP CC {" "}
                                <a rel="noopener noreferrer" target="_blank" href="//wiki.uni-foundation.eu/display/DASH/Importing+IIAs">here</a>.
                                </p>
                            </div>
                            <p>&nbsp;</p>
                            <p><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxImport} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                            <p>&nbsp;</p>
                            <input type="file" onChange={(e) => this.upload(e)} style={{ border: "0px" }}/>
                            {!this.state.tcCheckboxImport ? (
                                <React.Fragment>
                                    <Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "Import IIA") + "/file-details", state: { data: this.state.data } }}>
                                        <fieldset disabled style={{ verticalAlign: "bottom" }}>
                                            <button style={{ marginLeft: "5px" }}>
                                                View and Import
                                            </button>
                                        </fieldset>
                                    </Link>
                                    <fieldset disabled style={{ verticalAlign: "bottom" }}>
                                        <button onClick={() => this.importIIAs()} style={{ marginLeft: "5px" }}>Import All</button>
                                    </fieldset>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "Import IIA") + "/file-details", state: { data: this.state.data } }}>
                                        <button style={{ marginLeft: "5px" }}>
                                            View and Import
                                        </button>
                                    </Link>
                                    <button onClick={() => this.importIIAs()} style={{ marginLeft: "5px" }}>Import All</button>
                                </React.Fragment>
                            )}
                            <div style={{ marginTop: "10px", width: "50%" }}>
                                { uploadingAllIIAsCode }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            sessionStorage.removeItem("iiaSubmited");
            return (
                <div  className="content student_list">
                    <div className="card card_full_width card_full_height">
                        <Title text="Upload CSV file to import Inter-Institutional Agreements" />
                        <div className="custom_table">
                            <hr/>
                            <div style={{ marginTop: "10px", marginBottom: "5px", width: "95%" }}>
                                <p>The Inter-Institutional Agreement import funcionality now supports the CSV upload. Upon entering the data you can overview the agreements one-by-one to make sure all the 
                                information is correct and submit the data. This action will trigger a creation of pre-filled Inter-Institutional Agreement and a notification for the partner inviting it
                                to edit or sign the document. It is also possible to submit all agreements at the same time by clicking on the 'Import all' button. The format of the CSV file can be 
                                verified through the use of a template spreadsheet made available on the EWP CC {" "}
                                <a rel="noopener noreferrer" target="_blank" href="//wiki.uni-foundation.eu/display/DASH/Importing+IIAs">here</a>.
                                </p>
                            </div>
                            <p>&nbsp;</p>
                            <p><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxImport} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                            <p>&nbsp;</p>
                            <input type="file" onChange={(e) => this.upload(e)} style={{ border: "0px" }}/>
                        </div>
                    </div>
                </div>
            );
        }
        }
        else {
            return (
                <NoAccess/>
            );
        }
    }

    termsAndConditionsHandler(event) {
        const { name, value } = event.target;
        const isChecked = (document.getElementsByName(name)[0].checked) ? true : false;

        this.setState({ tcCheckboxImport: isChecked });
    }

    upload(e) {
        console.log("Uploading...");
        var file = e.target.files[0];
        console.log("Reading file... " + JSON.stringify(file));
        
        if (file != null) {
            if (file.name.substring(file.name.lastIndexOf('.') + 1) !== "csv") {
                alert("Sorry, but the selected file does not have the correct extension. Please, select a .csv file.");
                this.setState({ 
                    data: null,
                    importAllActive: false, 
                    isThereInvalidAgreements: false,
                    totalAgreements: 0,
                    uploadedAgreements: 0  
                });
            }
            else {
                var reader = new FileReader();
                reader.readAsText(file);
                
                reader.onload = (event) => {
                    let data = this.convertData(event.target.result);
                    if (this.isTemplateValid(data)) {
                        this.setState({ 
                            data: this.removeEmptyLines(data),
                            importAllActive: false, 
                            isThereInvalidAgreements: false,
                            totalAgreements: 0,
                            uploadedAgreements: 0 
                        });
                    }
                    else {
                        alert("Sorry, but the template used it's not valid. You can get the official template in the Import IIA page.");
                        this.setState({ 
                            data: null,
                            importAllActive: false, 
                            isThereInvalidAgreements: false,
                            totalAgreements: 0,
                            uploadedAgreements: 0  
                        });
                    }
                }
            }
            sessionStorage.removeItem("iiaSubmited");
        }
        else {
            this.setState({ 
                data: null,
                importAllActive: false, 
                isThereInvalidAgreements: false,
                totalAgreements: 0,
                uploadedAgreements: 0  
            });
        }
    }

    convertData(data) {
        let dataParsed = Papa.parse(data);
        
        return dataParsed;
    }

    isTemplateValid(data) {
        let csvHeader = data.data[0];
        
        if (csvHeader[0] !== "Creator OUnit" || csvHeader[1] !== "Creator Contact Name" || csvHeader[2] !== "Creator Contact Email" || csvHeader[3] !== "Creator Contact Phone" ||
            csvHeader[4] !== "Creator General Website" || csvHeader[5] !== "Creator Faculty Website" || csvHeader[6] !== "Creator Course Catalogue Website" ||
            csvHeader[7] !== "Partner EC" || csvHeader[8] !== "Partner OUnit" || csvHeader[9] !== "Partner Contact Name" || csvHeader[10] !== "Partner Contact Email" ||
            csvHeader[11] !== "Partner Contact Phone" || csvHeader[12] !== "Partner General Website" || csvHeader[13] !== "Partner Faculty Website" || csvHeader[14] !== "Partner Course Catalogue Website" ||
            csvHeader[15] !== "Start Academic Year" || csvHeader[16] !== "End Academic Year") {
                return false;
        }

        for (let i = 17; i < csvHeader.length; i += 13) {
            if (csvHeader[i] !== "Sender EC" || csvHeader[i+1] !== "Receiver EC" || csvHeader[i+2] !== "Sending OU" || csvHeader[i+3] !== "Receiving OU" || csvHeader[i+4] !== "Mobilities Per Year" || 
                csvHeader[i+5] !== "Subject Area" || csvHeader[i+6] !== "Mobility Type" || csvHeader[i+7] !== "Total Months Per Year Per Mobility" || csvHeader[i+8] !== "Study Cycle" || 
                csvHeader[i+9] !== "Blended" || csvHeader[i+10] !== "Total Days Per Year Per Mobility" || csvHeader[i+11] !== "Recommended Language Skill" || csvHeader[i+12] !== "Other Information") {
                    return false;
            }
        }

        return true;
    }

    removeEmptyLines(data) {
        let newData = [];
        let countValidIIA = 1;          // the initial value is 1 because of the header of the CSV
        let countEmptyRows = 0;
        let countWrongEC = 0;
        let currentEC = JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode;

        data.data.map((line, index) => {
            if (index == 0) { newData.push(line); }
            else {
                if (line.join("").length === 0) { countEmptyRows++; }
                else if (currentEC === line[7] || line[7] === "") { countWrongEC++; }
                else if (countValidIIA < this.maxIIA2Import) {           // it only imports the first 'maxIIA2Import' agreements and discards the rest
                    newData.push(line); 
                    countValidIIA++;
                }

                if ((data.data.length === index+1) && (countValidIIA >= this.maxIIA2Import)) {
                    let message1 = "";
                    let message2 = "";
                    if (countEmptyRows > 0 || countWrongEC > 0) { 
                        message1 = "with "; 
                        message2 = "these invalid rows and ";
                    }
                    let emptyRowsMessage = "";
                    if (countEmptyRows > 0) { emptyRowsMessage = countEmptyRows + " empty rows, "; }
                    let invalidIIAMessage = "";
                    if (countWrongEC > 0) { invalidIIAMessage = countWrongEC + " agreements with wrong PartnerEC (partners cannot have the same Erasmus Code as you or an invalid Erasmus Code), "; }
                    alert("Currently, importing inter-institutional agreements only accepts up to " + (this.maxIIA2Import-1) + " agreements, and the file you just selected "
                        + "have a total of " + (data.data.length-1) + " agreements, " + message1 + emptyRowsMessage + invalidIIAMessage + "so " + message2 + "the last " 
                        + ((data.data.length-1) - (this.maxIIA2Import-1) - countWrongEC - countEmptyRows) + " valid agreements will be discarded.");
                }
            }
            return newData;
        });
        console.log("newData length: ", newData.length);
        if (newData.length > 1) { return newData; }
        else { 
            alert("This error might be caused by one of the following:\n" +
                  " - All rows in the file uploaded are empty or invalid;\n" +
                  " - There is no Partner EC;" + 
                  " - You used your own EC as the Partner EC.\n"+
                  "Please check your file. Without these errors fixed, it is not possible to check the remaining fields.");
            return null; 
        }
    }

    initializeIIASubmited(isAllImported) {
        let iiaSubmited = new Array();
        
        for (let i=0; i<this.state.data.length-1; i++) {
            iiaSubmited[i] = isAllImported;
        }

        sessionStorage.setItem("iiaSubmited", JSON.stringify(iiaSubmited));
        if (!this.state.importAllActive) { this.setState({ totalAgreements: this.state.data.length - 1 }); }
    }

    importIIAs() {
        if (window.confirm("Are you sure do you want to import all agreements?")) {
            this.setState({ importAllActive: true });

            this.parsingData((missingFields) => {
                if (missingFields.length > 0) {
                    this.setState({ isThereInvalidAgreements: true });
                    alert("It is not possible to submit some agreements, as there are missing required fields or fields with invalid values:\n- " + missingFields.join("\n- "));
                }
            });
        }
    }

    parsingData(callback) {
        if (!this.props.getIscedFCodesList.loading) {
            this.allIIAs = new Array();
            let allMissingFields = [];
            let missingFields = [];

            this.state.data.map((iia, index, array) => {
                if (index > 0) {
                    // this.getCreatorECWithSpace(iia[0], (creatorEC) => {
                        let creatorEC = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;

                        this.getPartnerECWithSpace(iia[7], (partnerEC) => {
                            this.getInstitutionData(creatorEC, (creatorData) => {
                                this.getInstitutionData(partnerEC, (partnerData) => {
                                    let partnerDetails = ({
                                        creatorInstitution: (creatorData != null && creatorData !== "") ? creatorData.getIIAmInstitution.NameInLatinCharacterSet : "",
                                        creatorErasmusCode: (creatorData != null && creatorData !== "") ? creatorData.getIIAmInstitution.ErasmusCode : creatorEC ? creatorEC : "",
                                        creatorCountry: (creatorData != null && creatorData !== "") ? creatorData.getIIAmInstitution.CountryName : "",
                                        creatorCity: (creatorData != null && creatorData !== "") ? creatorData.getIIAmInstitution.CityName : "",
                                        creatorOUnit: iia[0],
                                        creatorContactName: iia[1],
                                        creatorContactEmail: iia[2],
                                        creatorContactPhone: iia[3],
                                        creatorGeneralWebsite: iia[4],
                                        creatorFacultyWebsite: iia[5],
                                        creatorCourseWebsite: iia[6],

                                        partnerInstitution: (partnerData != null && partnerData !== "") ? partnerData.getIIAmInstitution.NameInLatinCharacterSet : "",
                                        partnerErasmusCode: (partnerData != null && partnerData !== "") ? partnerData.getIIAmInstitution.ErasmusCode : partnerEC ? partnerEC : "",
                                        partnerCountry: (partnerData != null && partnerData !== "") ? partnerData.getIIAmInstitution.CountryName : "",
                                        partnerCity: (partnerData != null && partnerData !== "") ? partnerData.getIIAmInstitution.CityName : "",
                                        partnerOUnit: iia[8],
                                        partnerContactName: iia[9],
                                        partnerContactEmail: iia[10],
                                        partnerContactPhone: iia[11],
                                        partnerGeneralWebsite: iia[12],
                                        partnerFacultyWebsite: iia[13],
                                        partnerCourseWebsite: iia[14],

                                        academicYearStart: iia[15],
                                        academicYearEnd: iia[16],

                                        creatorSigningDate: this.setDate(),
                                        iiaCode: this.setIIACode(JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                    });

                                    let iiaConditions = new Array();
                                    let emptyConditionCount = JSON.stringify(iia).split(",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\"").length - 1;  // ignores empty conditions (13 empty fields)
                                    let actualLength = iia.length - (emptyConditionCount * 13);
                                    for (let i = 17; i < actualLength; i += 13) {

                                        let iscedFCodeValues = [];
                                        if (this.state.data[index][i + 5] !== "") {
                                            let iscedFCodeArray = this.state.data[index][i + 5].includes(";") ? this.state.data[index][i + 5].split(";") : [this.state.data[index][i + 5]];
                                            iscedFCodeValues = new Array();

                                            iscedFCodeArray.map((value, index) => {
                                                if (index % 2 === 0) {
                                                    this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                                                        if (data.code === value) {
                                                            iscedFCodeValues.push({ iscedFCode: data.code, iscedClarification: (iscedFCodeArray.length >= index + 1) ? iscedFCodeArray[index + 1] : "" });
                                                            return;
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                        let eqfValues = [];
                                        if (this.state.data[index][i + 8] !== "") {
                                            let eqfArray = this.state.data[index][i + 8].includes(";") ? this.state.data[index][i + 8].split(";") : [this.state.data[index][i + 8]];
                                            eqfValues = new Array();
                                            eqfArray.forEach((item) => {
                                                eqfValues.push("EQF " + item);
                                            });
                                        }
                                        let languagesValues = [];
                                        if (this.state.data[index][i + 11] !== "") {
                                            let languagesArray = this.state.data[index][i + 11].includes(";") ? this.state.data[index][i + 11].split(";") : [this.state.data[index][i + 11]];
                                            languagesValues = new Array();

                                            let language = "";
                                            let cefr = "";
                                            let isced = "";
                                            let clarification = "";
                                            languagesArray.map((value, index) => {
                                                let pos = index % 4;

                                                if (pos === 0) {
                                                    language = "";
                                                    cefr = "";
                                                    this.props.allLanguages.allLanguages.map((data) => {
                                                        if (data.Code === value) {
                                                            language = data.Name + " - " + data.Code;
                                                            cefr = languagesArray[index+1];
                                                            return;
                                                        }
                                                    });
                                                }
                                                else if (pos === 2) {
                                                    isced = "";
                                                    clarification = "";
                                                    this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                                                        if (data.code === value) {
                                                            isced = data.code;
                                                            clarification = languagesArray[index+1];
                                                            return;
                                                        }
                                                    });
                                                }
                                                else if (pos === 3) {
                                                    languagesValues.push({ language: language, cefrLevel: cefr, iscedFCode: isced, clarification: clarification });
                                                }
                                            });
                                        }
                                        console.log("iia[i] === creatorEC", iia[i] === creatorEC.replace(/\s+/g, ""));
                                        let senderEC = iia[i] === iia[7] ? partnerEC : (iia[i] === creatorEC.replace(/\s+/g, "") ? creatorEC : "");
                                        let receiverEC = iia[i + 1] === iia[7] ? partnerEC : (iia[i + 1] === creatorEC.replace(/\s+/g, "") ? creatorEC : "");
                                        iiaConditions.push({
                                            senderInstitution: senderEC,
                                            receiverInstitution: receiverEC,
                                            sendingOUnit: iia[i + 2],
                                            receivingOUnit: iia[i + 3],
                                            mobilitiesPerYear: iia[i + 4],
                                            iscedFCodes: iscedFCodeValues,
                                            mobilityType: iia[i + 6],
                                            totalMonths: (iia[i + 6] === "Student studies" || iia[i + 6] === "Student traineeship") ? iia[i + 7] : 0,
                                            totalDays: (iia[i + 6] === "Staff teacher" || iia[i + 6] === "Staff training") ? iia[i + 10] : 0,
                                            eqfLevels: eqfValues,
                                            blended: iia[i + 9] != "" ? iia[i + 9] : 0,
                                            languages: languagesValues,
                                            otherInformation: iia[i + 12]
                                        });
                                    }

                                    missingFields = this.validateFields(partnerDetails, iiaConditions, index, (values) => {
                                        if (values.length === 0) {
                                            let currentIIA = {
                                                partnerDetails,
                                                conditions: iiaConditions
                                            }
                                            this.submitIIA(currentIIA, index - 1);
                                        }

                                        return values;
                                    });

                                    allMissingFields = allMissingFields.concat(missingFields);
                                    if (this.state.data.length === index + 1) {
                                        return callback(allMissingFields);
                                    }
                                });
                            });
                        });
                    // });
                }
            });
        }
    }

    validateFields(pd, cc, iiaNum, callback) {
        let validator = null;
        let errorMessage = "";
        let missingFields = [];

        if (pd.creatorErasmusCode === "") { missingFields.push("Creator EC (agreement " + iiaNum + ") - This field may not be empty/invalid."); }
        else {
            validator = new Validator(pd.creatorErasmusCode);
            errorMessage = validator.validateErasmusCode(false);
            if (errorMessage !== "") { missingFields.push("Creator EC (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorContactName !== "") {
            validator = new Validator(pd.creatorContactName);
            errorMessage = validator.validateRegularNamesOfRequiredFields();
            if (errorMessage !== "") { missingFields.push("Creator Contact Name (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorContactEmail !== "") {
            validator = new Validator(pd.creatorContactEmail);
            errorMessage = validator.validateEmailFormat(false);
            if (errorMessage !== "") { missingFields.push("Creator Contact Email (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorContactPhone !== "") {
            validator = new Validator(pd.creatorContactPhone);
            errorMessage = validator.validatePhoneFaxNumber(true);
            if (errorMessage !== "") { missingFields.push("Creator Contact Phone (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorGeneralWebsite !== "") {
            validator = new Validator(pd.creatorGeneralWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator General Website (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorFacultyWebsite !== "") {
            validator = new Validator(pd.creatorFacultyWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator Faculty Website (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.creatorCourseWebsite !== "") {
            validator = new Validator(pd.creatorCourseWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator Course Catalogue Website (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerErasmusCode === "") { missingFields.push("Partner EC (agreement " + iiaNum + ") - This field may not be empty."); }
        else {
            validator = new Validator(pd.partnerErasmusCode);
            errorMessage = validator.validateErasmusCode(false);
            if (errorMessage !== "") { missingFields.push("Partner EC (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerContactName !== "") {
            validator = new Validator(pd.partnerContactName);
            errorMessage = validator.validateRegularNamesOfRequiredFields();
            if (errorMessage !== "") { missingFields.push("Partner Contact Name (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerContactEmail !== "") {
            validator = new Validator(pd.partnerContactEmail);
            errorMessage = validator.validateEmailFormat(false);
            if (errorMessage !== "") { missingFields.push("Partner Contact Email (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerContactPhone !== "") {
            validator = new Validator(pd.partnerContactPhone);
            errorMessage = validator.validatePhoneFaxNumber(true);
            if (errorMessage !== "") { missingFields.push("Partner Contact Phone (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerGeneralWebsite !== "") {
            validator = new Validator(pd.partnerGeneralWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner General Website (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerFacultyWebsite !== "") {
            validator = new Validator(pd.partnerFacultyWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner Faculty Website (agreement " + iiaNum + ") - " + errorMessage); }
        }
        if (pd.partnerCourseWebsite !== "") {
            validator = new Validator(pd.partnerCourseWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner Course Catalogue Website (agreement " + iiaNum + ") - " + errorMessage); }
        }

        let academicYearStartLeftYear = parseInt(pd.academicYearStart.substring(0, pd.academicYearStart.indexOf('/')));
        let academicYearStartRightYear = parseInt(pd.academicYearStart.substring(pd.academicYearStart.indexOf('/') + 1));
        let academicYearEndLeftYear = parseInt(pd.academicYearEnd.substring(0, pd.academicYearEnd.indexOf('/')));
        let academicYearEndRightYear = parseInt(pd.academicYearEnd.substring(pd.academicYearEnd.indexOf('/') + 1));
        if (pd.academicYearStart === "") { missingFields.push("Start Academic Year (agreement " + iiaNum + ") - This field may not be empty."); }
        if (academicYearStartLeftYear + 1 !== academicYearStartRightYear) {
            missingFields.push("Start Academic Year (agreement " + iiaNum + ") - The year on the left must be less one year than the year on the right.");
        }
        if (pd.academicYearEnd === "") { missingFields.push("End Academic Year (agreement " + iiaNum + ") - This field may not be empty."); }
        if (academicYearEndLeftYear + 1 !== academicYearEndRightYear) {
            missingFields.push("End Academic Year (agreement " + iiaNum + ") - The year on the left must be less one year than the year on the right.");
        }
        if (academicYearStartLeftYear > academicYearEndLeftYear) { missingFields.push("Academic Year (agreement " + iiaNum + ") - The Start Academic Year may not be greater than End Academic Year."); }

        cc.map((c, index) => {
            let condNum = index + 1;   // Number of the condition
            let columnIndex = index * 13 + 18;
            if (c.senderInstitution === "") { missingFields.push("Sender EC (agreement " + iiaNum + ", condition " + condNum + ", column " + columnIndex + ") - This field may not be empty."); }
            if (c.receiverInstitution === "") { missingFields.push("Receiver EC (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 1) + ") - This field may not be empty."); }
            if (c.mobilitiesPerYear === "") { missingFields.push("Mobilities Per Year (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 4) + ") - This field may not be empty."); }

            c.iscedFCodes.map((value, idx) => {
                if (value.iscedFCode === "" && value.iscedClarification !== "") {
                    missingFields.push("Subject Area (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 5) + ") - The pair " + parseInt((idx/2)+1) + " cannot have a clarification without a code.");
                }
            });
            if (c.mobilityType === "Student studies" || c.mobilityType === "Student traineeship") {
                if (c.totalMonths == 0) { missingFields.push("Total Months (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 7) + ") - This field may not be empty."); }
                if (c.blended != 1 && c.blended != 0) { missingFields.push("Blended (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 9) + ") - This field must be filled with 0 (not blended) or 1 (blended).")}
            }
            else if ((c.mobilityType === "Staff teacher" || c.mobilityType === "Staff training") && c.totalDays == 0) { missingFields.push("Total Days (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 10) + ") - This field may not be empty."); }
            else if (c.mobilityType === "" || (c.mobilityType !== "Student studies" && c.mobilityType !== "Student traineeship" && c.mobilityType !== "Staff teacher" && c.mobilityType !== "Staff training")) { 
                missingFields.push("Mobility Type (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 6) + ") - This field must be filled with Student studies/Student traineeship/Staff teacher/Staff training."); 
            }

            c.languages.map((lang, idx) => {
                if (lang.cefrLevel !== "A1" && lang.cefrLevel !== "A2" && lang.cefrLevel !== "B1" && lang.cefrLevel !== "B2" && lang.cefrLevel !== "C1" && lang.cefrLevel !== "C2" && lang.cefrLevel !== "") {
                    missingFields.push("Recommended Language Skill (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 11) + ") - The group " + parseInt((idx/4)+1) + " has a invalid CEFR level. The only possible values are A1, A2, B1, B2, C1 and C2.");
                }
                if (lang.iscedFCode === "" && lang.clarification !== "") {
                    missingFields.push("Subject Area of languages skills (agreement " + iiaNum + ", condition " + condNum + ", column " + parseInt(columnIndex + 11) + ") - The group " + parseInt((idx/4)+1) + " cannot have a clarification without a code.");
                }
            }); 
        });

        return callback(missingFields)
    }


    submitIIA(currentIIA, index) {
        let creatorOUCode = "";
        let partnerOUCode = "";

        this.getCreatorOUList(currentIIA.partnerDetails.creatorErasmusCode, (creatorOUList) => {
            this.getPartnerOUList(currentIIA.partnerDetails.partnerErasmusCode, (partnerOUList) => {
                creatorOUList.map(ounit => {
                    if (ounit.name === currentIIA.partnerDetails.creatorOUnit) { creatorOUCode = ounit.ounit_id; return; }
                });

                partnerOUList.map(ounit => {
                    if (ounit.name === currentIIA.partnerDetails.partnerOUnit) { partnerOUCode = ounit.ounit_id; return; }
                });

                let ccsTosend = [];
                currentIIA.conditions.map((c) => {
                    let sendingOUCode = "";
                    let receivingOUCode = "";

                    if (c.senderInstitution === currentIIA.partnerDetails.creatorErasmusCode) {
                        creatorOUList.map(ounit => {
                            if (ounit.name === c.sendingOUnit) { sendingOUCode = ounit.ounit_id; return; }
                        });

                        partnerOUList.map(ounit => {
                            if (ounit.name === c.receivingOUnit) { receivingOUCode = ounit.ounit_id; return; }
                        });
                    }
                    else {
                        creatorOUList.map(ounit => {
                            if (ounit.name === c.receivingOUnit) { receivingOUCode = ounit.ounit_id; return; }
                        });

                        partnerOUList.map(ounit => {
                            if (ounit.name === c.sendingOUnit) { sendingOUCode = ounit.ounit_id; return; }
                        });
                    }

                    let cEqfLevels = [];
                    c.eqfLevels.map((eqfLevel) => {
                        cEqfLevels.push({ eqfLevel: parseInt(eqfLevel.replace(/EQF /g, "")) });
                    });

                    ccsTosend.push({
                        senderInstitution: c.senderInstitution,
                        receiverInstitution: c.receiverInstitution,
                        sendingOUnit: sendingOUCode,
                        receivingOUnit: receivingOUCode,
                        mobilitiesPerYear: parseInt(c.mobilitiesPerYear) || 0,
                        mobilityType: c.mobilityType || c.mobilityTypeSelected,
                        totalDays: parseFloat(c.totalDays) || 0,
                        totalMonths: parseFloat(c.totalMonths) || 0,
                        iscedFCodes: c.iscedFCodes,
                        eqfLevels: cEqfLevels,
                        languages: c.languages,
                        blended: c.blended != "" ? parseInt(c.blended) : 0,
                        otherInformation: c.otherInformation
                    });
                });

                // let tOfComm = "";
                // if (this.state.creatorEWPStatus === 1 && this.state.partnerEWPStatus === 1) { 
                //     tOfComm = "EWP"; 
                // } 
                // else if ((this.state.creatorEWPStatus === 2 && this.state.partnerEWPStatus === 1 ) || (this.state.creatorEWPStatus === 1 && this.state.partnerEWPStatus === 2)
                //         || (this.state.creatorEWPStatus === 2 && this.state.partnerEWPStatus === 2)) { 
                //     tOfComm = "Dash"; 
                // }

                let toSend = {
                    'conditionsIn': ccsTosend,
                    'creatorIn': {
                        name: currentIIA.partnerDetails.creatorInstitution,
                        erasmusCode: GlobalVar.encryptWithKey(currentIIA.partnerDetails.creatorErasmusCode).toString(),
                        country: currentIIA.partnerDetails.creatorCountry,
                        city: currentIIA.partnerDetails.creatorCity,
                        contact: {
                            name: currentIIA.partnerDetails.creatorContactName,
                            email: currentIIA.partnerDetails.creatorContactEmail,
                            phone: currentIIA.partnerDetails.creatorContactPhone

                        },
                        website: {
                            general: currentIIA.partnerDetails.creatorGeneralWebsite,
                            faculty: currentIIA.partnerDetails.creatorFacultyWebsite,
                            course: currentIIA.partnerDetails.creatorCourseWebsite
                        },
                        ounit: creatorOUCode,
                    },
                    'partnerIn': {
                        name: currentIIA.partnerDetails.partnerInstitution,
                        erasmusCode: currentIIA.partnerDetails.partnerErasmusCode,
                        country: currentIIA.partnerDetails.partnerCountry,
                        city: currentIIA.partnerDetails.partnerCity,
                        contact: {
                            name: currentIIA.partnerDetails.partnerContactName,
                            email: currentIIA.partnerDetails.partnerContactEmail,
                            phone: currentIIA.partnerDetails.partnerContactPhone
                        },
                        website: {
                            general: currentIIA.partnerDetails.partnerGeneralWebsite,
                            faculty: currentIIA.partnerDetails.partnerFacultyWebsite,
                            course: currentIIA.partnerDetails.partnerCourseWebsite
                        },
                        ounit: partnerOUCode,
                    },
                    'iiaCode': currentIIA.partnerDetails.iiaCode,
                    'academicYearStartIn': currentIIA.partnerDetails.academicYearStart,
                    'academicYearEndIn': currentIIA.partnerDetails.academicYearEnd,
                    'currentSign': GlobalVar.decryptWithKey(localStorage.getItem("Email")),
                    'typeOfCommunication': (this.state.partnerEWPStatus === 2) ? "Dash" : "EWP"
                }

                console.log("Sending this to Backend: ", JSON.stringify(toSend));
                this.props["CreateIIAm"]({
                    variables: toSend
                }).then((x) => {
                    if (!this.agreementsImported.includes(x.data.createIIAv2.iia_code_p1)) {
                        this.sendNotificationFunction(currentIIA.partnerDetails);
                        this.agreementsImported.push(x.data.createIIAv2.iia_code_p1);
                        console.log("Submitting IIA " + x.data.createIIAv2.iia_code_p1 + "...\nResults: " + JSON.stringify(x));
                        this.setState({ uploadedAgreements: this.state.uploadedAgreements + 1 });
                        console.log("Agreement " + this.state.uploadedAgreements + " of " + this.state.totalAgreements + " imported.");
                        let iiaSubmited = JSON.parse(sessionStorage.getItem("iiaSubmited"));
                        iiaSubmited[index] = true;
                        sessionStorage.setItem("iiaSubmited", JSON.stringify(iiaSubmited));
                    }
                });
            });
        });
    }

    sendNotificationFunction(creatingIIA) {

        console.log("Importing all IIAs")
        console.log(creatingIIA)

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": GlobalVar.encryptWithKey(creatingIIA.creatorErasmusCode).toString(),
            },
        }).then((creatorSuccess) => {
            this.props.isIIAManagedByEWP({
                variables: {
                    "ErasmusCode": creatingIIA.partnerErasmusCode,
                },
            }).then((partnerSuccess) => {
                if (creatorSuccess.data.error) {
                    if (partnerSuccess.data.error) {
                        // alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // alert("The creator will not receive any notification and the parter will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        // alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        // alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        // alert("None of the partners will receive a notification regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 1) {
                    if (partnerSuccess.data.error) {
                        // alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // SHOULD NOT ENTER HERE
                        // If one of the partners is managed by an EWP node, the other one must be managed by Dashboard to edit it
                        // So in this case, the creator is already managed by an EWP node
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        // alert("The creator will receive a notification in their system and the partner will receive an email notification regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        // alert("Only the creator will receive a notification regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 2) {
                    if (partnerSuccess.data.error) {
                        // alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // alert("The creator will receive an email notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 1);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        // alert("Both partners will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 2);
                    }
                    else {
                        // alert("Only the creator will receive an email notification regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                }
                else {
                    if (partnerSuccess.data.error) {
                        // alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // alert("The creator will not receive any notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        // alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        // alert("None of the partners will receive a notification regarding this agreement.");
                        // alert("IIA data submited.");
                    }
                }
            });
        });
    }

    sendMailFunction(data, sendEmailTo) {
        const title = "New agreement created";
        const text = "A new agreement was created where you are one of the partners. You can see it in the Dashboard ('IIA List')."
        const action_url = GlobalVar.getServerLink();

        if (data.creatorContactEmail != null || data.creatorContactEmail != "") {
            // send to creator only
            if (sendEmailTo == 1) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                    receiver: data.creatorContactEmail,
                    institution_name: data.creatorInstitution,
                    institution_ecode: data.creatorErasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }

            // send to both partners
            if (sendEmailTo == 2) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                    receiver: data.creatorContactEmail,
                    institution_name: data.creatorInstitution,
                    institution_ecode: data.creatorErasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
                axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                    receiver: data.partnerContactEmail,
                    institution_name: data.creatorInstitution,
                    institution_ecode: data.creatorErasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }

            // send to partner only
            if (sendEmailTo == 3) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                    receiver: data.partnerContactEmail,
                    institution_name: data.creatorInstitution,
                    institution_ecode: data.creatorErasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }
        }
    }

    sendEWPNotification(creatingIIA){
        this.props.sendEWPIIANotification({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(creatingIIA.creatorIn.erasmusCode).toString(),
                "PartnerErasmusCode": creatingIIA.partnerIn.erasmusCode,
                "IIAID": creatingIIA.iiaCode
            },
        }).then((x) => {
            if (x.data.error) {
                alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
            } else {
                if(x.data.sendEWPIIANotification.Success){
                    // alert("Notification sent successfully to the partner.");
                } else {
                    alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                }
            }
        });
    }

    // getCreatorECWithSpace(erasmusCode, callback) {
    //     return this.props.checkCreatorEC({
    //         variables: {
    //             "ErasmusCode": GlobalVar.encryptWithKey(erasmusCode).toString(),
    //         },
    //     })
    //     .then((x) => {
    //         if (x.data == null) {
    //             console.log("An error has occurred while checking Erasmus Code.");
    //             return callback("");
    //         } else {
    //             console.log("The Erasmus Code with space successfully obtained: " + JSON.stringify(x));
    //         }
    //         return callback(x.data.checkCreatorEC.ErasmusCode);
    //     })
    //     .catch((x) => {
    //         console.log("An error has occurred while checking Erasmus Code.");
    //         return callback("");
    //     });
    // }

    getPartnerECWithSpace(erasmusCode, callback) {
        return this.props.checkPartnerEC({
            variables: {
                "ErasmusCode": erasmusCode,
            },
        })
        .then((x) => {
            if (x.data == null) {
                console.log("An error has occurred while checking Erasmus Code.");
                return callback("");
            } else {
                console.log("The Erasmus Code with space successfully obtained: " + JSON.stringify(x));
            }
            return callback(x.data.checkPartnerEC.ErasmusCode);
        })
        .catch((x) => {
            console.log("An error has occurred while checking Erasmus Code.");
            return callback("");
        });
    }

    getInstitutionData(erasmusCode, callback) {
        if (erasmusCode !== "") {
            erasmusCode = (erasmusCode.replace(/\s+/g, "") === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode) ? GlobalVar.encryptWithKey(erasmusCode).toString() : erasmusCode;

            return this.props.getInstitutionData({
                variables: { "erasmusCode": erasmusCode }
            })
            .then((res) => {
                if (res.data == null || res.data.getIIAmInstitution == null) {
                    console.log("An error has occurred while getting Institution data.");
                    return callback(null);
                } else {
                    console.log("The Institution data was obtained successfully: " + JSON.stringify(res));
                }
                return callback(res.data);
            })
            .catch(() => {
                return callback("");
            })
        }
        return callback(null)
    }

    getCreatorOUList(erasmusCode, callback) {
        let creatorOUList = [];
        if (erasmusCode != "") {
            this.checkPartnerInEWP(erasmusCode, (partnerEWPStatus) => {
                this.setState({ creatorEWPStatus: partnerEWPStatus });
                if (partnerEWPStatus == 2 || partnerEWPStatus == -1) {
                    this.props.listOU({
                        variables: {
                            "ErasmusCodeIn": GlobalVar.encryptWithKey(erasmusCode).toString()
                        }
                    }).then((x) => {
                        if (x.data.error) {
                            console.log("Error getting Organizational Units");
                        } else {
                            if (x.data.listOU) {
                                console.log("Successfully retrieved Organizational Units");
                                creatorOUList = x.data.listOU;
                            } else {
                                console.log("Error getting Organizational Units");
                            }
                        }
                        return callback(creatorOUList);
                    });
                } else {
                    creatorOUList = [];
                    return callback(creatorOUList);
                }
                
            });
        } else {
            creatorOUList = [];
            return callback(creatorOUList);
        }
    }

    getPartnerOUList(erasmusCode, callback) {
        let partnerOUList = [];

        if (erasmusCode != "") {
            this.checkPartnerInEWP(erasmusCode, (partnerEWPStatus) => {
                this.setState({ partnerEWPStatus: partnerEWPStatus });
                if (partnerEWPStatus == 2 || partnerEWPStatus == -1) {
                    this.props.listOU({
                        variables: {
                            "ErasmusCodeIn": erasmusCode
                        }
                    }).then((x) => {
                        if (x.data.error) {
                            console.log("Error getting Organizational Units");
                        } else {
                            if (x.data.listOU) {
                                console.log("Successfully retrieved Organizational Units");
                                partnerOUList = x.data.listOU;
                            } else {
                                console.log("Error getting Organizational Units");
                            }
                        }
                        return callback(partnerOUList);
                    });
                } else {
                    partnerOUList = [];
                    return callback(partnerOUList);
                }
            });
        } else {
            partnerOUList = [];
            return callback(partnerOUList);
        }
    }

    checkPartnerInEWP(erasmusCode, callback) {
        let partnerEWPStatus = 0;

        erasmusCode = (erasmusCode.replace(/\s+/g, "") === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode) ? GlobalVar.encryptWithKey(erasmusCode).toString() : erasmusCode;
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": erasmusCode
            },
        }).then((x) => {
            if (x.data.error) {
                partnerEWPStatus = 0;
            } else {
                console.log("(checkPartnerInEWP) Is partner in EWP?");
                console.log(x.data);
                switch(x.data.isIIAManagedByEWP.Success){
                    case 1:
                        //By a node in EWP
                        partnerEWPStatus = 1;
                        break;
                    case 2:
                        //By Dashboard
                        partnerEWPStatus = 2;
                        break;
                    case -1:
                        //Not in EWP
                        partnerEWPStatus = -1;
                        break;
                    default:
                        //Some error
                        partnerEWPStatus = 0;
                        break;
                }
            }
            return callback(partnerEWPStatus);
        });
    }

    setDate() {
        let year = new Date().getUTCFullYear();
        let month = new Date().getUTCMonth() + 1;
        let day = new Date().getUTCDate();
        let hours = new Date().getUTCHours();
        let minutes = new Date().getUTCMinutes();
        let seconds = new Date().getUTCSeconds();

        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }

        let fullUTCDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return fullUTCDate;
    }
    
    setIIACode(erasmusCode) {
        let currentDate = Date.now() + "";
        return erasmusCode.replace(/[\s-]/g, "") + "-" + currentDate.replace(/[\s-]/g, "");
    }

    refreshPage() {
        this.setState({
            reloadActions: !this.state.reloadActions
        });
    }
}

const mutation = gql`
    mutation CreateIIAm($conditionsIn: [CooperationInput]!, $creatorIn: InstituteInput!, $partnerIn:InstituteInput!, $iiaCode: String!, $academicYearStartIn: String!, $academicYearEndIn: String!, $currentSign: String!, $typeOfCommunication: String){
        createIIAv2(conditions: $conditionsIn, creator: $creatorIn, partner: $partnerIn, iiaCode: $iiaCode, academicYearStart: $academicYearStartIn, academicYearEnd: $academicYearEndIn, currentSign: $currentSign, typeOfCommunication: $typeOfCommunication) {
            iia_code_p1
        }
    }
`;

const checkCreatorEC = gqlM`
    mutation checkCreatorEC($ErasmusCode: String!) {
        checkCreatorEC(ErasmusCode: $ErasmusCode) {
            ErasmusCode
        }
    }
`;

const checkPartnerEC = gqlM`
    mutation checkPartnerEC($ErasmusCode: String!) {
        checkPartnerEC(ErasmusCode: $ErasmusCode) {
            ErasmusCode
        }
    }
`;

const getOunitCode = gqlM`
    mutation getOunitCode($ErasmusCode: String!, $OunitName: String!) {
        getOunitCode(ErasmusCode: $ErasmusCode, OunitName: $OunitName) {
            ounit_id
        }
    }
`;

const isIIAManagedByEWP = gqlM`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const isIIAManagedByEWPQuery = gqlM`
    query isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const sendEWPIIANotification = gqlM`
    mutation sendEWPIIANotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!) {
        sendEWPIIANotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID) {
            Success
            Message
        }
    }
`;

const getInstitutionData = gql`
    mutation getInstitutionData($erasmusCode: String!) {
        getIIAmInstitution(erasmusCode: $erasmusCode) {
            NameInLatinCharacterSet
            HeiID
            ErasmusCode
            CountryName
            CountryISO
            CityName
            PostalCode
        }
    }
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

const listOU = gql`
    mutation listOU($ErasmusCodeIn: String!) {
        listOU(ErasmusCodeIn: $ErasmusCodeIn) {
            ounit_id
            institution_ecode
            abbreviation
            is_ewp_partner
            active
            lang
            name
        }
    }
`;

export default compose(
    graphql(getLanguages, { name: "allLanguages" }),
    graphql(mutation, { name: "CreateIIAm" }),
    graphql(checkCreatorEC, { name: "checkCreatorEC" }),
    graphql(checkPartnerEC, { name: "checkPartnerEC" }),
    graphql(getInstitutionData, { name: "getInstitutionData" }),
    graphql(getIscedFCodesList, {name: "getIscedFCodesList"}),
    graphql(isIIAManagedByEWP, {name: "isIIAManagedByEWP"}),
    graphql(isIIAManagedByEWPQuery, 
        {name: "isIIAManagedByEWPLocal",
        options: (props) => {
            return {
                variables: {
                  ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
      }),
    graphql(listOU, {name: "listOU"})
)(ImportIIA);