const EQFvalues = [
    {
        "id": 1625,
        "code": "EQF-5",
        "title": "Associate degree"
    },
    {
        "id": 1626,
        "code": "EQF-6",
        "title": "Bachelor's degree"
    },
    {
        "id": 1627,
        "code": "EQF-7",
        "title": "Master's degree"
    },
    {
        "id": 1628,
        "code": "EQF-8",
        "title": "Doctorate degree"
    },
    {
        "id": 1629,
        "code": "EQF-9",
        "title": "Other"
    }
]

const CEFRvalues = [
    {
        "id": 1619,
        "code": "A1",
        "title": "Breakthrough or beginner"
    },
    {
        "id": 1620,
        "code": "A2",
        "title": "Waystage or elementary"
    },
    {
        "id": 1621,
        "code": "B1",
        "title": "Threshold or intermediate"
    },
    {
        "id": 1622,
        "code": "B2",
        "title": "Vantage or upper intermediate"
    },
    {
        "id": 1623,
        "code": "C1",
        "title": "Effective operational proficiency or advanced"
    },
    {
        "id": 1624,
        "code": "C2",
        "title": "Mastery or proficiency"
    }
]

const ISCEDvalues = [
    {
        "id": 1630,
        "title": "Education",
        "code": "01"
    },
    {
        "id": 1631,
        "title": "Education",
        "code": "011"
    },
    {
        "id": 1632,
        "title": "Education, not further defined",
        "code": "0110"
    },
    {
        "id": 1633,
        "title": "Education science",
        "code": "0111"
    },
    {
        "id": 1634,
        "title": "Training for pre-school teachers",
        "code": "0112"
    },
    {
        "id": 1635,
        "title": "Teacher training without subject specialization",
        "code": "0113"
    },
    {
        "id": 1636,
        "title": "Teacher training with subject specialization",
        "code": "0114"
    },
    {
        "id": 1637,
        "title": "Education, not elsewhere classified",
        "code": "0119"
    },
    {
        "id": 1638,
        "title": "Education, inter-disciplinary programmes",
        "code": "0118"
    },
    {
        "id": 1639,
        "title": "Arts and humanities",
        "code": "02"
    },
    {
        "id": 1640,
        "title": "Arts",
        "code": "021"
    },
    {
        "id": 1641,
        "title": "Arts, not further defined",
        "code": "0210"
    },
    {
        "id": 1642,
        "title": "Audio-visual techniques and media production",
        "code": "0211"
    },
    {
        "id": 1643,
        "title": "Fashion, interior and industrial design",
        "code": "0212"
    },
    {
        "id": 1644,
        "title": "Fine arts",
        "code": "0213"
    },
    {
        "id": 1645,
        "title": "Handicrafts",
        "code": "0214"
    },
    {
        "id": 1646,
        "title": "Music and performing arts",
        "code": "0215"
    },
    {
        "id": 1647,
        "title": "Arts, not elsewhere classified",
        "code": "0219"
    },
    {
        "id": 1648,
        "title": "Humanities (except languages)",
        "code": "022"
    },
    {
        "id": 1649,
        "title": "Humanities (except languages), not further defined",
        "code": "0220"
    },
    {
        "id": 1650,
        "title": "Religion and theology",
        "code": "0221"
    },
    {
        "id": 1651,
        "title": "History and archaeology",
        "code": "0222"
    },
    {
        "id": 1652,
        "title": "Philosophy and ethics",
        "code": "0223"
    },
    {
        "id": 1653,
        "title": "Humanities (except languages), not elsewhere classified",
        "code": "0229"
    },
    {
        "id": 1654,
        "title": "Languages",
        "code": "023"
    },
    {
        "id": 1655,
        "title": "Languages, not further defined",
        "code": "0230"
    },
    {
        "id": 1656,
        "title": "Language acquisition",
        "code": "0231"
    },
    {
        "id": 1657,
        "title": "Literature and linguistics",
        "code": "0232"
    },
    {
        "id": 1658,
        "title": "Languages, not elsewhere classified",
        "code": "0239"
    },
    {
        "id": 1659,
        "title": "Arts and humanities, inter-disciplinary programmes",
        "code": "0238"
    },
    {
        "id": 1660,
        "title": "Social sciences, journalism and information",
        "code": "03"
    },
    {
        "id": 1661,
        "title": "Social and behavioural sciences",
        "code": "031"
    },
    {
        "id": 1662,
        "title": "Social and behavioural sciences, not further defined",
        "code": "0310"
    },
    {
        "id": 1663,
        "title": "Economics",
        "code": "0311"
    },
    {
        "id": 1664,
        "title": "Political sciences and civics",
        "code": "0312"
    },
    {
        "id": 1665,
        "title": "Psychology",
        "code": "0313"
    },
    {
        "id": 1666,
        "title": "Sociology and cultural studies",
        "code": "0314"
    },
    {
        "id": 1667,
        "title": "Social and behavioural sciences, not elsewhere classified",
        "code": "0319"
    },
    {
        "id": 1668,
        "title": "Journalism and information",
        "code": "032"
    },
    {
        "id": 1669,
        "title": "Journalism and information, not further defined",
        "code": "0320"
    },
    {
        "id": 1670,
        "title": "Journalism and reporting",
        "code": "0321"
    },
    {
        "id": 1671,
        "title": "Library, information and archival studies",
        "code": "0322"
    },
    {
        "id": 1672,
        "title": "Journalism and information, not elsewhere classified",
        "code": "0329"
    },
    {
        "id": 1673,
        "title": "Social sciences, journalism and information, inter-disciplinary programmes",
        "code": "0328"
    },
    {
        "id": 1674,
        "title": "Business, administration and law",
        "code": "04"
    },
    {
        "id": 1675,
        "title": "Business and administration",
        "code": "041"
    },
    {
        "id": 1676,
        "title": "Business and administration, not further defined",
        "code": "0410"
    },
    {
        "id": 1677,
        "title": "Accounting and taxation",
        "code": "0411"
    },
    {
        "id": 1678,
        "title": "Finance, banking and insurance",
        "code": "0412"
    },
    {
        "id": 1679,
        "title": "Management and administration",
        "code": "0413"
    },
    {
        "id": 1680,
        "title": "Marketing and advertising",
        "code": "0414"
    },
    {
        "id": 1681,
        "title": "Secretarial and office work",
        "code": "0415"
    },
    {
        "id": 1682,
        "title": "Wholesale and retail sales",
        "code": "0416"
    },
    {
        "id": 1683,
        "title": "Work skills",
        "code": "0417"
    },
    {
        "id": 1684,
        "title": "Business and administration, not elsewhere classified",
        "code": "0419"
    },
    {
        "id": 1685,
        "title": "Law",
        "code": "042"
    },
    {
        "id": 1686,
        "title": "Law",
        "code": "0421"
    },
    {
        "id": 1687,
        "title": "Law, not elsewhere classified",
        "code": "0429"
    },
    {
        "id": 1688,
        "title": "Business, administration and law, inter-disciplinary programmes",
        "code": "0428"
    },
    {
        "id": 1689,
        "title": "Natural sciences, mathematics and statistics",
        "code": "05"
    },
    {
        "id": 1690,
        "title": "Biological and related sciences",
        "code": "051"
    },
    {
        "id": 1691,
        "title": "Biological and related sciences, not further defined",
        "code": "0510"
    },
    {
        "id": 1692,
        "title": "Biology",
        "code": "0511"
    },
    {
        "id": 1693,
        "title": "Biochemistry",
        "code": "0512"
    },
    {
        "id": 1694,
        "title": "Biological and related sciences, not elsewhere classifed",
        "code": "0519"
    },
    {
        "id": 1695,
        "title": "Environment",
        "code": "052"
    },
    {
        "id": 1696,
        "title": "Environment, not further defined",
        "code": "0520"
    },
    {
        "id": 1697,
        "title": "Environmental sciences",
        "code": "0521"
    },
    {
        "id": 1698,
        "title": "Natural environments and wildlife",
        "code": "0522"
    },
    {
        "id": 1699,
        "title": "Environment, not elsewhere classified",
        "code": "0529"
    },
    {
        "id": 1700,
        "title": "Physical sciences",
        "code": "053"
    },
    {
        "id": 1701,
        "title": "Physical sciences, not further defined",
        "code": "0530"
    },
    {
        "id": 1702,
        "title": "Chemistry",
        "code": "0531"
    },
    {
        "id": 1703,
        "title": "Earth sciences",
        "code": "0532"
    },
    {
        "id": 1704,
        "title": "Physics",
        "code": "0533"
    },
    {
        "id": 1705,
        "title": "Physical sciences, not elsewhere classified",
        "code": "0539"
    },
    {
        "id": 1706,
        "title": "Mathematics and statistics",
        "code": "054"
    },
    {
        "id": 1707,
        "title": "Mathematics and statistics, not further defined",
        "code": "0540"
    },
    {
        "id": 1708,
        "title": "Mathematics",
        "code": "0541"
    },
    {
        "id": 1709,
        "title": "Statistics",
        "code": "0542"
    },
    {
        "id": 1710,
        "title": "Mathematics and statistics, not elsewhere classified",
        "code": "0549"
    },
    {
        "id": 1711,
        "title": "Natural sciences, mathematics and statistics, inter-disciplinary programmes",
        "code": "0548"
    },
    {
        "id": 1712,
        "title": "Information and Communication Technologies (ICTs)",
        "code": "06"
    },
    {
        "id": 1713,
        "title": "Information and Communication Technologies (ICTs)",
        "code": "061"
    },
    {
        "id": 1714,
        "title": "Information and Communication Technologies (ICTs), not further defined",
        "code": "0610"
    },
    {
        "id": 1715,
        "title": "Computer use",
        "code": "0611"
    },
    {
        "id": 1716,
        "title": "Database and network design and administration",
        "code": "0612"
    },
    {
        "id": 1717,
        "title": "Software and applications development and analysis",
        "code": "0613"
    },
    {
        "id": 1718,
        "title": "Information and Communication Technologies (ICTs), not elsewhere classified",
        "code": "0619"
    },
    {
        "id": 1719,
        "title": "Information and Communication Technologies (ICTs), inter-disciplinary programmes",
        "code": "0618"
    },
    {
        "id": 1720,
        "title": "Engineering, manufacturing and construction",
        "code": "07"
    },
    {
        "id": 1721,
        "title": "Engineering and engineering trades",
        "code": "071"
    },
    {
        "id": 1722,
        "title": "Engineering and engineering trades, not further defined",
        "code": "0710"
    },
    {
        "id": 1723,
        "title": "Chemical engineering and processes",
        "code": "0711"
    },
    {
        "id": 1724,
        "title": "Environmental protection technology",
        "code": "0712"
    },
    {
        "id": 1725,
        "title": "Electricity and energy",
        "code": "0713"
    },
    {
        "id": 1726,
        "title": "Electronics and automation",
        "code": "0714"
    },
    {
        "id": 1727,
        "title": "Mechanics and metal trades",
        "code": "0715"
    },
    {
        "id": 1728,
        "title": "Motor vehicles, ships and aircraft",
        "code": "0716"
    },
    {
        "id": 1729,
        "title": "Engineering and engineering trades, not elsewhere classified",
        "code": "0719"
    },
    {
        "id": 1730,
        "title": "Manufacturing and processing",
        "code": "072"
    },
    {
        "id": 1731,
        "title": "Manufacturing and processing, not further defined",
        "code": "0720"
    },
    {
        "id": 1732,
        "title": "Food processing",
        "code": "0721"
    },
    {
        "id": 1733,
        "title": "Materials (glass, paper, plastic and wood)",
        "code": "0722"
    },
    {
        "id": 1734,
        "title": "Textiles (clothes, footwear and leather)",
        "code": "0723"
    },
    {
        "id": 1735,
        "title": "Mining and extraction",
        "code": "0724"
    },
    {
        "id": 1736,
        "title": "Manufacturing and processing, not elsewhere classified",
        "code": "0729"
    },
    {
        "id": 1737,
        "title": "Architecture and construction",
        "code": "073"
    },
    {
        "id": 1738,
        "title": "Architecture and construction, not further defined",
        "code": "0730"
    },
    {
        "id": 1739,
        "title": "Architecture and town planning",
        "code": "0731"
    },
    {
        "id": 1740,
        "title": "Building and civil engineering",
        "code": "0732"
    },
    {
        "id": 1741,
        "title": "Architecture and construction, not elsewhere classified",
        "code": "0739"
    },
    {
        "id": 1742,
        "title": "Engineering, manufacturing and construction, inter-disciplinary programmes",
        "code": "0738"
    },
    {
        "id": 1743,
        "title": "Agriculture, forestry, fisheries and veterinary",
        "code": "08"
    },
    {
        "id": 1744,
        "title": "Agriculture",
        "code": "081"
    },
    {
        "id": 1745,
        "title": "Agriculture, not further defined",
        "code": "0810"
    },
    {
        "id": 1746,
        "title": "Crop and livestock production",
        "code": "0811"
    },
    {
        "id": 1747,
        "title": "Horticulture",
        "code": "0812"
    },
    {
        "id": 1748,
        "title": "Agriculture, not elsewhere classified",
        "code": "0819"
    },
    {
        "id": 1749,
        "title": "Forestry",
        "code": "082"
    },
    {
        "id": 1750,
        "title": "Forestry",
        "code": "0821"
    },
    {
        "id": 1751,
        "title": "Forestry, not elsewhere classified",
        "code": "0829"
    },
    {
        "id": 1752,
        "title": "Fisheries",
        "code": "083"
    },
    {
        "id": 1753,
        "title": "Fisheries",
        "code": "0831"
    },
    {
        "id": 1754,
        "title": "Fisheries, not elsewhere classified",
        "code": "0839"
    },
    {
        "id": 1755,
        "title": "Veterinary",
        "code": "084"
    },
    {
        "id": 1756,
        "title": "Veterinary",
        "code": "0841"
    },
    {
        "id": 1757,
        "title": "Veterinary, not elsewhere classified",
        "code": "0849"
    },
    {
        "id": 1758,
        "title": "Agriculture, forestry, fisheries, veterinary, inter-disciplinary programmes",
        "code": "0848"
    },
    {
        "id": 1759,
        "title": "Health and welfare",
        "code": "09"
    },
    {
        "id": 1760,
        "title": "Health",
        "code": "091"
    },
    {
        "id": 1761,
        "title": "Health, not further defined",
        "code": "0910"
    },
    {
        "id": 1762,
        "title": "Dental studies",
        "code": "0911"
    },
    {
        "id": 1763,
        "title": "Medicine",
        "code": "0912"
    },
    {
        "id": 1764,
        "title": "Nursing and midwifery",
        "code": "0913"
    },
    {
        "id": 1765,
        "title": "Medical diagnostic and treatment technology",
        "code": "0914"
    },
    {
        "id": 1766,
        "title": "Therapy and rehabilitation",
        "code": "0915"
    },
    {
        "id": 1767,
        "title": "Pharmacy",
        "code": "0916"
    },
    {
        "id": 1768,
        "title": "Traditional and complementary medicine and therapy",
        "code": "0917"
    },
    {
        "id": 1769,
        "title": "Health, not elsewhere classified",
        "code": "0919"
    },
    {
        "id": 1770,
        "title": "Welfare",
        "code": "092"
    },
    {
        "id": 1771,
        "title": "Welfare, not further defined",
        "code": "0920"
    },
    {
        "id": 1772,
        "title": "Care of the elderly and of disabled adults",
        "code": "0921"
    },
    {
        "id": 1773,
        "title": "Child care and youth services",
        "code": "0922"
    },
    {
        "id": 1774,
        "title": "Social work and counselling",
        "code": "0923"
    },
    {
        "id": 1775,
        "title": "Welfare, not elsewhere classified",
        "code": "0929"
    },
    {
        "id": 1776,
        "title": "Health and Welfare, inter-disciplinary programmes",
        "code": "0928"
    },
    {
        "id": 1777,
        "title": "Services",
        "code": "10"
    },
    {
        "id": 1778,
        "title": "Personal services",
        "code": "101"
    },
    {
        "id": 1779,
        "title": "Personal services, not further defined",
        "code": "1010"
    },
    {
        "id": 1780,
        "title": "Domestic services",
        "code": "1011"
    },
    {
        "id": 1781,
        "title": "Hair and beauty services",
        "code": "1012"
    },
    {
        "id": 1782,
        "title": "Hotel, restaurants and catering",
        "code": "1013"
    },
    {
        "id": 1783,
        "title": "Sports",
        "code": "1014"
    },
    {
        "id": 1784,
        "title": "Travel, tourism and leisure",
        "code": "1015"
    },
    {
        "id": 1785,
        "title": "Personal services, not elsewhere classified",
        "code": "1019"
    },
    {
        "id": 1786,
        "title": "Hygiene and occupational health services",
        "code": "102"
    },
    {
        "id": 1787,
        "title": "Hygiene and occupational health services, not further defined",
        "code": "1020"
    },
    {
        "id": 1788,
        "title": "Community sanitation",
        "code": "1021"
    },
    {
        "id": 1789,
        "title": "Occupational health and safety",
        "code": "1022"
    },
    {
        "id": 1790,
        "title": "Hygiene and occupational health services, not elsewhere classified",
        "code": "1029"
    },
    {
        "id": 1791,
        "title": "Security services",
        "code": "103"
    },
    {
        "id": 1792,
        "title": "Security services, not further defined",
        "code": "1030"
    },
    {
        "id": 1793,
        "title": "Military and defence",
        "code": "1031"
    },
    {
        "id": 1794,
        "title": "Protection of persons and property",
        "code": "1032"
    },
    {
        "id": 1795,
        "title": "Security services, not elsewhere classified",
        "code": "1039"
    },
    {
        "id": 1796,
        "title": "Transport services",
        "code": "104"
    },
    {
        "id": 1797,
        "title": "Transport services",
        "code": "1041"
    },
    {
        "id": 1798,
        "title": "Transport services, not elsewhere classified",
        "code": "1049"
    },
    {
        "id": 1799,
        "title": "Services, inter-disciplinary programmes",
        "code": "1048"
    }
]

const languagesvalues = [
   {
      "id":1437,
      "title":"Bulgarian",
      "european":true,
      "code":"BG"
   },
   {
      "id":1438,
      "title":"Czech",
      "european":true,
      "code":"CS"
   },
   {
      "id":1439,
      "title":"Danish",
      "european":true,
      "code":"DA"
   },
   {
      "id":1440,
      "title":"German",
      "european":true,
      "code":"DE"
   },
   {
      "id":1441,
      "title":"Greek",
      "european":true,
      "code":"EL"
   },
   {
      "id":1442,
      "title":"English",
      "european":true,
      "code":"EN"
   },
   {
      "id":1443,
      "title":"Spanish",
      "european":true,
      "code":"ES"
   },
   {
      "id":1444,
      "title":"Estonian",
      "european":true,
      "code":"ET"
   },
   {
      "id":1445,
      "title":"Finnish",
      "european":true,
      "code":"FI"
   },
   {
      "id":1446,
      "title":"French",
      "european":true,
      "code":"FR"
   },
   {
      "id":1447,
      "title":"Irish",
      "european":true,
      "code":"GA"
   },
   {
      "id":1448,
      "title":"Hungarian",
      "european":true,
      "code":"HU"
   },
   {
      "id":1449,
      "title":"Italian",
      "european":true,
      "code":"IT"
   },
   {
      "id":1450,
      "title":"Lithuanian",
      "european":true,
      "code":"LT"
   },
   {
      "id":1451,
      "title":"Latvian",
      "european":true,
      "code":"LV"
   },
   {
      "id":1452,
      "title":"Maltese",
      "european":true,
      "code":"MT"
   },
   {
      "id":1453,
      "title":"Dutch",
      "european":true,
      "code":"NL"
   },
   {
      "id":1454,
      "title":"Polish",
      "european":true,
      "code":"PL"
   },
   {
      "id":1455,
      "title":"Portuguese",
      "european":true,
      "code":"PT"
   },
   {
      "id":1456,
      "title":"Romanian",
      "european":true,
      "code":"RO"
   },
   {
      "id":1457,
      "title":"Slovak",
      "european":true,
      "code":"SK"
   },
   {
      "id":1458,
      "title":"Slovenian",
      "european":true,
      "code":"SL"
   },
   {
      "id":1459,
      "title":"Swedish",
      "european":true,
      "code":"SV"
   },
   {
      "id":1460,
      "title":"Aragonese",
      "european":true,
      "code":"AN"
   },
   {
      "id":1461,
      "title":"Breton",
      "european":true,
      "code":"BR"
   },
   {
      "id":1462,
      "title":"Bosnian",
      "european":true,
      "code":"BS"
   },
   {
      "id":1463,
      "title":"Catalan",
      "european":true,
      "code":"CA"
   },
   {
      "id":1464,
      "title":"Corsican",
      "european":true,
      "code":"CO"
   },
   {
      "id":1465,
      "title":"Welsh",
      "european":true,
      "code":"CY"
   },
   {
      "id":1466,
      "title":"Basque",
      "european":true,
      "code":"EU"
   },
   {
      "id":1467,
      "title":"Faroese",
      "european":true,
      "code":"FO"
   },
   {
      "id":1468,
      "title":"Gaelic (Scots)",
      "european":true,
      "code":"GD"
   },
   {
      "id":1469,
      "title":"Gallegan",
      "european":true,
      "code":"GL"
   },
   {
      "id":1470,
      "title":"Limburgan",
      "european":true,
      "code":"LI"
   },
   {
      "id":1471,
      "title":"Norwegian Bokmael",
      "european":true,
      "code":"NB"
   },
   {
      "id":1472,
      "title":"Norwegian Nynorsk",
      "european":true,
      "code":"NN"
   },
   {
      "id":1473,
      "title":"Sardinian",
      "european":true,
      "code":"SC"
   },
   {
      "id":1474,
      "title":"Walloon",
      "european":true,
      "code":"WA"
   },
   {
      "id":1475,
      "title":"Church Slavic",
      "european":true,
      "code":"CU"
   },
   {
      "id":1476,
      "title":"Occitan (post 1500)",
      "european":true,
      "code":"OC"
   },
   {
      "id":1477,
      "title":"Raeto-Romance",
      "european":true,
      "code":"RM"
   },
   {
      "id":1478,
      "title":"Latin",
      "european":true,
      "code":"LA"
   },
   {
      "id":1479,
      "title":"Croatian",
      "european":true,
      "code":"HR"
   },
   {
      "id":1480,
      "title":"Icelandic",
      "european":true,
      "code":"IS"
   },
   {
      "id":1481,
      "title":"Letzeburgesch",
      "european":true,
      "code":"LB"
   },
   {
      "id":1482,
      "title":"Macedonian",
      "european":true,
      "code":"MK"
   },
   {
      "id":1483,
      "title":"Moldavian",
      "european":true,
      "code":"MO"
   },
   {
      "id":1484,
      "title":"Montenegrin",
      "european":true,
      "code":"ME"
   },
   {
      "id":1485,
      "title":"Norwegian",
      "european":true,
      "code":"NO"
   },
   {
      "id":1486,
      "title":"Russian",
      "european":true,
      "code":"RU"
   },
   {
      "id":1487,
      "title":"Serbo-Croatian",
      "european":true,
      "code":"SH"
   },
   {
      "id":1488,
      "title":"Albanian",
      "european":true,
      "code":"SQ"
   },
   {
      "id":1489,
      "title":"Serbian",
      "european":true,
      "code":"SR"
   },
   {
      "id":1490,
      "title":"Turkish",
      "european":true,
      "code":"TR"
   },
   {
      "id":1491,
      "title":"Ukrainian",
      "european":true,
      "code":"UK"
   },
   {
      "id":1492,
      "title":"Afar",
      "european":false,
      "code":"AA"
   },
   {
      "id":1493,
      "title":"Abkhazian",
      "european":false,
      "code":"AB"
   },
   {
      "id":1494,
      "title":"Avestan",
      "european":false,
      "code":"AE"
   },
   {
      "id":1495,
      "title":"Afrikaans",
      "european":false,
      "code":"AF"
   },
   {
      "id":1496,
      "title":"Akan",
      "european":false,
      "code":"AK"
   },
   {
      "id":1497,
      "title":"Amharic",
      "european":false,
      "code":"AM"
   },
   {
      "id":1498,
      "title":"Arabic",
      "european":false,
      "code":"AR"
   },
   {
      "id":1499,
      "title":"Assamese",
      "european":false,
      "code":"AS"
   },
   {
      "id":1500,
      "title":"Avaric",
      "european":false,
      "code":"AV"
   },
   {
      "id":1501,
      "title":"Aymara",
      "european":false,
      "code":"AY"
   },
   {
      "id":1502,
      "title":"Azerbaijani",
      "european":false,
      "code":"AZ"
   },
   {
      "id":1503,
      "title":"Bashkir",
      "european":false,
      "code":"BA"
   },
   {
      "id":1504,
      "title":"Belarusian",
      "european":false,
      "code":"BE"
   },
   {
      "id":1505,
      "title":"Bihari",
      "european":false,
      "code":"BH"
   },
   {
      "id":1506,
      "title":"Bislama",
      "european":false,
      "code":"BI"
   },
   {
      "id":1507,
      "title":"Bambara",
      "european":false,
      "code":"BM"
   },
   {
      "id":1508,
      "title":"Bengali",
      "european":false,
      "code":"BN"
   },
   {
      "id":1509,
      "title":"Tibetan",
      "european":false,
      "code":"BO"
   },
   {
      "id":1510,
      "title":"Chechen",
      "european":false,
      "code":"CE"
   },
   {
      "id":1511,
      "title":"Chamorro",
      "european":false,
      "code":"CH"
   },
   {
      "id":1512,
      "title":"Cree",
      "european":false,
      "code":"CR"
   },
   {
      "id":1513,
      "title":"Chuvash",
      "european":false,
      "code":"CV"
   },
   {
      "id":1514,
      "title":"Divehi",
      "european":false,
      "code":"DV"
   },
   {
      "id":1515,
      "title":"Dzongkha",
      "european":false,
      "code":"DZ"
   },
   {
      "id":1516,
      "title":"Ewe",
      "european":false,
      "code":"EE"
   },
   {
      "id":1517,
      "title":"Persian",
      "european":false,
      "code":"FA"
   },
   {
      "id":1518,
      "title":"Fulah",
      "european":false,
      "code":"FF"
   },
   {
      "id":1519,
      "title":"Fijian",
      "european":false,
      "code":"FJ"
   },
   {
      "id":1520,
      "title":"Frisian",
      "european":false,
      "code":"FY"
   },
   {
      "id":1521,
      "title":"Guarani",
      "european":false,
      "code":"GN"
   },
   {
      "id":1522,
      "title":"Gujarati",
      "european":false,
      "code":"GU"
   },
   {
      "id":1523,
      "title":"Manx",
      "european":false,
      "code":"GV"
   },
   {
      "id":1524,
      "title":"Hebrew",
      "european":false,
      "code":"HE"
   },
   {
      "id":1525,
      "title":"Hindi",
      "european":false,
      "code":"HI"
   },
   {
      "id":1526,
      "title":"Hiri Motu",
      "european":false,
      "code":"HO"
   },
   {
      "id":1527,
      "title":"Haitian",
      "european":false,
      "code":"HT"
   },
   {
      "id":1528,
      "title":"Armenian",
      "european":false,
      "code":"HY"
   },
   {
      "id":1529,
      "title":"Herero",
      "european":false,
      "code":"HZ"
   },
   {
      "id":1530,
      "title":"Indonesian",
      "european":false,
      "code":"ID"
   },
   {
      "id":1531,
      "title":"Interlingue",
      "european":false,
      "code":"IE"
   },
   {
      "id":1532,
      "title":"Igbo",
      "european":false,
      "code":"IG"
   },
   {
      "id":1533,
      "title":"Sichuan Yi",
      "european":false,
      "code":"II"
   },
   {
      "id":1534,
      "title":"Inupiaq",
      "european":false,
      "code":"IK"
   },
   {
      "id":1535,
      "title":"Ido",
      "european":false,
      "code":"IO"
   },
   {
      "id":1536,
      "title":"Inuktitut",
      "european":false,
      "code":"IU"
   },
   {
      "id":1537,
      "title":"Japanese",
      "european":false,
      "code":"JA"
   },
   {
      "id":1538,
      "title":"Javanese",
      "european":false,
      "code":"JV"
   },
   {
      "id":1539,
      "title":"Georgian",
      "european":false,
      "code":"KA"
   },
   {
      "id":1540,
      "title":"Kongo",
      "european":false,
      "code":"KG"
   },
   {
      "id":1541,
      "title":"Kikuyu",
      "european":false,
      "code":"KI"
   },
   {
      "id":1542,
      "title":"Kuanyama",
      "european":false,
      "code":"KJ"
   },
   {
      "id":1543,
      "title":"Kazakh",
      "european":false,
      "code":"KK"
   },
   {
      "id":1544,
      "title":"Kalaallisut",
      "european":false,
      "code":"KL"
   },
   {
      "id":1545,
      "title":"Khmer",
      "european":false,
      "code":"KM"
   },
   {
      "id":1546,
      "title":"Kannada",
      "european":false,
      "code":"KN"
   },
   {
      "id":1547,
      "title":"Korean",
      "european":false,
      "code":"KO"
   },
   {
      "id":1548,
      "title":"Kanuri",
      "european":false,
      "code":"KR"
   },
   {
      "id":1549,
      "title":"Kashmiri",
      "european":false,
      "code":"KS"
   },
   {
      "id":1550,
      "title":"Kurdish",
      "european":false,
      "code":"KU"
   },
   {
      "id":1551,
      "title":"Komi",
      "european":false,
      "code":"KV"
   },
   {
      "id":1552,
      "title":"Cornish",
      "european":false,
      "code":"KW"
   },
   {
      "id":1553,
      "title":"Kirghiz",
      "european":false,
      "code":"KY"
   },
   {
      "id":1554,
      "title":"Ganda",
      "european":false,
      "code":"LG"
   },
   {
      "id":1555,
      "title":"Lingala",
      "european":false,
      "code":"LN"
   },
   {
      "id":1556,
      "title":"Lao",
      "european":false,
      "code":"LO"
   },
   {
      "id":1557,
      "title":"Luba-Katanga",
      "european":false,
      "code":"LU"
   },
   {
      "id":1558,
      "title":"Malagasy",
      "european":false,
      "code":"MG"
   },
   {
      "id":1559,
      "title":"Marshall",
      "european":false,
      "code":"MH"
   },
   {
      "id":1560,
      "title":"Maori",
      "european":false,
      "code":"MI"
   },
   {
      "id":1561,
      "title":"Malayalam",
      "european":false,
      "code":"ML"
   },
   {
      "id":1562,
      "title":"Mongolian",
      "european":false,
      "code":"MN"
   },
   {
      "id":1563,
      "title":"Marathi",
      "european":false,
      "code":"MR"
   },
   {
      "id":1564,
      "title":"Malay",
      "european":false,
      "code":"MS"
   },
   {
      "id":1565,
      "title":"Burmese",
      "european":false,
      "code":"MY"
   },
   {
      "id":1566,
      "title":"Nauru",
      "european":false,
      "code":"NA"
   },
   {
      "id":1567,
      "title":"Ndebele, North",
      "european":false,
      "code":"ND"
   },
   {
      "id":1568,
      "title":"Nepali",
      "european":false,
      "code":"NE"
   },
   {
      "id":1569,
      "title":"Ndonga",
      "european":false,
      "code":"NG"
   },
   {
      "id":1570,
      "title":"Ndebele, South",
      "european":false,
      "code":"NR"
   },
   {
      "id":1571,
      "title":"Navajo",
      "european":false,
      "code":"NV"
   },
   {
      "id":1572,
      "title":"Chichewa",
      "european":false,
      "code":"NY"
   },
   {
      "id":1573,
      "title":"Ojibwa",
      "european":false,
      "code":"OJ"
   },
   {
      "id":1574,
      "title":"Oromo",
      "european":false,
      "code":"OM"
   },
   {
      "id":1575,
      "title":"Oriya",
      "european":false,
      "code":"OR"
   },
   {
      "id":1576,
      "title":"Ossetian",
      "european":false,
      "code":"OS"
   },
   {
      "id":1577,
      "title":"Panjabi",
      "european":false,
      "code":"PA"
   },
   {
      "id":1578,
      "title":"Pali",
      "european":false,
      "code":"PI"
   },
   {
      "id":1579,
      "title":"Pushto",
      "european":false,
      "code":"PS"
   },
   {
      "id":1580,
      "title":"Quechua",
      "european":false,
      "code":"QU"
   },
   {
      "id":1581,
      "title":"Rundi",
      "european":false,
      "code":"RN"
   },
   {
      "id":1582,
      "title":"Kinyarwanda",
      "european":false,
      "code":"RW"
   },
   {
      "id":1583,
      "title":"Sanskrit",
      "european":false,
      "code":"SA"
   },
   {
      "id":1584,
      "title":"Sindhi",
      "european":false,
      "code":"SD"
   },
   {
      "id":1585,
      "title":"Northern Sami",
      "european":false,
      "code":"SE"
   },
   {
      "id":1586,
      "title":"Sango",
      "european":false,
      "code":"SG"
   },
   {
      "id":1587,
      "title":"Sinhalese",
      "european":false,
      "code":"SI"
   },
   {
      "id":1588,
      "title":"Samoan",
      "european":false,
      "code":"SM"
   },
   {
      "id":1589,
      "title":"Shona",
      "european":false,
      "code":"SN"
   },
   {
      "id":1590,
      "title":"Somali",
      "european":false,
      "code":"SO"
   },
   {
      "id":1591,
      "title":"Swati",
      "european":false,
      "code":"SS"
   },
   {
      "id":1592,
      "title":"Sotho, Southern",
      "european":false,
      "code":"ST"
   },
   {
      "id":1593,
      "title":"Sundanese",
      "european":false,
      "code":"SU"
   },
   {
      "id":1594,
      "title":"Swahili",
      "european":false,
      "code":"SW"
   },
   {
      "id":1595,
      "title":"Tamil",
      "european":false,
      "code":"TA"
   },
   {
      "id":1596,
      "title":"Telugu",
      "european":false,
      "code":"TE"
   },
   {
      "id":1597,
      "title":"Tajik",
      "european":false,
      "code":"TG"
   },
   {
      "id":1598,
      "title":"Thai",
      "european":false,
      "code":"TH"
   },
   {
      "id":1599,
      "title":"Turkmen",
      "european":false,
      "code":"TK"
   },
   {
      "id":1600,
      "title":"Tagalog",
      "european":false,
      "code":"TL"
   },
   {
      "id":1601,
      "title":"Tswana",
      "european":false,
      "code":"TN"
   },
   {
      "id":1602,
      "title":"Tsonga",
      "european":false,
      "code":"TS"
   },
   {
      "id":1603,
      "title":"Tatar",
      "european":false,
      "code":"TT"
   },
   {
      "id":1604,
      "title":"Twi",
      "european":false,
      "code":"TW"
   },
   {
      "id":1605,
      "title":"Tahitian",
      "european":false,
      "code":"TY"
   },
   {
      "id":1606,
      "title":"Uighur",
      "european":false,
      "code":"UG"
   },
   {
      "id":1607,
      "title":"Un-identified",
      "european":false,
      "code":"UN"
   },
   {
      "id":1608,
      "title":"Urdu",
      "european":false,
      "code":"UR"
   },
   {
      "id":1609,
      "title":"Uzbek",
      "european":false,
      "code":"UZ"
   },
   {
      "id":1610,
      "title":"Venda",
      "european":false,
      "code":"VE"
   },
   {
      "id":1611,
      "title":"Vietnamese",
      "european":false,
      "code":"VI"
   },
   {
      "id":1612,
      "title":"Volapok",
      "european":false,
      "code":"VO"
   },
   {
      "id":1613,
      "title":"Wolof",
      "european":false,
      "code":"WO"
   },
   {
      "id":1614,
      "title":"Xhosa",
      "european":false,
      "code":"XH"
   },
   {
      "id":1615,
      "title":"Yiddish",
      "european":false,
      "code":"YI"
   },
   {
      "id":1616,
      "title":"Zhuang",
      "european":false,
      "code":"ZA"
   },
   {
      "id":1617,
      "title":"Chinese",
      "european":false,
      "code":"ZH"
   },
   {
      "id":1618,
      "title":"Zulu",
      "european":false,
      "code":"ZU"
   }
]

export { EQFvalues, ISCEDvalues, languagesvalues, CEFRvalues }
