import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import NominationRequirements from './NominationRequirements';
import { query as getManagedByEWP } from '../../queries/getManagedByEWP';

class NominationRequirementsAccess extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    if (!this.props.managedEWP.managedByEWP) {

      return (
        <NominationRequirements />

      )
    } else {
      return (
        <div className={`content nomNoAccess`}>
          <div className="card card_full_height card_full_width">
            <div className="no_access nAccess">
              <h2>Access is denied</h2>
              <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default compose(
  graphql(getManagedByEWP, {
    name: "managedEWP",
    options: (props) => ({
      variables: {
        erasmusCode: localStorage.getItem("ErasmusCode")
      }
    })
  })
)(NominationRequirementsAccess);