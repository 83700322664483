import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import GlobalVar from '../../../../../GlobalVar';
import RegExps from '../../../../../RegExps';
import FileBase64 from 'react-file-base64';
import DatePicker from "react-datepicker";
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
}from 'react-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css";

class DefaultInfoData extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			//new Event
			title: "",
			body:{
				//summary: "",
				value: ""
			},
			organisations: "",
			//programmes: "Higher Education - Studies",
			//mobilityPhases: "Before application",
			//tags: [],
			programmes: "",
			mobilityPhases: "",
			tags : "",
			website: "",
			selectedValue:"",
			text:"",
			heading:"",
			subtitle:"",
			
		}
	      this.handleMainSelectedChange = this.handleMainSelectedChange.bind(this);
	}


	handleInputChange(event) {
		console.log(event);
		if (event.keyCode == 13 && !event.shiftKey) {
			event.preventDefault();
		}

		if(event.target != null) {
			let value = event.target.value;
			let name = event.target.name;


			let isValid = false;
			let message = "";

			if (value != "") {
				if (name == "website" ) {
					message = this.hasValidFormat("websiteHTTPS", value);
				}
			}
			if (message == "") {
				isValid = true;
			}

			if (isValid) {
				document.getElementById("error_" + name).innerHTML = "";
			}
			else {
				document.getElementById("error_" + name).innerHTML = message;
				document.getElementById("error_" + name).style.color = "red";
			}
			this.setState({ [name]: value }, () => { console.log(this.state) });
		}
	}
	handleSelectChange(selectName) {
		console.log("select " + selectName);
		let select1 = document.getElementById(selectName);
		let selected1 = [];

		for (let i = 0; i < select1.length; i++) {
			if (select1.options[i].selected) selected1.push(select1.options[i].value);
		}
		this.setState({ [selectName]: selected1 }, () => { console.log(this.state[selectName]); });
	}

	handleTextareaChange(event) {
		const { body } = this.state;
		body[event.target.name] = event.target.value;
		this.setState({
		  body
		});
	}
    handleCheckboxChange = event => {
		let newArray = [...this.state.tags, event.target.id];
		if (this.state.tags.includes(event.target.id)) {
			newArray = newArray.filter(tag => tag !== event.target.id);
		}
		this.setState({
		tags: newArray
		});
    };
	
	handleMainSelectedChange(event) { 
	
	//var i = 0;
	var options = [
			[ "Study mobility - Information sessions (outgoing students, before application)", "Information sessions", "Higher Education Study Mobility", "Before application", "Inform yourself","Check if your institution holds information sessions for students who are interested in mobility abroad","Higher Education Institutions (HEIs) usually organise information sessions for students who want to spend time abroad, where they explain available opportunities for mobility. Look for relevant announcements and attend the sessions to learn about existing opportunities. There, you can also meet other interested students or those who have returned from mobility periods and want to share their experiences with you."],
			[ "Study mobility - Check the deadlines (outgoing students, before application)", "Check the deadlines","Higher Education Study Mobility","Before application","Check the deadlines","Keep a lookout for important announcements and deadlines.","Your institution will usually announce the necessary requirements you should fulfil and all the relevant information for a successful application. Pay attention to the internal deadlines set by your home institution and make sure you don’t miss them! For this, have a look at the website, social media posts of your institution or your mailbox."],
			[ "Study mobility - Application for Erasmus (outgoing students, during application)", "Application for Erasmus","Higher Education Study Mobility","During application","Discover possible study destinations","Get in touch with the office in charge of student exchanges at your institution or faculty.","Your institution most likely has an international relations office (IRO) or a student mobility office. There, coordinators for the Erasmus+ Programme know their partner institutions well and can give you advice on the best options for you to go abroad. They can also provide you with information on the steps to follow, eligibility criteria, grants and much more. The coordinator can also provide you with information about partner institutions such as guidebooks, course lists, curricula, language requirements, housing information and so on. All of this information is very useful for helping you choose your destination and to be ready to become a mobile student! We also encourage you to take a look at the u-multirank website. The platform helps you find the perfect university to match your needs and interests by giving you a personalised university ranking list. Find your best matching university. Planning to go abroad during the pandemic? Check out whether your host HEI is welcoming exchange students and/or offering classes online. Erasmus+ Covid-19 Mobility Status tool"],
			[ "Study mobility - Learning Agreement (outgoing students, during application)", "Learning Agreement","Higher Education Study Mobility","During application","Get your Online Learning Agreement signed","Get your Online Learning Agreement signed.","This step is crucial for the recognition of your successfully completed courses abroad. The Online Learning Agreement defines the programme of studies you will follow abroad and what courses they will replace in your study programme at home upon your return.  It must be approved by you, your home institution and your host institution before the start of your mobility. The Online Learning Agreement consists of all of the educational components that will be included in your degree upon successful completion of studying abroad. Before you leave, discuss with your Erasmus+ Academic Coordinator which courses you want to take at the host institution and what courses will be replaced so that they are recognised when you return. Ask also if and how the grades received abroad will be converted. Your choice of courses cannot always be guaranteed, thus if any courses are cancelled or you do not meet the requirements for them, you can always submit a request to change your Online Learning Agreement. The Online Learning Agreement can be completed via www.learning-agreement.eu or via the mobility software supported by your institution. Remember: When in doubt, contact your international office!"],
			[ "Study mobility - Grant Agreement (outgoing students, during application)", "Grant Agreement","Higher Education Study Mobility","During application","Get your Grant Agreement signed","Get your Grant Agreement signed.","You need to sign the grant agreement. It will specify the duration of your mobility, the amount of the grant that you will receive and other rights and obligations you may have in this regard. The amount of the grant depends on your destination country and the duration of your mobility. For students with fewer opportunities due to their economic or personal circumstances (such as parent or caregiving responsibilities), a disability or a physical, mental or health-related condition you have the opportunity to apply for a supplementary grant (top-up). Be sure to ask your Erasmus+ Academic Coordinator or International office for more information. Remember that if you return early from your Erasmus+ mobility, or if you fail to meet the terms of your Erasmus+ grant agreement, it is likely that you will have to return either part or the full sum of your Erasmus+ grant."],
			[ "Study mobility - Student Visa (outgoing students, before you go)", "Student Visa","Higher Education Study Mobility","Before you go","Make sure you get your Visa and Residence Permit on time","Check if you need a visa or a residence permit.","Before your departure, you will need to check whether your destination country requires a visa or a residence permit. If this is the case, make all the necessary arrangements. Higher Education Institutions usually provide details of a contact person who can assist in solving visa-related issues and provide all necessary documents (such as invitation letters, certificates of acceptance, and so on). Establish contact with this person to get support if needed."],
			[ "Study mobility - Accommodation (incoming students, before you go)", "Accommodation","Higher Education Study Mobility","Before you go","Look for accommodation","","Students are responsible for finding suitable accommodation but your host institution must provide you with support in finding accommodation. Some host institutions are able to offer accommodation for Erasmus+ students and will be able to send you the necessary registration form. The local Erasmus Student Network section or other student organisations at your institution may be able to help you with that as well."],
			[ "Study mobility - Going green & Environment (outgoing students, before you go)", "Going green & Environment","Higher Education Study Mobility","Before you go","I booked my travel","Think green when booking your travel to your host country.","When booking your trip to the mobility destination, check if it is reachable via train or bus. This will not only lower the environmental impact of your mobility but you will also see other parts of Europe on your journey in addition to your host country. Also, when you choose a more environmentally friendly way to travel, you may receive a “top-up” to your grant. Your mobility coordinator will be able to provide you with further information."],
			[ "Study mobility - Safety (incoming students, during mobility)", "Safety","Higher Education Study Mobility","During mobility","Stay safe","Be one-step ahead - stay safe","Inform yourself about what measures related to safety, public health and other topics are in place in your host institution. Provide your local phone number (as soon as you have one) to your host and home institutions as well as your emergency contacts' details (close family or friends). Check regularly on your home country's Ministry of Foreign Affairs' website to see if there are any specific recommendations for the country where you are. If you are planning to travel by yourself in your host country, make sure to inform for example your buddy about your plans and to ask your host institution about the information on safe travel. Going abroad during the pandemic? Follow the information on Re-open EU and explore what national tracing apps are being used."],
			[ "Study mobility - Learning Agreement (outgoing students, during mobility)", "Learning Agreement","Higher Education Study Mobility","During mobility","Update your Online Learning Agreement","","Upon arrival, make sure your Online Learning Agreement is still up to date. At this stage, you can submit a request to make necessary amendments to your Online Learning Agreement (via www.learning-agreement.eu or via the mobility software supported by your institution) if the courses you initially selected before your departure have changed or have been cancelled. Whenever the Online Learning Agreement is changed, it must be approved again by each of the three parties (you, your home institution and your host institution). Changes must be notified and agreed upon within five weeks of regular classes starting."],
			[ "Study mobility - Transcript of Records (incoming students, during mobility)", "Transcript of Records","Higher Education Study Mobility","During mobility","Say good-bye!","Visit the Erasmus+ Academic Coordinator at your host institution one last time.","Before you return to your home institution, make sure that you receive the Transcript of Records (ToR) from the host institution. The ToR is a document that clearly indicates all the courses you have followed, together with the grades you received in your exams and the ECTS credits you acquired. This is essential for your home institution to be able to recognise your courses. Be aware that in some cases the ToR will not be available on your date of departure, in which case the host institution must send it to you within a maximum of five weeks. The ToR should mention the first and last day at the institution. Please check that these are correct."],
			[ "Study mobility - Recognition (outgoing students, after mobility)", "Recognition","Higher Education Study Mobility","After mobility","Get your mobility recognised","Get your study abroad period recognised by your home institution.","Your institution will convert your results into their own grading scale and have all of the courses recognised as part of your curriculum as outlined in your Online Learning Agreement. Your institution must recognise the credits (using ECTS credits or an equivalent system) as agreed in the Online Learning Agreement before or during the mobility and count them towards your degree, without any further requirements. Your mobility period should also be recorded in your Diploma Supplement if your institution is located in a Program Country."],
			[ "Traineeship mobility - Information sessions (outgoing students, before application)", "Information sessions", "Higher Education Traineeship Mobility", "Before application", "Inform yourself","Check if your institution holds information sessions for students who are interested in mobility abroad","Higher Education Institutions (HEIs) usually organise information sessions for students who want to spend time abroad, where they explain available opportunities for mobility. Look for relevant announcements and attend the sessions to learn about existing opportunities. There, you can also meet other interested students or those who have returned from mobility periods and want to share their experiences with you."],
			[ "Traineeship mobility - Check the deadlines (outgoing students, before application)", "Check the deadlines","Higher Education Traineeship Mobility","Before application","Check the deadlines","Keep a lookout for important announcements and deadlines.","Your institution will usually announce the necessary requirements you should fulfil and all the relevant information for a successful application. Pay attention to the internal deadlines set by your home institution and make sure you don’t miss them! For this, have a look at the website, social media posts of your institution or your mailbox."],
			[ "Traineeship mobility - Traineeships (outgoing students, during application)", "Accommodation","Higher Education Traineeship Mobility","During application","Find traineeship opportunities","Find a traineeship.","The host organization where you will do your traineeship can be private or public for example a company, an NGO, a research lab or any other work place that can help prepare you for a career in your field. The only limitation are EU bodies and other EU institutions, including specialized agencies or organizations managing EU programs, such as National Agencies, which cannot be host organizations. It is useful to search on online platforms, such as www.erasmusintern.org or the European Employment Services (EURES) website, to find offers that fit your profile and interest or talk to the career services office at your institution and get advice!"],
			[ "Traineeship mobility - Student Visa (outgoing students, before you go)", "Student Visa","Higher Education Traineeship Mobility","Before you go","Make sure you get your Visa and Residence Permit on time","Check if you need a visa or a residence permit.","Before you depart you will need to check whether your destination country requires a visa or a residence permit. If you do need a visa, make all the necessary arrangements. Institutions usually provide details of a contact person who can assist in solving visa-related issues and provide all necessary documents (such as invitation letters, certificates of acceptance, etc.). Establish contact with this person to get support if needed."],
			[ "Traineeship mobility - Safety (outgoing students, during mobility)", "Safety","Higher Education Traineeship Mobility","During mobility","Stay safe","Be one-step ahead - stay safe","Inform yourself about what policy measures related to safety, public health and other topics are in place in your host institution. Provide your local phone number (as soon as you have one) to your host and home institutions as well as your emergency contacts' details (close family or friends). Check regularly on your home country's Ministry of Foreign Affairs' website to see if there are any specific recommendations for the country where you are. If you are planning to travel by yourself in your host country, make sure you inform for example your Traineeship supervisor about your plans and to ask your host institution about information on safe travel. Going abroad during the pandemic? Follow the information on Re-open EU and explore what national tracing apps are being used."],
			[ "Erasmus Mundus - Going green & Environment (outgoing students, before you go)", "Going green & Environment","Erasmus Mundus","Before you go","I booked my travel","Think green when booking your travel to your host country.","When booking your trip to the mobility destination, check if it is reachable via train or bus. This will not only lower the environmental impact of your mobility but you will also see other parts of Europe on your journey in addition to your host country. Also, when you choose a more environmentally friendly way to travel, you may receive a “top-up” to your grant. Your mobility coordinator will be able to provide you with further information."],
			[ "Erasmus Mundus - Student Visa (incoming students, before you go)", "Student Visa","Erasmus Mundus","Before you go","Useful info - visa, insurance, accommodation","Check your visa requirements, get insurance, and find accommodation.","Before you depart you will need to check whether your destination countries requires a visa or a residence permit. If this is the case, make all the necessary arrangements and ask the coordinator of your Erasmus Mundus Master for help with visa-related issues.  Applications for authorisations must be submitted to the relevant authorities of the the first EU country you will stay in; prepare well in advance, as the process may take up to 90 days. If there is no Consulate of the European Programme Country in their country of residence, contact the Ministry of Foreign Affairs or the Immigration Office of that European Country to identify the responsible consulate. In terms of health insurance, you have the right to get a European Health Insurance Card, which is issued by your national health insurance provider and gives you access to medically necessary, state-provided healthcare during a temporary stay in any of the EU countries, plus Iceland, Liechtenstein, Norway and Switzerland, under the same conditions and at the same cost (free in some countries) as people insured in that country. Lastly, you are responsible for finding accommodation but some host institutions could provide support; you could also find help for it at your local Erasmus Mundus Association."]
		];

	for (var i = 0; i < options.length; i++) {
		if (event.target.value == options[i][0]) this.setState({tags: options[i][1]});
		if (event.target.value == options[i][0]) this.setState({programmes: options[i][2]});
		if (event.target.value == options[i][0]) this.setState({mobilityPhases: options[i][3]});
		if (event.target.value == options[i][0]) this.setState({heading: options[i][4]});
		if (event.target.value == options[i][0]) this.setState({subtitle: options[i][5]});
		if (event.target.value == options[i][0]) this.setState({text: options[i][6]});
	}
	
	this.setState({selectedValue: event.target.value});
	
	}
		
	renderSelectedForm(param) {
	  switch(true) {
		case (param !== ""):
		  return <form name="form_name1" id="form_name1" onSubmit={e => { e.preventDefault(); }}>
		    <div className="content createUsefulInfo">
				<div>
						  <table>
						  <tr>
						  <td style={{paddingRight:10, width:110, textAlign: 'right', verticalAlign:'top'}}>
						  Info on the Erasmus+ App
						  </td>
						  <td style={{paddingLeft:15, width:550, borderLeft:'solid'}}>
						  	<div className="row header_row">
								<div className="column_long">
									<p style={{fontWeight: 'bold'}}><strong>{this.state.heading}</strong></p>
									<p>{this.state.subtitle}</p>
									<p style={{textAlign: 'justify'}}>{this.state.text}</p>
									<br/>
								</div>
							</div>
							</td>
						  </tr>
						  <tr>
						  <td style={{paddingRight:10, width:110, textAlign: 'right', verticalAlign:'top'}}>
						  What <a style={{color:'red'}}>*</a>
						  </td>
						  <td style={{paddingLeft:15, width:550, borderLeft:'solid'}}>
							<div className="row header_row">
								<div className="column_long">
									<input type="text" name="title" value={this.state.title} placeholder="Title" onChange={(event) => this.handleInputChange(event)} />
									<p id="error_title"/>
								</div>
							</div>
							<div className="row header_row">
								<div className="column_long" >
									<label>
										<textarea rows="12" cols="60" name="value" value={this.state.body.value} placeholder="More information for the student" onChange={(event) => this.handleTextareaChange(event)} />
									</label>
									<p id="error_value"/>
								</div>
							</div>
							</td>
						  </tr>
						<tr>
						  <td style={{paddingRight:10, width:110, textAlign: 'right', verticalAlign:'top'}}>
						  Website
						  </td>
						  <td style={{paddingLeft:15, width:550, borderLeft:'solid'}}>
						  
						  <div className="row header_row">
							<div className="column_long">
								<input type="text" name="website" value={this.state.website} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
								<p id="error_website"/>
							</div>
						  </div>
						  </td>
						</tr>
					</table>
					<div className="row">
					<br/>
					<p style={{width:900, textAlign:'justify'}}>Only submit content that you own. By submitting an update, you warrant that you have all the necessary rights to use and license the content and that this does not violate, misappropriate or infringe any law or any third parties' rights, including, without limitation, privacy rights and copyright. See more information in Terms and Conditions and Privacy Policy.</p>
					<br/>
					</div>
					<div className="row">
						<div className="column_long" id=""><button onClick={() => this.createInfoData()}>Submit & Publish on the Erasmus+ App</button></div>
					</div>
				
				</div>
		</div>
		</form>;
		default:
		  return null;
	  }
	}
	
	createInfoData(){
		var newWebsite = this.state.website;
		var newTitle =  this.state.title;
		// var newbody = this.state.body; 			not needed
		//var newSummary =  this.state.body.summary;
		var newValue =  this.state.body.value;
		var newProgrammes = this.state.programmes;
		var newmobilityPhases = this.state.mobilityPhases;
		var newtags = this.state.tags;

		
		if (this.hasValidFormat("websiteHTTPS", newWebsite) == "" || this.state.website == "") {

			console.log("in CreateUsefulInfo")
					this.props.CreateUsefulInfo({
						variables: {
							"title": newTitle,
							"body":{
								//"summary": newSummary,
								"value": newValue
							},
							"organisations": JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode,
							"programmes": newProgrammes,
							"mobilityPhases": newmobilityPhases,
							"tags": newtags,
							"website": newWebsite
						}
					}).then((response) => {
						console.log("in CreateUsefulInfo2");

						if (response.data.createUsefulInfo.success) {
							alert('Successfully submitted Useful Info')
							console.log('info created')
							document.getElementById('form_name1').reset()
						} else {
							alert('Error response on submitting Useful Info: '+ response.data.createUsefulInfo.errorMsg)
							console.log('error response with info:' + response.data.createUsefulInfo.errorMsg)
						}
					});
				//}
			//);
		}
		else {
			alert("Please make sure all values inserted are valid.");
		}
	}

	


	/**
	 * Check if value has the correct format for the type of input.
	 *
	 * @param {String} type
	 * @param {String} value
	 *
	 * @return {String} message with error or empty if there is no error
	 */
	hasValidFormat(type, value) {
		let valueTestRExp = [];

		switch (type) {
			/*case "dateDDMM":
          valueTestRExp = RegExps.dateDDMM.exec(value);
          break;
      case "email":
          valueTestRExp = RegExps.email.exec(value);
          break;*/
			case "websiteHTTPS":
				valueTestRExp = RegExps.websiteHTTPS.exec(value);
				break;
			/*case "phone":
          valueTestRExp = RegExps.phone.exec(value);
          break;*/
		}

		if (!valueTestRExp) {
			let message = "";

			switch (type) {
				/* case "dateDDMM":
             message = "Please enter a date in dd/mm format.";
             break;
         case "email":
             message = "Please enter a valid email (ex: user@example.com).";
             break;*/
				case "websiteHTTPS":
					message = "Please enter an URL that start with http or https.";
					break;
				/*case "phone":
            message = "Please enter a phone number that start with your country code (ex: +000)."
            break;*/
				default:
					message = "Please insert a valid value.";
			}
			return message;
		}
		return "";
	}


	render() {
		// const {NameInLocalCharacterSet, NameInLatinCharacterSet, PIC, ErasmusCode, EndDate, City, InstitutionDetail} = this.props.data.institution;
		// let currentErasmusCode = ({ erasmusCode: ErasmusCode })
		// localStorage.setItem("currentErasmusCode", JSON.stringify(currentErasmusCode));
		//console.log("props from genral info",this.props,typeof this.state.phoneNumber)

		return (
			<div className="content">
			 <div className="card card_full_width card_full_height">
			  <h2> Add Useful Information</h2>
              <form >
                <select value={this.state.selectedValue} onChange={this.handleMainSelectedChange} style={{width:570}}>
                    <option value="" selected="selected">Choose category...</option>
					<option value="Study mobility - Information sessions (outgoing students, before application)">Study mobility - Information sessions (outgoing students, before application)</option>
					<option value="Study mobility - Check the deadlines (outgoing students, before application)">Study mobility - Check the deadlines (outgoing students, before application)</option>
					<option value="Study mobility - Application for Erasmus (outgoing students, during application)">Study mobility - Application for Erasmus  (outgoing students, during application)</option>
					<option value="Study mobility - Learning Agreement (outgoing students, during application)">Study mobility - Learning Agreement (outgoing students, during application)</option>
					<option value="Study mobility - Grant Agreement (outgoing students, during application)">Study mobility - Grant Agreement (outgoing students, during application)</option>
					<option value="Study mobility - Student Visa (outgoing students, before you go)">Study mobility - Student Visa (outgoing students, before you go)</option>
					<option value="Study mobility - Accommodation (incoming students, before you go)">Study mobility - Accommodation (incoming students, before you go)</option>
					<option value="Study mobility - Going green & Environment (outgoing students, before you go)">Study mobility - Going green & Environment (outgoing students, before you go</option>
					<option value="Study mobility - Safety (incoming students, during mobility)">Study mobility - Safety (incoming students, during mobility)</option>
					<option value="Study mobility - Learning Agreement (outgoing students, during mobility)">Study mobility - Learning Agreement (outgoing students, during mobility)</option>
					<option value="Study mobility - Transcript of Records (incoming students, during mobility)">Study mobility - Transcript of Records (incoming students, during mobility)</option>
					<option value="Study mobility - Recognition (outgoing students, after mobility)">Study mobility - Recognition (outgoing students, after mobility)</option>
					<option value="Traineeship mobility - Information sessions (outgoing students, before application)">Traineeship mobility - Information sessions (outgoing students, before application)</option>
					<option value="Traineeship mobility - Check the deadlines (outgoing students, before application)">Traineeship mobility - Check the deadlines  (outgoing students, before application)</option>
					<option value="Traineeship mobility - Traineeships (outgoing students, during application)">Traineeship mobility - Traineeships  (outgoing students, during application)</option>
					<option value="Traineeship mobility - Student Visa (outgoing students, before you go)">Traineeship mobility - Student Visa (outgoing students, before you go)</option>
					<option value="Traineeship mobility - Safety (outgoing students, during mobility)">Traineeship mobility - Safety (outgoing students, during mobility)</option>
					<option value="Erasmus Mundus - Going green & Environment (outgoing students, before you go)">Erasmus Mundus - Going green & Environment (outgoing students, before you go)</option>
					<option value="Erasmus Mundus - Student Visa (incoming students, before you go)">Erasmus Mundus - Student Visa (incoming students, before you go)</option>
                </select>
             </form>
      {this.renderSelectedForm(this.state.selectedValue)}
			 </div>
			</div>
		);
	}
}

const mutationCreateUsefulInfo = gql`
	mutation createUsefulInfo($title: String, $body: BodyInput, $organisations: [String], $programmes: [String], $mobilityPhases: [String], $tags: [String], $website: [String]){
		createUsefulInfo(title: $title, body: $body, organisations: $organisations, programmes: $programmes, mobilityPhases: $mobilityPhases, tags: $tags, website: $website){
			success
			errorMsg
		}
	}
`;


export default compose(
	graphql(mutationCreateUsefulInfo,{ name : 'CreateUsefulInfo'})
)(DefaultInfoData);