import React, { Component } from 'react';

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: "",
            refreshPage: false
        }

        this.checkedItems = new Map();

        this.onClickHandler = this.onClickHandler.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    render() {
        return (
            this.renderSelectBox()
        );
    }

    renderSelectBox() {
        if (this.props.multiple) {
            return (
                <div className={this.props.className} groupname={this.props.groupname}>
                    <select id={this.props.id} name={this.props.name} className={this.props.selectClassName} value={this.props.optionsSelected} onChange={(event) => this.onChangeHandler(event)} multiple size="5" style={{height: "100%"}}>
                        {this.props.options.map((value, index) => {
                            return (
                                <option key={"eqfLevel"+index} value={`${value}`}>{`${value}`}</option>
                            );
                        })}
                    </select>
                </div>
            );
        }
        if (this.props.ounits) {
            return (
                <div className={this.props.className} groupname={this.props.groupname}>
                    <select name={this.props.name} className={this.props.selectClassName} value={this.props.value} onChange={(event) => this.onChangeOUHandler(event)}>
                        <option value="">{this.props.defaultMessage}</option>
                        {this.props.options.map((value, index) => {
                            if (this.props.value == value.ounit_id) {
                                return (
                                    <option key={index} value={`${value.ounit_id}`}>{`${value.name}`}</option>
                                );
                            } else {
                                return (
                                    <option key={index} value={`${value.ounit_id}`}>{`${value.name}`}</option>
                                );
                            }
                        })}
                    </select>
                </div>
            );
        }
        let actualIndex = 0;
        return (
            <div className={this.props.className} groupname={this.props.groupname}>
                <select name={this.props.name} className={this.props.selectClassName} value={this.props.value} onChange={(event) => this.onChangeHandler(event)}>
                    <option value="">{this.props.defaultMessage}</option>
                    {this.props.options.map((value, index) => {
                        if (index === actualIndex) {
                            actualIndex++;
                            if (value.split(" - ")[1] === "00") {
                                return (
                                    <optgroup key={index} label={value.split(" - ")[0]}>
                                        {this.props.options.map((language, i) => {
                                            if (i === actualIndex) {
                                                if (language.split(" - ")[1] !== "00") {
                                                    actualIndex++;
                                                    return (
                                                        <option key={i} value={`${language}`}>{`${language}`}</option>
                                                    );
                                                }
                                            }
                                        })}
                                    </optgroup>
                                );
                            }
                            else {
                                return (
                                    <option key={index} value={`${value}`}>{`${value}`}</option>
                                );
                            }
                        }
                    })}
                </select>
            </div>
        );
    }

    checkedItemsHandler() {
        let cc = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let checked = this.checkedItems;

        let newName = this.props.name;
        if (this.props.name === "languagesAdded") {
            newName = "languagesAddedSelection";
        }
        Object.keys(cc[auxInfo.pageNumber - 1][newName]).map((value) => {
            let v = cc[auxInfo.pageNumber - 1][newName][value];

            if (!checked.has(v)) {
                checked.set(v, true);
            }
            else {
                checked.set(v, false);
            }
        });
        this.refreshPage();
    }

    onClickHandler(event) {
        let checkList = document.getElementById(this.props.id);
        let items = document.getElementById(this.props.ulId);
        if (items.classList.contains('visible')) {
            items.classList.remove('visible');
            items.style.display = "none";
        }
        else {
            items.classList.add('visible');
            items.style.display = "block";
        }

        items.onblur = (event) => {
            items.classList.remove('visible');
            items.style.display = "none";
        }

        if (this.props.refreshButton !== undefined) { this.props.refreshButton("", this.props.name); }
    }

    onChangeHandler(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.props.handleSelection({ [name]: value });

        if (this.props.multiple) { this.checkedItems.set(value, !this.checkedItems.get(value)); }

        this.refreshPage();
    }

    onChangeOUHandler(event) {
        const value = event.target.value;

        this.props.handleSelection(value);

        this.refreshPage();
    }

    refreshPage() {
        this.setState({
            refreshPage: !this.state.refreshPage
        });
    }
}

export default Select;