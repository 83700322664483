import React, { Component } from 'react';
import { query } from '../../../queries/getVideoTutorials';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

class Tutorials extends Component {
	generateVideoTutorial(){
		return this.props.data.tutorialVideos.map(({description, title, id, youtubeID}) => {
			return (
				<div>
					<h3>{title}</h3>
					<div className="video_wrapper">
					    <iframe width="560" height="349" src={`https://www.youtube.com/embed/${youtubeID}`} frameborder="0" allowFullScreen />
					</div>
					<div dangerouslySetInnerHTML={{__html:description}}></div>
				</div>
			)
		})
	}
    render() {
    	if(this.props.data.loading){
    		return (
    			<div className="content documentation"></div>
    		)
    	}
    	return (
    		<div className="content documentation">
                <div className="division_50">
    				<div className="card">
    					
	    				<h2>Tutorials</h2>
	    				<div className="inside_content">
	    					{this.generateVideoTutorial()}
	    				</div>
	    			</div>
	    		</div>
			</div>	
		);
    }

};

export default compose(
	graphql(query)
)(Tutorials);