import gql from 'graphql-tag';

const query = gql`
  query ($page: Int, $erasmusCode: String, $optionalFilters: OptionalFilters){
    incomingNominations(erasmusCode: $erasmusCode, page: $page, optionalFilters: $optionalFilters){
			nominationList{
				id
				field_student_name
				field_student_email
				field_student_esi
				field_student_eqf
				field_student_isced
				field_academic_year
				field_academic_term
				field_cv
				field_external_cert
				field_lang_level
				field_motivation_letter
				field_other_document
				field_recom_letter
				field_tor
				field_cv_link
				field_tor_link
				field_motiv_letter_link
				field_recom_letter_link
				field_nomination_status
				field_mobility_details{
					id
					field_home_erasmus_code
					field_home_name
					field_home_org_unit
					field_host_erasmus_code
					field_host_name
					field_host_org_unit
					field_start_date
					field_end_date
					field_mobility_details_status
					field_coop_condition_id
				}
				hasFiles
			}
			nextLink
			page
		}
	}
`;
export { query };
