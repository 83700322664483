import React, { Component } from 'react';
class PreMadeEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMore: false
		}
	}
	showMore(event, stateValue){
		event.preventDefault();
		this.setState({
			showMore: stateValue
		})
	}
	renderBodyText(){
		if(this.state.showMore){
			return <p className="body">{this.props.emailData.BodyText}</p>;
		}
	}
	renderButtons(){
		if(this.state.showMore){
			return (
				<form onSubmit={(event) => this.showMore(event, false)}>
					<button className="text_button">Show less</button>
				</form>
			)
		}
		return (
			<form onSubmit={(event) => this.showMore(event, true)}>
				<button className="text_button">Show more</button>
			</form>
		)
	}

	changeValue(event){
		//console.log(event.target.value);
		this.props.setSelectedMail(event.target.value);
	}

	render() {
		const emailValue = {
			title: this.props.emailData.Title,
			bodyText: this.props.emailData.BodyText
		}
		return (
			<li>
				<input onChange={(event) => this.changeValue(event)} type="radio" name="mail" value={JSON.stringify(emailValue)}/>
				<p className="title">{this.props.emailData.Title}</p>
				{this.renderButtons()}
				
				{this.renderBodyText()}
			</li>
		)
	}

};
//export default graphql(query, (Popup));
export default PreMadeEmail;