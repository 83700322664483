import React, { Component } from 'react';

import { query } from '../../../queries/getDocuments';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import ReactQuill , { Quill, Mixin, Toolbar } from 'react-quill';



class GenerateDocumentRow extends Component {
    constructor(props) {
		super(props);
		this.state = {
            documentTitle: this.props.doc.title,
            documentDesciption: this.props.doc.description
		}
	}
	EditFaq(event){
        this.props.UpdateDocument({
			variables:{
				"id": this.props.doc.id,
				"title": this.state.documentTitle,
				"description": this.state.documentDesciption
			},
            refetchQueries: [{query}]
		})
	}
	renderDeleteButton(id){ 
		return (
	        <div>
	          <form className="info" onSubmit={(event) => this.deleteDocument(id,event)}>
	            <button className="remove fullwidth font">remove</button>
	          </form>
	        </div>
	    )
	} 
	deleteDocument(id,event){ 
	    event.preventDefault();
	    this.props.RemoveDocument({
	        variables:{
	            id
	        },
	        refetchQueries: [{query}]
	    }) 
	}  
    render() {
    	return(
    		<div className="documentation" onBlur={(event) => this.EditFaq(event)}>
		       <input className="documentation info" value={this.state.documentTitle} onChange={(event) => this.setState({documentTitle: event.target.value})} onBlur={(event) => this.EditFaq(event)}></input>
			   <ReactQuill 
		        theme={'snow'} className="documentation description" value={this.state.documentDesciption} onChange={(event) => this.setState({documentDesciption: event})}/>
			    {this.renderDeleteButton(this.props.doc.id)}
			</div>
		)		
    } 
};
const mutationUpdateDocument= gql`
	mutation UpdateDocument($id: ID!,  $title: String!, $description: String!){
	  updateDocument(id: $id, title:$title, description: $description){
	  	id
	  }
	}
`;
const mutationRemoveDocument = gql`
  mutation RemoveDocument($id: ID!){
    removeDocument(id:$id){
      description
    }
  }
`;

export default compose(
	graphql(mutationUpdateDocument, { name: 'UpdateDocument'}), 
    graphql(mutationRemoveDocument , { name: 'RemoveDocument' })
)(GenerateDocumentRow);