import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import GlobalVar from '../../../GlobalVar';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';


class DeclineOLA extends Component{

    constructor(props) {
        super(props)
        this.state = {
            valueDeclining: "",
            errorDeclining: false,
            errorDecliningText: ""
        }
        
    }

    generateDecliningErrorMessage(){
        if(this.state.errorDeclining){
            return <p className="error error_text">{this.state.errorDecliningText}</p>;
        }
    }

    async triggerEditTables(autoRecognitionData) {
        var tablePromise = [];
        if(autoRecognitionData.length > 0){
            autoRecognitionData.forEach(async (item) => {
                const variables = {
                    tableID: item.tableID,
                    tableType: item.tableType,
                    autoRecoValue: item.autoRecoValue,
                    reason: item.reason,
                }
                if(item.isPreliminary){
                    tablePromise.push(this.props.editPreliminaryTable({variables}));
                } else {
                    tablePromise.push(this.props.editFinalTable({variables}));
                }
            });
        }
        return await Promise.all(tablePromise);
    }

    declineOLA3 = async (event, olaID) => {
        let signatureAsDataString = this.refs[`declinePad`].getCanvas().toDataURL();
        event.preventDefault();
        if(this.validateDeclining()){
            const autoRecognitionData = this.props.autoRecognitionData;
            
            await this.triggerEditTables(autoRecognitionData).then((response) => {
                
                let variables = {};
                const loginAccountName = localStorage.getItem("Name");
                variables = {
                    reason: this.state.valueDeclining,
                    olaID,
                    email: GlobalVar.decryptWithKey(localStorage.getItem("Email")),
                    name: loginAccountName,
                    image: signatureAsDataString.replace("data:image/png;base64,", ""),
                    institution: this.props.typeOfStudent,
                    status: this.props.data.data.learningAgreement.field_la_status
                }
                if(this.props.typeOfStudent == 1) {
                    variables.position = "Responsible person at the Sending Institution";
                } else {
                    variables.position = "Responsible person at the Receiving Institution";
                }

                let data = this.props.declineOLA3({variables}).then((res) => {
                    this.props.onProcessing(false);
                    alert("Decline is successfully completed");
                    window.location.reload(false);
                }).catch(er => {
                    alert("Error on signing");
                    window.location.reload(false);
                });

            }).catch(e => {
                alert("Error on automatic recognition");
                window.location.reload(false);
            });
        }
    }

    validateDeclining(){
        if(this.state.valueDeclining === ""){
            this.setState({
                errorDeclining: true,
                errorDecliningText: "You have to give a reason why you are declining the OLA"
            })
            return false;
        }
        else if(this.refs[`declinePad`].isEmpty()){
            this.setState({
                errorDeclining: true,
                errorDecliningText: "Please give a signature inside the box (use your mouse)"
            })
            return false;
        }
        else if(this.refs[`declinePad`].getCanvas().toDataURL().length < 5500){
            this.setState({
                errorDeclining: true,
                errorDecliningText: "Please give a larger signature"
            })
            return false;
        }
        this.setState({
            errorDeclining: false,
            errorDecliningText: ""
        },() => {this.props.onProcessing(true)})
        
        return true
    }

    clearSignature(event){ 
        event.preventDefault();
        this.refs[`declinePad`].clear();
    }

    render(){
        const loginAccountName = localStorage.getItem("Name");
        const status = this.props.data.data.learningAgreement.field_la_status;
        const accounts = this.props.accounts;

        if(loginAccountName == undefined || loginAccountName.localeCompare("undefined") === 0 || !accounts.includes(loginAccountName)) {
            return (<div><p className="italic block_text">Please log in as a staff account to sign the Learning Agreement; main institutional accounts are not able to sign the OLAs. See more information <a href="https://wiki.uni-foundation.eu/display/DASH/Student+List+Management" target="_blank" rel="noopener noreferrer">here</a>.</p><p>&nbsp;</p><p className="italic block_text">If you are already using a staff account and still experiencing difficulties please log out from your current Dashboard session and log in again.</p></div>);
        }

        if(this.props.typeOfStudent == 1) {
            // Outgoing student

            let statusArray = ["preliminary_signed-by-student", "final_signed-by-student"];

            if(!statusArray.includes(status)) {
                return <p className="italic block_text">This learning agreement currently does not require a signature.</p>;
            }

        } else {
            // Incoming student

            let statusArray = ["preliminary_signed-by-student-sending", "final_signed-by-student-sending"];

            if(!statusArray.includes(status)) {
                return <p className="italic block_text">This learning agreement currently does not require a signature.</p>;
            }
        }

        return(
            <div>
               <div><p>Add your signature here</p></div><div><SignatureCanvas penColor='black' ref={`declinePad`}  canvasProps={{width: 300, height: 200, className: 'signingCanvas'}} /></div>
               <div><textarea onChange={(event) => this.setState({valueDeclining: event.target.value})} value={this.state.valueDeclining} placeholder="Fill in reason of declining*" /></div>
                {this.generateDecliningErrorMessage()}

                <p className="block_text declining_text">
                We want to draw your attention that by declining OLA, all the signatures will be removed and student(s) will be able to edit the Online Learning Agreement to introduce your proposed changes.
                Your comments will be emailed to the student(s).
                </p>
                <div className="button_list">
                    <form onSubmit={(event) => this.declineOLA3(event, this.props.data.data.learningAgreement.olaID )}>
                        <button className="decline_button">Confirm declining</button>
                    </form>
                    <form onSubmit={(event) => this.clearSignature(event)} >
                        <button>Clear Signature</button>
                    </form>
                </div>
            </div>
        )
    }
}

const editPreliminaryTable = gql`
    mutation EditPreliminaryTable($tableID: String, $tableType: Int, $autoRecoValue: Boolean, $reason: String){
        editPreliminaryTable(tableID: $tableID, tableType: $tableType, autoRecoValue: $autoRecoValue, reason: $reason)
    }`;

const editFinalTable = gql`
    mutation EditFinalTable($tableID: String, $tableType: Int, $autoRecoValue: Boolean, $reason: String){
        editFinalTable(tableID: $tableID, tableType: $tableType, autoRecoValue: $autoRecoValue, reason: $reason)
    }`;

const declineOLA3 = gql`
  mutation DeclineOLA3($olaID: String, $email: String, $name: String, $position: String, $reason: String, $image: String, $institution: Int, $status: String){
    declineOLA3(olaID: $olaID, reason: $reason, email: $email, name: $name, position: $position, image: $image, institution: $institution, status: $status)
  }`;

export default compose(
    graphql(editPreliminaryTable, { name: 'editPreliminaryTable' }),
    graphql(editFinalTable, { name: 'editFinalTable' }),
    graphql(declineOLA3, { name: 'declineOLA3' })
)(DeclineOLA);