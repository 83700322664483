import gql from 'graphql-tag';

const query = gql`
	query getStudentStudyCycle($Sending:Int, $CountryName:String, $InstitutionName:String, $SearchQuery: String, $StartMobility: String, $EndMobility: String, $TypeOfSigning: String, $SignedBefore: Int, $SignedDuring: Int, $Uploaded: String, $AcademicYear: String, $SubjectArea: String){
		getStudentStudyCycle(UnKnownEmail:"${localStorage.getItem('unknownEmail')}",ErasmusCode: "${localStorage.getItem('ErasmusCode')}", Sending:$Sending, CountryName: $CountryName, InstitutionName: $InstitutionName, SearchQuery: $SearchQuery, StartMobility: $StartMobility, EndMobility: $EndMobility, TypeOfSigning: $TypeOfSigning, SignedBefore: $SignedBefore, SignedDuring: $SignedDuring, Uploaded: $Uploaded, AcademicYear: $AcademicYear, SubjectArea: $SubjectArea){
	    	count
	    	cycle
		}
	}
`
export { query };
