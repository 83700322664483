import React, { Component } from 'react';
import intl from 'react-intl-universal';
import GlobalVar from '../../../../GlobalVar';

class DeclineOLA extends Component {
	constructor(props){
		super(props);
		this.state = {
			error: false,
			errorText: "",
			value: ""
		}
	}
	toPreviousPage(event){
		if(event){
			event.preventDefault();
		}
		this.props.history.goBack();
	}

	initiateDecliningOLA(event){
		event.preventDefault();
		if(this.validate()){
			this.props.declineOLAfunction(this.state.value, GlobalVar.getParameterByName("documentType"));
			this.toPreviousPage();
		}
	}

	validate(){
		if(this.state.value === ""){
			this.setState({
				error: true,
				errorText: "You have to give a reason why you are declining the OLA"
			})
			return false;
		}
		this.setState({
			error: false,
			errorText: ""
		})
		return true
	}

	generateErrorMessage(){
		if(this.state.error){
			return <p className="error">{this.state.errorText}</p>;
		}
	}

	render() {
		return (
			<div>
				<div onClick={() => this.toPreviousPage()} className="black_background"></div>
				<div className="content popup decline_ola_pop_up">
					<h3>Please explain why you are declining the Learning Agreement(s)</h3>
					<textarea onChange={(event) => this.setState({value: event.target.value})} autoFocus value={this.state.value} placeholder="Indicate your reason for declining*" />
					{this.generateErrorMessage()}
					<p>
					*By declining the LA all the signatures will be removed and student(s) will be able to edit the LA to introduce your proposed changes. Your comments will be emailed to the student(s).
					</p>
					<div className="button_list">
						<form onSubmit={(event) => this.initiateDecliningOLA(event)}>
							<button className="decline_button">Confirm declining</button>
						</form>
						<form onSubmit={(event) => this.toPreviousPage(event)} >
							<button>Cancel</button>
						</form>
					</div>
				</div>
			</div>
		);
	}
};

export default DeclineOLA;
