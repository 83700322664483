import React, { Component } from 'react';
import Select from 'react-select';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../queries/getAccountData';

class FunctionRow extends Component {
	constructor(props) {
		super(props);
		this.multiList = [];
		this.state = {
			editMode: false,
			functionValue: this.props.FunctionName,
			multivalue: this.multiList
		}

		this.abilities = [
			{ value: 'editingGeneralInfo', label: 'Editing general info' },
			{ value: 'creatingAccounts', label: 'Creating accounts' },
			{ value: 'managingOUnits', label: 'Managing Organizational Units' },
			{ value: 'viewingStudents', label: 'Viewing students' },
			{ value: 'managingStudents', label: 'Managing students' },
			{ value: 'uploadingStudents', label: 'Uploading students' },
			{ value: 'managingIIAs', label: 'Managing IIAs' },
			{ value: 'creatingIIA', label: 'Creating IIA' },
			// { value: 'signingIIA', label: 'Signing IIA' },
			{ value: 'defaultIIAData', label: 'Default IIA Data' },
			// { value: 'factsheet', label: 'Factsheet' },
			{ value: 'editingStepByStep', label: 'Editing step by step' },
			{ value: 'covidRestrictions', label: "Covid-19 Settings" },
			{ value: 'importIIA', label: 'Import IIA' },
			{ value: 'ewpSettings', label: 'EWP Settings' },
		    // { value: 'createDeals', label: 'Create Deals' },
			// { value: 'createEvents', label: 'Create Events' },
			// { value: 'createUsefulInfo', label: 'Add Useful Info' },
			{ value: 'erasmus-app', label: 'Erasmus+ App' },
			{ value: 'nominations', label: 'Applications for Erasmus+' }
		];

		for (let obj of this.abilities) {
			for (let i = 0; i < this.props.AccountAbility.length; i++) {
				if (this.props.AccountAbility[i].Name === obj.label) {
					this.multiList.push({ value: obj.value, label: obj.label })
				}
			}
		}
	}
	changeFunctionValue(event) {
		const functionValue = event.target.value;
		this.setState({ functionValue });
	}

	changeMultiSelect(multivalue) {
		const value = multivalue ? multivalue : [];
		if(value.length > 0 ) {
			this.setState({ multivalue });
		}
	}
	removeAccount(event) {
		event.preventDefault();
		if (window.confirm("If you remove the selected role, the accounts allocated to this role will also be deleted and the respective colleagues will lose access to the Dashboard.")) {
			this.props.RemoveAccountFunction({
				variables: {
					"id": this.props.id
				},
				refetchQueries: [{ query }]
			})
		}
	}
	EditAccount(event) {
		event.preventDefault();
		this.setState({
			editMode: true
		})
	}
	handleClick(event) {
		event.preventDefault();

		if(this.state.multivalue) {
			this.props.AccountAbility.map(({ Name, id }) => {
				this.props.RemoveAccountAbilityFunction({
					variables: {
						"id": id
					},
					refetchQueries: [{ query }]
				})
			})
			this.props.UpdateAccountFunction({
				variables: {
					id: this.props.id,
					FunctionName: this.state.functionValue
				},
				refetchQueries: [{ query }]
			})
			const FunctionID = this.props.id;
			const length = this.state.multivalue.length;

			this.state.multivalue.map(({ label, value }, index) => {
				if (index != length - 1) {
					this.props.addAccountAbility({
						variables: {
							Name: label,
							Value: value,
							FunctionID
						}
					})
				} else {
					this.props.addAccountAbility({
						variables: {
							Name: label,
							Value: value,
							FunctionID
						},
						refetchQueries: [{ query }]
					}).then((response) => {
					})
				}
			})
		}

		this.setState({
			editMode: false
		})

	}
	renderSaveButton() {
		if(this.state.multivalue) {
			if(this.state.multivalue.length === 0 || this.state.functionValue === "") {
				return <fieldset disabled><button className="top_fix">Save</button> </fieldset>
			} else return <button onClick={(event) => this.handleClick(event)} className="top_fix">Save</button>
		} else {
			return <fieldset disabled><button className="top_fix">Save</button> </fieldset>
		}
		
	}
	generateAbilities(data) {
		return data.map(({ Name, id }) => {
			return <li key={id}>{Name}</li>;
		})
	}
	render() {
		if (this.state.editMode) {
			return (
				<tr key={this.props.id} >
					<td>
						<input style={{ width: '140px', height: '22px' }} value={this.state.functionValue} onChange={(event) => this.changeFunctionValue(event)} type="text" placeholder="Function name" className="top_fix function_name" />
					</td>
					<td>
						<Select style={{ width: '300px', height: '20px' }} value={this.state.multivalue} placeholder="Select multiple abilities" isMulti value={this.state.multivalue} onChange={(value) => this.changeMultiSelect(value)} name="form-field-name" options={this.abilities} className="multiselect" />
					</td>
					<td>
						{this.renderSaveButton()}
					</td>
				</tr>
			)
		} else {
			return (
				<tr key={this.props.id} >
					<td>{this.props.FunctionName}</td>
					<td>
						<ul>
							{this.generateAbilities(this.props.AccountAbility)}
						</ul>
					</td>
					<td><form onSubmit={(event) => this.removeAccount(event)} className="text_button_wrapper"><button>Remove</button></form></td>
					<td>
						<form onSubmit={(event) => this.EditAccount(event)} className="text_button_wrapper">
							<button>Edit</button>
						</form>
					</td>
				</tr>
			)
		}
	}
}

const mutation = gql`
    mutation RemoveAccountFunction($id: ID!){
      removeAccountFunction(id: $id){
        Name
      }
    }
`;
const removeAccountAbility = gql`
    mutation RemoveAccountAbilityFunction($id: ID!){
      removeAccountAbility(id: $id){
        Name
      }
    }
`;
const updateAccountMutation = gql`
  mutation UpdateAccountFunction($id: ID!, $FunctionName: String!){
    updateAccountFunction(id: $id , FunctionName: $FunctionName){
      Name
    }
  }
`
const addAccountAbility = gql`
  mutation addAccountAbility($Name: String!, $Value: String!, $FunctionID: ID!){
    addAccountAbility(Name: $Name, Value: $Value, FunctionID: $FunctionID){
      id
    }
  }
`

export default compose(
	graphql(mutation, { name: 'RemoveAccountFunction' }),
	graphql(removeAccountAbility, { name: 'RemoveAccountAbilityFunction' }),
	graphql(updateAccountMutation, { name: 'UpdateAccountFunction' }),
	graphql(addAccountAbility, { name: "addAccountAbility" })
)(FunctionRow);
