import React, { Component } from "react";
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { withRouter } from "react-router-dom";

import Validator from '../iia/common/Validator';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            travel: -1,
            online_classes: -1,
            more_info_url: ""
        }
    }
    
    setStateValues(data){
        this.state.travel = data.travel;
        this.state.online_classes = data.online_classes;
        this.state.more_info_url = data.more_info_url;
    }

    setValue(name, val) {
        switch(name) {
            case "travel":
                this.state.travel = val;
                break;
            case "online_classes":
                this.state.online_classes = val;
                break;
            case "more_info_url":

                this.state.more_info_url = val;
                break;
        }
    }

    changeValue() {
        let validator = new Validator(this.state.more_info_url);
        let message = validator.validateUrlFormat(true);

        if (message != "") {
            document.getElementById("error_message").innerHTML = message;
            document.getElementById("error_message").style.color = "red";
        }
        else {
            document.getElementById("error_message").innerHTML = "";

            this.props.ChangeRestrictions({
                variables: {
                    erasmusCode: JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode, 
                    travel: this.state.travel, 
                    onlineClasses: this.state.online_classes, 
                    moreInfoUrl: this.state.more_info_url
                },
                refetchQueries: [{
                    query: getRestrictions, variables: {
                        erasmusCode: JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode
                    }
                }],
                awaitRefetchQueries: true
            }).then((x) => {
                if (x.data.error) {
                    alert("Error changing values");
                } else {
                    if (x.data.changeRestrictions.Success) {
                        alert("Successfully changed values");
                    } else {
                        alert(x.data.changeRestrictions.Message);
                    }
                }
            });
        }
    }
    
    renderRows(title, name, data) {
        if (data == undefined) {
			return (
			    <div className="row"><p>It's not possible to set this at the moment, please contact the Dashboard administrator.</p></div>
			);
        }
        if (data == 1) {
            return (
                <div className="row">
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, -1)} /> Unknown</h3></div>
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 0)} /> No</h3></div>
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 1)} defaultChecked /> Yes</h3></div>
                </div>
            );
        } 
        if (data == 0) {
            return (
                <div className="row">
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, -1)} /> Unknown</h3></div>
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 0)} defaultChecked /> No</h3></div>
                    <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 1)} /> Yes</h3></div>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, -1)} defaultChecked /> Unknown</h3></div>
                <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 0)} /> No</h3></div>
                <div className="column"><h3><input value={name} type="radio" name={name} onClick={() => this.setValue(name, 1)} /> Yes</h3></div>
            </div>
        );
    }

    render() {
        if(this.props.data.loading) { 
			return (
				<div className="card card_full_width card_full_height">
					<div className="custom_table">
						<p><img className="spinner" src="/icons/spinner.svg"/>Loading settings...</p>
					</div>
				</div>
			);
		}else{
            if(this.props.data.error){
				return (
					<div className="card card_full_width card_full_height">
					<div className="custom_table">
							<p><img className="spinner" src="/icons/spinner.svg"/>Error loading settings...</p>
						</div>
					</div>
				);
			}else{
                this.setStateValues(this.props.data.getRestrictions);
                return (
                    <div className="content">
                        <div className="card scrollbar card_full_width card_full_height">
                            <div className="custom_table" id="ewpPlatformSelection">
                                <div>
                                    <p>&nbsp;</p>
                                    <h1>To ensure that Higher Education Institutions and students across EU have more information on how the Covid-19 outbreak has impacted the upcoming academic semester, the collector below will 
                                        publicly inform on the current situation in each HEI and can be updated as the situation evolves: <a href="https://covid.uni-foundation.eu/">https://covid.uni-foundation.eu/</a></h1>
                                    <p>&nbsp;</p>
                                </div>
                                <hr/>
                                <div className="row header_row">
                                    <h1>&nbsp;</h1>
                                    <h2>Is your (host) university currently accepting exchange students for the start of the 2020/2021 academic year?</h2>
                                    <p>&nbsp;</p>
                                </div>
                                <div className="row">
                                    <div className="row">
                                        {this.renderRows("Travel Restrictions", "travel", this.state.travel)}
                                    </div>
                                </div>
                                <div className="row header_row">
                                    <h1>&nbsp;</h1>
                                    <h2>Can international students expect to follow classes online at your (host) university?</h2>
                                    <p>&nbsp;</p>
                                </div>
                                <div className="row">
                                    <div className="row">
                                        {this.renderRows("Online Classes Restrictions", "online_classes", this.state.online_classes)}
                                    </div>
                                </div>
                                <div className="row header_row">
                                    <h1>&nbsp;</h1>
                                    <h2> If available, please share a link to your University’s information webpage on the impact of Covid-19 on international mobility:</h2>
                                </div>
                                <div className="row">
                                    <div className="row">
                                        <div className="row">
                                            <p>Current URL defined: {this.state.more_info_url}</p>
                                            <p>New URL: <input type="text" size="50" placeholder="https://www.example.com" onChange={(event) => this.setValue("more_info_url", event.target.value)}></input></p>
                                            <p id="error_message"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column" id="">
                                        <button onClick={() => this.changeValue()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                
            }
        }
    }
}

const mutationChangeRestrictions = gql`
	mutation ChangeRestrictions($erasmusCode: String!, $travel: Int!, $onlineClasses: Int!, $moreInfoUrl: String!) {
        changeRestrictions(erasmusCode: $erasmusCode, travel: $travel, onlineClasses: $onlineClasses, moreInfoUrl: $moreInfoUrl ) {
            Success
            Message
	  }
	}
`;

const getRestrictions = gql`
    query getRestrictions($erasmusCode: String!) {
        getRestrictions(erasmusCode: $erasmusCode) {
            travel
            online_classes
            more_info_url
        }
    }
`;

export default compose(
	graphql(getRestrictions, {
		options: (props) => {return {
			variables: {
				erasmusCode: localStorage.getItem("currentErasmusCode") ? JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode : ""
			}
		}}
    }),
    graphql(mutationChangeRestrictions,{ name : 'ChangeRestrictions'})
)(Settings);