import xregexp from 'xregexp';
import mailValidator from 'email-validator';
import validator from 'validator';

class Validator {
	constructor(value) {
		this.value = value;
		this.unicodeWord = xregexp('^\\pL+$');
		this.phoneNumberFormat = /^\+[0-9]\d{1,14}$/; // E.164
		this.codeFormat = xregexp('\\p{ASCII}');
	}

	validationSpaces() {
		if (/^\s/.test(this.value)) {
			return "This field may not begin with a space.";
		}
		if (/\s$/.test(this.value)) {
			return "This field may not end with a space.";
		}
	}

	validateRegularNamesOfRequiredFields() {
		if (/^\s/.test(this.value) || /\s$/.test(this.value)) {
			return "This value cannot start or end with whitespace (space, tab or line break)."
		}
		if (!this.value || this.value.length === 0) {
			return "This field may not be empty.";
		}
		return "";
	}

	validateRegularNames() {
		if (!this.value) { return ""; }

		const unicodeValue = this.value.replace(/[\s-]/g, "");
		if (!this.unicodeWord.test(unicodeValue) || /^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (!this.unicodeWord.test(unicodeValue)) {
				return "This field may not contain any symbols or numbers.";
			}
			return this.validationSpaces();
		}
		return "";
	}

	validateErasmusCode(canBeEmpty) {
		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (/^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (this.value.length === 0) {
				return "This field may not be empty.";
			}
			if (!(/^[A-Z|\s]{1,3}[A-Z-.]{1,7}\d{2,3}\s?$/.test(this.value))) {
				return "Invalid Erasmus code.";
			}
		}
		return "";
	}

	validateEmailFormat(canBeEmpty) {
		if (!this.value) {
			this.value = "";
			return "";
		}

		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (!mailValidator.validate(this.value) || /^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (this.value.length === 0) {
				return "This field may not be empty.";
			}
			if (!mailValidator.validate(this.value)) {
				return "Wrong mail format.";
			}
			return this.validationSpaces();
		}
		return "";
	}

	validatePhoneFaxNumber(canBeEmpty) {
		if (this.value === null) {
			this.value = "";
		}

		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (!this.phoneNumberFormat.test(this.value)) {
			return "The phone format contains only digits starting with "+
			"'+' sign followed by country code (1 to 3 digits) and" +
			"subscriber number (max 12 digits)";
		}

		return "";
	}

	validateUrlFormat(canBeEmpty) {
		if (this.value === null) {
			this.value = "";
		}

		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (!validator.isURL(this.value) || /^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (!validator.isURL(this.value)) {
				return "Wrong URL format.";
			}
			return this.validationSpaces();
		}
		return "";
	}

	validateUrlFormatWithProtocol(canBeEmpty) {
		if (this.value === null) {
			this.value = "";
		}

		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (!validator.isURL(this.value, { require_protocol: true }) || /^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (!validator.isURL(this.value, { require_protocol: true })) {
				return "Wrong URL format.";
			}
			return this.validationSpaces();
		}
		return "";
	}

	validateCodes(canBeEmpty) {
		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (!this.codeFormat.test(this.value) || /^\s/.test(this.value) || /\s$/.test(this.value)) {
			if (this.value.length === 0) {
				return "This field may not be empty.";
			}
			if (!this.codeFormat.test(this.value)) {
				return "Wrong code format.";
			}
			return this.validationSpaces();
		}
		return "";
	}

	/**
	 * The value is valid if is an integer and it is between the 'min' and the 'max' values,
	 * exclusive.
	 * 
	 * @param {Boolean} canBeEmpty 
	 * @param {Number} min 
	 * @param {Number} max 
	 */
	validateNumbers(canBeEmpty, min, max) {
		if (canBeEmpty && this.isEmpty()) { return ""; }

		if (this.value > min && this.value < max) {
			return "";
		}
		return "The value must be between " + min + " and " + max + ", exclusive.";
	}

	isEmpty() {
		if (this.value.length === 0) { return true; }
		return false;
	}
}

export default Validator;