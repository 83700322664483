import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';
import { withRouter } from "react-router";

class AccountLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			error: false,
			title: ""
		}
	}

	changeValue(event, nameState) {
		this.setState({
			[nameState]: event.target.value
		})
	}

	login(event) {
		event.preventDefault();
		if (this.validate()) {
			setTimeout(function () {
				this.loginInstitution();
				//this.loginAccount();
			}.bind(this), 300);
		}
	}

	loginInstitution() {
		this.props.RegisteredInstitutionLoginMutation({
			variables: {
				email: this.state.email,
				password: this.state.password
			}
		}).then((response) => {
			const { loginRegisteredInstitution } = response.data;
			if (loginRegisteredInstitution !== null) {
				if (loginRegisteredInstitution.Valid == 1) {
					const restricted = this.props.location.state ? this.props.location.state.restricted : false;
					const urlRestricted = this.props.location.state ? this.props.location.state.urlRestricted : "";
					localStorage.clear();
					localStorage.setItem("ErasmusCode", loginRegisteredInstitution.ErasmusCodeEncrypted);
					let currentErasmusCode = ({ erasmusCode: loginRegisteredInstitution.CurrentErasmusCode })
					localStorage.setItem("currentErasmusCode", JSON.stringify(currentErasmusCode));
					localStorage.setItem("Email", loginRegisteredInstitution.EmailEncrypted);
					localStorage.setItem("Name", loginRegisteredInstitution.Name);
					localStorage.setItem("Offset", "0");
					GlobalVar.setDefaultLocalStorage();
					if (restricted) {
						window.location.replace(urlRestricted);
					} else {
						window.location.replace(GlobalVar.generateLinkUrl(intl, "My University", "General info"));
					}
				} else {
					this.setState({ error: true, title: "Declined institution account" });
					return false;
				}
			} else {
				this.loginAccount();
			}
		});
	}
	loginAccount() {
		this.props.AccountLoginMutation({
			variables: {
				Email: this.state.email,
				Password: this.state.password
			}
		}).then((response) => {
			const { accountLogin } = response.data;
			if (accountLogin === null) {
				this.setState({ error: true, title: "Incorrect email or password" });
				return false;
			}
			if (accountLogin.id === "Not confirmed") {
				this.setState({ error: true, title: "Account not confirmed, check your emails" });
				return false;
			} else {
				//check if the account is an admin
				if (GlobalVar.decryptWithKey(accountLogin.id) === "EUFadmin") {
					localStorage.clear();
					localStorage.setItem("ErasmusCode", accountLogin.id);
					window.location.replace("/institutions/pendingrequests");
					return;
				}
				const restricted = this.props.location.state ? this.props.location.state.restricted : false;
				const urlRestricted = this.props.location.state ? this.props.location.state.urlRestricted : "";
				localStorage.clear();
				localStorage.setItem("accountID", accountLogin.id);
				localStorage.setItem("ErasmusCode", accountLogin.ErasmusCodeEncrypted);
				let currentErasmusCode = ({ erasmusCode: accountLogin.CurrentErasmusCode })
				localStorage.setItem("currentErasmusCode", JSON.stringify(currentErasmusCode));
				localStorage.setItem("Email", accountLogin.EmailEncrypted);
				localStorage.setItem("Name", accountLogin.Name);
				GlobalVar.setDefaultLocalStorage();
				if (restricted) {
					window.location.replace(urlRestricted);
				} else {
					window.location.replace(GlobalVar.generateLinkUrl(intl, "My University", "General info"));
				}
			}
		});
	}

	validate() {
		if (this.state.email === "") {
			this.setState({ error: true, title: "The email can't be empty" })
			return false;
		}
		if (!EmailValidator.validate(this.state.email)) {
			this.setState({ error: true, title: "Wrong email format" })
			return false;
		}
		if (this.state.password === "") {
			this.setState({ error: true, title: "The password can't be empty" })
			return false;
		}
		if (this.state.password.length < 8) {
			this.setState({ error: true, title: "The password needs at least 8 characters" })
			return false;
		}
		this.setState({ error: false, title: "Valid input..." });
		return true;
	}

	getTitle() {
		if (this.state.error) {
			return <h2 className="error">{this.state.title}</h2>;
		}
		else if (this.state.title !== "") {
			return <h2>{this.state.title}</h2>;
		}
		return <h2>Login</h2>;
	}

	render() {
		const restricted = this.props.location.state ? this.props.location.state.restricted : false;
		const urlRestricted = this.props.location.state ? this.props.location.state.urlRestricted : "";
		return (
			<div className="intro">
				<div className="intro_content">
					<div className="account_login card">
						{(restricted) ? <h2 className="error">Please login to continue</h2> : ""}
						{(restricted) ?
							<Link
								className="back_link"
								to={{ pathname: "/intro", state: { urlRestricted: urlRestricted } }}>
								{"<"}Go back to Intro
							</Link> :
							<Link
								className="back_link"
								to={"/intro"}>
								{"<"}Go back to Intro
							</Link>}
						<p className="italic_p">Please find the updated Erasmus Dashboard Privacy Policy and Terms and Conditions <Link to={GlobalVar.generateLinkUrl(intl, "Account", "Login") + "/termsandconditions"}>here</Link></p>
						{this.getTitle()}
						<form onSubmit={(event) => this.login(event)} >
							<input autoFocus value={this.state.email} onChange={(event) => this.changeValue(event, "email")} type="email" placeholder="Email" />
							<input value={this.state.password} onChange={(event) => this.changeValue(event, "password")} type="password" placeholder="Password" />
							<button>Login</button>
							<Link to={GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten")}>Forgot password?</Link>
						</form>
					</div>
				</div>
			</div>
		)
	}

}


const AccountLoginMutation = gql`
	mutation AccountLogin($Email:String, $Password:String){
	  accountLogin(Email: $Email, Password: $Password){
	    id
		ErasmusCodeEncrypted
		CurrentErasmusCode
		EmailEncrypted
		Name
	  }
	}
`;

const RegisteredInstitutionLoginMutation = gql`
	mutation loginRegisteredInstitution($email:String, $password:String){
	  loginRegisteredInstitution(email: $email, password: $password){
	    id
	    Valid
		ErasmusCodeEncrypted
		CurrentErasmusCode
		EmailEncrypted
	  }
	}
`;

export default withRouter(compose(
	graphql(AccountLoginMutation, { name: 'AccountLoginMutation' }),
	graphql(RegisteredInstitutionLoginMutation, { name: 'RegisteredInstitutionLoginMutation' }),
)(AccountLogin));
