import React, { Component } from 'react';



class AcademicYearFilter extends Component {
  constructor(props) {
		super(props);

    this.state = {
      value: ""
    }
	}

  componentDidMount() {
    let academicYearValue = "";
    if(this.props.firstYear != null && this.props.secondYear != null){
      academicYearValue = this.props.firstYear+"/"+this.props.secondYear;
    }
    this.setState({
      value: academicYearValue
    }); 
  }

	changeSelection(event){
		this.setState({
			value: event.target.value
    });
    this.props.onSelectedAcademicYear(event.target.value)
	}

	renderAcademicYear(){
    let renderOptions = [];
    let today = new Date();
    let june = new Date(2021, 5, 2);
    let currentAcademicYear = "";
    if(today.toJSON().slice(0, 10) < june.toJSON().slice(0, 10)){
      // Before 1st June
      currentAcademicYear = today.getFullYear()-1;
    } else {
      // After 1st June
      currentAcademicYear = today.getFullYear();
    }

    for(var i=-3; i<=1; i++) {
      let firstYear = parseInt(currentAcademicYear)+parseInt(i);
      let secondYear = firstYear+1;
      let academicYear = firstYear + "/" + secondYear;
      renderOptions.push(<option key={i} value={academicYear}>{academicYear}</option>);
    }
    return renderOptions;
	}

	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		} else {
      return (
        <select id="filter-academic-year" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={"all"}>Select academic year</option>
					{this.renderAcademicYear()}
          <option value={"all"}>All academic years</option>
        </select>
      )
  	}
  }
};

export default AcademicYearFilter;
