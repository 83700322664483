import gql from 'graphql-tag';

const query = gql`
  query{
    faq{
      id
      Question
      Answer
    }
  }
` 
export { query };