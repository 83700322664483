import React, { Component } from 'react';
import { query } from '../../../queries/getCourses';
import { graphql } from 'react-apollo';
import GlobalVar from '../../../GlobalVar';

class Courses extends Component {

	findValue(value){
		if(value === undefined || value === ""){
			return <p className="italic">Not found</p>;
		}
		return value;
	}

	renderForm(){
		return (
			<form onSubmit={(event) => this.props.openOrCloseCoursesEvent(event)}>
    			<button>Show courses</button>
    		</form>
		)
	}
	generateStatusColumnHeader(){
		if(this.props.changes){
			return <div className="column courseStatus"><h3>Status</h3></div>
		}
	}
	generateReasonColumnHeader(){
		if(this.props.changes){
			return <div className="column courseReason"><h3>Reason</h3></div>
		}
	}
	generateReasonValue(reason_int, reason_txt){
		if(this.props.changes){
			let reasonValue = <p className="italic">Not found</p>;
			if(reason_int > 0){
				reasonValue = <p>{GlobalVar.OlaReasons[reason_int]}</p>;
			}
			if(reason_int === 4 || reason_int === 7){
				reasonValue = <p>Other ({reason_txt})</p>;
			}
			return <div className="column courseReason">{reasonValue}</div>
		}
	}
	generateStatusValue(isdeleted){
		if(this.props.changes){
			let status = <p className="succes">Added</p>;
			if(isdeleted) status = <p className="error">Removed</p>;

			return <div className="column courseStatus">{status}</div>
		}
	}

	renderTotalCalculation(){
		if(this.props.changes){
			let ectsRemoved = 0;
			let ectsAdded = 0;
			this.props.data.course.map(({ECTS, isdeleted}) => {
				if(isdeleted){
					ectsRemoved += ECTS;
				}
				else{
					ectsAdded += ECTS;
				}
			});
			let ectsDifference = ectsAdded - ectsRemoved;
			return(
				<div className="row data_row italic_row">
					<div className="column courseName"><p>Difference ECTS credits</p></div>
					<div className="column courseCode">
						<p>
							<span className="succes">{ectsAdded}</span>
							<span className="error"> - {ectsRemoved} </span>
							= {ectsDifference}
						</p>
					</div>
				</div>
			)
		}
		else{
			let ectsTotal = 0;
			this.props.data.course.map((courseData) => {
				ectsTotal += courseData.ECTS;
			});
			return(
				<div className="row data_row italic_row">
					<div className="column courseName"><p>Total ECTS credits</p></div>
					<div className="column ects"><p>{ectsTotal}</p></div>
					<div className="column courseCode"><p></p></div>
				</div>
			)
		}

	}
	renderCourses(){
		if(this.props.data.course.length === 0){
			return <p className="italic">No courses to show</p>;
		}
		return(
			<div className="sub_table">
				<div className="row header_row">
					<div className="column courseName"><h3>Course name</h3></div>
					<div className="column ects"><h3>ECTS</h3></div>
					<div className="column courseCode"><h3>Course code</h3></div>
					{this.generateStatusColumnHeader()}
					{this.generateReasonColumnHeader()}
				</div>
				{this.renderCoursesRows()}
				{this.renderTotalCalculation()}
				<form onSubmit={(event) => this.props.openOrCloseCoursesEvent(event)}>
	    			<button>Close courses</button>
	    		</form>
			</div>
		)
	}
	renderCoursesRows(){
		return this.props.data.course.map((courseData, index) => {
			const {reason_int, reason_txt, title, ECTS, code, isdeleted} = courseData;
			return (
				<div key={index} className="row data_row">
					<div className="column courseName"><p>{this.findValue(title)}</p></div>
					<div className="column ects"><p>{this.findValue(ECTS)}</p></div>
					<div className="column courseCode"><p>{this.findValue(code)}</p></div>
					{this.generateStatusValue(isdeleted)}
					{this.generateReasonValue(reason_int, reason_txt)}
				</div>
			)
		})
	}
	render() {
		if(this.props.changes && !this.props.OlaID){
			return<p className="italic">Not applicable</p>;
		}
		if(!this.props.openCourses){
			return this.renderForm();
		}
		if(this.props.data.loading){
			return <p>Loading courses...</p>;
		}
		return this.renderCourses();
  }

};

export default graphql(query, {
	options: (props) => {return {
		variables: {
			OpenCourses: Number(props.openCourses),
			OlaID: props.OlaID,
			CourseType: props.type,
			Changes: props.changes,
			ChangesOlaID: props.ChangesOlaID
		}
	}}
})(Courses);
