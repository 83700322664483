import React, { Component } from 'react';
import FunctionForm from './FunctionForm';
import FunctionTable from './FunctionTable';
import AccountForm from './AccountForm';
import AccountTable from './AccountTable';
import { query } from '../../../queries/getAccountData';
import { graphql } from 'react-apollo';
import Help from '../../general/Help';

class Accounts extends Component {

	render() {
        if(this.props.data.loading){
            return (<div className="content accounts"></div>)
        } else if(this.props.data.accounts && this.props.data.accountFunctions) {
        	return (
        		<div className="content accounts">
        			<div className="division_50">
        				<div className="card card_full_width card_full_height">
        					<h2>Multiuser access</h2>
        					<FunctionForm />
        					<FunctionTable data={this.props.data.accountFunctions} />
        				</div>
        			</div>
        			<div className="division_50">
        				<div className="card card_full_width card_full_height no_left_margin">
        					<h2>Account management <Help title={"Account management"} description={"added colleagues will receive an automatic email to set their own login credentials to the platform"} /></h2>
        					<AccountForm accountFunctions={this.props.data.accountFunctions} />
        					<AccountTable data={this.props.data.accounts} accountFunctions={this.props.data.accountFunctions}  />
        				</div>
        			</div>
    			</div>
          	);
        } else {
            const style = {"marginLeft": "10px", "marginTop": "12px"};
            return (
                <div className="content generalInfo">
                    <div className="card card_full_width">
                        <h2>Error loading account data. Please logout and try again</h2>
                        <p style={style}>If the problem persists, please contact the <a href="https://ewx.atlassian.net/servicedesk/customer/portals" 
target="_blank" rel="noopener noreferrer">ESCI Service Desk</a></p>
                    </div>
                </div>
            );
        }
    }

};


export default graphql(query)(Accounts);
