import React, { Component } from "react";

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }

        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        this.setState({ value: event.target.value });
        this.props.handlerChange(event, "search");
    }

    generateSearchImg(){
        if(this.state.value !== ""){
            return <img src="/icons/search-focus.svg"/>;
        }
        return <img src="/icons/search.svg"/>;
    }

    render() {
        return (
            <div className="search_wrapper">
                {this.generateSearchImg()}
                <input
                    name="iia-filter-search"
                    id="iia-filter-search"
                    // onKeyUp={() => this.stoppedTyping()}
                    value={this.state.value}
                    // onChange={(event) => this.changeValue(event)}
                    // onFocus={() => this.setState({ focus: true })}
                    // onBlur={() => this.setState({ focus: false })}
                    onChange={(event) => this.changeValue(event)}
                    type="text"
                    placeholder="Search"
                />
            </div>
        );
    }
}

export default Search;