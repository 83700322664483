import React, { Component } from 'react';
import { query } from '../../../../queries/getSingleStudent';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas'
import intl from 'react-intl-universal';
import gql from 'graphql-tag';
import GlobalVar from '../../../../GlobalVar';
import axios from "axios";
import querystring from 'querystring';

import { query as getStudent } from '../../../../queries/getStudent';
import { query as getStudentCountries } from '../../../../queries/getStudentCountries';
import { query as getStudentInstitutions } from '../../../../queries/getStudentInstitutions';
import { query as getStudentSignedBy } from '../../../../queries/getStudentSignedBy';
import { query as getStudentSubjectArea } from '../../../../queries/getStudentSubjectArea';
import { query as getStudentUploaded } from '../../../../queries/getStudentUploaded';
import { query as getStudentStudyCycle } from '../../../../queries/getStudentStudyCycle';
import { query as getStudentAcademicYear} from '../../../../queries/getStudentAcademicYear';

class Popup extends Component {
	constructor(props){
		super(props);
		this.state = {
			errorSigning: false,
			errorTextSigning: "",
			valueDeclining: "",
			errorDeclining: false,
			errorDecliningText: "",
			processingDownloadOLA: false
		}
		this.typeOfStudents = -1;
    this.typeOfStudentName = "";
	}

	componentWillMount() {
        this.setTypeOfStudents(this.props);
    }
    componentWillReceiveProps(nextProps) {
        this.setTypeOfStudents(nextProps);
    }

	setTypeOfStudents(props){
        const url = props.location.pathname;
        let outgoingStudent = intl.get("Outgoing students");
        outgoingStudent = outgoingStudent.replace(/\s/g, "-");
        outgoingStudent = outgoingStudent.toLowerCase();

        if(url.includes(outgoingStudent)){
            this.typeOfStudents = 1;
            this.typeOfStudentName = "Outgoing";
        }
        else{
            let incomingStudent = intl.get("Incoming students");
            incomingStudent = incomingStudent.replace(/\s/g, "-");
            incomingStudent = incomingStudent.toLowerCase();
            if(url.includes(incomingStudent)){
                this.typeOfStudents = 2;
                this.typeOfStudentName = "Incoming";
            }
        }
        //
    }

	toPreviousPage(event){
		if(event){
			event.preventDefault();
		}
		if(this.typeOfStudentName === "Incoming"){
			this.props.history.push(GlobalVar.generateLinkUrl(intl, "Incoming students", "Student List"));
		}
		else if(this.typeOfStudentName === "Outgoing"){
			this.props.history.push(GlobalVar.generateLinkUrl(intl, "Outgoing students", "Student List"));
		}
	}
	findValue(value){
		if(value == undefined || value==""){
			return <p className="italic">Not found</p>;
		}
		return value;
	}
	renderInstitutionName(lastOLAdata, sending){
		if(sending){
			if(lastOLAdata.SendingInstitution != null){
				return lastOLAdata.SendingInstitution.NameInLatinCharacterSet;
			}
		}
		else{
			if(lastOLAdata.ReceivingInstitution != null){
				return lastOLAdata.ReceivingInstitution.NameInLatinCharacterSet;
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderSignedBy(status){
		if(status < 1){
			return "Nobody";
		}
		else if(status == 1){
			return "Student";
		}
		else if(status == 2){
			return "Student + Home institution";
		}
		else if(status == 3){
			return "Everyone";
		}
	}

	renderInstitutionErasmusCode(lastOLAdata, sending){
		if(sending){
			if(lastOLAdata.SendingInstitution != null){
				return lastOLAdata.SendingInstitution.ErasmusCode;
			}
		}
		else{
			if(lastOLAdata.ReceivingInstitution != null){
				return lastOLAdata.ReceivingInstitution.ErasmusCode;
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCityName(lastOLAdata, sending){
		if(sending){
			if(lastOLAdata.SendingInstitution != null){
				if(lastOLAdata.SendingInstitution.City != null){
					return lastOLAdata.SendingInstitution.City.CityName
				}
			}
		}
		else{
			if(lastOLAdata.ReceivingInstitution != null){
				if(lastOLAdata.ReceivingInstitution.City != null){
					return lastOLAdata.ReceivingInstitution.City.CityName
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCountryName(lastOLAdata, sending){
		if(sending){
			if(lastOLAdata.SendingInstitution != null){
				if(lastOLAdata.SendingInstitution.City != null){
					if(lastOLAdata.SendingInstitution.City.Country != null){
						return lastOLAdata.SendingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		else{
			if(lastOLAdata.ReceivingInstitution != null){
				if(lastOLAdata.ReceivingInstitution.City != null){
					if(lastOLAdata.ReceivingInstitution.City.Country != null){
						return lastOLAdata.ReceivingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	generateReasonColumnHeader(changes){
		if(changes){
			return <th className="reason">Reason</th>
		}
	}
	generateStatusColumnHeader(changes){
		if(changes){
			return <th className="status">Status</th>
		}
	}
	renderSubjectTable(data, changes){
		let classNameTable = "table_without_changes";
		if(changes){
			classNameTable = "table_with_changes";
		}
		if(data !== undefined){
			if(data.length === 0){
				return <p className="italic">No courses to show</p>;
			}
			return(
				<table className={classNameTable}>
					<thead>
						<tr>
							<th className="code">Code</th>
							<th className="title">Subject title</th>
							<th className="ects">ECTS</th>
							<th className="semester">Semester</th>
							{this.generateStatusColumnHeader(changes)}
							{this.generateReasonColumnHeader(changes)}
						</tr>
					</thead>
					<tbody>
						{this.renderTableRows(data, changes)}
						{this.calculateECTStotal(data, changes)}
					</tbody>
				</table>
			)
		}
	}
	generateReasonValue(reason_int, changes, reason_txt){
		if(changes){
			let reasonValue = <p className="italic">Not found</p>;
			if(reason_int > 0){
				reasonValue = <p>{GlobalVar.OlaReasons[reason_int]}</p>;
			}
			if(reason_int === 4 || reason_int === 7){
				reasonValue = <p>Other ({reason_txt})</p>;
			}
			return <td className="reason">{reasonValue}</td>
		}
	}
	generateStatusValue(isdeleted, changes){
		if(changes){
			let status = <p className="succes">Added</p>;
			if(isdeleted) status = <p className="error">Removed</p>;

			return <td className="status">{status}</td>
		}
	}
	renderTableRows(data, changes){
		return data.map((courseData, index) => {
			const {ECTS, code, semester, title, reason_int, reason_txt, isdeleted} = courseData;
			return(
				<tr key={index}>
					<td className="code">{this.findValue(code)}</td>
					<td className="title">{this.findValue(title)}</td>
					<td className="ects">{this.findValue(ECTS)}</td>
					<td className="semester">{this.findValue(semester)}</td>
					{this.generateStatusValue(isdeleted, changes)}
					{this.generateReasonValue(reason_int, changes, reason_txt)}
				</tr>
			)
		})
	}

	calculateECTStotal(data, changes){
		if(changes){
			let ectsRemoved = 0;
			let ectsAdded = 0;
			data.map(({ECTS, isdeleted}) => {
				if(isdeleted){
					ectsRemoved += ECTS;
				}
				else{
					ectsAdded += ECTS;
				}
			});
			let ectsDifference = ectsAdded - ectsRemoved;
			return(
				<tr>
					<td></td>
					<td className="italic">Total ECTS credits</td>
					<td className="italic">
						<span className="succes">{ectsAdded}</span>
						<span className="error"> - {ectsRemoved} </span>
						= {ectsDifference}
					</td>
				</tr>
			)
		}
		else{
			let totalECTS = 0;
			data.map((courseData, index) => {
				totalECTS += courseData.ECTS;
			});
			return(
				<tr>
					<td></td>
					<td className="italic">Total ECTS credits</td>
					<td className="italic">{totalECTS}</td>
				</tr>
			)
		}
	}

	confirmSignature(event, olaID, email, changes, changesOlaID, typeOfSigning){
		event.preventDefault();
		let signatureAsDataString = this.refs[`signPad${typeOfSigning}`].getCanvas().toDataURL();

		if(this.refs[`signPad${typeOfSigning}`].isEmpty()){
			this.setState({
				errorSigning: true,
				errorTextSigning: "Please give a signature inside the box (use your mouse)"
			});
		}
		else if(signatureAsDataString.length < 5500){
			this.setState({
				errorSigning: true,
				errorTextSigning: "Please give a larger signature"
			});
		}
		else{
			this.setState({
				errorSigning: false,
				errorTextSigning: ""
			}, () => {
				signatureAsDataString = signatureAsDataString.replace("data:image/png;base64,", "");
				const variables = {
					SignatureDataString: signatureAsDataString,
					OlaID: olaID,
					Email: email,
					TypeOfStudent: this.typeOfStudentName,
					Changes: changes,
					ChangesOlaID: changesOlaID
				}
				this.props.signOLA({
					variables,
					refetchQueries: this.getRefetchQuery(typeOfSigning)
				}).then(() => {
					localStorage.removeItem("studentSelection"+this.props.typeOfStudentName);
					this.props.data.refetch();
					this.forceUpdate();
				});
			});
		}
	}
	declineOLA(event, olaID, email, changes){
		event.preventDefault();
		if(this.validateDeclining()){
			const variables = {
				reason: this.state.valueDeclining,
				olaID,
				email,
				TypeOfStudent: this.typeOfStudentName,
				changes,
				ErasmusCode: localStorage.getItem("ErasmusCode"),
				UnknownEmail: localStorage.getItem("unknownEmail")
			}
			let typeOfSigning = "signedBefore";
			if(changes){
				typeOfSigning = "signedDuring";
			}
			this.props.declineOLA({
				variables,
				refetchQueries: this.getRefetchQuery(typeOfSigning)
			});
		}
	}

	clearSignature(event, typeOfSigning){
		event.preventDefault();
		this.refs[`signPad${typeOfSigning}`].clear();
	}

	getRefetchQuery(typeOfSigning){
		let filters;
		if(localStorage.getItem("filters"+this.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.typeOfStudentName));
		}

		const filterVariables = {
			Sending: this.typeOfStudents,
			CountryName: filters["country"],
			InstitutionName: filters["institution"],
			SearchQuery: filters["search"],
			SignedBefore: Number(filters["signedBefore"]),
			SignedDuring: Number(filters["signedDuring"]),
			StartMobility: filters["startMobility"],
			EndMobility: filters["endMobility"],
			Uploaded: filters["uploaded"],
			AcademicYear: filters["academicYear"],
			StudyCycle: filters["studyCycle"],
			SubjectArea: filters["subjectArea"],
			TypeOfSigning: typeOfSigning
		}
		let filterVariablesStudent = filterVariables;
		filterVariablesStudent["Offset"] = 0;
		filterVariablesStudent["AmountOfStudents"] = 50;
		filterVariablesStudent["SortBy"] = localStorage.getItem("sortBy"+this.typeOfStudentName);
		filterVariablesStudent["SortDirection"] = localStorage.getItem("sortDirection"+this.typeOfStudentName);

		console.log(filterVariablesStudent);
		let returnArray = [
			{
				query,
				variables: { encryptedOlaID: this.props.match.params.olaID}
			},
			{
				query: getStudent,
				variables: filterVariablesStudent
			},
			{
				query: getStudentCountries,
				variables: filterVariables
			},
			{
				query: getStudentInstitutions,
				variables: filterVariables
			},
			{
				query: getStudentSignedBy,
				variables: filterVariables
			},
			{
				query: getStudentSignedBy,
				variables: filterVariables
			},
			{
				query: getStudentSubjectArea,
				variables: filterVariables
			},
			{
				query: getStudentUploaded,
				variables: filterVariables
			},
			{
				query: getStudentStudyCycle,
				variables: filterVariables
			},
			{
				query: getStudentAcademicYear,
				variables: filterVariables
			}
		]
		return returnArray;
	}


	renderSignOLAbox(status, sendResEmail, sendEmail, recResEmail, receiveEmail, olaID, email, changes, changesOlaID){
		const loginAccountEmail = GlobalVar.decryptWithKey(localStorage.getItem("Email"));
		if(this.typeOfStudentName === "Outgoing"){
			if(sendResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(sendResEmail !== ""){
						return <p className="italic block_text">Only OLA´s with the same Home responsible email as your login email can be signed</p>;
					}
					if(sendEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						return <p className="italic block_text">Only OLA´s with the same Home responsible email as your login email can be signed</p>;
					}
			}
			if(Number(status) === 3){
				return <p className="italic block_text">Already signed by everyone</p>;
			}
			if(Number(status) === 2){
				return <p className="italic block_text">Signed by sending institution</p>;
			}
			if(Number(status) <= 0){
				return <p className="italic block_text">Before you can sign the OLA, it needs a student signature</p>;
			}
		}
		else if(this.typeOfStudentName === "Incoming"){
			if(recResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(recResEmail !== ""){
						return <p className="italic block_text">Only OLA´s with the same Host responsible email as your login email can be signed</p>;
					}
					if(receiveEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						return <p className="italic block_text">Only OLA´s with the same Host responsible email as your login email can be signed</p>;
					}
			}
			if(Number(status) === 3){
				return <p className="italic block_text">Signed by everyone</p>;
			}
			if(Number(status) === 1){
				return <p className="italic block_text">Before you can sign the OLA, it needs a signature from the sending institution</p>;
			}
			if(Number(status) <= 0){
				return <p className="italic block_text">Before you can sign the OLA, it needs a student signature</p>;
			}
		}


		let typeOfSigning = "signedBefore";
		if(changes){
			typeOfSigning = "signedDuring";
		}
		return (
			<div>
				<SignatureCanvas penColor='black' ref={`signPad${typeOfSigning}`} canvasProps={{width: 300, height: 200, className: 'signingCanvas'}} />
				{this.generateSigningErrorMessage()}
				<div className="button_list">
					<form onSubmit={(event) => this.confirmSignature(event, olaID, email, changes, changesOlaID, typeOfSigning)}>
						<button>Confirm</button>
					</form>
					<form onSubmit={(event) => this.clearSignature(event, typeOfSigning)} >
						<button>Clear</button>
					</form>
				</div>
				<p className="block_text">Commitment
					<br/>
					By signing this document, the student, the sending institution and the receiving institution confirm that they approve the proposed Learning Agreement and that they will comply with all the arrangements agreed by all parties. Sending and receiving institutions undertake to apply all the principles of the Erasmus Charter for Higher Education relating to mobility for studies (or the principles agreed in the inter-institutional agreement for institutions located in partner countries).
					<br/>
					The receiving institution confirms that the educational components listed in Table A are in line with its course catalogue.
					<br/>
					The sending institution commits to recognise all the credits gained at the receiving institution for the successfully completed educational components and to count them towards the student´s degree as described in Table B. Any exceptions to this rule are documented in an annex of this Learning Agreement and agreed by all parties.
					<br/>
					The student and receiving institution will communicate to the sending institution any problems or changes regarding the proposed mobility programme, responsible persons and/or study period.
				</p>
			</div>
		);
	}


	renderDeclineOLA(status, olaID, email, changes){
		if(this.typeOfStudentName === "Outgoing"){
			if(Number(status) !== 1){
				return <p className="italic block_text">Only OLA´s that student has signed can be declined</p>;
			}
		}
		if(this.typeOfStudentName === "Incoming"){
			if(Number(status) !== 2){
				return <p className="italic block_text">Only OLA´s that student+home institution has signed can be declined</p>;
			}
		}
		return(
			<div>
				<textarea onChange={(event) => this.setState({valueDeclining: event.target.value})} value={this.state.valueDeclining} placeholder="Fill in reason of declining*" />
				{this.generateDecliningErrorMessage()}
				<p className="block_text declining_text">
				We want to draw your attention that by declining OLA, all the signatures will be removed and student(s) will be able to edit the Online Learning Agreement to introduce your proposed changes.
				Your comments will be emailed to the student(s).
				</p>
				<div className="button_list">
					<form onSubmit={(event) => this.declineOLA(event, olaID, email, changes)}>
						<button className="decline_button">Confirm declining</button>
					</form>
				</div>
			</div>
		)
	}

	validateDeclining(){
		if(this.state.valueDeclining === ""){
			this.setState({
				errorDeclining: true,
				errorDecliningText: "You have to give a reason why you are declining the OLA"
			})
			return false;
		}
		this.setState({
			errorDeclining: false,
			errorDecliningText: ""
		})
		return true
	}

	generateDecliningErrorMessage(){
		if(this.state.errorDeclining){
			return <p className="error error_text">{this.state.errorDecliningText}</p>;
		}
	}

	generateSigningErrorMessage(){

		if(this.state.errorSigning){
			return <p className="error error_text">{this.state.errorTextSigning}</p>;
		}
	}

	generateDownloadButton(olaID, studentID, changesOlaID){
		if(this.state.processingDownloadOLA){
			return <form onSubmit={(event) => {event.preventDefault()}}><button className="loading_button"><img className="spinner" src="/icons/spinner.svg"/></button></form>;
		}
		return <form onSubmit={(event) => this.downloadOLA(event, olaID, studentID, changesOlaID)}><button>Download OLA</button></form>;
	}

	downloadOLA(event, olaID, studentID, changesOlaID){
		event.preventDefault();
		this.setState({
			processingDownloadOLA: true
		})
		axios.post(`${GlobalVar.getServerLink()}/initiateDownloadOLAs`, querystring.stringify({
			studentIDlist: JSON.stringify([{studentID, olaID, changesOlaID}]),
			typeOfStudentName: this.typeOfStudentName
		})).then((response) => {
			if(response.data){
				setTimeout(function () {
					window.location.replace(`${GlobalVar.getServerLink()}/download/${response.data}`);
				}, 3000);
				this.setState({
					processingDownloadOLA: false
				})
			}
		});
	}

	generateLatestChangesAgreement(changesStatus, changesOlaID, HomeCoursesDuring, AbroadCoursesDuring, sendResEmail, sendEmail, recResEmail, receiveEmail, email, olaID){
		if(changesOlaID){
			return(
				<div>
					<div className="sub_content sub_content_subtitle">
						<h2>During mobility agreement (Changes to learning agreement)</h2>
						<p>Signed by: {this.renderSignedBy(changesStatus)}</p>
					</div>
					<div className="sub_content">
						<h3>Home subjects</h3>
						{this.renderSubjectTable(HomeCoursesDuring, true)}
					</div>
					<div className="sub_content">
						<h3>Host courses</h3>
						{this.renderSubjectTable(AbroadCoursesDuring, true)}
					</div>
					<div className="sub_content sub_content_half">
						<h3>Sign OLA</h3>
						{this.renderSignOLAbox(changesStatus, sendResEmail, sendEmail, recResEmail, receiveEmail, olaID, email, 1, changesOlaID)}
					</div>
					<div className="sub_content sub_content_half">
						<h3>Decline OLA</h3>
						{this.renderDeclineOLA(changesStatus, changesOlaID, email, 1)}
					</div>
				</div>
			)
		}
	}

	render() {
		if(this.props.data.loading){
	  	return (
	  		<div>
	    		<div onClick={() => this.toPreviousPage()} className="black_background"></div>
	    		<div className="content popup">
	    		</div>
	  		</div>
	    	);
	  	}
	  	else if(this.props.data.singleStudent === null){
	  		return (
	  		<div>
	    		<div onClick={() => this.toPreviousPage()} className="black_background"></div>
	    		<div className="content popup">
	    			<h2>Student not found</h2>
	    		</div>
	  		</div>
	    	);
	  	}
	  	else{
	  		const data = this.props.data.singleStudent;

	  		const {
					olaID, studentID, birthday, birthmonth, birthyear, email, firstname, name, nationality, phone, sex, status, subjectArea, subjectAreaCode,
	  			facultySend, departmentSend, facultyRecive, departmentRecive, cycle, receiveContactname, receiveEmail,
	  			receivePhone, receiveFunction, sendContactname, sendEmail, sendPhone, sendFunction, language, languagelevel,
	  			sendResName, sendResFun, sendResPhone, sendResEmail, recResName, recResFun, recResPhone, recResEmail, countrySend, countryRecive, uniNameRecive, uniNameSend, HomeCoursesBefore, AbroadCoursesBefore,
					changesStatus, changesOlaID, AbroadCoursesDuring, HomeCoursesDuring
				} = data;

				let documentTitle = document.title;
				documentTitle = documentTitle.replace("Detail", `${firstname} ${name}`);
				document.title = documentTitle;

	  		let birthdate = `${birthday}-${birthmonth}-${birthyear}`;
	  		let gender = "";

	  		if(sex === "F"){
	  			gender = "Female";
	  		}
	  		if(sex === "M"){
	  			gender = "Male";
	  		}
	  		return (
	  		<div>
	    		<div onClick={() => this.toPreviousPage()} className="black_background"></div>
	    		<div className="content popup student_popup">
						<div className="header">
	    				<h2>{firstname} {name}</h2>
							<form onSubmit={(event) => this.toPreviousPage(event)} className="close_button_wrapper">
								<button>
									<img src="/icons/remove.svg" />
								</button>
							</form>
						</div>
						<div className="popup_scroller">
		    			<div className="sub_content">
		    				<h3>Personal data</h3>
		    				<ul className="regular_list">
		    					<li><span className="label">Firstname</span><span className="value">{this.findValue(firstname)}</span></li>
		    					<li><span className="label">Lastname</span><span className="value">{this.findValue(name)}</span></li>
		    					<li><span className="label">Email</span><span className="value"><a href={`mailto:${email}`}>{this.findValue(email)}</a></span></li>
		    					<li><span className="label">Phone number</span><span className="value">{this.findValue(phone)}</span></li>
									<li><span className="label">Nationality</span><span className="value">{this.findValue(nationality)}</span></li>
			    				<li><span className="label">Birth date</span><span className="value">{this.findValue(birthdate)}</span></li>
		    				</ul>
		    				<ul className="regular_list">
			    				<li><span className="label">Gender</span><span className="value">{this.findValue(gender)}</span></li>
			    				<li><span className="label">Language</span><span className="value">{this.findValue(language)} ({this.findValue(languagelevel)})</span></li>
									<li><span className="label">Subject area</span><span className="value">{this.findValue(subjectArea)}</span></li>
			    				<li><span className="label">Subject area code</span><span className="value">{this.findValue(subjectAreaCode)}</span></li>
			    				<li><span className="label">Study cycle</span><span className="value">{this.findValue(cycle)}</span></li>
		    				</ul>
		    				<ul className="regular_list">

		    				</ul>
		    			</div>
		    			<div className="sub_content">
		    				<h3>Sending institution info</h3>
		    				<ul className="regular_list">
		    					<li><span className="label">Name</span><span className="value">{this.findValue(uniNameSend)}</span></li>
		    					<li><span className="label">Location</span><span className="value">{this.findValue(countrySend)}</span></li>
		    					<li><span className="label">Faculty</span><span className="value">{this.findValue(facultySend)}</span></li>
		    					<li><span className="label">Department</span><span className="value">{this.findValue(departmentSend)}</span></li>
		    				</ul>
		    				<ul className="regular_list">
		    					<li><span className="label">Contact person</span><span className="value">{this.findValue(sendContactname)}</span></li>
		    					<li><span className="label">Contact email</span><span className="value"><a href={`mailto:${sendEmail}`}>{this.findValue(sendEmail)}</a></span></li>
		    					<li><span className="label">Contact phone</span><span className="value">{this.findValue(sendPhone)}</span></li>
		    					<li><span className="label">Contact function</span><span className="value">{this.findValue(sendFunction)}</span></li>
									<li><span className="label">Res. person</span><span className="value">{this.findValue(sendResName)}</span></li>
		    					<li><span className="label">Res. email</span><span className="value"><a href={`mailto:${sendEmail}`}>{this.findValue(sendResEmail)}</a></span></li>
		    					<li><span className="label">Res. phone</span><span className="value">{this.findValue(sendResPhone)}</span></li>
		    					<li><span className="label">Res. function</span><span className="value">{this.findValue(sendResFun)}</span></li>
		    				</ul>
		    				<ul className="regular_list">

		    				</ul>
		    			</div>

		    			<div className="sub_content">
		    				<h3>Receiving institution info</h3>
		    				<ul className="regular_list">
		    					<li><span className="label">Name</span><span className="value">{this.findValue(uniNameRecive)}</span></li>
		    					<li><span className="label">Location</span><span className="value">{this.findValue(countryRecive)}</span></li>
		    					<li><span className="label">Faculty</span><span className="value">{this.findValue(facultyRecive)}</span></li>
		    					<li><span className="label">Department</span><span className="value">{this.findValue(departmentRecive)}</span></li>
		    				</ul>
		    				<ul className="regular_list">
		    					<li><span className="label">Contact person</span><span className="value">{this.findValue(receiveContactname)}</span></li>
		    					<li><span className="label">Contact email</span><span className="value"><a href={`mailto:${receiveEmail}`}>{this.findValue(receiveEmail)}</a></span></li>
		    					<li><span className="label">Contact phone</span><span className="value">{this.findValue(receivePhone)}</span></li>
		    					<li><span className="label">Contact function</span><span className="value">{this.findValue(receiveFunction)}</span></li>
									<li><span className="label">Res. person</span><span className="value">{this.findValue(recResName)}</span></li>
		    					<li><span className="label">Res. email</span><span className="value"><a href={`mailto:${sendEmail}`}>{this.findValue(recResEmail)}</a></span></li>
		    					<li><span className="label">Res. phone</span><span className="value">{this.findValue(recResPhone)}</span></li>
		    					<li><span className="label">Res. function</span><span className="value">{this.findValue(recResFun)}</span></li>
		    				</ul>

		    			</div>

							<div className="sub_content sub_content_subtitle">
								<h2>Before mobility agreement</h2>
								<p>Signed by: {this.renderSignedBy(status)}</p>
							</div>
		    			<div className="sub_content">
		    				<h3>Home subjects</h3>
		    				{this.renderSubjectTable(HomeCoursesBefore)}
		    			</div>
		    			<div className="sub_content">
		    				<h3>Host courses</h3>
		    				{this.renderSubjectTable(AbroadCoursesBefore)}
		    			</div>
		    			<div className="sub_content sub_content_half">
		    				<h3>Sign OLA</h3>
		    				{this.renderSignOLAbox(status, sendResEmail, sendEmail, recResEmail, receiveEmail, olaID, email)}
		    			</div>

		    			<div className="sub_content sub_content_half">
		    				<h3>Decline OLA</h3>
		    				{this.renderDeclineOLA(status, olaID, email)}
		    			</div>
		    			<div className="sub_content sub_content_half">
		    				<h3>Download documents</h3>
		    				{this.generateDownloadButton(olaID, studentID, changesOlaID)}
		    			</div>

							{
								this.generateLatestChangesAgreement(
									changesStatus,
									changesOlaID,
									HomeCoursesDuring,
									AbroadCoursesDuring,
									sendResEmail,
									sendEmail,
									recResEmail,
									receiveEmail,
									email,
									olaID
								)
							}
		    		</div>
					</div>
	  		</div>
	    	);
	  	}
    }
};

const signOLA = gql`
	mutation signOLA($OlaID:Int!, $SignatureDataString:String, $Email:String, $TypeOfStudent: String, $Changes: Int, $ChangesOlaID: Int){
	  signOLA(OlaID:$OlaID, SignatureDataString:$SignatureDataString, Email:$Email, TypeOfStudent: $TypeOfStudent, Changes: $Changes, ChangesOlaID: $ChangesOlaID){
	    olaID
	  }
	}
`
const declineOLA = gql`
	mutation DeclineOLA($olaID: Int!, $reason: String, $email: String, $TypeOfStudent: String, $changes: Int, $ErasmusCode: String, $UnknownEmail: String){
	  declineOLA(OlaID: $olaID, Reason: $reason, Email: $email, TypeOfStudent: $TypeOfStudent, Changes: $changes, ErasmusCode: $ErasmusCode, UnknownEmail: $UnknownEmail){
	    olaID
	  }
	}
`

export default compose(
	graphql(query, {
		options: (props) => {return {
			variables: { encryptedOlaID: props.match.params.olaID}
		}}
	}),
	graphql(signOLA, { name: 'signOLA'}),
	graphql(declineOLA, { name: 'declineOLA'}),
)(Popup);
