import React, { Component } from "react";
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Title from '../common/Title';
import Label from '../common/Label';
import TextArea from '../common/TextArea';
import Select from '../common/Select';
import GlobalVar from '../../../GlobalVar';
import Validator from '../common/Validator';

class CalendarInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creatorFactWebsite: "",
            partnerStaticData: null,
            partnerStaticDataLoading: true,
            errorMessage: ""
        }

        this.checkPartnerInEWPandGetData();
    }

    render() {
        if (!this.props.data.loading && (!this.props.data.error && this.props.data.getIIADefaultData != null)) {
            const data = this.props.data.getIIADefaultData;
            
            return (
                <div className={this.props.className}>
                    <div className="custom_table" id="partner_data" style={{ height: "calc(100% - 40px)" }}>
                        <Title text="Your Static Information" />
                        {this.renderInitialInformation()}
                        <hr />
                        {this.renderCalendar(data)}
                        <hr />
                        {this.renderAdditionalRequirements(data)}
                        <hr />
                        {this.renderInfrastructure(data)}
                        {this.renderSupportServices(data)}
                        <hr />
                        {this.renderHousing(data)}
                        <hr />
                        {this.renderVisa(data)}
                        <hr />
                        {this.renderInsurance(data)}
                        <hr />
                        {this.renderAdditionalInformation(data)}
                        <hr />
                        {this.renderToRInformation(data)}
                        <hr />
                        <hr />
                        <hr />
                        <Title text="Your Partner Static Information" />
                        <hr />
                        {this.renderPartnerData()}
                    </div>   
                </div>
            );
        }
        else if (this.props.data.error || this.props.data.getIIADefaultData == null) {
            return (
                <div className={this.props.className}>
                    <div className="custom_table" id="partner_data" style={{ height: "calc(100% - 40px)" }}>
                        <Title text="Your Static Information" />
                        <hr />
                        <h3 style={{ marginBottom: "3px" }}>Your organisation don't have any data on the system yet. To generate them, please use the menu <b style={{ fontWeight: "bold" }}>Default IIA Data</b> from the menu.</h3>

                        <br />
                        <hr />
                        <hr />
                        <hr />
                        <Title text="Partner Static Information" />
                        <hr />
                        {this.renderPartnerData()}
                    </div>
                </div>
            );
        }
        return (
            <div className="content upload">
                <Title text="Loading and setting up all forms..." />
                <h4>If loading is taking a while, please refresh the page.</h4>
            </div>
        );
    }

    renderInitialInformation() {
        return (
            <React.Fragment>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", marginLeft: "10px" }}>If you want to edit this information, please use the menu <b style={{ fontWeight: "bold" }}>Default IIA Data</b> from the menu.</p>
                </div>
            </React.Fragment>
        );
    }

    renderCalendar(data) {
        let autumnStuDate = data.stuNomAutumnTerm;
        let springStuDate = data.stuNomSpringTerm;
        let autumnAppDate = data.appNomAutumnTerm;
        let springAppDate = data.appNomSpringTerm;

        if (data.stuNomAutumnTerm.includes('-')) { autumnStuDate = data.stuNomAutumnTerm.split('-')[2] + "/" + data.stuNomAutumnTerm.split('-')[1] + "/" + data.stuNomAutumnTerm.split('-')[0]; }
        if (data.stuNomSpringTerm.includes('-')) { springStuDate = data.stuNomSpringTerm.split('-')[2] + "/" + data.stuNomSpringTerm.split('-')[1] + "/" + data.stuNomSpringTerm.split('-')[0]; }
        if (data.appNomAutumnTerm.includes('-')) { autumnAppDate = data.appNomAutumnTerm.split('-')[2] + "/" + data.appNomAutumnTerm.split('-')[1] + "/" + data.appNomAutumnTerm.split('-')[0]; }
        if (data.appNomSpringTerm.includes('-')) { springAppDate = data.appNomSpringTerm.split('-')[2] + "/" + data.appNomSpringTerm.split('-')[1] + "/" + data.appNomSpringTerm.split('-')[0]; }

        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Calendar"/>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>Student nominations must reach the receiving institution by:</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Autumn Term" />
                        {this.formatValue(autumnStuDate)}
                    </div>
                    <div className="column">
                        <Label text="Spring Term" />
                        {this.formatValue(springStuDate)}
                    </div>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>Applications from nominated students must reach the receiving institution by:</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Autumn Term" />
                        {this.formatValue(autumnAppDate)}
                    </div>
                    <div className="column">
                        <Label text="Spring Term" />
                        {this.formatValue(springAppDate)}
                    </div>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>Application procedure for nominated students</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Contact Email" />
                        {this.formatValue(data.appProcedureEmail)}
                    </div>
                    <div className="column">
                        <Label text="Contact Phone" />
                        {this.formatValue(data.appProcedurePhone)}
                    </div>
                    <div className="column">
                        <Label text="Contact Website" />
                        {this.formatValue(data.appProcedureWebsite)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderAdditionalRequirements(data) {
        if (!this.props.getIscedFCodesList.loading) {
            if (data.additionalRequirements.length === 0) {
                return (
                    <React.Fragment>
                        <div className="row">
                            <Label text="Additional Requirements"/>
                        </div>
                        <div className="row header_row">
                            <div className="column">
                                <Label text="Requirement" />
                                {this.formatValue("")}
                            </div>
                            <div className="column">
                                <Label text="Details" />
                                {this.formatValue("")}
                            </div>
                            <div className="column">
                                <Label text="Website for information" />
                                {this.formatValue("")}
                            </div>
                        </div>
                        <div className="row">
                            <p style={{fontWeight: "bold"}}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                        </div>
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <div className="row">
                        <Label text="Additional Requirements"/>
                    </div>
                    {data.additionalRequirements.map((requirement, index) => {
                        var reqNumber = "Requirement " + (index+1)
                        let addReqCode =                           
                            <div className="column">
                                <Label text="Details" />
                                {this.formatValue(requirement.details)}
                            </div>
                        ;
                        if (requirement.requirement === "Academic requirements") {
                            let iscedFCodes = "";
                            requirement.isced.split(";").map(v => {
                                this.props.getIscedFCodesList.getIscedFCodesList.map(data => {
                                    if (data.code === v) { 
                                        iscedFCodes = iscedFCodes.concat(data.code + " - " + data.name + "\n"); 
                                        return;
                                    }
                                });
                            });
                            addReqCode =   
                                <React.Fragment>
                                    <div className="column">
                                        <Label text="Number of ECTS completed"/>
                                        {this.formatValue(requirement.ects)}
                                    </div>
                                    <div className="column">
                                        <Label text="Subject area code - ISCED"/>
                                        <textarea rows="5" style={{height:"auto", whiteSpace: "pre-wrap", overflowY: "scroll"}} disabled>{iscedFCodes}</textarea>
                                    </div>
                                    <div className="column">
                                        <Label text="EQF level"/>
                                        {this.formatValue(requirement.eqf)}
                                    </div>
                                </React.Fragment>                          
                            ;
                        }
                        return (
                            <React.Fragment>
                                <div className="row header_row">
                                    <Label text={reqNumber} />
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <Label text="Requirement" />
                                        {this.formatValue(requirement.requirement)}
                                    </div>
                                    {addReqCode}
                                    <div className="column">
                                        <Label text="Website for information" />
                                        {this.formatValue(requirement.website)}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="row">
                        <p style={{fontWeight: "bold"}}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                    </div>
                </React.Fragment>
            );
        }
    }

    renderInfrastructure(data) {
        if (data.inclusionInfrastructure.length === 0) {
            return (
                <React.Fragment>
                    <div className="row">
                        <Label text="Inclusion and Accessibility"/>
                    </div>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold"}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    </div>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Infrastructure name" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Available infrastructure adjusted for people with" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label newLine={true} text="Description of infrastructure" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label newLine={true} text="Contact Email" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label newLine={true} text="Contact Phone" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label newLine={true} text="Website for information" />
                            {this.formatValue("")}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Inclusion and Accessibility"/>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                </div>
                {data.inclusionInfrastructure.map((inclusion, index) => {
                    var incNumber = "Infrastructure " + (index+1);
                    return (
                        <React.Fragment>
                            <div className="row">
                                <Label text={incNumber} />
                            </div>
                            <div className="row header_row">
                                <div className="column">
                                    <Label text="Infrastructure name" />
                                    {this.formatValue(inclusion.name)}
                                </div>
                                <div className="column">
                                    <Label text="Available infrastructure adjusted for people with" />
                                    {this.formatValue(inclusion.specialNeeds)}
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="Description of infrastructure" />
                                    {this.formatValue(inclusion.description)}
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="Contact Email" />
                                    {this.formatValue(inclusion.email)}
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="Contact Phone" />
                                    {this.formatValue(inclusion.phone)}
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="Contact Website" />
                                    {this.formatValue(inclusion.website)}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderSupportServices(data) {
        if (data.inclusionService.length === 0) {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Service name" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Available support services for people with" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Description of support services" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Contact Email" />
                            {this.formatValue("")}

                        </div>
                        <div className="column">
                            <Label text="Contact Phone" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Website for information" />
                            {this.formatValue("")}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {data.inclusionService.map((service, index) => {
                    var incNumber = "Support Service " + (index+1);
                    return (
                        <React.Fragment>
                            <div className="row">
                                <Label text={incNumber} />
                            </div>
                            <div className="row header_row">
                                <div className="column">
                                    <Label text="Service name" />
                                    {this.formatValue(service.name)}
                                </div>
                                <div className="column">
                                    <Label text="Available support service for people with" />
                                    {this.formatValue(service.specialNeeds)}
                                </div>
                                <div className="column">
                                    <Label text="Description of support service" />
                                    {this.formatValue(service.description)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Email" />
                                    {this.formatValue(service.email)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Phone" />
                                    {this.formatValue(service.phone)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Website" />
                                    {this.formatValue(service.website)}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderHousing(data) {
        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Housing"/>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Contact Email" />
                        {this.formatValue(data.housing_email)}
                    </div>
                    <div className="column">
                        <Label text="Contact Phone" />
                        {this.formatValue(data.housing_phone)}
                    </div>
                    <div className="column">
                        <Label text="Contact Website" />
                        {this.formatValue(data.housing_website)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderVisa(data) {
        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Visa"/>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Contact Email" />
                        {this.formatValue(data.visa_email)}
                    </div>
                    <div className="column">
                        <Label text="Contact Phone" />
                        {this.formatValue(data.visa_phone)}
                    </div>
                    <div className="column">
                        <Label text="Contact Website" />
                        {this.formatValue(data.visa_website)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderInsurance(data) {
        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Insurance"/>
                </div>
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:</p>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Contact Email" />
                        {this.formatValue(data.insurance_email)}
                    </div>
                    <div className="column">
                        <Label text="Contact Phone" />
                        {this.formatValue(data.insurance_phone)}
                    </div>
                    <div className="column">
                        <Label text="Contact Website" />
                        {this.formatValue(data.insurance_website)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderAdditionalInformation(data) {
        if (data.additionalInformation.length === 0) {
            return (
                <React.Fragment>
                    <div className="row">
                        <Label text="Additional Information"/>
                    </div>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Information on" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Contact Email" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Contact Phone" />
                            {this.formatValue("")}
                        </div>
                        <div className="column">
                            <Label text="Website for information" />
                            {this.formatValue("")}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="row">
                    <Label text="Additional Information"/>
                </div>
                {data.additionalInformation.map((info, index) => {
                    var infoNumber = "Additional Information " + (index+1);
                    return (
                        <React.Fragment>
                            <div className="column">
                                <Label text={infoNumber} />
                            </div>
                            <div className="row header_row">
                                <div className="column">
                                    <Label text="Information on" />
                                    {this.formatValue(info.information)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Email" />
                                    {this.formatValue(info.email)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Phone" />
                                    {this.formatValue(info.phone)}
                                </div>
                                <div className="column">
                                    <Label text="Contact Website" />
                                    {this.formatValue(info.website)}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderToRInformation(data) {
        return (
            <React.Fragment>
                <div className="row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>A Transcript of Records will be issued by the institution no later than {data.ToRWeeks} weeks after the assessment period has finished.</p>
                    <p style={{ marginBottom: "3px", fontWeight: "bold"}}>[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]</p>
                    <p>&nbsp;</p>
                    <p style={{ marginBottom: "3px", fontWeight: "bold", fontStyle: "italic"}}>*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</p>
                </div>
            </React.Fragment>
        );
    }

    formatValue(value) {
        if (value === undefined || value === null || value === "") {
            return <p>--------------</p>;
        }
        if (value.length > 28) {
            return <p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap"}}>{value}</p>
        }
        return <p>{value}</p>;
    }

    renderPartnerData() {
        console.log(this.state.partnerStaticData);
        
        if (this.state.partnerStaticDataLoading) {
            return (
                <p>Fetching the static data from EWP Partner. This will take just a few seconds.</p>
            );
        }
        else if (this.state.partnerStaticData == null) {
            if(this.state.errorMessage != "") {
                return (
                    <React.Fragment>
                        <h3>{this.state.errorMessage}</h3>
                    </React.Fragment>
                )
            }
            else {
                return (
                    <React.Fragment>
                        <h3>No information was retrieved from the partner.</h3>
                    </React.Fragment>
                )
            }
            
        } else {
            return (
                <React.Fragment>
                    {this.renderCalendar(this.state.partnerStaticData)}
                    <hr />
                    {this.renderAdditionalRequirements(this.state.partnerStaticData)}
                    <hr />
                    {this.renderInfrastructure(this.state.partnerStaticData)}
                    {this.renderSupportServices(this.state.partnerStaticData)}
                    <hr />
                    {this.renderHousing(this.state.partnerStaticData)}
                    <hr />
                    {this.renderVisa(this.state.partnerStaticData)}
                    <hr />
                    {this.renderInsurance(this.state.partnerStaticData)}
                    <hr />
                    {this.renderAdditionalInformation(this.state.partnerStaticData)}
                    <hr />
                    {this.renderToRInformation(this.state.partnerStaticData)}
                </React.Fragment>
            )
        }
    }

    checkPartnerInEWPandGetData() {
        this.props.getEWPStaticData({
            variables: {
                "PartnerErasmusCode": JSON.parse(localStorage.getItem("partnersDetails")).partnerErasmusCode,
                "clientHeiEC": localStorage.getItem("ErasmusCode")
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    partnerStaticData: null,
                    partnerStaticDataLoading: false
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch(x.data.getEWPStaticData.Success){
                    case 1:
                        //By a node in EWP
                        console.log(x.data.getEWPStaticData);
                        if(x.data.getEWPStaticData.StaticData != null) {
                            this.setState({
                                partnerStaticData: x.data.getEWPStaticData.StaticData,
                                partnerStaticDataLoading: false
                            });
                        }
                        break;
                    case 2:
                        this.getPartnerDataFromDashboard();
                        break;
                    default:
                        this.setState({
                            partnerStaticData: null,
                            partnerStaticDataLoading: false,
                            errorMessage: x.data.getEWPStaticData.Message
                        });
                        break;
                }
            }
        });
    }

    getPartnerDataFromDashboard() {
        console.log("Get partner data information from Dashboard.");
        this.props.getIIADefaultDataM({
            variables: {
                "ErasmusCode": JSON.parse(localStorage.getItem("partnersDetails")).partnerErasmusCode,
                "isToCreate": false
            },
        }).then((x) => {
            if(x.data.getIIADefaultData) {
                this.setState({
                    partnerStaticData: x.data.getIIADefaultData,
                    partnerStaticDataLoading: false
                });
            }
        }).catch((err) => {
            this.setState({
                partnerStaticData: null,
                partnerStaticDataLoading: false
            });
            console.log(err);
        });
    }
}

const getIIADefaultData = gql`
    query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
        getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
            stuNomAutumnTerm
            stuNomSpringTerm
            appNomAutumnTerm
            appNomSpringTerm
            appProcedureEmail
            appProcedurePhone
            appProcedureWebsite
            addReqWeeks
            ToRWeeks
            additionalRequirements {
                id
                requirement
                ects
                isced
                eqf
                details
                website
            }
            inclusionInfrastructure {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            inclusionService {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            housing_email
            housing_phone
            housing_website
            visa_email
            visa_phone
            visa_website
            insurance_email
            insurance_phone
            insurance_website
            additionalInformation {
                id
                information
                email
                phone
                website
            }
        }
    }
`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const getEWPStaticData = gql`
    mutation getEWPStaticData($PartnerErasmusCode: String!, $clientHeiEC: String!) {
        getEWPStaticData(PartnerErasmusCode: $PartnerErasmusCode, clientHeiEC: $clientHeiEC) {
            Success
            Message
            StaticData {
                stuNomAutumnTerm
                stuNomSpringTerm
                appNomAutumnTerm
                appNomSpringTerm
                appProcedureEmail
                appProcedurePhone
                appProcedureWebsite
                addReqWeeks 
                ToRWeeks
                additionalRequirements {
                    id
                    requirement
                    details
                    website
                }
                inclusionInfrastructure {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                inclusionService {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                housing_email
                housing_phone
                housing_website
                visa_email
                visa_phone
                visa_website
                insurance_email
                insurance_phone
                insurance_website
                additionalInformation {
                    id
                    information
                    email
                    phone
                    website
                }
            }
        }
    }
`;

const getIIADefaultDataM = gql`
    mutation getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
        getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
            stuNomAutumnTerm
            stuNomSpringTerm
            appNomAutumnTerm
            appNomSpringTerm
            appProcedureEmail
            appProcedurePhone
            appProcedureWebsite
            addReqWeeks
            ToRWeeks
            additionalRequirements {
                id
                requirement
                ects
                isced
                eqf
                details
                website
            }
            inclusionInfrastructure {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            inclusionService {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            housing_email
            housing_phone
            housing_website
            visa_email
            visa_phone
            visa_website
            insurance_email
            insurance_phone
            insurance_website
            additionalInformation {
                id
                information
                email
                phone
                website
            }
        }
    }
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

export default compose (
    graphql(getIIADefaultData, {
        // name: "getIIADefaultData",
		options: (props) => {return {
			variables: {
				ErasmusCode: GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode).toString(),
                isToCreate: false
			}
		}}
    }),
    graphql(isIIAManagedByEWP, {name: "isIIAManagedByEWP"}),    
    graphql(getEWPStaticData, {name: "getEWPStaticData"}),
    graphql(getIIADefaultDataM, {name: "getIIADefaultDataM"}),
    graphql(getIscedFCodesList, {name: "getIscedFCodesList"})
)(CalendarInfo);