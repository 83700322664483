import React, { Component } from 'react';
import Select from 'react-select';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query } from '../../../queries/getAccountData';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

import validator from 'validator';


class AccountForm extends Component {
	constructor(props) {
    super(props);
    this.state = {
      nameValue: "",
      emailValue: "",
      functionValue: "",
      errorTitle: "Email",
      phoneValue: "",
      defaultContact: "",
      error: false,
      checkedConsent: false
		}
  }

  changeValue(event, stateName){
    this.setState({
      [stateName]: event.target.value
    })
  }
  changeSingleSelect(functionValue){
    this.setState({ functionValue });
  }

  checkConsent(event){
    this.setState({ checkedConsent : !this.state.checkedConsent });
  }

  /*changeDefaultContactSelect(functionValue){
    this.setState({ "defaultContact": functionValue });
  }*/

  validate(){
    if(!this.state.checkedConsent){
      this.setState({
        error : true,
        errorTitle: "Please give consent on the treatment of data"
      })
      return false;
    }
    if(!validator.isEmail(this.state.emailValue)){
      this.setState({
        error : true,
        errorTitle: "Wrong email format"
      })
      return false;
    }
		if(!this.state.phoneValue.match(/^(?=.*[0-9])[- +()0-9]+$/)){
			this.setState({
        error : true,
        errorTitle: "Wrong phone format"
      })
      return false;
    }
    if(this.state.defaultContact.value == "in" && document.getElementsByClassName('defaultContact in').length > 0)
		{
			this.setState({
				errorTitle: "There's already an incoming default contact defined",
				error : true
			})
			return false;
		}
		if(this.state.defaultContact.value == "out" && document.getElementsByClassName('defaultContact out').length > 0)
		{
			this.setState({
				errorTitle: "There's already an outoing default contact defined",
				error : true
			})
			return false;
		}
    this.setState({
      errorTitle: "",
      error : false
    })
    return true;
  }

  addAccount(event){
    event.preventDefault();
    if(this.validate()){
      this.props.mutate({
        variables:{
          FunctionID: this.state.functionValue.value,
          ErasmusCode: localStorage.getItem('ErasmusCode'),
          Name: this.state.nameValue,
          Email: this.state.emailValue,
          Phone: this.state.phoneValue,
          DefaultContact: this.state.defaultContact ? this.state.defaultContact.value : ""
        },
        refetchQueries: [{query}]
      }).then((response) => {
        if(response.data.addAccount !== null){
          if(response.data.addAccount.id === "Email already exists in accounts"){
            this.setState({
              error : true,
              errorTitle: "The indicated email address is already in use. To avoid generation of redundant accounts, you can edit the permissions allocated to each role at any time."
            })
          }
          if(response.data.addAccount.id === "Email already exists in institutions"){
            this.setState({
              error : true,
              errorTitle: "Email is already in use at an institution account"
            })
          }
        }
        else{
          this.setState({
            nameValue: "",
            emailValue: "",
            functionValue: "",
            phoneValue:"",
            defaultContact: ""
          })
        }
      });
    }
  }
  renderAddButton(){
    if(this.state.functionValue === "" || this.state.nameValue === "" || this.state.emailValue === "" || this.state.phoneValue === ""){
      return <fieldset disabled><button className="top_fix">Add</button> </fieldset>
    }
    else return <button className="top_fix">Add</button>
  }
  generateError(){
    if(this.state.error){
      return <p style={{marginBottom: "5px"}} className="error">{this.state.errorTitle}</p>
    }
  }

  generateAccountFunctions(){
    let functions = [];
    this.props.accountFunctions.map(({Name, id}) => {
        functions.push({value: id, label: Name})
    });
    return functions;
  }

  generateDefaultContactOptions(){
    let options = [];
    options.push({value: "", label: "None"});
    options.push({value: "in", label: "Incoming"});
    options.push({value: "out", label: "Outgoing"});
    return options;
  }

	render() {
  	return (
  		<form onSubmit={(event) => this.addAccount(event)} noValidate>
        {this.generateError()}
        <div className="input_wrapper">
  				<input id="name_input" type="text" value={this.state.nameValue} onChange={(event) => this.changeValue(event, "nameValue")} placeholder="Name" className="top_fix" />
  				<input type="mail" value={this.state.emailValue} onChange={(event) => this.changeValue(event, "emailValue")} placeholder="Email" className="top_fix" />
  				<input value={this.state.phoneValue} onChange={(event) => this.changeValue(event, "phoneValue")} placeholder="Phone number " className="top_fix" />
          <Select placeholder="Select function" value={this.state.functionValue} onChange={(value) => this.changeSingleSelect(value)} name="form-field-name" options={this.generateAccountFunctions()} className="single_select" />
          {/*<Select placeholder="Default Contact" value={this.state.defaultContact} onChange={(value) => this.changeDefaultContactSelect(value)} name="form-field-name" options={this.generateDefaultContactOptions()} className="single_select" />*/}
        </div>
				{this.renderAddButton()}
        <div className="checkbox-wrapper">
          <input 
            className="checkbox" 
            type="checkbox" 
            checked={this.state.checkedConsent} 
            onChange={(event) => this.checkConsent(event)}
            required ></input>
          <label>I confirm, also on behalf of my HEI, that I have all the authorisations, 
          including the consent of the relevant natural persons, necessary to upload the 
          personal data and information I am providing, pursuant to the Dashboard {' '} 
          <Link to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")+"/termsandconditions"}>
          Terms & Conditions and Privacy Policy</Link>.
          </label>
        </div>
			</form>
    );
  }

};


const mutation = gql`
  mutation addAccount($Name: String!, $Email: String!, $ErasmusCode: String!, $FunctionID: ID!, $Phone: String!, $DefaultContact: String!){
    addAccount(Name:$Name, Email:$Email, ErasmusCode: $ErasmusCode, FunctionID: $FunctionID, Phone: $Phone, DefaultContact: $DefaultContact){
      id
    }
  }
`;

export default graphql(mutation)(AccountForm);
