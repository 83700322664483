import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import GlobalVar from '../../../GlobalVar';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';


class DeclineOLA extends Component{

    constructor(props) {
        super(props)
        this.state = {
            errorSigning: false,
            errorTextSigning: ""
        }    
    }

    generateSigningErrorMessage(){

        if(this.state.errorSigning){
            return <p className="error error_text">{this.state.errorTextSigning}</p>;
        }
    }

    clearSignature(event){
        event.preventDefault();
        this.refs[`signPad`].clear();
    }

    render(){
        const loginAccountName = localStorage.getItem("Name");
        const status = this.props.data.data.learningAgreement.field_la_status;
        const accounts = this.props.accounts;

        if(loginAccountName == undefined || loginAccountName.localeCompare("undefined") === 0 || !accounts.includes(loginAccountName)) {
            return (<div><p className="italic block_text">Please log in as a staff account to sign the Learning Agreement; main institutional accounts are not able to sign the OLAs. See more information <a href="https://wiki.uni-foundation.eu/display/DASH/Student+List+Management" target="_blank">here</a>.</p><p>&nbsp;</p><p className="italic block_text">If you are already using a staff account and still experiencing difficulties please log out from your current Dashboard session and log in again.</p></div>);
        }

        if(this.props.autoRecognitionData.length > 0){
            return <p className="italic block_text">This learning agreement currently does not require a signature.</p>;
        }

        if(this.props.typeOfStudent == 1) {
            // Outgoing student

            let statusArray = ["preliminary_signed-by-student", "final_signed-by-student"];

            if(!statusArray.includes(status) ) {
                return <p className="italic block_text">This learning agreement currently does not require a signature.</p>;
            }

        } else {
            // Incoming student

            let statusArray = ["preliminary_signed-by-student-sending", "final_signed-by-student-sending"];

            if(!statusArray.includes(status)) {
                return <p className="italic block_text">This learning agreement currently does not require a signature.</p>;
            }
        }
 
        return (
            <div>
                <SignatureCanvas penColor='black' ref={`signPad`} canvasProps={{width: 300, height: 200, className: 'signingCanvas'}} />
                {this.generateSigningErrorMessage()}
                <div className="button_list">
                    <form onSubmit={(event) => this.confirmSignature(event)}>
                        <button>Confirm</button>
                    </form>
                    <form onSubmit={(event) => this.clearSignature(event, 1)} >
                        <button>Clear Signature</button>
                    </form>

                </div>
                <p className="block_text">Commitment
                    <br/>
                    By digitally signing this document, the student,
                    the Sending Institution and the Receiving Institution confirm
                    that they approve the Learning Agreement and that they will comply
                    with all the arrangements agreed by all parties. Sending and
                    Receiving Institutions undertake to apply all the principles of the
                    Erasmus Charter for Higher Education relating to mobility for studies
                    (or the principles agreed in the Inter-Institutional Agreement for
                    institutions located in Partner Countries).
                    The Beneficiary Institution and the student should also commit to
                    what is set out in the Erasmus+ grant agreement. The Receiving Institution
                    confirms that the educational components listed are in line with its course
                    catalogue and should be available to the student.
                    The Sending Institution commits to recognise all the credits or
                    equivalent units gained at the Receiving Institution
                    for the successfully completed educational components and
                    to count them towards the student's degree.
                    The student and the Receiving Institution will communicate to
                    the Sending Institution any problems or changes regarding the study programme,
                    responsible persons and/or study period.
                </p>
            </div>
        );
    }

    confirmSignature(event){
        event.preventDefault();
        let signatureAsDataString = this.refs[`signPad`].getCanvas().toDataURL();

        if(this.refs[`signPad`].isEmpty()){
            this.setState({
                errorSigning: true,
                errorTextSigning: "Please give a signature inside the box (use your mouse)"
            });
        }
        else if(signatureAsDataString.length < 5500){
            this.setState({
                errorSigning: true,
                errorTextSigning: "Please give a larger signature"
            });
        }
        else{
            this.setState({
                errorSigning: false,
                errorTextSigning: ""
            }, async () => {
                signatureAsDataString = signatureAsDataString.replace("data:image/png;base64,", "");
                let variables = {};
                this.props.onProcessing(true)
                const loginAccountName = localStorage.getItem("Name");
                variables = {
                    olaID: this.props.data.data.learningAgreement.olaID,
                    email: GlobalVar.decryptWithKey(localStorage.getItem("Email")),
                    name: loginAccountName,
                    image: signatureAsDataString.replace("data:image/png;base64,", ""),
                    institution: this.props.typeOfStudent,
                    status: this.props.data.data.learningAgreement.field_la_status
                }
                if(this.props.typeOfStudent == 1) {
                    variables.position = "Responsible person at the Sending Institution";
                } else {
                    variables.position = "Responsible person at the Receiving Institution";
                }

                let data = await this.props.acceptOLA3({variables});

                if(data.data.acceptOLA3 == null){
                    this.props.onProcessing(false);
                    alert("Error on signing");
                    event.preventDefault();
                } else if(data.data.acceptOLA3.data.id != null){
                    this.props.onProcessing(false);
                    alert("Accept is successfully completed");
                    window.location.reload(false);
                }
            });
        }
    }

}

const acceptOLA3 = gql`
  mutation AcceptOLA3($olaID: String, $email: String, $name: String, $position: String, $image: String, $institution: Int, $status: String){
    acceptOLA3(olaID: $olaID, email: $email, name: $name, position: $position, image: $image, institution: $institution, status: $status)
  }`
export default compose(
    graphql(acceptOLA3, { name: 'acceptOLA3'})
)(DeclineOLA);