import React, { Component } from 'react';
import IIANominationInfo from './IIANominationInfo'

class IIAOther extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key : ""
    }
  }


  generateList(){

    return this.props.data.map((iiaNominations, index, arrayRef) => {
      return <IIANominationInfo key ={index} iiaNominations={iiaNominations} />;

    })
  }

  render() {
    return (
      <div>
        {this.generateList()}
      </div>
    );
  }
} export default IIAOther;
