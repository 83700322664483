import gql from 'graphql-tag';

const query = gql`
query{
    documents{
	    id
	    title
	    description
    }
}
`
export { query };
