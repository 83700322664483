import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import Main from './components/Main.js';
import GlobVar from './GlobalVar';

import intl from 'react-intl-universal';
import _ from "lodash";
import http from "axios";

const SUPPOER_LOCALES = [
  {
    name: "English",
    value: "en-US"
  },
  {
    name: "Dutch",
    value: "nl-BE"
  },
];

/*let networkInterface = new HttpLink({ uri: GlobVar.getServerLink() + '/graphql' }); */
/*let networkInterface = createNetworkInterface({ uri: GlobVar.getServerLink() + '/graphql' });*/
//let networkInterface = createNetworkInterface({ uri: 'https://unizoneserverdev.herokuapp.com/graphql' });
//let networkInterface = createNetworkInterface({ uri: 'https://uportoserver-dev.herokuapp.com/graphql' });
// let networkInterface = createNetworkInterface({ uri: 'https://unizoneserver.herokuapp.com/graphql' });


const client = new ApolloClient({
  link: createHttpLink({ uri: GlobVar.getServerLink() + '/graphql' }),
  cache: new InMemoryCache(),
  dataIdFromObject: o => o.id
});


class App extends Component {

  state = { initDone: false };

  componentDidMount() {
    this.loadLocales();
  }

  render() {
    return (
      this.state.initDone &&
      <ApolloProvider client={client}>
        <Router>
          <Route path={"/"} component={Main} />
        </Router>
      </ApolloProvider>
    );
  }

  loadLocales() {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang"
    });
    if (!_.find(SUPPOER_LOCALES, { value: currentLocale })) {
      currentLocale = "en-US";
    }
    http.get(`/locales/${currentLocale}.json`).then(res => {
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        });
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }
}

export default App;
