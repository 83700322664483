import React, { Component } from 'react';
import Select from 'react-select';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import {query as getCoopConditions} from '../../../queries/getCoopConditions';

class IncomingDestination extends Component {
	constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      idValue: props.nominations.id

  }
}

	render() {
			// style for span
		const spanStyle = {
			display: 'inline-block',
			marginBottom: '5px'
		}
		const flexStyleCenter = {
			display: 'flex',
			justifyContent: 'center'
		}
		const flexStyleRight = {
			display: 'flex',
			justifyContent: 'right'
		}
		const WaitingListButton = {
			backgroundColor: '#4CAF50',
			color: '#4CAF50',
			border: '#4CAF50'
		}
	return(

		<div key={this.props.nominations.id}>
			{ (this.props.coopConditions.loading) ? (
				<div className="row" id="" style={flexStyleCenter}><h4>Loading ...</h4></div>
			) : (
				(this.props.coopConditions.nominatedStudents && this.props.coopConditions.applicationCoopConditions.totalMonths && this.props.coopConditions.applicationCoopConditions.mobilitiesPerYear) ?
				<div>	<div className="row" id="" style={flexStyleRight}><h4>Available spots: {this.props.coopConditions.applicationCoopConditions.mobilitiesPerYear}</h4></div>
					<div className="row" id="" style={flexStyleRight}><h4>Available months: {this.props.coopConditions.applicationCoopConditions.totalMonths}</h4></div>
					<div className="row" id="" style={flexStyleRight}><h4>Students already nominated: {this.props.coopConditions.nominatedStudents.nominatedStudentsCount}</h4></div></div>
					:
					// case where query has returned no cooperation conditions data
					(<div className="row" id="" style={flexStyleRight}><h4>No cooperation conditions data</h4></div>)
			)}
				<div className="row" style={flexStyleCenter}>
					<div className="column" id="" style={flexStyleCenter}><h2>{this.props.nominations.field_home_name}</h2></div>
				</div>
				<div className="row" style={flexStyleCenter}>
					<div className="column" id="" style={flexStyleCenter}><h4 style={{ color: '#000000' }}>{this.props.nominations.field_home_org_unit}</h4></div>
				</div>
				<div className="row">
					<div className="column" id=""><a href={this.props.nominations.field_home_org_unit}></a></div>
				</div>
		</div>
	)
  }
}

export default compose(
	graphql(getCoopConditions, {
		name: "coopConditions",
		options: (props) => ({
			variables: {
				homeErasmusCode: props.nominations.field_home_erasmus_code,
				hostErasmusCode: props.nominations.field_host_erasmus_code,
				academicYear:"2022/2023",
				coopConditionsID: props.nominations.field_coop_condition_id
			}
		})
	})
)(IncomingDestination);
