import gql from 'graphql-tag';
const query = gql`
query getIIA($iiaCodeIn: String!) {
  getIIA(iiaCode: $iiaCodeIn) {
    iia_code_p1
    iia_id_p1
    iia_code_p2
    iia_id_p2
    academicYearStart
    academicYearEnd
    partner_contact
    creator_contact
    creator_signature_date
    creator_signature_contact
    partner_signature_date
    partner_signature_contact
    creator_institution_code
    partner_institution_code
    creatorGeneral
    creatorFaculty
    creatorCourse
    partnerGeneral
    partnerFaculty
    partnerCourse
    creatorOU {
      ounit_id
      name
      institution_ecode
      active
    }
    partnerOU {
      ounit_id
      name
      institution_ecode
      active
    }
    creator_institution_data {
      name
      erasmusCode
      city
      country
      contacts {
        name
        email
        phone
      }
      modified_vals
      factsheet
    }
    partner_institution_data {
      name
      erasmusCode
      city
      country
      contacts {
        name
        email
        phone
      }
      modified_vals
      factsheet
    }
    factsheet_data
    conditions {
      iscedFCodes {
        condId
        iscedFCode
        iscedClarification
      }
      mobilitiesPerYear
      senderInstitution
      receiverInstitution
      sendingOUnit {
        ounit_id
        name
        institution_ecode
        active
      }
      receivingOUnit {
        ounit_id
        name
        institution_ecode
        active
      }
      eqfLevels {
        condId
        eqfLevel
      }
      mobilitiesPerYear
      mobilityType
      totalDays
      totalMonths
      languages {
        cefrLevel
        language
        iscedFCode
        iscedClarification
      }
      blended
      otherInformation
    }
    typeOfCommunication
    inEffect
  }
}
`;

/*
aux{
      creatorStuNomAutumn
      creatorStuNomSpring
      partnerStuNomAutumn
      partnerStuNomSpring
      creatorAppNomAutumn
      creatorAppNomSpring
      partnerAppNomAutumn
      partnerAppNomSpring
      creatorAppProEmail
      creatorAppProPhone
      creatorAppProWebsite
      partnerAppProEmail
      partnerAppProPhone
      partnerAppProWebsite
      creatorInclusion
      creatorInclusionEmail
      creatorInclusionPhone
      creatorInclusionWebsite
      partnerInclusion
      partnerInclusionEmail
      partnerInclusionPhone
      partnerInclusionWebsite
      creatorHousingEmail
      creatorHousingPhone
      creatorHousingWebsite
      partnerHousingEmail
      partnerHousingPhone
      partnerHousingWebsite
      creatorVisaEmail
      creatorVisaPhone
      creatorVisaWebsite
      partnerVisaEmail
      partnerVisaPhone
      partnerVisaWebsite
      creatorInsuranceEmail
      creatorInsurancePhone
      creatorInsuranceWebsite
      partnerInsuranceEmail
      partnerInsurancePhone
      partnerInsuranceWebsite
      creatorAddInfo
      creatorAddInfoType
      creatorAddInfoWebsite
      partnerAddInfo
      partnerAddInfoType
      partnerAddInfoWebsite
    }
*/
export { query };
