import React, { Component } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
/*import { useQuery } from '@apollo/react-hooks';*/

import GlobalVar from '../../../../GlobalVar';

class UsefulInformationView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            visa: "<p>No information entered by the institution yet.</p>",
            insurance: "<p>No information entered by the institution yet.</p>",
            housing: "<p>No information entered by the institution yet.</p>",
            grading: "<p>No information entered by the institution yet.</p>",
            fcode: this.props.match.params.fcode
        }

        // this.props.getFactsheet({
		// 	variables: {
        //         "ErasmusCode": this.state.fcode
        //     }
        // });

        console.log(this.state);
    }

    render() {
        if(this.props.data.loading) {
			return (
				<div className="card card_full_width card_full_height">
					<div className="custom_table">
						<p><img className="spinner" src="/icons/spinner.svg"/>Loading Information...</p>
					</div>
				</div>
			);
		}else{
            if(this.props.data.error){
				return (
					<div className="card card_full_width card_full_height">
					    <div className="custom_table">
							<p><img className="spinner" src="/icons/spinner.svg"/>Error loading Information...</p>
						</div>
					</div>
				);
			}else{
                if(this.props.data.institutionFactsheet){
                    if (this.props.data.institutionFactsheet.institution.name != "") {
                        this.state.name = this.props.data.institutionFactsheet.institution.name;
                    }
                    if (this.props.data.institutionFactsheet.visa != "") {
                        this.state.visa = this.props.data.institutionFactsheet.visa;
                    }
                    if (this.props.data.institutionFactsheet.insurance != "") {
                        this.state.insurance = this.props.data.institutionFactsheet.insurance;
                    }
                    if (this.props.data.institutionFactsheet.housing != "") {
                        this.state.housing = this.props.data.institutionFactsheet.housing;
                    }
                    if (this.props.data.institutionFactsheet.grading != "") {
                        this.state.grading = this.props.data.institutionFactsheet.grading;
                    }
                }
                return(
					<div className="card card_full_width card_full_height">
                        <div className="custom_table">
                            <div className="row">
                                <h2>Useful Information of {this.state.name}</h2>
                            </div>
                            <div className="row">
                                <h3>Visa</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.visa}} />
                            </div>
                            <div className="row">
                                <h3>Insurance</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.insurance}} />
                            </div>
                            <div className="row">
                                <h3>Housing</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.housing}} />
                            </div>
                            <div className="row">
                                <h3>Grading</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.grading}} />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

}

const getFactsheet = gql`
    query getInstitutionFactsheet($erasmusCode: String!) {
        institutionFactsheet( ErasmusCode: $erasmusCode) {
            visa
            insurance
            housing
            grading
            institution {
                name
            }
        }
    }
`;

export default compose(
    graphql(getFactsheet, {
		options: (props) => {return {
            variables: { erasmusCode: GlobalVar.encryptWithKey(props.match.params.fcode).toString() }
		}}
	})
)(UsefulInformationView);