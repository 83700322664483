import React, { Component } from 'react';
import { query } from '../../../queries/getDefaultEmail';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

class DefaultEmail extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	id : "",
    	title : "",
    	bodytext : "",
    	buttonName : "Create"
    }
  }
    
  displayEmail(id,title,body){
  	this.setState({
      id : id,
      title : title,
      bodytext : body,
      buttonName : "Update"
  	})
  }

  editMailInfo(event){
  	event.preventDefault();
    if(this.state.buttonName === "Update"){
      this.props.updateInstitutionEmail({
				variables:{
				  "id"	: this.state.id,
				  "Title" : this.state.title,
				  "BodyText" : this.state.bodytext
				},
		    refetchQueries: [{query}]
	    })
	    this.setState({
        title : "",
        bodytext : "",
        buttonName : "Create"
      })
    }
    else{
    	if(this.state.title != "" && this.state.bodytext != ""){
    		let accID = localStorage.getItem('accountID')
        this.props.insertInstitutionEmail({
				variables:{
	        "ErasmusCode"	: localStorage.getItem('ErasmusCode'),
	        "Title" : this.state.title,
	        "BodyText" : this.state.bodytext
				},
			    refetchQueries: [{query}]
		    })
		    this.setState({
	        title : "",
	        bodytext : ""
        })
    	}
		}
  }

  handleTextInput(event){
  	event.preventDefault();
  	this.setState({title: event.target.value});
  }

  handleTextAreaInput(event){
  	event.preventDefault();
  	this.setState({bodytext: event.target.value});
  }

  removeMail(id,event){
  	event.preventDefault();
  	this.props.RemoveInstitutionEmail({
		variables:{
		    "id"	: id
		},
		refetchQueries: [{query}]
	})
	this.setState({
      	title : "",
      	bodytext : ""
      })
  }
  generateEmails(){
  	return this.props.data.institutionEmail.map(({ id, Title, BodyText }) => {
			return(
				<li key={id}><a onClick={this.displayEmail.bind(this, id, Title, BodyText)} >{Title}</a>
				<button className="remove_fix" onClick={this.displayEmail.bind(this, id, Title, BodyText)}>edit</button>
				<button className="remove_fix" onClick={this.removeMail.bind(this , id)}>remove</button></li>
			)
	})
  }

  clearInputData(event){
  	event.preventDefault();
  	this.setState({
      	title : "",
      	bodytext : "",
      	buttonName : "Create"
      })
  }

	render() {

    if(this.props.data.loading){
			return (
    		<div className="card card_half_width card_fixed_height">
    			<h2>Information</h2>
    		</div>
	    );
		} else {
	    	return (
	    		<div className="card card_half_width card_fixed_height mail_wrapper">
	    			<h2>Default emails</h2>
	    			<div className="sub_content">
	    				<h3>Mail list</h3>
	    				<ul>
	    					{this.generateEmails()}
	    				</ul>
	    				<button onClick={this.clearInputData.bind(this)}>New Mail</button>
	    			</div>
	    			<div className="sub_content">
		    			<h3>Mail</h3>
		    			<form onSubmit = {this.editMailInfo.bind(this)}>
							<input onChange= {this.handleTextInput.bind(this)} type="text" placeholder="Title" value={this.state.title}/>
							<textarea onChange= {this.handleTextAreaInput.bind(this)} type="text" placeholder="BodyText"rows="7" value={this.state.bodytext}/>
							<button type="submit">{this.state.buttonName}</button>
							<button onClick={this.clearInputData.bind(this)} className="clear_btn">Clear</button>
						</form>
		    		</div>
	    		</div>
      	);
	    }
	}
};



const mutationUpdateEmail = gql`
    mutation updateInstitutionEmail( $id: ID!, $Title: String!, $BodyText: String!){
      updateInstitutionEmail(id:$id, Title:$Title,BodyText: $BodyText){
        Title
        BodyText
      }
    }
`;

const mutationCreateEmail = gql`
	mutation insertInstitutionEmail($ErasmusCode: String!, $Title: String! , $BodyText: String!){
	  insertInstitutionEmail(ErasmusCode: $ErasmusCode, Title: $Title , BodyText : $BodyText){
	    Title
	    BodyText
	  }
	}
`;

const mutationRemoveEmail = gql`
	mutation RemoveInstitutionEmail($id: ID!){
	  removeInstitutionEmail(id:$id){
	    Title
	  }
	}
`;
export default compose(
	graphql(query),
	graphql(mutationUpdateEmail, { name: 'updateInstitutionEmail' }),
	graphql(mutationCreateEmail , { name: 'insertInstitutionEmail' }),
	graphql(mutationRemoveEmail , { name: 'RemoveInstitutionEmail' })
)(DefaultEmail);
