import React, { Component } from 'react';
import { query } from '../../../queries/getStudent';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import moment from 'moment';
import { decode } from 'he';

import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from "axios";
import querystring from 'querystring';

import Popup from './popup/Popup';
import SendMail from './popup/SendMail';
import DeclineOLA from './popup/DeclineOLA';
import SignOLA from './popup/SignOLA';
import RemoveOLA from './popup/RemoveOLA';
import Row from './Row';
import Notfication from '../../general/Notification'
import Help from '../../general/Help';

import { query as getStudentCountries } from '../../../queries/getStudentCountries';
import { query as getStudentInstitutions } from '../../../queries/getStudentInstitutions';
import { query as getStudentSignedBy } from '../../../queries/getStudentSignedBy';
import { query as getStudentSubjectArea } from '../../../queries/getStudentSubjectArea';
import { query as getStudentUploaded } from '../../../queries/getStudentUploaded';
import { query as getStudentStudyCycle } from '../../../queries/getStudentStudyCycle';
import { query as getStudentAcademicYear} from '../../../queries/getStudentAcademicYear';


class Data extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectionArray: [],
			selectionMinimum: undefined,
			selectionMaximum: undefined,
			sortFunction : false,
			sortOrder : "",
			sortName : "",
			hover : false,
			shouldFetchMoreStudents: false,
			processingDownloadOLA: false,
			errorNotificationSigning: false,
			errorNotificationDeclining: false,
			showHelp: false,
			helpTitle: "",
			helpDescription: ""
		}
		this.handleMultipleSelecting = this.handleMultipleSelecting.bind(this);
		this.declineOLAfunction = this.declineOLAfunction.bind(this);
		this.signOLAfunction = this.signOLAfunction.bind(this);
		this.sendMailFunction = this.sendMailFunction.bind(this);
		this.removeOLAfunction = this.removeOLAfunction.bind(this);
		this.closeNotification = this.closeNotification.bind(this);
		this.firstClick = "";
		this.studentList = []
		this.pages = 1;
	}

	componentWillMount() {
		if(localStorage.getItem("studentSelection"+this.props.typeOfStudentName) != null){
			const selectionArray = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
			this.setState({
				selectionMinimum: Math.min.apply(null, selectionArray),
				selectionMaximum: Math.max.apply(null, selectionArray)
			})
			this.props.reloadActions();
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.countryName !== this.props.countryName || nextProps.institutionName !== this.props.institutionName || nextProps.signedBy !== this.props.signedBy || nextProps.search !== this.props.search || nextProps.startMobility !== this.props.startMobility || nextProps.sortDirection !== this.props.sortDirection || nextProps.endMobility !== this.props.endMobility || nextProps.sortBy !== this.props.sortBy){
			if(this.pages > 1){
				this.pages = 1;
				// this.props.data.refetch();
			}
		}

		if(localStorage.getItem("studentSelection"+this.props.typeOfStudentName) === null && this.state.selectionMinimum !== undefined && this.state.selectionMaximum !== undefined){
			this.setState({
				selectionMinimum: undefined,
				selectionMaximum: undefined
			})
		}

	}

	sendMailFunction(title, text, sendNotification){
		const studentSelection = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
		for(let i = Math.min.apply(null, studentSelection); i <= Math.max.apply(null, studentSelection); i++){
			const student = this.props.data.student[i];
			if(sendNotification && student.erasmusID){
				axios.post(`${GlobalVar.getServerLink()}/pushNotification/messageToStudent`, querystring.stringify({
					erasmusID: student.erasmusID,
					title,
					description: text,
					typeOfStudent: this.props.typeOfStudentName,
					erasmusCode: localStorage.getItem("ErasmusCode")
				}));
			}
			axios.post(`${GlobalVar.getServerLink()}/sendMailStudent`, querystring.stringify({
				receiver: student.email,
				title,
				text
			}));
		}
	}

	declineOLAfunction(reason, typeOfDocument){
		const studentSelection = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
		const studentSelectionLength = Math.max.apply(null, studentSelection) - Math.min.apply(null, studentSelection);

		for(let i = Math.min.apply(null, studentSelection); i <= Math.max.apply(null, studentSelection); i++){
			const student = this.props.data.student[i];
			let olaID = student.olaID;
			let changes = 0;
			let typeOfSigning = "signedBefore";
			if(typeOfDocument === "during"){
				olaID = student.changesOlaID;
				typeOfSigning = "signedDuring";
				changes = 1;
			}
			if(this.validateDeclining(student, typeOfDocument)){
				const variables = {
					reason,
  				olaID,
  				email: student.email,
  				TypeOfStudent: this.props.typeOfStudentName,
					changes,
					ErasmusCode: localStorage.getItem("ErasmusCode"),
					UnknownEmail: localStorage.getItem("unknownEmail")
				}

				if(i === Math.max.apply(null, studentSelection)){ //LAST ITTERATION
					this.props.declineOLA({
						variables,
						refetchQueries: this.getRefetchQuery(typeOfSigning)
					}).then(() => {
						localStorage.removeItem("studentSelection"+this.props.typeOfStudentName);
						this.props.data.refetch();
						this.forceUpdate();
					});

				}
				else{
					this.props.declineOLA({
						variables
					});
				}
			}
			else{
				if(!this.state.errorNotificationDeclining){
					this.setState({
						errorNotificationDeclining: true
					})
				}
			}
		}

	}
	removeOLAfunction(){
		const studentSelection = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
		for(let i = Math.min.apply(null, studentSelection); i <= Math.max.apply(null, studentSelection); i++){
			const student = this.props.data.student[i];
			const {encryptedID, email} = student;
			let TypeOfInstitution = "Host";
			if(this.props.typeOfStudentName === "Outgoing"){
				TypeOfInstitution = "Home";
			}
			const variables = {
				EncryptedID: encryptedID,
				Email: email,
				ErasmusCode: localStorage.getItem("ErasmusCode"),
				TypeOfInstitution
			}
			console.log(variables);
			this.props.removeOLA({
				variables,
				refetchQueries: this.getRefetchQuery()
			}).then(() => {
				localStorage.removeItem("studentSelection"+this.props.typeOfStudentName);
				this.props.data.refetch();
				this.forceUpdate();
			});
		}
	}

	signOLAfunction(SignatureDataString, typeOfDocument){


		const studentSelection = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));

		for(let i = Math.min.apply(null, studentSelection); i <= Math.max.apply(null, studentSelection); i++){
			const student = this.props.data.student[i];
			if(this.validateSigning(student, typeOfDocument)){
				let olaID = student.olaID;
				let changes = 0;
				let typeOfSigning = "signedBefore";
				if(typeOfDocument === "during"){
					changes = 1;
					typeOfSigning = "signedDuring";
				}
				const variables = {
					SignatureDataString,
  				OlaID: olaID,
  				Email: student.email,
  				TypeOfStudent: this.props.typeOfStudentName,
					Changes: changes,
					ChangesOlaID: student.changesOlaID
				}
				if(i === Math.max.apply(null, studentSelection)){
					this.props.signOLA({
						variables,
						refetchQueries: this.getRefetchQuery(typeOfSigning)
					}).then(() => {
						localStorage.removeItem("studentSelection"+this.props.typeOfStudentName);
						//this.props.data.refetch();
						this.forceUpdate();
					});
				}
				else{
					this.props.signOLA({
						variables
					});
				}
			}
			else{
				if(!this.state.errorNotificationSigning){
					this.setState({
						errorNotificationSigning: true
					})
				}
			}
		}
	}

	validateSigning(student, typeOfDocument){
		let status = Number(student.status);
		if(typeOfDocument === "during"){
			status = student.changesStatus;
		}
		const loginAccountEmail = GlobalVar.decryptWithKey(localStorage.getItem("Email"));
		if(this.props.typeOfStudentName === "Outgoing"){
			if(student.sendResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(student.sendResEmail !== "" || student.sendEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						return false;
					}
			}
			if(status !== 1){
				return false;
			}
		}
		else if(this.props.typeOfStudentName === "Incoming"){
			if(student.recResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(student.recResEmail !== "" || student.receiveEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						return false;
					}
			}
			if(status !== 2){
				return false;
			}
		}
		return true;
	}

	validateDeclining(student, typeOfDocument){
		let status = Number(student.status);
		if(typeOfDocument === "during"){
			status = student.changesStatus;
		}
		if(this.props.typeOfStudentName === "Outgoing"){
			if(status !== 1){
				return false;
			}
		}
		if(this.props.typeOfStudentName === "Incoming"){
			if(status !== 2){
				return false;
			}
		}
		return true;
	}

	getRefetchQuery(typeOfSigning){
		let filterVariables = {
			Sending: this.props.typeOfStudents,
			CountryName: this.props.countryName,
			InstitutionName: this.props.institutionName,
			SearchQuery: this.props.search,
			SignedBefore: Number(this.props.signedBefore),
			SignedDuring: Number(this.props.signedDuring),
			StartMobility: this.props.StartMobility,
			EndMobility: this.props.EndMobility,
			Uploaded: this.props.uploaded,
			AcademicYear: this.props.academicYear,
			StudyCycle: this.props.studyCycle,
			SubjectArea: this.props.subjectArea,
			TypeOfSigning: typeOfSigning
		}
		let filterVariablesStudent = filterVariables;
		filterVariablesStudent["Offset"] = 0;
		filterVariablesStudent["AmountOfStudents"] = 50*this.pages;
		filterVariablesStudent["SortBy"] = localStorage.getItem("sortBy"+this.props.typeOfStudentName);
		filterVariablesStudent["SortDirection"] = localStorage.getItem("sortDirection"+this.props.typeOfStudentName);
		delete filterVariablesStudent.TypeOfSigning;

		console.log(filterVariablesStudent);
		return [
			{
				query,
				variables: filterVariablesStudent
			},
			{
				query: getStudentCountries,
				variables: filterVariables
			},
			{
				query: getStudentInstitutions,
				variables: filterVariables
			},
			{
				query: getStudentSignedBy,
				variables: filterVariables
			},
			{
				query: getStudentSubjectArea,
				variables: filterVariables
			},
			{
				query: getStudentUploaded,
				variables: filterVariables
			},
			{
				query: getStudentStudyCycle,
				variables: filterVariables
			},
			{
				query: getStudentAcademicYear,
				variables: filterVariables
			}
		]
	}

	handleMultipleSelecting(indexNormalClick, indexShiftClick, unclick){
		let selectionArray = [];
		if(localStorage.getItem("studentSelection"+this.props.typeOfStudentName) != null){
			selectionArray = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
		}
		if(indexNormalClick != undefined){
			if(selectionArray.length > 0){
				selectionArray = [];
			}
			selectionArray.push(indexNormalClick);
		}
		else if(indexShiftClick != undefined){
			selectionArray.push(indexShiftClick);
		}
		const selectionMinimum = Math.min.apply(null, selectionArray);
		const selectionMaximum = Math.max.apply(null, selectionArray);
		localStorage.setItem("studentSelection"+this.props.typeOfStudentName, JSON.stringify(selectionArray));
		this.setState({
			selectionMinimum,
			selectionMaximum
		});
		if(unclick){
			localStorage.setItem("studentSelection"+this.props.typeOfStudentName, "[]");
			this.setState({
				selectionMinimum: null,
				selectionMaximum: null
			});
		}
		this.props.reloadActions();
	}

	renderRows(){
		const regularStudentList = this.props.data.student;
		let realIndex = 0;
    const studentDataLength = regularStudentList.length;
		if(studentDataLength === 0){
			if(this.props.countryName || this.props.institutionName || this.props.signedBy || this.props.search || this.props.startMobility || this.props.endMobility){
				return <p className="italic">You don’t have any students that match the search parameter(s)</p>;
			}
			if(this.props.typeOfStudents === 1){
				return <p className="italic">You don’t have any outgoing students</p>;
			}
			else if(this.props.typeOfStudents === 2){
				return <p className="italic">You don’t have any incoming students</p>;
			}
		}

		return regularStudentList.map((data, index) => {
			realIndex++;
			return(
				<Row search={this.props.search} key={index} realIndex={realIndex} index={index} data={data} selectionMinimum={this.state.selectionMinimum} selectionMaximum={this.state.selectionMaximum} handleMultipleSelecting={this.handleMultipleSelecting} uselessProp={this.props.uselessProp} typeOfStudentName={this.props.typeOfStudentName} tutorial={this.props.data.tutorial}/>
			)
		})

	}

 	sortFunction(event,sortName){
 		event.preventDefault();
 		if(sortName !== undefined){
	 		if(localStorage.getItem("sortBy"+this.props.typeOfStudentName) === sortName){
	 			if(localStorage.getItem("sortDirection"+this.props.typeOfStudentName) == "desc"){
	 				localStorage.setItem("sortDirection"+this.props.typeOfStudentName, "asc");
	 			}
	 			else if(localStorage.getItem("sortDirection"+this.props.typeOfStudentName) == "asc"){
	 				localStorage.setItem("sortDirection"+this.props.typeOfStudentName, "desc");
	 			}
	 		}
	 		else{
	 			localStorage.setItem("sortDirection"+this.props.typeOfStudentName, "desc")
	 			localStorage.setItem("sortBy"+this.props.typeOfStudentName, sortName)
	 		}
		    this.props.filterIsApplied();
	    }
 	}

 	renderSortIndication(sortName){
 		if(sortName === localStorage.getItem("sortBy"+this.props.typeOfStudentName)){
 			if(localStorage.getItem("sortDirection"+this.props.typeOfStudentName) === "asc"){
 				return <img className="arrow" src="/icons/downArrow-blue.svg" />;
 			}
 			else if(localStorage.getItem("sortDirection"+this.props.typeOfStudentName) === "desc"){
 				return <img className="arrow" src="/icons/upArrow-blue.svg" />;
 			}
 		}
 	}

	renderHeaderColumn(title, uniqueName, sortName){

		if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
			let columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
			if(columnValues.includes(uniqueName)){
				return <form onSubmit={(event) => this.sortFunction(event,sortName)} className={"column "+uniqueName}><button className="button_title">{title}</button>{this.renderSortIndication(sortName)}</form>;
			}
		}
	}

	loadMoreStudents(event){
		if(event !== undefined){
			event.preventDefault();
		}
		this.setState({shouldFetchMoreStudents: true});
		this.props.data.fetchMore({
			variables:{
				Offset: 50*this.pages
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				let newStudent = [];
				newStudent = newStudent.concat(previousResult.student);
				newStudent = newStudent.concat(fetchMoreResult.student);
				this.setState({shouldFetchMoreStudents: false});
				return {
					student: newStudent,
					countOfStudents: this.props.data.countOfStudents
				};
			}
		})
	}

	renderLoadMoreButton(){
		if(this.props.data.countOfStudents.count > this.props.data.student.length && !this.state.shouldFetchMoreStudents){
			return <form className="load_more_button" onSubmit={(event) => this.loadMoreStudents(event)}>
				<button>Load more</button>
			</form>;
		}
	}

	scrollingTable(event){
		if((this.pages*50*40)-(this.pages*50)-200 < event.target.scrollTop+event.target.clientHeight && this.props.data.countOfStudents.count > this.props.data.student.length && !this.state.shouldFetchMoreStudents){ //Calculate when students should reload
			this.setState({shouldFetchMoreStudents: true})
			this.loadMoreStudents();

		}
	}

	renderLoader(){
		if(this.state.shouldFetchMoreStudents){
			return <p className="blue_text"><img className="spinner" src="/icons/spinner.svg"/>Loading more students...</p>
		}
	}

	calculatePagesOfStudents(CurrentCountOfStudents, totalCountOfStudents){
		if(CurrentCountOfStudents < totalCountOfStudents){
			this.pages = CurrentCountOfStudents/50;
			/*if(this.pages == 1){
				this.props.data.refetch();
			}*/
		}
	}





	downloadOLA(event){
		event.preventDefault();
		this.setState({
			processingDownloadOLA: true
		})
		const studentSelection = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
		let studentIDlist = [];
		for(let i = Math.min.apply(null, studentSelection); i <= Math.max.apply(null, studentSelection); i++){
			const student = this.props.data.student[i];
			if(student != undefined){
				studentIDlist.push({studentID: student.studentID, olaID: student.olaID, changesOlaID: student.changesOlaID});
			}
		}
		axios.post(`${GlobalVar.getServerLink()}/initiateDownloadOLAs`, querystring.stringify({
			studentIDlist: JSON.stringify(studentIDlist),
			typeOfStudentName: this.props.typeOfStudentName
		})).then((response) => {
			if(response.data){
				setTimeout(function () {
					window.location.replace(`${GlobalVar.getServerLink()}/download/${response.data}`);
				}, 3000);
				this.setState({
					processingDownloadOLA: false
				})
			}
		});
	}

	generateDownloadOLAbutton(amountOfStudentsSelected){
		if(amountOfStudentsSelected > 19){
			return (
				<form onSubmit={(event) => {event.preventDefault()}}>
					<button
						onMouseOver={() => this.showHelp(true, "Selection error", "Can‘t download more than 20 documents")}
						onMouseLeave={() => this.showHelp(false)}
						className="disabled"
					>
						Download OLA
					</button>
				</form>
			)
		}
		if(this.state.processingDownloadOLA){
			return <form onSubmit={(event) => {event.preventDefault()}}><button className="loading_button download_ola_button"><img className="spinner" src="/icons/spinner.svg"/></button></form>;
		}
		return <form onSubmit={(event) => event.preventDefault()}><button className="disabled">Download OLA</button></form>;
	}

	generateRemoveButton(amountOfStudentsSelected){
		if(amountOfStudentsSelected > 99){
			return (
				<form onSubmit={(event) => {event.preventDefault()}}>
					<button
						onMouseOver={() => this.showHelp(true, "Selection error", "Can‘t remove more than 100 documents")}
						onMouseLeave={() => this.showHelp(false)}
						className="disabled decline_button"
					>
						Remove OLA
					</button>
				</form>
			)
		}
		if(localStorage.getItem("unknownEmail") !== null){
			return (
				<form onSubmit={(event) => {event.preventDefault()}}>
					<button
						onMouseOver={() => this.showHelp(true, "Account error", "You have to register in order to remove OLA(s). To register please log out and follow the instructions or contact your HEI.")}
						onMouseLeave={() => this.showHelp(false)}
						className="disabled decline_button"
					>
						Remove OLA
					</button>
				</form>
			)
		}
		return <Link to={`${GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")}/remove-OLA`}><button className="decline_button">Remove OLA</button></Link>;
	}

	generateSignButton(selectionArray, during){

		let buttonTilte = "Sign before mobility";
		let linkVariable = "before";
		if(during){
			linkVariable = "during";
			buttonTilte = "Sign during mobility";
		}
		let errorTitle = "";
		let errorDescription = "";
		const loginAccountEmail = GlobalVar.decryptWithKey(localStorage.getItem("Email"));
		for(let i = Math.min.apply(null, selectionArray); i <= Math.max.apply(null, selectionArray); i++){
			const student = this.props.data.student[i];
			let status = Number(student.status);
			if(during){
				status = student.changesStatus;
			}
			if(this.props.typeOfStudentName === "Outgoing"){
				if(student.sendResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(student.sendResEmail !== "" || student.sendEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						errorTitle = "Email mismatch";
						errorDescription = "Only OLA's with the same Home responsible email as your login email can be signed";
					}
				}
				if(status !== 1){
					errorTitle = "Signature error";
					errorDescription = "Only OLA's with student signatures can be signed";
				}
			}
			else if(this.props.typeOfStudentName === "Incoming"){
				if(student.recResEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
					if(student.recResEmail !== "" || student.receiveEmail.toLowerCase() !== loginAccountEmail.toLowerCase()){
						errorTitle = "Email mismatch";
						errorDescription = "Only OLA's with the same Host responsible email as your login email can be signed";
					}
				}
				if(status !== 2){
					errorTitle = "Signature error";
					errorDescription = "Only OLA's with student+sending coordinator signatures can be signed";
				}
			}
			if(student.changesStatus === null && during){
				errorTitle = "Document missing";
				errorDescription = "One of the selected students doesn't have a learning agreement of the during mobility type.";
			}
		}

		if(errorDescription === ""){
			return <Link to={`${GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")}/sign-OLA?documentType=${linkVariable}`}><button>{buttonTilte}</button></Link>;
		}
		return (
			<form onSubmit={(event) => {event.preventDefault()}}>
				<button
					onMouseOver={() => this.showHelp(true, errorTitle, errorDescription)}
					onMouseLeave={() => this.showHelp(false)}
					className="disabled"
				>
					{buttonTilte}
				</button>
			</form>
		)
	}

	generateDeclineButton(selectionArray, during){
		let errorTitle = "";
		let errorDescription = "";
		let buttonTilte = "Decline before mobility";
		let linkVariable = "before";
		if(during){
			linkVariable = "during";
			buttonTilte = "Decline during mobility";
		}
		for(let i = Math.min.apply(null, selectionArray); i <= Math.max.apply(null, selectionArray); i++){
			const student = this.props.data.student[i];
			let status = Number(student.status);
			if(during){
				status = student.changesStatus;
			}
			if(this.props.typeOfStudentName === "Outgoing"){
				if(status !== 1){
					errorTitle = "Signature error";
					errorDescription = "Only OLA's that student has signed can be declined";
				}
			}
			if(this.props.typeOfStudentName === "Incoming"){
				if(status !== 2){
					errorTitle = "Signature error";
					errorDescription = "Only OLA's that student+sending coordinator has signed can be declined";
				}
			}
		}
		if(errorDescription === ""){
			return <Link to={`${GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")}/decline-OLA?documentType=${linkVariable}`}><button className="decline_button">{buttonTilte}</button></Link>;
		}
		return (
			<form onSubmit={(event) => {event.preventDefault()}}>
				<button
					onMouseOver={() => this.showHelp(true, errorTitle, errorDescription)}
					onMouseLeave={() => this.showHelp(false)}
					className="disabled decline_button"
				>
					{buttonTilte}
				</button>
			</form>
		)
	}
	generateButtonList(){
		if(localStorage.getItem("studentSelection"+this.props.typeOfStudentName) != null && this.props.data.student.length > 0){
            const selectionArray = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
            if(selectionArray){
                if(selectionArray.length > 0){
                	const amountOfStudentsSelected = Math.max.apply(null, selectionArray) - Math.min.apply(null, selectionArray);
                    return(
                    	<div className="button_list">
                    		<Link to={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/mail"} ><button>Send mail</button></Link>
                    		{this.generateSignButton(selectionArray, false)}
												{this.generateSignButton(selectionArray, true)}
                    		{this.generateDeclineButton(selectionArray, false)}
												{this.generateDeclineButton(selectionArray, true)}
												{this.generateDownloadOLAbutton(amountOfStudentsSelected)}
												{this.generateRemoveButton(amountOfStudentsSelected)}
                    	</div>
                    )
                }
            }
        }
        return(
        	<div className="button_list">
        		<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled"
							>
								Send mail
							</button>
						</form>
        		<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled"
							>
								Sign before mobility
							</button>
						</form>

						<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled"
							>
								Sign during mobility
							</button>
						</form>
						<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled decline_button"
							>
								Decline before mobility
							</button>
						</form>
        		<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled decline_button"
							>
								Decline during mobility
							</button>
						</form>
						<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled"
							>
								Download OLA
							</button>
						</form>
						<form onSubmit={(event) => {event.preventDefault()}}>
							<button
								onMouseOver={() => this.showHelp(true, "No students selected:", "You have to select a student in order to perform any actions. You can select multiple students by holding the shift key.")}
								onMouseLeave={() => this.showHelp(false)}
								className="disabled decline_button"
							>
								Remove OLA
							</button>
						</form>
        	</div>
        )
	}

	showHelp(status, title, description){
		if(status){
			if(!this.state.showHelp){
				this.setState({
					showHelp: true,
					helpTitle: title,
					helpDescription: description
				})
			}
		}
		else{
			if(this.state.showHelp){
				this.setState({
					showHelp: false
				})
			}
		}
	}

	checkLengthOfStudentSelection(){
		if(localStorage.getItem("studentSelection"+this.props.typeOfStudentName) != null){
            const selectionArray = JSON.parse(localStorage.getItem("studentSelection"+this.props.typeOfStudentName));
            if(Math.max.apply(null, selectionArray) > this.props.data.student.length){
            	localStorage.removeItem("studentSelection"+this.props.typeOfStudentName);
            }
        }
	}

	closeNotification(notificationStateName){
		this.setState({
			[notificationStateName]: false
		})
	}
	generateNotification(){
		if(this.state.errorNotificationSigning){
			return <Notfication closeNotification={this.closeNotification}  type="error" notificationStateName="errorNotificationSigning" description="You don't have the adequate rights to sign the document" />;
		}
		if(this.state.errorNotificationDeclining){
			return <Notfication closeNotification={this.closeNotification}  type="error" notificationStateName="errorNotificationDeclining" description="You don't have the adequate rights to decline the document" />;
		}
	}

	generateHelpBox(){
		if(this.state.showHelp){
			return(
				<div className="help_wrapper help_wrapper_action_buttons">
					<h4>{this.state.helpTitle}</h4>
					<p>{this.state.helpDescription}</p>
				</div>
			)
		}
	}

	render() {
		if(this.props.data.loading){
			return (
    		<div className="card card_full_width card_full_height">
   				<div className="custom_table">
		    		<div className="row header_row">
		    			<div className="column bulk"><h3>Select</h3></div>
		    			<div className="column nr"><h3>Nr</h3></div>
		    			{this.renderHeaderColumn("First name", "firstname")}
		    			{this.renderHeaderColumn("Last name", "lastname")}
		    			{this.renderHeaderColumn("Email", "email")}
		    			{this.renderHeaderColumn("Start mobility", "startMobility")}
							{this.renderHeaderColumn("End mobility", "endMobility")}
		    			{this.renderHeaderColumn("Nationality", "nationality")}
							{this.renderHeaderColumn("Phone number", "phone")}
							{this.renderHeaderColumn("Gender", "gender")}

							{/*<div className="column learningAgreement"><h3>Learning agreement -></h3></div>*/}
							{this.renderHeaderColumn("Language name", "language")}
							{this.renderHeaderColumn("Language level", "languagelevel")}

							{this.renderHeaderColumn("Signed by", "signed")}
							{this.renderHeaderColumn("Subject Area", "subjectArea")}
							{this.renderHeaderColumn("Sending faculty", "facultySend")}
							{this.renderHeaderColumn("Sending department", "departmentSend")}
							{this.renderHeaderColumn("Receiving faculty", "facultyRecive")}
							{this.renderHeaderColumn("Receiving department", "departmentRecive")}
							{this.renderHeaderColumn("Study cycle", "cycle")}

							{this.renderHeaderColumn("Host contact name", "receiveContactname")}
							{this.renderHeaderColumn("Host contact email", "receiveEmail")}
							{this.renderHeaderColumn("Host contact phone", "receivePhone")}
							{this.renderHeaderColumn("Host contact function", "receiveFunction")}

							{this.renderHeaderColumn("Home contact name", "sendingContactname")}
							{this.renderHeaderColumn("Home contact email", "sendingEmail")}
							{this.renderHeaderColumn("Home contact phone", "sendingPhone")}
							{this.renderHeaderColumn("Home contact function", "sendingFunction")}

							{this.renderHeaderColumn("Home responsible name", "sendingResponsibleName")}
							{this.renderHeaderColumn("Home responsible email", "sendingResponsibleEmail")}
							{this.renderHeaderColumn("Home responsible phone", "sendingResponsiblePhone")}
							{this.renderHeaderColumn("Home responsible function", "sendingResponsibleFunction")}

							{this.renderHeaderColumn("Receiving Erasmus code", "receivingErasmusCode")}
							{this.renderHeaderColumn("Receiving institution", "receivingInstitution")}
							{this.renderHeaderColumn("City", "city")}
							{this.renderHeaderColumn("Country", "country")}

							{this.renderHeaderColumn("Sending Erasmus code", "sendingErasmusCode")}
							{this.renderHeaderColumn("Sending institution", "sendingInstitution")}
							{this.renderHeaderColumn("City", "sendingCity")}
							{this.renderHeaderColumn("Country", "sendingCountry")}

							{this.renderHeaderColumn("Home courses", "homeCourses")}
							{this.renderHeaderColumn("Host courses", "hostCourses")}
		    		</div>
	    			<p><img className="spinner" src="/icons/spinner.svg"/>Loading students...</p>
		    	</div>

    		</div>
	    );
		}

		this.calculatePagesOfStudents(this.props.data.student.length, this.props.data.countOfStudents.count);
		this.checkLengthOfStudentSelection();
		const props = this.props;

  	return (
  		<div className="division_75">
  			{this.generateNotification()}
    		<div className="card card_full_width card_full_height">
   				<div className="custom_table">
		    		<div className="row header_row">
		    			<div className="column bulk"><h3>Select</h3></div>
		    			<div className="column nr"><h3>Nr</h3></div>
		    			{this.renderHeaderColumn("First name", "firstname", "la_student.firstname")}
		    			{this.renderHeaderColumn("Last name", "lastname", "la_student.name")}
		    			{this.renderHeaderColumn("Email", "email", "la_student.emailstudent")}
		    			{this.renderHeaderColumn("Start mobility", "startMobility")}
							{this.renderHeaderColumn("End mobility", "endMobility")}
							{this.renderHeaderColumn("Uploaded", "uploaded")}
		    			{this.renderHeaderColumn("Nationality", "nationality", "la_student.nationality")}
							{this.renderHeaderColumn("Phone number", "phone", "la_student.phone")}
							{this.renderHeaderColumn("Gender", "gender", "la_student.sex")}
							{this.renderHeaderColumn("Academic year", "academicYear")}

							{/*<div className="column learningAgreement"><h3>Learning agreement -></h3></div>*/}
							{this.renderHeaderColumn("Language name", "language")}
							{this.renderHeaderColumn("Language level", "languagelevel")}

							{this.renderHeaderColumn("Subject Area", "subjectArea", "la_la.code")}
							{this.renderHeaderColumn("Sending faculty", "facultySend", "la_la.facultySend")}
							{this.renderHeaderColumn("Sending department", "departmentSend", "la_la.departmentSend")}
							{this.renderHeaderColumn("Receiving faculty", "facultyRecive", "la_la.facultyRecive")}
							{this.renderHeaderColumn("Receiving department", "departmentRecive", "la_la.departmentRecive")}
							{this.renderHeaderColumn("Study cycle", "cycle", "la_la.cycle")}

							{this.renderHeaderColumn("Host contact name", "receiveContactname", "la_la.receiveContactname")}
							{this.renderHeaderColumn("Host contact email", "receiveEmail", "la_la.receiveEmail")}
							{this.renderHeaderColumn("Host contact phone", "receivePhone", "la_la.receivePhone")}
							{this.renderHeaderColumn("Host contact function", "receiveFunction", "la_la.receiveFunction")}

							{this.renderHeaderColumn("Host responsible name", "receiveResponsibleContactname", "la_la.recResName")}
							{this.renderHeaderColumn("Host responsible email", "receiveResponsibleEmail", "la_la.recResEmail")}
							{this.renderHeaderColumn("Host responsible phone", "receiveResponsiblePhone", "la_la.recResPhone")}
							{this.renderHeaderColumn("Host responsible function", "receiveResponsibleFunction", "la_la.recResFun")}

							{this.renderHeaderColumn("Home contact name", "sendingContactname", "la_la.sendContactname")}
							{this.renderHeaderColumn("Home contact email", "sendingEmail", "la_la.sendEmail")}
							{this.renderHeaderColumn("Home contact phone", "sendingPhone", "la_la.sendPhone")}
							{this.renderHeaderColumn("Home contact function", "sendingFunction", 'la_la.sendFunction')}

							{this.renderHeaderColumn("Home responsible name", "sendingResponsibleName", "la_la.sendResName")}
							{this.renderHeaderColumn("Home responsible email", "sendingResponsibleEmail", "la_la.sendResEmail")}
							{this.renderHeaderColumn("Home responsible phone", "sendingResponsiblePhone", "la_la.sendResPhone")}
							{this.renderHeaderColumn("Home responsible function", "sendingResponsibleFunction", "la_la.sendResFun")}

							{this.renderHeaderColumn("Receiving Erasmus code", "receivingErasmusCode", "la_la.erasusCodeRecive")}
							{this.renderHeaderColumn("Receiving institution", "receivingInstitution", "la_la.uniNameRecive")}
							{this.renderHeaderColumn("Country", "country", "la_la.countryRecive")}

							{this.renderHeaderColumn("Sending Erasmus code", "sendingErasmusCode", "la_la.erasmusCodeSend")}
							{this.renderHeaderColumn("Sending institution", "sendingInstitution", "la_la.uniNameSend")}
							{this.renderHeaderColumn("Country", "sendingCountry", "la_la.countrySend")}

							{this.renderHeaderColumn("Signed by (Before mobility)", "signed", "la_la.status")}
							{this.renderHeaderColumn("Home courses (Before mobility)", "homeCourses")}
							{this.renderHeaderColumn("Host courses (Before mobility)", "hostCourses")}

							{this.renderHeaderColumn("Signed by (During mobility)", "signedChanges", "signedChanges")}
							{this.renderHeaderColumn("Home courses (During mobility)", "homeCoursesChanges")}
							{this.renderHeaderColumn("Host courses (During mobility)", "hostCoursesChanges")}
		    		</div>
	    			<div onScroll={(event) => this.scrollingTable(event)} className="table_body">
	    				{this.renderRows()}
	    				{this.renderLoadMoreButton()}
	    				{this.renderLoader()}
					</div>
		    	</div>
		    	<Route path={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/student-detail/:olaID"} exact component={Popup}/>

				</div>
    		<div className="card card_full_width actions">
					{this.generateHelpBox()}
    			{this.generateButtonList()}

					{/*ROUTES TO POPUPS*/}
          <Route path={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/mail"} exact render={() => <SendMail history={this.props.history} typeOfStudentName={this.props.typeOfStudentName} sendMailFunction={this.sendMailFunction} />}/>
          <Route path={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/decline-OLA"} exact render={() => <DeclineOLA declineOLAfunction={this.declineOLAfunction} typeOfStudentName={this.props.typeOfStudentName} history={this.props.history} />}/>
          <Route path={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/sign-OLA"} exact render={() => <SignOLA signOLAfunction={this.signOLAfunction} typeOfStudentName={this.props.typeOfStudentName} history={this.props.history} />}/>
					<Route path={GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/remove-OLA"} exact render={() => <RemoveOLA removeOLAfunction={this.removeOLAfunction} typeOfStudentName={this.props.typeOfStudentName} history={this.props.history} />}/>
    		</div>
  		</div>
    	);
    }

};

const declineOLA = gql`
	mutation DeclineOLA($olaID: Int!, $reason: String, $email: String, $TypeOfStudent: String, $changes: Int, $ErasmusCode: String, $UnknownEmail: String){
	  declineOLA(OlaID: $olaID, Reason: $reason, Email: $email, TypeOfStudent: $TypeOfStudent, Changes: $changes, ErasmusCode: $ErasmusCode, UnknownEmail: $UnknownEmail){
	    olaID
	  }
	}
`

const signOLA = gql`
	mutation signOLA($OlaID:Int!, $SignatureDataString:String, $Email:String, $TypeOfStudent: String, $Changes: Int, $ChangesOlaID: Int){
	  signOLA(OlaID:$OlaID, SignatureDataString:$SignatureDataString, Email:$Email, TypeOfStudent: $TypeOfStudent, Changes: $Changes, ChangesOlaID: $ChangesOlaID){
	    olaID
	  }
	}
`

const removeOLA = gql`
	mutation removeOLA($EncryptedID: String!, $Email: String!, $ErasmusCode: String!, $TypeOfInstitution: String!){
	  removeOLA(EncryptedID:$EncryptedID, Email: $Email, ErasmusCode: $ErasmusCode, TypeOfInstitution: $TypeOfInstitution){
	    olaID
	  }
	}
`

export default compose(
	graphql(query, {
		options: (props) => ({
			variables: {
				Sending: props.typeOfStudents,
				Offset: 0,
				AmountOfStudents: 50,
				CountryName: props.countryName,
				InstitutionName: props.institutionName,
				SignedBefore: Number(props.signedBefore),
				SignedDuring: Number(props.signedDuring),
				SearchQuery: props.search,
				StartMobility: props.startMobility,
				EndMobility: props.endMobility,
				Uploaded: props.uploaded,
				AcademicYear: props.academicYear,
				StudyCycle: props.studyCycle,
				SubjectArea: props.subjectArea,
				SortBy: props.sortBy,
				SortDirection: props.sortDirection
			}
		})
	}),
	graphql(declineOLA, { name: 'declineOLA'}),
	graphql(signOLA, { name: 'signOLA'}),
	graphql(removeOLA, { name: 'removeOLA'})
)(Data);
