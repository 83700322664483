import gql from 'graphql-tag';
const query = gql`
	query getInstitutions($CityLink: String){
	  	institutions(CityLink: $CityLink){
			NameInLatinCharacterSet
			ErasmusCode
			ID
	   	}
	}
`;
export { query };
