import gql from 'graphql-tag';
const query = gql`
query InstitutionRegistrationFilter($Valid:Int,$ContactEmail:String,$InstitutionName:String, $Offset: Int, $AmountOfRequests: Int){
  InstitutionRegistrationFilter(Valid: $Valid,ContactEmail:$ContactEmail,InstitutionName:$InstitutionName, Offset: $Offset, AmountOfRequests: $AmountOfRequests){
    id
    Phone
    Email
    Website
    TimeStamp
    Institution {
      NameInLatinCharacterSet
    }
  }
}
`;
export { query };