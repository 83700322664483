import React, { Component } from 'react';
import intl from 'react-intl-universal';
import GlobalVar from '../../GlobalVar';
import { Link } from 'react-router-dom';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			dismissBanner: false
		}
	}
	generatePageTitle(){
		let title = window.location.pathname;
		
		if(title.startsWith("/ola3")) {
			title = title.replace("ola3", "Mobilities (ola 3.0)");
		}
		if(title !== "/"){
			title = title.substr(1); //Remove first slash
			title = title.replace("/", " > ");
			title = title.replace(/-/g, " ");
			title = title.replace(/\/.*/g, "");
			title = this.titleCase(title);
			return title;
		}
	}
	titleCase(str) { //https://www.sitepoint.com/community/t/capitalizing-first-letter-of-each-word-in-string/209644/2
		str = str.toLowerCase().split(' ');
		for(var i = 0; i < str.length; i++){
			if (str[i] === "iiam") {
				str[i] = "IIAm";
				continue;
			} else if (str[i] === "iia") {
				str[i] = "IIA";
				continue;
			} else if (str[i] === "esc") {
				str[i] = "ESC";
				continue;
			} else if (str[i] === "egracons") {
				str[i] = "EGRACONS";
				continue;
			} else if (str[i].includes("ola")) {
				str[i] = str[i].replace("ola", "OLA");
				continue;
			}
		    str[i] = str[i].split('');
		    str[i][0] = (str[i][0]) ? str[i][0].toUpperCase() : str[i][0];
		    str[i] = str[i].join('');
		}
		return str.join(' ');
	}

	addToPageTitle(title){
		const newTitle = this.state.title + title;
		this.setState({
			title: newTitle
		})
	}

	componentWillMount() {
		const title = this.generatePageTitle();
		this.setState({
			title
		})
	}

	componentWillReceiveProps(nextProps) {
		const title = this.generatePageTitle();
		this.setState({
			title
		})
	}

	dismissBanner(event){
		event.preventDefault();
		this.setState({
			dismissBanner: true
		})
		localStorage.setItem("dismissBanner", true);
	}

	generateNotficationNewCondition(){
		if(!this.state.dismissBanner && localStorage.getItem("dismissBanner") !== "true"){
			return(
				<div className="card notification_new_conditions">
					<div className="inside_content">
						<h3>Important notice</h3>
						<p>We made updates to our <Link to={GlobalVar.generateLinkUrl(intl,  "Institution", "Registration")+"/termsandconditions"}>Privacy Policy and Terms and Conditions</Link> that will go into effect on May 25, 2018. We encourage you to review the documentation and let us know if you have any questions.</p>
						<form onSubmit={(event) => this.dismissBanner(event)}>
							<button>
								Close
							</button>
						</form>
					</div>
				</div>
			)
		}
	}
	generateImgLinkMenu(){
		if(!this.props.openNavigation){
			return "/icons/hamburger.svg";
		}
		return "/icons/remove.svg";
	}
	render() {
		let url = this.props.pathname;
		url = url.toLowerCase(url);
		if(url === "/intro" || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Login")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Confirmation")) || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Registration")) || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Confirmation")) || url === GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten")|| url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Reset Password")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Reset Password")) || url.includes("/useful-information")){
			return (
	  		<div>
	  		</div>
  		);
		}

    return (
  		<header>
				<div className="logo_wrapper">
					<img src="/icons/e-plus-cms.svg" alt="Erasmus+ logo for institutions" />
					<h1>{intl.get('Admin panel')}</h1>
				</div>
				<div className="menu_icon_wrapper">
					<form onSubmit={(event) => this.props.openOrCloseNavigation(event)} className="text_button_wrapper">
						<button>

							<img alt="" src={this.generateImgLinkMenu()}/>
						</button>
					</form>
				</div>
				<h2 className="page_title">{this.state.title}</h2>
				{/*this.generateNotficationNewCondition()*/}
			</header>
    );
  }
};

export default Header;
