import React, { Component } from 'react';

class Input extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.className}>
                <p>
                    <input type={this.props.type} name={this.props.name} onChange={(event) => this.props.onChange(event)} checked={this.props.checked} />
                    {this.props.text}
                </p>
            </div>
        );
    }
}

export default Input;