import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import TableRow from './TableRow';

function Table(props) {

    const automaticRecognitionCheck = (dataFromRow) => {
        props.parentCallback(dataFromRow);
    }

    const renderTableRows = (isOutgoing) => {
        const semesterMap = props.data.shortTermMobility.semester_map;
        let semesterName = "";
        return props.data.shortTermMobility.preliminary_table_c.map((courseData, index) => {

            return(
                <TableRow 
                    parentCallback={automaticRecognitionCheck} 
                    courseData={courseData}
                    accounts={props.accounts}
                    status={props.status}
                    key={index} 
                    tableType={3} 
                    isOutgoing={isOutgoing} />
            )
        });
    }

    const calculateECTStotal = () => {
        let totalECTS = 0;

        props.data.shortTermMobility.preliminary_table_c.forEach((courseData) => {
            const ects = courseData.attributes.field_pre_tbl_c_ects.replace(',', '.').replace(/\s+/g, '');
            if (!isNaN(ects)){
                totalECTS += parseFloat(ects, 10);
            } else {
                totalECTS += 0;
            }
        });
        return (
            <tr>
                <td></td>
                <td></td>
                <td className="italic">Total ECTS credits</td>
                <td className="italic">{totalECTS}</td>
                <td></td>
            </tr>
        );
    }

    /*****************************/
    /******** RENDER PART ********/
    /*****************************/
        const isOutgoing = props.isOutgoing != null ? props.isOutgoing : false;
        const classNameTable = "table_without_changes";
        
        if(props.data.shortTermMobility.preliminary_table_c !== undefined){
            if(props.data.shortTermMobility.preliminary_table_c.length === 0){
                return <p className="italic">No courses to show</p>;
            }
            return(
                <table className={classNameTable}>
                    <thead>
                        <tr>
                            <th className="code">Code</th>
                            <th className="title">Subject title</th>
                            <th className="description">Description</th>
                            <th className="ects">ECTS</th>
                            <th className="automatic_recognition">Automatic Recognition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableRows(isOutgoing)}
                        {calculateECTStotal()}
                    </tbody>
                </table>
            )
        }
    /*****************************/
    /****** END RENDER PART ******/
    /*****************************/
}

export default Table;