import gql from 'graphql-tag';
const query = gql`
	query InstitutionSearch($SearchValue: String){
	  institutionSearch(SearchValue: $SearchValue){
	    NameInLatinCharacterSet
	    ErasmusCode
	    City{
	      CityName
	      Country{
	        CountryName
	      }
	    }
	  }
	}
`;
export { query };