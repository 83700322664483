import React, { Component } from 'react';

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			focus: false
		}
		this.timeout =  0;
	}

	changeValue(event){
		this.setState({
			value: event.target.value
		})
	}

	stoppedTyping(){
		if(this.timeout) clearTimeout(this.timeout);
	    this.timeout = setTimeout(() => {
	      	let filters = {};
			if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
				filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			}
			filters["search"] = this.state.value;
			localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
			this.props.filterIsApplied();
	    }, 300);
	}

	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			if(!filters["search"]){
				this.setState({
					value: ""
				})
			}
			else{
				this.setState({
					value: filters["search"]
				})
			}
		}
	}

	generateSearchImg(){
		if(this.state.value !== ""){
			return <img src="/icons/search-focus.svg"/>;
		}
		return <img src="/icons/search.svg"/>;
	}

	renderInformationBox(){
		if(this.state.focus){
			return(
				<div className="search_information_box">
					<h4>Search for the following data</h4>
					<ul>
						<li>Student data</li>
						<li>
							<ul>
								<li>First name, last name</li>
								<li>Email address</li>
								<li>Language, language level</li>
							</ul>
						</li>
						<li>Home/host institution data</li>
						<li>
							<ul>
								<li>Faculty name</li>
								<li>Responsible name/email</li>
								<li>Contact name/email</li>
								<li>Institution name/erasmuscode</li>
							</ul>
						</li>
					</ul>
				</div>
			)
		}
	}

	render() {
		return (
  		<div className="search_wrapper">
  			{this.generateSearchImg()}
  			<input
					onKeyUp={() => this.stoppedTyping()}
					value={this.state.value}
					onChange={(event) => this.changeValue(event)}
					onFocus={() => this.setState({focus: true})}
					onBlur={() => this.setState({focus: false})}
					type="text"
					placeholder="Search"
				/>
				{this.renderInformationBox()}
  		</div>
    );
  }

};

export default Search;
