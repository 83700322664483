import React, { Component } from 'react';
import InputColumn from './InputColumn';
import GlobalVar from '../../../GlobalVar';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../queries/getTutorial';

class Row extends Component {
	constructor(props) {
		super(props);
		this.handlePastingData = this.handlePastingData.bind(this);
		this.handleMultiFillInData = this.handleMultiFillInData.bind(this)
		this.handlePopUpList = this.handlePopUpList.bind(this);
		this.handleCheckingBeforeUpload = this.handleCheckingBeforeUpload.bind(this);
		this.handleDateComparing = this.handleDateComparing.bind(this);
		this.state = {
			indexColumn: 0,
			pastedValue: "",
			checked: false,
			cities: [],
			institutions: [],
			statusPopUp: false,
			sendingCoordinatorValue: "",
			sendingResponsibleValue: "",
			fieldOfEducationValue: "",
			reloadDate: false,
			openTutorial : false,
		}
		this.overlay = true;
		this.tutorialID = "";
		this.x = 0;
		this.y = 0;
	}
	handlePastingData(array, indexColumn){
		this.props.handlePastingData(array, indexColumn, this.props.index);
	}
	handleMultiFillInData(value, indexColumn){
		this.props.handleMultiFillInData(value, indexColumn);
	}
	handlePopUpList(statusPopUp){
		this.setState({
			statusPopUp
		})
	}
	handleCheckingBeforeUpload(correctData){
		this.props.handleCheckingBeforeUpload(correctData);
	}
	handleDateComparing(stateValue){
		this.setState({
			reloadDate: stateValue
		})
	}
	componentWillMount() {
		if(GlobalVar.checkIfObjectIsInLocalStorageArray("selectedRows", this.props.index)){
			this.setState({
				checked: true
			})
		}
		this.setState({
			fieldOfEducationValue: this.props.data.fieldOfEducation,
			sendingCoordinatorValue: this.props.data.sendingCoordinator,
			sendingResponsibleValue: this.props.data.sendingResponsible
		})
	}
	componentWillReceiveProps(nextProps) {
		//

		//PASTING
		if(nextProps.indexRow !== undefined && this.props.pasteArray !== nextProps.pasteArray){
			const beginIndex = nextProps.indexRow;
			const endIndex = nextProps.indexRow+nextProps.pasteArray.length;
			if(nextProps.index >= beginIndex && nextProps.index < endIndex){
				const arrayIndex = nextProps.index-nextProps.indexRow;
				//
				this.setState({
					indexColumn: nextProps.indexColumn,
					pastedValue: nextProps.pasteArray[arrayIndex]
				})
			}
		}
		//Multi selecting
		if(nextProps.selectionMinimum !== this.props.selectionMinimum || nextProps.selectionMaximum !== this.props.selectionMaximum){
			if(nextProps.index > nextProps.selectionMinimum && nextProps.index < nextProps.selectionMaximum){
				this.setState({
					checked: true
				})
			}
			else if(nextProps.index === nextProps.selectionMinimum || nextProps.index === nextProps.selectionMaximum){
				this.setState({
					checked: true
				})
			}
			else{
				this.setState({
					checked: false
				})
			}
		}


		if(nextProps.multiFillInValue !== this.props.multiFillInValue || this.props.indexColumn !== nextProps.indexColumn){
			if(this.state.checked){
				if(nextProps.indexColumn !== 12 && nextProps.indexColumn !== 13 && nextProps.indexColumn !== 11){
					this.setState({
						indexColumn: nextProps.indexColumn,
						pastedValue: nextProps.multiFillInValue
					})
				}
				else if(nextProps.indexColumn === 11){
					this.setState({
						fieldOfEducationValue: nextProps.multiFillInValue,
					})
					this.safeValue("fieldOfEducation", nextProps.index, nextProps.multiFillInValue);
				}
				else if(nextProps.indexColumn === 12){
					this.setState({
						sendingCoordinatorValue: nextProps.multiFillInValue,
					})
					this.safeValue("sendingCoordinator", nextProps.index, nextProps.multiFillInValue);
				}
				else if(nextProps.indexColumn === 13){
					this.setState({
						sendingResponsibleValue: nextProps.multiFillInValue,
					})
					this.safeValue("sendingResponsible", nextProps.index, nextProps.multiFillInValue);
				}
			}
		}
	}

	componentWillUpdate(nextProps, nextState) {
		//Defining selectionArray
		if(this.state.checked != nextState.checked){
			if(nextState.checked){
				GlobalVar.addToLocalStorageArray("selectedRows", nextProps.index);
			}
			else{
				GlobalVar.removeLocalStorageFromArray("selectedRows", nextProps.index);
			}
		}
		return true;
	}
	checkBoxClick(event){
        console.log(window.innerWidth,event.clientX,window.innerHeight,event.clientY)
		if(!this.state.checked){
			if(event.shiftKey){
				this.props.handleMultipleSelecting(undefined, this.props.index);
			}
			else{
				this.props.handleMultipleSelecting(this.props.index, undefined);
			}
		}
		this.setState({
			checked: !this.state.checked,
			openTutorial: true
		});
		this.x = 20;
		this.y = event.clientY;
	}


	checkPopUp(){
		if(this.state.statusPopUp){
			return " overlap";
		}
		return "";
	}

	generateAccounts(){
		return this.props.accountData.map(({id, Name, Email, Phone, AccountFunction}) => {
			let specialIDforFasterUploading = JSON.stringify({Name, Email, Phone, "function": AccountFunction.Name});
			return <option key={id} value={specialIDforFasterUploading} >{Name}</option>;
		})
	}

	generateInstitutionAccounts(){
		return this.props.institutionAccountData.map(({id, Email, Phone}, index) => {
			let specialIDforFasterUploading = JSON.stringify({Name: `Institution account ${index + 1}`, Email, Phone, "function": "Main account holder"});
			return <option key={id} value={specialIDforFasterUploading} >Institution account {index + 1} ({Email})</option>;
		});
	}

	changeFieldOfEducationValue(event){
		this.setState({
			fieldOfEducationValue: event.target.value
		})
		this.handleMultiFillInData(event.target.value, 11);

		this.safeValue("fieldOfEducation", this.props.index, event.target.value);
	}
	changeSendingCoordinatorValue(event, sendingResponsibleValue){
		let value = "";
		if(event){
			value = event.target.value;
		} else{
			value = sendingResponsibleValue;
		}
		this.setState({
			sendingCoordinatorValue: value
		}, () => {
			if(this.state.sendingResponsibleValue === "" && this.state.sendingCoordinatorValue !== ""){
				this.changeSendingResponsibleValue(null, this.state.sendingCoordinatorValue);
			}
		})
		this.handleMultiFillInData(value, 12);

		this.safeValue("sendingCoordinator", this.props.index, value);
	}
	changeSendingResponsibleValue(event, sendingCoordinatorValue){
		let value = "";
		if(event){
			value = event.target.value;
		} else{
			value = sendingCoordinatorValue;
		}
		this.setState({
			sendingResponsibleValue: value
		}, () => {
			if(this.state.sendingCoordinatorValue === "" && this.state.sendingResponsibleValue !== ""){
				this.changeSendingCoordinatorValue(null, this.state.sendingResponsibleValue);
			}
		})
		this.handleMultiFillInData(value, 13);

		this.safeValue("sendingResponsible", this.props.index, value);

	}

	safeValue(key, rowIndex, value){
		let studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
		studentUpload[rowIndex][key] = value;
		localStorage.setItem("studentUpload", JSON.stringify(studentUpload));
	}

	generateTutorial(){
		if(this.props.tutorial){
			for(let data of this.props.tutorial){
		    	if(data.didShiftClickUpload == 0 && this.state.openTutorial && this.overlay){
		    		this.overlay = false;
		    		this.tutorialID = data.id;
			        return (
				        <div style={{"left":this.x,"right":this.y}} className="overlay" >
				            <h1 className="Header">Select Options : </h1>
				            <p className="textTutorial">To select multiple students, click the select box, and then press the "shift" key to select more.
				            </p>
				            <form className="button" onSubmit={(event) => this.handleTutorial(event)}><button>Skip</button></form>
				        </div>
			        )
				}
	        }
        }
    }

    handleTutorial(event){
    	event.preventDefault();
        this.setState({
			openTutorial : false
		})

		this.props.updateTutorial({
			variables:{
			   "id"	: this.tutorialID,
			   "didShiftClickUpload" : 1
			},
		    refetchQueries: [{query}]
	    })
    }

    generateStudies(){
    	return GlobalVar.studyList.map((data) => {
    		//if(data.code.length > 3){
    			return (
	    			<option key={data.code} value={`${data.code} - ${data.text}`} >{`${data.code} - ${data.text}`}</option>
	    		)
    		/*}
    		else{
    			return (
	    			<option disabled key={data.code} >{`${data.code} - ${data.text}`}</option>
	    		)
    		}*/
    	})
    }

	render() {
		let index = this.props.index + 1;
    	return (
    		<div className={"row"+this.checkPopUp()}>
				<div className="column bulk"><input onClick={(event) => this.checkBoxClick(event)} checked={this.state.checked} type="checkbox" /></div>
				{this.generateTutorial()}
				<div className="column nr"><p>{index}</p></div>
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.firstName} index={0} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"firstName"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} uploadCopyPaste={this.props.uploadCopyPaste}/>
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.lastName} index={1} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"lastName"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.email} index={2} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"email"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.beginDate} index={3} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} division={true} selected={this.state.checked} date={true} name={"beginDate"} reloadDate={this.state.reloadDate} handleDateComparing={this.handleDateComparing} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.endDate} index={4} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} date={true} name={"endDate"} reloadDate={this.state.reloadDate} handleDateComparing={this.handleDateComparing} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.faculty} index={5} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} division={true} selected={this.state.checked} name={"faculty"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />

				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.receivingInstitution} index={6} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"receivingInstitution"} handlePopUpList={this.handlePopUpList} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.hostContactName} index={7} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"hostContactName"} division={true}  uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.hostContactEmail} index={8} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"hostContactEmail"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.hostResponsibleName} index={9} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"hostResponsibleName"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />
				<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.hostResponsibleEmail} index={10} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"hostResponsibleEmail"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />

				{/*<InputColumn handlePastingData={this.handlePastingData} handleMultiFillInData={this.handleMultiFillInData} rowIndex={this.props.index} value={this.props.data.fieldOfEducation} index={11} indexColumn={this.state.indexColumn} pastedValue={this.state.pastedValue} selected={this.state.checked} name={"fieldOfEducation"} uploading={this.props.uploading} handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} />*/}
				<div className="column">

					<select onChange={(event) => this.changeFieldOfEducationValue(event)} value={this.state.fieldOfEducationValue}>
						<option value="">Select</option>
						{this.generateStudies()}
					</select>
				</div>
				<div className="column division">
					<select onChange={(event) => this.changeSendingCoordinatorValue(event)} value={this.state.sendingCoordinatorValue} >
						<option value="">Select</option>
						{this.generateAccounts()}
						{this.generateInstitutionAccounts()}
					</select>
				</div>
				<div className="column">
					<select onChange={(event) => this.changeSendingResponsibleValue(event)} value={this.state.sendingResponsibleValue} >
						<option value="">Select</option>
						{this.generateAccounts()}
						{this.generateInstitutionAccounts()}
					</select>
				</div>


			</div>
      	);
    }
};
const updateTutorial = gql`
  mutation updateTutorial($id: ID!, $didShiftClickUpload: Int!){
    updateTutorialUpload(id: $id , didShiftClickUpload: $didShiftClickUpload){
      erasmusCode
    }
  }
`

export default compose(
    graphql(updateTutorial, {name: "updateTutorial"})
)(Row);
