import gql from 'graphql-tag';

const query = gql`
  query{
    accounts(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      Name
      Email
      Phone
      AccountFunction{
        id
        Name
      }
    }
    InstitutionRegistrationsByErasmusCode(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      Email
      Phone
    }
    tutorial(erasmusCode: "${localStorage.getItem('ErasmusCode')}",accountID: "${localStorage.getItem('accountID')}"){
        id
        erasmusCode
        accountID
        didShiftClickList
        didShiftClickUpload
        uploadCopyPaste
      }
  }
`
export { query };
