import React, { Component } from 'react';
import { graphql, gql as gqlM } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

class Info extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tcCheckboxEdit: false
        }

        this.termsAndConditionsHandler = this.termsAndConditionsHandler.bind(this);
    }

    componentDidMount() {
        this.setState({ tcCheckboxEdit: (sessionStorage.getItem("tcCheckboxEdit") === "true") ? true : false });
    }

    render() {
        return (
            <div className={this.props.className}>
                { this.renderTopInfo() }
                { this.renderEWPInfo() }
            </div>
        );
    }

    renderTopInfo() {
        return (
            <div>
                <div>
                    <h2 style={{ textAlign: "center" }}>Key Action 1 - Mobility of learners and staff - Higher Education Student and Staff Mobility between Programme Countries</h2>
                    <h2 style={{ textAlign: "center" }}>Requirements for Inter-Institutional Agreements 2021-20[29]</h2>
                    <p>&nbsp;</p>
                </div>

                <div>
                    <h1>Static information applicable to all Inter-Institutional Agreements</h1>
                    <p>&nbsp;</p>
                    <p>*The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus programme. They commit to respect the quality requirements of the <a href="https://ec.europa.eu/programmes/erasmus-plus/resources/documents/applicants/higher-education-charter_en" target="_blank">Erasmus Charter for Higher Education</a> in all aspects related to the organisation and management of the mobility, in particular the <a href="https://ec.europa.eu/education/education-in-the-eu/proposal-for-a-council-recommendation-on-the-automatic-mutual-recognition-of-diplomas-and-learning-periods-abroad_en" target="_blank">automatic recognition</a> of the credits awarded to students by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript of Records, or according to the learning outcomes of the modules completed abroad, as described in the Course Catalogue, in line with the <a href="https://ec.europa.eu/education/resources-and-tools/european-credit-transfer-and-accumulation-system-ects_en" target="_blank">European Credit and Accumulation System</a>. The institutions agree on exchanging their mobility related data in line with the technical standards of <a href="https://ec.europa.eu/education/education-in-the-eu/european-student-card-initiative_en" target="_blank">the European Student Card Initiative</a>.</p>
                    <p>&nbsp;</p>
                </div>

                <div>
                    <p>&nbsp;</p>
                    <h3>Grading systems of the institutions</h3>
                    <p>It is recommended that receiving institutions provide a link to the statistical distribution of grades or makes the information available through <a href="https://egracons.eu/" target="_blank">EGRACONS</a> according to the descriptions in the <a href="https://ec.europa.eu/education/ects/users-guide/docs/ects-users-guide_en.pdf" target="_blank">ECTS users’ guide</a>. The information will facilitate the interpretation of each grade awarded to students and will facilitate the credit transfer by the sending institution.</p>
                    <p>&nbsp;</p>
                    <p><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxEdit} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        );
    }

    renderEWPInfo() {
        if(this.props.data.loading) {
            return (
                <div>
                    <h3 style={{color:"black"}}>Getting EWP information. Please wait a few seconds.</h3>
                </div>
            );
        }
        if(this.props.data.error) {
            return (
                <div>
                    <h3 style={{color:"red"}}>An error occurred while getting EWP information.</h3>
                </div>
            );
        }
        if(this.props.data.isIIAManagedByEWP.Success == 1) {
            return (
                <div>
                    <h3 style={{color:"red"}}>The IIAs of your institution are managed by a node in the EWP network which is not the Dashboard. You will not be able to send this agreement through the EWP Network.</h3>
                </div>
            );
        }
        if(this.props.data.isIIAManagedByEWP.Success == 2) {
            return (
                <div>
                    <h3 style={{color:"green"}}>The IIAs of your institution are managed by the Dashboard in the EWP network so you will be able to send this agreement through the EWP Network.</h3>
                </div>
            );
        }
        if(this.props.data.isIIAManagedByEWP.Success == -1) {
            return (
                <div>
                    <h3 style={{color:"blue"}}>The IIAs of your institution are not managed by a node in the EWP network neither by the Dashboard so this agreement cannot be sent by the EWP network. If you want the Dashboard to be responsible for handling the IIAs through the EWP network, please check that you have enabled the Dashboard in the Settings page of the EWP menu on the left.</h3>
                </div>
            );
        }
        return (
            <div>
                <h3 style={{color:"red"}}>Error fetching EWP data. It may not be possible to send this Agreement through the network. If the problem persists please contact the administrator.</h3>
            </div>
        );
    }

    termsAndConditionsHandler(event) {
        const { name, value } = event.target;
        const isChecked = (document.getElementsByName(name)[0].checked) ? true : false;

        let iiaListAuxiliarInformation = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        iiaListAuxiliarInformation.nextDisabled = !isChecked;
        sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(iiaListAuxiliarInformation));
        sessionStorage.setItem("tcCheckboxEdit", isChecked);
        this.setState({ tcCheckboxEdit: isChecked });
        
        this.props.refreshPage();        
    }
}

const isIIAManagedByEWP = gql`
query isIIAManagedByEWP($ErasmusCode: String!) {
    isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
        Success
        Message
  }
}
`;

export default compose(
    graphql(isIIAManagedByEWP, {
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode")
                }
            }
        }
    })
)(Info);