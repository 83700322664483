import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import axios from 'axios';
import querystring from 'querystring';
import intl from 'react-intl-universal';

import GlobVar from '../../../GlobalVar';
import Partner from './Partner';
import Condition from './Condition';
import Buttons from './Buttons';
import Title from '../common/Title';
import Info from './Info';
import Summary from './Summary';
import CalendarInfo from './CalendarInfo';

import { query as getInstitution } from '../../../queries/getInstitution';

/**
 * This component is the root of all components used to create an agreement.
 * 
 * If we look to this as a tree, the direct components child are:
 *  - Partner
 *  - Condition
 *  - Buttons
 * 
 * Also, this component generates and updates the three localStorage variables that 
 * keep the agreement information and others development informations. They are:
 *  - **partnersDetails**: first page with data regarding both partners (institutions, contacts and agreement data)
 *  - **coopConditions**: next pages with data regarding cooperation conditions (mobility type, language skill, ISCED-F code, 
 *    CEFR Levels, etc). It's an array, so it's possible to add more than one condition in the same
 *    agreement purpose
 *  - **iiaCreationAuxiliarInformation**: data for development purposes (which page user is at the moment, visibility of 
 *    buttons, etc)
 * 
 * @see Partner
 * @see Condition
 * @see Buttons
 */
class IIACreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reloadPage: false,
            coopConditionsPageEnable: false,
            iiasSubmited: 0,
            ewpStatus: -10,
            ewpPartnerStatus: -10
        }

        this.numberErrors = 0;

        this.refreshPages = this.refreshPages.bind(this);
        this.refreshPageNumber = this.refreshPageNumber.bind(this);
        this.clearDisabled = this.clearAll.bind(this);
        this.isPartnersRequiredFieldsFilled = this.isPartnersRequiredFieldsFilled.bind(this);
        this.enableButtons = this.enableButtons.bind(this);
        this.disableButtons = this.disableButtons.bind(this);
        this.addNewCoopCondition = this.addNewCoopCondition.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.setErrors = this.setErrors.bind(this);
        this.submitIIA = this.submitIIA.bind(this);
    }

    componentDidMount() {
        this.checkEwpConnection();
    }
    
    render() {
        let partnersDetails = JSON.parse(localStorage.getItem("partnersDetails"));
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let tcCheckboxCreate = sessionStorage.getItem("tcCheckboxCreate");

        if (!this.props.data.loading) {
            if (iiaCreationAuxiliarInformation === null) {
                this.generateIIACreationAuxiliarInformation();
            }

            if (tcCheckboxCreate === null) {
                this.generateTCcheckbox();
            }

            if (partnersDetails === null) {
                this.generatePartnersDefinitions();
            }

            if (coopConditions === null) {
                this.generateCoopConditions();
            }

            if (this.state.ewpStatus !== -10) {
                this.verifyConditionsToUpdateButtons();

                return (
                    <div className="content upload">
                        {this.renderPage()}
                        {this.renderButtons()}
                    </div>
                );
            }
            return (
                <div className="content upload">
                    <Title text="Getting EWP information. This can take approximately 3 minutes." />
                </div>
            );
        }

        return (
            <div className="content upload">
                <Title text="Loading and setting up all forms..." />
                <h4>If loading is taking more than 5 minutes, please refresh the page.</h4>
            </div>
        );
    }

    /********************/
    /* RENDER FUNCTIONS */
    /********************/
    renderPage() {
        let partnersDetails = JSON.parse(localStorage.getItem("partnersDetails"));
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));

        if (this.state.ewpStatus === 1) {
            localStorage.removeItem("partnersDetails");
            localStorage.removeItem("coopConditions");
            return (
                <Info
                    className="card scrollbar card_full_width card_full_height"
                    refreshPage={this.refreshPages}
                />
            );
        } 
        else {
            if (iiaCreationAuxiliarInformation.pageNumber === -2) {
                return (
                    <Info
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPages}
                    />
                );
            }
            if (iiaCreationAuxiliarInformation.pageNumber === -1) {
                return (
                    <Partner
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPages}
                        data={partnersDetails}
                        setErrors={this.setErrors}
                    />
                );
            }
            else if (iiaCreationAuxiliarInformation.pageNumber === 0) {
                return (
                    <CalendarInfo
                        className="card scrollbar card_full_width card_full_height"
                        data={partnersDetails}
                        refreshPage={this.refreshPages}
                        setErrors={this.setErrors}
                    />
                );
            }
            else if (iiaCreationAuxiliarInformation.pageNumber === iiaCreationAuxiliarInformation.numberOfConditions + 1) {
                return (
                    <Summary
                        className="card scrollbar card_full_width card_full_height"
                        refreshPages={this.refreshPages}
                    />
                );
            }
            else if (iiaCreationAuxiliarInformation.pageNumber > 0) {
                return (
                    <Condition
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPages}
                        institutions={[partnersDetails.creatorErasmusCode, partnersDetails.partnerErasmusCode]}
                        data={coopConditions}
                        setErrors={this.setErrors}
                        showMobFields={iiaCreationAuxiliarInformation.showMobFields}
                    />
                );
            }
        }
        return;
    }

    renderButtons() {
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        return (
            <Buttons
                className="card card_full_width actions"
                id="upload_card_create_iia"
                auxInfo={iiaCreationAuxiliarInformation}
                refreshPageNumber={this.refreshPageNumber}
                refreshPage={this.refreshPages}
                clearAll={this.clearAll}
                refreshCoopConditions={this.refreshCoopConditions}
                isPartnersRequiredFieldsFilled={this.isPartnersRequiredFieldsFilled}
                disableButtons={this.disableButtons}
                enableButtons={this.enableButtons}
                addNewCoopCondition={this.addNewCoopCondition}
                refreshNumberOfConditions={this.refreshNumberOfConditions}
                deleteCondition={this.deleteCondition}
                submitIIA={this.submitIIA}
            />
        );
    }

    /**********************/
    /* AUXILIAR FUNCTIONS */
    /**********************/
    generatePartnersDefinitions() {
        if (this.props.data.institution) {
            const { NameInLatinCharacterSet, ErasmusCode, City, Country, InstitutionDetail } = this.props.data.institution;

            let partnersDetails = ({
                creatorInstitution: NameInLatinCharacterSet,        // Required
                creatorErasmusCode: ErasmusCode,                    // Required
                creatorCountry: Country,
                creatorCity: City,

                creatorOUnit: "",
                creatorFactsheetData: "",

                creatorContactName: localStorage.getItem("Name"),     // Required
                creatorContactEmail: GlobVar.decryptWithKey(localStorage.getItem("Email")),    // Required
                creatorContactPhone: (InstitutionDetail != null && InstitutionDetail.Phone != null) ? InstitutionDetail.Phone : "",
                
                creatorGeneralWebsite: (InstitutionDetail != null && InstitutionDetail.Website != null) ? InstitutionDetail.Website : "",
                creatorFacultyWebsite: "",
                creatorCourseWebsite: "",

                creatorSigningDate: this.setDate(),     // Required
                iiaCode: this.setIIACode(ErasmusCode),                // Required

                partnerInstitution: "",     // Required
                partnerErasmusCode: "",     // Required
                partnerCountry: "",
                partnerCity: "",

                partnerOUnit: "",
                partnerFactsheetData: "",

                partnerContactName: "",     // Required 
                partnerContactEmail: "",    // Required
                partnerContactPhone: "",

                partnerGeneralWebsite: "",
                partnerFacultyWebsite: "",
                partnerCourseWebsite: "",

                academicYearStart: "",      // Required
                academicYearEnd: "",        // Required

                pageComplete: false
            });
            localStorage.setItem("partnersDetails", JSON.stringify(partnersDetails));
        }
        else {
            // show error page
        }
    }

    generateCoopConditions() {
        let coopConditions = [];

        coopConditions.push({
            senderInstitution: "",      // Required
            receiverInstitution: "",    // Required
            sendingOUnit: "",
            receivingOUnit: "",
            mobilitiesPerYear: "",      // Required
            iscedFCode: [{code: "", clarification: ""}],
            languageSkill: [{language: "", cefrLevel: "", iscedFCode: "", clarification:""}],
            mobilityTypeSelected: "",   // Required
            totalMonths: "",            // Required (with conditions)
            totalDays: "",              // Required (with conditions)
            eqfLevels: [],              // Required (with conditions)
            blended: 0,
            otherInformation: "",
            pageComplete: false
        });

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
    }

    generateIIACreationAuxiliarInformation() {
        let iiaCreationAuxiliarInformation = ({
            pageNumber: -2,
            numberOfConditions: 1,
            allPagesCompleted: false,
            clearDisabled: false,
            backDisabled: true,
            nextDisabled: true,
            addDisabled: true,
            deleteDisabled: true,
            submitDisabled: true,
            showMobFields: "",
            creatorOUnitList: [],
            partnerOUnitList: [],
            partnerErasmusCodeValid: false,
            getPDWasClickedAtLeastOnce: false,
            isRegularNamesValid: true,
            isEmailValid: true,
            isPhoneValid: true,
            isWebsiteValid: true
        });
        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(iiaCreationAuxiliarInformation));
    }

    generateTCcheckbox() {
        sessionStorage.setItem("tcCheckboxCreate", false);
    }

    checkEwpConnection() {
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : "",
            },
        }).then((x) => {
            if (x.data.error) { this.setState({ ewpStatus: 0 }); }
            else { this.setState({ ewpStatus: x.data.isIIAManagedByEWP.Success }); }
        });
    }

    checkPartnerEwpConnection(ErasmusCode, callback) {
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": ErasmusCode,
            },
        }).then((x) => {
            console.log("x: " + JSON.stringify(x));
            if (x.data.error) { this.setState({ ewpPartnerStatus: 0 }); }
            else { this.setState({ ewpPartnerStatus: x.data.isIIAManagedByEWP.Success }); }
            return callback();
        });
    }

    setDate() {
        let year = new Date().getUTCFullYear();
        let month = new Date().getUTCMonth() + 1;
        let day = new Date().getUTCDate();
        let hours = new Date().getUTCHours();
        let minutes = new Date().getUTCMinutes();
        let seconds = new Date().getUTCSeconds();

        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }

        let fullUTCDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return fullUTCDate;
    }

    setIIACode(erasmusCode) {
        let currentDate = Date.now() + "";
        return erasmusCode.replace(/[\s-]/g, "") + "-" + currentDate.replace(/[\s-]/g, "");
    }

    handleEnableDisable(value, name) {
        if (name === "mobilityTypeSelected") {
            if (value === "") {
                this.hideElements(document.querySelectorAll('[groupname="eqf"], [groupname="sms"], [groupname="stms"]'));
            }
            else if (value === "Student studies") {
                this.hideElements(document.querySelectorAll('[groupname="stms"]'));
                this.showElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                
            }
            else if (value === "Student traineeship") {
                this.hideElements(document.querySelectorAll('[groupname="eqf"], [groupname="stms"]'));
                this.showElements(document.querySelectorAll('[groupname="sms"]'));
            }
            else if ((value === "Staff teacher") || (value === "Staff training")) {
                this.hideElements(document.querySelectorAll('[groupname="eqf"], [groupname="sms"]'));
                this.showElements(document.querySelectorAll('[groupname="stms"]'));
            }
        }
        this.refreshPages();
    }

    hideElements(elements) {
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = "none";
        }
    }

    showElements(elements) {
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = "block";
        }
    }

    verifyConditionsToUpdateButtons() {
        let partnersDetails = JSON.parse(localStorage.getItem("partnersDetails"));
        let aux = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let tcCheckboxCreate = sessionStorage.getItem("tcCheckboxCreate");

        if (this.state.ewpStatus !== 2) {
            this.disableButtons(["backDisabled", "nextDisabled", "addDisabled", "deleteDisabled", "submitDisabled"]);
        }
        else {
            if (aux !== null && partnersDetails !== null && coopConditions !== null) {
                if (aux.pageNumber === -2) {
                    this.disableButtons(["backDisabled", "addDisabled", "deleteDisabled", "submitDisabled"]);
                    (tcCheckboxCreate === "true") ? this.enableButtons(["nextDisabled"]) : this.disableButtons(["nextDisabled"]);
                }
                else if (aux.pageNumber === -1) {
                    this.disableButtons(["deleteDisabled"]);
                    this.enableButtons(["backDisabled"]);

                    if (!this.isPartnersRequiredFieldsFilled() || !aux.isRegularNamesValid || !aux.isEmailValid || !aux.isPhoneValid || !aux.isWebsiteValid) {
                        this.disableButtons(["nextDisabled"]);
                    }
                    else {
                        this.enableButtons(["nextDisabled"]);
                    }
                }
                else if (aux.pageNumber === 0) {
                    this.enableButtons(["backDisabled", "nextDisabled"]);
                    this.disableButtons(["submitDisabled", "addDisabled", "deleteDisabled"]);
                }
                else if (aux.pageNumber === aux.numberOfConditions + 1) {
                    this.disableButtons(["nextDisabled", "deleteDisabled", "addDisabled"]);

                    if (this.areAllPagesCompleted(partnersDetails, coopConditions)) {
                        this.enableButtons(["submitDisabled"]);
                    }
                    else { this.disabledButtons(["submitDisabled"]); }
                }
                else if (aux.pageNumber === aux.numberOfConditions) {
                    this.disableButtons(["submitDisabled"]);

                    if (aux.numberOfConditions > 1) {
                        this.enableButtons(["deleteDisabled"]);
                    }
                    else {
                        this.disableButtons(["deleteDisabled"]);
                    }

                    if (!this.isPartnersRequiredFieldsFilled() || !this.isConditionRequiredFieldsFilled()) {
                        this.disableButtons(["addDisabled", "submitDisabled", "nextDisabled"]);
                    }
                    else {
                        this.enableButtons(["addDisabled", "nextDisabled"]);
                    }
                }
                else {
                    if (aux.numberOfConditions > 1) {
                        this.enableButtons(["deleteDisabled"]);
                    }
                    else {
                        this.disableButtons(["deleteDisabled"]);
                    }

                    this.disableButtons(["addDisabled", "submitDisabled"]);
                    this.enableButtons(["backDisabled", "nextDisabled", "deleteDisabled"]);
                }
            }
        }
    }

    isPartnersRequiredFieldsFilled() {
        let pd = JSON.parse(localStorage.getItem("partnersDetails"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (pd !== null) {
            if (pd.creatorInstitution !== "" /*&& pd.creatorContactName !== "" && pd.creatorContactEmail !== ""*/
                && pd.creatorSigningDate !== "" && pd.partnerInstitution !== "" && pd.partnerErasmusCode !== ""
                /*&& auxInfo.partnerErasmusCodeValid && pd.partnerContactName !== "" && pd.partnerContactEmail !== ""*/
                && pd.academicYearStart !== "" && pd.academicYearEnd !== "") {
                pd.pageComplete = true;
                localStorage.setItem("partnersDetails", JSON.stringify(pd));
                return true;
            }
        }
        pd.pageComplete = false;
        localStorage.setItem("partnersDetails", JSON.stringify(pd));
        return false;
    }

    isConditionRequiredFieldsFilled() {
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let cc = JSON.parse(localStorage.getItem("coopConditions"));

        if (cc[auxInfo.pageNumber - 1] !== null) {
            if (cc[auxInfo.pageNumber - 1].senderInstitution !== "" && cc[auxInfo.pageNumber - 1].receiverInstitution !== ""
                && !isNaN(cc[auxInfo.pageNumber - 1].mobilitiesPerYear) && cc[auxInfo.pageNumber - 1].mobilitiesPerYear !== ""
                && cc[auxInfo.pageNumber - 1].mobilityTypeSelected !== "") {
                if (cc[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student studies") {
                    if (cc[auxInfo.pageNumber - 1].totalMonths === "") {
                        cc[auxInfo.pageNumber - 1].pageComplete = false;
                        localStorage.setItem("coopConditions", JSON.stringify(cc));
                        return false;
                    }
                }
                else if (cc[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student traineeship") {
                    if (cc[auxInfo.pageNumber - 1].totalMonths === "" || isNaN(cc[auxInfo.pageNumber - 1].totalMonths)) {
                        cc[auxInfo.pageNumber - 1].pageComplete = false;
                        localStorage.setItem("coopConditions", JSON.stringify(cc));
                        return false;
                    }
                }
                else if (cc[auxInfo.pageNumber - 1].mobilityTypeSelected === "Staff teacher" || cc[auxInfo.pageNumber - 1].mobilityTypeSelected === "Staff training") {
                    if (cc[auxInfo.pageNumber - 1].totalDays === "" || isNaN(cc[auxInfo.pageNumber - 1].totalDays)) {
                        cc[auxInfo.pageNumber - 1].pageComplete = false;
                        localStorage.setItem("coopConditions", JSON.stringify(cc));
                        return false;
                    }
                }
                cc[auxInfo.pageNumber - 1].pageComplete = true;
                localStorage.setItem("coopConditions", JSON.stringify(cc));
                return true;
            }
            cc[auxInfo.pageNumber - 1].pageComplete = false;
            localStorage.setItem("coopConditions", JSON.stringify(cc));
            return false;
        }
    }

    areAllPagesCompleted(pd, cc) {
        if (pd.pageComplete) {
            cc.map((condition, index) => {
                if (!condition.pageComplete) {
                    return false;
                }
            });
        }
        return true;
    }

    refreshPages() {
        this.setState({
            reloadPage: !this.state.reloadPage
        });
    }

    refreshPageNumber(pageNumber) {
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let cc = JSON.parse(localStorage.getItem("coopConditions"));
        iiaCreationAuxiliarInformation.pageNumber = pageNumber;
        if (pageNumber > 0 && pageNumber < cc.length + 1) {
            this.handleEnableDisable(cc[pageNumber-1].mobilityTypeSelected, "mobilityTypeSelected");
        }
        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(iiaCreationAuxiliarInformation));
    }

    refreshNumberOfConditions(isToIncrement) {
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (isToIncrement) { auxInfo.numberOfConditions = auxInfo.numberOfConditions + 1; }
        else { auxInfo.numberOfConditions = auxInfo.numberOfConditions - 1; }

        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(auxInfo));
    }

    disableButtons(buttonsNames) {
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        buttonsNames.map(item => {
            if (this.state[item] !== true) {
                this.setState({
                    [item]: true
                });
                iiaCreationAuxiliarInformation[item] = true;
            }
        });
        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(iiaCreationAuxiliarInformation));
    }

    enableButtons(buttonsNames) {
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        buttonsNames.map(item => {
            if (this.state[item] !== false) {
                this.setState({
                    [item]: false
                });
                iiaCreationAuxiliarInformation[item] = false;
            }
        });
        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(iiaCreationAuxiliarInformation));
    }

    clearAll() {
        window.location.reload();
    }

    setErrors(hasError) {
        if (!hasError) {
            this.numberErrors--;
        }
        else {
            this.numberErrors++;
        }

        this.refreshPages();
    }

    addNewCoopCondition() {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));

        coopConditions.push({
            senderInstitution: "",      // Required
            receiverInstitution: "",    // Required
            sendingOUnit: "",
            receivingOUnit: "",
            mobilitiesPerYear: "",      // Required
            iscedFCode: [{code: "", clarification: ""}],
            languageSkill: [{language: "", cefrLevel: "", iscedFCode: "", clarification:""}],
            mobilityTypeSelected: "",   // Required
            totalMonths: "",            // Required (with conditions)
            totalDays: "",              // Required (with conditions)
            eqfLevels: [],              // Required (with conditions)
            blended: 0,
            otherInformation: "",
            pageComplete: false
        });

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
    }

    arrangeCalendarDetails(data) {
        let details = {};

        details = {
            creatorStuNomAutumn: data.creatorStuNomAutumn,
            creatorStuNomSpring: data.creatorStuNomSpring,
            partnerStuNomAutumn: data.partnerStuNomAutumn,
            partnerStuNomSpring: data.partnerStuNomSpring,
            creatorAppNomAutumn: data.creatorAppNomAutumn,
            creatorAppNomSpring: data.creatorAppNomSpring,
            partnerAppNomAutumn: data.partnerAppNomAutumn,
            partnerAppNomSpring: data.partnerAppNomSpring,
            creatorAppProEmail: data.creatorAppProEmail,
            creatorAppProPhone: data.creatorAppProPhone,
            creatorAppProWebsite: data.creatorAppProWebsite,
            partnerAppProEmail: data.partnerAppProEmail,
            partnerAppProPhone: data.partnerAppProPhone,
            partnerAppProWebsite: data.partnerAppProWebsite,
            creatorInclusion: data.creatorInclusion,
            creatorInclusionEmail: data.creatorInclusionEmail,
            creatorInclusionPhone: data.creatorInclusionPhone,
            creatorInclusionWebsite: data.creatorInclusionWebsite,
            partnerInclusion: data.partnerInclusion,
            partnerInclusionEmail: data.partnerInclusionEmail,
            partnerInclusionPhone: data.partnerInclusionPhone,
            partnerInclusionWebsite: data.partnerInclusionWebsite,
            creatorHousingEmail: data.creatorHousingEmail,
            creatorHousingPhone: data.creatorHousingPhone,
            creatorHousingWebsite: data.creatorHousingWebsite,
            partnerHousingEmail: data.partnerHousingEmail,
            partnerHousingPhone: data.partnerHousingPhone,
            partnerHousingWebsite: data.partnerHousingWebsite,
            creatorVisaEmail: data.creatorVisaEmail,
            creatorVisaPhone: data.creatorVisaPhone,
            creatorVisaWebsite: data.creatorVisaWebsite,
            partnerVisaEmail: data.partnerVisaEmail,
            partnerVisaPhone: data.partnerVisaPhone,
            partnerVisaWebsite: data.partnerVisaWebsite,
            creatorInsuranceEmail: data.creatorInsuranceEmail,
            creatorInsurancePhone: data.creatorInsurancePhone,
            creatorInsuranceWebsite: data.creatorInsuranceWebsite,
            partnerInsuranceEmail: data.partnerInsuranceEmail,
            partnerInsurancePhone: data.partnerInsurancePhone,
            partnerInsuranceWebsite: data.partnerInsuranceWebsite,
            creatorAddInfo: data.creatorAddInfo,
            creatorAddInfoType: data.creatorAddInfoType,
            creatorAddInfoWebsite: data.creatorAddInfoWebsite,
            partnerAddInfo: data.partnerAddInfo,
            partnerAddInfoType: data.partnerAddInfoType,
            partnerAddInfoWebsite: data.partnerAddInfoWebsite
        }
        return details
    }

    deleteCondition(pageNumber) {
        let cc = JSON.parse(localStorage.getItem("coopConditions"));

        if (pageNumber - 1 > -1) {
            cc.splice(pageNumber - 1, 1);
        }

        this.verifyConditionsToUpdateButtons();
        localStorage.setItem("coopConditions", JSON.stringify(cc));
        this.refreshPages();
    }

    sendNotificationFunction(creatingIIA) {

        console.log("Creating IIA")
        console.log(creatingIIA[0])

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": GlobVar.encryptWithKey(creatingIIA[0].creatorErasmusCode).toString(),
            },
        }).then((creatorSuccess) => {
            if (creatorSuccess.data.error) {
                alert("An error has occurred while checking creator in EWP. A notification will not be sent to this partner.");
                this.props.isIIAManagedByEWP({
                    variables: {
                        "ErasmusCode": creatingIIA[0].partnerErasmusCode,
                    },
                }).then((partnerSuccess) => {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner.");
                        alert("IIA data submited.");
                    } else {
                        console.log("Is partner in EWP?");
                        console.log(partnerSuccess.data);
                        if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                            alert("The creator will not receive any notification and the parter will receive a notification in their system regarding this agreement.");
                            this.sendEWPNotification(creatingIIA);
                            alert("IIA data submited.");
                        } else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                            alert("Only the partner will receive an email notification regarding this agreement.");
                            this.sendMailFunction(creatingIIA, 3);
                        } else {
                            alert("None of the partners will receive a notification regarding this agreement.");
                            alert("IIA data submited.");
                        }
                    }
                });
            } else {
                if(creatorSuccess.data.isIIAManagedByEWP.Success==2) {
                    this.props.isIIAManagedByEWP({
                        variables: {
                            "ErasmusCode": creatingIIA[0].partnerErasmusCode,
                        },
                    }).then((partnerSuccess) => {
                        if (partnerSuccess.data.error) {
                            alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner.");
                            this.sendMailFunction(creatingIIA, 1);
                            alert("IIA data submited.");
                        } else {
                            console.log("Is partner in EWP?");
                            console.log(partnerSuccess.data);
                            if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                                alert("Both partners will receive an email notification regarding this agreement.");
                                this.sendMailFunction(creatingIIA, 2);
                            }
                            else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                                alert("The creator will receive an email notification and the parter will receive a notification in their system regarding this agreement.");
                                this.sendMailFunction(creatingIIA, 1);
                                this.sendEWPNotification(creatingIIA);
                                alert("IIA data submited.");
                            } 
                            else if(partnerSuccess.data.isIIAManagedByEWP.Success == 0) {
                                alert("The creator will receive an email notification, but it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                                this.sendMailFunction(creatingIIA, 1);
                                alert("IIA data submited.");
                            }
                            else {
                                alert("None of the partners will receive a notification regarding this agreement.");
                                alert("IIA data submited.");
                            }
                        }
                    });
                }
            }
        });
        this.deleteIIAInformation();
    }

    sendMailFunction(creatingIIA, sendEmailTo) {
        const title = "New agreement created";
        const text = "A new agreement was created where you are one of the partners. You can see it in the Dashboard ('IIA List')."
        const action_url = GlobVar.getClientLink();

        if (creatingIIA[0].creatorContactEmail != null) {
            // send to creator only
            if(sendEmailTo == 1) {
                creatingIIA.map((data, index) => {
                    axios.post(`${GlobVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                        receiver: data.creatorContactEmail,
                        institution_name: data.creatorInstitution,
                        institution_ecode: data.creatorErasmusCode,
                        newiia_id: data.iiaCode,
                        action_url: action_url
                    }));
                });
            }

            // send to both partners
            if(sendEmailTo == 2) {
                creatingIIA.map((data, index) => {
                    axios.post(`${GlobVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                        receiver: data.creatorContactEmail,
                        institution_name: data.creatorInstitution,
                        institution_ecode: data.creatorErasmusCode,
                        newiia_id: data.iiaCode,
                        action_url: action_url
                    }));
                    axios.post(`${GlobVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                        receiver: data.partnerContactEmail,
                        institution_name: data.creatorInstitution,
                        institution_ecode: data.creatorErasmusCode,
                        newiia_id: data.iiaCode,
                        action_url: action_url
                    }));
                });
            }

            // send to partner only
            if (sendEmailTo == 3) {
                creatingIIA.map((data, index) => {
                    axios.post(`${GlobVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                        receiver: data.partnerContactEmail,
                        institution_name: data.creatorInstitution,
                        institution_ecode: data.creatorErasmusCode,
                        newiia_id: data.iiaCode,
                        action_url: action_url
                    }));
                });
            }
        }
        else {
            alert("There is no contact to send the notification by email.");
        }
    }

    sendEWPNotification(creatingIIA){
        this.props.sendEWPIIANotification({
            variables: {
                "CreatorErasmusCode": GlobVar.encryptWithKey(creatingIIA[0].creatorErasmusCode).toString(),
                "PartnerErasmusCode": creatingIIA[0].partnerErasmusCode,
                "IIAID": creatingIIA[0].iiaCode
            },
        }).then((x) => {
            if (x.data.error) {
                alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
            } else {
                if(x.data.sendEWPIIANotification.Success){
                    alert("Notification sent successfully to the partner.");
                } else {
                    alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                }
            }
        });
    }

    deleteIIAInformation() {
        localStorage.removeItem("partnersDetails");
        localStorage.removeItem("coopConditions");
        localStorage.removeItem("iiaCreationAuxiliarInformation");
        this.props.history.push(GlobVar.generateLinkUrl(intl, "IIAm", "IIA List"));
    }

    submitIIA() {
        let pd = JSON.parse(localStorage.getItem("partnersDetails"));
        let cc = JSON.parse(localStorage.getItem("coopConditions"));
        const nPages = cc.length;
        let pdArray = [];
        pdArray.push(pd);

        if (window.confirm("Are you sure do you want to create this agreement?")) {
            this.checkPartnerEwpConnection(pdArray[0].partnerErasmusCode, () => {
                const promiseSendIIA = pdArray.map((
                    { iiaCode, creatorInstitution, creatorErasmusCode, creatorCountry, creatorCity, partnerInstitution, partnerErasmusCode, partnerCountry, partnerCity, creatorContactName, creatorContactEmail, creatorContactPhone, creatorGeneralWebsite, creatorFacultyWebsite, creatorCourseWebsite, creatorOUnit, partnerContactName, partnerContactEmail, partnerContactPhone, partnerGeneralWebsite, partnerFacultyWebsite, partnerCourseWebsite, partnerOUnit, academicYearStart, academicYearEnd, creatorFactsheetData, partnerFactsheetData }
                    , index) => {

                    console.log("this.state.ewpPartnerStatus: " + this.state.ewpPartnerStatus);

                    const uploadVariables = { creatorInstitution, creatorErasmusCode, creatorCountry, creatorCity, partnerInstitution, partnerErasmusCode, partnerCountry, partnerCity, creatorContactName, creatorContactEmail, creatorContactPhone, creatorGeneralWebsite, creatorFacultyWebsite, creatorCourseWebsite, creatorOUnit, partnerContactName, partnerContactEmail, partnerContactPhone, partnerGeneralWebsite, partnerFacultyWebsite, partnerCourseWebsite, partnerOUnit }
                    console.log("uploadVariables: " + JSON.stringify(uploadVariables));
                    // TODO need to get Contacts variable?;
                    var ccsTosend = []
                    
                    cc.map((c) => {
                        let clanguages = []
                        c.languageSkill.map((item) => {
                            if (item.language !== "") {
                                clanguages.push({ language: item.language.split(' - ')[0], cefrLevel: item.cefrLevel, iscedFCode: item.iscedFCode, clarification: item.clarification });
                            }
                        });

                        let cEqfLevels = [];
                        c.eqfLevels.map((eqfLevel) => {
                            cEqfLevels.push({ eqfLevel: parseInt(eqfLevel.replace(/EQF /g, "")) });
                        });

                        let cIscedFCode = [];
                        c.iscedFCode.map((item) => {
                            if (item.code !== "") {
                                cIscedFCode.push({ iscedFCode: item.code, iscedClarification: item.clarification });
                            }
                        });

                        ccsTosend.push({
                            senderInstitution: c.senderInstitution,
                            receiverInstitution: c.receiverInstitution,
                            sendingOUnit: c.sendingOUnit,
                            receivingOUnit: c.receivingOUnit,
                            mobilitiesPerYear: parseInt(c.mobilitiesPerYear),
                            mobilityType: c.mobilityType || c.mobilityTypeSelected,
                            totalDays: parseFloat(c.totalDays) || 0,
                            totalMonths: parseFloat(c.totalMonths) || 0,
                            iscedFCodes: cIscedFCode,
                            eqfLevels: cEqfLevels,
                            languages: clanguages,
                            blended: parseInt(c.blended),
                            otherInformation: c.otherInformation
                        })
                    })

                    var toSend = {
                        'conditionsIn': ccsTosend,
                        'creatorIn': {
                            name: creatorInstitution,
                            erasmusCode: GlobVar.encryptWithKey(creatorErasmusCode).toString(),
                            country: creatorCountry,
                            city: creatorCity,
                            contact: {
                                name: creatorContactName,
                                email: creatorContactEmail,
                                phone: creatorContactPhone

                            },
                            website: {
                                general: creatorGeneralWebsite,
                                faculty: creatorFacultyWebsite,
                                course: creatorCourseWebsite
                            },
                            ounit: creatorOUnit,
                            factsheet: creatorFactsheetData
                        },
                        'partnerIn': {
                            name: partnerInstitution,
                            erasmusCode: partnerErasmusCode,
                            country: partnerCountry,
                            city: partnerCity,
                            contact: {
                                name: partnerContactName,
                                email: partnerContactEmail,
                                phone: partnerContactPhone
                            },
                            website: {
                                general: partnerGeneralWebsite,
                                faculty: partnerFacultyWebsite,
                                course: partnerCourseWebsite
                            },
                            ounit: partnerOUnit,
                            factsheet: partnerFactsheetData
                        },
                        'iiaCode': iiaCode,
                        'academicYearStartIn': academicYearStart,
                        'academicYearEndIn': academicYearEnd,
                        'currentSign': GlobVar.decryptWithKey(localStorage.getItem("Email")),
                        'typeOfCommunication': (this.state.ewpPartnerStatus === 2) ? "Dash" : "EWP"
                    }
                    console.log("Sending this to Backend: ", toSend)
                    return this.props["CreateIIAm"]({
                        variables: toSend
                    }).then(() => {
                        sessionStorage.removeItem("tcCheckboxCreate");

                        if (this.props.data.createIIAv2) {
                            console.log("Submitting IIA " + pdArray[0].iiaCode + "...\nResults: " + JSON.stringify(this.props.data.createIIAv2));
                            alert("Submitting IIA " + pdArray[0].iiaCode + "... " + JSON.stringify(this.props.CreateIIAm));
                        }
                    });
                });

                Promise.all(promiseSendIIA).then(() => {
                    this.sendNotificationFunction(pdArray);
                    alert("IIA " + pdArray[0].iiaCode + " submited.");
                    console.log("IIA " + pdArray[0].iiaCode + " submited.");
                });
            });
        }
    }
}
const mutation = gql`
mutation CreateIIAm($conditionsIn: [CooperationInput]!, $creatorIn: InstituteInput!, $partnerIn:InstituteInput!, $iiaCode: String!,  $academicYearStartIn: String!, $academicYearEndIn: String!, $currentSign: String!, $typeOfCommunication: String){
    createIIAv2(conditions: $conditionsIn, creator: $creatorIn, partner: $partnerIn, iiaCode: $iiaCode, academicYearStart: $academicYearStartIn, academicYearEnd: $academicYearEndIn, currentSign: $currentSign, typeOfCommunication: $typeOfCommunication) {
      iia_code_p1
    }
}`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const sendEWPIIANotification = gql`
    mutation sendEWPIIANotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!) {
        sendEWPIIANotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID) {
            Success
            Message
        }
    }
`;

export default compose(
    graphql(getInstitution),
    graphql(mutation, { name: "CreateIIAm" }),
    graphql(isIIAManagedByEWP, {name: "isIIAManagedByEWP"}),
    graphql(sendEWPIIANotification, {name: "sendEWPIIANotification"})
)(IIACreation);
