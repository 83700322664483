import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../GlobalVar';

class IntroBackground extends Component {

    render(){
		return (
			<div className="background_intro_wrapper">
				<div className="divide_50">
					<img alt="" className="photo" src="/photos/background.jpg" />
					<img alt="" className="main_logo" src="/icons/e-plus-cms.svg" />
					<p>Less paper. Less time wasted.</p>
				</div>
				<div className="divide_50 gradient_background">
					<img alt="" className="logo_top_right" src="/icons/OLALogo.svg" />
					<div className="centralImages">
						<img alt="" className="main_visual" src="/icons/E+dashboardlogo.svg" />
						<img alt="" className="logos" src="/icons/allLogosConsortium.svg" />
						<a href="mailto:dashboard@uni-foundation.eu">dashboard@uni-foundation.eu</a>
						<label className="space"><Link to={GlobalVar.generateLinkUrl(intl,  "Institution", "Registration")+"/faqs"}>FAQs</Link></label>
					</div>
				</div>
				{/*
				<div className="banner_new_conditions">
					<p>We made updates to our <Link to={GlobalVar.generateLinkUrl(intl,  "Institution", "Registration")+"/termsandconditions"}>Privacy Policy and Terms and Conditions</Link> that will go into effect on May 25, 2018. We encourage you to review the documentation and let us know if you have any questions.</p>
				</div>
				*/}
			</div>
		)
	}
}

export default IntroBackground;
