import React, { Component } from 'react';
import Select from 'react-select';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../../../queries/getUsefulInfo';
import Confirm from "./Confirm"

class UsefulInfoRow extends Component {
	constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      externalIDValue: props.usefulInfoLists.externalID,
      titleValue: props.usefulInfoLists.title,

	//this.accountSection = React.createRef();
  }
}
  //comment in remove function
  deleteUsefulInfo(event){
		//event.preventDefault();
		this.props.DeleteUsefulInfo({
			variables:{
				"usefulInfoID": this.props.usefulInfoLists.usefulInfoID,
				"externalID": this.props.usefulInfoLists.externalID
			},
    	refetchQueries: [{query}]
		})
	}
	
	
	//change values in edit
	/*
  changeValue(event, stateName){
    this.setState({
      [stateName]: event.target.value
    })
  }
  	changeSingleSelect(functionValue){
    	this.setState({ functionValue });
	}
	*/
	
	/*changeDefaultContactSelect(functionValue){
		this.setState({ "defaultContact": functionValue });
	}*/
	
	//edit account event
	/*
  editAccount(event){
  	event.preventDefault();
  	this.setState({
  		editMode: true
  	})
  }*/
  
  
  //no need for validation
	/*validate(){
		if(!this.state.phoneValue.match(/^(?=.*[0-9])[- +()0-9]+$/)){
				this.setState({
				error : true,
				errorTitle: "Wrong phone format"
			})
			return false;
		}
		if(this.state.defaultContact.value == "in" && document.getElementsByClassName('defaultContact in').length > 0)
		{
			this.setState({
				errorTitle: "There's already an incoming default contact defined",
				error : true
			})
			return false;
		}
		if(this.state.defaultContact.value == "out" && document.getElementsByClassName('defaultContact out').length > 0)
		{
			this.setState({
				errorTitle: "There's already an outoing default contact defined",
				error : true
			})
			return false;
		}
		this.setState({
			errorTitle: "",
			error : false
		})
    	return true;
	}*/
	
	//saving edit
	/*
  saveEditAccount(event){
		if(this.validate()){
	    this.props.UpdateAccount({
				variables:{
					"id": this.props.account.id,
					"Name": this.state.nameValue,
					"Email": this.state.emailValue,
					"FunctionID": this.state.functionValue.value,
					"Phone": this.state.phoneValue,
					"DefaultContact": this.state.defaultContact ? this.state.defaultContact.value : ""
				},
	    	refetchQueries: [{query}]
			})
			this.setState({
	  		editMode: false
	  	})
		}
  }*/
  
  //save button for edit
  /*
  renderSaveButton(){
    if(this.state.functionValue === ""  || this.state.emailValue === "" || this.state.nameValue === ""){
      return <fieldset disabled><button className="top_fix">Save</button> </fieldset>
    }
    else return	<button  onClick = {(event) => this.saveEditAccount(event)} className="top_fix">Save</button>
  }*/

	/*generateError(){
    if(this.state.error){
      return <p className = "error">{this.state.errorTitle}</p>
    }
  }*/

	render() {
	return(
		<tr key={this.props.usefulInfoLists.externalID} >
			<td>{this.props.usefulInfoLists.title}</td>
			<td>
		<Confirm title="Confirm" description="Are you sure you want to delete this? The change will be permanent?">
          {confirm => (
            <form onSubmit={confirm((event) => this.deleteUsefulInfo(event))}>
                <button>Remove</button>
            </form>
          )}
        </Confirm>
		</td>
		</tr>
	)
  }
}

const deleteInfoMutation = gql`
    mutation DeleteUsefulInfo($usefulInfoID: Int!, $externalID: String!){
			deleteUsefulInfo(usefulInfoID: $usefulInfoID, externalID: $externalID){
				success
				errorMsg
	    }
    }
`;
// const updateInfoMutation = gql`
//     mutation UpdateAccount($id: ID! , $title: String!){
// 	    updateAccount(id: $id , title: $title){
// 	      Name
// 	    }
//     }
// `
export default compose(
   graphql(deleteInfoMutation,{ name: 'DeleteUsefulInfo'}),
   // graphql(updateInfoMutation,{ name: 'UpdateAccount'})
)(UsefulInfoRow);
