import gql from 'graphql-tag';

const query = gql`
	query getStudentUploaded($Sending:Int, $CountryName:String, $InstitutionName:String, $SearchQuery: String, $StartMobility: String, $EndMobility: String, $TypeOfSigning: String, $SignedBefore: Int, $SignedDuring: Int, $AcademicYear: String, $StudyCycle: String, $SubjectArea: String){
		getStudentUploaded(UnKnownEmail:"${localStorage.getItem('unknownEmail')}",ErasmusCode: "${localStorage.getItem('ErasmusCode')}", Sending:$Sending, CountryName: $CountryName, InstitutionName: $InstitutionName, SearchQuery: $SearchQuery, StartMobility: $StartMobility, EndMobility: $EndMobility, TypeOfSigning: $TypeOfSigning, SignedBefore: $SignedBefore, SignedDuring: $SignedDuring, AcademicYear: $AcademicYear, StudyCycle: $StudyCycle, SubjectArea: $SubjectArea){
	    	count
	    	uploadedByInstitution
		}
	}
`
export { query };
