import React, { Component } from 'react';
import gql from 'graphql-tag';
import axios from 'axios';
import querystring from 'querystring';
import intl from 'react-intl-universal';
import { Button as ReactButton, Collapse } from 'reactstrap';
import { graphql, gql as gqlM } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import GlobalVar from '../../../GlobalVar';
import Label from '../common/Label';
import Button from '../common/Button';
import Validator from '../common/Validator';

import { query as getLanguages } from '../../../queries/getLanguages';

import '../../../style/css/customIIA.css';

class ShowIIAsToImport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reloadActions: false,
            actualPage: 1,              // index 0 is the header of the CSV and can be ignored
            conditionsDefaultOpen: true,
            creatorEWPStatus: -10,
            partnerEWPStatus: -10
        };

        this.data = props.location.state.data;
        this.creatorEC = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
        this.partnerEC = "";
        this.creatorData = null;
        this.partnerData = null;
        this.creatorOUCode = "";
        this.partnerOUCode = "";
        this.partnerDetails = [];
        this.conditions = new Array();

        // this.getCreatorECWithSpace(JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode, () => {
            this.getPartnerECWithSpace(this.data[this.state.actualPage][7], () => {
                this.getInstitutionData(this.creatorEC, (creatorData) => {
                    if (creatorData != null && creatorData != "") { 
                        this.creatorData = creatorData.getIIAmInstitution; 
                        this.creatorEC = creatorData.getIIAmInstitution.ErasmusCode;
                    }
                    else { this.creatorData = creatorData; }

                    this.getInstitutionData(this.partnerEC, (partnerData) => {
                        if (partnerData != null && partnerData != "") { 
                            this.partnerData = partnerData.getIIAmInstitution; 
                            this.partnerEC = partnerData.getIIAmInstitution.ErasmusCode;
                        }
                        else { this.partnerData = partnerData; }

                        this.refreshPage();
                    });
                });
            });
        // });

        this.toogleConditions = this.toogleConditions.bind(this);
        this.handleButtonsClick = this.handleButtonsClick.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    render() {
        console.log("ShowIIAsToImport - data length: " + this.data.length);
        if (this.props.allLanguages.loading || this.props.checkCreatorEC.loading || this.props.checkPartnerEC.loading || this.props.getIscedFCodesList.loading) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg" />Loading Inter-Institutional Agreements...</p>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div onClick={() => this.toPreviousPage(true)} className="black_background" />
                <div className="content popup student_popup">
                    {this.showTitle()}
                    {this.showData()}
                </div>
            </div>
        );
    }

    showTitle() {
        return (
            <div className="header" style={{ whiteSpace: 'nowrap', fontSize: '16pt', marginLeft: '10px' }}>
                <h2>Details of the Inter-Institutional Agreements to be imported</h2>
                <form onSubmit={() => this.toPreviousPage(false)} className="close_button_wrapper">
                    <button>
                        <img src="/icons/remove.svg" />
                    </button>
                </form>
            </div>
        );
    }

    showData() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (this.state.actualPage < this.data.length) {
            return (
                <div className="popup_scroller">
                    { this.showCreatorData(this.state.actualPage)}
                    { this.showPartnerData(this.state.actualPage)}
                    { this.showOtherData(this.state.actualPage)}
                    <hr />
                    <div className="sub_content">
                        <Label text="Terms of the agreement to be set for each agreement and approved by the institutions (Information only accessible to the relevant parties)." />
                        <h3>Mobility numbers per academic year</h3>
                        <p>&nbsp;</p>
                        <p style={{ fontWeight: "bold", width: "95%" }}>The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year.</p>
                    </div>
                    <div className="sub_content">
                        <ReactButton onClick={this.toogleConditions} style={subContentStyle}>Hide/Show Cooperations Conditions</ReactButton>
                    </div>
                    <div className="sub_content" style={{ width: "98%", overflowX: "hidden" }}>
                        <Collapse isOpen={this.state.conditionsDefaultOpen}>
                            {this.showConditionsData(this.state.actualPage)}
                        </Collapse>
                    </div>
                    {this.renderFinalInformation()}
                    {this.showButtons(this.state.actualPage)}
                </div>
            );
        }
    }

    showCreatorData(index) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <div className="sub_content" style={subContentStyle}>
                <h3>Creator data</h3>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Institution Name", (this.creatorData != null && this.creatorData !== "") ? this.creatorData.NameInLatinCharacterSet : "")}
                    {this.generateLabelValueRow("Erasmus Code", this.creatorEC)}
                    {this.generateLabelValueRow("Country", (this.creatorData != null && this.creatorData !== "") ? this.creatorData.CountryName : "")}
                    {this.generateLabelValueRow("City", (this.creatorData != null && this.creatorData !== "") ? this.creatorData.CityName : "")}
                    {this.generateLabelValueRow("OUnit", (this.data[index][0] != "") ? this.data[index][0] : "")}
                </ul>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Contact Name", this.data[index][1])}
                    {this.generateLabelValueRow("Contact Email", this.data[index][2])}
                    {this.generateLabelValueRow("Contact Phone", this.data[index][3])}
                    {this.generateLabelValueRow("General Website", this.data[index][4])}
                    {this.generateLabelValueRow("Faculty Website", this.data[index][5])}
                    {this.generateLabelValueRow("Course Website", this.data[index][6])}
                </ul>
            </div>
        );
    }

    showPartnerData(index) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <div className="sub_content" style={subContentStyle}>
                <h3>Partner data</h3>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Institution Name", (this.partnerData != null && this.partnerData !== "") ? this.partnerData.NameInLatinCharacterSet : "")}
                    {this.generateLabelValueRow("Erasmus Code", this.partnerEC)}
                    {this.generateLabelValueRow("Country", (this.partnerData != null && this.partnerData !== "") ? this.partnerData.CountryName : "")}
                    {this.generateLabelValueRow("City", (this.partnerData != null && this.partnerData !== "") ? this.partnerData.CityName : "")}
                    {this.generateLabelValueRow("OUnit", (this.data[index][8] != "") ? this.data[index][8] : "")}
                </ul>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Contact Name", this.data[index][9])}
                    {this.generateLabelValueRow("Contact Email", this.data[index][10])}
                    {this.generateLabelValueRow("Contact Phone", this.data[index][11])}
                    {this.generateLabelValueRow("General Website", this.data[index][12])}
                    {this.generateLabelValueRow("Faculty Website", this.data[index][13])}
                    {this.generateLabelValueRow("Course Website", this.data[index][14])}
                </ul>
            </div>
        );
    }

    showOtherData(index) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <div className="sub_content" style={subContentStyle}>
                <h3>Other data</h3>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Academic Year (start)", this.data[index][15])}
                </ul>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Academic Year (end)", this.data[index][16])}
                </ul>
            </div>
        );
    }

    showConditionsData(index) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (this.data.length < 2) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>No conditions</h3>
                </div>
            );
        }

        let conditionsCode = new Array();
        let emptyConditionCount = JSON.stringify(this.data[index]).split(",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\"").length - 1; // ignores empty conditions (13 empty fields)
        let actualLength = this.data[index].length - (emptyConditionCount * 13);
        for (let i = 17; i < actualLength; i += 13) {

            let iscedFCodeValues = null;
            if (this.data[index][i + 5] !== "") {
                let iscedFCodeArray = this.data[index][i + 5].includes(";") ? this.data[index][i + 5].split(";") : [this.data[index][i + 5]];
                iscedFCodeValues = new Array();
                iscedFCodeArray.map((value, idx) => {
                    if (idx % 2 === 0) {
                        this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                            if (data.code === value) {
                                iscedFCodeValues.push({ iscedFCode: data.code, iscedClarification: (iscedFCodeArray.length >= idx + 1) ? iscedFCodeArray[idx + 1] : "" });
                                return;
                            }
                        });
                    }
                });
            }

            let eqfValues = null;
            if (this.data[index][i + 8] !== "") {
                let eqfArray = this.data[index][i + 8].includes(";") ? this.data[index][i + 8].split(";") : [this.data[index][i + 8]];
                eqfValues = new Array();
                eqfArray.forEach((item) => {
                    eqfValues.push("EQF " + item);
                });
            }

            let languagesValues = null;
            if (this.data[index][i + 11] !== "") {
                let languagesArray = this.data[index][i + 11].includes(";") ? this.data[index][i + 11].split(";") : [this.data[index][i + 11]];
                languagesValues = new Array();
                let language = "";
                let cefr = "";
                let isced = "";
                let clarification = "";

                languagesArray.map((value, idx) => {
                    let pos = idx % 4;

                    if (pos === 0) {
                        language = "";
                        cefr = "";
                        this.props.allLanguages.allLanguages.map((data) => {
                            if (data.Code === value) {
                                language = data.Name + " - " + data.Code;
                                cefr = languagesArray[idx+1];
                                return;
                            }
                        });
                    }
                    else if (pos === 2) {
                        isced = "";
                        clarification = "";
                        this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                            if (data.code === value) {
                                isced = data.code;
                                clarification = languagesArray[idx+1];
                                return;
                            }
                        });
                    }
                    else if (pos === 3) {
                        languagesValues.push({ language: language, cefrLevel: cefr, iscedFCode: isced, clarification: clarification });
                    }
                });
            }

            console.log("this.data[index][i] === creatorEC", this.data[index][i] === this.creatorEC.replace(/\s+/g, ""));
            let senderEC = this.data[index][i] === this.data[index][7] ? this.partnerEC : (this.data[index][i] === this.creatorEC.replace(/\s+/g, "") ? this.creatorEC : "");
            let receiverEC = this.data[index][i + 1] === this.data[index][7] ? this.partnerEC : (this.data[index][i + 1] === this.creatorEC.replace(/\s+/g, "") ? this.creatorEC : "");
            let mobilityTimeCode = "";
            if (this.data[index][i + 6] === "Staff teacher" || this.data[index][i + 6] === "Staff training") { mobilityTimeCode = this.generateLabelValueRow("Total Days Per Year Per Mobility", this.data[index][i + 10]) }
            else if (this.data[index][i + 6] === "Student studies" || this.data[index][i + 6] === "Student traineeship") { mobilityTimeCode = this.generateLabelValueRow("Total Months Per Year Per Mobility", this.data[index][i + 7]); }

            let iscedFCodesCounter = 0;
            iscedFCodeValues.map((iscedFCode) => { if (iscedFCode.iscedFCode !== "") { iscedFCodesCounter++; } });
            let iscedFCodesTitle = (iscedFCodesCounter === 0) ? <h2>No subject area selected</h2> : <h2>Subject area</h2>;
            let languagesCounter = 0;
            if (languagesValues !== null) {
                languagesValues.map((language) => { if (language.language !== "") { languagesCounter++; } });
            }
            let languagesTitle = (languagesCounter === 0) ? <h2>No recommended language skills</h2>
                : (<React.Fragment><h2>Recommended language skills</h2><p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p></React.Fragment>);

            conditionsCode.push(
                <React.Fragment>
                    <div key={this.data[index][1] + "_partOne_" + i} className="sub_content" style={subContentStyle}>
                        <h3>Condition {Math.floor(i / 13)}/{Math.floor(actualLength / 13) - 1}</h3>
                        <ul className="regular_list">
                            {this.generateLabelValueRow("Sender Erasmus Code", senderEC)}
                            {this.generateLabelValueRow("Receiver Erasmus Code", receiverEC)}
                            {this.generateLabelValueRow("Sending Organizational Unit", this.data[index][i + 2])}
                            {this.generateLabelValueRow("Receiving Organizational Unit", this.data[index][i + 3])}
                            {this.generateLabelValueRow("Mobilities per Year", this.data[index][i + 4])}
                            {this.generateLabelValueRow("Mobility Type", this.data[index][i + 6])}
                            {mobilityTimeCode}
                            {this.generateLabelMultiValueRow("EQF Levels", eqfValues, "")}
                            {
                                this.data[index][i + 6].includes("Student") ? (
                                    <ul className="regular_list" style={{ width: "100%" }}>
                                        {this.generateLabelValueRow("Blended mobility", (this.data[index][i + 9] == 1) ? "Yes" : "No")}
                                    </ul>
                                ) : <div />
                            }
                        </ul>
                        <ul className="regular_list">
                            {iscedFCodesTitle}
                            {this.generateObjectRows("Subject Area", iscedFCodeValues)}
                        </ul>
                    </div>
                    <div key={this.data[index][1] + "_partTwo_" + i} className="sub_content" style={subContentStyle}>
                        <ul className="regular_list" style={{ width: "95%" }}>
                            {languagesTitle}
                            {this.generateObjectRows("Recommended language skills", languagesValues)}
                        </ul>
                    </div>
                    <div key={this.data[index][1] + "_partThree_" + i} className="sub_content" style={subContentStyle}>
                        <ul className="regular_list" style={{ width: "95%" }}>
                            {this.generateLabelValueRow("Other Information", this.data[index][i + 12])}
                        </ul>
                    </div>
                </React.Fragment>
            );
        }

        return conditionsCode;
    }

    renderFinalInformation() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Termination of the agreement" />
                </div>
                <div className="sub_content" style={subContentStyle}>
                    <p style={{ fontStyle: "italic", display: "block", width: "98%" }}>[It is up to the involved institutions to agree on the procedure for modifying or terminating the inter-institutional agreement. However, in the event of unilateral termination, a notice of at least one academic year should be given. This means that a unilateral decision to discontinue the exchanges notified to the other party by 1 September 20XX will only take effect as of 1 September 20XX+1. The termination clauses must include the following disclaimer: "Neither the European Commission nor the National Agencies can be held responsible in case of a conflict."]</p>
                </div>
                <div className="sub_content" style={subContentStyle}>
                    <p style={{ margin: "20px", fontSize: "20px", textAlign: "center", display: "block" }}>"Inter-Institutional Agreements are digitally signed"</p>
                </div>
            </React.Fragment>
        );
    }

    showButtons(index) {
        let iiaSubmited = JSON.parse(sessionStorage.getItem("iiaSubmited"));
        let submitHTMLCode = <h1 id="statusFinished" style={{ textSize: "20px" }}>Already submited</h1>;

        if (!iiaSubmited[this.state.actualPage - 1]) {
            submitHTMLCode =
                <Button
                    id="submitSingleIIA"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Submit this IIA"
                />
                ;
        }

        if (index > 1 && index < (this.data.length - 1)) {
            return (
                <React.Fragment>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        <Button
                            id="previousIIA"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Previous IIA"
                        />
                        <p> Page {this.state.actualPage}/{this.data.length - 1} </p>
                        <Button
                            id="nextIIA"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Next IIA"
                        />
                    </div>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        {submitHTMLCode}
                    </div>
                </React.Fragment>
            );
        }
        else if (index > 1) {
            return (
                <React.Fragment>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        <Button
                            id="previousIIA"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Previous IIA"
                        />
                        <p> Page {this.state.actualPage}/{this.data.length - 1} </p>
                    </div>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        {submitHTMLCode}
                    </div>
                </React.Fragment>
            );
        }
        else if (index === 1 && (this.data.length - 1) < 2) {
            return (
                <React.Fragment>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        {submitHTMLCode}
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        <p>Page {this.state.actualPage}/{this.data.length - 1} </p>
                        <Button
                            id="nextIIA"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Next IIA"
                        />
                    </div>
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        {submitHTMLCode}
                    </div>
                </React.Fragment>
            );
        }
    }

    getPartnersDetails(currentIIA) {
        let pd = [];

        pd.push({
            creatorInstitution: (this.creatorData != null && this.creatorData !== "") ? this.creatorData.NameInLatinCharacterSet : "",
            creatorErasmusCode: this.creatorEC,
            creatorCountry: (this.creatorData != null && this.creatorData !== "") ? this.creatorData.CountryName : "",
            creatorCity: (this.creatorData != null && this.creatorData !== "") ? this.creatorData.CityName : "",
            creatorOUnit: currentIIA[0],
            creatorContactName: currentIIA[1],
            creatorContactEmail: currentIIA[2],
            creatorContactPhone: currentIIA[3],
            creatorGeneralWebsite: currentIIA[4],
            creatorFacultyWebsite: currentIIA[5],
            creatorCourseWebsite: currentIIA[6],

            partnerInstitution: (this.partnerData != null && this.partnerData !== "") ? this.partnerData.NameInLatinCharacterSet : "",
            partnerErasmusCode: this.partnerEC,
            partnerCountry: (this.partnerData != null && this.partnerData !== "") ? this.partnerData.CountryName : "",
            partnerCity: (this.partnerData != null && this.partnerData !== "") ? this.partnerData.CityName : "",
            partnerOUnit: currentIIA[8],
            partnerContactName: currentIIA[9],
            partnerContactEmail: currentIIA[10],
            partnerContactPhone: currentIIA[11],
            partnerGeneralWebsite: currentIIA[12],
            partnerFacultyWebsite: currentIIA[13],
            partnerCourseWebsite: currentIIA[14],

            academicYearStart: currentIIA[15],
            academicYearEnd: currentIIA[16],

            creatorSigningDate: this.setDate(),
            iiaCode: this.setIIACode(this.creatorEC)
        });

        return pd;
    }

    getConditions(currentIIA) {
        let iiaConditions = new Array();
        let emptyConditionCount = JSON.stringify(currentIIA).split(",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\"").length - 1; // ignores empty conditions (13 empty fields)
        let actualLength = currentIIA.length - (emptyConditionCount * 13);

        for (let i = 17; i < actualLength; i += 13) {

            let iscedFCodeValues = null;
            if (currentIIA[i + 5] !== "") {
                let iscedFCodeArray = currentIIA[i + 5].includes(";") ? currentIIA[i + 5].split(";") : [currentIIA[i + 5]];
                iscedFCodeValues = new Array();

                iscedFCodeArray.map((value, index) => {
                    if (index % 2 === 0) {
                        this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                            if (data.code === value) {
                                iscedFCodeValues.push({ iscedFCode: data.code, iscedClarification: (iscedFCodeArray.length >= index + 1) ? iscedFCodeArray[index + 1] : "" });
                                return;
                            }
                        });
                    }
                });
            }

            let eqfValues = null;
            if (currentIIA[i + 8] !== "") {
                let eqfArray = currentIIA[i + 8].includes(";") ? currentIIA[i + 8].split(";") : [currentIIA[i + 8]];
                eqfValues = new Array();
                eqfArray.forEach((item) => {
                    eqfValues.push("EQF " + item);
                });
            }

            let languagesValues = null;
            if (currentIIA[i + 11] !== "") {
                let languagesArray = currentIIA[i + 11].includes(";") ? currentIIA[i + 11].split(";") : [currentIIA[i + 11]];
                languagesValues = new Array();

                let language = "";
                let cefr = "";
                let isced = "";
                let clarification = "";
                languagesArray.map((value, index) => {
                    let pos = index % 4;

                    if (pos === 0) {
                        language = "";
                        cefr = "";
                        this.props.allLanguages.allLanguages.map((data) => {
                            if (data.Code === value) {
                                language = data.Name + " - " + data.Code;
                                cefr = languagesArray[index+1];
                                return;
                            }
                        });
                    }
                    else if (pos === 2) {
                        isced = "";
                        clarification = "";
                        this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                            if (data.code === value) {
                                isced = data.code;
                                clarification = languagesArray[index+1];
                                return;
                            }
                        });
                    }
                    else if (pos === 3) {
                        languagesValues.push({ language: language, cefrLevel: cefr, iscedFCode: isced, clarification: clarification });
                    }
                });
            }
            console.log("currentIIA[i] === creatorEC", currentIIA[i] === this.creatorEC.replace(/\s+/g, ""));
            let senderEC = currentIIA[i] === currentIIA[7] ? this.partnerEC : (currentIIA[i] === this.creatorEC.replace(/\s+/g, "") ? this.creatorEC : "");
            let receiverEC = currentIIA[i + 1] === currentIIA[7] ? this.partnerEC : (currentIIA[i + 1] === this.creatorEC.replace(/\s+/g, "") ? this.creatorEC : "");
            iiaConditions.push({
                senderInstitution: senderEC,
                receiverInstitution: receiverEC,
                sendingOUnit: currentIIA[i + 2],
                receivingOUnit: currentIIA[i + 3],
                mobilitiesPerYear: currentIIA[i + 4],
                iscedFCodes: iscedFCodeValues,
                languages: languagesValues,
                mobilityType: currentIIA[i + 6],
                totalMonths: currentIIA[i + 7],
                totalDays: currentIIA[i + 10],
                eqfLevels: eqfValues,
                blended: currentIIA[i + 9] != "" ? currentIIA[i + 9] : 0,
                otherInformation: currentIIA[i + 12]
            });
        }

        return iiaConditions;
    }

    setDate() {
        let year = new Date().getUTCFullYear();
        let month = new Date().getUTCMonth() + 1;
        let day = new Date().getUTCDate();
        let hours = new Date().getUTCHours();
        let minutes = new Date().getUTCMinutes();
        let seconds = new Date().getUTCSeconds();

        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }

        let fullUTCDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return fullUTCDate;
    }

    previousIIA() {
        // this.getCreatorECWithSpace(this.data[this.state.actualPage - 1][0], () => {
            this.getPartnerECWithSpace(this.data[this.state.actualPage - 1][7], () => {
                this.getInstitutionData(this.creatorEC, (creatorData) => {
                    if (creatorData != null && creatorData != "") { 
                        this.creatorData = creatorData.getIIAmInstitution; 
                        this.creatorEC = creatorData.getIIAmInstitution.ErasmusCode;
                    }
                    else { this.creatorData = creatorData; }

                    this.getInstitutionData(this.partnerEC, (partnerData) => {
                        if (partnerData != null && partnerData != "") { 
                            this.partnerData = partnerData.getIIAmInstitution; 
                            this.partnerEC = partnerData.getIIAmInstitution.ErasmusCode;
                        }
                        else { this.partnerData = partnerData; }

                        this.setState({ actualPage: this.state.actualPage - 1 });
                        this.refreshPage();
                    });
                });
            });
        // });
    }

    nextIIA() {
        // this.getCreatorECWithSpace(this.data[this.state.actualPage + 1][0], () => {
            this.getPartnerECWithSpace(this.data[this.state.actualPage + 1][7], () => {
                this.getInstitutionData(this.creatorEC, (creatorData) => {
                    if (creatorData != null && creatorData != "") { 
                        this.creatorData = creatorData.getIIAmInstitution; 
                        this.creatorEC = creatorData.getIIAmInstitution.ErasmusCode;
                    }
                    else { this.creatorData = creatorData; }

                    this.getInstitutionData(this.partnerEC, (partnerData) => {
                        if (partnerData != null && partnerData != "") { 
                            this.partnerData = partnerData.getIIAmInstitution; 
                            this.partnerEC = partnerData.getIIAmInstitution.ErasmusCode;
                        }
                        else { this.partnerData = partnerData; }

                        this.setState({ actualPage: this.state.actualPage + 1 });
                        this.refreshPage();
                    });
                });
            });
        // });
    }

    submitSingleIIA() {
        let currentIIA = this.data[this.state.actualPage];
        let iiaSubmited = JSON.parse(sessionStorage.getItem("iiaSubmited"));
        let creatorOUList = [];
        let partnerOUList = [];

        if (window.confirm("Are you sure do you want to create this agreement? Both partners will receive a confirmation email notifying all conditions were submited successfully.")) {
            let pd = this.getPartnersDetails(currentIIA);
            let cc = this.getConditions(currentIIA);
            let missingFields = this.validateFields(pd, cc);

            this.getCreatorOUList(this.creatorEC, (ouList) => {
                creatorOUList = ouList;

                this.getPartnerOUList(this.partnerEC, (ouList) => {
                    partnerOUList = ouList;

                    creatorOUList.map(ounit => {
                        if (ounit.name === this.data[this.state.actualPage][0]) { this.creatorOUCode = ounit.ounit_id; return; }
                    });

                    partnerOUList.map(ounit => {
                        if (ounit.name === this.data[this.state.actualPage][8]) { this.partnerOUCode = ounit.ounit_id; return; }
                    });

                    if (missingFields.length > 0) {
                        alert("It is not possible to submit this agreement, as there are missing required fields or fields with invalid values:\n- " + missingFields.join("\n- "));
                    } else {
                        let ccsTosend = [];
                        let promiseConditions = cc.map((c) => {
                            let sendingOUCode = "";
                            let receivingOUCode = "";

                            if (c.senderInstitution === this.creatorEC) {
                                creatorOUList.map(ounit => {
                                    if (ounit.name === c.sendingOUnit) { sendingOUCode = ounit.ounit_id; return; }
                                });

                                partnerOUList.map(ounit => {
                                    if (ounit.name === c.receivingOUnit) { receivingOUCode = ounit.ounit_id; return; }
                                });
                            }
                            else {
                                creatorOUList.map(ounit => {
                                    if (ounit.name === c.receivingOUnit) { receivingOUCode = ounit.ounit_id; return; }
                                });

                                partnerOUList.map(ounit => {
                                    if (ounit.name === c.sendingOUnit) { sendingOUCode = ounit.ounit_id; return; }
                                });
                            }

                            let cEqfLevels = [];
                            if (c.eqfLevels != null) {
                                c.eqfLevels.map((eqfLevel) => {
                                    cEqfLevels.push({ eqfLevel: parseInt(eqfLevel.replace(/EQF /g, "")) });
                                });
                            }

                            ccsTosend.push({
                                senderInstitution: c.senderInstitution,
                                receiverInstitution: c.receiverInstitution,
                                sendingOUnit: sendingOUCode,
                                receivingOUnit: receivingOUCode,
                                mobilitiesPerYear: parseInt(c.mobilitiesPerYear) || 0,
                                mobilityType: c.mobilityType,
                                totalDays: (c.mobilityType === "Staff teacher" || c.mobilityType === "Staff training") ? parseFloat(c.totalDays) : 0,
                                totalMonths: (c.mobilityType === "Student studies" || c.mobilityType === "Student traineeship") ? parseFloat(c.totalMonths) : 0,
                                iscedFCodes: c.iscedFCodes,
                                eqfLevels: cEqfLevels,
                                languages: c.languages,
                                blended: (c.blended != "") ? parseInt(c.blended) : 0,
                                otherInformation: c.otherInformation
                            })
                        });

                        // let tOfComm = "";
                        // if (this.state.creatorEWPStatus === 1 && this.state.partnerEWPStatus === 1) { 
                        //     tOfComm = "EWP"; 
                        // } 
                        // else if ((this.state.creatorEWPStatus === 2 && this.state.partnerEWPStatus === 1 ) || (this.state.creatorEWPStatus === 1 && this.state.partnerEWPStatus === 2)
                        //         || (this.state.creatorEWPStatus === 2 && this.state.partnerEWPStatus === 2)) { 
                        //     tOfComm = "Dash"; 
                        // }

                        let toSend = {
                            'conditionsIn': ccsTosend,
                            'creatorIn': {
                                name: pd[0].creatorInstitution,
                                erasmusCode: GlobalVar.encryptWithKey(pd[0].creatorErasmusCode).toString(),
                                country: pd[0].creatorCountry,
                                city: pd[0].creatorCity,
                                contact: {
                                    name: pd[0].creatorContactName,
                                    email: pd[0].creatorContactEmail,
                                    phone: pd[0].creatorContactPhone

                                },
                                website: {
                                    general: pd[0].creatorGeneralWebsite,
                                    faculty: pd[0].creatorFacultyWebsite,
                                    course: pd[0].creatorCourseWebsite
                                },
                                ounit: this.creatorOUCode,
                                factsheet: ""
                            },
                            'partnerIn': {
                                name: pd[0].partnerInstitution,
                                erasmusCode: pd[0].partnerErasmusCode,
                                country: pd[0].partnerCountry,
                                city: pd[0].partnerCity,
                                contact: {
                                    name: pd[0].partnerContactName,
                                    email: pd[0].partnerContactEmail,
                                    phone: pd[0].partnerContactPhone
                                },
                                website: {
                                    general: pd[0].partnerGeneralWebsite,
                                    faculty: pd[0].partnerFacultyWebsite,
                                    course: pd[0].partnerCourseWebsite
                                },
                                ounit: this.partnerOUCode,
                                factsheet: ""
                            },
                            'iiaCode': this.setIIACode(pd[0].creatorErasmusCode),
                            'academicYearStartIn': pd[0].academicYearStart,
                            'academicYearEndIn': pd[0].academicYearEnd,
                            'currentSign': GlobalVar.decryptWithKey(localStorage.getItem("Email")),
                            'typeOfCommunication': (this.state.partnerEWPStatus === 2) ? "Dash" : "EWP"
                        }

                        console.log("Sending this to Backend: ", JSON.stringify(toSend));
                        let promiseSend = this.props["CreateIIAm"]({
                            variables: toSend
                        }).then((x) => {
                            if (x.data.CreateIIAm) {
                                console.log("Submitting IIA data...\nResults: " + JSON.stringify(x.data.CreateIIAm));
                                alert("Submitting IIA data... " + JSON.stringify(this.props.CreateIIAm));
                            }
                        });

                        Promise.all([pd, cc, ccsTosend, promiseConditions, toSend, promiseSend]).then(() => {
                            console.log("pd to send notification: " + JSON.stringify(pd));
                            this.sendNotificationFunction(pd[0]);
                            iiaSubmited[this.state.actualPage - 1] = true;
                            sessionStorage.setItem("iiaSubmited", JSON.stringify(iiaSubmited));
                            this.refreshPage();
                        });
                    }
                });
            });
        }
    }

    validateFields(pd, cc) {
        let validator = null;
        let errorMessage = "";
        let missingFields = [];

        if (pd[0].creatorErasmusCode === "") { missingFields.push("Creator EC - This field may not be empty."); }
        else {
            validator = new Validator(pd[0].creatorErasmusCode);
            errorMessage = validator.validateErasmusCode(false);
            if (errorMessage !== "") { missingFields.push("Creator EC - " + errorMessage); }
        }
        if (pd[0].creatorContactName !== "") {
            validator = new Validator(pd[0].creatorContactName);
            errorMessage = validator.validateRegularNamesOfRequiredFields();
            if (errorMessage !== "") { missingFields.push("Creator Contact Name - " + errorMessage); }
        }
        if (pd[0].creatorContactEmail !== "") {
            validator = new Validator(pd[0].creatorContactEmail);
            errorMessage = validator.validateEmailFormat(false);
            if (errorMessage !== "") { missingFields.push("Creator Contact Email - " + errorMessage); }
        }
        if (pd[0].creatorContactPhone !== "") {
            validator = new Validator(pd[0].creatorContactPhone);
            errorMessage = validator.validatePhoneFaxNumber(true);
            if (errorMessage !== "") { missingFields.push("Creator Contact Phone - " + errorMessage); }
        }
        if (pd[0].creatorGeneralWebsite !== "") {
            validator = new Validator(pd[0].creatorGeneralWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator General Website - " + errorMessage); }
        }
        if (pd[0].creatorFacultyWebsite !== "") {
            validator = new Validator(pd[0].creatorFacultyWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator Faculty Website - " + errorMessage); }
        }
        if (pd[0].creatorCourseWebsite !== "") {
            validator = new Validator(pd[0].creatorCourseWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Creator Course Catalogue Website - " + errorMessage); }
        }
        if (pd[0].partnerErasmusCode === "") { missingFields.push("Partner EC - This field may not be empty/invalid."); }
        else {
            validator = new Validator(pd[0].partnerErasmusCode);
            errorMessage = validator.validateErasmusCode(false);
            if (errorMessage !== "") { missingFields.push("Partner EC - " + errorMessage); }
        }
        if (pd[0].partnerContactName !== "") {
            validator = new Validator(pd[0].partnerContactName);
            errorMessage = validator.validateRegularNamesOfRequiredFields();
            if (errorMessage !== "") { missingFields.push("Partner Contact Name - " + errorMessage); }
        }
        if (pd[0].partnerContactEmail !== "") {
            validator = new Validator(pd[0].partnerContactEmail);
            errorMessage = validator.validateEmailFormat(false);
            if (errorMessage !== "") { missingFields.push("Partner Contact Email - " + errorMessage); }
        }
        if (pd[0].partnerContactPhone !== "") {
            validator = new Validator(pd[0].partnerContactPhone);
            errorMessage = validator.validatePhoneFaxNumber(true);
            if (errorMessage !== "") { missingFields.push("Partner Contact Phone - " + errorMessage); }
        }
        if (pd[0].partnerGeneralWebsite !== "") {
            validator = new Validator(pd[0].partnerGeneralWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner General Website - " + errorMessage); }
        }
        if (pd[0].partnerFacultyWebsite !== "") {
            validator = new Validator(pd[0].partnerFacultyWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner Faculty Website - " + errorMessage); }
        }
        if (pd[0].partnerCourseWebsite !== "") {
            validator = new Validator(pd[0].partnerCourseWebsite);
            errorMessage = validator.validateUrlFormat(true);
            if (errorMessage !== "") { missingFields.push("Partner Course Catalogue Website - " + errorMessage); }
        }
        let academicYearStartLeftYear = parseInt(pd[0].academicYearStart.substring(0, pd[0].academicYearStart.indexOf('/')));
        let academicYearStartRightYear = parseInt(pd[0].academicYearStart.substring(pd[0].academicYearStart.indexOf('/') + 1));
        let academicYearEndLeftYear = parseInt(pd[0].academicYearEnd.substring(0, pd[0].academicYearEnd.indexOf('/')));
        let academicYearEndRightYear = parseInt(pd[0].academicYearEnd.substring(pd[0].academicYearEnd.indexOf('/') + 1));
        if (pd[0].academicYearStart === "") { missingFields.push("Start Academic Year - This field may not be empty."); }
        if (academicYearStartLeftYear + 1 !== academicYearStartRightYear) {
            missingFields.push("Start Academic Year - The year on the left must be less one year than the year on the right.");
        }
        if (pd[0].academicYearEnd === "") { missingFields.push("End Academic Year - This field may not be empty."); }
        if (academicYearEndLeftYear + 1 !== academicYearEndRightYear) {
            missingFields.push("End Academic Year - The year on the left must be less one year than the year on the right.");
        }
        if (academicYearStartLeftYear > academicYearEndLeftYear) { missingFields.push("Academic Year - The Start Academic Year may not be greater than End Academic Year."); }

        cc.map((c, index) => {
            let condNum = index + 1;   // Number of the condition
            let columnIndex = index * 13 + 18;
            if (c.senderInstitution === "") { missingFields.push("Sender EC (condition " + condNum + ", column " + columnIndex + ") - This field may not be empty."); }
            if (c.receiverInstitution === "") { missingFields.push("Receiver EC (condition " + condNum + ", column " + parseInt(columnIndex + 1) + ") - This field may not be empty."); }
            if (c.mobilitiesPerYear === "") { missingFields.push("Mobilities Per Year (condition " + condNum + ", column " + parseInt(columnIndex + 4) + ") - This field may not be empty."); }
            
            c.iscedFCodes.map((value, idx) => {
                if (value.iscedFCode === "" && value.iscedClarification !== "") {
                    missingFields.push("Subject Area (condition " + condNum + ", column " + parseInt(columnIndex + 5) + ") - The pair " + parseInt((idx / 2) + 1) + " cannot have a clarification without a code.");
                }
            });
            if (c.mobilityType === "Student studies" || c.mobilityType === "Student traineeship") {
                if (c.totalMonths == 0) { missingFields.push("Total Months (condition " + condNum + ", column " + parseInt(columnIndex + 7) + ") - This field may not be empty."); }
                if (c.blended != 1 && c.blended != 0) { missingFields.push("Blended (condition " + condNum + ", column " + parseInt(columnIndex + 9) + ") - This field must be filled with 0 (not blended) or 1 (blended).") }
            }
            else if ((c.mobilityType === "Staff teacher" || c.mobilityType === "Staff training") && c.totalDays == 0) { missingFields.push("Total Days (condition " + condNum + ", column " + parseInt(columnIndex + 10) + ") - This field may not be empty."); }
            else if (c.mobilityType === "" || (c.mobilityType !== "Student studies" && c.mobilityType !== "Student traineeship" && c.mobilityType !== "Staff teacher" && c.mobilityType !== "Staff training")) {
                missingFields.push("Mobility Type (condition " + condNum + ", column " + parseInt(columnIndex + 6) + ") - This field must be filled with Student studies/Student traineeship/Staff teacher/Staff training.");
            }
            if (c.languages !== null) {
                c.languages.map((lang, idx) => {
                    if (lang.cefrLevel !== "A1" && lang.cefrLevel !== "A2" && lang.cefrLevel !== "B1" && lang.cefrLevel !== "B2" && lang.cefrLevel !== "C1" && lang.cefrLevel !== "C2" && lang.cefrLevel !== "") {
                        missingFields.push("Languages (condition " + condNum + ", column " + parseInt(columnIndex + 11) + ") - The language has a invalid CEFR level. The only possible values are A1, A2, B1, B2, C1 and C2.");
                    }
                    if (lang.iscedFCode === "" && lang.clarification !== "") {
                        missingFields.push("Subject Area of languages skills (condition " + condNum + ", column " + parseInt(columnIndex + 11) + ") - The group " + parseInt((idx / 4) + 1) + " cannot have a clarification without a code.");
                    }
                });
            }
        });

        return missingFields;
    }

    sendNotificationFunction(creatingIIA) {

        console.log("Importing IIA")
        console.log(creatingIIA)

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": GlobalVar.encryptWithKey(creatingIIA.creatorErasmusCode).toString(),
            },
        }).then((creatorSuccess) => {
            this.props.isIIAManagedByEWP({
                variables: {
                    "ErasmusCode": creatingIIA.partnerErasmusCode,
                },
            }).then((partnerSuccess) => {

                if (creatorSuccess.data.error) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the parter will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 1) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // SHOULD NOT ENTER HERE
                        // If one of the partners is managed by an EWP node, the other one must be managed by Dashboard to edit it
                        // So in this case, the creator is already managed by an EWP node
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("The creator will receive a notification in their system and the partner will receive an email notification regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        alert("Only the creator will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 2) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will receive an email notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 1);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Both partners will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 2);
                    }
                    else {
                        alert("Only the creator will receive an email notification regarding this agreement.");
                        alert("IIA data submited.");
                    }
                }
                else {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                    }
                }
            });
        });
    }

    sendMailFunction(data, sendEmailTo) {
        const title = "New agreement created";
        const text = "A new agreement was created where you are one of the partners. You can see it in the Dashboard ('IIA List')."
        const action_url = GlobalVar.getServerLink();

        // send to creator only
        if (sendEmailTo == 1) {
            axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                receiver: data.creatorContactEmail,
                institution_name: data.creatorInstitution,
                institution_ecode: data.creatorErasmusCode,
                newiia_id: data.iiaCode,
                action_url: action_url
            }));
        }

        // send to both partners
        if (sendEmailTo == 2) {
            axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                receiver: data.creatorContactEmail,
                institution_name: data.creatorInstitution,
                institution_ecode: data.creatorErasmusCode,
                newiia_id: data.iiaCode,
                action_url: action_url
            }));
            axios.post(`${GlobalVar.getServerLink()}/sendMailNewIIA`, querystring.stringify({
                receiver: data.partnerContactEmail,
                institution_name: data.creatorInstitution,
                institution_ecode: data.creatorErasmusCode,
                newiia_id: data.iiaCode,
                action_url: action_url
            }));
        }

        // send to partner only
        if (sendEmailTo == 3) {
            axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                receiver: data.partnerContactEmail,
                institution_name: data.creatorInstitution,
                institution_ecode: data.creatorErasmusCode,
                newiia_id: data.iiaCode,
                action_url: action_url
            }));
        }
    }

    sendEWPNotification(creatingIIA) {
        this.props.sendEWPIIANotification({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(creatingIIA.creatorErasmusCode).toString(),
                "PartnerErasmusCode": creatingIIA.partnerErasmusCode,
                "IIAID": creatingIIA.iiaCode
            },
        }).then((x) => {
            if (x.data.error) {
                alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
            } else {
                if (x.data.sendEWPIIANotification.Success) {
                    alert("Notification sent successfully to the partner.");
                } else {
                    alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                }
            }
        });
    }

    generateObjectRows(label, object) {
        if (object === undefined || object === null || object.length === 0) {
            return;
        }

        if (label === "Subject Area") {
            return object.map((item) => {
                if (item.iscedFCode === "") {
                    return;
                }

                return (
                    <React.Fragment>
                        <li>
                            <span className="label">ISCED-F Code</span>
                            <span className="value">{this.formatValue(item.iscedFCode)}</span>
                            <span className="label">ISCED-F Clarification</span>
                            <span className="value">{this.formatValue(item.iscedClarification)}</span>
                            <hr />
                        </li>
                    </React.Fragment>
                );
            });
        }
        else if (label === "Recommended language skills") {
            return object.map((item) => {
                if (item.language === "") {
                    return;
                }

                return (
                    <React.Fragment>
                        <li>
                            <span className="label">Language</span>
                            <span className="value">{this.formatValue(item.language)}</span>
                            <span className="label">CEFR Level</span>
                            <span className="value">{this.formatValue(item.cefrLevel)}</span>
                            <span className="label">ISCED-F Code</span>
                            <span className="value">{this.formatValue(item.iscedFCode)}</span>
                            <span className="label">ISCED-F Clarification</span>
                            <span className="value">{this.formatValue(item.clarification)}</span>
                            <hr />
                        </li>
                    </React.Fragment>
                );
            });
        }
    }

    generateLabelValueRow(label, value) {
        if (value === undefined || value === null || value === "") {
            return;
        }
        if (label === "Total Months Per Year Per Mobility" || label === "Total Days Per Year Per Mobility") {
            if (value == 0) {
                return;
            }
        }
        return (
            <li>
                <span className="label">{label}</span>
                <span className="value" style={{whiteSpace: "pre-wrap"}}>{this.formatValue(value)}</span>
            </li>
        );
    }

    generateLabelMultiValueRow(label, values, tag) {
        if (values == null || values.length === 0) {
            return;
        }

        return (
            <li>
                <span className="label">{label}</span>
                <ul className="value">
                    {values && values.length && values.map((item, key) => {
                        if (tag === "") {
                            return (
                                <li key={key}>
                                    <span>{this.formatValue(item)}</span>
                                </li>
                            );
                        }
                        return (
                            <li key={key}>
                                <span>{this.formatValue(item[tag])}</span>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    generateLanguagesRows(label, values) {
        if (values == null || values.length === 0) {
            return;
        }
        if (values && values.length) {
            return (
                <li>
                    <p style={{ width: "100%", display: "block", fontWeight: "bold" }}>{label}</p>
                    <p style={{ width: "95%", display: "block" }}>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                    <ul className="value">
                        {values && values.length && values.map((item, key) => {
                            return (
                                <li key={key}>
                                    <span>{this.formatValue(item.language)} - {this.formatValue(item.cefrLevel)}</span>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
        }
        return (
            <li>
                <p style={{ width: "100%", display: "block", fontWeight: "bold" }}>{label}</p>
                <p style={{ width: "95%", display: "block" }}>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                {this.formatValue("")}
            </li>
        )
    }

    setIIACode(erasmusCode) {
        let currentDate = Date.now() + "";
        return erasmusCode.replace(/[\s-]/g, "") + "-" + currentDate.replace(/[\s-]/g, "");
    }

    formatValue(value) {
        if (value === undefined || value === null || value === "") {
            return "--------------";
        }
        return value;
    }

    handleButtonsClick(buttonId) {
        if (buttonId === "previousIIA") {
            this.previousIIA();
        }
        if (buttonId === "nextIIA") {
            this.nextIIA();
        }
        if (buttonId === "submitSingleIIA") {
            this.submitSingleIIA();
        }
    }

    toogleConditions() {
        this.setState({
            conditionsDefaultOpen: !this.state.conditionsDefaultOpen
        });
    }

    // getCreatorECWithSpace(erasmusCode, callback) {
    //     this.props.checkCreatorEC({
    //         variables: {
    //             "ErasmusCode": GlobalVar.encryptWithKey(erasmusCode).toString(),
    //         },
    //     })
    //         .then((x) => {
    //             if (x.data == null) {
    //                 console.log("An error has occurred while checking Erasmus Code.");
    //                 this.creatorEC = "";
    //             } else {
    //                 console.log("The Erasmus Code with space successfully obtained: " + JSON.stringify(x));
    //                 this.creatorEC = x.data.checkCreatorEC.ErasmusCode;
    //             }
    //             return callback();
    //         })
    //         .catch((x) => {
    //             console.log("An error has occurred while checking Erasmus Code.");
    //             this.creatorEC = "";
    //             return callback();
    //         });
    // }

    getPartnerECWithSpace(erasmusCode, callback) {
        this.props.checkPartnerEC({
            variables: {
                "ErasmusCode": erasmusCode,
            },
        })
            .then((x) => {
                if (x.data == null) {
                    console.log("An error has occurred while checking Erasmus Code.");
                    this.partnerEC = "";
                } else {
                    console.log("The Erasmus Code with space successfully obtained: " + JSON.stringify(x));
                    this.partnerEC = x.data.checkPartnerEC.ErasmusCode;
                }
                return callback();
            })
            .catch((x) => {
                console.log("An error has occurred while checking Erasmus Code.");
                this.partnerEC = "";
                return callback();
            });
    }

    getInstitutionData(erasmusCode, callback) {
        if (erasmusCode !== "") {
            erasmusCode = (erasmusCode.replace(/\s+/g, "") === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode) ? GlobalVar.encryptWithKey(erasmusCode).toString() : erasmusCode;
            
            return this.props.getInstitutionData({
                variables: { "erasmusCode": erasmusCode }
            })
                .then((res) => {
                    if (res.data == null) {
                        console.log("An error has occurred while getting Institution data.");
                        alert("An error has occurred while getting Institution data.");
                    } else {
                        console.log("The Institution data was obtained successfully: " + JSON.stringify(res));
                    }
                    return callback(res.data);
                })
                .catch((error) => {
                    return callback("");
                });
        }
        return callback(null);
    }

    getCreatorOUList(erasmusCode, callback) {
        let creatorOUList = [];

        if (erasmusCode != "") {
            this.checkPartnerInEWP(erasmusCode, (partnerEWPStatus) => {
                this.setState({ creatorEWPStatus: partnerEWPStatus });
                if (partnerEWPStatus == 2 || partnerEWPStatus == -1) {
                    this.props.listOU({
                        variables: {
                            "ErasmusCodeIn": GlobalVar.encryptWithKey(erasmusCode).toString()
                        }
                    }).then((x) => {
                        if (x.data.error) {
                            console.log("Error getting Organizational Units");
                        } else {
                            if (x.data.listOU) {
                                console.log("Successfully retrieved Organizational Units");
                                creatorOUList = x.data.listOU;
                            } else {
                                console.log("Error getting Organizational Units");
                            }
                        }
                        return callback(creatorOUList);
                    });
                } else {
                    creatorOUList = [];
                    return callback(creatorOUList);
                }

            });
        } else {
            creatorOUList = [];
            return callback(creatorOUList);
        }
    }

    getPartnerOUList(erasmusCode, callback) {
        let partnerOUList = [];

        if (erasmusCode != "") {
            this.checkPartnerInEWP(erasmusCode, (partnerEWPStatus) => {
                this.setState({ partnerEWPStatus: partnerEWPStatus });
                if (partnerEWPStatus == 2 || partnerEWPStatus == -1) {
                    this.props.listOU({
                        variables: {
                            "ErasmusCodeIn": erasmusCode
                        }
                    }).then((x) => {
                        if (x.data.error) {
                            console.log("Error getting Organizational Units");
                        } else {
                            if (x.data.listOU) {
                                console.log("Successfully retrieved Organizational Units");
                                partnerOUList = x.data.listOU;
                            } else {
                                console.log("Error getting Organizational Units");
                            }
                        }
                        return callback(partnerOUList);
                    });
                } else {
                    partnerOUList = [];
                    return callback(partnerOUList);
                }
            });
        } else {
            partnerOUList = [];
            return callback(partnerOUList);
        }
    }

    checkPartnerInEWP(erasmusCode, callback) {
        let partnerEWPStatus = 0;

        erasmusCode = (erasmusCode.replace(/\s+/g, "") === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode) ? GlobalVar.encryptWithKey(erasmusCode).toString() : erasmusCode;
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": erasmusCode
            },
        }).then((x) => {
            if (x.data.error) {
                partnerEWPStatus = 0;
            } else {
                console.log("(checkPartnerInEWP) Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        partnerEWPStatus = 1;
                        break;
                    case 2:
                        //By Dashboard
                        partnerEWPStatus = 2;
                        break;
                    case -1:
                        //Not in EWP
                        partnerEWPStatus = -1;
                        break;
                    default:
                        //Some error
                        partnerEWPStatus = 0;
                        break;
                }
            }
            return callback(partnerEWPStatus);
        });
    }

    toPreviousPage() {
        this.props.history.push(GlobalVar.generateLinkUrl(intl, "IIAm", "Import IIA"));
    }

    refreshPage() {
        this.setState({
            reloadActions: !this.state.reloadActions
        });
    }
}

const mutation = gql`
    mutation CreateIIAm($conditionsIn: [CooperationInput]!, $creatorIn: InstituteInput!, $partnerIn:InstituteInput!, $iiaCode: String!, $academicYearStartIn: String!, $academicYearEndIn: String!, $currentSign: String!, $typeOfCommunication: String){
        createIIAv2(conditions: $conditionsIn, creator: $creatorIn, partner: $partnerIn, iiaCode: $iiaCode, academicYearStart: $academicYearStartIn, academicYearEnd: $academicYearEndIn, currentSign: $currentSign, typeOfCommunication: $typeOfCommunication) {
            iia_code_p1
        }
    }
`;

const checkCreatorEC = gqlM`
    mutation checkCreatorEC($ErasmusCode: String!) {
        checkCreatorEC(ErasmusCode: $ErasmusCode) {
            ErasmusCode
        }
    }
`;

const checkPartnerEC = gqlM`
    mutation checkPartnerEC($ErasmusCode: String!) {
        checkPartnerEC(ErasmusCode: $ErasmusCode) {
            ErasmusCode
        }
    }
`;

const isIIAManagedByEWP = gqlM`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const sendEWPIIANotification = gqlM`
    mutation sendEWPIIANotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!) {
        sendEWPIIANotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID) {
            Success
            Message
        }
    }
`;

const getInstitutionData = gql`
    mutation getInstitutionData($erasmusCode: String!) {
        getIIAmInstitution(erasmusCode: $erasmusCode) {
            NameInLatinCharacterSet
            HeiID
            ErasmusCode
            CountryName
            CountryISO
            CityName
            PostalCode
        }
    }
`;

const listOU = gql`
    mutation listOU($ErasmusCodeIn: String!) {
        listOU(ErasmusCodeIn: $ErasmusCodeIn) {
            ounit_id
            institution_ecode
            abbreviation
            is_ewp_partner
            active
            lang
            name
        }
    }
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

export default compose(
    graphql(getLanguages, { name: "allLanguages" }),
    graphql(mutation, { name: "CreateIIAm" }),
    graphql(checkCreatorEC, { name: "checkCreatorEC" }),
    graphql(checkPartnerEC, { name: "checkPartnerEC" }),
    graphql(isIIAManagedByEWP, { name: "isIIAManagedByEWP" }),
    graphql(sendEWPIIANotification, { name: "sendEWPIIANotification" }),
    graphql(getInstitutionData, { name: "getInstitutionData" }),
    graphql(getIscedFCodesList, { name: "getIscedFCodesList" }),
    graphql(listOU, { name: "listOU" })
)(ShowIIAsToImport);