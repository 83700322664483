import gql from 'graphql-tag';

const query = gql`
  query{
    tutorial(erasmusCode: "${localStorage.getItem('ErasmusCode')}" ,accountID: "${localStorage.getItem('accountID')}"){
      id
      erasmusCode
      accountID
      didShiftClickList
      didShiftClickUpload
      uploadCopyPaste
    }
  }
` //remove erasmuscode and use localstorage
export { query };