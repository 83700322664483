import React, { Component } from 'react'

class NoAccess extends Component {
  render() {
    return (
        <div className={`content nomNoAccess`}>
          <div className="card card_full_height card_full_width">
            <div className="no_access nAccess">
              <h2>Access is denied</h2>
              <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
            </div>
          </div>
        </div>
    );
  }

}

export default NoAccess;