import React from 'react';

function LangLink(props) {

    const renderLangRows = () => {
		let langLink = props.langLink;
		        return langLink.map((langData, index, arrayRef) => {
			if(arrayRef.length === 1)
			     return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Language Level (link): </span>
                            <a
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`${langData}`}>
                                    Open Language Level Link
                            </a>
				</li>
				)
				return(
				<li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Language Level (link) {index + 1}: </span>
								<a
									rel="noopener noreferrer" 
									target="_blank" 
									href={`${langData}`}>
										Open Language Level Link
								</a>
				</li>
				)
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.langLink !== undefined){
        return(
		<div>
                {renderLangRows()}
				</div>
        )
    }
}

export default LangLink;