import gql from 'graphql-tag';

const query = gql`
 	query ($ErasmusCode: String!) {
		isOLAManagedByEWP(ErasmusCode: $ErasmusCode) {
			Success
			Message
	  }
	} 
`
export { query };