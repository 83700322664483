import React, { Component } from 'react';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false
        }
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event){
        this.props.setValue(event.target.value);
    }

    generateSearchImg(){
        if(this.state.value !== ""){
            return <img src="/icons/search-focus.svg"/>;
        }
        return <img src="/icons/search.svg"/>;
    }

    render() {
        return (
            <div className="search_wrapper">
                {this.generateSearchImg()}

                <input
                    onChange={(event) => this.changeValue(event)}
                    type="text"
                    id="filter-search"
                />

            </div>
        );
    }

};

export default Search;
