import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import GlobVar from '../../../../GlobalVar';
import Title from '../../common/Title';
import Label from '../../common/Label';
import TextArea from '../../common/TextArea';
import Select from '../../common/Select';
import SearchInstitution from '../../creation/SearchInstitution';
import Validator from '../../common/Validator';

/**
 * This component creates the page that defines the partners, such as the institutions names,
 * the institutions Erasmus codes, the contacts names, emails, phones and websites, and much 
 * more information.
 * Updates are made by the parent, storing the data in localStorage "partnersDetails" and 
 * calling back the refreshPage function passed as prop from IIACreation.
 */
class Partner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            key: "",
            isPartnerInEWPSearch: false,
            partnerEWPStatus: -2,
            partnerEWPIIA: 0,
            partnerEWPInst: 0,
            partnerEWPOU: 0,
            searchValue: "",
            searchKey: "",
            creatorOUlist: [],
            partnerOUlist: [],
        };

        this.searchtimeout = null;
        this.amITheCreator = false;
        if (JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode === this.props.data.creator_institution_data.erasmusCode.replace(/\s+/g, '')) {
            this.amITheCreator = true;
        }
        this.getCreatorOUList();
        if ((this.amITheCreator && props.data.partner_institution_data.erasmusCode != "") || (!this.amITheCreator && props.data.creator_institution_data.erasmusCode)) {
            this.checkPartnerInEWP();
        }

        this.renderSearchBox = this.renderSearchBox.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleOUSelection = this.handleOUSelection.bind(this);
        this.takeSearchResult = this.takeSearchResult.bind(this);
        this.closeSearchBox = this.closeSearchBox.bind(this);
        this.checkPopUp = this.checkPopUp.bind(this);
        this.checkPartnerInEWP = this.checkPartnerInEWP.bind(this);
        this.getValidation = this.getValidation.bind(this);
    }

    render() {
        let creatorTitleText = "Creator Details";
        let partnerTitleText = "Partner Details";
        if (this.amITheCreator) {
            creatorTitleText = creatorTitleText + " (YOU)";
        }
        else {
            partnerTitleText = partnerTitleText + " (YOU)";
        }

        return (
            <div className={this.props.className}>
                <Title text="Partners definition" />

                <div className="custom_table" id="create_IIA" style={{ width: "auto" }}>
                    <hr />
                    <h2 id="subtitleH2">{creatorTitleText}</h2>
                    {this.renderCreatorDetails()}

                    <hr />
                    <h2 id="subtitleH2">{partnerTitleText}</h2>
                    {this.renderPartnerDetails()}

                    <hr />
                    <h2 id="subtitleH2">Other Details</h2>
                    {this.renderCommonDetails()}
                </div>
            </div>
        );
    }

    /********************/
    /* RENDER FUNCTIONS */
    /********************/
    renderCreatorDetails() {
        if (this.amITheCreator) {
            return (
                <React.Fragment>
                    { this.renderRowOne()}
                    { this.renderRowTwo()}
                    { this.renderRowThree()}
                    { this.renderRowFour()}
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    { this.renderPartnerEWPRow()}
                    { this.renderRowOne()}
                    { this.renderRowTwo()}
                    { this.renderRowThree()}
                    { this.renderRowFour()}
                </React.Fragment>
            );
        }
    }

    renderRowOne() {
        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Institution Name" />
                        {this.formatValue((this.props.data.creator_institution_data.name != null) ? this.props.data.creator_institution_data.name : "")}
                    </div>
                    <div className="column">
                        <Label text="Erasmus Code" />
                        {this.formatValue((this.props.data.creator_institution_data.erasmusCode != null) ? this.props.data.creator_institution_data.erasmusCode : "")}
                    </div>
                    <div className="column">
                        <Label text="Country" />
                        {this.formatValue((this.props.data.creator_institution_data.country != null) ? this.props.data.creator_institution_data.country : "")}
                    </div>
                    <div className="column">
                        <Label text="City" />
                        {this.formatValue((this.props.data.creator_institution_data.city != null) ? this.props.data.creator_institution_data.city : "")}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderRowTwo() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let partnerOriginalInfo = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails"));
        let code = <div />;
        if (this.amITheCreator) {
            code =
                <div className="row">
                    <Select
                        className="column"
                        name="creatorOrganizationalUnit"
                        handleSelection={this.handleOUSelection}
                        value={partnerInfo.creatorOUnit}
                        defaultValue={partnerOriginalInfo.creatorOUnit}
                        multiple={false}
                        ounits={true}
                        options={this.state.creatorOUlist}
                        defaultMessage="Select OU"
                        refreshPage={this.props.refreshPage}
                    />
                </div>
                ;
        }
        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Organizational Unit" />
                        {this.formatValue((this.props.data.creatorOU != null) ? this.props.data.creatorOU.name : "")}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    renderRowThree() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let code = <div />;
        if (this.amITheCreator) {
            code =
                <div className="row">
                    <TextArea
                        className="column"
                        name="partnerContactName"
                        placeHolder="New value"
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        value={partnerInfo.partnerContactName}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                    <TextArea
                        className="column"
                        name="partnerContactEmail"
                        placeHolder="New value"
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        value={partnerInfo.partnerContactEmail}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                    <TextArea
                        className="column"
                        name="partnerContactPhone"
                        placeHolder="New value"
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        value={partnerInfo.partnerContactPhone}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                </div>
                ;
        }

        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Contact Name" />
                        {this.formatValue((this.props.data.creator_institution_data.contacts[0] != null) ? this.props.data.creator_institution_data.contacts[0].name : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Contact Email" />
                        {this.formatValue((this.props.data.creator_institution_data.contacts[0] != null) ? this.props.data.creator_institution_data.contacts[0].email : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Contact Phone" />
                        {this.formatValue((this.props.data.creator_institution_data.contacts[0] != null) ? this.props.data.creator_institution_data.contacts[0].phone : "")}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    renderRowFour() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let code = <div />;
        if (this.amITheCreator) {
            code =
                <React.Fragment>
                    <div className="row">
                        <div className="column">
                            <TextArea
                                name="partnerGeneralWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                handleSelection={this.handleSelection}
                                value={partnerInfo.partnerGeneralWebsite}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                        <div className="column">
                            <TextArea
                                name="partnerFacultyWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                value={partnerInfo.partnerFacultyWebsite}
                                handleSelection={this.handleSelection}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                        <div className="column">
                            <TextArea
                                name="partnerCourseWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                value={partnerInfo.partnerCourseWebsite}
                                handleSelection={this.handleSelection}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                    </div>
                </React.Fragment>
                ;
        }
        return (
            <React.Fragment>
                <div className="row header_row">
                    <h2 style={{ marginLeft: "0px" }}>Websites</h2>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="General" />
                        {this.formatValue((this.props.data.creatorGeneral != null) ? this.props.data.creatorGeneral : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Faculty/Faculties" />
                        {this.formatValue((this.props.data.creatorFaculty != null) ? this.props.data.creatorFaculty : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Course Catalogue" />
                        {this.formatValue((this.props.data.creatorCourse != null) ? this.props.data.creatorCourse : "")}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    renderPartnerDetails() {
        if (!this.amITheCreator) {
            return (
                <React.Fragment>
                    { this.renderRowFive()}
                    { this.renderRowSix()}
                    { this.renderRowSeven()}
                    { this.renderRowEight()}
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    { this.renderPartnerEWPRow()}
                    { this.renderRowFive()}
                    { this.renderRowSix()}
                    { this.renderRowSeven()}
                    { this.renderRowEight()}
                </React.Fragment>
            );
        }
    }

    renderRowFive() {
        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Institution Name" />
                        {this.formatValue((this.props.data.partner_institution_data.name != null) ? this.props.data.partner_institution_data.name : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Erasmus Code" />
                        {this.formatValue((this.props.data.partner_institution_data.erasmusCode != null) ? this.props.data.partner_institution_data.erasmusCode : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Country" />
                        {this.formatValue((this.props.data.partner_institution_data.country != null) ? this.props.data.partner_institution_data.country : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="City" />
                        {this.formatValue((this.props.data.partner_institution_data.city != null) ? this.props.data.partner_institution_data.city : "")}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderRowSix() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let partnerOriginalInfo = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails"));
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        let code = <div />;
        if (!this.amITheCreator) {
            code =
                <div className="row">
                    <Select
                        className="column"
                        name="partnerrOrganizationalUnit"
                        handleSelection={this.handleOUSelection}
                        value={partnerInfo.partnerOUnit}
                        defaultValue={partnerOriginalInfo.partnerOUnit}
                        multiple={false}
                        ounits={true}
                        options={this.state.partnerOUlist}
                        defaultMessage="Select OU"
                        refreshPage={this.props.refreshPage}
                    />
                </div>
                ;
        }

        let ounitName = "";
        if (this.props.data.partnerOU != null && this.state.partnerOUlist.length > 0) {
            this.state.partnerOUlist.map((ounit) => { 
                if (ounit.ounit_id === this.props.data.partnerOU.ounit_id) {
                    ounitName = ounit.name;
                }
            });
        }
        
        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Organizational Unit" />
                        {this.formatValue(ounitName)}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    renderRowSeven() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let code = <div />;
        if (!this.amITheCreator) {
            code =
                <div className="row">
                    <TextArea
                        className="column"
                        name="partnerContactName"
                        placeHolder="New value"
                        value={partnerInfo.partnerContactName}
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                    <TextArea
                        className="column"
                        name="partnerContactEmail"
                        placeHolder="New value"
                        value={partnerInfo.partnerContactEmail}
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                    <TextArea
                        className="column"
                        name="partnerContactPhone"
                        placeHolder="New value"
                        value={partnerInfo.partnerContactPhone}
                        validation={this.getValidation}
                        handleSelection={this.handleSelection}
                        setErrors={this.props.setErrors}
                        readOnly={false}
                    />
                </div>
                ;
        }

        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Contact Name" />
                        {this.formatValue((this.props.data.partner_institution_data.contacts[0] != null) ? this.props.data.partner_institution_data.contacts[0].name : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Contact Email" />
                        {this.formatValue((this.props.data.partner_institution_data.contacts[0] != null) ? this.props.data.partner_institution_data.contacts[0].email : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Contact Phone" />
                        {this.formatValue((this.props.data.partner_institution_data.contacts[0] != null) ? this.props.data.partner_institution_data.contacts[0].phone : "")}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    /* UDPATE */
    renderRowEight() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let code = <div />;
        if (!this.amITheCreator) {
            code =
                <React.Fragment>
                    <div className="row">
                        <div className="column">
                            <TextArea
                                name="partnerGeneralWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                handleSelection={this.handleSelection}
                                value={partnerInfo.partnerGeneralWebsite}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                        <div className="column">
                            <TextArea
                                name="partnerFacultyWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                value={partnerInfo.partnerFacultyWebsite}
                                handleSelection={this.handleSelection}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                        <div className="column">
                            <TextArea
                                name="partnerCourseWebsite"
                                placeHolder=""
                                validation={this.getValidation}
                                value={partnerInfo.partnerCourseWebsite}
                                handleSelection={this.handleSelection}
                                refreshPage={this.props.refreshPage}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                    </div>
                </React.Fragment>
                ;
        }
        return (
            <React.Fragment>
                <div className="row header_row">
                    <h2 style={{ marginLeft: "0px" }}>Websites</h2>
                </div>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="General" />
                        {this.formatValue((this.props.data.partnerGeneral != null) ? this.props.data.partnerGeneral : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Faculty/Faculties" />
                        {this.formatValue((this.props.data.partnerFaculty != null) ? this.props.data.partnerFaculty : "")}
                    </div>
                    <div className="column">
                        <Label className="column" text="Course Catalogue" />
                        {this.formatValue((this.props.data.partnerCourse != null) ? this.props.data.partnerCourse : "")}
                    </div>
                </div>
                {code}
            </React.Fragment>
        );
    }

    renderPartnerEWPRow() {
        if (this.state.partnerEWPStatus == 1) {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <h3 style={{ color: "green" }}>The IIAs of the partner are managed by a node in the EWP network. This agreement will be sent through the EWP Network.</h3>
                        <p>&nbsp;</p>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.partnerEWPStatus == 2) {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <h3 style={{ color: "green" }}>The IIAs of the partner are managed by the Dashboard in the EWP network. This agreement will not be sent through the EWP Network and treated internally in the Dashboard.</h3>
                        <p>&nbsp;</p>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.partnerEWPStatus == -1) {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <h3 style={{ color: "orange" }}>The IIAs of the partner are not managed by any node in the EWP network. This agreement will not be sent through the EWP Network.</h3>
                        <p>&nbsp;</p>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.partnerEWPStatus == 0) {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <h3 style={{ color: "orange" }}>It was not possible to get the information about the partner on the EWP Network. It may not be possible to send the IIA through the EWP Network. Please contact the Administrator.</h3>
                        <p>&nbsp;</p>
                    </div>
                </React.Fragment>
            );
        }
    }

    renderCommonDetails() {
        return (
            <React.Fragment>
                { this.renderRowNine()}
            </React.Fragment>
        );
    }

    renderRowNine() {
        let partnerInfo = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let origPartnerInfo = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails"));
        let academicYearsEnd = GlobVar.academicYears.slice(GlobVar.academicYears.indexOf(origPartnerInfo.academicYearStart));

        if (this.academicYearsStartSelectionIndex > 0) { academicYearsEnd = GlobVar.academicYears.slice(this.academicYearsStartSelectionIndex - 1); }

        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label className="column" text="Academic Year (start)" />
                        {this.formatValue(this.props.data.academicYearStart)}
                        <Select
                            name="academicYearStart"
                            multiple={false}
                            options={GlobVar.academicYears}
                            value={partnerInfo.academicYearStart}
                            handleSelection={this.handleSelection}
                            defaultMessage="New value"
                            readOnly={false}
                        />
                    </div>
                    <div className="column">
                        <Label className="column" text="Academic Year (end)" />
                        {this.formatValue(this.props.data.academicYearEnd)}
                        <Select
                            name="academicYearEnd"
                            multiple={false}
                            options={academicYearsEnd}
                            value={partnerInfo.academicYearEnd}
                            handleSelection={this.handleSelection}
                            defaultMessage="New value"
                            readOnly={false}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    /**********************/
    /* AUXILIAR FUNCTIONS */
    /**********************/
    handleSelection(selectionValue) {
        this.setState(selectionValue);

        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        let partners = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));

        Object.keys(selectionValue).map((key, index) => {
            if (key === "partnerInstitution") {
                this.setState({
                    searchValue: selectionValue[key],
                    searchKey: key
                });
                this.searchInstitution();
            }
            else if (key === "academicYearStart") {
                this.academicYearsStartSelectionIndex = document.getElementsByName(key)[0].selectedIndex;
            }
            else {
                this.setState({ key: key });
            }
            partners[key] = selectionValue[key];
            sessionStorage.setItem("iiaListPartnersDetails", JSON.stringify(partners));
        });

        aux.hasChangesPartners = (this.checkEmpty(partners)) ? false : true;
        sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
        this.refreshPage();
    }

    handleOUSelection(selectionValue) {
        if (this.amITheCreator) { this.setState({ creatorOUnit: selectionValue }); }
        else { this.setState({ partnerOUnit: selectionValue }); }

        let partnersDetails = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        partnersDetails["partnerOUnit"] = selectionValue;
        aux.hasChangesPartners = (this.checkEmpty(partnersDetails)) ? false : true;
        sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
        sessionStorage.setItem("iiaListPartnersDetails", JSON.stringify(partnersDetails));
    }

    /**
     * Gets only the OUnits that are active, so user cannot select the OUnits not active.
     * 
     * @param {Object} ouList 
     */
    getOnlyActiveOUnits(ouList) {
        let ounitsList = [];

        if (ouList != null) {
            ouList.map(ou => {
                if (ou.active) {
                    ounitsList.push(ou);
                }
            });
        }

        console.log("ounitsList:", ounitsList);
        return ounitsList;
    }

    getCreatorOUList() {
        const origPartner = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails"));
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (origPartner.creatorErasmusCode != "") {
            this.props.listOU({
                variables: {
                    "ErasmusCodeIn": (origPartner.creatorErasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                    ? GlobVar.encryptWithKey(origPartner.creatorErasmusCode).toString() : origPartner.creatorErasmusCode
                }
            }).then((x) => {
                if (x.data.error) {
                    console.log("Error getting Organizational Units");
                } else {
                    if (x.data.listOU) {
                        console.log("Successfully retrieved Organizational Units");
                        let ounitsList = this.getOnlyActiveOUnits(x.data.listOU);

                        this.setState({
                            creatorOUlist: ounitsList
                        });
                        aux.creatorOUnitList = ounitsList;
                        sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                        // this.getPartnerOUList();
                    } else {
                        console.log("Error getting Organizational Units");
                    }
                }
            });
        }
    }

    getPartnerOUList() {
        const origPartner = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails"));
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        
        if (origPartner.partnerErasmusCode != "") {
            console.log("this.state.partnerEWPStatus: " + this.state.partnerEWPStatus)
            if (this.state.partnerEWPStatus == 2 || this.state.partnerEWPStatus == -1) {
                this.props.listOU({
                    variables: {
                        "ErasmusCodeIn": (origPartner.partnerErasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                        ? GlobVar.encryptWithKey(origPartner.partnerErasmusCode).toString() : origPartner.partnerErasmusCode
                    }
                }).then((x) => {
                    if (x.data.error) {
                        console.log("Error getting Organizational Units");
                    } else {
                        if (x.data.listOU.length > 0) {
                            console.log("Successfully retrieved Organizational Units");
                            let ounitsList = this.getOnlyActiveOUnits(x.data.listOU);

                            this.setState({
                                partnerOUlist: ounitsList
                            });
                            aux.partnerOUnitList = ounitsList;
                            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                        } else {
                            console.log("Error getting Organizational Units");
                        }
                    }
                });
            } 
            // else {
            //     this.setState({
            //         partnerOUlist: []
            //     });
            //     aux.partnerOUnitList = [];
            //     sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            // }
        }
    }

    checkEmpty(obj) {
        for (let key in obj) {
            if (obj[key] instanceof Object === true) {
                if (this.checkEmpty(obj[key]) === false) {
                    return false;
                }
            }
            else {
                if (obj[key].length !== 0 && obj[key].length != null) {
                    return false;
                }
            }
        }
        return true;
    }

    getValidation(type, value) {
        const validator = new Validator(value);
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (type === "creatorInstitution" || type === "creatorContactName" || type === "partnerInstitution" || type === "partnerContactName"
            || type === "useFactsheetData" || type === "creatorCountry" || type === "creatorCity" || type === "partnerCountry" || type === "partnerCity") {
            let message = validator.validateRegularNames();
            aux.nextDisabled = (message === "") ? false : true;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            return message;
        }
        else if (type === "creatorErasmusCode" || type === "partnerErasmusCode") {
            return validator.validateErasmusCode(true);
        }
        else if (type === "creatorContactEmail" || type === "partnerContactEmail") {
            let message = validator.validateEmailFormat(true);
            aux.nextDisabled = (message === "") ? false : true;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            return message;
        }
        else if (type === "creatorContactPhone" || type === "partnerContactPhone") {
            let message = validator.validatePhoneFaxNumber(true);
            aux.nextDisabled = (message === "") ? false : true;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            return message;
        }
        else if (type === "creatorGeneralWebsite" || type === "creatorFacultyWebsite" || type === "creatorCourseWebsite" ||
            type === "partnerGeneralWebsite" || type === "partnerFacultyWebsite" || type === "partnerCourseWebsite") {
            let message = validator.validateUrlFormat(true);
            aux.nextDisabled = (message === "") ? false : true;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            return message;
        }
        else if (type === "iiaCode") {
            return validator.validateCodes(true);
        }
        else if (type === "creatorSigningDate") {
            return "";
        }

        this.refreshPage();
    }

    /********************************/
    /* INSTITUTION SEARCH FUNCTIONS */
    /********************************/
    renderSearchBox(value) {
        if (this.state.searchValue.length > 0) {
            if (!this.state.searchOpen) {
                this.setState({
                    searchOpen: true
                }, () => {
                    this.handlePopUpList(true);
                });
            }
            return <SearchInstitution value={value} takeSearchResult={this.takeSearchResult} checkPopUp={this.checkPopUp} />;
        }
        else if (this.state.searchOpen) {
            this.handlePopUpList(false);
            this.setState({
                searchOpen: false
            })
        }
    }

    takeSearchResult(newValue) {
        this.setState({
            searchValue: ""
        }, () => {
            this.handlePopUpList(false);
            this.closeSearchBox();
        });

        let partnersDetails = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        partnersDetails.partnerInstitution = newValue.NameInLatinCharacterSet;
        partnersDetails.partnerErasmusCode = newValue.ErasmusCode;
        partnersDetails.partnerCountry = newValue.City.Country.CountryName;
        partnersDetails.partnerCity = newValue.City.CityName;
        sessionStorage.setItem("iiaListPartnersDetails", JSON.stringify(partnersDetails));
        this.refreshPage();
    }

    searchInstitution() {
        clearTimeout(this.searchtimeout);
        const thisClass = this;
        let value = "";
        if (this.state.searchKey === "partnerInstitution") {
            value = thisClass.state.partnerInstitution;
        }

        this.searchtimeout = setTimeout(() => {
            thisClass.setState({
                searchValue: value
            })
        }, 500);
    }

    closeSearchBox() {
        this.setState({
            searchOpen: false
        }, () => { });
    }

    handlePopUpList(statusPopUp) {
        this.setState({
            statusPopUp
        }, () => { });
    }

    checkPopUp() {
        if (this.state.statusPopUp) {
            return " overlap";
        }
        return "";
    }

    saveValue(value) {
        let creationPageDisplaying = JSON.parse(localStorage.getItem("creationPageDisplaying"));
        let creatingIIA = JSON.parse(localStorage.getItem("creatingIIA"));

        if (creationPageDisplaying.isDisplayingPartnerPage) {
            let creatingPartner = JSON.parse(localStorage.getItem("creatingPartner"));

            if (this.props.name === "partnerErasmusCode") {
                creatingPartner[this.props.name] = value.ErasmusCode;
            }
            else if (this.props.name === "allowFactsheet") {
                if (value === "0") {
                    creatingPartner[this.props.name] = 0;
                } else {
                    creatingPartner[this.props.name] = 1;
                }
            } else {
                creatingPartner[this.props.name] = value;
            }
            localStorage.setItem("creatingPartner", JSON.stringify(creatingPartner));
        } else if (creatingIIA !== null) {
            creatingIIA[0][this.props.name] = value;
            localStorage.setItem("creatingIIA", JSON.stringify(creatingIIA));
        }
    }

    refreshPage() {
        this.setState({
            reloadPage: !this.state.reloadPage
        });
        this.props.refreshPage();
    }

    formatValue(value) {
        if (value === undefined || value === null || value === "") {
            return <p>--------------</p>;
        }
        return <p>{value}</p>;
    }

    // getPartnerData(status) {
    //     if (status == 1) {
    //         // let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
    //         console.log("Get partner data");
    //         this.props.getEWPInstitutionData({
    //             variables: {
    //                 "CreatorErasmusCode": localStorage.getItem("ErasmusCode"),
    //                 "PartnerErasmusCode": GlobVar.encryptWithKey(JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")).partnerErasmusCode).toString(),
    //             },
    //         }).then((x) => {
    //             if (x.data.getEWPInstitutionData.Success) {
    //                 console.log(x);
    //                 // this.setState({
    //                 //     partnerOUlist: x.data.getEWPInstitutionData.OUList
    //                 // });
    //                 // aux.partnerOUnitList = x.data.getEWPInstitutionData.OUList;
    //                 // sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));

    //                 // let ounitCodesList = [];
    //                 // x.data.getEWPInstitutionData.OUList.map((ounit) => {
    //                 //     ounitCodesList.push(ounit.ounit_id);
    //                 // });
    //                 // this.getPartnerEWPOUnits(JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")).partnerErasmusCode, ounitCodesList);
    //             }
    //         }).catch(error => {
    //             console.log(error);
    //         });
    //     } else {
    //         this.getPartnerOUList();
    //     }
    // }

    // getPartnerEWPOUnits(eCode, ouCodesList) {
    //     let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

    //     if (eCode != "") {

    //         this.props.getEWPOUnits({
    //             variables: {
    //                 "ErasmusCode": GlobVar.encryptWithKey(eCode).toString(),
    //                 "ounitsList": ouCodesList
    //             }
    //         }).then((response) => {
    //             if (response.data.error) {
    //                 console.log("Error getting Organizational Units");
    //             } else {
    //                 if (response.data.getEWPOUnits.Success === 1) {
    //                     console.log("Successfully retrieved Organizational Units");
    //                     this.setState({
    //                         partnerOUlist: response.data.getEWPOUnits.OUList
    //                     });
    //                     aux.partnerOUnitList = response.data.getEWPOUnits.OUList;
    //                     sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
    //                     // document.getElementById("searchingText").innerHTML = "";
    //                     // return;
    //                 } else {
    //                     console.log("Error getting Organizational Units");
    //                 }
    //             }
    //             // this.setState({
    //             //     partnerOUlist: []
    //             // });
    //             // aux.partnerOUnitList = [];
    //             // localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(aux));
    //             // document.getElementById("searchingText").innerHTML = "";
    //         });
    //     }
    // }

    checkPartnerInEWP() {
        let partnerErasmsusCode = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")).partnerErasmusCode;

        /*DEL*/console.log("partnerErasmusCode (before): " + partnerErasmsusCode);

        if (!this.amITheCreator) {
            // if (JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode === JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")).creatorErasmusCode) {
                partnerErasmsusCode = JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")).creatorErasmusCode;
            // }
        }
        /*DEL*/console.log("partnerErasmusCode (after): " + partnerErasmsusCode);

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": partnerErasmsusCode,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isPartnerInEWPSearch: true,
                    partnerEWPStatus: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isPartnerInEWPSearch: true,
                            partnerEWPStatus: 1,
                        });
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isPartnerInEWPSearch: true,
                            partnerEWPStatus: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isPartnerInEWPSearch: true,
                            partnerEWPStatus: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isPartnerInEWPSearch: true,
                            partnerEWPStatus: 0
                        });
                        break;
                }
                this.getPartnerOUList();
                // this.getPartnerData(x.data.isIIAManagedByEWP.Success);
            }
        });
    }
}

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

// const getEWPInstitutionData = gql`
//     mutation getEWPInstitutionData($CreatorErasmusCode: String!, $PartnerErasmusCode: String!) {
//         getEWPInstitutionData(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode) {
//             Success
//             Message
//             ContactName
//             ContactEmail
//             ContactPhone
//             WebsiteGeneral
//             WebsiteFaculty
//             WebsiteCourse
//             OUList {
//                 ounit_id
//                 name
//                 institution_ecode
//                 active
//             }
//         }
//     }
// `;

const listOU = gql`
    mutation listOU($ErasmusCodeIn: String!) {
        listOU(ErasmusCodeIn: $ErasmusCodeIn) {
            ounit_id
            institution_ecode
            abbreviation
            is_ewp_partner
            active
            lang
            name
        }
    }
`;

// const getEWPOUnits = gql`
//     mutation getEWPOUnits($ErasmusCode: String!, $ounitsList: [String]!) {
//         getEWPOUnits(ErasmusCode: $ErasmusCode, ounitsList: $ounitsList) {
//             Success
//             OUList {
//                 ounit_id
//                 name
//                 institution_ecode
//                 active
//             }
//         }
//     }
// `;

export default compose(
    graphql(listOU, { name: "listOU" }),
    // graphql(getEWPOUnits, { name: "getEWPOUnits" }),
    graphql(isIIAManagedByEWP, { name: "isIIAManagedByEWP" }),
    // graphql(getEWPInstitutionData, { name: "getEWPInstitutionData" }),
)(Partner);