import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

import GlobalVar from '../../../GlobalVar';
import Label from '../common/Label';
import AcademicYear from './filters/AcademicYear';
import Modified from './filters/Modified';
import PartnerECode from './filters/PartnerECode';
import Search from './filters/Search';
import Status from './filters/Status';
import Sorting from './filters/Sorting';

class Options extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshPage: false
    }

    this.newSettings = {
      sortField: "iia_id_p1",
      sortDirection: "ASC",
      status: [],
      academicYear: [],
      partnerEC: [],
      modifiedSince: "",
      search: ""
    }
    
    this.handlerSingleValue = this.handlerSingleValue.bind(this);
    this.handlerMultipleValue = this.handlerMultipleValue.bind(this);
    this.resetOptionsSettings = this.resetOptionsSettings.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  resetOptionsSettings() {
    window.location.reload();
  }

  refreshPage() {
    this.setState({
        refreshPage: !this.state.refreshPage
    });
  }

  handlerSingleValue(event, filterName) {
    this.newSettings[filterName] = event.target.value;
  }

  handlerMultipleValue(event, filterName) {
    let selectElement = document.getElementById(event.target.name);
    let selectedItens = [];

    for (let i = 0; i < selectElement.length; i++) {
      if (selectElement.options[i].selected) { selectedItens.push(selectElement.options[i].value); }
    }
    this.newSettings[filterName] = selectedItens;
  }

	render() {
    if(this.props.data.loading){
      return (
        <div className="division_25">
          <div className="card card_full_width card_full_height">
            <div className="custom_table">
              <p><img className="spinner" src="/icons/spinner.svg"/>Loading...</p>
            </div>
          </div>
        </div>
      );
    } 
    return (
      <div className="division_25">
        <div className="card card_full_width card_full_height">
          <Label text="Choose a field to sort and its direction"/>
          <Sorting handlerChange={this.handlerSingleValue} value={this.newSettings.sortField} direction={this.newSettings.sortDirection}/>
          <br/>

          <Label text="Select a filter for the list" />
          <div className="option_wrapper">
            <Search handlerChange={this.handlerSingleValue} value={this.newSettings.search}/>
            <br/>
            <Status handlerChange={this.handlerMultipleValue} value={this.newSettings.status}/>
            <AcademicYear handlerChange={this.handlerMultipleValue} value={this.newSettings.academicYear}/>
            <PartnerECode handlerChange={this.handlerMultipleValue} value={this.newSettings.partnerEC}/>
            <Modified handlerChange={this.handlerSingleValue} value={this.newSettings.modifiedSince}/>
          </div>
          <button onClick={() => this.props.handleApplyChanges(this.newSettings)}>Apply changes</button>
          <button style={{marginLeft: "10px"}} onClick={() => {window.location.reload()}}>Reset</button>
          <br/>
          <p style={{marginTop: "10px"}}>Found {this.props.totalResults} Agreements</p>
          <br/>

          <hr />
          <Label text="Search for IIAs in EWP" />
          {<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/ewp-iia-list"}}>
            <button>List EWP Agreements</button>
					</Link>}
        </div>
      </div>
    );
  }
};

export default Options;
