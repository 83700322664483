import React, { useEffect, Fragment } from "react";
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import { useAssistanceSettings } from './hooks/useAssistanceSettings';
import Validator from '../iia/common/Validator';

const description = 'In response to the dramatic events unfolding in Ukraine we have developed a portal to provide information about assistance offered by Higher Education Institutions to students fleeing the war. If you would like to help us broadcast how your University is stepping up and making a difference please indicate:';
const titles = [
    'Does your Institution provide emergency shelter?',
    'Is health and psychological support made available at your Institution?',
    'Does your Institution provide a fast track application procedure for studies?',
    'Does your Institution offer special scholarships for studies?'
];

const names = ['emergencyShelter', 'accessHealthSupport', 'fastTrackApplication', 'specialScholarships', 'moreInfoUrl'];
const responseNames = ['shelter', 'health', 'fast_track', 'scholarship', 'more_info_url'];

const options = ['Unknown', 'Yes', 'No'];
const optionsMappingValues = [-1, 0, 1];

const UkraineAssistanceSettings = (props) => {
    const { state: settings, updateMultipleValues, setSaveButtonDisabled } = useAssistanceSettings();

    useEffect(() => {
        if (!props.data.loading) {
            updateMultipleValues({
                ...settings,
                ...mapStringSettingsToInt(props.data.getUkraineAssistanceInfo)
            });
        }
    }, [props.data.loading])

    const handleSelect = (name, value) => {
        updateMultipleValues({ [`${name}`]: value })
    }

    const handleUrlChange = (event) => {
        let validator = new Validator(event.target.value);
        let message = validator.validateUrlFormatWithProtocol(true);

        if (message) {
            updateMultipleValues({ moreInfoUrl: event.target.value, urlError: true, urlErrorMessage: message })
        } else {
            updateMultipleValues({ moreInfoUrl: event.target.value, urlError: false, urlErrorMessage: '' })
        }
    }

    // validate that at least one option is a yes, or a url is provided
    const validSettings = () => {
        if (settings.urlError)
            return false;

        // valid url provided
        if (settings.moreInfoUrl !== "") {
            return true;
        }

        // at least one yes option
        for (const name of names) {
            if (settings[name] === 0) {
                return true;
            }
        }

        return false;
    }

    const mapSettingsToString = () => {
        const result = {};

        for (const name of names) {
            const index = optionsMappingValues.indexOf(settings[name]);

            if (index === -1) {
                result[name] = settings.moreInfoUrl;
            } else {
                result[name] = options[index];
            }
        }

        return result;
    }

    const mapStringSettingsToInt = (responseData) => {
        const result = {};

        if (!responseData) {
            return {};
        }

        for (let i = 0; i < responseNames.length; i++) {
            const responseName = responseNames[i];
            const stringValueIndex = options.indexOf(responseData[responseName]);

            if (stringValueIndex === -1) {
                result[names[i]] = responseData[responseName]; // more_info_url property has no predefined value, the resulting index is always -1
            } else {
                result[names[i]] = optionsMappingValues[stringValueIndex] ?? '';
            }
        }

        return result;
    }

    const handleSave = async (event) => {
        setSaveButtonDisabled(true);
        if (!validSettings()) {
            alert('Please select at least one "Yes" option or provide a valid link');
            return;
        }

        const valuesToSave = mapSettingsToString();

        const response = await props.changeUkraineAssistanceInfo({
            variables: {
                erasmusCode: JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode,
                shelter: valuesToSave.emergencyShelter,
                health: valuesToSave.accessHealthSupport,
                fastTrack: valuesToSave.fastTrackApplication,
                scholarship: valuesToSave.specialScholarships,
                moreInfoUrl: valuesToSave.moreInfoUrl
            },
            refetchQueries: [{
                query: getUkraineAssistanceInfo,
                variables: {
                    erasmusCode: JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode
                }
            }],
            awaitRefetchQueries: true
        });

        if (!response.data.changeUkraineAssistanceInfo.Success) {
            alert(response.data.changeUkraineAssistanceInfo.Message);
        } else {
            alert("Successfully changed values");
        }
        setSaveButtonDisabled(false);
    }

    const renderRows = (name, data) => {
        if (data || (data === 0)) {
            return (
                <div className="row">
                    {options.map((option, index) => (
                        <div className="column" key={index}>
                            <h3>
                                <input value={optionsMappingValues[index]} type="radio" name={name} onChange={() => handleSelect(name, optionsMappingValues[index])}
                                    checked={data === optionsMappingValues[index] ? true : false}
                                />
                                {option}
                            </h3>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className="row"><p>It's not possible to set this at the moment, please contact the Dashboard administrator.</p></div>
        );
    }

    if (props.data.loading) {
        return (
            <div className="card card_full_width card_full_height">
                <div className="custom_table">
                    <p><img className="spinner" src="/icons/spinner.svg" />Loading settings...</p>
                </div>
            </div>
        );
    } else if (props.data.error) {
        return (
            <div className="card card_full_width card_full_height">
                <div className="custom_table">
                    <p><img className="spinner" src="/icons/spinner.svg" />Error loading settings...</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="content">
                <div className="card scrollbar card_full_width card_full_height">
                    <div className="custom_table">
                        <h2 style={{ marginLeft: 0 }}>War in Ukraine - Student Support Centre</h2>
                        <div>
                            <p>&nbsp;</p>
                            <h1>{description}</h1>
                            <p>&nbsp;</p>
                        </div>
                        <hr />

                        {titles.map((title, index) => (
                            <Fragment key={index}>
                                <div className="row header_row">
                                    <h1>&nbsp;</h1>
                                    <h2>{title}</h2>
                                    <p>&nbsp;</p>
                                </div>
                                <div className="row">
                                    <div className="row">
                                        {renderRows(names[index], settings[names[index]])}
                                    </div>
                                </div>
                            </Fragment>
                        ))}

                        <div className="row header_row">
                            <h1>&nbsp;</h1>
                            <h2>Please provide a link to your Institution's dedicated webpage with more information on this topic, or to your Institution's relevant contacts page.</h2>
                        </div>
                        <div className="row">
                            <div className="row">
                                <div className="row">
                                    <p style={{ marginLeft: '10px' }}>URL: <input type="text" size="50" placeholder="https://www.example.com" onChange={handleUrlChange} value={settings.moreInfoUrl}></input></p>
                                    {settings.urlError ? <p style={{ color: 'red' }}>{settings.urlErrorMessage}</p> : <></>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <button onClick={handleSave} disabled={settings.saveButtonDisabled}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mutationChangeUkraineAssistanceInfo = gql`
    mutation changeUkraineAssistanceInfo($erasmusCode: String!, $shelter: String!, $health: String!, $fastTrack: String!, $scholarship: String!, $moreInfoUrl: String!) {
        changeUkraineAssistanceInfo(erasmusCode: $erasmusCode, shelter: $shelter, health: $health, fastTrack: $fastTrack, scholarship: $scholarship, moreInfoUrl: $moreInfoUrl ) {
            Success
            Message
	  }
	}
`;

const getUkraineAssistanceInfo = gql`
    query getUkraineAssistanceInfos($erasmusCode: String!) {
        getUkraineAssistanceInfo(erasmusCode: $erasmusCode) {
            shelter
            health
            fast_track
            scholarship
            more_info_url
        }
    }
`;

export default compose(
    graphql(getUkraineAssistanceInfo, {
        options: (props) => {
            return {
                variables: {
                    erasmusCode: localStorage.getItem("currentErasmusCode") ? JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode : ""
                }
            }
        }
    }),
    graphql(mutationChangeUkraineAssistanceInfo, { name: 'changeUkraineAssistanceInfo' })
)(UkraineAssistanceSettings);