import React, { Component } from 'react';
import Options from './Options';
import Data from './Data';
import intl from 'react-intl-universal';

class List extends Component {
  constructor(props) {
      super(props);
      this.filterIsApplied = this.filterIsApplied.bind(this);
      this.reloadActions = this.reloadActions.bind(this);
      this.state = {
          reloadActions: false,  //just to reload the Action component
          actionName: ""
      }
      this.typeOfStudents = -1;
      this.typeOfStudentName = "";
  }

  setTypeOfStudents(props){
      const url = props.location.pathname;
      let outgoingStudent = intl.get("Outgoing students");
      outgoingStudent = outgoingStudent.replace(/\s/g, "-");
      outgoingStudent = outgoingStudent.toLowerCase();

      if(url.includes(outgoingStudent)){
          this.typeOfStudents = 1;
          this.typeOfStudentName = "Outgoing";
      }
      else{
          let incomingStudent = intl.get("Incoming students");
          incomingStudent = incomingStudent.replace(/\s/g, "-");
          incomingStudent = incomingStudent.toLowerCase();
          if(url.includes(incomingStudent)){
              this.typeOfStudents = 2;
              this.typeOfStudentName = "Incoming";
          }
      }
      //
  }
  componentWillMount() {
      this.setTypeOfStudents(this.props);
      this.setFilterAsState("country");
      this.setFilterAsState("institution");
      this.setFilterAsState("signedBefore");
      this.setFilterAsState("signedDuring");
      this.setFilterAsState("search");
      this.setFilterAsState("startMobility");
      this.setFilterAsState("endMobility");
      this.setFilterAsState("uploaded");
      this.setFilterAsState("academicYear");
      this.setFilterAsState("studyCycle");
      this.setFilterAsState("subjectArea");
      this.setOtherLocalstorageAsState("sortBy");
      this.setOtherLocalstorageAsState("sortDirection");
  }
  componentWillReceiveProps(nextProps) {
      this.setTypeOfStudents(nextProps);
  }
  setFilterAsState(filterName){
      if(localStorage.getItem("filters"+this.typeOfStudentName) != null){
          const filters = JSON.parse(localStorage.getItem("filters"+this.typeOfStudentName));
          if(filters[filterName] != this.state[filterName]){
            localStorage.removeItem("studentSelection"+this.typeOfStudentName);
          }
          if(filters[filterName] != null){
            this.setState({
                [filterName]: filters[filterName]
            })
          }
          else{
            this.setState({
                [filterName]: undefined
            })
          }
      }
  }
  setOtherLocalstorageAsState(value){
      if(localStorage.getItem(value+this.typeOfStudentName) != null){
          this.setState({
              [value]: localStorage.getItem(value+this.typeOfStudentName)
          })
      }
  }

  filterIsApplied(actionName = ""){
    this.setState({
      uselessState: !this.state.uselessState,
      actionName
    })
    this.setFilterAsState("country");
    this.setFilterAsState("institution");
    this.setFilterAsState("signedBefore");
    this.setFilterAsState("signedDuring");
    this.setFilterAsState("search");
    this.setFilterAsState("startMobility");
    this.setFilterAsState("endMobility");
    this.setFilterAsState("uploaded");
    this.setFilterAsState("academicYear");
    this.setFilterAsState("studyCycle");
    this.setFilterAsState("subjectArea");
    this.setOtherLocalstorageAsState("sortBy");
    this.setOtherLocalstorageAsState("sortDirection");
  }
  reloadActions(){
    this.setState({
        reloadActions: !this.state.reloadActions
    });
  }
	render() {
  	return (
  		<div className="content student_list">
        <div className="division_25">
           <Options
            typeOfStudentName={this.typeOfStudentName}
            typeOfStudents={this.typeOfStudents}
            filterIsApplied={this.filterIsApplied}
            />
        </div>
        <Data
          typeOfStudentName={this.typeOfStudentName}
          filterIsApplied={this.filterIsApplied}
          history={this.props.history}
          typeOfStudents={this.typeOfStudents}
          countryName={this.state.country}
          institutionName={this.state.institution}
          signedBefore={this.state.signedBefore}
          signedDuring={this.state.signedDuring}
          search={this.state.search}
          startMobility={this.state.startMobility}
          endMobility={this.state.endMobility}
          uploaded={this.state.uploaded}
          academicYear={this.state.academicYear}
          studyCycle={this.state.studyCycle}
          subjectArea={this.state.subjectArea}
          sortBy={this.state.sortBy}
          sortDirection={this.state.sortDirection}
          reloadActions={this.reloadActions}
          actionName={this.state.actionName}
          uselessProp={this.state.uselessState}
        />
      </div>
    );
  }

};

export default List;
