import React, { Component } from 'react';
import { query } from '../../../queries/getSingleAccount';
import { graphql } from 'react-apollo';

class Information extends Component {
	renderAbilities(){
		return this.props.data.singleAccount.AccountFunction.AccountAbility.map((data, index) => {
			return <li key={data.Name}>{data.Name}</li>
		})
	}
	render() {
		const { data } = this.props;
		if(data.loading){
	    	return (
	    		<div className="card card_fourth_width card_fixed_height">
	    			<h2>Information</h2>
	    		</div>
	      	);
      	}
      	else if(data.singleAccount){
      		return (
	    		<div className="card card_fourth_width card_fixed_height">
	    			<h2>Information</h2>
	    			<div className="inside_content">
		    			<ul>
		    				<li>Name: {data.singleAccount.Name}</li>
		    				<li>Email: <a href={"mailto:"+data.singleAccount.Email}>{data.singleAccount.Email}</a></li>
		    				<li>Role: {data.singleAccount.AccountFunction.Name}</li>
		    			</ul>
		    			<h3>Permissions</h3>
		    			<ul>
		    				{this.renderAbilities()}
		    			</ul>
	    			</div>
	    		</div>
	      	);
      	}
      	else{
      		return (
	    		<div className="card card_fourth_width card_fixed_height">
	    			<h2>Information</h2>
	    		</div>
	      	);
      	}
    }

};

export default graphql(query, {
    options: (props) => {
    	return {
        	variables: { id: localStorage.getItem("accountID")}
    	}
    }
})(Information);
