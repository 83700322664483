import React, { Component } from 'react';
import StatusFilter from "./filters/StatusFilter"
import SearchFilter from "./filters/SearchFilter"
import CreatedByFilter from "./filters/CreatedByFilter"
import AcademicYearFilter from './filters/AcademicYearFilter';
import HeiFilter from './filters/HeiFilter';
import CountryFilter from './filters/CountryFilter';


class Options extends Component {

  resetFilters = (val) => {
    this.props.reset(val)
  }

  handleFilterSearch = (val) => {
    this.props.SearchFilter(val)
  }
  handleFilterStatus = (val) =>{
    this.props.StatusFilter(val)
  }
  handleFilterCreatedBy = (val) =>{
    this.props.CreatedByFilter(val)
  }
  handleFilterAcademicYear = (val) =>{
    this.props.academicYearValue(val)
  }
  handleFilterHei = (val) =>{
    this.props.heiValue(val)
  }
  handleFilterCountry = (val) =>{
    this.props.countryValue(val)
  }

	render() {
  	return (
      <div className="card card_full_width card_full_height">
        <h4 style className={"row"} style={{color: "#4A90E2", marginLeft: "10px"}}>Select a filter for the table</h4>
        <br/>
        <div className="option_wrapper">
          <SearchFilter onChange={(event) => this.changeValue(event)} data={this.props.data.learningAgreementList} onSelectedSearch={this.handleFilterSearch}/>
          <StatusFilter onChange={(event) => this.changeValue(event)} data={this.props.data.learningAgreementList} typeOfStudent={this.props.data.typeOfStudent} onSelectedStatus={this.handleFilterStatus} />
          <AcademicYearFilter
            onChange={(event) => this.changeValue(event)}
            data={this.props.data.learningAgreementList}
            typeOfStudent={this.props.data.typeOfStudent}
            onSelectedAcademicYear={this.handleFilterAcademicYear}
            firstYear={this.props.firstYear}
            secondYear={this.props.secondYear}
          />
          <HeiFilter onChange={(event) => this.changeValue(event)} data={this.props.data.learningAgreementList} typeOfStudent={this.props.data.typeOfStudent} onSelectedHEI={this.handleFilterHei}/>
          <CountryFilter onChange={(event) => this.changeValue(event)} data={this.props.data.learningAgreementList} typeOfStudent={this.props.data.typeOfStudent} onSelectedCountry={this.handleFilterCountry}/>
          {(this.props.typeOfStudent === 1)&&<CreatedByFilter  onChange={(event) => this.changeValue(event)} data={this.props.data.learningAgreementList} onSelectedCreatedBy={this.handleFilterCreatedBy} />}
          <div>
            <button onChange={(event) => this.changeValue(event)} onClick={this.resetFilters}>Reset</button>
            <p className="num_of_las">Number of Learning Agreements: {this.props.length}</p>
          </div>
        </div>
         <br/>
         <ul className="instructions">
            <h4 style className={"row"} style={{color: "#4A90E2"}}>Instructions</h4>
            <li>Search bar to filter student name, start/end mobility, the coordinators, emails of all involved, faculty names and HEI name.</li>
        </ul>
      </div>
  	);
  }

};
export default Options;
