import gql from 'graphql-tag';

const query = gql`
  query ($erasmusCode: String, $id: String) {
    outgoingNomination (erasmusCode: $erasmusCode,id: $id) {
		id
		title
		created
		field_cv
		field_external_cert
		field_lang_level
		field_lang_level_filename
		field_motivation_letter
		field_other_document
		field_recom_letter
		field_tor
		field_cv_link
		field_req_ects
		field_student_email
		field_academic_year
		field_academic_term
		field_req_eqf
		field_student_esi
		field_external_cert_link
		field_req_isced
		field_lang_level_link
		field_mobility_details{
			id
			field_home_erasmus_code
			field_home_name
			field_home_org_unit
			field_host_erasmus_code
			field_host_name
			field_host_org_unit
			field_start_date
			field_end_date
			field_mobility_details_status
			canBeAccepted
			field_coop_condition_id
		}
		field_motiv_letter_link
		field_student_name
		field_nomination_status
		field_other_document_link
		field_other_requirement
		field_portfolio
		field_recom_letter_link
		field_reference_contact
		field_student_isced
		field_student_eqf
		field_tor_link
		error
    }
  }
`;

export { query };
