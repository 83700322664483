import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {query as getAllShortTerms} from '../../queries/getAllShortTerms';
import { graphql, useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ShortTermRow from './Row';
import Loader from 'react-loader-spinner';
import Options from './Options';

function ShortTermData(props) {

    const [statusValue,setStatusValue] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const [CreatedByValue,setCreatedByValue] = useState("");
    const [academicYearValue,setAcademicYearValue] = useState("");
    const [heiValue,setHeiValue] = useState("");
    const [countryValue,setCountryValue] = useState("");
    const [sortFirstName,setSortFirstName] = useState("");
    const [getSortFirstName,setGetSortFirstName] = useState("");
    const [sortLastName,setSortLastName] = useState("");
    const [sortStatus,setSortStatus] = useState("");
    const [sortInstitution,setSortInstitution] = useState("");
    const [sortStartMobility,setSortStartMobility] = useState("");
    const [sortEndMobility,setSortEndMobility] = useState("");
    let [sortBy, setSortBy] = useState({
        "sortFirstName": "",
        "sortLastName": "",
        "sortStartMobility": "",
        "sortEndMobility": "",
        "sortStatus": "",
        "sortInstitution": ""
    });
    let [hasNext, setHasNext] = useState(true);
    let [loadingState, setLoadingState] = useState(true);

    const { firstYear, secondYear } = useParams();
    const history = useHistory();

    const renderHeader = () => {
        const institutionType = (props.typeOfStudent === 1) ? "Receiving Institution" : "Sending Institution";
        const institutionClass = (props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution";

        const headerColumns = [
            <div 
                style={{display:"flex"}} 
                onClick={() => {
                    if (getSortFirstName === "" || sortFirstName === "desc"){
                        setSortFirstName("asc");
                        setSortStartMobility("");
                        setSortEndMobility("");
                        setSortLastName("");
                        setSortStatus("");
                        setSortInstitution("");
                    }

                    if(sortFirstName === "asc"){
                        setSortFirstName("desc");
                    }
                }}>
                <div>First Name</div>
                <div>
                    {(sortFirstName === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) :
                    ((sortFirstName==="desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />):(<div/>))}
                </div>
            </div>, 
            <div 
                style={{display:"flex"}} 
                onClick={() => {
                if (sortLastName === "" || sortLastName === "desc") {
                    setSortLastName("asc");
                    setSortStartMobility("");
                    setSortEndMobility("");
                    setSortFirstName("");
                    setSortStatus("");
                    setSortInstitution("");
                }

                if(sortLastName==="asc") {
                    setSortLastName("desc");
                }
            }}>
            <div>Last Name</div>
            <div>
                {(sortLastName==='asc') ? 
                (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                ((sortLastName==="desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))}
            </div>
        </div>, 
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortStartMobility === "" || sortStartMobility === "desc"){
                    setSortStartMobility("asc");
                    setSortFirstName("");
                    setSortEndMobility("");
                    setSortLastName("");
                    setSortStatus("");
                    setSortInstitution("");
                }

                if(sortStartMobility === "asc"){
                    setSortStartMobility("desc");
                }}}>
                <div>Start Mobility</div>
                <div>{(sortStartMobility === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortStartMobility==="desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))}
                </div>
            </div>, 
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortEndMobility === "" || sortEndMobility === "desc"){
                    setSortEndMobility("asc");
                    setSortStartMobility("");
                    setSortFirstName("");
                    setSortLastName("");
                    setSortStatus("");
                    setSortInstitution("");
                }

                if(sortEndMobility === "asc"){
                    setSortEndMobility("desc");
                }}}>
            <div>End Mobility</div>
            <div>{(sortEndMobility==='asc') ? 
                (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                ((sortEndMobility==="desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) :(<div/>))}
            </div>
        </div>,
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortStatus === "" || sortStatus === "desc"){
                    setSortStatus("asc");
                    setSortStartMobility("");
                    setSortEndMobility("");
                    setSortFirstName("");
                    setSortLastName("");
                    setSortInstitution("");
                }

                if(sortStatus === "asc") {
                    setSortStatus("desc");
                }
            }}>
            <div>Status</div>
            <div>
                {(sortStatus === 'asc') ? 
                    (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                    ((sortStatus === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                }
            </div>
        </div>,
        <div 
            style={{display:"flex"}} 
            onClick={() => {
                if (sortInstitution === "" || sortInstitution === "desc"){
                    setSortInstitution("asc");
                    setSortStartMobility("");
                    setSortEndMobility("");
                    setSortFirstName("");
                    setSortLastName("");
                    setSortStatus("");
                }

                if(sortInstitution === "asc"){
                    setSortInstitution("desc");
                }
            }}>
            <div>{institutionType}</div>
            <div>
                {(sortInstitution === 'asc') ? 
                (<img className="arrow" src="/icons/downArrow-blue.svg" />) : 
                ((sortInstitution === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />):(<div/>))}
            </div>
        </div>]
    
        const renderedHeader = headerColumns.map((column, index) => (
            <th className={["th_student column media_links",
                    (column === headerColumns[headerColumns.length-1] ? institutionClass : null),
                    (column === headerColumns[headerColumns.length-2]  ? "status" : null)].join(" ")} 
                    key={index} role="columnheader">
                <h3>{column}</h3>
            </th>
        ));
        return (
            renderedHeader
        );
    }

    const renderRows = (shortTermMobilities) => {
        if (shortTermMobilities.length > 0) {
            return shortTermMobilities.map(shortTermMobility =>
                <ShortTermRow
                    key={shortTermMobility.shortTermID}
                    shortTerm={shortTermMobility}
                    firstYear={firstYear}
                    secondYear={secondYear}
                    typeOfStudent={props.typeOfStudent}
                    typeOfStudentName={props.typeOfStudentName}
                    institutionInfo={props.institutionInfo} />
            );
        }
        const style = {"paddingTop": "15px"};
        return <tr><td style={style}>No Short-Term Mobilities available</td></tr>;
    }

    const triggerInput = (enteredName, enteredValue) => {
        const input = document.getElementById(enteredName);
        if(input != null){
            const lastValue = input.value;
            input.value = enteredValue;
            const event = new Event("change", { bubbles: true });
            const tracker = input._valueTracker;
            if (tracker) {
                tracker.setValue(lastValue);
            }
            input.dispatchEvent(event);
        }
    }

    const resetFilters = (val) => {
        if (props.typeOfStudent === 1) {
            setSearchValue("");
            setCreatedByValue("Select Created By");
            setStatusValue("");
            /*setAcademicYearValue("");*/
            setHeiValue("");
            setCountryValue("");
            setSortFirstName("");
            setSortLastName("");
            setSortStatus("");
            setSortInstitution("");
            setSortStartMobility("");
            setSortEndMobility("");

            triggerInput("filter-search", "");
            triggerInput("created-by", "");
            triggerInput("status-filter", "");
            /*triggerInput("filter-academic-year", "");*/
            triggerInput("filter-hei", "");
            triggerInput("filter-country", "");
        }

        if (props.typeOfStudent === 2) {
            setSearchValue("");
            setCreatedByValue("");
            setStatusValue("");
            /*setAcademicYearValue("");*/
            setHeiValue("");
            setCountryValue("");
            setSortFirstName("");
            setSortLastName("");
            setSortStatus("");
            setSortInstitution("");
            setSortStartMobility("");
            setSortEndMobility("");

            triggerInput("filter-search", "");
            triggerInput("status-filter", "");
            /*triggerInput("filter-academic-year", "");*/
            triggerInput("filter-hei", "");
            triggerInput("filter-country", "");
        }
    }

    const handleFilterSearch = (val) => {
        setSearchValue(val);
    }
    const handleFilterStatus = (val) => {
        setStatusValue(val);
    }
    const handleFilterCreatedBy = (val) => {
        setCreatedByValue(val);
    }
    const handleFilterAcademicYear = (val) => {
        if(val !== "all" && val !== "" && val !== "null" && val !== "undefined"){
            let split = val.split("/");
            let firstYear = split[0];
            let secondYear = split[1];
            if(props.typeOfStudent === 1) {
                history.replace({pathname: "/short-term/outgoing-students/" + firstYear + "-" + secondYear});
            } else {
                history.replace({pathname: "/short-term/incoming-students/" + firstYear + "-" + secondYear});
            }
        } else {
            if(props.typeOfStudent === 1) {
                history.replace({pathname: "/short-term/outgoing-students"});
            } else {
                history.replace({pathname: "/short-term/incoming-students"});
            }
        }
        /*setAcademicYearValue(val);*/
    }
    const handleFilterHei = (val) => {
        setHeiValue(val);
    }
    const handleFilterCountry = (val) => {
        setCountryValue(val);
    }

    const sortFirstNameRows = (rows, property) => {
        if (sortFirstName === 'asc' || sortFirstName === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortFirstName === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortLastNameRows = (rows, property) => {
        if (sortLastName === 'asc' || sortLastName === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortLastName === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortStartMobilityRows = (rows, property) => {
        if (sortStartMobility === 'asc' || sortStartMobility === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortStartMobility === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortEndMobilityRows = (rows, property) => {
        if (sortEndMobility === 'asc' || sortEndMobility === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortEndMobility === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortStatusRows = (rows, property) => {
        if (sortStatus === 'asc' || sortStatus === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortStatus === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const sortInstitutionRows = (rows, property) => {
        if (sortInstitution === 'asc' || sortInstitution === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return a[property].localeCompare(b[property]);}
            });
        } else if (sortInstitution === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

    const { data, error, loading, fetchMore } = useQuery(getAllShortTerms, {
        variables: { 
            HEC: localStorage.getItem('ErasmusCode'),
            typeOfStudent: props.typeOfStudent,
            page: 0,
            firstYear: firstYear,
            secondYear: secondYear
        },
    });

    useEffect(() => {
        if(data != null) {
            if(data.shortTermMobilities !== []){
                const { nextLink, page } = data.shortTermMobilities;
                let isNext = (nextLink !== "") ? true : false;
                setHasNext(isNext);

                if(hasNext && page != null){
                    const { data } = fetchMore({
                        variables: { 
                            HEC: localStorage.getItem('ErasmusCode'),
                            typeOfStudent: props.typeOfStudent,
                            page: page
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) {
                                return prev;
                            }
                            return {
                                shortTermMobilities: {
                                    ...prev.shortTermMobilities,
                                    ...fetchMoreResult.shortTermMobilities,
                                    shortTermList: [
                                        ...prev.shortTermMobilities.shortTermList,
                                        ...fetchMoreResult.shortTermMobilities.shortTermList,
                                    ],
                                },
                            };
                        }
                    });
                } else {
                    setLoadingState(false);
                }
            }
        }
    });

    /*****************************/
    /******** RENDER PART ********/
    /*****************************/
    if (error) console.log(error);

    if(loading || loadingState) {
        return (
            <div className="white_background">
                <Loader
                className="spinner"
                type="TailSpin"
                color="#3986df"
                height={70}
                width={70} />
            </div>
        )
    }

    const header = renderHeader();
    let rows = data.shortTermMobilities ? data.shortTermMobilities.shortTermList: null;
    if(rows !== undefined && rows !== null){
        rows = Array.prototype.slice.call(rows);
        if (sortFirstName) {
            rows = sortFirstNameRows(rows, "field_student_firstname");
        }
        if (sortLastName) {
            rows = sortLastNameRows(rows, "field_student_lastname");
        }
        if (sortStartMobility) {
            rows = sortStartMobilityRows(rows, "field_planned_period_from");
        }
        if (sortEndMobility) {
            rows = sortEndMobilityRows(rows, "field_planned_period_to");
        }
        if (sortStatus) {
            rows = sortStatusRows(rows, "field_la_status");
        }
        if (sortInstitution) {
            (props.typeOfStudent === 1) ? 
                rows = sortInstitutionRows(rows,"receiving_institution") : 
                rows = sortInstitutionRows(rows,"sending_institution")
        }
    }
    
    if (searchValue){
        rows = rows.filter((data) => {
            return  (data.field_student_firstname !== null && data.field_student_firstname.toLowerCase().includes(searchValue.toLowerCase()))||
            (data.field_student_lastname !== null && data.field_student_lastname.toLowerCase().includes(searchValue.toLowerCase()))||
            (data.field_dashboard_student_email !== null && data.field_dashboard_student_email.toLowerCase().includes(searchValue.toLowerCase()))||
            (data.sending_institution_faculty !== null && data.sending_institution_faculty.toLowerCase().includes(searchValue.toLowerCase()))||
            (data.receiving_institution_faculty !== null && data.receiving_institution_faculty.toLowerCase().includes(searchValue.toLowerCase()))||
            (data.field_planned_period_from !== null && data.field_planned_period_from.includes(searchValue))||
            (data.field_planned_period_to !== null && data.field_planned_period_to.includes(searchValue))})
    }
    if (academicYearValue){
        rows = (rows.filter((data) => {
            return academicYearValue.includes(data.field_academic_year)}))
    }
    if (heiValue) {
        rows = (rows.filter((data) => {
            return ((data.sending_institution_hei === heiValue)|| (data.receiving_institution_hei === heiValue))}))
    }
    if (countryValue) {
        rows= (rows.filter((data) => {
            return ((data.sending_institution_country === countryValue)|| (data.receiving_institution_country === countryValue))}))
    }
    if (statusValue) {
       rows = (rows.filter((data) => {
            return data.field_la_status === statusValue}))
    }
    if (CreatedByValue){
        if(CreatedByValue.includes("Created By Student")){
            rows = (rows.filter((data) => {
                return data.field_dashboard_student_email === null}))
        }
        if(CreatedByValue.includes("Created By Institution")){
            rows=(rows.filter((data) => {
                return data.field_dashboard_student_email !== null}))
            }

    }
    let length = 0
    if (rows){
        rows.map(la => {
            if(la.field_la_status != "preliminary_unsigned" || 
                (props.typeOfStudent == 1 && 
                la.field_dashboard_student_email != null)) {
                    length += 1;
            }
        });
    }
    
    return (
        <div className={"content student_list"}>
            <div className="division_25">
                <Options
                    SearchFilter={handleFilterSearch}
                    CreatedByFilter={handleFilterCreatedBy}
                    StatusFilter={handleFilterStatus}
                    academicYearValue={handleFilterAcademicYear}
                    heiValue={handleFilterHei}
                    countryValue={handleFilterCountry}
                    reset={resetFilters}
                    data={data.shortTermMobilities}
                    typeOfStudent={props.typeOfStudent}
                    firstYear={firstYear}
                    secondYear={secondYear}
                    length={length} />
            </div>
            <div className="card card_full_width card_full_height">
                <table className={"table1_student custom_table"} role="table"> 
                    <thead className={"thead_student"} role="rowgroup">
                        <tr className={"tr_student row"} role="row"> 
                            {header}
                        </tr>
                    </thead>
                    <tbody className={"tbody_student"} role="rowgroup">
                       {renderRows(rows)}
                    </tbody>
                </table>
            </div>
        </div>
    )
    /*****************************/
    /****** END RENDER PART ******/
    /*****************************/
}

export default ShortTermData;