import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentSubjectArea';
import { graphql } from 'react-apollo';
import GlobalVar from '../../../../GlobalVar';

class SubjectArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}

	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};

		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		filters["subjectArea"] = event.target.value;
		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}

	renderSubjectArea(){
		let studyListObject = {};
		GlobalVar.studyList.map((data) => {
			const {code, text} = data;
			studyListObject[code] = text;
		});

		return this.props.data.getStudentSubjectArea.map(({code, count}, index) => {
			let extractedName = code;
			let extractedValue = code;
			if(extractedName == ""){
				extractedName = "Not found";
				extractedValue = "Not found";
			}
			else{
				if(studyListObject[code]){
					extractedName = `${code} - ${studyListObject[code]}`;
				}
				else{
					return;
				}
			}
			return <option key={index} value={extractedValue} >{extractedName} ({count})</option>;
		})
	}
	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			this.setState({
				value: filters["subjectArea"]
			})
		}
		else{
			this.setState({
				value: ""
			})
		}
	}

	render() {
		//console.log(GlobalVar.studyList);

		/*
    return (
      <select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
        <option value={""}>Not selected</option>
      </select>
    )*/
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
      return (
        <select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={""}>Not selected</option>
					{this.renderSubjectArea()}
        </select>
      )
  	}
  }
};


export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			CountryName: props.CountryID,
			InstitutionName: props.InstitutionID,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			TypeOfSigning: props.typeOfSigning,
			Uploaded: props.Uploaded,
			AcademicYear: props.AcademicYear,
			StudyCycle: props.StudyCycle,
			SignedBefore:  Number(props.SignedBefore),
			SignedDuring:  Number(props.SignedDuring)
		}
	}}
})(SubjectArea);
