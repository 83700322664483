import gql from 'graphql-tag';

const query = gql`
  query{
    tutorialVideos{
      id
      description
      title
      youtubeID
    }
  }
`
export { query };