import React, { Component } from 'react';
import { query } from '../../../queries/getFrequentlyAskedQuestions';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ReactQuill , { Quill, Mixin, Toolbar } from 'react-quill';

class SingleTutorial extends Component {
	constructor(props){
		super(props);
		const {description, title, youtubeID} = props.data;
		this.state = {
			description,
			title,
			youtubeID
		}
	}

	changeValue(event, stateName){
		event.preventDefault();
		console.log(event.target.value);
		this.setState({
			[stateName]: event.target.value
		})
	}

	saveValue(mutationName, stateNameValue){
		console.log(this.state[stateNameValue]);

		this.props[mutationName]({
			variables:{
				id: this.props.data.id,
				[stateNameValue]: this.state[stateNameValue]
			}
		})
	}

	render() { 
		return (
			<div className="single_tutorial_edit">
				<input value={this.state.title} onChange={(event) => this.changeValue(event, "title")} onBlur={() => this.saveValue("updateTitleTutorialVideo", "title")} type="text" placeholder="title" />
				<input value={this.state.youtubeID} onChange={(event) => this.changeValue(event, "youtubeID")} onBlur={() => this.saveValue("updateYoutubeIDTutorialVideo", "youtubeID")} type="text" placeholder="youtube identifier" />
				<ReactQuill className="html_textarea" value={this.state.description} onChange={(event) => this.setState({description: event})}  onBlur={() => this.saveValue("updateDescriptionTutorialVideo", "description")} theme={'snow'}  placeholder="description" />
			</div>
		);
	} 
}


const updateTitleTutorialVideo = gql`
	mutation updateTitleTutorialVideo($id: ID!, $title: String){
		updateTitleTutorialVideo(id: $id, title: $title){
			title
		}
	}
`

const updateDescriptionTutorialVideo = gql`
	mutation updateDescriptionTutorialVideo($id: ID!, $description: String){
		updateDescriptionTutorialVideo(id: $id, description: $description){
			description
		}
	}
`
const updateYoutubeIDTutorialVideo = gql`
	mutation updateYoutubeIDTutorialVideo($id: ID!, $youtubeID: String){
		updateYoutubeIDTutorialVideo(id: $id, youtubeID: $youtubeID){
			youtubeID
		}
	}
`


export default compose(
	graphql(updateTitleTutorialVideo, { name: 'updateTitleTutorialVideo'}),
	graphql(updateDescriptionTutorialVideo, { name: 'updateDescriptionTutorialVideo'}),
	graphql(updateYoutubeIDTutorialVideo, { name: 'updateYoutubeIDTutorialVideo'}),
)(SingleTutorial);
