import gql from 'graphql-tag';
const query = gql`
	query getCities($CountryLink: String){
	  cities(CountryLink: $CountryLink){
	  	ID
	    CityName
	    CityLink
	  }
	}
`;
export { query };