import React, { Component } from "react";

class Sorting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            direction: this.props.direction
        }

        this.changeSelection = this.changeSelection.bind(this);
    }

    changeSelection(event, isDirection) {
        if (isDirection) { 
            this.setState({ direction: event.target.value }); 
            this.props.handlerChange(event, "sortDirection");
        }
        else { 
            this.setState({ value: event.target.value }); 
            this.props.handlerChange(event, "sortField");
        }
    }

    render() {
        // if (this.props.data.loading) {
        //     return <select><option value="">Loading...</option></select>
        // }
        return (
            <React.Fragment>
                <select name="iia-sorting" id="iia-sorting" value={this.state.value} onChange={(event) => this.changeSelection(event, false)} >
                    {/* <option value="">Select a field to sorting</option> */}
                    <option value="iia_id_p1" selected>IIA ID</option>
                    <option value="inEffect">Status</option>
                    <option value="creator_contact">Creator EC</option>
                    <option value="partner_contact">Partner Contact</option>
                    <option value="creator_signature_date">Creator Sign Date</option>
                    <option value="creator_signature_contact">Creator Sign Contact</option>
                    <option value="partner_signature_date">Partner Sign Date</option>
                    <option value="partner_signature_contact">Partner Sign Contact</option>
                </select>
                <select name="iia-sorting-direction" id="iia-sorting-direction" value={this.state.direction} onChange={(event) => this.changeSelection(event, true)}>
                    <option value="ASC" selected>Ascending</option>
                    <option value="DESC">Descending</option>
                </select>
            </React.Fragment>
        );
    }
}

export default Sorting;