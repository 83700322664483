import gql from 'graphql-tag';

const query = gql`
query($HEC: String, $typeOfStudent: Int, $page: Int, $firstYear: String, $secondYear: String) {
    shortTermMobilities(HEC: $HEC, typeOfStudent: $typeOfStudent, page: $page, firstYear: $firstYear, secondYear: $secondYear) {
        shortTermList {
            shortTermID
            title
            field_la_status
            field_planned_period_from
            field_planned_period_to
            field_student_firstname
            field_student_lastname
            field_dashboard_student_email
            sending_institution
            receiving_institution
            field_academic_year
            sending_institution_hei
            receiving_institution_hei
            sending_institution_country
            receiving_institution_country
            sending_institution_faculty
            receiving_institution_faculty
        }
        nextLink
        page
    }
}`

export { query };
