import React, { Component } from 'react';
import HelpBox from './HelpBox';

class Help extends Component {
  	constructor(props) {
		super(props);
		this.state = {
      		showHelpErasmusCode: false
		}
  	}

  	generateHelpBox(){
		if(this.state.showHelpErasmusCode){
      		const {title, description} = this.props;
			return <HelpBox title={title} description={description} top={this.props.top}/>;
		}
	}

  	showHelpBox(event){
        event.preventDefault();
		if(!this.state.showHelpErasmusCode){
			this.setState({
				showHelpErasmusCode: true
			})
		}
	}
	
	closeHelpBox(event){
		event.preventDefault();
		if(this.state.showHelpErasmusCode){
			this.setState({
				showHelpErasmusCode: false
			})
		}
	}

	render() {
		return (
      		<div className="help_wrapper_parrent">
	        	<input 
	        		onFocus={(event) => this.showHelpBox(event)} 
	        		onBlur={(event) => this.closeHelpBox(event)} 
	        		onMouseLeave={(event) => this.closeHelpBox(event)} 
	        		onMouseEnter={(event) => this.showHelpBox(event)} 
	        		className="help_button" 
	        		type="image" src="/icons/questionMark-blue.svg" />
        		{this.generateHelpBox()}
      		</div>
		)
	}
};

// onFocus={(event) => this.showHelpBox(event)} onBlur={() => this.closeHelpBox()} onMouseLeave={() => this.closeHelpBox()} onMouseEnter={() => this.showHelpBox()}

export default Help;
