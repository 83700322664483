import gql from 'graphql-tag';

const query = gql`
	query getStudentCountries($Sending: Int, $SearchQuery: String, $StartMobility: String, $EndMobility: String, $SignedBefore: Int, $SignedDuring: Int, $Uploaded: String, $AcademicYear: String, $StudyCycle: String, $SubjectArea: String){
	  	getStudentCountries(UnKnownEmail:"${localStorage.getItem('unknownEmail')}",ErasmusCode: "${localStorage.getItem('ErasmusCode')}", Sending:$Sending, SearchQuery: $SearchQuery, StartMobility: $StartMobility, EndMobility: $EndMobility, SignedBefore: $SignedBefore, SignedDuring: $SignedDuring, Uploaded: $Uploaded, AcademicYear: $AcademicYear, StudyCycle: $StudyCycle, SubjectArea: $SubjectArea){
		    CountryName
		    count
		}
	}
`
export { query };
