import React, { Component } from 'react';
import { query } from '../../../queries/getSingleInstitutionRegistration';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

class InstitutionConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            error: false,
            title: ""
        }
    }

    login(event){
        event.preventDefault();
        if(this.validate()){
            this.props.mutate({
            variables: {
                id: this.props.match.params.institutionID,
                password: this.state.password
            }
            }).then((response) => {
                localStorage.clear();
                localStorage.setItem("ErasmusCode", GlobalVar.encryptWithKey(this.props.data.singleInstitutionRegistration.Institution.ErasmusCode));
                localStorage.setItem("Email", GlobalVar.encryptWithKey(this.props.data.singleInstitutionRegistration.Email));
  	        		GlobalVar.setDefaultLocalStorage();
                window.location.replace(GlobalVar.generateLinkUrl(intl, "My University", "General info"));
            })
        }
    }
    validate(){
        if(this.state.password === ""){
            this.setState({error: true, title: "The password can't be empty"})
            return false;
        }
        if(this.state.password.length < 8){
            this.setState({error: true, title: "The password needs at least 8 characters"})
            return false;
        }
        this.setState({error: false, title: "Valid input..."});
        return true;
    }
    getTitle(){
        if(this.state.error){
            return <h2 className="error">{this.state.title}</h2>;
        }
        else if(this.state.title !== ""){
            return <h2>{this.state.title}</h2>;
        }
        return <h2>Finalize your account</h2>;
    }

	render() {
        if(this.props.data.loading){
            return <div className="account_login card card_center"></div>;
        }
        else if(!this.props.data.singleInstitutionRegistration){
            return (
                <div className="account_login card card_center">
                    <h2>Institution not found</h2>

                </div>
            );
        }
        else{
            const {Email, Website, Phone, Institution, Valid } = this.props.data.singleInstitutionRegistration;
            if(Number(Valid) === -1){
                return (
                    <div className="intro">
                        <div className="intro_content">
                            <div className="account_confirmation card">
                                <h2>Declined registration</h2>
                            </div>
                        </div>
                    </div>
                )
            }
            else if(Number(Valid) === 0){
                return (
                    <div className="intro">
                        <div className="intro_content">
                            <div className="account_confirmation card">
                                <h2>Registration not validated</h2>
                            </div>
                        </div>
                    </div>
                )
            }
        	return (
                <div className="intro">
                    <div className="intro_content">
                		<div className="account_confirmation card">
                            {this.getTitle()}
                            <form onSubmit={(event) => this.login(event)} >
                                <div className="login_input_wrapper">
                                    <label>Login email</label>
                                    <p><a href={`mailto:${Email}`}>{Email}</a></p>
                                </div>

                                <div className="login_input_wrapper">
                                    <label>Registration phone number</label>
                                    <p>{Phone}</p>
                                </div>

                                <div className="login_input_wrapper">
                                    <label>Institution name</label>
                                    <p>{Institution.NameInLatinCharacterSet}</p>
                                </div>

                                <div className="login_input_wrapper">
                                    <label>New password (minimum 8 characters)</label>
                                    <input type="password" onChange={(event) => this.setState({password: event.target.value})} value={this.state.password} autoFocus placeholder="Minimum 8 characters" />
                                </div>
                                <button type="submit">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
          	);
        }
    }

};

const mutation = gql`
    mutation changePasswordRegisteredInstitution($id: ID, $password: String){
      changePasswordRegisteredInstitution(id: $id, password:$password){
        id
      }
    }
`;


export default graphql(mutation)(
    graphql(query, {
    options: (props) => {return {
        variables: { id: props.match.params.institutionID}
    }}})(InstitutionConfirmation)
);
