import React, { Component } from 'react';

class TextArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: this.props.value,
            error: false,
            errorMessage: "",
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        
        if (this.props.validation) {
            this.setValidationsErrors(this.props.name, this.props.value);
        }
    }

    render() {
        return (
            <div className={this.props.className} name={this.props.name} groupname={this.props.groupname}>
                <textarea
                    className={this.checkError()}
                    name={this.props.name}
                    groupname={this.props.groupname}
                    placeholder={this.props.placeHolder}
                    value={this.props.value}
                    onChange={(event) => this.onChangeHandler(event)}
                    readOnly={this.props.readOnly}
                />
                { this.generateErrorMessage() }
                { this.renderSearch() }
            </div>
        );
    }

    onChangeHandler(event) {
        if (event.keyCode == 13 && !event.shiftKey) {
            event.preventDefault();
        }

        const value = event.target.value;
        const name = event.target.name;
        this.setState({ searchValue: value });
        this.props.handleSelection({
			[name]: value
        });
        if (this.props.validation) {
            this.setValidationsErrors(name, value);
        }
    }

    renderSearch() {
        if (this.props.renderSearchBox) {
            return (
                <React.Fragment>
                    { this.props.renderSearchBox(this.state.searchValue) }
                </React.Fragment>
            );
        }
        return (<React.Fragment />);
    }

    setValidationsErrors(name, value) {
        let message = this.props.validation(name, value);
        if (message === "" && this.state.error) {
            this.setState({ error: false, errorMessage: "" });
        }
        else if (message !== "" && !this.state.error) {
            this.setState({ error: true, errorMessage: message });
        }
        
        if (message === "") { this.props.setErrors(name, false); }
        else { this.props.setErrors(name, true); }
    }

    checkError() {
        if (this.state.error) {
            return "error_field";
        }
        else {
            return "valid_field";
        }
    }
    
    generateErrorMessage() {
        if (this.state.error) {
            return <div className="error_message"><p>{this.state.errorMessage}</p></div>
        }
	}
}

export default TextArea;