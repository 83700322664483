import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import unregister from './registerServiceWorker';

ReactDOM.render(
	<App />,
	document.getElementById('render_target')
);
//we don't need a service worker
unregister();
