import React, { Component } from 'react';


class StatusFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}
	changeSelection(event){
		this.setState({
			value: event.target.value
        })
        this.props.onSelectedStatus(event.target.value)

    }

	renderSignedBy(printStatus, label){
        let doesExist = this.props.data.find((Status) => {
            return Status.field_la_status === label
        })
		if(doesExist){
			return <option value={label}>{printStatus}</option>;
		}
		return <option value={label} disabled>{printStatus}</option>;
    }


	render() {
            if(this.props.data.loading){
                return <select><option value="">Loading...</option></select>
            }
            else if( (this.props.typeOfStudent === 2 )){
                return (
                    <select id="status-filter" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                        <option value="">Select status</option>

                        {this.renderSignedBy("Changes unsigned", "final_unsigned")}
                        {this.renderSignedBy("Changes signed by student", "final_signed-by-student")}
                        {this.renderSignedBy("Changes signed by student/sending", "final_signed-by-student-sending")}
                        {this.renderSignedBy("Changes signed by student/sending/receiving", "final_signed-by-student-sending-receiving")}
                        {this.renderSignedBy("Signed by student/sending", "preliminary_signed-by-student-sending")}
                        {this.renderSignedBy("Signed by student", "preliminary_signed-by-student")}
                        {this.renderSignedBy("Signed by student/sending/receiving", "preliminary_signed-by-student-sending-receiving")}
                    </select>
                  );
            }
            else{
                return (
                    <select id="status-filter" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                        <option value="">Select status</option>
                        {this.renderSignedBy("Unsigned", "preliminary_unsigned")}
                        {this.renderSignedBy("Changes unsigned", "final_unsigned")}
                        {this.renderSignedBy("Changes signed by student", "final_signed-by-student")}
                        {this.renderSignedBy("Changes signed by student/sending", "final_signed-by-student-sending")}
                        {this.renderSignedBy("Changes signed by student/sending/receiving", "final_signed-by-student-sending-receiving")}
                        {this.renderSignedBy("Signed by student/sending", "preliminary_signed-by-student-sending")}
                        {this.renderSignedBy("Signed by student", "preliminary_signed-by-student")}
                        {this.renderSignedBy("Signed by student/sending/receiving", "preliminary_signed-by-student-sending-receiving")}
                    </select>
                  );
              }

    }

};

export default StatusFilter;
