import React, { Component } from 'react';
import StatusFilter from "./filters/StatusFilter";
import AcademicYearFilter from './filters/AcademicYearFilter';
import CreatedByFilter from "./filters/CreatedByFilter";
import HeiFilter from './filters/HeiFilter';
import SearchFilter from "./filters/SearchFilter";
import CountryFilter from './filters/CountryFilter';

function Options(props) {
  
  const resetFilters = (val) => {
    props.reset(val)
  }
  
  const handleFilterSearch = (val) => {
    props.SearchFilter(val)
  }

  const handleFilterStatus = (val) => {
    props.StatusFilter(val)
  }

  const handleFilterCreatedBy = (val) => {
    props.CreatedByFilter(val)
  }

  const handleFilterAcademicYear = (val) => {
    props.academicYearValue(val)
  }

  const handleFilterHei = (val) => {
    props.heiValue(val)
  }

  const handleFilterCountry = (val) => {
    props.countryValue(val)
  }

	return (
    <div className="card card_full_width card_full_height">
    <h4 className={"row"} style={{color: "#4A90E2", marginLeft: "10px"}}>Select a filter for the table</h4>
    <br/>
    <div className="option_wrapper">
      <SearchFilter onChange={(event) => this.changeValue(event)} data={props.data.shortTermList} onSelectedSearch={handleFilterSearch}/>
      <StatusFilter onChange={(event) => this.changeValue(event)} data={props.data.shortTermList} typeOfStudent={props.data.typeOfStudent} onSelectedStatus={handleFilterStatus} />
      <AcademicYearFilter 
        onChange={(event) => this.changeValue(event)} 
        data={props.data.shortTermList} 
        typeOfStudent={props.data.typeOfStudent} 
        onSelectedAcademicYear={handleFilterAcademicYear}
        firstYear={props.firstYear}
        secondYear={props.secondYear} />
      <HeiFilter onChange={(event) => this.changeValue(event)} data={props.data.shortTermList} typeOfStudent={props.data.typeOfStudent} onSelectedHEI={handleFilterHei} />
      <CountryFilter onChange={(event) => this.changeValue(event)} data={props.data.shortTermList} typeOfStudent={props.data.typeOfStudent} onSelectedCountry={handleFilterCountry}/>
      {(props.typeOfStudent === 1)&&<CreatedByFilter  onChange={(event) => this.changeValue(event)} data={props.data.shortTermList} onSelectedCreatedBy={handleFilterCreatedBy} />}
      <div>
        <button onChange={(event) => this.changeValue(event)} onClick={resetFilters}>Reset</button>
        <p className="num_of_las">Number of Short-Term Mobilities: {props.length}</p>
      </div>                  
     </div>
       <br/>
       <ul className="instructions">
          <h4 className={"row"} style={{color: "#4A90E2"}}>Instructions</h4>
          <li>Search bar to filter student name, start/end mobility, the coordinators, emails of all involved, faculty names and HEI name.</li>
      </ul> 
    
    </div>
	);

};
export default Options;
