import gql from 'graphql-tag';

const query = gql`
query SingleAccount($id: ID){
  singleAccount(id: $id){
    AccountFunction{
      AccountAbility{
        Value
      }
    }
  }
}`

export { query };