import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import GlobVar from '../../../GlobalVar';
import Title from '../common/Title';
import TextArea from '../common/TextArea';
import Select from '../common/Select';
import Label from '../common/Label';
import Validator from '../common/Validator';
import Input from '../common/Input';

import { query as getLanguages } from '../../../queries/getLanguages';
import { query as getEQF } from '../../../queries/getEQF';

/**
 * This component creates the page that defines the cooperation conditions, such as the sender 
 * and receiver institutions, the academic years of mobility, number of mobilities per year, 
 * CEFR levels, ISCED-F code, languages skills and much more information.
 * Updates are made by the parent, storing the data in localStorage "coopConditions" and 
 * calling back the refreshPage function passed as prop from IIACreation.
 */
class Condition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshPage: false,
            disableComponents: true,
            addButtonDisabled: false,
            removeButtonDisabled: false,
            showMobFields: props.showMobFields
        }

        this.handleSelection = this.handleSelection.bind(this);
        this.handleObject = this.handleObject.bind(this);
        this.handleTextArea = this.handleTextArea.bind(this);
        this.handleEnableDisable = this.handleEnableDisable.bind(this);
        this.handleEnableDisableLanguage = this.handleEnableDisableLanguage.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.refreshButton = this.refreshButton.bind(this);
        this.changeBlended = this.changeBlended.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.handleSendingOUSelection = this.handleSendingOUSelection.bind(this);
        this.handleReceivingOUSelection = this.handleReceivingOUSelection.bind(this);
    }

    componentDidMount() {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        this.handleEnableDisable(coopConditions[auxInfo.pageNumber - 1].languageSelected, "languageSelected");
        this.handleEnableDisable(coopConditions[auxInfo.pageNumber - 1].languagesAddedSelection, "languagesAdded");
        this.handleEnableDisable(coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected, "mobilityTypeSelected");

        let mobType = coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected;
        if (mobType === "") {
            this.handleEnableDisable("", "mobilityTypeSelected");
        }
        else if (mobType === "Student studies" || mobType === "Student traineeship") {
            this.handleEnableDisable(coopConditions[auxInfo.pageNumber - 1].totalMonths, "totalMonths");
        }
        else {
            this.handleEnableDisable(coopConditions[auxInfo.pageNumber - 1].totalDays, "totalDays");
        }
    }

    render() {
        let pd = JSON.parse(localStorage.getItem("partnersDetails"));
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let personalizatedTitle = "Cooperation conditions (page " + auxInfo.pageNumber + ")";
        return (
            <div className={this.props.className}>
                <Title text="Terms of the agreement to be set for each agreement and approved by the institutions (Information only accessible to the relevant parties)." />
                <Title text={personalizatedTitle} />
                { this.renderMobilitySpec(pd, coopConditions, auxInfo)}
            </div>
        );
    }

    /********************/
    /* RENDER FUNCTIONS */
    /********************/
    renderMobilitySpec(pd, coopConditions, auxInfo) {
        return (
            <div className="custom_table" id="create_IIA" style={{width: "auto"}}>
                <hr />
                { this.renderTopTextRow() }
                { this.renderInstitutionRow(coopConditions, auxInfo) }
                { this.renderOUnitsRow(pd, coopConditions, auxInfo) }
                { this.renderMobilitiesRow(coopConditions, auxInfo) }
                { this.renderMobilityTypeRow(coopConditions, auxInfo) }
                { this.renderBlendedMobility(coopConditions, auxInfo) }
                { this.renderLanguagesRow(coopConditions, auxInfo) }
                { this.renderOtherInformation(coopConditions, auxInfo) }
            </div>
        );
    }

    /**
     * **Sender Institution** and **Receiver Institution** fields.
     * @param {Object[]]} coopConditions 
     * @param {Object} auxInfo 
     */

    renderTopTextRow() {
        return (
            <React.Fragment>
                <div className="row header_row">
                    <h3>Mobility numbers per academic year</h3>
                    <p>&nbsp;</p>
                    <p style={{ fontWeight: "bold" }}>The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year.</p>
                </div>
            </React.Fragment>
        );
    }

    renderInstitutionRow(coopConditions, auxInfo) {
        let institutions = [this.props.institutions[0], this.props.institutions[1]];
        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Sender Erasmus Code" />
                        <Select
                            name="senderInstitution"
                            multiple={false}
                            options={institutions}
                            value={coopConditions[auxInfo.pageNumber - 1].senderInstitution}
                            handleSelection={this.handleSelection}
                            refreshPage={this.refreshPage}
                            defaultMessage="(Required field)"
                        />
                    </div>
                    <div className="column">
                        <Label text="Receiver Erasmus Code" />
                        <TextArea
                            name="receiverInstitution"
                            value={coopConditions[auxInfo.pageNumber - 1].receiverInstitution}
                            placeHolder="(Required field)"
                            validation={this.getValidation}
                            readOnly={true}
                            setErrors={this.props.setErrors}
                        />
                    </div>

                </div>
            </React.Fragment>
        );
    }

    /**
     * (Optional) Selection of the **Sender OUnit** and the **Receiver OUnit**.
     * 
     * @param {Object[]} coopConditions 
     * @param {Object} auxInfo 
     */
    renderOUnitsRow(pd, coopConditions, auxInfo) {
        if (coopConditions[auxInfo.pageNumber - 1].senderInstitution === "") {
            return (
                <React.Fragment>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Sending Organizational Unit" />
                            <TextArea
                                name="sendingOUnit"
                                value=""
                                placeHolder="Select a Sender Erasmus Code first, please"
                                readOnly={true}
                            />
                        </div>
                        <div className="column">
                            <Label text="Receiving Organizational Unit" />
                            <TextArea
                                name="receivingOUnit"
                                value=""
                                placeHolder="Select a Sender Erasmus Code first, please"
                                readOnly={true}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else if (coopConditions[auxInfo.pageNumber - 1].senderInstitution === JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode) {
            // let creatorOUnitSelected = [];
            // let partnerOUnitSelected = [];
            // if (pd.creatorOUnit != "") { 
            //     creatorOUnitSelected.push(auxInfo.creatorOUnitList.find((ounit) => ounit.ounit_id === pd.creatorOUnit));
            // }
            // if (pd.partnerOUnit != "") {
            //     partnerOUnitSelected.push(auxInfo.partnerOUnitList.find((ounit) => ounit.ounit_id === pd.partnerOUnit));
            // }

            return (
                <React.Fragment>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Sending Organizational Unit" />
                            <Select
                                name="sendingOUnit"
                                handleSelection={this.handleSendingOUSelection}
                                value={coopConditions[auxInfo.pageNumber-1].sendingOUnit}
                                defaultValue={coopConditions[auxInfo.pageNumber-1].sendingOUnit}
                                multiple={false}
                                ounits={true}
                                options={auxInfo.creatorOUnitList}
                                defaultMessage="Select OUnit"
                                refreshPage={this.props.refreshPage}
                            />
                        </div>
                        <div className="column">
                            <Label text="Receiving Organizational Unit" />
                            <Select
                                name="receivingOUnit"
                                handleSelection={this.handleReceivingOUSelection}
                                value={coopConditions[auxInfo.pageNumber-1].receivingOUnit}
                                defaultValue={coopConditions[auxInfo.pageNumber-1].receivingOUnit}
                                multiple={false}
                                ounits={true}
                                options={auxInfo.partnerOUnitList}
                                defaultMessage="Select OUnit"
                                refreshPage={this.props.refreshPage}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            // let creatorOUnitSelected = [];
            // let partnerOUnitSelected = [];
            // if (pd.creatorOUnit != "") { 
            //     creatorOUnitSelected.push(auxInfo.creatorOUnitList.find((ounit) => ounit.ounit_id === pd.creatorOUnit));
            // }
            // if (pd.partnerOUnit != "") {
            //     partnerOUnitSelected.push(auxInfo.partnerOUnitList.find((ounit) => ounit.ounit_id === pd.partnerOUnit));
            // }

            return (
                <React.Fragment>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Sending Organizational Unit" />
                            <Select
                                name="sendingOUnit"
                                handleSelection={this.handleSendingOUSelection}
                                value={coopConditions[auxInfo.pageNumber-1].sendingOUnit}
                                defaultValue={coopConditions[auxInfo.pageNumber-1].sendingOUnit}
                                multiple={false}
                                ounits={true}
                                options={auxInfo.partnerOUnitList}
                                defaultMessage="Select OUnit"
                                refreshPage={this.props.refreshPage}
                            />
                        </div>
                        <div className="column">
                            <Label text="Receiving Organizational Unit" />
                            <Select
                                name="receivingOUnit"
                                handleSelection={this.handleReceivingOUSelection}
                                value={coopConditions[auxInfo.pageNumber-1].receivingOUnit}
                                defaultValue={coopConditions[auxInfo.pageNumber-1].receivingOUnit}
                                multiple={false}
                                ounits={true}
                                options={auxInfo.creatorOUnitList}
                                defaultMessage="Select OUnit"
                                refreshPage={this.props.refreshPage}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    /**
     * **Academic Years**, **Mobilities Per Year** and **ISCED-F Code**.
     * @param {Object[]} coopConditions 
     * @param {Object} auxInfo 
     */
    renderMobilitiesRow(coopConditions, auxInfo) {
        if (!this.props.getIscedFCodesList.loading) {
            let iscedFCode = [];
            {
                this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                    return (iscedFCode.push(data.code + " - " + data.name));
                })
            }

            return (
                <React.Fragment>
                    <div className="row header_row">
                        <div className="column">
                            <Label text="Mobilities Per Year" />
                            <TextArea
                                name="mobilitiesPerYear"
                                placeHolder="(Required) Maximum number of people to be sent for each academic year"
                                validation={this.getValidation}
                                value={coopConditions[auxInfo.pageNumber - 1].mobilitiesPerYear}
                                handleSelection={this.handleSelection}
                                setErrors={this.props.setErrors}
                            />
                        </div>
                    </div>
                    <div className="row header_row" style={{ marginTop: "10px" }}>
                        <h3>Subject area</h3>
                    </div>
                    {coopConditions[auxInfo.pageNumber-1].iscedFCode.map((value, index) => {
                        let clarificationDisabled = (value.code === "") ? true : false;

                        return (
                            <div className="row header_row">
                                <div className="column">
                                    <Label text="ISCED-F Code" />
                                     <select 
                                        name={"iscedFCode"+index}
                                        id={"iscedFCode"+index}
                                        value={value.code} 
                                        onChange={(event) => this.handleObject("iscedFCode"+index, event, index)}
                                    >
                                        <option key="code" value=""/>
                                        {iscedFCode.map((value, index) => {
                                            return <option key={"code"+index} value={value.split(" - ")[0]}>{`${value}`}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="column">
                                    <Label text="ISCED - Clarification" />
                                    <textarea
                                        name={"iscedClarification"+index}
                                        id={"iscedClarification"+index}
                                        style={{ height: "auto", width: "200%", whiteSpace: "break-spaces" }}
                                        placeHolder="Here you can set more details regarding the subject area (5000 characters max)"
                                        onChange={(event) => this.handleObject("iscedClarification", event, index)}
                                        rows="2"
                                        maxLength="5000"
                                        value={value.clarification}
                                        disabled={clarificationDisabled}
                                    />
                                </div>
                                <div className="column">
                                </div>
                                <div className="column">
                                    <button style={{ marginTop: "20px" }} onClick={() => this.deleteObject("iscedFCode", index)}>Delete</button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="column">
                        <button onClick={() => this.addNewObject("iscedFCode")}>Add</button>  
                    </div>
                </React.Fragment>
            );
        }
    }

    /**
     * **Mobility Type**, **Average Months**, **Total Months**, **Average Days**, **Total Days** and **EQF Level**.
     * @param {Object[]} coopConditions 
     * @param {Object} auxInfo 
     */
    renderMobilityTypeRow(coopConditions, auxInfo) {
        const mobTypes = ["Student studies", "Student traineeship", "Staff teacher", "Staff training"];
        const eqfLevels = [];
        if (!this.props.allEQF.loading) {
            console.log("AllEQF: ", this.props.allEQF)
            this.props.allEQF.allEQF.map((data) => {
                eqfLevels.push(data.Code);
            });
        }

        return (
            <React.Fragment>
                <div className="row header_row">
                    <div className="column">
                        <Label text="Mobility Type" />
                        <Select
                            name="mobilityTypeSelected"
                            multiple={false}
                            options={mobTypes}
                            value={coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected}
                            handleSelection={this.handleSelection}
                            handleEnableDisable={this.handleEnableDisable}
                            defaultMessage="(Required field)"
                            refreshPage={this.refreshPage}
                        />
                    </div>
                    <div className="column">
                        <Label name="totalMonths" groupname="sms" text="Total Months Per Year Per Mobility" />
                        <TextArea
                            name="totalMonths"
                            groupname="sms"
                            placeHolder="(Required) Put a number"
                            validation={this.getValidation}
                            value={coopConditions[auxInfo.pageNumber - 1].totalMonths}
                            handleSelection={this.handleSelection}
                            setErrors={this.props.setErrors}
                        />
                        <Label name="totalDays" groupname="stms" text="Total Days Per Year Per Mobility" />
                        <TextArea
                            name="totalDays"
                            groupname="stms"
                            placeHolder="(Required) Put a number"
                            validation={this.getValidation}
                            value={coopConditions[auxInfo.pageNumber - 1].totalDays}
                            handleSelection={this.handleSelection}
                            setErrors={this.props.setErrors}
                        />
                    </div>
                    <div className="column" groupname="eqf">
                        <Label groupname="eqf" text="Study Cycle" />
                        <Input
                            name="EQF 5"
                            type="checkbox"
                            text="Short cycle (EQF 5)"
                            checked={coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes("EQF 5") ? true : false}
                            onChange={this.inputOnChange}
                        />
                        <Input
                            name="EQF 6"
                            type="checkbox"
                            text="1st cycle (EQF 6)"
                            checked={coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes("EQF 6") ? true : false}
                            onChange={this.inputOnChange}
                        />
                        <Input
                            name="EQF 7"
                            type="checkbox"
                            text="2nd cycle (EQF 7)"
                            checked={coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes("EQF 7") ? true : false}
                            onChange={this.inputOnChange}
                        />
                        <Input
                            name="EQF 8"
                            type="checkbox"
                            text="3rd cycle (EQF 8)"
                            checked={coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes("EQF 8") ? true : false}
                            onChange={this.inputOnChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderBlendedMobility(coopConditions, auxInfo) {
        if (coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student studies" || coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student traineeship") {
            return (
                <React.Fragment>
                    <div className="row">
                        <p style={{ fontWeight: "bold" }}>
                            <Input
                                type="checkbox"
                                text="Blended mobility option for students"
                                name="autoRec"
                                checked={coopConditions[auxInfo.pageNumber - 1].blended === 1 ? true : false}
                                onChange={this.changeBlended} />
                        </p>
                        <p>&nbsp;</p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>By checking this box, the partners confirm that they are willing to exchange students who wish to carry out their mobility in a blended format, a combination of a short-term physical mobility with a virtual component.</p>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return;
        }
    }

    /**
     * **Recommended Languages**, **CEFR Level**, **Languages Added** and **Add** and **Remove** buttons.
     * @param {Object[]} coopConditions 
     * @param {Object} auxInfo 
     */
     renderLanguagesRow(coopConditions, auxInfo) {
        let languages = this.generateLanguagesList();
        let cefrLevels = [];
        GlobVar.cefrLevel.map((data) => {
            cefrLevels.push(data.code);
        });
        let cefrDefaultMessage = "";
        if (coopConditions[auxInfo.pageNumber - 1].languageSelected === "") { cefrDefaultMessage = "(Select a language to add)"; }

        let minLangLevelMessage = "";
        if (coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student studies" || coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected === "Student traineeship") {
            minLangLevelMessage = "(Student Mobility for Studies - Minimum recommended level: B1)";
        }
        else {
            minLangLevelMessage = "(Staff Mobility for Teaching: Minimum recommended level: B2)"
        }

        if (!this.props.getIscedFCodesList.loading) {
            let iscedFCodes = [];
            {
                this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                    return (iscedFCodes.push(data.code + " - " + data.name));
                });
            }

            return (
                <React.Fragment>
                    <hr />
                    <div className="row header_row" style={{ marginTop: "10px" }}>
                        <h3>Recommended language skills</h3>
                        <p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                    </div>
                    {coopConditions[auxInfo.pageNumber-1].languageSkill.map((value, index) => {
                        let clarificationDisabled = (value.iscedFCode === "") ? true : false;

                        return(
                            <div className="row header_row">
                                <div className="column">
                                    <Label newLine={true} text="Language of instruction" />
                                    <select     // Languages
                                        name={"language"+index}
                                        id={"language"+index}
                                        value={value.language}
                                        onChange={(event) => this.handleObject("language"+index, event, index)}
                                    >
                                        <option key="lang" value="" />
                                        {languages.map((value, index) => {
                                            if (value.includes("00")) {
                                                return <option key={"lang" + index} value={`${value}`} disabled>{`${value}`}</option>    
                                            }
                                            return <option key={"lang" + index} value={`${value}`}>{`${value}`}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="column">
                                    <Label text="Recommended language(s) of instruction level" />
                                    <select     // CEFR level
                                        name={"cefrLevel"+index}
                                        id={"cefrLevel"+index}
                                        value={value.cefrLevel}
                                        onChange={(event) => this.handleObject("cefrLevel"+index, event, index)}
                                    >
                                        <option key="cefr" value="" />
                                        {cefrLevels.map((value, index) => {
                                            return <option key={"cefr" + index} value={`${value}`}>{`${value}`}</option>
                                        })}
                                    </select>
                                    <p>{minLangLevelMessage}</p>
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="ISCED-F Code" />
                                    <select
                                        name={"iscedFCodeLang" + index}
                                        id={"iscedFCodeLang" + index}
                                        value={value.iscedFCode}
                                        onChange={(event) => this.handleObject("iscedFCodeLang" + index, event, index)}
                                    >
                                        <option key="codeLang" value="" />
                                        {iscedFCodes.map((value, index) => {
                                            return <option key={"codeLang" + index} value={value.split(" - ")[0]}>{`${value}`}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="column">
                                    <Label newLine={true} text="ISCED - Clarification" />
                                    <textarea
                                        name={"iscedClarificationLang"+index}
                                        id={"iscedClarificationLang"+index}
                                        style={{ height: "auto", width: "200%", whiteSpace: "break-spaces" }}
                                        placeHolder="Here you can set more details regarding the subject area (5000 characters max)"
                                        onChange={(event) => this.handleObject("iscedClarificationLang", event, index)}
                                        rows="2"
                                        maxLength="5000"
                                        value={value.clarification}
                                        disabled={clarificationDisabled}
                                    />
                                </div>
                                <div className="column">
                                </div>
                                <div className="column">
                                    <br/>
                                    <button style={{ marginTop: "20px" }} onClick={() => this.deleteObject("languageSkill", index)}>Delete</button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="column">
                        <button onClick={() => this.addNewObject("languageSkill")}>Add</button>  
                    </div>
                </React.Fragment>
            );
        }
    }

    renderOtherInformation(coopConditions, auxInfo) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <Label text="Any other information regarding the terms of the agreement"/>
                </div>
                <div className="row" style={subContentStyle}>
                    <textarea
                        name="otherInformation"
                        style={{height: "auto", whiteSpace: "break-spaces"}}
                        placeholder="Write here any other information to be included in the agreement (5000 characters max)"
                        value={coopConditions[auxInfo.pageNumber-1].otherInformation}
                        onChange={(event) => this.handleTextArea(event)}
                        rows="5"
                        maxLength="5000"
                    /> 
                </div>
            </React.Fragment>
        );
    }

    /**********************/
    /* AUXILIAR FUNCTIONS */
    /**********************/
    generateLanguagesList() {
        let languages = [];

        if (!this.props.allLanguages.loading && !this.props.allLanguages.error) {
            languages.push("Most commonly spoken European languages - 00");

            GlobVar.mostSpokenLanguages.map((data) => {
                languages.push(data.Name + " - " + data.Code);
            });

            languages.push("Other Languages - 00");

            this.props.allLanguages.allLanguages.map((data) => {
                if (!languages.includes(data.Name + " - " + data.Code)) {
                    languages.push(data.Name + " - " + data.Code);
                }
            });
        }

        return languages;
    }

    changeBlended(event) {
        const { name, value } = event.target;

        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (document.getElementsByName(name)[0].checked) { coopConditions[auxInfo.pageNumber - 1].blended = 1; }
        else { coopConditions[auxInfo.pageNumber - 1].blended = 0; }

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    inputOnChange(event) {
        const { name, value } = event.target;

        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (document.getElementsByName(name)[0].checked) {
            if (!coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes(name)) {
                coopConditions[auxInfo.pageNumber - 1].eqfLevels.push(name);
            }
        }
        else {
            if (coopConditions[auxInfo.pageNumber - 1].eqfLevels.includes(name)) {
                let array = this.arrayRemove(coopConditions[auxInfo.pageNumber - 1].eqfLevels, name);
                coopConditions[auxInfo.pageNumber - 1].eqfLevels = array.filter(el => { return el != null });
            }
        }

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    handleObject(name, event, index) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (name === "iscedFCode"+index) {
            let sel = document.getElementById(name);

            coopConditions[auxInfo.pageNumber - 1].iscedFCode[index].code = sel.value;
            coopConditions[auxInfo.pageNumber - 1].iscedFCode[index].clarification = (sel.value === "" || sel.value === null || sel.value === undefined) ? "" : sel.options[sel.selectedIndex].text.split(" - ")[1];
            this.handleEnableDisableLanguage(document.getElementById(name).value, name, index);
        }
        else if (name === "iscedClarification") {
            coopConditions[auxInfo.pageNumber - 1].iscedFCode[index].clarification = event.target.value;
        }
        else if (name === "language"+index) {
            coopConditions[auxInfo.pageNumber - 1].languageSkill[index].language = document.getElementById(name).value;
            this.handleEnableDisableLanguage(document.getElementById(name).value, "language"+index, index);
        }
        else if (name === "cefrLevel"+index) {
            coopConditions[auxInfo.pageNumber - 1].languageSkill[index].cefrLevel = document.getElementById(name).value; 
        }
        else if (name === "iscedFCodeLang"+index) {
            let sel = document.getElementById(name);

            coopConditions[auxInfo.pageNumber - 1].languageSkill[index].iscedFCode = sel.value;
            coopConditions[auxInfo.pageNumber - 1].languageSkill[index].clarification = (sel.value === "" || sel.value === null || sel.value === undefined) ? "" : sel.options[sel.selectedIndex].text.split(" - ")[1];
        }
        else if (name === "iscedClarificationLang") {
            coopConditions[auxInfo.pageNumber - 1].languageSkill[index].clarification = event.target.value;
        }

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    handleTextArea(event) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        coopConditions[auxInfo.pageNumber - 1][event.target.name] = event.target.value;
        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));

        this.refreshPage();
    }

    handleSelection(selection) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        Object.keys(selection).map((key, index) => {
            if (key === "senderInstitution") {
                if (selection[key] === "") {
                    coopConditions[auxInfo.pageNumber - 1].receiverInstitution = "";
                    coopConditions[auxInfo.pageNumber - 1].sendingOUnit = "";
                    coopConditions[auxInfo.pageNumber - 1].receivingOUnit = "";
                }
                else if (selection[key] === this.props.institutions[0]) {
                    coopConditions[auxInfo.pageNumber - 1].receiverInstitution = this.props.institutions[1];
                    let aux = coopConditions[auxInfo.pageNumber - 1].sendingOUnit;
                    coopConditions[auxInfo.pageNumber - 1].sendingOUnit = coopConditions[auxInfo.pageNumber - 1].receivingOUnit;
                    coopConditions[auxInfo.pageNumber - 1].receivingOUnit = aux;
                }
                else {
                    coopConditions[auxInfo.pageNumber - 1].receiverInstitution = this.props.institutions[0];
                    let aux = coopConditions[auxInfo.pageNumber - 1].sendingOUnit;
                    coopConditions[auxInfo.pageNumber - 1].sendingOUnit = coopConditions[auxInfo.pageNumber - 1].receivingOUnit;
                    coopConditions[auxInfo.pageNumber - 1].receivingOUnit = aux;
                }
            }
            else if (key === "mobilityTypeSelected") {
                if (selection[key] !== coopConditions[auxInfo.pageNumber - 1].mobilityTypeSelected) {
                    coopConditions[auxInfo.pageNumber - 1].totalDays = "";
                    coopConditions[auxInfo.pageNumber - 1].totalMonths = "";
                    coopConditions[auxInfo.pageNumber - 1].eqfLevels.length = 0;
                }
                this.handleEnableDisable(selection[key], key);
            }
            else if (key === "totalMonths" || key === "totalDays") {
                this.handleEnableDisable(selection[key], key);
            }

            coopConditions[auxInfo.pageNumber - 1][key] = selection[key];
            localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        });
        this.refreshPage();
    }

    // handleMultipleSelection(selection) {
    //     let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
    //     let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
    //     Object.keys(selection).map((key, index) => {
    //         if (key === "languagesAdded") {
    //             let select = document.getElementsByName(key)[0];
    //             coopConditions[auxInfo.pageNumber - 1]["languagesAddedSelection"] = this.getSelectValues(select);
    //         }
    //         else {
    //             let select = document.getElementsByName(key)[0];
    //             coopConditions[auxInfo.pageNumber - 1][key] = this.getSelectValues(select);
    //         }
    //         this.handleEnableDisable(selection[key], key);
    //         localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
    //     });

    //     this.refreshPage();
    // }

    /**
     * @param {String} value - OUnit ID
     */
    handleSendingOUSelection(value) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        coopConditions[auxInfo.pageNumber-1].sendingOUnit = value;
        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    /**
     * @param {String} value - OUnit ID
     */
    handleReceivingOUSelection(value) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        coopConditions[auxInfo.pageNumber-1].receivingOUnit = value;
        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    getSelectValues(select) {
        let result = [];
        let options = select && select.options;
        let opt;

        for (let i = 0; i < options.length; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }

        return result;
    }

    addNewObject(fieldname) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        if (fieldname === "iscedFCode") { coopConditions[auxInfo.pageNumber-1].iscedFCode.push({code: "", clarification: ""}); }
        else if (fieldname === "languageSkill") { coopConditions[auxInfo.pageNumber-1].languageSkill.push({language: "", cefrLevel: "", iscedFCode: "", clarification: ""}); }

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    deleteObject(fieldname, index) {
        let coopConditions = JSON.parse(localStorage.getItem("coopConditions"));
        let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

        coopConditions[auxInfo.pageNumber-1][fieldname].splice(index, 1);

        localStorage.setItem("coopConditions", JSON.stringify(coopConditions));
        this.refreshPage();
    }

    getValidation(type, value) {
        const validator = new Validator(value);

        if (type === "mobilitiesPerYear" || type === "totalMonths" || type === "totalDays") {
            return validator.validateNumbers(true, 0, 10000);
        }
        else if (type === "receiverInstitution") {
            return "";
        }

        this.refreshPage();
    }

    // onSubmitAddHandler() {
    //     let cc = JSON.parse(localStorage.getItem("coopConditions"));
    //     let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

    //     if (cc[auxInfo.pageNumber - 1].languagesAdded.findIndex(element => element.includes(cc[auxInfo.pageNumber - 1].languageSelected)) < 0) {
    //         cc[auxInfo.pageNumber - 1].languagesAdded.push(cc[auxInfo.pageNumber - 1].languageSelected + " - " + cc[auxInfo.pageNumber - 1].cefrLevelSelected);
    //         cc[auxInfo.pageNumber - 1].languageSelected = "";
    //         cc[auxInfo.pageNumber - 1].cefrLevelSelected = "";
    //     }
    //     else {
    //         alert("The language selected was already added.\nLanguage: " + cc[auxInfo.pageNumber - 1].languageSelected + "\nCEFR level: " + cc[auxInfo.pageNumber - 1].cefrLevelSelected);
    //         cc[auxInfo.pageNumber - 1].languageSelected = "";
    //         cc[auxInfo.pageNumber - 1].cefrLevelSelected = "";
    //     }

    //     this.handleEnableDisable("", "languageSelected");
    //     localStorage.setItem("coopConditions", JSON.stringify(cc));
    //     this.refreshPage();
    // }

    // onSubmitRemoveHandler() {
    //     let cc = JSON.parse(localStorage.getItem("coopConditions"));
    //     let auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));

    //     cc[auxInfo.pageNumber - 1].languagesAddedSelection.map((value) => {
    //         let array = this.arrayRemove(cc[auxInfo.pageNumber - 1].languagesAdded, value);
    //         cc[auxInfo.pageNumber - 1].languagesAdded = array.filter(el => { return el != null });
    //     });
    //     cc[auxInfo.pageNumber - 1].languagesAddedSelection = [];

    //     this.handleEnableDisable("", "languagesAdded");
    //     localStorage.setItem("coopConditions", JSON.stringify(cc));
    //     this.refreshPage();
    // }

    handleEnableDisable(value, name) {
        if (name === "mobilityTypeSelected") {
            if (value === "") {
                this.hideElements(document.querySelectorAll('[groupname="eqf"], [groupname="sms"], [groupname="stms"]'));
            }
            else if (value === "Student studies" || value === "Student traineeship") {
                this.hideElements(document.querySelectorAll('[groupname="stms"]'));
                this.showElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));

            }
            else if ((value === "Staff teacher") || (value === "Staff training")) {
                this.hideElements(document.querySelectorAll('[groupname="eqf"], [groupname="sms"]'));
                this.showElements(document.querySelectorAll('[groupname="stms"]'));
            }
        }

        this.refreshPage();
    }

    handleEnableDisableLanguage(value, name, index) {
        if (name === "language"+index) {
            if (value === "") {
                document.getElementById("cefrLevel"+index).disabled = "disabled";
                document.getElementById("iscedFCodeLang"+index).disabled = "disabled";
                document.getElementById("iscedClarificationLang"+index).disabled = "disabled";
            }
            else {
                document.getElementById("cefrLevel"+index).disabled = "";
                document.getElementById("iscedFCodeLang"+index).disabled = "";
            }
        }
        else if (name === "iscedFCodeLang"+index) {
            if (value === "") {
                document.getElementById("iscedClarificationLang"+index).disabled = "disabled";
            }
            else {
                document.getElementById("iscedClarificationLang"+index).disabled = "";
            }
        }
        else if (name === "iscedFCode"+index) {
            if (value === "") {
                document.getElementById("iscedClarification"+index).disabled = "disabled";
            }
            else {
                document.getElementById("iscedClarification"+index).disabled = "";
            }
        }

        this.refreshPage();
    }

    hideElements(elements) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
        }
    }

    showElements(elements) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "block";
        }
    }

    arrayRemove(array, value) {
        let index = array.indexOf(value);
        delete array[index];
        return array;
    }

    generateMessageForMultipleSelect(name) {
        const coopCondition = JSON.parse(localStorage.getItem("coopConditions"));
        const auxInfo = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let cc = coopCondition[auxInfo.pageNumber - 1];
        let message = "";

        if (name === "eqfLevels") {
            if (cc.eqfLevels.length === 0) { message = "(Select at least one)"; }
            else { message = cc.eqfLevels.length + " selected"; }
        }
        else if (name === "languagesAdded") {
            if (cc.languagesAddedSelection.length === 0) { message = "(Select items to remove)"; }
            else { message = cc.languagesAddedSelection.length + " selected"; }
        }
        else if (name === "academicYears") {
            if (cc.academicYears.length === 0) { message = "(Select at least one)"; }
            else { message = cc.academicYears.length + " selected"; }
        }

        return message;
    }

    refreshPage() {
        this.setState({
            refreshPage: !this.state.refreshPage
        });
        this.props.refreshPage();
    }

    refreshButton(value, name) {
        this.handleEnableDisable(value, name);
    }
}

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

export default compose(
    graphql(getLanguages, { name: "allLanguages" }),
    graphql(getEQF, { name: "allEQF" }),
    graphql(getIscedFCodesList, {name: "getIscedFCodesList"})
)(Condition);
