import React, { Component } from 'react';
import Select from 'react-select';
/*import 'react-select/dist/react-select.css';*/
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../queries/getAccountData';

class FunctionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
        functionValue: "",
        multivalue: []
    }
  }

  changeFunctionValue(event){
      const functionValue = event.target.value;
      this.setState({ functionValue });
  }

  changeMultiSelect(multivalue){
      this.setState({ multivalue });
  }

  safeAccountFunction(event){
    event.preventDefault();

    this.props.addAccountFunction({
      variables:{
        FunctionName: this.state.functionValue,
        ErasmusCode: localStorage.getItem('ErasmusCode')
      }
    }).then((res) => {
      console.log("From FunctionForm",res.data.addAccountFunction.id)
      const FunctionID = res.data.addAccountFunction.id;
      const length = this.state.multivalue.length;
      this.state.multivalue.map(({label, value}, index) => {
        if(index != length-1){
          this.props.addAccountAbility({
            variables:{
              Name: label,
              Value: value,
              FunctionID
            }
          })
        }
        else{
          this.props.addAccountAbility({
            variables:{
              Name: label,
              Value: value,
              FunctionID
            },
            refetchQueries: [{query}]
          }).then((response) => {
          })
        }
      })
      this.setState({
        functionValue: "",
        multivalue: []
      })
    })
  }

  renderAddButton() {
    if(this.state.multivalue != null) {
      if(this.state.multivalue.length === 0 || this.state.functionValue === ""){
        return <fieldset disabled><button className="top_fix">Add</button> </fieldset>
      }
      else return <button className="top_fix">Add</button>
    } else {
      return <fieldset disabled><button className="top_fix">Add</button> </fieldset>
    }
  }

	render() {
    const abilities = [
      { value: 'editingGeneralInfo', label: 'Editing general info' },
      { value: 'creatingAccounts', label: 'Creating accounts' },
      { value: 'viewingStudents', label: 'Viewing students' },
      { value: 'managingStudents', label: 'Managing students' },
      { value: 'uploadingStudents', label: 'Uploading students' },
      { value: 'managingIIAs', label: 'Managing IIAs' },
      { value: 'managingOUnits', label: 'Managing Organizational Units' },
      { value: 'creatingIIA', label: 'Creating IIA' },
      { value: 'defaultIIAData', label: 'Default IIA Data' },
      { value: 'editingStepByStep', label: 'Editing step by step' },
      { value: 'covidRestrictions', label: "Covid-19 Settings" },
      { value: 'importIIA', label: 'Import IIA' },
      { value: 'ewpSettings', label: 'EWP Settings' },
      // { value: 'createDeals', label: 'Create Deals' },
      // { value: 'createEvents', label: 'Create Events' },
      // { value: 'createUsefulInfo', label: 'Add Useful Info' },
      { value: 'erasmus-app', label: 'Erasmus+ App' },
      { value: 'nominations', label: 'Applications for Erasmus+' }
    ];
  	return (
  		<form onSubmit={(event) => this.safeAccountFunction(event)}>
        <input value={this.state.functionValue} onChange={(event) => this.changeFunctionValue(event)} type="text" placeholder="Role name" className="top_fix function_name" />
        <Select placeholder="Select multiple permissions" isMulti value={this.state.multivalue} onChange={(value) => this.changeMultiSelect(value)} name="form-field-name" options={abilities} className="multiselect" />
        {this.renderAddButton()}
  		</form>
    	);
  }
};

const addAccountFunction = gql`
  mutation addAccountFunction($FunctionName: String!, $ErasmusCode: String!){
    addAccountFunction(FunctionName: $FunctionName, ErasmusCode: $ErasmusCode){
      id
    }
  }
`

const addAccountAbility = gql`
  mutation addAccountAbility($Name: String!, $Value: String!, $FunctionID: ID!){
    addAccountAbility(Name: $Name, Value: $Value, FunctionID: $FunctionID){
      id
    }
  }
`

export default compose(
  graphql(addAccountAbility, {name: "addAccountAbility"}),
  graphql(addAccountFunction, {name: "addAccountFunction"})
)(FunctionForm);
