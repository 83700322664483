import React, { useState, useEffect } from 'react';

function AcademicYearFilter(props) {
  
  const [value, setValue] = useState("");
	
  const changeSelection = (event) => {
		setValue(event.target.value);
    props.onSelectedAcademicYear(event.target.value);
	}

  useEffect(() => {
    let academicYearValue = "";
    if(props.firstYear != null && props.secondYear != null){
      academicYearValue = props.firstYear+"/"+props.secondYear;
    }

    setValue(academicYearValue); 
  }, []);

	const renderAcademicYear = () => {
    let renderOptions = [];
    let today = new Date();
    let june = new Date(2021, 5, 2);
    let currentAcademicYear = "";
    if(today.toJSON().slice(0, 10) < june.toJSON().slice(0, 10)){
      // Before 1st June
      currentAcademicYear = today.getFullYear()-1;
    } else {
      // After 1st June
      currentAcademicYear = today.getFullYear();
    }

    for(var i=-3; i<=1; i++) {
      let firstYear = parseInt(currentAcademicYear)+parseInt(i);
      let secondYear = firstYear+1;
      let academicYear = firstYear + "/" + secondYear;
      renderOptions.push(<option key={i} value={academicYear}>{academicYear}</option>);
    }
    return renderOptions;
	}

	if(props.data.loading){
		return <select><option value="">Loading...</option></select>
	}	else {
    return (
      <select id="filter-academic-year" value={value} onChange={(event) => changeSelection(event)} >
        <option value={"all"}>Select academic year</option>
				{renderAcademicYear()}
        <option value={"all"}>All academic years</option>
      </select>
    )
	}

};

export default AcademicYearFilter;
