import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from 'lodash';
import gql from "graphql-tag";
import Loader from "react-loader-spinner";
import GlobalVar from '../../GlobalVar';
import { query as getnPDF } from "../../queries/getNominationPDF";
import { query as getNominationHostHEI } from '../../queries/getNominationHostHEI';
import { query as getManagedByEWP } from '../../queries/getManagedByEWP';


class ExportNominationsPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  render() {

    // check the permission for the user trying to export
     if(GlobalVar.checkAccountFunction("nominations")){
       if (!this.props.managedEWP.managedByEWP) {
         if (this.props.data.loading) {
           return (
             <div>
               <div className="white_background">
                 <Loader
                   className="spinner"
                   type="TailSpin"
                   color="#3986df"
                   height={70}
                   width={70}
                 />
               </div>
             </div>
           );
         }

         //var file = this.props.data.nominationsPdf.file;
         //console.log(file);
         //if(file){
         let pdf = this.props.data.nominationsPdf
           ? this.props.data.nominationsPdf.file
           : "";

         const errormsg = this.props.data.nominationsPdf.error;
         //const errormsg = "";
         if (errormsg != "") {
           return (<p>An error occured{errormsg}</p>);
         }
         else if (pdf) {
           let byteCharacters = atob(pdf);
           let byteNumbers = new Array(byteCharacters.length);

           for (let i = 0; i < byteCharacters.length; i++) {
             byteNumbers[i] = byteCharacters.charCodeAt(i);
           }

           let byteArray = new Uint8Array(byteNumbers);
           let file = new Blob([byteArray], { type: "application/pdf;base64" });
           const fileURL = URL.createObjectURL(file);

           return (
             <object
               style={{ width: window.innerWidth - 201 }}
               height="100%"
               type="application/pdf"
               data={fileURL}
             >
               <p>Download the PDF by clicking <a href={fileURL}>here</a>.</p>
             </object>
           );
         }
       } else {
         return (
         <div className={`content nomNoAccess`}>
           <div className="card card_full_height card_full_width">
             <div className="no_access nAccess">
               <h2>Access is denied</h2>
               <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
             </div>
           </div>
         </div>
         );
       }
	    } else {
      return (
    		<div className="content">
    			<div className="card card_full_width card_full_height">
    				<h2>You are not allowed to access the Export PDF page</h2>
    				<div className="inside_content">
    					<p>Dear Erasmus Dashboard user,</p>
              <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
    				</div>
    			</div>
    		</div>
    	);
    }
  }
}

export default compose(
  graphql(getnPDF, {
    options: (props) => ({
      variables: {
        uuid: decodeURIComponent(props.match.params.pdfUrl),
      },
    }),
  }),
    graphql(getManagedByEWP, {
      name: "managedEWP",
      options: (props) => ({
        variables: {
          erasmusCode: localStorage.getItem("ErasmusCode")
        }
      })
    })
)(ExportNominationsPDF);