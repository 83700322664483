import { reducer, SET_URL_ERROR_MESSAGE_ACTION } from '../reducers/AssistanceSettingsReducer';
import { useReducer, useCallback } from 'react';
import {
    SET_EMERGENCY_SHELTER,
    SET_ACCESS_HEALTH_SUPPORT,
    SET_FAST_TRACK_APPLICATION,
    SET_SPECIAL_SCHOLARSHIPS,
    SET_MORE_INFO_URL,
    SET_URL_ERROR_ACTION,
    SET_SAVE_BUTTON_DISABLED_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION
} from '../reducers/AssistanceSettingsReducer';

export const defaultState = {
    emergencyShelter: -1,
    accessHealthSupport: -1,
    fastTrackApplication: -1,
    specialScholarships: -1,
    moreInfoUrl: "",
    urlError: false,
    urlErrorMessage: "",
    saveButtonDisabled: false,
}

export const useAssistanceSettings = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setEmergencyShelter = useCallback((data) => {
        dispatch({ type: SET_EMERGENCY_SHELTER, payload: { emergencyShelter: data }})
    }, []);

    const setAccessHealthSupport = useCallback((data) => {
        dispatch({ type: SET_ACCESS_HEALTH_SUPPORT, payload: { accessHealthSupport: data }})
    }, []);

    const setFastTrackApplication = useCallback((data) => {
        dispatch({ type: SET_FAST_TRACK_APPLICATION, payload: { fastTrackApplication: data }})
    }, []);

    const setSpecialScholarShips = useCallback((data) => {
        dispatch({ type: SET_SPECIAL_SCHOLARSHIPS, payload: { specialScholarships: data }})
    }, []);

    const setMoreInfoUrl = useCallback((data) => {
        dispatch({ type: SET_MORE_INFO_URL, payload: { specialScholarships: data }})
    }, []);

    const setUrlError = useCallback((data) => {
        dispatch({ type: SET_URL_ERROR_ACTION, payload: { urlError: data }})
    }, []);

    const setUrlErrorMessage = useCallback((data) => {
        dispatch({ type: SET_URL_ERROR_MESSAGE_ACTION, payload: { urlErrorMessage: data }})
    }, []);

    const setSaveButtonDisabled = useCallback((data) => {
        dispatch({ type: SET_SAVE_BUTTON_DISABLED_ACTION, payload: { saveButtonDisabled: data }})
    })

    const updateMultipleValues = useCallback((data) => {
        dispatch({type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data})
    }, []);
    
    return {
        state,
        setEmergencyShelter,
        setAccessHealthSupport,
        setFastTrackApplication,
        setSpecialScholarShips,
        setMoreInfoUrl,
        setUrlError,
        setUrlErrorMessage,
        setSaveButtonDisabled,
        updateMultipleValues
    }
}