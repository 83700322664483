import React, { useState } from 'react';

function CreatedByFilter(props) {
	const [value, setValue] = useState("");

	const changeSelection = (event) => {
		setValue(event.target.value);
        props.onSelectedCreatedBy(event.target.value);
    }

	const renderCreatedBy = (printStatus) => {
        let doesExist = props.data.find((Status) => {
            if(printStatus === "Created By Student") {
                if(Status.field_dashboard_student_email === null) {
                    return true;
                }
            }
            if(printStatus === "Created By Institution"){
                if (Status.field_dashboard_student_email !== null) {
                    return true;
                }
            }
        });

		if(doesExist) {
			return <option>{printStatus}</option>;
		}
		return <option disabled>{printStatus}</option>;
    }


    if(props.data.loading) {
        return <select><option value="">Loading...</option></select>
    } else {
        return (
            <select id="created-by" value={value} onChange={(event) => changeSelection(event)} >
                <option value="">Select Created By</option>

                {renderCreatedBy("Created By Student")}
                {renderCreatedBy("Created By Institution")}

            </select>
        );
    }
}

export default CreatedByFilter;
