import React, { Component } from 'react';
import { query } from '../../../../queries/getTermsAndConditions';
import { compose,graphql } from 'react-apollo';
import gql from 'graphql-tag';
class Terms extends Component {
	constructor(props) {
		super(props);
	}
	gotoPreviousPage(){
  	this.props.history.goBack();
	}
  generateTerm(){
		return this.props.data.terms.map((term) => {
	    return(
      	<div dangerouslySetInnerHTML={{__html:term.terms}}></div>
	    )
		})
	}

	render() {
    if(this.props.data.loading){
			return (
    		<div className="card card_half_width card_fixed_height">
    			<h2>Information</h2>
    		</div>
	    );
		}
		else {
			return(
				<div className="institution_registration">
					<div onClick={() => this.gotoPreviousPage()} className="black_background"/>
					<div className="content popup terms">
				    {this.generateTerm()}
				    <button className="button" onClick={() => this.gotoPreviousPage()}>close</button>
					</div>
				</div>
			)
	    }
	}
};

export default graphql(query)(Terms);
