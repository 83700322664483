import React, { Component, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import PreliminaryTableRow from './PreliminaryTableRow';

class PreliminaryTable extends Component {

    constructor(props) {
        super(props);
    }

    automaticRecognitionCheck = (dataFromRow) => {
        this.props.parentCallback(dataFromRow);
    }

    render(){
        let tableType = this.props.tableType;
        const isOutgoing = this.props.isOutgoing != null ? this.props.isOutgoing : false;
        let classNameTable = "table_without_changes";
        if(tableType === 1){
            if(this.props.data.data.learningAgreement.preliminary_table_a !== undefined){
                if(this.props.data.data.learningAgreement.preliminary_table_a.length === 0){
                    return <p className="italic">No courses to show</p>;
                }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="ects">ECTS</th>
                                <th className="semester">Semester</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows(tableType)}
                            {this.calculateECTStotal()}
                        </tbody>
                    </table>
                )
            }
        } else if(tableType === 2){
            if(this.props.data.data.learningAgreement.preliminary_table_b !== undefined){
                if(this.props.data.data.learningAgreement.preliminary_table_b.length === 0){
                    return <p className="italic">No courses to show</p>;
                }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="ects">ECTS</th>
                                <th className="semester">Semester</th>
                                <th className="automatic_recognition">Automatic Recognition</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows(tableType,isOutgoing)}
                            {this.calculateECTStotal()}
                        </tbody>
                    </table>
                )
            }
        } else {
            if(this.props.data.data.learningAgreement.preliminary_table_c !== undefined){
                if(this.props.data.data.learningAgreement.preliminary_table_c.length === 0){
                    return <p className="italic">No courses to show</p>;
                }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="description">Description</th>
                                <th className="ects">ECTS</th>
                                <th className="automatic_recognition">Automatic Recognition</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows(tableType,isOutgoing)}
                            {this.calculateECTStotal()}
                        </tbody>
                    </table>
                )
            }
        }
    }

    renderTableRows(tableType, isOutgoing){
        let semesterMap = this.props.data.data.learningAgreement.semester_map;
        let semesterName = "";
        if(tableType === 1){
            return this.props.data.data.learningAgreement.preliminary_table_a.map((courseData, index) => {
                let semesterId = courseData.semester.data != undefined ? courseData.semester.data.id : 0;

                semesterMap.forEach((element, i) => {
                    if(semesterId === element.id){
                        semesterName = element.name;
                    }
                });

                return(
                    <PreliminaryTableRow 
                        courseData={courseData} 
                        key={index} 
                        tableType={1} 
                        semesterName={semesterName}
                        isOutgoing={isOutgoing} />
                )
            })
        } else if(tableType === 2) {
            return this.props.data.data.learningAgreement.preliminary_table_b.map((courseData, index) => {
                let semesterId = courseData.semester.data != undefined ? courseData.semester.data.id : 0;
                let semesterName = "";

                semesterMap.forEach((element, i) => {
                    if(semesterId === element.id){
                        semesterName = element.name;
                    }
                });

                return(
                    <PreliminaryTableRow 
                        parentCallback={this.automaticRecognitionCheck} 
                        courseData={courseData}
                        accounts={this.props.accounts}
                        status={this.props.status}
                        key={index} 
                        tableType={2} 
                        semesterName={semesterName} 
                        isOutgoing={isOutgoing} />
                )
            })
        } else {
            return this.props.data.data.learningAgreement.preliminary_table_c.map((courseData, index) => {

                return(
                    <PreliminaryTableRow 
                        parentCallback={this.automaticRecognitionCheck} 
                        courseData={courseData}
                        accounts={this.props.accounts}
                        status={this.props.status}
                        key={index} 
                        tableType={3} 
                        isOutgoing={isOutgoing} />
                )
            })
        }
    }

    calculateECTStotal(){
        let tableType = this.props.tableType;
        let totalECTS = 0;

        if(tableType === 1){
            this.props.data.data.learningAgreement.preliminary_table_a.forEach((courseData) => {
                if(courseData.attributes.field_pre_tbl_a_ects) {
                    const ects = courseData.attributes.field_pre_tbl_a_ects.replace(',', '.').replace(/\s+/g, '');
                    if (!isNaN(ects)){
                        totalECTS += parseFloat(ects, 10);
                    } else {
                        totalECTS += 0;
                    }
                }
            });
            return(
                <tr>
                    <td></td>
                    <td className="italic">Total ECTS credits</td>
                    <td className="italic">{totalECTS}</td>
                    <td></td>
                </tr>
            )
        } else if(tableType === 2) {
            this.props.data.data.learningAgreement.preliminary_table_b.forEach((courseData) => {
                if(courseData.attributes.field_pre_tbl_b_ects){
                    const ects = courseData.attributes.field_pre_tbl_b_ects.replace(',', '.').replace(/\s+/g, '');
                    if (!isNaN(ects)){
                        totalECTS += parseFloat(ects, 10);
                    } else {
                        totalECTS += 0;
                    }
                }
            });
            return(
                <tr>
                    <td></td>
                    <td className="italic">Total ECTS credits</td>
                    <td className="italic">{totalECTS}</td>
                    <td></td>
                </tr>
            )
        } else {
            this.props.data.data.learningAgreement.preliminary_table_c.forEach((courseData) => {
                if(courseData.attributes.field_pre_tbl_c_ects) {
                    const ects = courseData.attributes.field_pre_tbl_c_ects.replace(',', '.').replace(/\s+/g, '');
                    if (!isNaN(ects)){
                        totalECTS += parseFloat(ects, 10);
                    } else {
                        totalECTS += 0;
                    }
                }
            });
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td className="italic">Total ECTS credits</td>
                    <td className="italic">{totalECTS}</td>
                    <td></td>
                </tr>
            );
        }
    }
}

export default PreliminaryTable;