import gql from 'graphql-tag';

const query = gql`
  query{
    accounts(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      Name
      Email
      Phone
      AccountFunction{
        id
        Name
      }
    }
    accountFunctions(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      Name
      AccountAbility{
        id
        Name
      }
    }
  }
`
export { query };
