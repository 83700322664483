import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentSignedBy';
import { graphql } from 'react-apollo';

class SignedBy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}
	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		filters[this.props.typeOfSigning] = event.target.value;

		if(filters.signedBefore !== "-1" && filters.signedDuring !== "-1"){
			if(this.props.typeOfSigning === "signedBefore"){
				filters.signedDuring = "-1";
			}
			if(this.props.typeOfSigning === "signedDuring"){
				filters.signedBefore = "-1";
			}
		}
		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}
	renderSignedBy(statusCode, label){
		let doesExist = false;
		let setCount = 0;
		this.props.data.getStudentSignedBy.map(({count, Status}) => {
			if(Status === statusCode){
				doesExist = true;
				setCount = count;
			}
		})
		if(doesExist){
			return <option value={statusCode}>{label} ({setCount})</option>;
		}
		return <option value={statusCode} disabled>{label} ({setCount})</option>;
	}
	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			this.setState({
				value: filters[this.props.typeOfSigning]
			})
		}
		else{
			this.setState({
				value: ""
			})
		}
	}
	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
	    	return (
	    		<select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
	    			<option value={-1}>Not selected</option>
	    			{this.renderSignedBy(0, "Nobody")}
	    			{this.renderSignedBy(1, "Student")}
	    			{this.renderSignedBy(2, "Student+ sending institution")}
	    			{this.renderSignedBy(3, "Everyone")}
	    		</select>
	      	);
      	}
    }

};

export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			CountryName: props.CountryID,
			InstitutionName: props.InstitutionID,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			TypeOfSigning: props.typeOfSigning,
			Uploaded: props.Uploaded,
			AcademicYear: props.AcademicYear,
			StudyCycle: props.StudyCycle,
			SubjectArea: props.SubjectArea,
			SignedBefore:  Number(props.SignedBefore),
			SignedDuring:  Number(props.SignedDuring)
		}
	}}
})(SignedBy);
