import React, { useState } from 'react';

function HeiFilter(props) {

  const [value, setValue] = useState("");

	const changeSelection = (event) => {
		setValue(event.target.value);
    props.onSelectedHEI(event.target.value)
	}

	const renderΗΕΙ = () => {
    var unique = [...new Set(props.data.map(item => item.sending_institution_hei)),...new Set(props.data.map(item => item.receiving_institution_hei))]
    return [...new Set(unique)].sort().map((extractedName, index) => {
      if (extractedName !==null && extractedName !== "")
        return <option key={index} value={extractedName} >{extractedName}</option>;
    });
	}

	
	if(props.data.loading){
		return <select><option value="">Loading...</option></select>
	}	else {
      return (
        <select id="filter-hei" value={value} onChange={(event) => changeSelection(event)} >
          <option value={""}>Select HEI Erasmus Code</option>
					{renderΗΕΙ()}
        </select>
      )
	}

}

export default HeiFilter;