import gql from 'graphql-tag';
const query = gql`
	query {
	  allEQF{
        Code
        Title
	  }
	}
`;
export { query };
