import React, { Component } from 'react';


class CountryFilter extends Component {
    state={
        value:""
    }
	changeSelection(event){
		this.setState({
			value: event.target.value
        })
        this.props.onSelectedCountry(event.target.value)
			}
	renderCountry(){
    var unique = [...new Set(this.props.data.map(item => item.sending_institution_country)),...new Set(this.props.data.map(item => item.receiving_institution_country))]
    return [...new Set(unique)].sort().map((extractedName, index) => {
      if (extractedName !==null && extractedName !== "")
        return <option key={index} value={extractedName} >{extractedName}</option>;
    })
	}

	
	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
      return (
        <select id="filter-country" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={""}>Select country</option>
					{this.renderCountry()}
        </select>
      )
  	}
  }

};

export default CountryFilter;