import React, { Component } from "react";
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

class PartnerECode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }

        this.changeSelection = this.changeSelection.bind(this);
    }

    changeSelection(event) {
        this.setState({ value: event.target.value });
        this.props.handlerChange(event, "partnerEC");
    }

    render() {
        if (this.props.data.loading) {
            return <select><option value="">Loading...</option></select>
        }

        return (
            <select name="iia-filter-hei" id="iia-filter-hei" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                <option value={""}>Select partner HEI Erasmus Code</option>
                {this.props.data.getPartnersECodes.sort((a, b) => a.ECode !== b.ECode ? a.ECode < b.ECode ? -1 : 1 : 0).map(ecode => {
                    return <option value={ecode.ECode}>{ecode.ECode}</option>
                })}
            </select>
        );
    }
}

const getPartnersECodes = gql`
	query getPartnersECodes($ErasmusCode: String!) {
		getPartnersECodes(ErasmusCode: $ErasmusCode) {
			ECode
	    }
	} 
`;

export default compose(
    graphql(getPartnersECodes, {
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    })
)(PartnerECode);