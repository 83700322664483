import React, { Component } from 'react';
import { query } from '../../../queries/getFrequentlyAskedQuestions';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

class FrequentlyAskedQuestions extends Component {

	generateQuestions(){
		return this.props.data.faq.map((faq) => {
		    return( 
		    	<div  key={faq.id} className="inside_content">

		    	<h3>{faq.Question} </h3>
	            <div dangerouslySetInnerHTML={{__html:faq.Answer}}></div>
	            </div>
		    )        
		})
		
	}

    render() {
    	if(this.props.data.loading){	    
			return (
	    		<div className="card card_half_width card_fixed_height">
	    			<h2>Information</h2>
	    		</div>
	      	);
		}
		else {  	
	    	return (
	    	    <div className="card">
	    			<h2>Frequently asked questions</h2>
		    			<ol>
		    			    {this.generateQuestions()}
		    			</ol>    
	    	    </div>		
	      	);
	    } 
    }

};

export default compose(
	graphql(query)
)(FrequentlyAskedQuestions);