import gql from 'graphql-tag';

const query = gql`
	query course($OlaID: Int, $CourseType: String, $OpenCourses: Int, $Changes: Int, $ChangesOlaID: Int){
		course(OlaID: $OlaID, CourseType: $CourseType, OpenCourses: $OpenCourses, Changes: $Changes, ChangesOlaID: $ChangesOlaID){
	    code
	    title
	    semester
	    ECTS
	    status
			reason_int
			reason_txt
			isdeleted
			changes
		}
	}
`
export { query };
