import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import Row from './Row';

class Data extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectionMinimum: undefined,
			selectionMaximum: undefined
		}

		this.firstClick = "";
		this.studentList = [];
		this.pages = 1;
	}

	componentDidUpdate(prevProps, prevState) {
		Object.entries(this.props).forEach(([key, val]) =>
			prevProps[key] !== val && console.log(`Data Prop '${key}' changed`)
		);
		if (this.state) {
			Object.entries(this.state).forEach(([key, val]) =>
				prevState[key] !== val && console.log(`Data State '${key}' changed`)
			);
		}
	}

	renderRows() {
		const iiaListInfo = this.props.listIIA;
		let realIndex = 0;
		let sortBy = localStorage.getItem("sortByIIAList");
		let sortDirection = localStorage.getItem("sortDirectionIIAList");
		let currentErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;

		let sortedData = [];
		if (iiaListInfo != undefined) {
			iiaListInfo.map((data) => {
				let element = data;

				// if (data.creator_signature_date != "" && data.partner_signature_date != "") {
				// 	element.inEffect = "Signed by both parties";
				// } else {
				// 	if ((currentErasmusCode == data.creator_institution_code && data.creator_signature_date != "") || (currentErasmusCode == data.partner_institution_code && data.partner_signature_date != "")) {
				// 		element.inEffect = "Requested partner signature";
				// 	}
				// 	if ((currentErasmusCode == data.creator_institution_code && data.partner_signature_date != "") || (currentErasmusCode == data.partner_institution_code && data.creator_signature_date != "")) {
				// 		element.inEffect = "Need to sign or edit";
				// 	}
				// }

				// if (currentErasmusCode === data.creator_institution_code) {
				// 	switch (data.inEffect) {
				// 		case 0: element.inEffect = "Changed and waiting partner review";
				// 			break;
				// 		case 1: element.inEffect = "Signed and waiting partner review";
				// 			break;
				// 		case 2: element.inEffect = "Changed by partner and need your review";
				// 			break;
				// 		case 3: element.inEffect = "Signed by partner and need your review";
				// 			break;
				// 		case 4: element.inEffect = "Signed by both parties";
				// 			break;
				// 	}
				// }
				// else if (currentErasmusCode === data.partner_institution_code) {
				// 	switch (data.inEffect) {
				// 		case 0: element.inEffect = "Changed by creator and need your review";
				// 			break;
				// 		case 1: element.inEffect = "Signed by creator and need your review";
				// 			break;
				// 		case 2: element.inEffect = "Changed and waiting creator review";
				// 			break;
				// 		case 3: element.inEffect = "Signed and waiting creator review";
				// 			break;
				// 		case 4: element.inEffect = "Signed by both parties";
				// 			break;
				// 	}
				// }

				element.creator_signature_timestamp = (data.creator_signature_date !== "") ? Date.parse(data.creator_signature_date) : 0;
				element.partner_signature_timestamp = (data.partner_signature_date !== "") ? Date.parse(data.partner_signature_date) : 0;
				sortedData.push(element);
			});

			if (sortBy != null) {
				if (sortBy === "creator_signature_date") { sortBy = "creator_signature_timestamp"; }
				else if (sortBy === "partner_signature_date") { sortBy = "partner_signature_timestamp"; }
				sortedData.sort((row1, row2) => {
					if (row1[sortBy] > row2[sortBy]) {
						if (sortDirection === "asc") { return 1; }
						if (sortDirection === "desc") { return -1; }
					}
					if (row1[sortBy] < row2[sortBy]) {
						if (sortDirection === "asc") { return -1; }
						if (sortDirection === "desc") { return 1; }
					}
					return 0;
				});
			}

			return sortedData.map((data, index) => {
				if (currentErasmusCode === data.creator_institution_code || currentErasmusCode === data.partner_institution_code) {
					realIndex++;
					return (
						<Row
							search={this.props.search}
							key={index}
							realIndex={realIndex}
							index={index}
							data={data}
							selectionMinimum={this.state.selectionMinimum}
							selectionMaximum={this.state.selectionMaximum}
							handleMultipleSelecting={this.handleMultipleSelecting}
							uselessProp={this.props.uselessProp}
							history={this.props.history}
							ewpStatus={this.props.ewpStatus}
						/>
					)
				}
			});
		}
	}

	sortFunction(event, sortName) {
		event.preventDefault();
		if (sortName !== undefined) {
			if (localStorage.getItem("sortByIIAList") === sortName) {
				if (localStorage.getItem("sortDirectionIIAList") == "desc") {
					localStorage.setItem("sortDirectionIIAList", "asc");
				}
				else if (localStorage.getItem("sortDirectionIIAList") == "asc") {
					localStorage.setItem("sortDirectionIIAList", "desc");
				}
			}
			else {
				localStorage.setItem("sortDirectionIIAList", "desc")
				localStorage.setItem("sortByIIAList", sortName)
			}
			window.location.reload();
		}
	}

	renderSortIndication(sortName) {
		if (sortName === localStorage.getItem("sortByIIAList")) {
			if (localStorage.getItem("sortDirectionIIAList") === "asc") {
				return <img className="arrow" src="/icons/downArrow-blue.svg" />;
			}
			else if (localStorage.getItem("sortDirectionIIAList") === "desc") {
				return <img className="arrow" src="/icons/upArrow-blue.svg" />;
			}
		}
	}

	renderHeaderColumn(title, uniqueName, sortName) {
		if (title === "Status" || title === "Creator EC" || title === "Partner EC" || title === "Creator Sign Contact" || title === "Partner Sign Contact") {
			return <form id="status_column" onSubmit={(event) => this.sortFunction(event, sortName)} className={"column " + uniqueName}><button className="button_title">{title}</button>{this.renderSortIndication(sortName)}</form>;
		}
		return <form onSubmit={(event) => this.sortFunction(event, sortName)} className={"column " + uniqueName}><button className="button_title">{title}</button>{this.renderSortIndication(sortName)}</form>;
	}

	render() {
		return (
			<div className="card card_full_width card_full_height">
				<div className="custom_table">
					<div className="table_body">
						{this.renderRows()}
					</div>
				</div>
			</div>
		);
	}

};

export default Data;