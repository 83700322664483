import React, { Component, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

class PreliminaryTableRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkTableBRow: false,
            checkTableBRow: false,
            rejectValue: "",
            processingAutomatic: false,
            emptyRejectValue: false,
            isChecked: false,
            isChanged: false,
        }

        this.changeRecognise = this.changeRecognise.bind(this);
    }

    componentDidMount(){
        const tableType = this.props.tableType;

        let recognisedFromApi = null;
        if(tableType === 2){
            recognisedFromApi = (this.props.courseData.attributes
                ? this.props.courseData.attributes.field_pre_tbl_b_auto_recognised
                : false);

            if(recognisedFromApi){
                this.setState({
                    checkTableBRow: true
                });
            } else {
                this.setState({
                    checkTableBRow: false
                });
            }
        } else if(tableType === 3) {
            recognisedFromApi = (this.props.courseData.attributes
                ? this.props.courseData.attributes.field_pre_tbl_c_auto_recognised
                : false);

            if(recognisedFromApi){
                this.setState({
                    checkTableCRow: true
                });
            } else {
                this.setState({
                    checkTableCRow: false
                });
            }
        }
    }

    confirmRecognisedPopup(e, tableID, tableType, checkTable, rejectValue) {
        e.preventDefault();
        if(rejectValue === ""){
            this.setState({
                emptyRejectValue: true
            });
        } else {
            this.setState({
                processingAutomatic: true
            });

            let toReturn = {
                tableID: tableID,
                tableType: tableType,
                reason: rejectValue,
                autoRecoValue: checkTable,
                isPreliminary: true,
            };

            this.props.parentCallback(toReturn);

            setTimeout(
              () => this.setState({
                  processingAutomatic: false,
                  isChecked: false,
                  isChanged: true,
                }),
              1000
            );
        }
    }

    cancelRecognisedPopup(e) {
        e.preventDefault();
        const tableType = this.props.tableType;

        this.setState({
            emptyRejectValue: false,
            isChecked: false,
            isChanged: false,
        });
    }

    changeRecognise(event) {
        const tableType = this.props.tableType;
        const courseData = this.props.courseData;
        const recognised = tableType === 2 
                ? courseData.attributes.field_pre_tbl_b_auto_recognised
                : courseData.attributes.field_pre_tbl_c_auto_recognised;
        const tableID = courseData.id;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        if(tableType === 2){
            this.setState({
                checkTableBRow: value,
                isChecked: true
            });
        } else {
            this.setState({
                checkTableCRow: value,
                isChecked: true
            });
        }

        if(tableType === 2 && !recognised){
            this.setState({
                checkTableBRow: value,
                isChecked: false,
                isChanged: true
            });

            let toReturn = {
                tableID: tableID,
                tableType: tableType,
                reason: "",
                autoRecoValue: value,
            };

            this.props.parentCallback(toReturn);
        } else if(tableType === 3 && !recognised) {
            this.setState({
                checkTableCRow: value,
                isChecked: false,
                isChanged: true
            });

            let toReturn = {
                tableID: tableID,
                tableType: tableType,
                reason: "",
                autoRecoValue: value,
            };

            this.props.parentCallback(toReturn);
        }
    }

    accountNameNotExist() {
        const loginAccountName = localStorage.getItem("Name");
        const accounts = this.props.accounts;

        return loginAccountName == undefined ||
            loginAccountName.localeCompare("undefined") === 0 ||
            !accounts.includes(loginAccountName);
    }

    statusIncluded() {
        let statusArray = [];
        if(this.props.isOutgoing) {
            statusArray = ["preliminary_signed-by-student"];
        } else {
            statusArray = ["preliminary_signed-by-student-sending"];
        }

        return statusArray.includes(this.props.status);
    }

    checkBoxRequired(){
        return this.statusIncluded() && !this.accountNameNotExist();
    }

    render(){
        const tableType = this.props.tableType;
        const courseData = this.props.courseData;
        const isOutgoing = this.props.isOutgoing != null ? this.props.isOutgoing : false;
        const tableID = courseData.id;
        const semesterName = this.props.semesterName;

        if(tableType === 1) {
            return(
                <tr key={this.props.index}>
                    <td className="code">{courseData.attributes.field_pre_tbl_a_code}</td>
                    <td className="title">{courseData.attributes.field_pre_tbl_a_title}</td>
                    <td className="ects">{courseData.attributes.field_pre_tbl_a_ects}</td>
                    <td className="semester">{semesterName}</td>
                </tr>
            )
        } else if(tableType === 2) {
            const recognised = courseData.attributes.field_pre_tbl_b_auto_recognised;
            const recognisedText = this.state.checkTableBRow ? "Yes" : "No";
            const renderCheckBox = this.checkBoxRequired() ? <input
                                    name="autRec"
                                    type="checkbox"
                                    checked={recognised}
                                    onChange={this.changeRecognise} /> : recognisedText;
            return(
                <Fragment key={this.props.index}>
                    <tr>
                        <td className="code">{courseData.attributes.field_pre_tbl_b_code}</td>
                        <td className="title">{courseData.attributes.field_pre_tbl_b_title}</td>
                        <td className="ects">{courseData.attributes.field_pre_tbl_b_ects}</td>
                        <td className="semester">{semesterName}</td>
                        <td className="automatic_recognition">
                            {isOutgoing && !this.state.isChanged ? renderCheckBox
                            : recognisedText}
                        </td>
                    </tr>
                    {this.state.isChecked && recognised ?
                        (<Fragment>
                            <div onClick={(e) => this.cancelRecognisedPopup(e)} className="black_background"></div>
                            <div className={`content popup single_popup recognise_popup`}>
                                {this.state.emptyRejectValue
                                    ? <p className="error error_text">Please write down a reason</p>
                                    : null}
                                {this.state.processingAutomatic ? (<div className="white_background">
                                    <Loader
                                        className="spinner"
                                        type="TailSpin"
                                        color="#3986df"
                                        height={50}
                                        width={50} />
                                </div>) : null}
                                <div>
                                    <p className="italic">As per official guidelines from the European Commission,
                                    if the automatic recognition will not take place,
                                        please provide a clear justification and an indication on what
                                    other type of formal recognition will be applied.</p>
                                    <textarea
                                        onChange={(event) => this.setState({rejectValue: event.target.value})}
                                        value={this.state.rejectValue}
                                        placeholder="Fill in reason of rejecting" />
                                </div>
                                <div>
                                    <button
                                        className="cancel button-danger"
                                        onClick={(e) => this.cancelRecognisedPopup(e)}>
                                            Cancel
                                    </button>
                                    <button
                                        className="confirm"
                                        onClick={(e) => this.confirmRecognisedPopup(e, tableID, tableType, this.state.checkTableBRow, this.state.rejectValue)}>
                                            Confirm
                                    </button>
                                </div>
                            </div>
                        </Fragment>) : null
                    }
                </Fragment>
            )
        } else {
            const recognised = courseData.attributes.field_pre_tbl_c_auto_recognised;
            const recognisedText = this.state.checkTableCRow ? "Yes" : "No";
            const renderCheckBox = this.checkBoxRequired() ? <input
                                    name="autRec"
                                    type="checkbox"
                                    checked={recognised}
                                    onChange={this.changeRecognise} /> : recognisedText;

            return (
                <Fragment key={this.props.index}>
                    <tr>
                        <td className="code">{courseData.attributes.field_pre_tbl_c_code}</td>
                        <td className="title">{courseData.attributes.field_pre_tbl_c_title}</td>
                        <td className="description">{courseData.attributes.field_pre_tbl_c_virt_comp_descr}</td>
                        <td className="ects">{courseData.attributes.field_pre_tbl_c_ects}</td>
                        <td className="automatic_recognition">
                            {isOutgoing && !this.state.isChanged ? renderCheckBox
                            : recognisedText}
                        </td>
                    </tr>
                    {this.state.isChecked && recognised ?
                        (<Fragment>
                            <div onClick={(e) => this.cancelRecognisedPopup(e)} className="black_background"></div>
                            <div className={`content popup single_popup recognise_popup`}>
                                {this.state.emptyRejectValue
                                    ? <p className="error error_text">Please write down a reason</p>
                                    : null}
                                {this.state.processingAutomatic ? (<div className="white_background">
                                    <Loader
                                        className="spinner"
                                        type="TailSpin"
                                        color="#3986df"
                                        height={50}
                                        width={50} />
                                </div>) : null}
                                <div>
                                    <p className="italic">As per official guidelines from the European Commission,
                                        if the automatic recognition will not take place,
                                            please provide a clear justification and an indication on what
                                        other type of formal recognition will be applied.</p>
                                    <textarea
                                        onChange={(event) => this.setState({rejectValue: event.target.value})}
                                        value={this.state.rejectValue}
                                        placeholder="Fill in reason of rejecting" />
                                </div>
                                <div>
                                    <button
                                        className="cancel button-danger"
                                        onClick={(e) => this.cancelRecognisedPopup(e)}>
                                            Cancel
                                    </button>
                                    <button
                                        className="confirm"
                                        onClick={(e) => this.confirmRecognisedPopup(e, tableID, tableType, this.state.checkTableCRow, this.state.rejectValue)}>
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </Fragment>) : null
                    }
                </Fragment>
            );
        }
    }
}

export default PreliminaryTableRow;
