import React, { Component } from 'react';
import { query } from '../../../queries/getTermsAndConditions';
import { compose,graphql } from 'react-apollo';
import gql from 'graphql-tag';
class TermsAndConditions extends Component {
	constructor(props) {
		super(props);
	}
	
    generateTerm(){
		return this.props.data.terms.map((term) => {
		    return( 
	            <div key={term.id} dangerouslySetInnerHTML={{__html:term.terms}}></div>
		    )        
		})	
	}
	
	render() {
    	if(this.props.data.loading){	    
			return (
	    		<div className="card">
	    		</div>
	      	);
		}
		else {  	
			return( 
				<div className="termsAndConditions content">
					<div style={{ "maxWidth":"800px", "margin":"10px", "paddingLeft":"10px", "height":"90%", "width":"calc(100% - 227px)"}} className="card">
					    {this.generateTerm()}
					</div>
				</div>
			)  
	    }		 
	}
};

export default graphql(query)(TermsAndConditions);
