import React, { useState, useEffect } from 'react';
import { query as institutionInfo } from '../../queries/getInstitution';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import ShortTermData from './Data';
import GlobalVar from '../../GlobalVar';
import {query as isOLAManagedByEWP} from "../../queries/isOLAManagedByEWP";
import NoAccess from "./NoAccess";

function ShortTermList(props) {

    const [typeOfStudent, setTypeOfStudent] = useState(-1);
    const [typeOfStudentName, setTypeOfStudentName] = useState("");

    useEffect(() => {
        setStudentsType(props);
    },[]);

    const setStudentsType = (props) => {
        const url = props.location.pathname;
        let outgoingStudent = intl.get("Outgoing students");
        outgoingStudent = outgoingStudent.replace(/\s/g, "-");
        outgoingStudent = outgoingStudent.toLowerCase();
  
        if(url.includes(outgoingStudent)){
            setTypeOfStudent(1);
            setTypeOfStudentName("Outgoing");
        } else {
            let incomingStudent = intl.get("Incoming students");
            incomingStudent = incomingStudent.replace(/\s/g, "-");
            incomingStudent = incomingStudent.toLowerCase();
            if(url.includes(incomingStudent)){
                setTypeOfStudent(2);
                setTypeOfStudentName("Incoming");
            }
        }
    }

    if (props.institutionInfo.loading||props.isOLAManagedByEWP.loading) {
        return <p>Loading...</p>
    }
    if (props.isOLAManagedByEWP.isOLAManagedByEWP.Success == 2) {

        return (
            <ShortTermData
                typeOfStudent={typeOfStudent}
                typeOfStudentName={typeOfStudentName}
                institutionInfo={props.institutionInfo}
            />
        )
    }
    else{
        return (
            <NoAccess/>
        );
    }
}

export default compose(
    graphql(isOLAManagedByEWP, {name: "isOLAManagedByEWP"
        ,
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    }),
    graphql(institutionInfo, {name: "institutionInfo"})
)(ShortTermList);