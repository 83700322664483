import React, { Component } from 'react';
import { query } from '../../../queries/getSingleAccount';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';
import Terms from '../registration/popup/Terms';

import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

class AccountConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      checked:false,
      error: false,
			title: "",
    }
  }
  disabledButton(event){
    event.preventDefault();
  }
  validate(){
    console.log("validating")
    if(this.state.password.length < 7){
      console.log("password error");
      this.setState({error: true, title: "The password needs at least 8 characters"})
			return false;
    }
    if(this.state.checked === false){
			this.setState({error: true, title: "Please accept the Privacy Policy and Terms and Conditions"})
			return false;
		}
    this.setState({error: false, title: "Confirming..."});
		return true;
  }
  login(event){
    event.preventDefault();
    if(this.validate()){
      this.props.mutate({
        variables: {
          id: this.props.match.params.accountID,
          password: this.state.password
        }
      }).then((response) => {
        localStorage.clear(this.props.match.params.accountID);
        localStorage.setItem("accountID", this.props.match.params.accountID);
        localStorage.setItem("ErasmusCode", GlobalVar.encryptWithKey(this.props.data.singleAccount.ErasmusCode));
        localStorage.setItem("Email", GlobalVar.encryptWithKey(this.props.data.singleAccount.Email));
        localStorage.setItem("Name", this.props.data.singleAccount.Name);
    		GlobalVar.setDefaultLocalStorage();
        window.location.replace(GlobalVar.generateLinkUrl(intl, "My University", "General info"));
      })
    }
  }
  changePassword(event){
    this.setState({
      password: event.target.value
    })
  }

  checked(event){
    this.setState({ checked : !this.state.checked })
  }

  getTitle(){
		if(this.state.error){
			return <h2 className="error">{this.state.title}</h2>;
		}
		else if(this.state.title !== ""){
			return <h2>{this.state.title}</h2>;
		}
		return <h2>Finalize your account</h2>;
	}

  render() {
    if(this.props.data.loading){
        return <div className="account_login card card_center"></div>;
    }
    else if(!this.props.data.singleAccount){
        return (
            <div className="account_login card card_center">
                <h2>Account not found</h2>
            </div>
        );
    }
    else{
      const {Email, Name, Institution, AccountFunction } = this.props.data.singleAccount;
    	return (
        <div className="intro">
          <div className="intro_content">
              <div className="account_confirmation card">
                  {this.getTitle()}
                  <form>
                      <div className="login_input_wrapper">
                          <label>Email</label>
                          <p><a href={`mailto:${Email}`}>{Email}</a></p>
                      </div>

                      <div className="login_input_wrapper">
                          <label>Full name</label>
                          <p>{Name}</p>
                      </div>

                      <div className="login_input_wrapper">
                          <label>Higher education institution name</label>
                          <p>{Institution.NameInLatinCharacterSet}</p>
                      </div>

                      <div className="login_input_wrapper">
                          <label>Function name</label>
                          <p>{AccountFunction.Name}</p>
                      </div>

                      <div className="login_input_wrapper">
                          <label>New password (minimum 8 characters)</label>
                          <input type="password" onChange={(event) => this.changePassword(event)} value={this.state.password} autoFocus placeholder="Minimum 8 characters" />
                      </div>
                      <div className="accept_terms">
                        <input  className="checkbox" type="checkbox" checked={this.state.checked} onChange={(event) => this.checked(event)}></input>
                        <label>I have read and agree to the <Link to={`${this.props.match.url}/termsandconditions`}>Privacy Policy and Terms and Conditions</Link></label>
                      </div>
                      <button onClick={(event) => this.login(event)} type="submit">Login</button>
                  </form>
              </div>
          </div>
          <Route exact path={GlobalVar.generateLinkUrl(intl, "Account", "Confirmation")+"/:accountID/termsandconditions"} render={() => <Terms history={this.props.history}/>}/>
        </div>
      );
    }
  }
};

const mutation = gql`
    mutation ChangePasswordAccount($id: ID, $password:String){
      changePasswordAccount(id:$id, password: $password){
        id
      }
    }
`;


export default graphql(mutation)(
    graphql(query, {
    options: (props) => {return {
        variables: { id: props.match.params.accountID}
    }}})(AccountConfirmation)
);
