import gql from 'graphql-tag';

let erasmusCode = "G THESSAL01";
if (localStorage.getItem("currentErasmusCode")){
  erasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
}

const query = gql`

query{
  deals(erasmusCode: "${erasmusCode}"){
    externalID
    dealID
    title
  }
}
`
export { query };