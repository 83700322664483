import React, { Component } from 'react';
import { query } from '../../../queries/getCities';
import { graphql } from 'react-apollo';

class SelectCity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0
		}
	}

	changeSelection(e){
		this.setState({
			value: e.target.value
		});
		this.props.getCityInfo(e.target.value, e.target.selectedOptions[0].dataset.link);
	}

	generateCities(){
		let citiesList = this.props.data.cities;
		// sort list alphabetically
		citiesList.sort(function(a, b){
		    if(a.CityName < b.CityName) { return -1; }
		    if(a.CityName > b.CityName) { return 1; }
		    return 0;
		});
		return citiesList.map((data) => {
			return (
				<option key={data.ID} value={data.ID} data-link={data.CityLink}>
					{data.CityName}
				</option>
			);
		})
	}

	render() {
		if(this.props.countryLink != 0 && this.props.countryLink != undefined){
			if(this.props.data.loading) {
				return <select><option>Loading...</option></select>;
			} else {
				return ( 
					<select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
						<option value={0} >Select city of institution</option>{this.generateCities()}
					</select>
				);
			}
			
		}      	
		return (
    		<select><option>(City) select a country first</option></select>
      	);
    	
    }

};

export default graphql(query, {
	options: (props) => {return {
		variables: { CountryLink: props.countryLink}
	}}
})(SelectCity);
