import React, { Component } from 'react';

import { query } from '../../../queries/getSingleDocument';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import { Link } from 'react-router-dom';

import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

class Document extends Component {
	generateList(){
	  	return this.props.data.documents.map(doc => {
	  		if(doc.title !== ""){
		  		if(this.props.match.params.title === doc.title){
		  			return(
				    <li key={doc.id}><Link className="active" to={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")+`/title/${doc.title}`}>{doc.title}</Link></li>	
				) 
		  		}
		    	return(
				    <li key={doc.id}><Link to={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")+`/title/${doc.title}`}>{doc.title}</Link></li>	
				)  
			}	 
	    })		    
	  }
	generateDocument(){
		if(!this.props.data.document){
            return(
				<div className="card content">
				</div> 
			) 
		}
		else {
			const description = this.props.data.document.description
			console.log(this.props.data.document.description)
			return(
				<div className="card content">
				   <h2>{this.props.data.document.title}</h2>
				   <div dangerouslySetInnerHTML={{__html:description}}></div>
				</div> 
			) 
		}	
	}				   
	render(){
		if(this.props.data.loading){
	      return (
	        <div className="document">
	          <div className="card">

	          </div>
	        </div>
	      )
	    }
		return(
		  	<div className="document">	  	   
               {this.generateDocument()} 
               	<ul >
		           {this.generateList()}
		        </ul>	
		  	</div>
	    )  	
	}
}

export default compose(
  graphql(query, {
    options: (props) => {return {
      variables: { 
        title : props.match.params.title
      }
    }}
  })
)(Document);	