import React, { Component } from 'react';
import { compose,graphql } from 'react-apollo';
import Title from '../common/Title';

class FAQs extends Component {

    render() {
    	return (
	    	<div className="card card_full_width card_full_height">
				<div className="custom_table">
                    <div className="row">
					    <Title text="IIAm Frequently Asqued Quesitons" />
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>What is the Inter-Institutional Agreement Manager?</h3>
                    </div>
                    <div className="row">
					    <p style={{margin: "10px"}}>The Inter-Institutional Agreement Manager (IIAM) is a tool that allows creating, negotiating and updating Inter-Institutional Agreements in a machine-readable form. This is a beta version of the IIAM.</p>
                        <p style={{margin: "10px"}}>The IIAM has been developed with the financial support of the European Commission and corresponds to the official templates and processes as per Inter-institutional Agreement documentation requirements and official data standards of the European Student Card Initiative for the programme period of 2021-2027. More information is available here:</p>
                        <p style={{margin: "10px"}}><a href="https://ec.europa.eu/programmes/erasmus-plus/resources/documents/information-about-inter-instutional-agreements-between-programme-countries-calls_en" target="_blank">https://ec.europa.eu/programmes/erasmus-plus/resources/documents/information-about-inter-instutional-agreements-between-programme-countries-calls_en</a></p>
                        <p style={{margin: "10px"}}>The IIAM is accessible in the Erasmus Dashboard, which is an administration panel for Higher Education Institutions (HEIs) for managing Inter-Institutional Agreements (IIAs) as well as Learning Agreements (LAs) online.</p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>What are the Inter-Institutional Agreement Manager functions?</h3>
                    </div>
                    <div className="row">
					    <p style={{margin: "10px"}}>The IIAM allows for digitising Inter-institutional Agreements as the key part of the mobility documentation and offers the following features:</p>
                        <p style={{margin: "10px"}}>- Indicate and upload all the key elements in the IIAs.</p>
                        <p style={{margin: "10px"}}>- Stipulate the cooperation conditions per Higher Education Institution or smaller organisational units.</p>
                        <p style={{margin: "10px"}}>- Communicate with the partner on the necessary updates in the IIA before signing.</p>
                        <p style={{margin: "10px"}}>- Use the multiuser system to grant different levels of access to the staff involved in IIA administration.</p>
                        <p style={{margin: "10px"}}>- Sign the IIA online and have a clear overview of the status of all the IIAs.</p>
                        <p style={{margin: "10px"}}>- Specify and update the general profile information about the Higher Education Institution stable for all the IIAs (previously known as Factsheets) that will allow this useful information for incoming students be put at their fingertips via the Erasmus+ App.</p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>What is Erasmus Dashboard?</h3>
                    </div>
                    <div className="row">
					    <p style={{margin: "10px"}}>The Erasmus Dashboard offers a simple-to-use yet sophisticated tool to ease the mobility administration for HEIs. Furthermore, as the Erasmus Dashboard and the Inter-Institutional Agreement are being developed with EU funding, their usage is and will always be free of charge for all HEIs interested in using them.</p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>What are the upcoming functionalities of the Inter-institutional Agreement Manager?</h3>
                    </div>
                    <div className="row">
					    <p style={{margin: "10px"}}>In the upcoming months, more features will be added to the tool in line with the feedback gathered during the testing phase. However, the following features are already foreseen:</p>
                        <p style={{margin: "10px"}}>- More advanced ways of determining the distinction between the signee and other staff members who interact with the Inter-institutional Agreement.</p>
                        <p style={{margin: "10px"}}>- An open comment field to indicate additional aspects that are key to the Inter-institutional Agreement between the partners.</p>
                        <p style={{margin: "10px"}}>- The possibility to upload lists of Inter-institutional Agreements, via Excel or CSV files.</p>
                        <p style={{margin: "10px"}}>- Additional features to determine a more user-friendly interaction with the tool - allowing to save and pre-fill certain values automatically, filter, search and sort as well as remove the Inter-institutional Agreements, etc.</p>
                        <p style={{margin: "10px"}}>- Full connection to the EWP network.</p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>What is the implementation timeline for digital Inter-institutional agreements in the upcoming Erasmus+ Programme?</h3>
                    </div>
                    <div className="row">
                        <p style={{margin: "10px"}}>The Inter-institutional Agreement Manager is available for testing from February 2020 until the end of April. Testing feedback will be gathered via an online survey which will be sent to all registered users.</p>
                        <p style={{margin: "10px"}}>Starting from May 1st, the tool can be populated with the real data that will be determining the agreements for the first call under the new Erasmus+ Programme in 2021; the development of the IIAM will continue in parallel throughout this period.</p>
                        <p style={{margin: "10px"}}>Please consult your National Agency for further details and follow the webinar devoted to introducing the digital Inter-institutional Agreement management in the next Erasmus+ Programme:</p>
                        <p style={{margin: "10px"}}><a href="https://webcast.ec.europa.eu/erasmus-goes-digital-renewing-and-managing-inter-institutional-agreements" target="_blank">https://webcast.ec.europa.eu/erasmus-goes-digital-renewing-and-managing-inter-institutional-agreements</a></p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>Who is developing the Inter-Institutional Agreement Manager?</h3>
                    </div>
                    <div className="row">
                        <p style={{margin: "10px"}}>The Inter-Institutional Agreement Manager module, available in the Erasmus Dashboard, was developed under the aegis of the EWP 2.0 project with the goal of enabling all stakeholders that are not connected to the EWP network to create fully digitally compliant Inter-Institutional Agreements for the new Erasmus+ programme (2021-2027).</p>
                        <p style={{margin: "10px"}}>The development work has been carried out by the University of Porto with significant support from higher education institutions across Europe, who shared their feedback and ideas on how the digitisation of IIA can better support IROs and students in the next Erasmus+ Programme.</p>
                    </div>
                    <div className="row">
                        <h3 style={{marginTop: "30px"}}>Where can I find more information?</h3>
                    </div>
                    <div className="row">
                        <p style={{margin: "10px"}}>More guidance materials will be published in the Erasmus Without Paper Competence Centre.</p>
                    </div>
				</div>
			</div>
	    );
    }

};

export default (FAQs);