import gql from 'graphql-tag';

const query = gql`
  query ($uuid: String) {
    nominationsPdf(uuid: $uuid){
      file,
      error
  }
}`

export { query };
