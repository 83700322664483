import gql from 'graphql-tag';

const query = gql`
query SingleAccount($id: ID){
  singleAccount(id: $id){
    Email
    Name
    ErasmusCode
    AccountFunction{
      Name
      AccountAbility{
        Name
      }
    }
    Institution{
      NameInLatinCharacterSet
    }
  }
}`

export { query };