import React, { Component } from "react";
import axios from 'axios';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import GlobalVar from '../../../GlobalVar';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reRender: false,
            schac: "",
            apiInst: undefined,
            apiOU: undefined,
            apiIIA: undefined,
            apiIS: undefined,
            apiOS: undefined,
            apiOLA: undefined,
            apiStatic: undefined,
            // newSCHAC: ""
        }

        this.staticFieldsErrors = [];
    }

    // changeSCHACValue(event){
	// 	this.setState({
	// 		newSCHAC: event.target.value.toLowerCase(),
	// 	});
    // }
    
    setStateValues(data){
        // this.state.schac = data.institution_schac;
        this.state.apiInst = data.api_inst;
        this.state.apiOU = data.api_ounit;
        this.state.apiIIA = data.api_iia;
        this.state.apiIS = data.api_outstudent;
        this.state.apiOS = data.api_instudent;
        this.state.apiStatic = data.api_static;
        this.state.apiOLA = data.api_ola;
    }

    changeSCHAC(){
        if (this.state.schac != "") {

            // var re = /^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9])(((\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9])*)*)(\.[a-zA-Z0-9]{2,25})$/;

            // var OK = re.exec(this.state.newSCHAC);

            // if (!OK) {
            //     alert("Not a valid SCHAC Code");
            // } else {

            //check EWP network for institutions who use the same SCHAC and disable the APIs if needed
            this.props.ChangeEWPSCHAC({
                variables: {
                    "ErasmusCode": localStorage.getItem("ErasmusCode"),
                    "Schac": this.state.schac,
                },
                refetchQueries: [{
                    query: getEWPAPIsList, variables: {
                        ErasmusCodeIn: localStorage.getItem("ErasmusCode")
                    }
                }],
                awaitRefetchQueries: true
            }).then((x) => {
                if (x.data.error) {
                    alert("Error enabling EWP.");
                } else {
                    if (x.data.changeEWPSCHAC.Success) {
                        alert("The connection to the EWP network was successfully enabled.");
                        window.location.reload();
                    } else {
                        alert("Error response on enabling EWP.");
                    }
                }
            });

        }
    // }
    }

    setValue(name, val) {
        console.log(name);
        console.log(val);
        switch(name) {
            case "institution":
                //check EWP network for institutions with this API
                this.state.apiInst = val;
                break;
            case "ounits":
                //check EWP network for institutions
                this.state.apiOU = val;
                break;
            case "iia":
                //check EWP network for institutions
                this.state.apiIIA = val;
                break;
            case "outstudents":
                //check EWP network for institutions
                this.state.apiIS = val;
                break;
            case "instudents":
                //check EWP network for institutions
                this.state.apiOS = val;
                break;
            case "static":
                //check EWP network for institutions
                let isToChange = true;
                if (val) { 
                    this.checkStaticMandatoryFields((errors) => {
                        console.log("errors: " + JSON.stringify(errors));
                        if (errors.length > 0) {
                            alert("There are some errors with your Default IIA Data. Please correct them in 'IIA manager'> 'Default IIA Data' so that you can use the Dashboard " +
                                "to represent your high education institution.\n\nHere is the list of errors:\n- " + errors.join("\n- "));
                            document.getElementById("api_static_dashboard").checked = true;
                            isToChange = false;
                            return false;
                        }
                    }); 
                }
                if (isToChange) { /*DEL*/console.log("Changing apiStatic to " + val); this.state.apiStatic = val; }
                break;
            case "ola":
                //check EWP network for institutions
                this.state.apiOLA = val;
                break;
        }
    }

    changeValue(name){
        switch(name) {
            case 'iia':
                this.props.ChangeEWPAPIsIIA({
                    variables: {
                        "ErasmusCode": localStorage.getItem("ErasmusCode"),
                        "APIIIA": this.state.apiIIA,
                    },
                    refetchQueries: [{
                        query: getEWPAPIsList, variables: {
                            ErasmusCodeIn: localStorage.getItem("ErasmusCode")
                        }
                    }],
                    awaitRefetchQueries: true
                }).then((x) => {
                    if (x.data.error) {
                        alert("Error changing EWP IIA API values");
                    } else {
                        alert(x.data.changeEWPAPIsIIA.Message);
                    }
                });
                break;
            case 'static':        
                this.props.ChangeEWPAPIsStatic({
                    variables: {
                        "ErasmusCode": localStorage.getItem("ErasmusCode"),
                        "APIStatic": this.state.apiStatic,
                    },
                    refetchQueries: [{
                        query: getEWPAPIsList, variables: {
                            ErasmusCodeIn: localStorage.getItem("ErasmusCode")
                        }
                    }],
                    awaitRefetchQueries: true
                }).then((x) => {
                    if (x.data.error) {
                        alert("Error changing EWP API for serving Static Information");
                    } else {
                        alert(x.data.changeEWPAPIsStatic.Message);
                    }
                });
                break;
            case 'ola':
                this.props.ChangeEWPAPIsOLA({
                    variables: {
                        "ErasmusCode": localStorage.getItem("ErasmusCode"),
                        "APIOLA": this.state.apiOLA,
                    },
                    refetchQueries: [{
                        query: getEWPAPIsList, variables: {
                            ErasmusCodeIn: localStorage.getItem("ErasmusCode")
                        }
                    }],
                    awaitRefetchQueries: true
                }).then((x) => {
                    if (x.data.error) {
                        alert("Error changing EWP OLA API values");
                    } else {
                        alert(x.data.changeEWPAPIsOLA.Message);
                    }
                });
                break;
        }
    }

    checkStaticMandatoryFields(callback) {
        let data = this.props.DefaultIIAData.getIIADefaultData;
        let staticFieldsErrors = [];

        console.log(data);
        if (data === undefined || data === null) { staticFieldsErrors.push("No Default Data found."); }
        else {
            if (data.stuNomAutumnTerm === "") { staticFieldsErrors.push("The field 'Autumn Term' at 'Student nominations' is required."); }
            if (data.stuNomSpringTerm === "") { staticFieldsErrors.push("The field 'Spring Term' at 'Student nominations' is required."); }
            if (data.appNomAutumnTerm === "") { staticFieldsErrors.push("The field 'Autumn Term' at 'Applications from nominated' is required."); }
            if (data.appNomSpringTerm === "") { staticFieldsErrors.push("The field 'Spring Term' at 'Applications from nominated' is required."); }
            if (data.appProcedureEmail === "") { staticFieldsErrors.push("The field 'Contact Email' at 'Application procedure' is required."); }
            if (data.appProcedureWebsite === "") { staticFieldsErrors.push("The field 'Contact Website' at 'Application procedure' is required."); }
            if (data.addReqWeeks === "") { staticFieldsErrors.push("The field 'For Additional Requirements Decision' at 'Default Weeks Values' is required."); }
            if (data.TorWeeks === "") { staticFieldsErrors.push("The field 'For Transcript of Records issue' at 'Default Weeks Values' is required."); }
            if (data.additionalRequirements.length > 0) {
                data.additionalRequirements.map((item, index) => {
                    if (item.requirement === "") { staticFieldsErrors.push("The field 'Requirement' at 'Additional Requirement " + parseInt(index+1) + "' is required."); }
                    else if (item.requirement === "Academic requirements") {
                        if (data.ects === "" && data.eqf === "" && data.isced === "") { staticFieldsErrors.push("At least one of the 'ECTS', 'ISCED' and 'EQF' fields at 'Additional Requirement " + parseInt(index+1) + "' is required."); }
                    }
                    else if (item.details === "") { staticFieldsErrors.push("The field 'Details' at 'Additional Requirement " + parseInt(index+1) + "' is required."); }
                });
            }
            if (data.inclusionInfrastructure.length > 0) {
                data.inclusionInfrastructure.map((item, index) => {
                    if (item.name === "") { staticFieldsErrors.push("The field 'Infrastructure name' at 'Infrastructure " + parseInt(index+1) + "' is required."); }
                    if (item.specialNeeds === "") { staticFieldsErrors.push("The field 'Available infrastructure adjusted for people with' at 'Infrastructure " + parseInt(index+1) + "' is required."); }
                });
            }
            if (data.inclusionService.length > 0) {
                data.inclusionService.map((item, index) => {
                    if (item.name === "") { staticFieldsErrors.push("The field 'Service name' at 'Service " + parseInt(index+1) + "' is required."); }
                    if (item.specialNeeds === "") { staticFieldsErrors.push("The field 'Available support services for people with' at 'Service " + parseInt(index+1) + "' is required."); }
                });
            }
            if (data.housing_email === "" && data.housing_phone === "" && data.housing_website === "") { staticFieldsErrors.push("At least one of the 'Contact Email', 'Contact Phone' and 'Contact Website' at 'Housing is required."); }
            if (data.visa_email === "" && data.visa_phone === "" && data.visa_website === "") { staticFieldsErrors.push("At least one of the 'Contact Email', 'Contact Phone' and 'Contact Website' at 'Visa is required."); }
            if (data.insurance_email === "" && data.insurance_phone === "" && data.insurance_website === "") { staticFieldsErrors.push("At least one of the 'Contact Email', 'Contact Phone' and 'Contact Website' at 'Insurance is required."); }
            if (data.additionalInformation.length > 0) {
                data.additionalInformation.map((item, index) => {
                    if (item.information === "") { staticFieldsErrors.push("The field 'Information on' at 'Additional Information " + parseInt(index+1) + "' is required."); }
                });
            }
        }
        
        return callback(staticFieldsErrors);
    }
    
    renderRows(title, name, data) {
        if (data == undefined) {
			return (
			    <div className="column"><h3>It's not possible to set this at the moment, please contact the Dashboard administrator.</h3></div>
			);
        }
        if (data) {
            return (
                <div className="row">
                    <div className="column dashboard"><h3><input id="api_iia_dashboard" value={name} type="radio" name={name} onClick={() => this.setValue(name, 0)} /> No</h3></div>
                    <div className="column ewp"><h3><input id="api_iia_ewp" value={name} type="radio" name={name} onClick={() => this.setValue(name, 1)} defaultChecked /> Yes</h3></div>
                    <div className="column" id=""><button onClick={() => this.changeValue(name)}>Save</button></div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="column dashboard"><h3><input id="api_static_dashboard" value={name} type="radio" name={name} onClick={() => this.setValue(name, 0)} defaultChecked /> No</h3></div>
                    <div className="column ewp"><h3><input id="api_static_ewp" value={name} type="radio" name={name} onClick={() => this.setValue(name, 1)} /> Yes</h3></div>
                    <div className="column" id=""><button onClick={() => this.changeValue(name)}>Save</button></div>
                </div>
            );
        }
    }

    render() {
        if(GlobalVar.checkAccountFunction("ewpSettings")){
            if(this.props.data.loading || this.props.DefaultIIAData.loading || this.props.getInstitutionCodes.loading || this.props.checkEWPconnection.loading) { 
    			return (
    				<div className="card card_full_width card_full_height">
    					<div className="custom_table">
    						<p><img className="spinner" src="/icons/spinner.svg"/>Loading EWP API settings...</p>
    					</div>
    				</div>
    			);
    		} else {
                if(this.props.data.error){
                    const style = {"marginLeft": "10px", "marginTop": "12px"};
                    return (
                        <div className="content generalInfo">
                            <div className="card card_full_width">
                                <h2>Error loading EWP API settings. Please logout and try again</h2>
                                <p style={style}>If the problem persists, please contact the <a href="https://ewx.atlassian.net/servicedesk/customer/portals" 
target="_blank" rel="noopener noreferrer">ESCI Service Desk</a></p>
                            </div>
                        </div>
                    );
    			} else {
                    if (this.state.schac === "") { this.setState({ schac: this.props.getInstitutionCodes.getInstitutionCodes.hei_id }); }

                    if (this.props.data.listEWPAPIs.length > 0) { this.setStateValues(this.props.data.listEWPAPIs[0]); }      

                    if (!this.props.checkEWPconnection.checkEWPconnection.Success) {
                        return (
                            <div className="content upload">
                                <div className="card scrollbar card_full_width card_full_height" style={{ height: `calc(100% - 80px)` }}>
                                    <div className="custom_table" id="ewpPlatformSelection" style={{ height: "auto", width: "auto" }}>
                                        <div className="row">
                                            {/* <h2>In order to activate the EWP Network data exchange functionality, please indicate the top-level domain of your higher education institution, also known as SCHAC (e.g.: ub.edu, elte.hu, eur.nl, etc).</h2>
                                            <p>&nbsp;</p>
                                            <p>Please, note that the SCHAC code must be in lower case. Once this parameter is set it can no longer be updated by the user.</p>
                                            <p>&nbsp;</p> */}
                                            <p>Your SCHAC code is {this.state.schac}.</p>
                                            <p>&nbsp;</p>
                                            <p>In order to connect your Institution to the EWP network, please click on “Enable EWP”.</p>
                                        </div>
                                        <div className="row">
                                            {/* <div className="column" id="">
                                                <p><input type="text" onChange={(event) => this.changeSCHACValue(event)} placeholder="Enter SCHAC Code" /></p>
                                            </div> */}
                                            {/* <div className="column" id=""><button onClick={() => this.changeSCHAC()}>Save SCHAC</button></div> */}
                                            <div className="column" id=""><button onClick={() => this.changeSCHAC()}>Enable EWP</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return(
                            <div className="content upload">
                                <div className="card scrollbar card_full_width card_full_height" style={{ height: `calc(100% - 80px)` }}>
                                    <div className="custom_table" id="ewpPlatformSelection" style={{ height: "auto", width: "auto" }}>
                                        <div className="row header_row">
                                            <p>Current SCHAC code for the EWP Network: {this.state.schac}</p>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div className="row header_row">
                                            <h1>&nbsp;</h1>
                                            <h2>Do you agree that the Dashboard represents your higher education institution in the EWP Network to exchange inter-institutional agreements with your partners?</h2>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div className="row">
                                            <div className="row">
                                                {this.renderRows("InterInstitutional Agreements", "iia", this.state.apiIIA)}
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <h1>&nbsp;</h1>
                                            <h2>Do you agree that the Dashboard represents your higher education institution in the EWP Network to exchange the static information for inter-institutional agreements with your partners?</h2>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div className="row">
                                            <div className="row">
                                                {this.renderRows("InterInstitutional Agreements Static Data", "static", this.state.apiStatic)}
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <h1>&nbsp;</h1>
                                            <h2>Should the Dashboard represent your higher education institution in the EWP Network for the purpose of enabling the electronic exchange of the <strong> Online Learning Agreement </strong> information?</h2>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div className="row">
                                            <div className="row">
                                                <div className="row">
                                                    {this.renderRows("Online Agreements", "ola", this.state.apiOLA)}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            }
        } else {
            return (
                <div>
                    <div className={`content`}>
                        <div className={`card card_full_width card_full_height`}>
                            <h2 style={{"marginBottom": "15px"}}>You are not allowed to access the EWP Settings page</h2>
                            <div className="inside_content">
                                <p style={{"marginBottom": "5px"}}>Dear Erasmus Dashboard user,</p>
                                <p style={{"marginBottom": "5px"}}>Your Higher Education Institution has not granted you access to this page and its features.</p>
                                <p style={{"marginBottom": "5px"}}>If you think you should have access, please contact your main international relations office and/or
                                the main institutional accountholder of the Dashboard.</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mutationChangeSCHAC = gql`
	mutation ChangeEWPSCHAC($ErasmusCode: String!, $Schac: String!){
		changeEWPSCHAC(ErasmusCode: $ErasmusCode, Schac: $Schac){
            Success
            Message
	  }
	}
`;

const mutationChangeEWPAPIsIIA = gql`
	mutation ChangeEWPAPIsIIA($ErasmusCode: String!, $APIIIA: Boolean!){
		changeEWPAPIsIIA(ErasmusCode: $ErasmusCode, APIIIA: $APIIIA){
            Success
            Message
	  }
	}
`;

const mutationChangeEWPAPIsStatic = gql`
	mutation ChangeEWPAPIsStatic($ErasmusCode: String!, $APIStatic: Boolean!){
		changeEWPAPIsStatic(ErasmusCode: $ErasmusCode, APIStatic: $APIStatic){
            Success
            Message
	  }
	}
`;

const mutationChangeEWPAPIsOLA = gql`
	mutation ChangeEWPAPIsOLA($ErasmusCode: String!, $APIOLA: Boolean!){
		changeEWPAPIsOLA(ErasmusCode: $ErasmusCode, APIOLA: $APIOLA){
            Success
            Message
	  }
	}
`;

// const mutationChangeEWPAPIs = gql`
// 	mutation ChangeEWPAPIs($ErasmusCode: String!, $APIInst: Boolean!, $APIOUnit: Boolean!, $APIIIA: Boolean!, $APIInStu: Boolean!, $APIOutStu: Boolean!){
// 		changeEWPAPIs(ErasmusCode: $ErasmusCode, APIInst: $APIInst, APIOUnit: $APIOUnit, APIIIA: $APIIIA, APIInStu: $APIInStu, APIOutStu: $APIOutStu){
//             Success
//             Message
// 	  }
// 	}
// `;

const getEWPAPIsList = gql`
query listEWPAPIs($ErasmusCodeIn: String!) {
  listEWPAPIs(ErasmusCode: $ErasmusCodeIn) {
    institution_ecode
    institution_schac
    api_inst
    api_ounit
    api_iia
    api_outstudent
    api_instudent
    api_static
    api_ola
  }
}
`;

const getIIADefaultData = gql`
query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
    getIIADefaultData(ErasmusCode: $ErasmusCode isToCreate: $isToCreate) {
        stuNomAutumnTerm
		stuNomSpringTerm
		appNomAutumnTerm
		appNomSpringTerm
		appProcedureEmail
		appProcedurePhone
        appProcedureWebsite
        addReqWeeks
        ToRWeeks
        additionalRequirements {
            id
            requirement
            ects
            isced
            eqf
            details
            website
        }
        inclusionInfrastructure {
            id
            name
            specialNeeds
            description
            email
            phone
            website
        }
        inclusionService {
            id
            name
            specialNeeds
            description
            email
            phone
            website
        }
		housing_email
		housing_phone
		housing_website
		visa_email
		visa_phone
		visa_website
		insurance_email
		insurance_phone
        insurance_website
        additionalInformation {
            id
            information
            email
            phone
            website
        }
    }
}
`;

const getInstitutionCodes = gql`
    query getInstitutionCodes($ErasmusCode: String!) {
        getInstitutionCodes(ErasmusCode: $ErasmusCode) {
            hei_id
            erasmus
            erasmus_charter
            pic
            bectyl
            becol
            beco1
        }
    }
`;

const checkEWPconnection = gql`
    query checkEWPconnection($ErasmusCode: String!) {
        checkEWPconnection(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

export default compose(
    graphql(getEWPAPIsList, {
        options: (props) => {
            return {
                variables: {
                    ErasmusCodeIn: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    }),
    graphql(getIIADefaultData, {
        name: "DefaultIIAData",
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : "",
                    isToCreate: true
                }
            }
        }
    }),
    graphql(getInstitutionCodes, { 
        name: 'getInstitutionCodes',
        options: () => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    }),
    graphql(checkEWPconnection, {
        name: 'checkEWPconnection',
        options: () => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    }),
    graphql(mutationChangeSCHAC, { name: 'ChangeEWPSCHAC' }),
    // graphql(mutationChangeEWPAPIs,{ name : 'ChangeEWPAPIs'}),
    graphql(mutationChangeEWPAPIsIIA, { name: 'ChangeEWPAPIsIIA' }),
    graphql(mutationChangeEWPAPIsStatic, { name: 'ChangeEWPAPIsStatic' }),
    graphql(mutationChangeEWPAPIsOLA, { name: 'ChangeEWPAPIsOLA' })
)(Settings);
