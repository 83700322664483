import React from 'react';

function OtherreqTable(props) {

    const renderOtherreqRows = () => {
        let otherreq = props.otherreq;
        return otherreq.map((otherreqData, index, arrayRef) => {
			if(arrayRef.length === 1)
			     return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other requirements: {otherreqData}</span></li>
				)
				return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other requirements {index + 1}: {otherreqData}</span></li>
				)
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.otherreq !== undefined){
        return(
		<div>
                {renderOtherreqRows()}
				</div>
        )
    }
}

export default OtherreqTable;