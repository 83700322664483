import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from 'lodash';
import gql from "graphql-tag";
import Loader from "react-loader-spinner";
import GlobalVar from '../../GlobalVar';
import { query as getInZIP } from "../../queries/getIncomingNominationZIP";
import { query as getManagedByEWP } from '../../queries/getManagedByEWP';

class ExportNominationsInZIP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  render() {
    // check the permission for the user trying to export
    if(GlobalVar.checkAccountFunction("nominations")){
      if (!this.props.managedEWP.managedByEWP) {
        if (this.props.data.loading) {
        return (
          <div className={`nominationZip`}>
            <div className="card card_full_width">
              <div className="white_background">
                <Loader
                  className="spinner"
                  type="TailSpin"
                  color="#3986df"
                  height={70}
                  width={70}
                />
              </div>
            </div>
          </div>
        );
      }

      //var file = this.props.data.studentApplicationZip.base64;
      //console.log(file);
      //if(file){
      let zip = this.props.data.studentApplicationZip ? this.props.data.studentApplicationZip.base64 : "";

      const errormsg = this.props.data.studentApplicationZip.error;
      //const errormsg = "";
      // if (errormsg != "") {
      //   return (<p>An error occurred {errormsg}</p>);
      // }
      if (zip) {
        let byteCharacters = atob(zip);
        let byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], { type: "application/zip" });
        const fileURL = URL.createObjectURL(file);
        let studentName = this.props.data.incomingNomination.field_student_name;
        let zipName = studentName.replace(/\s+/g,"_");

        //const studentName = this.props.zipN;
        // const link = document.createElement('a');
        // link.href = fileURL;
        // link.setAttribute(
        //   'download',
        //   `documents.zip`,
        // );
        // // Append to html link element page
        // document.body.appendChild(link);
        // // Start download
        // link.click();
        //
        // window.open("", "_self");
        // window.close();
        return (
          <div className="content">
            <div className="card card_full_width card_full_height">
              <p>Download the ZIP by clicking <a href={fileURL} download={zipName+".zip"}>here</a>.</p>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={`content nomNoAccess`}>
          <div className="card card_full_height card_full_width">
            <div className="no_access nAccess">
              <h2>Access is denied</h2>
              <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
            </div>
          </div>
        </div>
      );
    }
    } else {
      return (
        <div className="content">
          <div className="card card_full_width card_full_height">
            <h2>You are not allowed to access the exported ZIP</h2>
            <div className="inside_content">
              <p>Dear Erasmus Dashboard user,</p>
              <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default compose(
  graphql(getInZIP, {
    options: (props) => ({
      variables: {
        uuid: props.match.params.zipUrl,
        id: props.match.params.zipUrl,
        erasmusCode: localStorage.getItem("ErasmusCode")
      },
    }),
  }),
  graphql(getManagedByEWP, {
    name: "managedEWP",
    options: (props) => ({
      variables: {
        erasmusCode: localStorage.getItem("ErasmusCode")
      }
    })
  })
)(ExportNominationsInZIP);