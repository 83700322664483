import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../GlobalVar';
import {query as isOLAManagedByEWP} from "../../queries/isOLAManagedByEWP";
import NoAccess from "./NoAccess";

const ResetButton = React.forwardRef((props, ref) => <input ref={ref} style={{display: "none"}} type={"reset"} defaultValue={"Reset"} />)

class UploadOLA extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedFile: false,
            selectedFileData: null,
            selectedFileInfo: null,
            selectedFileError: false,
            uploadResult: [],
            pendingUpload: false,
            checkedConsent: false,
            error: false,
            title: ""
        }
        this.fileReader = null;
        this.rowNum = 0;
        this.columnNum = 0
        this.allowedColumns = {
            "First Name": "firstName",
            "Last Name": "lastName",
            "Email": "email",
            "Start of mobility": "startOfMobility",
            "End of mobility": "endOfMobility",
            "Receiving faculty": "receivingFaculty",
            "Receiving institution": "receivingInstitution",
            "Receiving contact first name": "receivingContactFirstName",
            "Receiving contact last name": "receivingContactLastName",
            "Receiving contact email": "receivingContactEmail",
            "Receiving responsible first name": "receivingResponsibleFirstName",
            "Receiving responsible last name": "receivingResponsibleLastName",
            "Receiving responsible email": "receivingResponsibleEmail",
            "Sending contact first name": "sendingContactFirstName",
            "Sending contact last name": "sendingContactLastName",
            "Sending contact email": "sendingContactEmail",
            "Sending responsible first name": "sendingResponsibleFirstName",
            "Sending responsible last name": "sendingResponsibleLastName",
            "Sending responsible email": "sendingResponsibleEmail"
        }

        this.errorMessage = [];
        this.resetRef = React.createRef();
        this.fileRef = React.createRef();
    }

    checkConsent(event){
        this.setState({ checkedConsent : !this.state.checkedConsent });
    }

    csvHandler = (data) => {
        this.rowNum = 0;
        this.columnNum = 0
        this.errorMessage = [];
        const header = Object.keys(data.data[0])
        if(!this.validateColumnNames(header) && !this.validateData(data.data)) {
            const newData =  [...data.data]
            this.setState(state => ({
                selectedFile: true,
                selectedFileData: newData,
                selectedFileError: false
            }))
        } else {
            this.resetRef.current.click();
            this.setState(state => ({
                selectedFileError: true
            }))
        }
    }

    validate(files){
        if(files.length === 0) {
            this.setState({error: true, title: "Please select a CSV file"});
            return false;
        } else if(this.state.checkedConsent === false){
            this.setState({error: true, title: "Please give consent on the treatment of data"});
            return false;
        }
        return true;
    }


    fileHandler = (e) => {
        e.preventDefault();
        const files = this.fileRef.current.files;
        if(this.validate(files)){
            Papa.parse(files[0], {
                skipEmptyLines: true,
                header: true,
                complete: results => {
                    this.csvHandler(results)
                }
            });
        }
    }

    uploadHandler = async () => {
        this.setState(state => ({
            selectedFile: false,
            selectedFileInfo: null,
            selectedFileError: false,
            pendingUpload: true
        }), async () => {
            const convertedData = this.convertData(this.state.selectedFileData);
            this.updateProgress(convertedData.length, 0, "", false);
            const resultData = []

            //split the upload in smaller chunks
            var i,j,temparray,chunk = 5;
            for (i=0,j=convertedData.length; i<j; i+=chunk) {
                //add a new batch
                temparray = convertedData.slice(i,i+chunk);
                await this.batchUpload(temparray).then(data => { resultData.push(data); })
                //update progress
                var uploadResults = resultData.flat().map(item => <p>{item.data.createOLA3prefilled[0]}</p>);
                this.updateProgress(j, i+temparray.length, uploadResults, false);
            }

            //report on final progress
            var uploadResults = resultData.flat().map(item => <p>{item.data.createOLA3prefilled[0]}</p>);
            this.updateProgress(convertedData.length, convertedData.length, uploadResults, true);
        });
    }

    cancelHandler = () => {
        this.setState(state => ({
            selectedFile: false,
            selectedFileData: null,
            selectedFileInfo: null,
            selectedFileError: false,
            checkedConsent: false,
        }))
    }

    updateProgress(max, value, results, final) {
        var perc = Math.round(((value)/max)*100);

        this.setState(state => ({
            uploadResult: (
                <div style={{"textAlign":"center"}}>
                    <h3>Uploading Learning Agreements</h3>
                    <p style={{"fontStyle":"italic"}}>This can take a while, please do not close this page!</p>
                    <div id="progressbar">
                        <div style={{"width": perc+"%"}}><p>{perc}%</p></div>
                    </div>
                    <p style={{"fontWeight":"bold"}}>Completed {value} / {max}</p>
                    <div style={{"paddingTop":"20px"}}>
                        {results}
                        {final && (
                            <Fragment>
                                <p style={{"marginTop":"10px"}}>If you see some errors, please check the Common Errors page available {" "}
                                    <a rel="noopener noreferrer"
                                        target="_blank"
                                        href="//wiki.uni-foundation.eu/display/DASH/Common+errors+when+uploading+a+CSV+OLA">
                                            here
                                    </a>
                                </p>
                                <div style={{"paddingTop":"10px"}}><a href="/ola3/upload">Return to the upload start</a></div>
                            </Fragment>
                            ) }
                    </div>
                </div>
            ),
        }))
    }

    async batchUpload(array){
        var laPromise = [];
        array.forEach(async (element) => {
            const variables = {
                data: [element],
            }
            laPromise.push(this.props.createOLA3prefilled({variables}));
        })
        return await Promise.all(laPromise);
    }

    validateData(data) {
        const errors = {
            emailError: false,
            dateError: false,
            maxRowsError: false
        }
        for(var key in data){

          //check for empty lines
          if(data[key]["Email"] != ""){
            if(!(this.validateEmail(data[key]["Email"]) && this.validateEmail(data[key]["Receiving contact email"]) && this.validateEmail(data[key]["Receiving responsible email"]) && this.validateEmail(data[key]["Sending contact email"]) && this.validateEmail(data[key]["Sending responsible email"]))){
              errors.emailError = true;
              this.errorMessage.push("Email error in line "+(parseInt(key)+1)+": Check the email format of your data.");
            }
            if(!(this.validateDate(data[key]["Start of mobility"]) && this.validateDate(data[key]["End of mobility"]))){
              errors.dateError = true;
              this.errorMessage.push("Date error in line "+(parseInt(key)+1)+": Make your the dates are in dd/mm/yyyy format.");
            }
          } else {
            //it is an emtpy line, so remove it.
            data.splice(key, 1);
          }
        }
        if (data.length > 25){
          errors.maxRowsError = true;
          this.errorMessage.push("File error: You can only upload 25 LA's at once.");
        }
        return errors.maxRowsError || errors.emailError || errors.dateError;
    }

    validateColumnNames(columns) {
        let error = false;
        columns.forEach(element => {
            error = !Object.keys(this.allowedColumns).includes(element) ? true : error;
        });
        if (error) {
            this.errorMessage.push("Error: Invalid column names. ");
        }
        return error;
    }

    validateEmail(email) {
        email = email.replace(/\s+/g, '');
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(String(email).toLowerCase());
    }

    validateDate(date) {
        const dateString = String(date)
        const regEx = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        const parts = dateString.split("/");
        const d = new Date(parts[2], parts[1]-1, parts[0]);
        const dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return true;
    }

    convertData(data) {
        const header =  Object.keys(data[0])
        return data.map(item => {
                const newPrefilledLA = {}
                header.forEach(column => newPrefilledLA[this.allowedColumns[column]] = item[column])
                //Add Erasmus Code of current account as sending institution
                newPrefilledLA["sendingInstitution"] = localStorage.getItem("ErasmusCode");
                return newPrefilledLA;
            })
    }

    renderRow(rowData, header) {

        const rowClass =  header ? "row header_row" : "row";

        /*header ? null : rowData.splice(0,0, this.rowNum + 1)*/
        if(!header){
            rowData.splice(0,0, this.rowNum + 1);
        }

        const columns = rowData.map((column, index) => {
            return (
                <td className={["column", (isNaN(column) && column !== "#") ? null : "nr"].join(" ")} key={index}>
                    {header ? <h3>{column}</h3> : <p key={index}>{column}</p>}
                </td>
                )
        })
        this.columnNum = 0;
        /*return (
            <div className={rowClass} key={this.rowNum++ + "row"}>
                {columns}
            </div>
        )*/
        return (
            <tr className={rowClass} key={this.rowNum++ + "row"}>{columns}</tr>
        )
    }

    renderTable(data) {
        let header = Object.keys(data[0])
        const body = data.map(item => {
            const rowData = header.map(columnName => item[columnName]);
            return this.renderRow(rowData)
        })
        header.splice(0,0, "#")
        header = this.renderRow(header, true)

        return (
            <div className={"custom_table"} id="test">
            <table className="table_body">
                <thead>
                    {header}
                </thead>
                <tbody>
                    {body}
                </tbody>
            </table>
        </div>
        )
    }

    render() {

        let renderBody;
        let renderActions;
        if (this.props.isOLAManagedByEWP.loading) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg"/>Loading...
                        </p>
                    </div>
                </div>
            );
        } else {
            if (this.props.isOLAManagedByEWP.isOLAManagedByEWP.Success == 2) {
                if (!this.state.selectedFile) {
                    const renderError = this.state.selectedFileError ?
                        <h2 className="error">{this.errorMessage.join(", ")}</h2> : null;
                    renderBody = (
                        <div className={"upload-container scrollbar"}>
                            {!this.state.pendingUpload ? (
                                <form onSubmit={this.fileHandler} noValidate>
                                    <div className="sub_content">
                                        <h3>Upload CSV file to pre-fill the Online Learning Agreements</h3>
                                        <p>The Online Learning Agreement pre-filling functionality now supports the CSV
                                            upload.
                                            Upon entering the data you can overview the students list to make sure all
                                            the information
                                            is correct and submit the data. This action will trigger a creation of
                                            pre-filled
                                            Online Learning Agreements and a notification for the students inviting them
                                            to finalise the document.</p>
                                        <p>The format of the CSV file can be verified through the use of a template
                                            spreadsheet
                                            made available on the EWP CC {" "}
                                            <a rel="noopener noreferrer"
                                               target="_blank"
                                               href="//wiki.uni-foundation.eu/display/DASH/Creating+OLA">
                                                here
                                            </a>.
                                        </p>
                                        {this.state.error ? (
                                            <h2 className="error">{this.state.title}</h2>
                                        ) : null}
                                        {renderError}
                                        <input ref={this.fileRef} type={"file"} accept={".csv"}/>
                                        <div className="checkbox-wrapper">
                                            <input
                                                className="checkbox"
                                                type="checkbox"
                                                checked={this.state.checkedConsent}
                                                onChange={(event) => this.checkConsent(event)}
                                                required></input>
                                            <label>I confirm, also on behalf of my HEI, that I have all the
                                                authorisations,
                                                including the consent of the relevant natural persons, necessary to
                                                upload the
                                                personal data and information I am providing, pursuant to the
                                                Dashboard {' '}
                                                <Link
                                                    to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration") + "/termsandconditions"}>
                                                    Terms & Conditions and Privacy Policy</Link>.
                                            </label>
                                        </div>
                                        <button type="submit">Submit</button>
                                        <ResetButton ref={this.resetRef}/>
                                    </div>
                                </form>
                            ) : null}
                            {this.state.uploadResult}
                        </div>
                    );
                } else {
                    renderBody = this.renderTable([...this.state.selectedFileData])
                    renderActions = (
                        <div className={"card card_full_width actions"}>
                            <button onClick={this.uploadHandler}>Upload</button>
                            <button className={"warning_button"} onClick={this.cancelHandler}>Cancel</button>
                            <span>Please make sure that the data is compliant with the template available {" "}
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="//wiki.uni-foundation.eu/display/DASH/Creating+OLA">
                            here
                        </a>
                    </span>
                        </div>
                    );
                }

                return (
                    <div className={"content upload"}>
                        <div className={"card scrollbar card_full_width card_full_height"}>
                            {renderBody}
                        </div>
                        {renderActions}
                    </div>
                )
            } else {
                return (
                    <NoAccess/>
                );
            }
        }
    }
}

const createOLA3prefilled = gql`
    mutation CreateOLA3prefilled($data: [PrefilledLearningAgreementInput]) {
        createOLA3prefilled(data: $data)
    }
`

export default compose(
    graphql(createOLA3prefilled, {name: "createOLA3prefilled"}),
    graphql(isOLAManagedByEWP, {name: "isOLAManagedByEWP"
        ,
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    })
)(UploadOLA)
