import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../GlobalVar';

class Intro extends Component{
	render(){
		return (
			<div className="intro">
				<div className="intro_content">
					<div className="intro_card">

						<h2>Erasmus Dashboard</h2>
						<p>Erasmus Dashboard is a tool designed to support HEIs with the administration of mobility. It allows you to manage incoming and outgoing students, sign and review their Online Learning Agreements as well as communicate with students and partner institutions.<br/>Less paper. Less time wasted.<br/>And this is just the beginning!<br/><br/>*To get login credentials, please register and wait for account validation notification from the European University Foundation</p>
					</div>
					<div className="button_wrapper">
						{
							!this.props.location.state ? 
							<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "Account", "Login")}}>
								<button>Login</button>
							</Link> :
							<Link to={{ 
								pathname: GlobalVar.generateLinkUrl(intl, "Account", "Login"), 
								state: { restricted: true, urlRestricted: this.props.location.state.urlRestricted }}}>
								<button>Login</button>
							</Link>
						}
						<Link to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")}>
							<button id="register_button">Registration</button>
						</Link>
					</div>
				</div>
			</div>

		);
	}
}

export default Intro;
