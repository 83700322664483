import React, { Component } from 'react';
import CheckBox from './CheckBox';
import SelectCountry from './filters/SelectCountry';
import SelectInstitution from './filters/SelectInstitution';
import SignedBy from './filters/SignedBy';
import Uploaded from './filters/Uploaded';
import Search from './filters/Search';
import StartMobility from './filters/StartMobility';
import EndMobility from './filters/EndMobility';
import AcademicYear from './filters/AcademicYear';
import StudyCycle from './filters/StudyCycle';
import SubjectArea from './filters/SubjectArea';
import Help from '../../general/Help';

// Datepicker imports

import DatePicker from 'react-datepicker';
import moment from 'moment';

//import 'react-datepicker/dist/react-datepicker.css';


class Options extends Component {

  constructor(props) {
    super(props);

    this.allColumns = ["lastname","email","startMobility","endMobility", "uploaded", "nationality","phone","gender","academicYear","firstname","language","languagelevel","signed","subjectArea","facultySend","departmentSend","facultyRecive","departmentRecive","cycle","receiveContactname","receiveEmail","receivePhone","receiveFunction", "receiveResponsibleContactname", "receiveResponsibleEmail", "receiveResponsiblePhone", "receiveResponsibleFunction","sendingContactname", "sendingEmail", "sendingPhone", "sendingFunction", "sendingResponsibleName","sendingResponsibleEmail","sendingResponsiblePhone","sendingResponsibleFunction", "sendingErasmusCode", "sendingInstitution", "sendingCountry", "receivingErasmusCode", "receivingInstitution", "country", "homeCourses","hostCourses", "signedChanges", "homeCoursesChanges", "hostCoursesChanges"];

    this.state = {value : "" ,startDateFilter: moment() , endDateFilter : moment(), reloadState: false}

  }

  parseDate(date) {
    date = date.toString().slice(0, -2) + ":" + date.toString().slice(-2);
    const newDate = new Date(date);
    let year = newDate.getFullYear();
    let mon = newDate.getMonth()+1;
    let day = newDate.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (mon < 10) {
      mon = '0' + mon;
    }
    return day+'-'+mon+'-'+year;
  }

  generateID(filterName){
    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
      const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
      if(filters[filterName]){
        return filters[filterName];
      }
    }
    else{
      return "";
    }
  }


  clearAll(event){
    event.preventDefault();
    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
      //console.log("resetting");
      localStorage.setItem("filters"+this.props.typeOfStudentName, '{"signedBefore":"-1","signedDuring":"-1"}');
      this.setState({
        reloadState: !this.state.reloadState
      })
      this.props.filterIsApplied();
    }
  }


  selectAllColumns(event){
    event.preventDefault();
    localStorage.setItem("columnValues"+this.props.typeOfStudentName, JSON.stringify(this.allColumns));
    this.props.filterIsApplied();
  }

  unSelectAllColumns(event){
    event.preventDefault();
    localStorage.removeItem("columnValues"+this.props.typeOfStudentName);

    this.props.filterIsApplied();
  }

  componentWillMount() {
    this.setSelection();
  }

  componentWillReceiveProps(nextProps) {
    this.setSelection();
  }

  setSelection(){
    let filterStartDate;
    let filterEndDate

    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
      const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
      if(filters["startMobility"] === null || filters["endMobility"] === null){
          filterStartDate = moment();
          filterEndDate = moment();
      }
      else{
          filterStartDate = moment(filters["startMobility"]);
          filterEndDate = moment(filters["endMobility"]);
      }
      this.setState({
        value : filters["signedBy"],
        startDateFilter : filterStartDate,
        endDateFilter : filterEndDate
      })
    }
  }
  renderSelectAllButton(){
    if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
      const columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
      if(columnValues.length == this.allColumns.length){
        return(
          <form onSubmit={(event) => this.unSelectAllColumns(event)} className="header_button text_button_wrapper">
            <button>Unselect all</button>
          </form>
        )
      }
    }
    return(
      <form onSubmit={(event) => this.selectAllColumns(event)} className="header_button text_button_wrapper">
        <button>Select all</button>
      </form>
    )
  }


  handleChangeEnd(date) {
    this.setState({
      endDateFilter: date
    });
    let filters = {};
    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
      filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
    }
    filters["endMobility"] = date;
    localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
    this.props.filterIsApplied();
  }


	render() {
  	return (
  		<div className="card card_full_width card_full_height">
  			<div className="option_wrapper">
          <Search typeOfStudents={this.props.typeOfStudents} typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} />
          <header>
            <h3>Filters</h3>
            <form onSubmit={(event) => this.clearAll(event)} className="header_button text_button_wrapper">
              <button>Clear all</button>
            </form>
          </header>

 					<ul>

            <li>
              <span>Signed before</span>
              <SignedBy
                typeOfSigning={"signedBefore"}
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                SearchQuery={this.generateID("search")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Signed during</span>
              <SignedBy
                typeOfSigning={"signedDuring"}
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                SearchQuery={this.generateID("search")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
 						<li>
              <span>Country</span>
              <SelectCountry
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Institution</span>
              <SelectInstitution
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Start Mobility</span>
              <StartMobility
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
                parseDate={this.parseDate}
              />
            </li>
            <li>
              <span>End Mobility</span>
              <EndMobility
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
                parseDate={this.parseDate}
              />
            </li>
            <li>
              <span>Uploaded<Help title="Uploaded student" description="Uploaded students are those added to the system by the sending institution (as of 23.03.2018). Students who are not uploaded have initiated the Online Learning Agreement process themselves." /></span>
              <Uploaded
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Academic year</span>
              <AcademicYear
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                StudyCycle={this.generateID("studyCycle")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Study cycle</span>
              <StudyCycle
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                SubjectArea={this.generateID("subjectArea")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
            <li>
              <span>Subject area</span>
              <SubjectArea
                typeOfStudents={this.props.typeOfStudents}
                typeOfStudentName={this.props.typeOfStudentName}
                SignedBefore={this.generateID("signedBefore")}
                SignedDuring={this.generateID("signedDuring")}
                CountryID={this.generateID("country")}
                InstitutionID={this.generateID("institution")}
                SearchQuery={this.generateID("search")}
                StartMobility={this.generateID("startMobility")}
                EndMobility={this.generateID("endMobility")}
                Uploaded={this.generateID("uploaded")}
                AcademicYear={this.generateID("academicYear")}
                StudyCycle={this.generateID("studyCycle")}
                filterIsApplied={this.props.filterIsApplied}
                reloadState={this.state.reloadState}
              />
            </li>
 					</ul>

 				</div>
 				<div className="option_wrapper option_wrapper_columns">
          <header>
   					<h3>Columns</h3>
            {this.renderSelectAllButton()}
          </header>
 					<ul>
 						<CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="First name" uniqueName="firstname" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Last name" uniqueName="lastname" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Email" uniqueName="email" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Start mobility" uniqueName="startMobility" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="End mobility" uniqueName="endMobility" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Uploaded" uniqueName="uploaded" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Nationality" uniqueName="nationality" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Phone" uniqueName="phone" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Gender" uniqueName="gender" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Academic year" uniqueName="academicYear" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Language name" uniqueName="language" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Language level" uniqueName="languagelevel" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Subject Area" uniqueName="subjectArea" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Sending faculty" uniqueName="facultySend" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Sending department" uniqueName="departmentSend" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Receiving faculty" uniqueName="facultyRecive" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Receiving department" uniqueName="departmentRecive" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Study cycle" uniqueName="cycle" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host contact name" uniqueName="receiveContactname" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host contact email" uniqueName="receiveEmail" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host contact phone" uniqueName="receivePhone" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host contact function" uniqueName="receiveFunction" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host responsible name" uniqueName="receiveResponsibleContactname" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host responsible email" uniqueName="receiveResponsibleEmail" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host responsible phone" uniqueName="receiveResponsiblePhone" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host responsible function" uniqueName="receiveResponsibleFunction" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home contact name" uniqueName="sendingContactname" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home contact email" uniqueName="sendingEmail" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home contact phone" uniqueName="sendingPhone" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home contact function" uniqueName="sendingFunction" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home responsible name" uniqueName="sendingResponsibleName" division={true} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home responsible email" uniqueName="sendingResponsibleEmail" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home responsible phone" uniqueName="sendingResponsiblePhone" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home responsible function" uniqueName="sendingResponsibleFunction" />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Receiving Erasmus code" uniqueName="receivingErasmusCode" division={true} InstitutionStatus={"Outgoing"} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Receiving institution" uniqueName="receivingInstitution" InstitutionStatus={"Outgoing"} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Country" uniqueName="country" InstitutionStatus={"Outgoing"} />

            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Sending Erasmus code" uniqueName="sendingErasmusCode" division={true} InstitutionStatus={"Incoming"} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Sending institution" uniqueName="sendingInstitution" InstitutionStatus={"Incoming"} />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Country" uniqueName="sendingCountry" InstitutionStatus={"Incoming"} />

            <li className="list_subtitle">Before mobility</li>
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Signed by" uniqueName="signed" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home courses" uniqueName="homeCourses" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host courses" uniqueName="hostCourses" />

            <li className="list_subtitle">During mobility</li>
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Signed by" uniqueName="signedChanges" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Home courses" uniqueName="homeCoursesChanges" />
            <CheckBox typeOfStudentName={this.props.typeOfStudentName} filterIsApplied={this.props.filterIsApplied} title="Host courses" uniqueName="hostCoursesChanges" />
 					</ul>
 				</div>
  		</div>
    	);
    }

};
export default Options;
