import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Options from './Options';
import Data from './Data';

import { query as countOfIIA } from '../../../queries/countOfIIA';
import NoAccess from "./NoAccess";

/**
 * This component is used to list the IIA's which correspond to the user's Erasmus Code
 * When rendering the web page, it communicates with two components: 
 * - The Options: which renders the options to interact with the list, such as selecting sorts and filters; 
 * - The Data: which interacts with the backend and makes a query to select all IIA's with the given filters and sorts;
 * This component also generates the buttons for pagination.
 * This component interacts with the backend query isIIAManagedByEWP and the mutation countOfIIA
*/
class IIAList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadActions: false,  //just to reload the Action component
      actionName: "",
      
      currentOffset: 0,     // used to keep track of the current offset and re-render the page in case it changes
      currentPage: 1,       // used to keep track of the current page (default if 0) and re-render the page in case it changes
      lastPage: 0           // used to keep track of the last page (default if 0) and re-render the page in case it changes. This value is only altered when a CountOfIIA is executed
    }

    this.totalResults = 0;
    this.listData = null;
    this.newSettings = {
      sortField: "iia_id_p1",
      sortDirection: "ASC",
      status: [],
      academicYear: [],
      partnerEC: [],
      modifiedSince: "",
      search: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleApplyChanges = this.handleApplyChanges.bind(this);
    this.reloadActions = this.reloadActions.bind(this);
    
    localStorage.setItem("Offset", "0"); 
  }

  componentDidMount() {
    this.handleApplyChanges(this.newSettings);
  }

  generateIIAListAuxiliarInformation() {
    let iiaListAuxiliarInformation = {
      editActivated: false,
      editPageNumber: -2,
      backDisabled: true,
      nextDisabled: true,
      deleteDisabled: true,
      addDisabled: true,
      updateDisabled: true,
      signDisabled: false,
      hasChangesPartners: false,
      hasChangesConditions: false,
      creatorOUnitList: [],
      partnerOUnitList: [],
      showMobFields: "" 
    }

    sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(iiaListAuxiliarInformation));
  }

  reloadActions() {
    this.setState({
      reloadActions: !this.state.reloadActions
    });
  }

  render() {
    if(this.props.data.loading ){
      return (
          <div className="card card_full_width card_full_height">
            <div className="custom_table">
              <p><img className="spinner" src="/icons/spinner.svg" />Loading Inter-Institutional Agreements...</p>
            </div>
          </div>
      );
    }
    else {
      if (this.props.data.isIIAManagedByEWP.Success === 2) {
        if (JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation")) === null) {
          this.generateIIAListAuxiliarInformation();
          return (<></>)
        } else {
          return this.renderList();
        }
      } else {
        return (
            <NoAccess/>
        );
      }
    }
  }

  /**
   * Increments or Decreases value in both offset and current page value
   */
  changePage(nextPage) {
    let newPage = this.state.currentPage + nextPage;
    let newOffset = newPage * 10 - 10;
    localStorage.setItem("Offset", newOffset.toString());

    this.setState({ currentOffset: newOffset, currentPage: newPage }, () => {
      this.handleApplyChanges(this.newSettings);
    });
  } 
    
  changePageFixedValues(newPage) {
    // Example: if it's page sent as parameter is 3, then the newOffset will be 3*10-10 = 20. That is, the backend will construct a query like: "[...] LIMIT 20,10"
    let newOffset = newPage * 10 - 10; 
    localStorage.setItem("Offset", newOffset.toString());

    this.setState({ currentOffset: newOffset, currentPage: newPage }, () => {
      this.handleApplyChanges(this.newSettings);
    });
  } 


  /**
    * Calls the countOfIIA in order to set the corresponding "lastPage" value
  */
  count(){
    let { sortField, sortDirection, status, academicYear, partnerEC, modifiedSince, search } = this.newSettings;
    this.props.countOfIIA({
      variables: {
        "ErasmusCode": localStorage.getItem("ErasmusCode"),
        "Status": status, 
        "AcademicYear": academicYear, 
        "PartnerEC": partnerEC, 
        "ModifiedSince": modifiedSince, 
        "Search": search,
      }
    }).then((result) => {
        let countS = JSON.stringify(result.data.countOfIIA);
        let pars = JSON.parse(countS);
        
        console.log("New settings count: " + this.newSettings);
        console.log("New settings count status: " + this.newSettings.status);
        console.log("New settings count status seperated: " + status);
        console.log("You Have This Results: " + pars[0].count);
        console.log("Current Last Page: " + this.state.lastPage);
        this.totalResults = pars[0].count;
 
        // If the "lastPage" variable has not been set, configures it with the correct value
        console.log("New Last Page: " + this.state.lastPage)
        this.setState({
          lastPage: Math.ceil(pars[0].count/10)  // Example, if the database has 114 results, the max number of pages will be 12
        });
    });
    
  }

  buttons() {
    if (this.totalResults < 10) {
      return (<div>
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <input style={{ height: "22px", marginLeft: "10px" }} type="text" name="number" placeholder="Page Number" />
          <button style={{ marginLeft: "10px" }} type="submit">Go To</button>
        </form>
      </div>
      )
    }

    // If it's the first page, only shows the Next Page and Last Page Buttons, as well as the form to insert the value of the desired page
    if (this.state.currentPage == 1) {
      return (
        <div>
          <button style={{ marginLeft: "10px" }} onClick={() => this.changePage(1)}>Next Page</button>
          <button style={{ marginLeft: "10px" }} onClick={() => this.changePageFixedValues(this.state.lastPage)}>Last Page</button>
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <input style={{ height: "22px", marginLeft: "10px" }} type="text" name="number" placeholder="Page Number" />
            <button style={{ marginLeft: "10px" }} type="submit">Go To</button>
          </form>
        </div>
      );
    };

    // If it's the last page, only shows the First Page and Previous Page Buttons, as well as the form to insert the value of the desired page
    if (this.state.currentPage === this.state.lastPage) {
      return (
        <div><button style={{ marginLeft: "10px" }} onClick={() => this.changePageFixedValues(1)}>First Page</button>
          <button style={{ marginLeft: "10px" }} onClick={() => this.changePage(-1)}>Previous Page</button>
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <input style={{ height: "22px", marginLeft: "10px" }} type="text" name="number" placeholder="Page Number" />
            <button style={{ marginLeft: "10px" }} type="submit">Go To</button>
          </form>
        </div>
      );
    }

    // Otherwise, returns all of the previously described elements
    return (
      <div> <button style={{ marginLeft: "10px" }} onClick={() => this.changePageFixedValues(1)}>First Page</button>
        <button style={{ marginLeft: "10px" }} onClick={() => this.changePage(-1)}>Previous Page</button>
        <button style={{ marginLeft: "10px" }} onClick={() => this.changePage(1)}>Next Page</button>
        <button style={{ marginLeft: "10px" }} onClick={() => this.changePageFixedValues(this.state.lastPage)}>Last Page</button>
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <input style={{ height: "22px", marginLeft: "10px" }} type="text" name="number" placeholder="Page Number" />
          <button style={{ marginLeft: "10px" }} type="submit">Go To</button>
        </form>
      </div>
    );
  };

  /**
   * If user inserts the value of a desired page, goes to that page, if it's a valid value
   * @param {*} event 
   */
  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    for (let number of data.keys()) {
      const input = form.elements[number];
      let current = parseInt(input.value);

      // Validates if the inserted number is an integer. If that is the case, doesn't alter the value of offset and page
      if (!Number.isInteger(current)) {
        this.setState(prevState => {
          return { currentOffset: this.state.currentOffset, currentPage: this.state.currentPage }
        });
      }
      else {
        // If the number is lesser than 0, shows page 1
        if (current <= 0) {
          this.changePageFixedValues(1);
        }
        // If the number is bigger than the last page, shows page "lastPage"
        else if (current > this.state.lastPage) {
          this.changePageFixedValues(this.state.lastPage);
        }
        // Otherwise, goes to the inserted page
        else {
          this.changePageFixedValues(current);
        }
      }
    }
  }

  handleApplyChanges(newSettings) {
    let { sortField, sortDirection, status, academicYear, partnerEC, modifiedSince, search } = newSettings;
    this.newSettings = newSettings;

    this.props.getIIAListFiltered({
      variables: {
        "ErasmusCode": localStorage.getItem("ErasmusCode"),
        "SortField": sortField,
        "SortDirection": sortDirection,
        "Status": status,
        "AcademicYear": academicYear,
        "PartnerEC": partnerEC,
        "ModifiedSince": modifiedSince,
        "Search": search,
        "Offset": this.state.currentOffset
      }
    })
      .then((iiaList) => {
        this.listData = iiaList.data.getIIAListFiltered;
        console.log("Got new list: " + JSON.stringify(iiaList.data.getIIAListFiltered));
        this.count();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderList() {
    let iiaListAuxiliarInformation = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

    if (this.props.history.location.pathname === "/iia-manager/iia-list") {
      sessionStorage.removeItem("iiaListPartnersDetails");
      sessionStorage.removeItem("iiaListOriginalPartnersDetails");
      sessionStorage.removeItem("iiaListCoopConditions");
      sessionStorage.removeItem("iiaListOriginalCoopConditions");
      sessionStorage.removeItem("iiaListAuxiliarInformation");

      this.generateIIAListAuxiliarInformation();
      iiaListAuxiliarInformation = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

      if (this.props.data.error) {
        return (
          <div className="card card_full_width card_full_height">
            <div className="custom_table">
              <p><img className="spinner" src="/icons/spinner.svg" />Error loading Inter-Institutional Agreements...</p>
            </div>
          </div>
        );
      }

      return (
        <div className="card card_full_width card_full_height">
          <h2 style={{ marginBottom: "5px" }}>Page {this.state.currentPage} of {this.state.lastPage}</h2>
          {this.buttons()}
          <Options
            data={this.props.data}
            handleApplyChanges={this.handleApplyChanges}
            totalResults={this.totalResults}
          />
          <Data
            data={this.props.data}
            listIIA={this.listData}
            reloadActions={this.reloadActions}
            filterIsApplied={this.filterIsApplied}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            ewpStatus={this.props.data.isIIAManagedByEWP.Success}
          />
        </div>
      );
    }
    return (
      <React.Fragment />
    );
  }
};

const isIIAManagedByEWP = gql`
	query isIIAManagedByEWP($ErasmusCode: String!) {
		isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
			Success
			Message
	  }
	} 
`;

const getIIAListFiltered = gql`
  mutation getIIAListFiltered($ErasmusCode: String!, $SortField: String, $SortDirection: String, $Status: [String], $AcademicYear: [String], $PartnerEC: [String], $ModifiedSince: String, $Search: String, $Offset: Int) {
    getIIAListFiltered(ErasmusCode: $ErasmusCode, SortField: $SortField, SortDirection: $SortDirection, Status: $Status, AcademicYear: $AcademicYear, PartnerEC: $PartnerEC, ModifiedSince: $ModifiedSince, Search: $Search, Offset: $Offset) {
      iia_code_p1
      iia_id_p1
      iia_code_p2
      iia_id_p2
      creator_contact
      partner_contact
      creator_signature_date
      creator_signature_contact
      partner_signature_date
      partner_signature_contact
      creator_institution_code
      partner_institution_code
      typeOfCommunication
      inEffect
    }
  }
`;


export default compose(
  graphql(isIIAManagedByEWP, {
    options: (props) => {
        return {
            variables: {
              ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
            }
        }
    }
  }),
  graphql(countOfIIA, { name: "countOfIIA" }),
  graphql(getIIAListFiltered, { name: "getIIAListFiltered" })
)(IIAList);
