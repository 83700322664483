import gql from 'graphql-tag';

const query = gql`
    query ($homeErasmusCode: String!) {
        nominationHostHEI (homeErasmusCode: $homeErasmusCode) {
            hostErasmusCode
            hostName
        }
    }
`;
export { query };
