import gql from 'graphql-tag';

const query = gql`
	query{
		institution(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
		    NameInLocalCharacterSet
		    NameInLatinCharacterSet
		    PIC
		    ErasmusCode
		    EndDate
		    City
		    Country
		    InstitutionDetail{
      			Description
	      		Email
	      		Website
      			CourseCatalogue
		  		Phone
		  		SHAC
		    }
	  	}
	}
`;


export { query };