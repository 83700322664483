import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from 'lodash';
import gql from "graphql-tag";
import Loader from "react-loader-spinner";
import GlobalVar from '../../GlobalVar';

import { query as getPDF } from "../../queries/getShortTermPDF";

class ExportPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  render() {
    // check the permission for the user trying to export
    if(GlobalVar.checkAccountFunction("managingStudents,viewingStudents")){
      if (this.props.data.loading) {
        return (
          <div>
            <div className="white_background">
              <Loader
                className="spinner"
                type="TailSpin"
                color="#3986df"
                height={70}
                width={70}
              />
            </div>
          </div>
        );
      }

      var file = this.props.data.shortTermPdf.file;

      if(file){
        let pdf = this.props.data.shortTermPdf ? this.props.data.shortTermPdf.file : "";
        const errormsg = this.props.data.shortTermPdf.error;

        if(errormsg != ""){
          return(<p>An error occured{errormsg}</p>);
        } else if (pdf) {
          let byteCharacters = atob(pdf);
          let byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);
          let file = new Blob([byteArray], { type: "application/pdf;base64" });
          const fileURL = URL.createObjectURL(file);

          return (
            <object
              style={{ width: window.innerWidth - 201 }}
              height="100%"
              type="application/pdf"
              data={fileURL}
            >
            <p>Download the PDF by clicking <a href={fileURL}>here</a>.</p>
            </object>
          );
        }
      } else {
        return (
      		<div className="content">
      			<div className="card card_full_width card_full_height">
      				<h2>This Learning Agreement does not belong to your institution</h2>
      				<div className="inside_content">
                <p>You are trying to export a Learning Agreement that doesn't belong to your Higher Education Institution</p>
      				</div>
      			</div>
      		</div>
      	);
      }
    } else {
      return (
    		<div className="content">
    			<div className="card card_full_width card_full_height">
    				<h2>You are not allowed to access the Export PDF page</h2>
    				<div className="inside_content">
    					<p>Dear Erasmus Dashboard user,</p>
              <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
    				</div>
    			</div>
    		</div>
    	);
    }
  }
}

export default compose(
  graphql(getPDF, {
    options: (props) => ({
      variables: {
        ID: props.match.params.id,
        VID: props.match.params.vid,
        eCode: JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode,
        encryptedECode: localStorage.getItem('ErasmusCode'),
      },
    }),
  })
)(ExportPDF);