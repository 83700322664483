import React from 'react';

function RevisionsTable(props) {
    
    const parseDate = (date) => {
        const newDate = new Date(date);
        let year = newDate.getFullYear();
        let mon = newDate.getMonth()+1;
        let day = newDate.getDate();
        let hour = newDate.getHours();
        let min = newDate.getMinutes();
        let sec = newDate.getSeconds();
        if (day < 10) {
          day = '0' + day;
        }
        if (mon < 10) {
          mon = '0' + mon;
        }
        if (hour < 10) {
          hour = '0' + hour;
        }
        if (min < 10) {
          min = '0' + min;
        }
        if (sec < 10) {
          sec = '0' + sec;
        }
        return year+'-'+mon+'-'+day+' '+hour+':'+min+':'+sec;
    }

    const renderRevisionsRows = () => {
        let revisions = props.revisions;
        return revisions.map((revisionData, index) => {

            return(
                <div className="row" key={index}>
                    <div className="column id"><p>{revisionData.vid}</p></div>
                    <div className="column date"><p>{parseDate(revisionData.revision_timestamp)}</p></div>
                    <div className="column status"><p className="capitalize">{props.parseStatus(revisionData.status)}</p></div>
                    <div className="column download">
                        <p>
                            <a 
                                className="button" 
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`/short-term/export/${props.shortTermID}/${revisionData.vid}`}>
                                    Download Revision
                            </a>
                        </p>
                    </div>
                </div>
            )
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.revisions !== undefined){
        if(props.revisions.length === 0){
            return <p className="italic">No revisions to show</p>;
        }
        return(
            <div className={classNameTable}>
                <div className="row header_row">
                    <div className="column id"><p>ID</p></div>
                    <div className="column date"><p>Date</p></div>
                    <div className="column status"><p>Status</p></div>
                </div>
                {renderRevisionsRows()}
            </div>
        )
    }
}

export default RevisionsTable;