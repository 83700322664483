import React, { Component, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from './../../../GlobalVar';

class NominationFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			optionalFilters:{
				academicYear: "",
				academicTerm: "",
				studentName: "",
				nominationStatus: "",
				studentEmail: "",
				studentEQF: "",
				studentISCED: ""
				// studentESI: ""
			}
		}
	}

	handleInputChange(event) {
		const { optionalFilters } = this.state;
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		optionalFilters[name] = value;
		this.setState({
			optionalFilters
		}, () => { console.log(this.state) });
		//console.log(optionalFilters);
	}

	sendOptionalFiltersData(){
		this.props.sendData(this.state.optionalFilters)
	}

	resetFilters () {
		this.state.optionalFilters = {
			academicYear: '',
			academicTerm: '',
			studentName: '',
			nominationStatus: '',
			studentEmail: '',
			studentEQF: '',
			studentISCED: ''
			// studentESI: '',
		}
		this.props.sendData(this.state.optionalFilters)
	}

	render() {

		return (
			<div className="sub_content">
				<h2 style={{ color: '#4a90e2' }}>Filters</h2>
				<div className="row">
					<div className="column_long">
						<br/>
						<input type="text" name="studentName" style={{width:250}} value={this.state.optionalFilters.studentName} placeholder="Search name" onChange={(event) => this.handleInputChange(event)} />
						<p id="error_title"/>
						<div></div>
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<br/>
						<input type="text" name="studentISCED" style={{width:250}} value={this.state.optionalFilters.studentISCED} placeholder="Search isced" onChange={(event) => this.handleInputChange(event)} />
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<br/>
						<input type="text" name="studentEmail" style={{width:250}} value={this.state.optionalFilters.studentEmail} placeholder="Search email" onChange={(event) => this.handleInputChange(event)} />
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<br/>
						<label>
							<select name="nominationStatus" style={{width:250}} value={this.state.optionalFilters.nominationStatus} placeholder="Select status" onChange={(event) => this.handleInputChange(event)}>
								<option value="" selected="selected">Select status</option>
								<option value="accepted_by_student">Accepted by student</option>
								<option value="accepted_by_receiving">Accepted by receiving</option>
								<option value="cancelled">Cancelled</option>
							</select>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<label>
							<select name="studentEQF" style={{width:250}} value={this.state.optionalFilters.studentEQF} placeholder="Select eqf levels" onChange={(event) => this.handleInputChange(event)}>
								<option value="" selected="selected">Select eqf levels</option>
								<option value="Associate degree">Associate degree</option>
								<option value="Bachelor's degree">Bachelor's degree</option>
								<option value="Master's degree">Master's degree</option>
								<option value="Doctorate degree">Doctorate degree</option>
								<option value="Other">Other</option>
							</select>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<label>
							<select name="academicYear" style={{width:250}} value={this.state.optionalFilters.academicYear} placeholder="Select academic year" onChange={(event) => this.handleInputChange(event)}>
								<option value="" selected="selected">Select academic year</option>
								<option value="2021/2022">2021 - 2022</option>
								<option value="2022/2023">2022 - 2023</option>
								<option value="2023/2024">2023 - 2024</option>
							</select>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="column_long">
						<label>
							<select name="academicTerm" style={{width:250}} value={this.state.optionalFilters.academicTerm} placeholder="Select academic term" onChange={(event) => this.handleInputChange(event)}>
								<option value="" selected="selected">Select academic term</option>
								<option value="First semester (Winter/Autumn)">First semester (Winter/Autumn)</option>
								<option value="Second semester (Summer/Spring)">Second semester (Summer/Spring)</option>
								<option value="First trimester">First trimester</option>
								<option value="Second trimester">Second trimester</option>
								<option value="Full academic year">Full academic year</option>
							</select>
						</label>
					</div>
				</div>
				<div className="row">
					<br/>
					<div className={"nominationPage_button-set_outer"}>
						<div className={"nominationPage_button-set_inner"}><button className={"nominationPage_button"} onClick={() => this.sendOptionalFiltersData()}>Apply Filters</button></div>
						<div className={"nominationPage_button-set_inner"}><button className={"nominationPage_button"} onClick={() => this.resetFilters()}>Reset Filters</button></div>
					</div>
				</div>
			</div>
		)
	}
}

export default NominationFilter;
