import gql from 'graphql-tag';

const query = gql`
query getSingleDocument($title: String){
    document(title: $title){
	    id
	    title
	    description
    }
    documents{
	    id
	    title
	    description
    }
}
`
export { query };
