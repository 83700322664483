import React, { Component } from 'react';
import { Button as ReactButton, Collapse } from 'reactstrap';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Title from '../common/Title';
import Label from '../common/Label';
import GlobalVar from '../../../GlobalVar';

class Summary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshPage: false,
            creatorDefaultOpen: false,
            partnerDefaultOpen: false,
            coopCondDefaultOpen: true,
            partnerStaticData: null,
            isCreatorInEWP: -2,
            isPartnerInEWP: -2,
            errorMessage: ""
        }

        this.handleSelection = this.handleSelection.bind(this);
        this.toogleCreator = this.toogleCreator.bind(this);
        this.tooglePartner = this.tooglePartner.bind(this);
        this.toogleCoopCond = this.toogleCoopCond.bind(this);

        this.checkIfPartnersIIAisManagedByEWP();
    }

    render() {
        if (!this.props.creatorDefaultData.loading && !this.props.partnerDefaultData.loading && !this.props.getIscedFCodesList.loading) {
            const creatorData = this.props.creatorDefaultData.getIIADefaultData;
            if (this.state.isPartnerInEWP === 2) { this.state.partnerStaticData = this.props.partnerDefaultData.getIIADefaultData; }

            return (
                <div className="card scrollbar card_full_width card_full_height">
                    {this.showTitle()}
                    {this.showData(creatorData, this.state.partnerStaticData)}
                </div>
            );
        }
        return (
            <div className="content upload">
                <Title text="Loading and setting up all forms..." />
                <h4>If loading is taking a while, please refresh the page.</h4>
            </div>
        );
    }

    showTitle() {
        return (
            <Title text="Information on Digital Inter-Institutional Agreements" />
        );
    }

    showData(creatorData, partnerData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        const partners = JSON.parse(localStorage.getItem("partnersDetails"));

        let creatorDataCode = <div />
        if (!creatorData) {
            creatorDataCode = <React.Fragment>
                <h3>No information was retrieved from the creator.</h3>
            </React.Fragment>
        }
        else {
            creatorDataCode = <React.Fragment>
                {this.renderCalendar(creatorData)}
                {this.renderAdditionalRequirements(creatorData)}
                {this.renderInfrastructure(creatorData)}
                {this.renderSupportServices(creatorData)}
                {this.renderHousing(creatorData)}
                {this.renderVisa(creatorData)}
                {this.renderInsurance(creatorData)}
                {this.renderAdditionalInformation(creatorData)}
            </React.Fragment>
        }

        let partnerDataCode = <div />
        if (partnerData === null || partnerData === undefined) {
            if(this.state.errorMessage != "") {
                partnerDataCode =
                    <React.Fragment>
                        <h3>{this.state.errorMessage}</h3>
                    </React.Fragment>

            }
            else {
                partnerDataCode =
                    <React.Fragment>
                        <h3>No information was retrieved from the partner.</h3>
                    </React.Fragment>

            }
        } else if (this.props.partnerDefaultData?.error?.message === "GraphQL error: static") {
            partnerDataCode = <React.Fragment>
                <h3>The partner is not exchanging static information for inter-institutional agreements.</h3>
            </React.Fragment>
        }
        else {
            partnerDataCode = <React.Fragment>
                {this.renderCalendar(partnerData)}
                {this.renderAdditionalRequirements(partnerData)}
                {this.renderInfrastructure(partnerData)}
                {this.renderSupportServices(partnerData)}
                {this.renderHousing(partnerData)}
                {this.renderVisa(partnerData)}
                {this.renderInsurance(partnerData)}
                {this.renderAdditionalInformation(partnerData)}
            </React.Fragment>
        }

        return (
            <div className="custom_table" id="create_IIA" style={{ width: "auto" }}>
                <div className="row">
                    <div>
                        <h2 style={{ textAlign: "center" }}>Key Action 1 - Mobility of learners and staff - Higher Education Student and Staff Mobility between Programme Countries</h2>
                        <h2 style={{ textAlign: "center" }}>Requirements for Inter-Institutional Agreements 2021-20[29]</h2>
                        <p>&nbsp;</p>
                    </div>

                    <div>
                        <h1>Static information applicable to all Inter-Institutional Agreements</h1>
                        <p>&nbsp;</p>
                        <p>*The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus programme. They commit to respect the quality requirements of the <a href="https://ec.europa.eu/programmes/erasmus-plus/resources/documents/applicants/higher-education-charter_en" target="_blank">Erasmus Charter for Higher Education</a> in all aspects related to the organisation and management of the mobility, in particular the <a href="https://ec.europa.eu/education/education-in-the-eu/proposal-for-a-council-recommendation-on-the-automatic-mutual-recognition-of-diplomas-and-learning-periods-abroad_en" target="_blank">automatic recognition</a> of the credits awarded to students by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript of Records, or according to the learning outcomes of the modules completed abroad, as described in the Course Catalogue, in line with the <a href="https://ec.europa.eu/education/resources-and-tools/european-credit-transfer-and-accumulation-system-ects_en" target="_blank">European Credit and Accumulation System</a>. The institutions agree on exchanging their mobility related data in line with the technical standards of <a href="https://ec.europa.eu/education/education-in-the-eu/european-student-card-initiative_en" target="_blank">the European Student Card Initiative</a>.</p>
                        <p>&nbsp;</p>
                    </div>

                    <div>
                        <p>&nbsp;</p>
                        <h3>Grading systems of the institutions</h3>
                        <p>It is recommended that receiving institutions provide a link to the statistical distribution of grades or makes the information available through <a href="https://egracons.eu/" target="_blank">EGRACONS</a> according to the descriptions in the <a href="https://ec.europa.eu/education/ects/users-guide/docs/ects-users-guide_en.pdf" target="_blank">ECTS users’ guide</a>. The information will facilitate the interpretation of each grade awarded to students and will facilitate the credit transfer by the sending institution.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                    <h1>General information entered into the higher education institutions’ profile and updated by the higher education institution. The general institutional information about the institution is accessible to students.</h1>
                </div>
                {this.showCreatorData()}
                {this.showPartnerData()}
                {this.showCommonDetails()}
                <div className="row">
                    <ReactButton onClick={this.toogleCoopCond} style={subContentStyle}>Hide/Show Cooperation Conditions</ReactButton>
                </div>
                <div className="row">
                    <Collapse isOpen={this.state.coopCondDefaultOpen}>
                        {this.showConditionsData()}
                    </Collapse>
                </div>
                <div className="row">
                    <ReactButton onClick={this.toogleCreator} style={subContentStyle}>Hide/Show {partners.creatorErasmusCode} default data</ReactButton>
                </div>
                <div className="row">
                    <Collapse isOpen={this.state.creatorDefaultOpen}>
                        {creatorDataCode}
                    </Collapse>
                </div>
                <div className="row">
                    <ReactButton onClick={this.tooglePartner} style={subContentStyle}>Hide/Show {partners.partnerErasmusCode} default data</ReactButton>
                </div>
                <div className="row">
                    <Collapse isOpen={this.state.partnerDefaultOpen}>
                        {partnerDataCode}
                    </Collapse>
                </div>
                {this.renderFinalInformation()}
            </div>
        );
    }

    showCreatorInEWP() {
        if (this.state.isCreatorInEWP == -2) {
            return (
                <h3 style={{ color: "black" }}>Searching for your institution in EWP...</h3>
            );
        }
        if (this.state.isCreatorInEWP == -1) {
            return (
                <h3 style={{ color: "blue" }}>The IIAs of your institution are not managed by a node in the EWP network neither by the Dashboard so this agreement cannot be sent by the EWP network. If you want the Dashboard to be responsible for handling the IIAs through the EWP network, please check that you have enabled the Dashboard in the Settings page of the EWP menu on the left.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 0) {
            return (
                <h3 style={{ color: "orange" }}>It was not possible to get the information about your institution on the EWP Network. The IIA maybe saved in the Dashboard only. Please contact the administrator or try again later.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 1) {
            return (
                <h3 style={{ color: "red" }}>The IIAs of your institution are managed by a node in the EWP network which is not the Dashboard. You will not be able to send this agreement through the EWP Network.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 2) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of your institution are managed by the Dashboard in the EWP network so you will be able to send this agreement through the EWP Network.</h3>
            );
        }
    }

    showCreatorData() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };
        const partners = JSON.parse(localStorage.getItem("partnersDetails"));

        return (
            <div className="row" style={subContentStyle}>
                <div className="row header_row">
                    <Label text="Creator data" />
                </div>
                <div className="row header_row">
                    {this.showCreatorInEWP()}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("Institution Name", partners.creatorInstitution)}
                    {this.generateLabelValueRow("Erasmus Code", partners.creatorErasmusCode)}
                    {this.generateLabelValueRow("Country", partners.creatorCountry)}
                    {this.generateLabelValueRow("City", partners.creatorCity)}
                    {this.generateLabelValueRow("OUnit", this.getOUnitName("creator", 0))}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("Factsheet URL", partners.creatorFactsheetData)}
                    {this.generateLabelValueRow("Contact Name", partners.creatorContactName)}
                    {this.generateLabelValueRow("Contact Email", partners.creatorContactEmail)}
                    {this.generateLabelValueRow("Contact Phone", partners.creatorContactPhone)}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("General Website", partners.creatorGeneralWebsite)}
                    {this.generateLabelValueRow("Faculty/Faculties Website", partners.creatorFacultyWebsite)}
                    {this.generateLabelValueRow("Course Catalogue Website", partners.creatorCourseWebsite)}
                </div>
                <hr />
            </div>
        );
    }

    showPartnerInEWP() {
        if (this.state.isPartnerInEWP == -2) {
            return (
                <h3 style={{ color: "black" }}>Searching for partner in EWP...</h3>
            );
        }
        if (this.state.isPartnerInEWP == -1) {
            return (
                <h3 style={{ color: "blue" }}>The IIAs of the partner are not managed by any node in the EWP network. This agreement will not be sent through the EWP Network.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 0) {
            return (
                <h3 style={{ color: "orange" }}>It was not possible to get the information about the partner on the EWP Network. It may not be possible to send the IIA through the EWP Network. Please contact the Administrator.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 1) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of the partner are managed by a node in the EWP network. This agreement will be sent through the EWP Network.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 2) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of the partner are managed by the Dashboard in the EWP network. This agreement will not be sent through the EWP Network and treated internally in the Dashboard.</h3>
            );
        }
    }

    showPartnerData() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };
        const partners = JSON.parse(localStorage.getItem("partnersDetails"));

        return (
            <div className="row" style={subContentStyle}>
                <div className="row header_row">
                    <Label text="Partner data" />
                </div>
                <div className="row header_row">
                    {this.showPartnerInEWP()}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("Institution Name", partners.partnerInstitution)}
                    {this.generateLabelValueRow("Erasmus Code", partners.partnerErasmusCode)}
                    {this.generateLabelValueRow("Country", partners.partnerCountry)}
                    {this.generateLabelValueRow("City", partners.partnerCity)}
                    {this.generateLabelValueRow("OUnit", this.getOUnitName("partner", 0))}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("Factsheet URL", partners.partnerFactsheetData)}
                    {this.generateLabelValueRow("Contact Name", partners.partnerContactName)}
                    {this.generateLabelValueRow("Contact Email", partners.partnerContactEmail)}
                    {this.generateLabelValueRow("Contact Phone", partners.partnerContactPhone)}
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("General Website", partners.partnerGeneralWebsite)}
                    {this.generateLabelValueRow("Faculty/Faculties Website", partners.partnerFacultyWebsite)}
                    {this.generateLabelValueRow("Course Catalogue Website", partners.partnerCourseWebsite)}
                </div>
                <hr />
            </div>
        );
    }

    showCommonDetails() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };
        const partners = JSON.parse(localStorage.getItem("partnersDetails"));

        return (
            <div className="row" style={subContentStyle}>
                <div className="row header_row">
                    <Label text="Other Details" />
                </div>
                <div className="row header_row">
                    {this.generateLabelValueRow("Academic Year (start)", partners.academicYearStart)}
                    {this.generateLabelValueRow("Academic Year (end)", partners.academicYearEnd)}
                </div>
                <hr />
            </div>
        );
    }


    showConditionsData() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };
        const cc = JSON.parse(localStorage.getItem("coopConditions"));

        if (cc.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row header_row">
                        <Label text="Terms of the agreement to be set for each agreement and approved by the institutions (Information only accessible to the relevant parties)." />
                    </div>
                    <div className="row header_row">
                        <Label text="No conditions" />
                    </div>
                    <hr />
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="row header_row">
                    <Label text="Terms of the agreement to be set for each agreement and approved by the institutions (Information only accessible to the relevant parties)." />
                </div>
                <div className="row header_row">
                    <h3>Mobility numbers per academic year</h3>
                    <p>&nbsp;</p>
                    <p style={{ fontWeight: "bold" }}>The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year.</p>
                </div>
                {cc.map((data, index) => {
                    var condText = "Condition " + (index + 1) + "/" + (cc.length);

                    let iscedFCodesCounter = 0;
                    data.iscedFCode.map((iscedFCode) => { if (iscedFCode.code !== "") { iscedFCodesCounter++; } });

                    let iscedFCodesTitle = (iscedFCodesCounter === 0) ? <h2>No subject area selected</h2> : <h2>Subject area</h2>;
                    let languagesCounter = 0;
                    data.languageSkill.map((language) => { if (language.language !== "") { languagesCounter++; } });

                    let languagesTitle = (languagesCounter === 0) ? <h2>No recommended language skills</h2>
                        : (<React.Fragment><h2>Recommended language skills</h2><p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p></React.Fragment>);

                    return (
                        <div key={index} className="row" style={subContentStyle}>
                            <div className="row header_row">
                                <Label text={condText} />
                            </div>
                            <div className="row">
                                {this.generateLabelValueRow("Sender Erasmus Code", data.senderInstitution)}
                                {this.generateLabelValueRow("Receiver Erasmus Code", data.receiverInstitution)}
                                {this.generateLabelValueRow("Sending Organizational Unit", this.getOUnitName("sendingOUnit", index))}
                                {this.generateLabelValueRow("Receiving Organizational Unit", this.getOUnitName("receivingOUnit", index))}
                                {this.generateLabelValueRow("Mobilities per Year", data.mobilitiesPerYear)}
                            </div>
                            <div className="row">
                                {iscedFCodesTitle}
                                {this.generateObjectRows("Subject Area", data.iscedFCode)}
                            </div>
                            <div className="row">
                                {this.generateLabelValueRow("Mobility Type", data.mobilityTypeSelected)}
                                {this.generateLabelValueRow("Total Months Per Year Per Mobility", data.totalMonths)}
                                {this.generateLabelValueRow("Total Days Per Year Per Mobility", data.totalDays)}
                                {this.generateLabelMultiValueRow("EQF Levels", data.eqfLevels, "")}
                            </div>
                            {
                                data.mobilityTypeSelected.includes("Student") ? (
                                    <div className="row">
                                        {this.generateLabelValueRow("Blended mobility", (data.blended === 1) ? "Yes" : "No")}
                                    </div>
                                ) : <div />
                            }
                            <div className="row">
                                {languagesTitle}
                                {this.generateObjectRows("Recommended language skills", data.languageSkill)}
                            </div>
                            <div className="row">
                                {this.generateLabelValueRow("Other Information", data.otherInformation)}
                            </div>
                            <hr />
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    renderCalendar(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.stuNomAutumnTerm === "" && data.stuNomSpringTerm === "" && data.appNomAutumnTerm === "" && data.appNomSpringTerm === "" &&
            data.appProcedureEmail === "" && data.appProcedurePhone === "" && data.appProcedureWebsite === "") {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Calendar Information" />
                    </div>
                    <hr />
                </div>
            );
        }

        let nomCode =
            <div className="row header_row">
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No student nominations information</p>
                </div>
            </div>
            ;
        if (data.stuNomAutumnTerm !== "" || data.stuNomSpringTerm !== "") {
            let autumnDate = data.stuNomAutumnTerm;
            let springDate = data.stuNomSpringTerm;

            if (data.stuNomAutumnTerm.includes('-')) { autumnDate = data.stuNomAutumnTerm.split('-')[2] + "/" + data.stuNomAutumnTerm.split('-')[1] + "/" + data.stuNomAutumnTerm.split('-')[0]; }
            if (data.stuNomSpringTerm.includes('-')) { springDate = data.stuNomSpringTerm.split('-')[2] + "/" + data.stuNomSpringTerm.split('-')[1] + "/" + data.stuNomSpringTerm.split('-')[0]; }

            nomCode =
                <React.Fragment>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Student nominations must reach the receiving institution by</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Autumn Term", autumnDate)}
                        {this.generateLabelValueRow("Spring Term", springDate)}
                    </div>
                </React.Fragment>
                ;
        }

        let appCode =
            <div className="row header_row">
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No applications from nominated students information</p>
                </div>
            </div>
            ;
        if (data.appNomAutumnTerm !== "" || data.appNomSpringTerm !== "") {
            let autumnDate = data.appNomAutumnTerm;
            let springDate = data.appNomSpringTerm;

            if (data.appNomAutumnTerm.includes('-')) { autumnDate = data.appNomAutumnTerm.split('-')[2] + "/" + data.appNomAutumnTerm.split('-')[1] + "/" + data.appNomAutumnTerm.split('-')[0]; }
            if (data.appNomSpringTerm.includes('-')) { springDate = data.appNomSpringTerm.split('-')[2] + "/" + data.appNomSpringTerm.split('-')[1] + "/" + data.appNomSpringTerm.split('-')[0]; }

            appCode =
                <React.Fragment>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Applications from nominated students must reach the receiving institution by:</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Autumn Term", autumnDate)}
                        {this.generateLabelValueRow("Spring Term", springDate)}
                    </div>
                </React.Fragment>
                ;
        }

        let procCode =
            <div className="row header_row">
                <div className="row header_row">
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No application procedure for nominated students information</p>
                </div>
            </div>
            ;
        if (data.appProcedureEmail !== "" || data.appProcedurePhone !== "" || data.appProcedureWebsite !== "") {
            procCode =
                <React.Fragment>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Application procedure for nominated students</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Contact Email", data.appProcedureEmail)}
                        {this.generateLabelValueRow("Contact Phone", data.appProcedurePhone)}
                        {this.generateLabelValueRow("Contact Website", data.appProcedureWebsite)}
                    </div>
                </React.Fragment>
                ;
        }

        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Calendar" />
                    </div>
                    {nomCode}
                    {appCode}
                    {procCode}
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderAdditionalRequirements(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.additionalRequirements.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Additional Requirements" />
                    </div>
                    <div className="row">
                        <p style={{ fontWeight: "bold" }}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Additional Requirements" />
                    </div>
                    {data.additionalRequirements.map((requirement, index) => {
                        var reqNumber = "Requirement " + (index + 1)
                        let addReqCode =
                            <div className="column">
                                <Label text="Details" />
                                <p>{this.formatValue(requirement.details)}</p>
                            </div>
                            ;
                        if (requirement.requirement === "Academic requirements") {
                            let iscedFCodes = "";
                            requirement.isced.split(";").map(v => {
                                this.props.getIscedFCodesList.getIscedFCodesList.map(data => {
                                    if (data.code === v) {
                                        iscedFCodes = iscedFCodes.concat(data.code + " - " + data.name + "\n");
                                        return;
                                    }
                                });
                            });
                            addReqCode =
                                <React.Fragment>
                                    <div className="column">
                                        <Label text="Number of ECTS completed" />
                                        <p>{this.formatValue(requirement.ects)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Subject area code - ISCED" />
                                        <textarea rows="5" style={{ height: "auto", whiteSpace: "pre-wrap", overflowY: "scroll" }} disabled>{iscedFCodes}</textarea>
                                    </div>
                                    <div className="column">
                                        <Label text="EQF level" />
                                        <p>{this.formatValue(requirement.eqf)}</p>
                                    </div>
                                </React.Fragment>
                                ;
                        }
                        return (
                            <React.Fragment>
                                <div className="row header_row">
                                    <Label text={reqNumber} />
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <Label text="Requirement" />
                                        <p>{this.formatValue(requirement.requirement)}</p>
                                    </div>
                                    {addReqCode}
                                    <div className="column">
                                        <Label text="Website for information" />
                                        <p>{this.formatValue(requirement.website)}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="row">
                        <p style={{ fontWeight: "bold" }}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                    </div>
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderInfrastructure(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.inclusionInfrastructure.length === 0 && data.inclusionService.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Inclusion and Accessibility Information" />
                    </div>
                    <hr />
                </div>
            );
        }
        if (data.inclusionInfrastructure.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Inclusion and Accessibility" />
                    </div>
                    <div className="row">
                        <Label text="No Infrastructure Information" />
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Inclusion and Accessibility" />
                    </div>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    </div>
                    {data.inclusionInfrastructure.map((inclusion, index) => {
                        var incNumber = "Infrastructure " + (index + 1);
                        return (
                            <React.Fragment>
                                <div className="row">
                                    <Label text={incNumber} />
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <Label text="Infrastructure name" />
                                        <p>{this.formatValue(inclusion.name)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Available infrastructure adjusted for people with" />
                                        <p>{this.formatValue(inclusion.specialNeeds)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Description of infrastructure" />
                                        <p>{this.formatValue(inclusion.description)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Email" />
                                        <p>{this.formatValue(inclusion.email)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Phone" />
                                        <p>{this.formatValue(inclusion.phone)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Website" />
                                        <p>{this.formatValue(inclusion.website)}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    renderSupportServices(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.inclusionService.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Support Services Information" />
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                {data.inclusionService.map((service, index) => {
                    var incNumber = "Support Service " + (index + 1);
                    return (
                        <React.Fragment>
                            <div className="row">
                                <Label text={incNumber} />
                            </div>
                            <div className="row header_row">
                                <div className="column">
                                    <Label text="Service name" />
                                    <p>{this.formatValue(service.name)}</p>
                                </div>
                                <div className="column">
                                    <Label text="Available support service for people with" />
                                    <p>{this.formatValue(service.specialNeeds)}</p>
                                </div>
                                <div className="column">
                                    <Label text="Description of support service" />
                                    <p>{this.formatValue(service.description)}</p>
                                </div>
                                <div className="column">
                                    <Label text="Contact Email" />
                                    <p>{this.formatValue(service.email)}</p>
                                </div>
                                <div className="column">
                                    <Label text="Contact Phone" />
                                    <p>{this.formatValue(service.phone)}</p>
                                </div>
                                <div className="column">
                                    <Label text="Contact Website" />
                                    <p>{this.formatValue(service.website)}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderHousing(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.housing_email === "" && data.housing_phone === "" && data.housing_website === "") {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Housing Information" />
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Housing" />
                    </div>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Contact Email", data.housing_email)}
                        {this.generateLabelValueRow("Contact Phone", data.housing_phone)}
                        {this.generateLabelValueRow("Contact Website", data.housing_website)}
                    </div>
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderVisa(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.visa_email === "" && data.visa_phone === "" && data.visa_website === "") {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Visa Information" />
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Visa" />
                    </div>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Contact Email", data.visa_email)}
                        {this.generateLabelValueRow("Contact Phone", data.visa_phone)}
                        {this.generateLabelValueRow("Contact Website", data.visa_website)}
                    </div>
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderInsurance(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.insurance_email === "" && data.insurance_phone === "" && data.insurance_website === "") {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Insurance Information" />
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Insurance" />
                    </div>
                    <div className="row header_row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:</p>
                    </div>
                    <div className="row header_row">
                        {this.generateLabelValueRow("Contact Email", data.insurance_email)}
                        {this.generateLabelValueRow("Contact Phone", data.insurance_phone)}
                        {this.generateLabelValueRow("Contact Website", data.insurance_website)}
                    </div>
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderAdditionalInformation(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px", float: "left" };

        if (data.additionalInformation.length === 0) {
            return (
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="No Additional Information" />
                    </div>
                    <hr />
                    <div className="row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>A Transcript of Records will be issued by the institution no later than {data.ToRWeeks} weeks after the assessment period has finished.</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]</p>
                        <p>&nbsp;</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold", fontStyle: "italic" }}>*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</p>
                    </div>
                    <hr />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <div className="row">
                        <Label text="Additional Information" />
                    </div>
                    {data.additionalInformation.map((info, index) => {
                        var infoNumber = "Additional Information " + (index + 1);
                        return (
                            <React.Fragment>
                                <div className="row">
                                    <Label text={infoNumber} />
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <Label text="Information on" />
                                        <p>{this.formatValue(info.information)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Email" />
                                        <p>{this.formatValue(info.email)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Phone" />
                                        <p>{this.formatValue(info.phone)}</p>
                                    </div>
                                    <div className="column">
                                        <Label text="Contact Website" />
                                        <p>{this.formatValue(info.website)}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <hr />
                    <div className="row">
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>A Transcript of Records will be issued by the institution no later than {data.ToRWeeks} weeks after the assessment period has finished.</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]</p>
                        <p>&nbsp;</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold", fontStyle: "italic" }}>*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</p>
                    </div>
                    <hr />
                </div>
            </React.Fragment>
        );
    }

    renderOtherInformation() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        let partners = JSON.parse(localStorage.getItem("partnersDetails"));

        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <Label text="Any other information regarding the terms of the agreement?" />
                </div>
                <div className="row" style={subContentStyle}>
                    <textarea
                        name="otherInformation"
                        style={{ height: "auto", whiteSpace: "break-spaces" }}
                        placeholder="Write here any other information to be included in the agreement (5000 characters max)"
                        value={partners.otherInformation}
                        onChange={(event) => this.handleSelection(event)}
                        rows="5"
                        maxLength="5000"
                    />
                </div>
            </React.Fragment>
        );
    }

    renderFinalInformation() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <Label text="Termination of the agreement" />
                </div>
                <div className="row" style={subContentStyle}>
                    <p style={{ fontStyle: "italic", display: "block", width: "98%" }}>[It is up to the involved institutions to agree on the procedure for modifying or terminating the inter-institutional agreement. However, in the event of unilateral termination, a notice of at least one academic year should be given. This means that a unilateral decision to discontinue the exchanges notified to the other party by 1 September 20XX will only take effect as of 1 September 20XX+1. The termination clauses must include the following disclaimer: "Neither the European Commission nor the National Agencies can be held responsible in case of a conflict."]</p>
                </div>
                <div className="row" style={subContentStyle}>
                    <p style={{ margin: "20px", fontSize: "20px", textAlign: "center" }}>"Inter-Institutional Agreements are digitally signed"</p>
                </div>
            </React.Fragment>
        );
    }

    handleSelection(event) {
        let pd = JSON.parse(localStorage.getItem("partnersDetails"));
        pd[event.target.name] = event.target.value;
        localStorage.setItem("partnersDetails", JSON.stringify(pd));
        this.refreshPage();
    }

    refreshPage() {
        this.setState({
            refreshPage: !this.state.refreshPage
        });
    }

    toogleCreator() {
        this.setState({
            creatorDefaultOpen: !this.state.creatorDefaultOpen
        });
    }

    tooglePartner() {
        this.setState({
            partnerDefaultOpen: !this.state.partnerDefaultOpen
        });
    }

    toogleCoopCond() {
        this.setState({
            coopCondDefaultOpen: !this.state.coopCondDefaultOpen
        });
    }

    getOUnitName(type, numberOfCondition) {
        const partners = JSON.parse(localStorage.getItem("partnersDetails"));
        const conds = JSON.parse(localStorage.getItem("coopConditions"));
        const aux = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        let ounitName = "";

        if (type === "creator") {
            {
                aux.creatorOUnitList.map((value) => {
                    if (partners.creatorOUnit == value.ounit_id) {
                        ounitName = value.name;
                    }
                })
            }
        }
        else if (type === "partner") {
            {
                aux.partnerOUnitList.map((value) => {
                    if (partners.partnerOUnit == value.ounit_id) {
                        ounitName = value.name;
                    }
                })
            }
        }
        else if (type === "sendingOUnit") {
            if (conds[numberOfCondition].senderInstitution === JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode) {
                aux.creatorOUnitList.map((value) => {
                    if (conds[numberOfCondition].sendingOUnit === value.ounit_id) { ounitName = value.name; }
                });
            }
            else {
                aux.partnerOUnitList.map((value) => {
                    if (conds[numberOfCondition].sendingOUnit === value.ounit_id) { ounitName = value.name; }
                });
            }
        }
        else if (type === "receivingOUnit") {
            if (conds[numberOfCondition].receiverInstitution === JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode) {
                aux.creatorOUnitList.map((value) => {
                    if (conds[numberOfCondition].receivingOUnit === value.ounit_id) { ounitName = value.name; }
                });
            }
            else {
                aux.partnerOUnitList.map((value) => {
                    if (conds[numberOfCondition].receivingOUnit === value.ounit_id) { ounitName = value.name; }
                });
            }
        }
        return ounitName;
    }

    generateLanguagesRows(label, values) {
        if (values == null || values.length === 0) {
            return;
        }
        console.log(values)
        if (values && values.length) {
            return (
                <div className="row">
                    <Label text={label} />
                    <p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                    {values && values.length && values.map((item, key) => {
                        return (
                            <p key={key} style={{ margin: "20px" }}>{this.formatValue(item)}</p>
                        )
                    })}

                </div>
            )
        }
        return (
            <div className="row">
                <Label text={label} />
                <p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                <p>{this.formatValue("")}</p>
            </div>
        );
    }

    generateLabelMultiValueRow(label, values, tag) {
        if (values == null || values.length === 0) {
            return;
        }
        return (
            <div className="column">
                <Label text={label} />
                {values && values.length && values.map((item, key) => {
                    if (tag === "") {
                        if (label === "ISCED-F Code") {
                            return (
                                <p key={key}>
                                    <p style={{ margin: "0px", width: "250px", whiteSpace: "nowrap" }}>{item}</p>
                                </p>
                            );
                        }
                        return (
                            <p key={key}>{this.formatValue(item)}</p>
                        );
                    }
                    return (
                        <p key={key}>{this.formatValue(item[tag])}</p>
                    )
                })}

            </div>
        );
    }

    generateLabelValueRow(label, value) {
        if (value === undefined || value === null || value === "") {
            return;
        }
        if (label === "Total Months Per Year Per Mobility" || label === "Total Days Per Year Per Mobility") {
            if (value == 0) {
                return;
            }
        }

        if (label === "Other Information") {
            return (
                <React.Fragment>
                    <Label text="Other information regarding the terms of the agreement" />
                    <p>{value}</p>
                </React.Fragment>
            );
        }
        return (
            <div className="column">
                <Label text={label} />
                <p>{this.formatValue(value)}</p>
            </div>
        );
    }

    generateObjectRows(label, object) {
        if (object === undefined || object === null || object.length === 0) {
            return;
        }

        if (label === "Subject Area") {
            return object.map((item) => {
                if (item.code === "") {
                    return;
                }

                return (
                    <React.Fragment>
                        <div className="row header_row">
                            <div className="column">
                                <Label text="ISCED-F Code" />
                                <p>{this.formatValue(item.code)}</p>
                            </div>
                            <div className="column">
                                <Label text="ISCED-F Clarification" />
                                <p>{this.formatValue(item.clarification)}</p>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
        else if (label === "Recommended language skills") {
            return object.map((item) => {
                if (item.language === "") {
                    return;
                }

                return (
                    <React.Fragment>
                        <div className="row header_row">
                            <div className="column">
                                <Label text="Language" />
                                <p>{this.formatValue(item.language)}</p>
                            </div>
                            <div className="column">
                                <Label text="CEFR Level" />
                                <p>{this.formatValue(item.cefrLevel)}</p>
                            </div>
                            <div className="column">
                                <Label text="ISCED-F Code" />
                                <p>{this.formatValue(item.iscedFCode)}</p>
                            </div>
                            <div className="column">
                                <Label text="ISCED-F Clarification" />
                                <p>{this.formatValue(item.clarification)}</p>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
    }

    formatValue(value) {
        if (value === undefined || value === null || value === "") {
            return "--------------"
        }
        if (value.length > 28) {
            return <p style={{ margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap" }}>{value}</p>
        }
        return value;
    }

    checkIfPartnersIIAisManagedByEWP() {
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("partnersDetails")).creatorErasmusCode).toString(),
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isCreatorInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isCreatorInEWP: 1,
                        });
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isCreatorInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isCreatorInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isCreatorInEWP: 0
                        });
                        break;
                }
            }
        });
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": JSON.parse(localStorage.getItem("partnersDetails")).partnerErasmusCode,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isPartnerInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isPartnerInEWP: 1,
                        });
                        this.getEWPStaticData();
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isPartnerInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isPartnerInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isPartnerInEWP: 0
                        });
                        break;
                }
            }
        });
    }

    getEWPStaticData() {
        this.props.getEWPStaticData({
            variables: {
                "PartnerErasmusCode": JSON.parse(localStorage.getItem("partnersDetails")).partnerErasmusCode,
                "clientHeiEC": localStorage.getItem("ErasmusCode")
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    partnerStaticData: null,
                });
            } else {
                console.log("(checkPartnerInEWPandGetData) Is partner in EWP?");
                console.log(x.data);
                switch (x.data.getEWPStaticData.Success) {
                    case 1:
                        //By a node in EWP
                        console.log(x.data.getEWPStaticData);
                        if (x.data.getEWPStaticData.StaticData != null) {
                            this.setState({
                                partnerStaticData: x.data.getEWPStaticData.StaticData,
                            });
                        }
                        break;
                    default:
                        this.setState({
                            partnerStaticData: null,
                            errorMessage: x.data.getEWPStaticData.Message
                        });
                        break;
                }
            }
        });
    }
}

const getIIADefaultData = gql`
    query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
        getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
            stuNomAutumnTerm
            stuNomSpringTerm
            appNomAutumnTerm
            appNomSpringTerm
            appProcedureEmail
            appProcedurePhone
            appProcedureWebsite
            addReqWeeks
            ToRWeeks
            additionalRequirements {
                id
                requirement
                ects
                isced
                eqf
                details
                website
            }
            inclusionInfrastructure {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            inclusionService {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            housing_email
            housing_phone
            housing_website
            visa_email
            visa_phone
            visa_website
            insurance_email
            insurance_phone
            insurance_website
            additionalInformation {
                id
                information
                email
                phone
                website
            }
        }
    }
`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

const getEWPStaticData = gql`
    mutation getEWPStaticData($PartnerErasmusCode: String!, $clientHeiEC: String!) {
        getEWPStaticData(PartnerErasmusCode: $PartnerErasmusCode, clientHeiEC: $clientHeiEC) {
            Success
            Message
            StaticData {
                stuNomAutumnTerm
                stuNomSpringTerm
                appNomAutumnTerm
                appNomSpringTerm
                appProcedureEmail
                appProcedurePhone
                appProcedureWebsite
                addReqWeeks 
                ToRWeeks
                additionalRequirements {
                    id
                    requirement
                    details
                    website
                }
                inclusionInfrastructure {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                inclusionService {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                housing_email
                housing_phone
                housing_website
                visa_email
                visa_phone
                visa_website
                insurance_email
                insurance_phone
                insurance_website
                additionalInformation {
                    id
                    information
                    email
                    phone
                    website
                }
            }
        }
    }
`;

export default compose(
    graphql(getIIADefaultData, {
        name: "creatorDefaultData",
        options: (props) => {
            const partners = JSON.parse(localStorage.getItem("partnersDetails"));

            return {
                variables: {
                    ErasmusCode: GlobalVar.encryptWithKey(partners.creatorErasmusCode).toString(),
                    isToCreate: false
                }
            }
        }
    }),
    graphql(getIIADefaultData, {
        name: "partnerDefaultData",
        options: (props) => {
            const partners = JSON.parse(localStorage.getItem("partnersDetails"));

            return {
                variables: {
                    ErasmusCode: partners.partnerErasmusCode,
                    isToCreate: false
                }
            }
        }
    }),
    graphql(isIIAManagedByEWP, { name: "isIIAManagedByEWP" }),
    graphql(getIscedFCodesList, { name: "getIscedFCodesList" }),
    graphql(getEWPStaticData, { name: "getEWPStaticData" })
)(Summary);