import React, { Component } from 'react';
import { query } from '../../../queries/getTermsAndConditions';
import { graphql } from 'react-apollo';
import Terms from './Terms'

class Conditions extends Component {
    
    generateRow(){
    	return this.props.data.terms.map((term) => {
            return(
				<Terms key={term.id} term={term}/>	
		    )
    	})
    }
    render() { 
    	if(!this.props.data.terms){
    		return (
	    		<div className="card card_half_width card_fixed_height">
	    		</div>
	      	);
    	}
    	else{
    	return (
	    		<div className="content documentation">
                    	{this.generateRow()}
    	        </div>
		)	
        }
    }
}

export default graphql(query)(Conditions);