import React, { Component } from "react";
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Label from "../../common/Label";
import GlobalVar from '../../../../GlobalVar';
import RegExps from '../../../../RegExps';

class DefaultIIAData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stuNomAutumn: "",
            stuNomSpring: "",
            appNomAutumn: "",
            appNomSpring: "",
            appProEmail: "",
            appProPhone: "",
            appProWebsite: "",
            addReqWeeks: "",
            ToRWeeks: "",
            addReqReq: "",
            addReqDetECTS: "",
            addReqDetISCED: [],
            addReqDetEQF: "",
            addReqDet: "",
            addReqWeb: "",
            incInfraType: "",
            incInfraSpecialNeeds: "",
            incInfraDesc: "",
            incInfraEmail: "",
            incInfraPhone: "",
            incInfraWeb: "",
            incServType: "",
            incServSpecialNeeds: "",
            incServDesc: "",
            incServEmail: "",
            incServPhone: "",
            incServWeb: "",
            housingEmail: "",
            housingPhone: "",
            housingWebsite: "",
            visaEmail: "",
            visaPhone: "",
            visaWebsite: "",
            insuranceEmail: "",
            insurancePhone: "",
            insuranceWebsite: "",
            addInfoInfo: "",
            addInfoEmail: "",
            addInfoPhone: "",
            addInfoWeb: "",
            tcCheckboxFactsheet: false
        }

        // const ecode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
        this.specialNeedsOptions = ["Reduced Mobility", "Hearing Impairments", "Visual Impairments", "Other"];
        this.tcErrorMessage = "Please give consent on the treatment of data (checkbox on top of the page)";
    }

    setStateData() {
        const ecode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode

        if(!this.props.data.loading) {
            if(this.props.data.error) {
            } else {
                this.setState({
                    stuNomAutumn: this.props.data.getIIADefaultData.stuNomAutumnTerm,
                    stuNomSpring: this.props.data.getIIADefaultData.stuNomSpringTerm,
                    appNomAutumn: this.props.data.getIIADefaultData.appNomAutumnTerm,
                    appNomSpring: this.props.data.getIIADefaultData.appNomSpringTerm,
                    appProEmail: this.props.data.getIIADefaultData.appProcedureEmail,
                    appProPhone: this.props.data.getIIADefaultData.appProcedurePhone,
                    appProWebsite: this.props.data.getIIADefaultData.appProcedureWebsite,
                    housingEmail: this.props.data.getIIADefaultData.housing_email,
                    housingPhone: this.props.data.getIIADefaultData.housing_phone,
                    housingWebsite: this.props.data.getIIADefaultData.housing_website,
                    visaEmail: this.props.data.getIIADefaultData.visa_email,
                    visaPhone: this.props.data.getIIADefaultData.visa_phone,
                    visaWebsite: this.props.data.getIIADefaultData.visa_website,
                    insuranceEmail: this.props.data.getIIADefaultData.insurance_email,
                    insurancePhone: this.props.data.getIIADefaultData.insurance_phone,
                    insuranceWebsite: this.props.data.getIIADefaultData.insurance_website,
                });
            }
        }
    }

    termsAndConditionsHandler(event) {
        const { name, value } = event.target;
        const isChecked = (document.getElementsByName(name)[0].checked) ? true : false;

        this.setState({ tcCheckboxFactsheet: isChecked });
    }

    handleInputChange(event) {
        console.log(event);
        if (event.keyCode == 13 && !event.shiftKey) {
            event.preventDefault();
        }

        if(event.target != null) {
            let value = event.target.value;
            let name = event.target.name;
            let isValid = false;
            let message = "";

            if (value != "") {
                if(name == "stuNomAutumn" || name == "stuNomSpring" ||  name == "appNomAutumn" || name == "appNomSpring") {
                    message = this.hasValidFormat("dateDDMM", value);
                }
                else if (name == "appProEmail" || name == "housingEmail" || name == "visaEmail" || name == "insuranceEmail" ||
                    name == "incInfraEmail" || name == "incServEmail" || name == "addInfoEmail") {
                    message = this.hasValidFormat("email", value);
                }
                else if (name == "appProPhone" || name == "housingPhone" || name == "visaPhone" || name == "insurancePhone" ||
                    name == "incInfraPhone" || name == "incServPhone" || name == "addInfoPhone") {
                    message = this.hasValidFormat("phone", value);
                }
                else if (name == "appProWebsite" || name == "housingWebsite" || name == "visaWebsite" || name == "insuranceWebsite" ||
                    name == "addReqWeb" || name == "incInfraWeb" || name == "incServWeb" || name == "addInfoWeb") {
                    message = this.hasValidFormat("websiteHTTPS", value);
                }
                else if (name == "addReqDetECTS") {
                    if (value > 0 && value <= 360) { message = ""; }
                    else { message = "Please, insert a value greater than 0 and less than or equal to 360."; }
                }
                else if (name == "addReqDetEQF") {
                    if (value >= 5 && value <= 8) { message = ""; }
                    else { message = "Please, insert a value between 5 and 8, inclusive."; }
                }
            }

            if (message == "") {
                isValid = true;
            }

            if (isValid) {
                document.getElementById("error_" + name).innerHTML = "";
            }
            else {
                document.getElementById("error_" + name).innerHTML = message;
                document.getElementById("error_" + name).style.color = "red";
            }
            this.setState({ [name]: value }, () => { console.log(this.state) });
        }
    }

    handleSelectChange(selectName) {
        let select1 = document.getElementById(selectName);
        let selected1 = [];

        for (let i = 0; i < select1.length; i++) {
          if (select1.options[i].selected) selected1.push(select1.options[i].value);
        }
        this.setState({ [selectName]: selected1 }, () => { console.log(this.state[selectName]); });
    }

    generateFirstData() {
        this.createDefaultDataIfNotExists();
    }

    createDefaultDataIfNotExists() {
        let ecode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;

        this.saveCalendarDataIfNotExists(ecode, () => {
            this.saveHVIDataIfNotExists(ecode, () => {
                window.location.reload();
            });
        });
    }

    saveCalendarDataIfNotExists(ecode, callback) {
        this.props.SaveCalendarIfNotExists({
            variables: {
                "ErasmusCode": GlobalVar.encryptWithKey(ecode).toString(),
            },
            // refetchQueries: [{
            //     query: getIIADefaultData, variables: {ErasmusCode: GlobalVar.encryptWithKey(ecode).toString()}
            // }],
            // awaitRefetchQueries: true
        }).then((x) => {
            if (this.props.data.error) {
                //Already there - nothing happens
            } else {
                if (x.data.saveCalendarIfNotExists.Success) {
                    //Success - nothing happens
                } else {
                    //Already there - nothing happens
                }
            }
            return callback();
        });
    }

    saveHVIDataIfNotExists(ecode, callback) {
        this.props.SaveHVIIfNotExists({
            variables: {
                "ErasmusCode": GlobalVar.encryptWithKey(ecode).toString(),
            },
            refetchQueries: [{
                query: getIIADefaultData, variables: {ErasmusCode: GlobalVar.encryptWithKey(ecode).toString(), isToCreate: true}
            }],
            awaitRefetchQueries: true
        }).then((x) => {
            if (this.props.data.error) {
                //Already there - nothing happens
            } else {
                if (x.data.saveHVIIfNotExists.Success) {
                    //Success - nothing happens
                } else {
                    //Already there - nothing happens
                }
            }
            return callback();
        });
    }

    saveCalendarData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveCalendarTCError").innerHTML = "";

            var newStuNomAutumn = this.state.stuNomAutumn;
            if(this.state.stuNomAutumn == "") {
                newStuNomAutumn = this.props.data.getIIADefaultData.stuNomAutumnTerm;
            }
            var newStuNomSpring = this.state.stuNomSpring;
            if(this.state.stuNomSpring == "") {
                newStuNomSpring = this.props.data.getIIADefaultData.stuNomSpringTerm;
            }
            var newAppNomAutumn = this.state.appNomAutumn;
            if(this.state.appNomAutumn == "") {
                newAppNomAutumn = this.props.data.getIIADefaultData.appNomAutumnTerm;
            }
            var newAppNomSpring = this.state.appNomSpring;
            if(this.state.appNomSpring == "") {
                newAppNomSpring = this.props.data.getIIADefaultData.appNomSpringTerm;
            }
            var newAppProEmail = this.state.appProEmail;
            if(this.state.appProEmail == "") {
                newAppProEmail = this.props.data.getIIADefaultData.appProcedureEmail;
            }
            var newAppProPhone = this.state.appProPhone;
            if(this.state.appProPhone == "") {
                newAppProPhone = this.props.data.getIIADefaultData.appProcedurePhone;
            }
            var newAppProWebsite = this.state.appProWebsite;
            if(this.state.appProWebsite == "") {
                newAppProWebsite = this.props.data.getIIADefaultData.appProcedureWebsite;
            }
            var newAddReqWeeks = this.state.addReqWeeks;
            if(this.state.addReqWeeks == "") {
                newAddReqWeeks = this.props.data.getIIADefaultData.addReqWeeks;
            }
            var newToRWeeks = this.state.ToRWeeks;
            if(this.state.ToRWeeks == "") {
                newToRWeeks = this.props.data.getIIADefaultData.ToRWeeks;
            }

            if (this.hasValidFormat("dateDDMM", newStuNomAutumn) == "" && this.hasValidFormat("dateDDMM", newStuNomSpring) == "" &&
                this.hasValidFormat("dateDDMM", newAppNomAutumn) == "" && this.hasValidFormat("dateDDMM", newAppNomSpring) == "" &&
                this.hasValidFormat("email", newAppProEmail) == "" && this.hasValidFormat("phone", newAppProPhone) == "" &&
                this.hasValidFormat("websiteHTTPS", newAppProWebsite) == "") {

                this.props.SaveCalendar({
                    variables: {
                        "ErasmusCode": localStorage.getItem("ErasmusCode"),
                        "StuNomAutumn": newStuNomAutumn,
                        "StuNomSpring": newStuNomSpring,
                        "StuAppAutumn": newAppNomAutumn,
                        "StuAppSpring": newAppNomSpring,
                        "AppProEmail": newAppProEmail,
                        "AppProPhone": newAppProPhone,
                        "AppProWebsite": newAppProWebsite,
                        "AddReqWeeks": newAddReqWeeks,
                        "ToRWeeks": newToRWeeks,
                    },
                    refetchQueries: [{
                        query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                    }],
                    awaitRefetchQueries: true
                }).then((x) => {
                    if (this.props.data.error) {
                        alert("Error submiting Calendar Data");
                    } else {
                        if (x.data.saveCalendar.Success) {
                            alert("Successfully submitted Calendar Data");
                        } else {
                            alert("Error response on submiting Calendar Data");
                        }
                    }
                });
            }
            else {
                alert("Please make sure all values inserted are valid.");
            }
        }
        else {
            document.getElementById("saveCalendarTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveCalendarTCError").style.color = "red";
        }
    }

    saveAddReqData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveAddReqDataTCError").innerHTML = "";
            if (this.state.addReqReq != "" && ((this.state.addReqDetECTS != "" || this.state.addReqDetISCED.length > 0 || this.state.addReqDetEQF != "") || this.state.addReqDet != "")) {
                if (this.state.addReqWeb == "" || (this.state.addReqWeb != "" && this.hasValidFormat("websiteHTTPS", this.state.addReqWeb) == "")) {

                    let iscedFCodesString = this.state.addReqDetISCED.map(value => { return value.slice(0, value.indexOf("-")-1); }).join(";");
                    this.props.SaveNewAddReq({
                        variables: {
                            "ErasmusCode": localStorage.getItem("ErasmusCode"),
                            "Requirement": this.state.addReqReq,
                            "ECTS": this.state.addReqDetECTS+"",
                            "ISCED": iscedFCodesString,
                            "EQF": this.state.addReqDetEQF+"",
                            "Details": this.state.addReqDet,
                            "Website": this.state.addReqWeb,
                        },
                        refetchQueries: [{
                            query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                        }],
                        awaitRefetchQueries: true
                    }).then((x) => {
                        if (this.props.data.error) {
                            alert("Error submiting New Additional Requirement");
                        } else {
                            if (x.data.saveNewRequirement.Success) {
                                alert("Successfully submitted New Additional Requirement");
                            } else {
                                alert("Error response on submiting New Additional Requirement");
                            }
                        }
                    });
                }
                else {
                    alert("Please make sure all values inserted are valid.");
                }
            }
        }
        else {
            document.getElementById("saveAddReqDataTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveAddReqDataTCError").style.color = "red";
        }
    }

    deleteAddReqData(ID){
        this.props.DeleteReq({
            variables: {
                "ID": ID
            },
            refetchQueries: [{
                query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
            }],
            awaitRefetchQueries: true
        }).then((x) => {
            if (this.props.data.error) {
                alert("Error deleting Additional Requirement");
            } else {
                if (x.data.deleteRequirement.Success) {
                    alert("Successfully deleted Additional Requirement");
                } else {
                    alert("Error response on deleting Additional Requirement");
                }
            }
        });
    }

    saveInfraData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveInfraDataTCError").innerHTML = "";
            if(this.state.incInfraType != "" && this.state.incInfraSpecialNeeds.length > 0) {

                let message = "";
                let errorsList = [];
                if (this.state.incInfraEmail !== "") { if (message = (this.hasValidFormat("email", this.state.incInfraEmail) !== "")) { errorsList.push(message); } }
                if (this.state.incInfraPhone !== "") { if (message = (this.hasValidFormat("phone", this.state.incInfraPhone) !== "")) { errorsList.push(message); } }
                if (this.state.incInfraWeb !== "") { if (message = (this.hasValidFormat("websiteHTTPS", this.state.incInfraWeb) !== "")) { errorsList.push(message); } }

                if (errorsList.length === 0) {
                    this.props.SaveNewInfra({
                        variables: {
                            "ErasmusCode": localStorage.getItem("ErasmusCode"),
                            "Name": this.state.incInfraType,
                            "SpecialNeeds":this.state.incInfraSpecialNeeds,
                            "Description": this.state.incInfraDesc,
                            "Email": this.state.incInfraEmail,
                            "Phone": this.state.incInfraPhone,
                            "Website": this.state.incInfraWeb,
                        },
                        refetchQueries: [{
                            query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                        }],
                        awaitRefetchQueries: true
                    }).then((x) => {
                        if (this.props.data.error) {
                            alert("Error submiting New Infrastructure Data");
                        } else {
                            if (x.data.saveNewInfrastructure.Success) {
                                alert("Successfully submitted New Infrastructure Data");
                            } else {
                                alert("Error response on submiting New Infrastructure Data");
                            }
                        }
                    });
                }
                else {
                    alert("Please make sure all values inserted are valid.");
                }
            }
        }
        else {
            document.getElementById("saveInfraDataTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveInfraDataTCError").style.color = "red";
        }
    }

    saveServData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveServDataTCError").innerHTML = "";
            if(this.state.incServType != "" && this.state.incServSpecialNeeds.length > 0){

                let message = "";
                let errorsList = [];
                if (this.state.incServEmail !== "") { if (message = (this.hasValidFormat("email", this.state.incServEmail) !== "")) { errorsList.push(message); } }
                if (this.state.incServPhone !== "") { if (message = (this.hasValidFormat("phone", this.state.incServPhone) !== "")) { errorsList.push(message); } }
                if (this.state.incServWeb !== "") { if (message = (this.hasValidFormat("websiteHTTPS", this.state.incServWeb) !== "")) { errorsList.push(message); } }

                if (errorsList.length === 0) {
                    this.props.SaveNewServ({
                        variables: {
                            "ErasmusCode": localStorage.getItem("ErasmusCode"),
                            "Name": this.state.incServType,
                            "SpecialNeeds": this.state.incServSpecialNeeds,
                            "Description": this.state.incServDesc,
                            "Email": this.state.incServEmail,
                            "Phone": this.state.incServPhone,
                            "Website": this.state.incServWeb,
                        },
                        refetchQueries: [{
                            query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                        }],
                        awaitRefetchQueries: true
                    }).then((x) => {
                        if (this.props.data.error) {
                            alert("Error submiting New Service Data");
                        } else {
                            if (x.data.saveNewService.Success) {
                                alert("Successfully submitted New Service Data");
                            } else {
                                alert("Error response on submiting New Service Data");
                            }
                        }
                    });
                }
                else {
                    alert("Please make sure all values inserted are valid.");
                }
            }
        }
        else {
            document.getElementById("saveServDataTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveServDataTCError").style.color = "red";
        }
    }

    deleteIncData(ID){
        this.props.DeleteInc({
            variables: {
                "ID": ID
            },
            refetchQueries: [{
                query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
            }],
            awaitRefetchQueries: true
        }).then((x) => {
            if (this.props.data.error) {
                alert("Error deleting Inclusion and Accesscibility data");
            } else {
                if (x.data.deleteInclusion.Success) {
                    alert("Successfully deleted Inclusion and Accesscibility data");
                } else {
                    alert("Error response on deleting Inclusion and Accesscibility data");
                }
            }
        });
    }

    saveHVIData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveHVIDataTCError").innerHTML = "";
            var newHousingEmail = this.state.housingEmail;
            if(this.state.housingEmail == "") {
                newHousingEmail = this.props.data.getIIADefaultData.housing_email;
            }
            var newHousingPhone = this.state.housingPhone;
            if(this.state.housingPhone == "") {
                newHousingPhone = this.props.data.getIIADefaultData.housing_phone;
            }
            var newHousingWebsite = this.state.housingWebsite;
            if(this.state.housingWebsite == "") {
                newHousingWebsite = this.props.data.getIIADefaultData.housing_website;
            }
            var newVisaEmail = this.state.visaEmail;
            if(this.state.visaEmail == "") {
                newVisaEmail = this.props.data.getIIADefaultData.visa_email;
            }
            var newVisaPhone = this.state.visaPhone;
            if(this.state.visaPhone == "") {
                newVisaPhone = this.props.data.getIIADefaultData.visa_phone;
            }
            var newVisaWebsite = this.state.visaWebsite;
            if(this.state.visaWebsite == "") {
                newVisaWebsite = this.props.data.getIIADefaultData.visa_website;
            }
            var newInsuranceEmail = this.state.insuranceEmail;
            if(this.state.insuranceEmail == "") {
                newInsuranceEmail = this.props.data.getIIADefaultData.insurance_email;
            }
            var newInsurancePhone = this.state.insurancePhone;
            if(this.state.insurancePhone == "") {
                newInsurancePhone = this.props.data.getIIADefaultData.insurance_phone;
            }
            var newInsuranceWebsite = this.state.insuranceWebsite;
            if(this.state.insuranceWebsite == "") {
                newInsuranceWebsite = this.props.data.getIIADefaultData.insurance_website;
            }

            if (this.hasValidFormat("email", newHousingEmail) == "" && this.hasValidFormat("phone", newHousingPhone) == "" &&
                this.hasValidFormat("websiteHTTPS", newHousingWebsite) == "" && this.hasValidFormat("email", newVisaEmail) == "" &&
                this.hasValidFormat("phone", newVisaPhone) == "" && this.hasValidFormat("websiteHTTPS", newVisaWebsite) == "" &&
                this.hasValidFormat("email", newInsuranceEmail) == "" && this.hasValidFormat("phone", newInsurancePhone) == "" &&
                this.hasValidFormat("websiteHTTPS", newInsuranceWebsite) == "") {

                this.props.SaveHVI({
                    variables: {
                        "ErasmusCode": localStorage.getItem("ErasmusCode"),
                        "HousingEmail": newHousingEmail,
                        "HousingPhone": newHousingPhone,
                        "HousingWeb": newHousingWebsite,
                        "VisaEmail": newVisaEmail,
                        "VisaPhone": newVisaPhone,
                        "VisaWeb": newVisaWebsite,
                        "InsuranceEmail": newInsuranceEmail,
                        "InsurancePhone": newInsurancePhone,
                        "InsuranceWeb": newInsuranceWebsite,
                    },
                    refetchQueries: [{
                        query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                    }],
                    awaitRefetchQueries: true
                }).then((x) => {
                    if (this.props.data.error) {
                        alert("Error submiting Housing, Visa and Insurance Data");
                    } else {
                        if (x.data.saveHVI.Success) {
                            alert("Successfully submitted Housing, Visa and Insurance Data");
                        } else {
                            alert("Error response on submiting Housing, Visa and Insurance Data");
                        }
                    }
                });
            }
            else {
                alert("Please make sure all values inserted are valid.");
            }
        }
        else {
            document.getElementById("saveHVIDataTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveHVIDataTCError").style.color = "red";
        }
    }

    saveAddInfoData(){
        if (this.state.tcCheckboxFactsheet) {
            document.getElementById("saveAddInfoDataTCError").innerHTML = "";
            if(this.state.addInfoInfo != "") {
                let message = "";
                let errorsList = [];
                if (this.state.addInfoEmail !== "") { if (message = (this.hasValidFormat("email", this.state.addInfoEmail) !== "")) { errorsList.push(message); } }
                if (this.state.addInfoPhone !== "") { if (message = (this.hasValidFormat("phone", this.state.addInfoPhone) !== "")) { errorsList.push(message); } }
                if (this.state.addInfoWeb !== "") { if (message = (this.hasValidFormat("websiteHTTPS", this.state.addInfoWeb) !== "")) { errorsList.push(message); } }

                if (errorsList.length === 0) {
                    this.props.SaveNewInfo({
                        variables: {
                            "ErasmusCode": localStorage.getItem("ErasmusCode"),
                            "Information": this.state.addInfoInfo,
                            "Email": this.state.addInfoEmail,
                            "Phone": this.state.addInfoPhone,
                            "Website": this.state.addInfoWeb,
                        },
                        refetchQueries: [{
                            query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
                        }],
                        awaitRefetchQueries: true
                    }).then((x) => {
                        if (this.props.data.error) {
                            alert("Error submiting New Additional Requirement");
                        } else {
                            if (x.data.saveNewInformation.Success) {
                                alert("Successfully submitted New Additional Requirement");
                            } else {
                                alert("Error response on submiting New Additional Requirement");
                            }
                        }
                    });
                }
                else {
                    alert("Please make sure all values inserted are valid.");
                }
            }
        }
        else {
            document.getElementById("saveAddInfoDataTCError").innerHTML = this.tcErrorMessage;
            document.getElementById("saveAddInfoDataTCError").style.color = "red";
        }
    }

    deleteInfoData(ID){
        this.props.DeleteInfo({
            variables: {
                "ID": ID
            },
            refetchQueries: [{
                query: getIIADefaultData, variables: {ErasmusCode: localStorage.getItem("ErasmusCode"), isToCreate: true}
            }],
            awaitRefetchQueries: true
        }).then((x) => {
            if (this.props.data.error) {
                alert("Error deleting Additional Information data");
            } else {
                if (x.data.deleteInformation.Success) {
                    alert("Successfully deleted Additional Information data");
                } else {
                    alert("Error response on deleting Additional Information data");
                }
            }
        });
    }

    /**
     * Check if value has the correct format for the type of input.
     *
     * @param {String} type
     * @param {String} value
     *
     * @return {String} message with error or empty if there is no error
     */
    hasValidFormat(type, value) {
        let valueTestRExp = [];

        switch (type) {
            case "dateDDMM":
                valueTestRExp = RegExps.dateDDMM.exec(value);
                break;
            case "email":
                valueTestRExp = RegExps.email.exec(value);
                break;
            case "websiteHTTPS":
                valueTestRExp = RegExps.websiteHTTPS.exec(value);
                break;
            case "phone":
                valueTestRExp = RegExps.phone.exec(value);
                break;
        }

        if (!valueTestRExp) {
            let message = "";

            switch (type) {
                case "dateDDMM":
                    message = "Please enter a date in dd/mm format.";
                    break;
                case "email":
                    message = "Please enter a valid email (ex: user@example.com).";
                    break;
                case "websiteHTTPS":
                    message = "Please enter an URL that start with http or https.";
                    break;
                case "phone":
                    message = "Please enter a phone number that start with your country code (ex: +000)."
                    break;
                default:
                    message = "Please insert a valid value.";
            }
            return message;
        }
        return "";
    }

    renderRows(type, data) {
		let stringHTML = ''
		if (data == undefined || data.length == 0) {
			return (
			    <div className="row"><h3>No data available</h3></div>
			);
		}
		return data.map((value, index) => {
            if ( type == "req") {
                let addReqCode =
                    <div className="column">
                        <h4>Details</h4>
                        <p>{value.details}</p>
                    </div>
                ;
                if (value.requirement === "Academic requirements") {
                    let iscedFCodes = "";
                    value.isced.split(";").map(v => {
                        this.props.getIscedFCodesList.getIscedFCodesList.map(data => {
                            if (data.code === v) {
                                iscedFCodes = iscedFCodes.concat(data.code + " - " + data.name + "\n");
                                return;
                            }
                        });
                    });
                    addReqCode =
                        <React.Fragment>
                            <div className="column">
                                <h4>Number of ECTS completed</h4>
                                <p>{value.ects}</p>
                            </div>
                            <div className="column">
                                <h4>Subject area code - ISCED</h4>
                                <textarea rows="5" disabled>{iscedFCodes}</textarea>
                            </div>
                            <div className="column">
                                <h4>EQF level</h4>
                                <p>{value.eqf}</p>
                            </div>
                        </React.Fragment>
                    ;
                }

                return (
					<div className="row header_row" key={index}>
                        <h3>Additional Requirement {(index+1)}</h3>
						<div className="column">
                            <h4>Requirement</h4>
                            <p>{value.requirement}</p>
                        </div>
                        {addReqCode}
                        <div className="column">
                            <h4>Website for information</h4>
                            <p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{value.website}</p>
                        </div>
						<div className="column">
                            <h4>Action</h4>
                            <p><button onClick={() => this.deleteAddReqData(value.id)}>Delete</button></p>
                        </div>
					</div>
				);
            }
            if ( type == "incInfra") {
                return (
					<div className="row header_row" key={index}>
                        <h3>Infrastructure {(index+1)}</h3>
                        <div className="column">
                            <h4>Infrastructure name:</h4>
                            <p>{value.name}</p>
                        </div>
						<div className="column">
                            <h4>Available infrastructure adjusted for people with:</h4>
                            <p>{value.specialNeeds}</p>
                        </div>
						<div className="column">
                            <h4>Description of infrastructure (optional)</h4>
                            <p>{value.description}</p>
                        </div>
                        <div className="column">
                            <h4>Contact Email</h4>
                            <p>{value.email}</p>
                        </div>
                        <div className="column">
                            <h4>Contact Phone</h4>
                            <p>{value.phone}</p>
                        </div>
                        <div className="column">
                            <h4>Website for information</h4>
                            <p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{value.website}</p>
                        </div>
						<div className="column">
                            <h4>Action</h4>
                            <p><button onClick={() => this.deleteIncData(value.id)}>Delete</button></p>
                        </div>
					</div>
				);
            }
            if ( type == "incServ") {
                return (
					<div className="row header_row" key={index}>
                        <h3>Service {(index+1)}</h3>
                        <div className="column">
                            <h3>Service name:</h3>
                            <p>{value.name}</p>
                        </div>
						<div className="column">
                            <h3>Available support services for people with:</h3>
                            <p>{value.specialNeeds}</p>
                        </div>
						<div className="column">
                            <h3>Description of support services (optional)</h3>
                            <p>{value.description}</p>
                        </div>
                        <div className="column">
                            <h3>Contact Email</h3>
                            <p>{value.email}</p>
                        </div>
                        <div className="column">
                            <h3>Contact Phone</h3>
                            <p>{value.phone}</p>
                            </div>
                        <div className="column">
                            <h3>Website for information</h3>
                            <p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{value.website}</p>
                        </div>
						<div className="column">
                            <h3>Action</h3>
                            <p><button onClick={() => this.deleteIncData(value.id)}>Delete</button></p>
                        </div>
					</div>
				);
            }
            if ( type == "info") {
                return (
					<div className="row header_row" key={index}>
                        <h3>Additional Information {(index+1)}</h3>
						<div className="column">
                            <h3>Information on:</h3>
                            <p>{value.information}</p>
                        </div>
						<div className="column">
                            <h3>Contact Email</h3>
                            <p>{value.email}</p>
                        </div>
                        <div className="column">
                            <h3>Contact Phone</h3>
                            <p>{value.phone}</p>
                        </div>
                        <div className="column">
                            <h3>Website for information</h3>
                            <p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{value.website}</p>
                        </div>
						<div className="column">
                            <h3>Action</h3>
                            <p><button onClick={() => this.deleteInfoData(value.id)}>Delete</button></p>
                        </div>
					</div>
				);
            }
		});
	}

	render() {
        if(this.props.data.loading) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg"/>Loading Data...</p>
                    </div>
                </div>
            );
        } else {
            if (this.props.data.getIIADefaultData == null) {
                return (
                    <div className="content DefaulIIAData">
                        <div className="card card_full_width card_full_height">
                            <div className="row">
                                <p>Your organisation don't have any data on the system yet. Click in the button below to generate default data.</p>
                            </div>
                            <div className="row">
                                <button onClick={() => this.generateFirstData()}>Generate Default Data</button>
                            </div>
                        </div>
                    </div>
                );
            } else if(this.props.data.error){
                return (
					<div className="card card_full_width card_full_height">
					<div className="custom_table">
							<p><img className="spinner" src="/icons/spinner.svg"/>Error loading Data...</p>
						</div>
					</div>
				);
            } else {
                let data = this.props.data.getIIADefaultData;
                let autumnStuDate = data.stuNomAutumnTerm;
                let springStuDate = data.stuNomSpringTerm;
                let autumnAppDate = data.appNomAutumnTerm;
                let springAppDate = data.appNomSpringTerm;

                if (data.stuNomAutumnTerm.includes('-')) { autumnStuDate = data.stuNomAutumnTerm.split('-')[2] + "/" + data.stuNomAutumnTerm.split('-')[1] + "/" + data.stuNomAutumnTerm.split('-')[0]; }
                if (data.stuNomSpringTerm.includes('-')) { springStuDate = data.stuNomSpringTerm.split('-')[2] + "/" + data.stuNomSpringTerm.split('-')[1] + "/" + data.stuNomSpringTerm.split('-')[0]; }
                if (data.appNomAutumnTerm.includes('-')) { autumnAppDate = data.appNomAutumnTerm.split('-')[2] + "/" + data.appNomAutumnTerm.split('-')[1] + "/" + data.appNomAutumnTerm.split('-')[0]; }
                if (data.appNomSpringTerm.includes('-')) { springAppDate = data.appNomSpringTerm.split('-')[2] + "/" + data.appNomSpringTerm.split('-')[1] + "/" + data.appNomSpringTerm.split('-')[0]; }

                let addReqDetCode =
                    <React.Fragment>
                        <input type="text" name="addReqDet" value={this.state.addReqDet} placeholder="Details" onChange={(event) => this.handleInputChange(event)} />
                        <p id="error_addReqDet"/>
                    </React.Fragment>
                ;
                if (this.state.addReqReq[0] === "Academic requirements") {
                    let iscedFCodes = [];
                    this.props.getIscedFCodesList.getIscedFCodesList.map((data) => {
                        return (iscedFCodes.push(data.code + " - " + data.name));
                    })
                    addReqDetCode = <div style={{borderStyle: "solid"}}>
                        <input type="number" name="addReqDetECTS" style={{width: "94.5%"}} min="1" max="360"value={this.state.addReqDetECTS} placeholder="Number of ECTS completed" onChange={(event) => this.handleInputChange(event)}/>
                        <p id="error_addReqDetECTS"/>
                        <br/>
                        <select name="addReqDetISCED" id="addReqDetISCED" value={this.state.addReqDetISCED} style={{height: "auto"}} size="3" onChange={(event) => this.handleSelectChange("addReqDetISCED")} multiple>
                            <option key="iscedFCode" value="" selected>Subject area code - ISCED</option>
                            {iscedFCodes.map((value, index) => {
                                return <option key={"iscedFCode"+index} value={`${value}`}>{`${value}`}</option>
                            })}
                        </select>
                        <p id="error_addReqDetISCED"/>
                        <br/>
                        <input type="number" name="addReqDetEQF" style={{width: "94.5%"}} min="5" max="8" value={this.state.addReqDetEQF} placeholder="EQF level" onChange={(event) => this.handleInputChange(event)}/>
                        <p id="error_addReqDetEQF"/>
                        <br/>
                    </div>;
                }

                return (
                    <div className="content DefaulIIAData">
                        <div className="card card_full_width card_full_height">
                            <div className="custom_table">
                                <p>&nbsp;</p>
                                <p><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxFactsheet} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                                <p>&nbsp;</p>
                                <h2>Calendar</h2>
                                <div className="row">
                                    <h3>Student nominations must reach the receiving institution by:</h3>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Autumn Term (dd/mm)</h4>
                                        <p>Current Value: { autumnStuDate }</p>
                                        <input type="text" name="stuNomAutumn" value={this.state.stuNomAutumn} placeholder="new value" pattern="\[{0,1}[[0-9]{2}/[0-9]{2}\]{0,1}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_stuNomAutumn"/>
                                    </div>
                                    <div className="column">
                                        <h4>Spring Term (dd/mm)</h4>
                                        <p>Current Value: {springStuDate}</p>
                                        <input type="text" name="stuNomSpring" value={this.state.stuNomSpring} placeholder="new value" pattern="\[{0,1}[[0-9]{2}/[0-9]{2}\]{0,1}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_stuNomSpring"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Applications from nominated students must reach the receiving institution by:</h3>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Autumn Term (dd/mm)</h4>
                                        <p>Current Value: {autumnAppDate}</p>
                                        <input type="text" name="appNomAutumn" value={this.state.appNomAutumn} placeholder="new value" pattern="\[{0,1}[[0-9]{2}/[0-9]{2}\]{0,1}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_appNomAutumn"/>
                                    </div>
                                    <div className="column">
                                        <h4>Spring Term (dd/mm)</h4>
                                        <p>Current Value: {springAppDate}</p>
                                        <input type="text" name="appNomSpring" value={this.state.appNomSpring} placeholder="new value" pattern="\[{0,1}[[0-9]{2}/[0-9]{2}\]{0,1}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_appNomSpring"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Application procedure for nominated students</h3>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Contact Email</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.appProcedureEmail}</p>
                                        <input type="email" name="appProEmail" value={this.state.appProEmail} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_appProEmail"/>
                                    </div>
                                    <div className="column">
                                        <h4>Contact Phone (+000)</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.appProcedurePhone}</p>
                                        <input type="text" name="appProPhone" value={this.state.appProPhone} placeholder="new value" pattern="\+{0,1}[0-9]{0,15}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_appProPhone"/>
                                    </div>
                                    <div className="column_long">
                                        <h4>Contact Website</h4>
                                        <p>Current Value:</p><p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{this.props.data.getIIADefaultData.appProcedureWebsite}</p>
                                        <input type="text" name="appProWebsite" value={this.state.appProWebsite} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_appProWebsite"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Default Weeks Values</h3>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>For Additional Requirements Decision</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.addReqWeeks}</p>
                                        <input type="text" name="addReqWeeks" value={this.state.addReqWeeks} placeholder="new value" pattern="[1-5]{1}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_addReqWeeks"/>
                                    </div>
                                    <div className="column">
                                        <h4><br/>For Transcript of Records issue</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.ToRWeeks}</p>
                                        <input type="text" name="ToRWeeks" value={this.state.ToRWeeks} placeholder="new value" pattern="[0-9]{1,2}" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_ToRWeeks"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column_long" id=""><button onClick={() => this.saveCalendarData()}>Save Calendar Data</button></div><p id="saveCalendarTCError"/>
                                </div>
                                <hr />
                                <div className="row">
                                    <Label text="Additional Requirements" />
                                </div>
                                <div className="row">
                                    <div className="custom_table">
                                        {this.renderRows("req", this.props.data.getIIADefaultData.additionalRequirements)}
                                        <div className="row">
                                            <h3>Add New Additional Requirement</h3>
                                        </div>
                                        <div className="row">
                                            <div className="column" id="">
                                                <select name="addReqReq" id="addReqReq" style={{height: "auto"}} size="4" onChange={() => this.handleSelectChange("addReqReq")} required>
                                                    <option value="Academic requirements">Academic requirements</option>
                                                    <option value="CV">CV</option>
                                                    <option value="Motivation letter">Motivation letter</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <p id="error_addReqReq"/>
                                            </div>
                                            <div className="column" id="">
                                                { addReqDetCode }
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="addReqWeb" value={this.state.addReqWeb} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_addReqWeb"/>
                                            </div>
                                            <div className="column_long" id="">
                                                <button onClick={() => this.saveAddReqData()}>Add New Additional Requirement</button><p id="saveAddReqDataTCError"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <Label text="Inclusion and Accessibility" />
                                    <p>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                                </div>
                                <div className="row">
                                    <div className="custom_table">
                                        {this.renderRows("incInfra", this.props.data.getIIADefaultData.inclusionInfrastructure)}
                                        <div className="row">
                                            <h3>Add New Infrastructure Data</h3>
                                        </div>
                                        <div className="row">
                                            <div className="column" id="">
                                                <input type="text" name="incInfraType" value={this.state.incInfraType} placeholder="Infrastruture" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incInfraType"/>
                                            </div>
                                            <div className="column" id="">
                                                <select name="incInfraSpecialNeeds" id="incInfraSpecialNeeds" style={{height: "auto"}} size="4" onChange={() => this.handleSelectChange("incInfraSpecialNeeds")} multiple required >
                                                    {this.specialNeedsOptions.map((item) => {
                                                        return (<option value={item}>{item}</option>);
                                                    })}
                                                </select>
                                                <p id="error_incInfraSpecialNeeds"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incInfraDesc" value={this.state.incInfraDesc} placeholder="Description (optional)" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incInfraDesc"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incInfraEmail" value={this.state.incInfraEmail} placeholder="Email" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incInfraEmail"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incInfraPhone" value={this.state.incInfraPhone} placeholder="Phone (+000)" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incInfraPhone"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incInfraWeb" value={this.state.incInfraWeb} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incInfraWeb"/>
                                            </div>
                                            <br/>
                                            <div className="column" id="">
                                                <button onClick={() => this.saveInfraData()}>Add New Infrastructure Data</button><p id="saveInfraDataTCError"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="custom_table">
                                        {this.renderRows("incServ", this.props.data.getIIADefaultData.inclusionService)}
                                        <div className="row">
                                            <h3>Add New Service Data</h3>
                                        </div>
                                        <div className="row">
                                            <div className="column" id="">
                                                <input type="text" name="incServType" value={this.state.incServType} placeholder="Service" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incServType"/>
                                            </div>
                                            <div className="column" id="">
                                                <select name="incServSpecialNeeds" id="incServSpecialNeeds" style={{height: "auto"}} size="4" onChange={() => this.handleSelectChange("incServSpecialNeeds")} multiple required >
                                                    {this.specialNeedsOptions.map((item) => {
                                                        return (<option value={item}>{item}</option>);
                                                    })}
                                                </select>
                                                <p id="error_incInfraSpecialNeeds"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incServDesc" value={this.state.incServDesc} placeholder="Description (optional)" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incServDesc"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incServEmail" value={this.state.incServEmail} placeholder="Email" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incServEmail"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incServPhone" value={this.state.incServPhone} placeholder="Phone (+000)" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incServPhone"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="incServWeb" value={this.state.incServWeb} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_incServWeb"/>
                                            </div>
                                            <br/>
                                            <div className="column" id="">
                                                <button onClick={() => this.saveServData()}>Add New Service Data</button><p id="saveServDataTCError"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h3>Housing</h3>
                                    <p>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Contact Email</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.housing_email}</p>
                                        <input type="email" name="housingEmail" value={this.state.housingEmail} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_housingEmail"/>
                                    </div>
                                    <div className="column">
                                        <h4>Contact Phone (+000)</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.housing_phone}</p>
                                        <input type="text" name="housingPhone" value={this.state.housingPhone} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_housingPhone"/>
                                    </div>
                                    <div className="column_long">
                                        <h4>Contact Website</h4>
                                        <p>Current Value:</p><p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{this.props.data.getIIADefaultData.housing_website}</p>
                                        <input type="text" name="housingWebsite" value={this.state.housingWebsite} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_housingWebsite"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Visa</h3>
                                    <p>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Contact Email</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.visa_email}</p>
                                        <input type="email" name="visaEmail" value={this.state.visaEmail} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_visaEmail"/>
                                    </div>
                                    <div className="column">
                                        <h4>Contact Phone (+000)</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.visa_phone}</p>
                                        <input type="text" name="visaPhone" value={this.state.visaPhone} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_visaPhone"/>
                                    </div>
                                    <div className="column_long">
                                        <h4>Contact Website</h4>
                                        <p>Current Value:</p><p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{this.props.data.getIIADefaultData.visa_website}</p>
                                        <input type="text" name="visaWebsite" value={this.state.visaWebsite} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_visaWebsite"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Insurance</h3>
                                    <p>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:</p>
                                </div>
                                <div className="row header_row">
                                    <div className="column">
                                        <h4>Contact Email</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.insurance_email}</p>
                                        <input type="email" name="insuranceEmail" value={this.state.insuranceEmail} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_insuranceEmail"/>
                                    </div>
                                    <div className="column">
                                        <h4>Contact Phone (+000)</h4>
                                        <p>Current Value: {this.props.data.getIIADefaultData.insurance_phone}</p>
                                        <input type="text" name="insurancePhone" value={this.state.insurancePhone} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_insurancePhone"/>
                                    </div>
                                    <div className="column_long">
                                        <h4>Contact Website</h4>
                                        <p>Current Value:</p><p style={{margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden"}}>{this.props.data.getIIADefaultData.insurance_website}</p>
                                        <input type="text" name="insuranceWebsite" value={this.state.insuranceWebsite} placeholder="new value" onChange={(event) => this.handleInputChange(event)} />
                                        <p id="error_insuranceWebsite"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column_long" id=""><button onClick={() => this.saveHVIData()}>Save Housing, Visa and Insurance Data</button></div><p id="saveHVIDataTCError"/>
                                </div>
                                <hr />
                                <div className="row">
                                    <Label text="Additional information" />
                                </div>
                                <div className="row">
                                    <div className="custom_table">
                                        {this.renderRows("info", this.props.data.getIIADefaultData.additionalInformation)}
                                        <div className="row">
                                            <h3>Add New Additional Information</h3>
                                        </div>
                                        <div className="row">
                                            <div className="column" id="">
                                                <select name="addInfoInfo" id="addInfoInfo" style={{height: "auto"}} size="2" onChange={() => this.handleSelectChange("addInfoInfo")} required>
                                                    <option value="Recognition process">Recognition process</option>
                                                    <option value="Other useful information">Other useful information</option>
                                                </select>
                                                <p id="error_addInfoInfo"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="addInfoEmail" value={this.state.addInfoEmail} placeholder="Email" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_addInfoEmail"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="addInfoPhone" value={this.state.addInfoPhone} placeholder="Phone (+000)" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_addInfoPhone"/>
                                            </div>
                                            <div className="column" id="">
                                                <input type="text" name="addInfoWeb" value={this.state.addInfoWeb} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
                                                <p id="error_addInfoWeb"/>
                                            </div>
                                            <div className="column" id="">
                                                <button onClick={() => this.saveAddInfoData()}>Add New Additional Information</button><p id="saveAddInfoDataTCError"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
                }
           
		}
    }
}

const mutationSaveCalendar = gql`
	mutation saveCalendar($ErasmusCode: String!, $StuNomAutumn: String!, $StuNomSpring: String!, $StuAppAutumn: String!, $StuAppSpring: String!, $AppProEmail: String!, $AppProPhone: String!, $AppProWebsite: String!, $AddReqWeeks: Int!, $ToRWeeks: Int!){
		saveCalendar(ErasmusCode: $ErasmusCode, StuNomAutumn: $StuNomAutumn, StuNomSpring: $StuNomSpring, StuAppAutumn: $StuAppAutumn, StuAppSpring: $StuAppSpring, AppProEmail: $AppProEmail, AppProPhone: $AppProPhone, AppProWebsite: $AppProWebsite, AddReqWeeks: $AddReqWeeks, ToRWeeks: $ToRWeeks){
			Success
	  }
	}
`;

const mutationAddRequirment = gql`
mutation saveNewRequirement($ErasmusCode: String!, $Requirement: String!, $ECTS: String, $ISCED: String, $EQF: String, $Details: String, $Website: String!){
    saveNewRequirement(ErasmusCode: $ErasmusCode, Requirement: $Requirement, ECTS: $ECTS, ISCED: $ISCED, EQF: $EQF, Details: $Details, Website: $Website){
        Success
  }
}
`;

const mutationDeleteRequirement = gql`
mutation deleteRequirement($ID: Int!){
    deleteRequirement(ID: $ID){
        Success
  }
}
`;

const mutationAddInfrastructure = gql`
	mutation saveNewInfrastructure($ErasmusCode: String!, $Name: String!, $SpecialNeeds: [String]!, $Description: String!, $Email: String!, $Phone: String!, $Website: String!){
		saveNewInfrastructure(ErasmusCode: $ErasmusCode, Name: $Name, SpecialNeeds: $SpecialNeeds, Description: $Description, Email: $Email, Phone: $Phone, Website: $Website){
			Success
	  }
	}
`;

const mutationAddService = gql`
	mutation saveNewService($ErasmusCode: String!, $Name: String!, $SpecialNeeds: [String]!, $Description: String!, $Email: String!, $Phone: String!, $Website: String!){
		saveNewService(ErasmusCode: $ErasmusCode, Name: $Name, SpecialNeeds: $SpecialNeeds, Description: $Description, Email: $Email, Phone: $Phone, Website: $Website){
			Success
	  }
	}
`;

const mutationDeleteInclusion = gql`
mutation deleteInclusion($ID: Int!){
    deleteInclusion(ID: $ID){
        Success
  }
}
`;

const mutationSaveHVI = gql`
	mutation saveHVI($ErasmusCode: String!, $HousingEmail: String!, $HousingPhone: String!, $HousingWeb: String!, $VisaEmail: String!, $VisaPhone: String!, $VisaWeb: String!, $InsuranceEmail: String!, $InsurancePhone: String!, $InsuranceWeb: String!){
		saveHVI(ErasmusCode: $ErasmusCode, HousingEmail: $HousingEmail, HousingPhone: $HousingPhone, HousingWeb: $HousingWeb, VisaEmail: $VisaEmail, VisaPhone: $VisaPhone, VisaWeb: $VisaWeb, InsuranceEmail: $InsuranceEmail, InsurancePhone: $InsurancePhone, InsuranceWeb: $InsuranceWeb){
			Success
	  }
	}
`;

const mutationAddInformation = gql`
mutation saveNewInformation($ErasmusCode: String!, $Information: String!, $Email: String!, $Phone: String!, $Website: String!){
    saveNewInformation(ErasmusCode: $ErasmusCode, Information: $Information, Email: $Email, Phone: $Phone, Website: $Website){
        Success
  }
}
`;

const mutationDeleteInformation = gql`
mutation deleteInformation($ID: Int!){
    deleteInformation(ID: $ID){
        Success
  }
}
`;

const mutationSaveCalendarIfNotExists = gql`
	mutation saveCalendarIfNotExists($ErasmusCode: String!){
		saveCalendarIfNotExists(ErasmusCode: $ErasmusCode){
			Success
	  }
	}
`;

const mutationSaveHVIIfNotExists = gql`
	mutation saveHVIIfNotExists($ErasmusCode: String!){
		saveHVIIfNotExists(ErasmusCode: $ErasmusCode){
			Success
	  }
	}
`;

const getIIADefaultData = gql`
query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
    getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
        stuNomAutumnTerm
		stuNomSpringTerm
		appNomAutumnTerm
		appNomSpringTerm
		appProcedureEmail
		appProcedurePhone
        appProcedureWebsite
        addReqWeeks
        ToRWeeks
        additionalRequirements {
            id
            requirement
            ects
            isced
            eqf
            details
            website
        }
        inclusionInfrastructure {
            id
            name
            specialNeeds
            description
            email
            phone
            website
        }
        inclusionService {
            id
            name
            specialNeeds
            description
            email
            phone
            website
        }
		housing_email
		housing_phone
		housing_website
		visa_email
		visa_phone
		visa_website
		insurance_email
		insurance_phone
        insurance_website
        additionalInformation {
            id
            information
            email
            phone
            website
        }
    }
}
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

export default compose(
    graphql(getIIADefaultData, {
		options: (props) => {return {
			variables: {
				ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : "",
                isToCreate: true
			}
		}}
	}),
	graphql(mutationSaveCalendar,{ name : 'SaveCalendar'}),
	graphql(mutationSaveHVI,{ name : 'SaveHVI'}),
	graphql(mutationAddInfrastructure,{ name : 'SaveNewInfra'}),
    graphql(mutationAddService,{ name : 'SaveNewServ'}),
    graphql(mutationAddRequirment,{ name : 'SaveNewAddReq'}),
    graphql(mutationAddInformation,{ name : 'SaveNewInfo'}),
    graphql(mutationDeleteRequirement,{ name : 'DeleteReq'}),
    graphql(mutationDeleteInclusion,{ name : 'DeleteInc'}),
    graphql(mutationDeleteInformation,{ name : 'DeleteInfo'}),
    graphql(mutationSaveCalendarIfNotExists,{ name : 'SaveCalendarIfNotExists'}),
    graphql(mutationSaveHVIIfNotExists,{ name : 'SaveHVIIfNotExists'}),
    graphql(getIscedFCodesList, {name: "getIscedFCodesList"})
)(DefaultIIAData);