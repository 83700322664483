import React, {Component} from 'react';
import { query } from '../../../queries/getInstitutionRegistrationFilter';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import FilterOption from "./filter/FilterOption";

class VerifyRequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldFetchMoreRequests: false,
            pages: 1,
            institutionName:null,
            contactEmail:null
        }

        this.pages = 1;
        this.count = 50;

        /*this.loadMoreRequests = this.loadMoreRequests.bind(this);*/
        if (JSON.parse(sessionStorage.getItem("requestsPages")) === null) { this.generateRequestsPages(); }
    }

    componentWillMount() {
        let requestsPages = JSON.parse(sessionStorage.getItem("requestsPages"));

        if (this.props.valid === 0) {
            // this.setState({ pages: requestsPages.pendingPages });
            this.pages = requestsPages.pendingPages;
            this.count = requestsPages.pendingLength;
        }
        else if (this.props.valid === -1) {
            // this.setState({ pages: requestsPages.declinedPages });
            this.pages = requestsPages.declinedPages;
            this.count = requestsPages.declinedLength;
        }
        else {
            // this.setState({ pages: requestsPages.acceptedPages });
            this.pages = requestsPages.acceptedPages;
            this.count = requestsPages.declinedLength;
        }
    }

    componentWillUnmount() {
        let requestsPages = JSON.parse(sessionStorage.getItem("requestsPages"));

        if (this.props.valid === 0) {
            // requestsPages.pendingPages = this.state.pages;
            requestsPages.pendingPages = this.pages;
            requestsPages.pendingLength = this.count;
        }
        else if (this.props.valid === -1) {
            // requestsPages.declinedPages = this.state.pages;
            requestsPages.declinedPages = this.pages;
            requestsPages.declinedLength = this.count;
        }
        else {
            // requestsPages.acceptedPages = this.state.pages;
            requestsPages.acceptedPages = this.pages;
            requestsPages.acceptedLength = this.count;
        }
        sessionStorage.setItem("requestsPages", JSON.stringify(requestsPages));
    }

    generateRequestsPages() {
        let requestsPages = ({
            pendingPages: 1,
            pendingLength: 50,
            acceptedPages: 1,
            acceptedLength: 50,
            declinedPages: 1,
            declinedLength: 50
        });
        sessionStorage.setItem("requestsPages", JSON.stringify(requestsPages));
    }

    renderRows(){
        if(!this.props.data.loading){
            if(this.props.data.InstitutionRegistrationFilter){
                if (this.count !== this.props.data.InstitutionRegistrationFilter.length) {
                    this.count = this.props.data.InstitutionRegistrationFilter.length;
                }

                if (Math.ceil(this.props.data.InstitutionRegistrationFilter.length / 50) !== this.pages) {
                    if (Math.ceil(this.props.data.InstitutionRegistrationFilter.length / 50) < this.pages - 1) {
                        let reqPages = JSON.parse(sessionStorage.getItem("requestsPages"));
                        if (this.props.valid === 0) {
                            reqPages.pendingPages = 1;
                            reqPages.pendingLength = 50;
                        }
                        else if (this.props.valid === -1) {
                            reqPages.declinedPages = 1;
                            reqPages.declinedLength = 50;
                        }
                        else {
                            reqPages.acceptedPages = 1;
                            reqPages.acceptedLength = 50;
                        }
                        sessionStorage.setItem("requestsPages", JSON.stringify(reqPages));
                        this.pages = 1;
                        this.count = 50;
                    } /*else {
						this.pages--;
					}*/
                }
                if(this.props.data.InstitutionRegistrationFilter?.length>0) {
                    return this.props.data.InstitutionRegistrationFilter.map(({
                                                                                  id,
                                                                                  Phone,
                                                                                  Website,
                                                                                  Email,
                                                                                  Institution,
                                                                                  TimeStamp
                                                                              }, index) => {
                        try {
                            const date = moment(Number(TimeStamp)).format("DD MMM YY");
                            return (
                                <tr key={id}>
                                    <td>{index + 1}</td>
                                    <td>{Institution.NameInLatinCharacterSet}</td>
                                    <td>{Phone}</td>
                                    <td><a href={"mailto:" + Email}>{Email}</a></td>
                                    <td style={{maxWidth: "300px", overflowWrap: "break-word", whiteSpace: 'pre-wrap'}}>
                                        <a target="_blank" href={Website}>{Website}</a>
                                    </td>
                                    <td>{date}</td>
                                    {this.renderButtons(id, Email)}
                                </tr>
                            )
                        } catch (error) {
                            /*console.log(error);*/
                        }
                    });
                }else{
                    return  (<p style={{marginTop: "20px"}}>No results</p>);

                }
            } else {
                return  (<p style={{marginTop: "20px"}}>Error loading data...</p>);
            }
        } else {
            return (<p><img className="spinner" src="/icons/spinner.svg"/>Loading requests...</p>);
        }
    }

    renderButtons(id, Email){
        if(this.props.valid === 0){
            return (
                <React.Fragment>
                    <td><form onSubmit={(event) => this.acceptRegistration(event, id, Email)}><button className="accept_button">Accept</button></form></td>
                    <td><form onSubmit={(event) => this.declineRegistration(event, id, Email)}><button className="decline_button">Decline</button></form></td>
                </React.Fragment>
            )
        }
        else if(this.props.valid === 1){
            return <td><form onSubmit={(event) => this.declineRegistration(event, id, Email)}><button className="decline_button">Decline</button></form></td>
        }
        else if(this.props.valid === -1){
            return (
                <React.Fragment>
                    <td><form onSubmit={(event) => this.acceptRegistration(event, id, Email)}><button className="accept_button">Accept</button></form></td>
                    <td><form onSubmit={(event) => this.removeRegistration(event, id)}><button className="decline_button">Remove</button></form></td>
                </React.Fragment>
            )
        }
    }

    performMutation(Id, Valid, Email){
        this.props.ChangeValidationRegisteredInstitution({
            variables: {
                Id,
                Valid,
                Email
            },
            refetchQueries: [
                {
                    query,
                    variables: {Valid: 0}
                },
                {
                    query,
                    variables: {Valid: 1}
                },
                {
                    query,
                    variables: {Valid: -1}
                }
            ]
        });
    }
    search(){
            this.props.data.refetch({
                    Valid: this.props.valid,
                    ContactEmail: this.state.contactEmail?this.state.contactEmail:undefined,
                    InstitutionName: this.state.institutionName?this.state.institutionName:undefined,
                    Offset: 0,
                    AmountOfRequests: 50

            })
    }
    acceptRegistration(event, id, Email){
        event.preventDefault();
        this.performMutation(id, 1, Email);
    }

    declineRegistration(event, id, Email){
        event.preventDefault();
        this.performMutation(id, -1, Email);
    }

    removeRegistration(event, id){
        event.preventDefault();
        this.props.RemoveRegisteredInstitution({
            variables: {
                Id: id,
            },
            refetchQueries: [
                {
                    query,
                    variables: {Valid: -1}
                }
            ]
        })
    }

    loadMoreRequests = (event) => {
    	event.preventDefault();

    	this.setState(prevState => {
           return {pages: prevState.pages + 1}
        });

    	this.setState({shouldFetchMoreRequests: true});

    	this.props.data.fetchMore({
    		variables:{
    			Offset: 50*this.state.pages,
                ContactEmail: this.state.contactEmail?this.state.contactEmail:undefined,
                InstitutionName: this.state.institutionName?this.state.institutionName:undefined
    		},
    		updateQuery: (previousResult, { fetchMoreResult }) => {
    			let newRequest = [];
    			newRequest = newRequest.concat(previousResult.InstitutionRegistrationFilter);
    			newRequest = newRequest.concat(fetchMoreResult.InstitutionRegistrationFilter);
    			this.setState({shouldFetchMoreRequests: false});
    			return {
    				InstitutionRegistrationFilter: newRequest
    			};
    		}
    	})
    }

    renderLoadMoreButton() {
    	if(!this.props.data.loading){
    		if(this.state.shouldFetchMoreRequests){
    			return <p className="blue_text"><img className="spinner" src="/icons/spinner.svg"/>Loading more requests...</p>
    		} else {
                if(this.count>=50) {
                    return <div>
                        <form className="load_more_button" onSubmit={(event) => this.loadMoreRequests(event)}>
                            <button>Load more requests</button>
                        </form>
                    </div>;
                }
                else{
                    return (<></>)
                }
            }
    	}
    }

    renderTitle(){
        if(this.props.valid === 0){
            return <h2>Pending requests</h2>
        }
        else if(this.props.valid === 1){
            return <h2>Accepted requests</h2>
        }
        else if(this.props.valid === -1){
            return <h2>Declined requests</h2>
        }

    }
    render(){
        return(
            <div className="content institution_verification">

                    <div className="division_25">
                        <FilterOption
                            title={this.renderTitle()}
                            onChange={()=>this.search()}
                            changeContactEmail={(e)=>this.setState({contactEmail:e})}
                            changeInstitutionName={(e)=>this.setState({institutionName:e})}
                        />
                    </div>
                    <div className="card card_full_width card_full_height">
                        <table>
                            <thead>
                            <tr>
                                <th>Nr</th>
                                <th>Institution name</th>
                                <th>Contact phone</th>
                                <th>Contact email</th>
                                <th style={{maxWidth: "300px", overflowWrap: "break-word", whiteSpace: 'pre-wrap'}}>Website</th>
                                <th>Time of registration</th>
                            </tr>
                            </thead>
                            <tbody>
                            { this.renderRows() }
                            </tbody>
                        </table>
                        { this.renderLoadMoreButton() }
                    </div>
            </div>
        )
    }
}
const ChangeValidationRegisteredInstitution = gql`
	mutation ChangeValidationRegisteredInstitution($Id: ID, $Valid: Int, $Email: String){
	  changeValidationRegisteredInstitution(id: $Id, Valid: $Valid, Email: $Email){
	    id
	  }
	}
`;

const RemoveRegisteredInstitution = gql`
	mutation RemoveRegisteredInstitution($Id: ID!){
	  removeRegisteredInstitution(id: $Id){
	    id
	  }
	}
`
export default compose(
    graphql(query, {
        options: (props) => {
            return {
                variables: {
                    Valid: props.valid,
                    Offset: 0,
                    AmountOfRequests: 50
                }
            }
        }

    }),
    graphql(RemoveRegisteredInstitution, {name: "RemoveRegisteredInstitution"}),
    graphql(ChangeValidationRegisteredInstitution, {name: "ChangeValidationRegisteredInstitution"}),
)(VerifyRequests);