import React, { Component } from "react";

class AcademicYear extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }

        this.changeSelection = this.changeSelection.bind(this);
    }

    changeSelection(event) {
        this.setState({ value: event.target.value });
        this.props.handlerChange(event, "academicYear");
    }

    render() {
        // if (this.props.data.loading) {
        //     return <select><option value="">Loading...</option></select>
        // }
        return (
            <select name="iia-filter-academic-year" id="iia-filter-academic-year" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                <option value={""}>Select academic year</option>
                <option value="2020/2021">2020/2021</option>
                <option value="2021/2022">2021/2022</option>
                <option value="2022/2023">2022/2023</option>
                <option value="2023/2024">2023/2024</option>
                <option value="2024/2025">2024/2025</option>
                <option value="2025/2026">2025/2026</option>
                <option value="2026/2027">2026/2027</option>
                <option value="2027/2028">2027/2028</option>
                <option value="2028/2029">2028/2029</option>
            </select>
        );
    }
}

export default AcademicYear;