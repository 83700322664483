import React, { Component, Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { graphql, useQuery, gql, useMutation } from '@apollo/client';
import { EQFvalues, ISCEDvalues, languagesvalues, CEFRvalues } from './NominationRequirementsTaxonomies.js'
import validator from 'validator'

const maxHeiNumbers = Array.from({ length: 20 }, (_, i) => i + 1)

// Mutation for Application Settings
const APPLICATION_SETTINGS = gql`
mutation updateApplicationSettings($applicationSettingsInput: ApplicationSettingsInput!) {
        updateApplicationSettings(applicationSettingsInput: $applicationSettingsInput) {
            success
            errorMsg
        }
}
`

// Mutation for Requirements for Outgoing Students
const OUTGOING_STUDENTS_REQUIREMENTS = gql`
mutation updateApplicationRequirements($applicationRequirementsInput: ApplicationRequirementsInput!) {
    updateApplicationRequirements(applicationRequirementsInput: $applicationRequirementsInput) {
            success
            errorMsg
        }
}
`

// Query to get all data to pre-fill the form
const GET_APPICATION_SETTINGS = gql`
query applicationRequirements($erasmusCode: String!) {
        applicationRequirements(erasmusCode: $erasmusCode) {
            winterStartDate
            winterEndDate
            summerStartDate
            summerEndDate
            maxHEInumber
            ectsRequired
            ectsURL
            iscedURL
            isced
            eqfLevel
            eqfLevelURL
            cvDescription
            cvRequired
            cvURL
            motivLetterDescription
            motivLetterRequired
            motivLetterURL
            torDescription
            torRequired
            torURL
            recomLetterDescription
            recomLetterRequired
            recomLetterURL
            languageArray {uuid, isRequired, code, level, url, certRequired, certDescription}
            extCertDescription
            extCertRequired
            extCertURL
            referenceContactDescription
            referenceContactRequired
            referenceContactURL
            portfolioDescription
            portfolioRequired
            portfolioURL
            otherRequirementArray {uuid, isRequired, url, description}
            otherDocumentArray {uuid, isRequired, url, description}
        }
    }
`

const erasmusCode = "LV OLAINE01"

function NominationRequirements(props) {
    // State
    const [ectsErrorMessage, setEctsErrorMessage] = useState('')
    const [iscedErrorMessage, setIscedErrorMessage] = useState('')
    const [eqfErrorMessage, setEqfErrorMessage] = useState('')
    const [cvErrorMessage, setCvErrorMessage] = useState('')
    const [motivLetterErrorMessage, setMotivLetterErrorMessage] = useState('')
    const [torErrorMessage, setTorErrorMessage] = useState('')
    const [extCertErrorMessage, setExtCertErrorMessage] = useState('')
    const [recomLetterErrorMessage, setRecomLetterErrorMessage] = useState('')
    const [otherDocErrorMessage, setOtherDocErrorMessage] = useState('')
    const [otherReqErrorMessage, setOtherReqErrorMessage] = useState('')
    const [langLevelErrorMessage, setLangLevelErrorMessage] = useState('')
    const [refContactErrorMessage, setRefContactErrorMessage] = useState('')
    const [portfolioErrorMessage, setPortfolioErrorMessage] = useState('')
    const [ectsRequired, setEctsRequired] = useState("")
    const [ectsURL, setEctsURL] = useState("")
    const [isced, setIsced] = useState("")
    const [iscedURL, setIscedURL] = useState("")
    const [eqfLevel, setEqfLevel] = useState("")
    const [eqfLevelURL, setEqfLevelURL] = useState("")
    const [winterStartDate, setWinterStartDate] = useState("")
    const [winterEndDate, setWinterEndDate] = useState("")
    const [summerStartDate, setSummerStartDate] = useState("")
    const [summerEndDate, setSummerEndDate] = useState("")
    const [winterURL, setWinterURL] = useState("")
    const [summerURL, setSummerURL] = useState("")
    const [cvURL, setCvURL] = useState("")
    const [cvDescription, setCvDescription] = useState("")
    const [cvRequired, setCvRequired] = useState(false)
    const [motivLetterURL, setMotivLetterURL] = useState("")
    const [motivLetterDescription, setMotivLetterDescription] = useState("")
    const [motivLetterRequired, setMotivLetterRequired] = useState(false)
    const [torURL, setTorURL] = useState("")
    const [torDescription, setTorDescription] = useState("")
    const [torRequired, setTorRequired] = useState(false)
    const [extCertURL, setExtCertURL] = useState("")
    const [extCertDescription, setExtCertDescription] = useState("")
    const [extCertRequired, setExtCertRequired] = useState(false)
    const [langRequired, setLangRequired] = useState("")
    const [recomLetterURL, setRecomLetterURL] = useState("")
    const [recomLetterDescription, setRecomLetterDescription] = useState("")
    const [recomLetterRequired, setRecomLetterRequired] = useState(false)
    const [otherDocRequired, setOtherDocRequired] = useState(false)
    const [maxHEInumber, setMaxHEInumber] = useState("")
    const [portfolioURL, setPortfolioURL] = useState("")
    const [portfolioDescription, setPortfolioDescription] = useState("")
    const [portfolioRequired, setPortfolioRequired] = useState(false)
    const [referenceContactURL, setReferenceContactURL] = useState("")
    const [referenceContactDescription, setReferenceContactDescription] = useState("")
    const [referenceContactRequired, setReferenceContactRequired] = useState(false)
    const [otherRequirementRequired, setOtherRequirementRequired] = useState(false)
    const [languageArray, setLanguageArray] = useState([])
    const [otherDocumentArray, setOtherDocumentArray] = useState([{
        uuid: "",
        isRequired: 0,
        url: "",
        description: ""
    }])
    const [otherRequirementArray, setOtherRequirementArray] = useState([{
        uuid: "",
        isRequired: 0,
        url: "",
        description: ""
    }])

    const validateUrl = (value, type) => {
        switch (type) {
            case "ects":
                if (validator.isURL(value) || value === "") {
                    setEctsErrorMessage('')
                } else {
                    setEctsErrorMessage('URL not valid.')
                }
                break
            case "isced":
                if (validator.isURL(value) || value === "") {
                    setIscedErrorMessage('')
                } else {
                    setIscedErrorMessage('URL not valid.')
                }
                break
            case "eqf":
                if (validator.isURL(value) || value === "") {
                    setEqfErrorMessage('')
                } else {
                    setEqfErrorMessage('URL not valid.')
                }
                break
            case "cv":
                if (validator.isURL(value) || value === "") {
                    setCvErrorMessage('')
                } else {
                    setCvErrorMessage('URL not valid.')
                }
                break
            case "motivLetter":
                if (validator.isURL(value) || value === "") {
                    setMotivLetterErrorMessage('')
                } else {
                    setMotivLetterErrorMessage('URL not valid.')
                }
                break
            case "tor":
                if (validator.isURL(value) || value === "") {
                    setTorErrorMessage('')
                } else {
                    setTorErrorMessage('URL not valid.')
                }
                break
            case "extCert":
                if (validator.isURL(value) || value === "") {
                    setExtCertErrorMessage('')
                } else {
                    setExtCertErrorMessage('URL not valid.')
                }
                break
            case "recomLetter":
                if (validator.isURL(value) || value === "") {
                    setRecomLetterErrorMessage('')
                } else {
                    setRecomLetterErrorMessage('URL not valid.')
                }
                break
            case "otherDoc":
                if (validator.isURL(value) || value === "") {
                    setOtherDocErrorMessage('')
                } else {
                    setOtherDocErrorMessage('Other document URL not valid.')
                }
                break
            case "langLevel":
                if (validator.isURL(value) || value === "") {
                    setLangLevelErrorMessage('')
                } else {
                    setLangLevelErrorMessage('Language level URL not valid.')
                }
                break
            case "refContact":
                if (validator.isURL(value) || value === "") {
                    setRefContactErrorMessage('')
                } else {
                    setRefContactErrorMessage('URL not valid.')
                }
                break
            case "portfolio":
                if (validator.isURL(value) || value === "") {
                    setPortfolioErrorMessage('')
                } else {
                    setPortfolioErrorMessage('URL not valid.')
                }
                break
            case "otherReq":
                if (validator.isURL(value) || value === "") {
                    setOtherReqErrorMessage('')
                } else {
                    setOtherReqErrorMessage('Other requirements URL not valid.')
                }
        }
    }

    // ---
    // Mutations and Queries Hooks
    //---

    // Mutation for Application Settings
    const [updateApplicationSettings, { }] = useMutation(
        APPLICATION_SETTINGS, {
        // When request is completed, page reloads
        onCompleted({ updateApplicationSettings }) {
            if (updateApplicationSettings.success) {
                alert("Application settings updated successfully.")
            }
            else {
                alert("There was an error with updating application settings: " + updateApplicationSettings.errorMsg)
            }
        }
    });

    // Mutation for Outgoing Students Application Requirements
    const [updateApplicationRequirements, { }] = useMutation(
        OUTGOING_STUDENTS_REQUIREMENTS, {
        // When request is completed, page reloads
        onCompleted({ updateApplicationRequirements }) {
            if (updateApplicationRequirements.success) {
                alert("Application requirements updated successfully.")
            }
            else {
                alert("There was an error with updating application requirements: " + updateApplicationRequirements.errorMsg)
            }
        }
    });

    // Query for all fields (pre-fill)
    const { data, error, loading } = useQuery(GET_APPICATION_SETTINGS, {
        variables: { erasmusCode: localStorage.getItem("ErasmusCode") },
        // When request is completed, we set the state
        onCompleted() {

            if (data.applicationRequirements && data.applicationRequirements.winterStartDate) {
                let date_string = data.applicationRequirements.winterStartDate + "/2021"; // Apr 03 2020
                let parts_of_date = date_string.split("/");
                let output = new Date(+parts_of_date[2], parts_of_date[1] - 1, +parts_of_date[0]);
                setWinterStartDate(output)
            } else {
                setWinterStartDate(data.applicationRequirements.winterStartDate)
            }

            if (data.applicationRequirements && data.applicationRequirements.winterEndDate) {
                let date_string2 = data.applicationRequirements.winterEndDate + "/2021"; // Apr 03 2020
                let parts_of_date2 = date_string2.split("/");
                let output2 = new Date(+parts_of_date2[2], parts_of_date2[1] - 1, +parts_of_date2[0]);
                setWinterEndDate(output2)
            } else {
                setWinterEndDate(data.applicationRequirements.winterEndDate)
            }

            if (data.applicationRequirements && data.applicationRequirements.summerStartDate) {
                let date_string3 = data.applicationRequirements.summerStartDate + "/2021"; // Apr 03 2020
                let parts_of_date3 = date_string3.split("/");
                let output3 = new Date(+parts_of_date3[2], parts_of_date3[1] - 1, +parts_of_date3[0]);
                setSummerStartDate(output3)
            } else {
                setSummerStartDate(data.applicationRequirements.summerStartDate)
            }

            if (data.applicationRequirements && data.applicationRequirements.summerEndDate) {
                let date_string4 = data.applicationRequirements.summerEndDate + "/2021"; // Apr 03 2020
                let parts_of_date4 = date_string4.split("/");
                let output4 = new Date(+parts_of_date4[2], parts_of_date4[1] - 1, +parts_of_date4[0]);
                setSummerEndDate(output4)
            } else {
                setSummerEndDate(data.applicationRequirements.summerEndDate)
            }

            setMaxHEInumber(data.applicationRequirements.maxHEInumber)

            setEctsRequired(data.applicationRequirements.ectsRequired)
            setEctsURL(data.applicationRequirements.ectsURL)
            // Dropdowns
            setIsced(data.applicationRequirements.isced)
            setIscedURL(data.applicationRequirements.iscedURL)

            setEqfLevel(data.applicationRequirements.eqfLevel)
            setEqfLevelURL(data.applicationRequirements.eqfLevelURL)

            // Checkboxes
            setCvRequired(data.applicationRequirements.cvRequired)
            setCvDescription(data.applicationRequirements.cvDescription)
            setCvURL(data.applicationRequirements.cvURL)

            setMotivLetterRequired(data.applicationRequirements.motivLetterRequired)
            setMotivLetterDescription(data.applicationRequirements.motivLetterDescription)
            setMotivLetterURL(data.applicationRequirements.motivLetterURL)

            setTorRequired(data.applicationRequirements.torRequired)
            setTorDescription(data.applicationRequirements.torDescription)
            setTorURL(data.applicationRequirements.torURL)

            setRecomLetterRequired(data.applicationRequirements.recomLetterRequired)
            setRecomLetterDescription(data.applicationRequirements.recomLetterDescription)
            setRecomLetterURL(data.applicationRequirements.recomLetterURL)

            setExtCertRequired(data.applicationRequirements.extCertRequired)
            setExtCertDescription(data.applicationRequirements.extCertDescription)
            setExtCertURL(data.applicationRequirements.extCertURL)

            setReferenceContactRequired(data.applicationRequirements.referenceContactRequired)
            setReferenceContactDescription(data.applicationRequirements.referenceContactDescription)
            setReferenceContactURL(data.applicationRequirements.referenceContactURL)

            setPortfolioRequired(data.applicationRequirements.portfolioRequired)
            setPortfolioDescription(data.applicationRequirements.portfolioDescription)
            setPortfolioURL(data.applicationRequirements.portfolioURL)

            setLanguageArray(data.applicationRequirements.languageArray)
            if (data.applicationRequirements && data.applicationRequirements.languageArray.length >= 1) {
                setLangRequired(true)
            }

            setOtherRequirementArray(data.applicationRequirements.otherRequirementArray)
            if (data.applicationRequirements && data.applicationRequirements.otherRequirementArray.length >= 1) {
                setOtherRequirementRequired(true)
            }

            setOtherDocumentArray(data.applicationRequirements.otherDocumentArray)
            if (data.applicationRequirements && data.applicationRequirements.otherDocumentArray.length >= 1) {
                setOtherDocRequired(true)
            }

        }
    })

    // Function triggered when Save & Publish Requirements for Outgoing Students on the Erasmus+ App button is clicked
    // Triggers the updateApplicationRequirements function (mutation)
    // Used to preprocess the state variables before sending them to graphql server
    const prepareAndUpdateApplicationRequirements = () => {
        // construct language array
        let LanguageRequirementInput = [];
        let iterationCountLanguage = 0;
        let tempLangArray = languageArray
        if (!langRequired) {
            tempLangArray = languageArray.filter(language => language.hasOwnProperty("uuid"))
            setLanguageArray(tempLangArray)
        }

        LanguageRequirementInput = tempLangArray.map(language => ({
            uuid: (language.uuid !== undefined ? language.uuid : iterationCountLanguage++),
            field_out_language: language.code, // when eworx ready, put CODE here. Right now we only have access to full name (English). Backend accepts code (EN)
            field_out_language_required: langRequired ? language.isRequired : false, // bulk delete all items if the external checkbox is false
            field_out_lang_cert_description: language.certDescription,
            field_out_lang_cert_required: (language.certRequired ? 1 : 0),
            field_out_language_level: language.level,
            field_out_language_url: { uri: language.url }
        }))
        //force empty, no other way to handle it
        //  if(langRequired == 0) {
        //    LanguageRequirementInput = [];
        //}
        // construct other requirements array
        let OtherRequirementInput = [];
        let iterationCountOtherReq = 0;
        let tempOtherRequirementArray = otherRequirementArray
        if (!otherRequirementRequired) {
            tempOtherRequirementArray = otherRequirementArray.filter(otherRequirement => otherRequirement.uuid !== undefined)
            setOtherRequirementArray(tempOtherRequirementArray)
        }
        OtherRequirementInput = tempOtherRequirementArray.map(otherRequirement => ({
            uuid: (otherRequirement.uuid !== undefined ? otherRequirement.uuid : iterationCountOtherReq++),
            field_out_other_requirement_req: otherRequirementRequired == true ? otherRequirement.isRequired : 0,  // bulk delete all items if the external checkbox is false
            field_out_other_requirement_url: { uri: otherRequirement.url },
            field_out_other_requirement_d: otherRequirement.description
        }))

        // construct other documents array
        let OtherDocumentInput = [];
        let iterationCountOtherDoc = 0;
        let tempOtherDocumentArray = otherDocumentArray
        if (!otherDocRequired) {
            tempOtherDocumentArray = otherDocumentArray.filter(otherDocument => otherDocument.uuid !== undefined)
            setOtherDocumentArray(tempOtherDocumentArray)
        }
        OtherDocumentInput = tempOtherDocumentArray.map(otherDocument => ({
            uuid: (otherDocument.uuid !== undefined ? otherDocument.uuid : iterationCountOtherDoc++),
            field_out_other_document_req: otherDocRequired ? otherDocument.isRequired : 0,  // decide how to handle these fields, add them to state if necessary
            field_out_other_document_url: { uri: otherDocument.url },
            field_out_other_document_descr: otherDocument.description
        }))

        // console log arrays for testing


        updateApplicationRequirements({
            variables: {
                applicationRequirementsInput: {
                    // flat fields
                    organisation_erasmus_code: localStorage.getItem("ErasmusCode"),
                    // The 9 following fields are not sent until EWORX supports the functionality
                    // field_out_ects_requirement: ectsRequired,
                    // field_out_ects_required: 1,
                    // field_out_isced_required: 1,
                    // field_out_eqf_required: 1,
                    // field_out_ects_url: { uri: ectsURL },
                    // field_out_isced: isced,
                    // field_out_isced_url: { uri: iscedURL },
                    // field_out_eqf: eqfLevel,
                    // field_out_eqf_url: { uri: eqfLevelURL },
                    field_out_cv_description: cvDescription,
                    field_out_cv_required: cvRequired,
                    field_out_cv_url: { uri: cvURL },
                    field_out_motiv_letter_descr: motivLetterDescription,
                    field_out_motiv_letter_required: motivLetterRequired,
                    field_out_motiv_letter_url: { uri: motivLetterURL },
                    field_out_transcript_description: torDescription,
                    field_out_transcript_required: torRequired,
                    field_out_transcript_url: { uri: torURL },
                    field_out_recom_letter_descr: recomLetterDescription,
                    field_out_recom_letter_required: recomLetterRequired,
                    field_out_recom_letter_url: { uri: recomLetterURL },
                    field_out_ext_cert_description: extCertDescription,
                    field_out_ext_cert_required: extCertRequired,
                    field_out_ext_cert_url: { uri: extCertURL },
                    field_out_reference_description: referenceContactDescription,
                    field_out_reference_required: referenceContactRequired,
                    field_out_reference_url: { uri: referenceContactURL },
                    field_out_portfolio_description: portfolioDescription,
                    field_out_portfolio_required: portfolioRequired,
                    field_out_portfolio_url: { uri: portfolioURL },
                    field_out_language_requirements: LanguageRequirementInput,
                    field_out_other_requirements: OtherRequirementInput,
                    field_out_other_documents: OtherDocumentInput
                }
            }
        })
    }


    // --
    // Multiple fields (arrays) handling
    //

    // Handle multiple Other Document
    const handleAddOtherDocumentsFields = () => {
        const values = [...otherDocumentArray];
        values.push({ url: "", description: "", isRequired: 1 });
        setOtherDocumentArray(values);
    }

    const handleRemoveOtherDocumentsFields = (index) => {
        const values = [...otherDocumentArray];

        if (values[index].uuid !== undefined) {
            values[index].isRequired = 0;
        } else {
            values.splice(index, 1);
        }
        setOtherDocumentArray(values);
    }

    const handleInputOtherDocumentChange = (index, event) => {
        const values = [...otherDocumentArray];
        if (event.target.name === "url") {
            values[index].url = event.target.value;
        } else {
            values[index].description = event.target.value;
        }

        setOtherDocumentArray(values);
    }

    // Handle multiple Other Requirement
    const handleAddOtherRequirementFields = () => {
        const values = [...otherRequirementArray];
        values.push({ url: "", description: "", isRequired: 1 });
        setOtherRequirementArray(values);
    }

    const handleRemoveOtherRequirementFields = (index) => {
        const values = [...otherRequirementArray];
        if (values[index].uuid !== undefined) {
            values[index].isRequired = 0;
        } else {
            values.splice(index, 1);
        }
        setOtherRequirementArray(values);
    }

    const handleInputOtherRequirementChange = (index, event) => {
        const values = [...otherRequirementArray];
        if (event.target.name === "url") {
            values[index].url = event.target.value;
        } else {
            values[index].description = event.target.value;
        }

        setOtherRequirementArray(values);
    }


    // Handle multiple Language
    const handleAddLanguageFields = () => {
        const values = [...languageArray];
        values.push({ code: "", level: "", url: "", certRequired: false, certDescription: "", isRequired: 1 });
        setLanguageArray(values);
    }

    const handleRemoveLanguageFields = (index) => {
        const values = [...languageArray];
        if (values[index].uuid !== undefined) {
            values[index].isRequired = 0;
        } else {
            values.splice(index, 1);
        }
        setLanguageArray(values);
    }

    const handleInputLanguageChange = (index, event) => {
        const values = [...languageArray];
        if (event.target.name === "code") {
            values[index].code = event.target.value;
        } else if (event.target.name === "level") {
            values[index].level = event.target.value;
        } else if (event.target.name === "url") {
            values[index].url = event.target.value;
        } else if (event.target.name === "certDescription") {
            values[index].certDescription = event.target.value;
        } else if (event.target.id === "certRequired") {
            values[index].certRequired = !values[index].certRequired
        }
        setLanguageArray(values);
    }


    const handleWinterStartChange = (date) => {
        setWinterStartDate(date);
    }

    const handleWinterEndChange = (date) => {
        setWinterEndDate(date);
    }

    const handleSummerStartChange = (date) => {
        setSummerStartDate(date);
    }

    const handleSummerEndChange = (date) => {
        setSummerEndDate(date);
    }

    const handleWinterURLChange = (event) => {
        setWinterURL(event.target.value)
    }

    const handleSummerURLChange = (event) => {
        setSummerURL(event.target.value)
    }

    return (
        <div className="content nominationRequirements">

            <div className="card">

                {/* ---- Application Settings ---- */}
                <div className="custom_table">
                    <h2>Application Settings</h2>
                    <table>
                        <tr>
                            <td style={{ paddingRight: 10, paddingTop: 20, width: 300, textAlign: 'right', verticalAlign: 'middle' }}>
                                Autumn/Winter semester/annual Application Period <a style={{ color: 'red' }}>*</a>
                            </td>
                            <td style={{ paddingLeft: 15, paddingTop: 20, borderLeft: 'solid', verticalAlign: 'top' }}>
                                <div className="row header_row" style={{ zIndex: '2' }}>
                                    <div className="column" >
                                        Start Date
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={winterStartDate}
                                            onChange={(date) => handleWinterStartChange(date)}
                                        />
                                        <p id="error_startDate" />
                                    </div>
                                    <div className="column">
                                        End Date
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={winterEndDate}
                                            onChange={(date) => handleWinterEndChange(date)}
                                        />
                                        <p id="error_endDate" />
                                    </div>
                                    {/* <div className="column">
                                        URL
                                        <div>
                                            <input type="text" name="url" value={winterURL} placeholder="URL" onChange={(event) => handleWinterURLChange(event)} />
                                        </div>
                                        <p id="error_winterURL" />
                                    </div> */}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 10, paddingTop: 0, width: 300, textAlign: 'right', verticalAlign: 'middle' }}>
                                Spring/Summer semester/annual Application Period <a style={{ color: 'red' }}>*</a>
                            </td>
                            <td style={{ paddingLeft: 15, paddingTop: 0, borderLeft: 'solid', verticalAlign: 'middle' }}>
                                <div className="row header_row" style={{ zIndex: '1' }}>
                                    <div className="column">
                                        Start Date
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={summerStartDate}
                                            onChange={(date) => handleSummerStartChange(date)}
                                        />
                                        <p id="error_startDate" />
                                    </div>
                                    <div className="column">
                                        End Date
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={summerEndDate}
                                            onChange={(date) => handleSummerEndChange(date)}
                                        />
                                        <p id="error_endDate" />
                                    </div>
                                    {/* <div className="column">
                                        URL
                                        <div>
                                            <input type="text" name="url" value={summerURL} placeholder="URL" onChange={(event) => handleSummerURLChange(event)} />
                                        </div>
                                        <p id="error_summerURL" />
                                    </div> */}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 10, paddingBottom: 30, width: 85, textAlign: 'right', verticalAlign: 'top', paddingTop: 14 }}>
                                <div>
                                    Max number of priorities of host HEI per student application  <a style={{ color: 'red' }}>*</a>
                                </div>
                            </td>
                            <td style={{ paddingLeft: 15, paddingTop: 0, borderLeft: 'solid' }}>
                                <div className="row">
                                    <div className="column">
                                        <label>
                                            <select name="maxHEInumber" value={maxHEInumber} placeholder="Select number of HEIs" onChange={(event) => setMaxHEInumber(event.target.value)}>
                                                <option value="" selected="selected">Select number of HEIs</option>
                                                {/* <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option> */}
                                                {
                                                    maxHeiNumbers.map(maxHeiNumber => (
                                                        <option value={maxHeiNumber}>{maxHeiNumber}</option>
                                                    ))
                                                }
                                            </select>
                                        </label>
                                        <p id="error_programmes" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <br /> <br />
                    <div className="row">
                        <div className="column_long" id="">
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    updateApplicationSettings({
                                        variables: {
                                            applicationSettingsInput: {
                                                organisation_erasmus_code: localStorage.getItem("ErasmusCode"),
                                                field_universities_number: maxHEInumber,
                                                field_out_process_autumn_start: winterStartDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                                                field_out_process_autumn_end: winterEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                                                field_out_process_spring_start: summerStartDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                                                field_out_process_spring_end: summerEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' })
                                            }
                                        }
                                    })
                                }}
                            >
                                Save & Publish Application Settings on the Erasmus+ App
                            </button>
                        </div>
                    </div>
                </div>


                <br /> <br />


                {/* ---- Requirements for Outgoing Students ---- */}

                <div className="custom_table">
                    <h2>Requirements for Outgoing Students</h2>
                    {/* 
                    <table>
                        <tr>
                            <td style={{ paddingRight: 10, paddingTop: 20, width: 120, textAlign: 'right', verticalAlign: 'top' }}>
                                Academic requirements
                            </td>
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 230, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    ECTS
                                </td>

                                <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                    <div className="row header_row">
                                        <div className="column">
                                            ECTS Requirement
                                            <div>
                                                <input type="text" name="url" value={ectsRequired} placeholder="Number of ECTS" onChange={(event) => setEctsRequired(event.target.value)} />
                                            </div>
                                        </div>
                                        <div className="column">
                                            ECTS More Info
                                            <div>
                                                <input type="text" name="url" value={ectsURL} placeholder="URL" onChange={(event) => setEctsURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "ects")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{ectsErrorMessage}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 230, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    ISCED
                                </td>

                                <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                    <div className="row header_row">
                                        <div className="column">
                                            ISCED
                                            <div>
                                                <select onChange={(event) => setIsced(event.target.value)} value={isced} >
                                                    <option value="Select an ISCED">Select an ISCED</option>
                                                    {ISCEDvalues.map((ISCED) => <option key={ISCED.code} value={ISCED.code}>{ISCED.code}: {ISCED.title}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="column">
                                            ISCED More Info
                                            <div>
                                                <input type="text" name="url" value={iscedURL} placeholder="URL" onChange={(event) => setIscedURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "isced")} required="required" />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{iscedErrorMessage}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 230, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    EQF Level
                                </td>

                                <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                    <div className="row header_row">
                                        <div className="column">
                                            EQF Level
                                            <div>
                                                <select onChange={(event) => setEqfLevel(event.target.value)} value={eqfLevel}>
                                                    <option value="Select an EQF Level">Select an EQF Level</option>
                                                    {EQFvalues.map((EQF) => <option key={EQF.id} value={EQF.code}>{EQF.code}: {EQF.title}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="column">
                                            EQF Level More Info
                                            <div>
                                                <input type="text" name="url" value={eqfLevelURL} placeholder="URL" onChange={(event) => setEqfLevelURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "eqf")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{eqfErrorMessage}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tr>
                    </table> */}

                    {/* Documents required */}
                    <h4 style={{ paddingLeft: 10, paddingTop: 10 }}>Please check the boxes of the files that are required for the Outgoing Students of your HEI and provide the relevant URLs and descriptions.</h4>
                    <table>
                        <tr>
                            <td style={{ paddingRight: 10, paddingTop: 20, width: 120, textAlign: 'right', verticalAlign: 'top' }}>
                                Documents Required
                            </td>

                            {/* CV */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 200, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    CV
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={cvRequired} onChange={(event) => setCvRequired(prevState => !prevState)} />
                                </td>

                                {cvRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={cvURL} placeholder="URL" onChange={(event) => setCvURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "cv")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{cvErrorMessage}</span>
                                                <p id="error_cvURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={cvDescription} placeholder="Description" onChange={(event) => setCvDescription(event.target.value)} />
                                                </label>
                                                <p id="error_cvDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                CV is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Motivation Letter */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Motivation Letter
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={motivLetterRequired} onChange={(event) => setMotivLetterRequired(prevState => !prevState)} />
                                </td>

                                {motivLetterRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={motivLetterURL} placeholder="URL" onChange={(event) => setMotivLetterURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "motivLetter")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{motivLetterErrorMessage}</span>
                                                <p id="error_motivLetterURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={motivLetterDescription} placeholder="Description" onChange={(event) => setMotivLetterDescription(event.target.value)} />
                                                </label>
                                                <p id="error_motivLetterDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Motivation Letter is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Transcript of Records */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid' }}>
                                    Transcript of Records
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={torRequired} onChange={(event) => setTorRequired(prevState => !prevState)} />
                                </td>
                                {torRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={torURL} placeholder="URL" onChange={(event) => setTorURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "tor")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{torErrorMessage}</span>
                                                <p id="error_torURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={torDescription} placeholder="Description" onChange={(event) => setTorDescription(event.target.value)} />
                                                </label>
                                                <p id="error_torDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Transcript of Records is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* External Certification */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    External Certification
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={extCertRequired} onChange={(event) => setExtCertRequired(prevState => !prevState)} />
                                </td>
                                {extCertRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={extCertURL} placeholder="URL" onChange={(event) => setExtCertURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "extCert")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{extCertErrorMessage}</span>
                                                <p id="error_refLetterURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={extCertDescription} placeholder="Description" onChange={(event) => setExtCertDescription(event.target.value)} />
                                                </label>
                                                <p id="error_extCertDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                External Certification is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Recommendation Letter */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Recommendation Letter
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={recomLetterRequired} onChange={(event) => setRecomLetterRequired(prevState => !prevState)} />
                                </td>
                                {recomLetterRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={recomLetterURL} placeholder="URL" onChange={(event) => setRecomLetterURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "recomLetter")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{recomLetterErrorMessage}</span>
                                                <p id="error_recomLetterURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={recomLetterDescription} placeholder="Description" onChange={(event) => setRecomLetterDescription(event.target.value)} />
                                                </label>
                                                <p id="error_recomLetterDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Recommendation Letter is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Other Document */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Other Document
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={otherDocRequired} onChange={(event) => setOtherDocRequired(prevState => !prevState)} />
                                </td>
                                {otherDocRequired ?
                                    <div>
                                        <br />
                                        <span style={{
                                            paddingLeft: 20,
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>{otherDocErrorMessage}</span>
                                        {otherDocumentArray.map((otherDocument, index) => (
                                            <div>

                                                { otherDocument.isRequired ?
                                                    <Fragment key={`${otherDocument}~${index}`}>
                                                        <div>
                                                            <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                                                <div className="row header_row">
                                                                    <div className="column">
                                                                        <input type="text" name="url" value={otherDocument.url} placeholder="URL" onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                                            onInput={(event) => validateUrl(event.target.value, "otherDoc")} />
                                                                        <p id="error_otherDocURL" />
                                                                    </div>
                                                                </div>
                                                                <div className="row header_row">
                                                                    <div className="column_long">
                                                                        <label>
                                                                            <textarea rows="8" cols="55" name="summary" value={otherDocument.description} placeholder="Description" onChange={(event) => handleInputOtherDocumentChange(index, event)} />
                                                                        </label>
                                                                        <p id="error_otherDocDescription" />
                                                                    </div>
                                                                    <div className="column">
                                                                        <button
                                                                            className="decline_button"
                                                                            onClick={() => handleRemoveOtherDocumentsFields(index)}
                                                                        //disabled={otherDocumentArray.length === 1}
                                                                        >
                                                                            Remove Other Document
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </Fragment>
                                                  :
                                                  <Fragment/>

                                                }
                                            </div>
                                        ))}
                                        <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                            <div className="row header_row">
                                                <div className="column">
                                                    <button
                                                        className="acceptNomination_button"
                                                        onClick={() => handleAddOtherDocumentsFields()}
                                                    >
                                                        Add Other Document
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </div>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Other Document is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>


                            {/* Language Level */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Language Level
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="MotivationLetter" checked={langRequired} onChange={(event) => setLangRequired(prevState => !prevState)} />
                                </td>
                                {langRequired ?
                                    <div>
                                        <br />
                                        <span style={{
                                            paddingLeft: 20,
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>{langLevelErrorMessage}</span>
                                        {languageArray.map((language, index) => (
                                            <div>

                                                {language.isRequired ?
                                                    <Fragment key={`${language}~${index}`}>
                                                        <div>
                                                            <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                                                <div className="row header_row">
                                                                    <div className="column">
                                                                        Language Required
                                                                        <div>
                                                                            <select name='code' onChange={(event) => handleInputLanguageChange(index, event)} value={language.code} >
                                                                                <option value="Select a Language">Select a Language</option>
                                                                                {languagesvalues.map((language) => <option key={language.id} value={language.code}>{language.title}</option>)}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column">
                                                                        Language Level
                                                                        <div>
                                                                            <select name='level' onChange={(event) => handleInputLanguageChange(index, event)} value={language.level}>
                                                                                <option value="Select a Language Level">Select a Language Level</option>
                                                                                {CEFRvalues.map((CEFR) => <option key={CEFR.id} value={CEFR.code}>{CEFR.code}: {CEFR.title}</option>)}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column">
                                                                        Language More Info
                                                                        <div>
                                                                            <input type="text" name="url" value={language.url} placeholder="URL" onChange={(event) => handleInputLanguageChange(index, event)}
                                                                                onInput={(event) => validateUrl(event.target.value, "langLevel")} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row header_row">
                                                                    <div className="column">
                                                                        Language Certification Required
                                                                    </div>
                                                                    <div className="column">
                                                                        <input type="checkbox" name="certRequired" id="certRequired" checked={language.certRequired} onChange={(event) => handleInputLanguageChange(index, event)} />
                                                                    </div>
                                                                </div>
                                                                {language.certRequired ?
                                                                    <div className="row header_row">
                                                                        <div className="column_long">
                                                                            <label>
                                                                                <textarea rows="8" cols="55" name="certDescription" value={language.certDescription} placeholder="Description" onChange={(event) => handleInputLanguageChange(index, event)} />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                                }
                                                                <div className="column" style={{ paddingTop: 10 }}>
                                                                    <button
                                                                        className="decline_button"
                                                                        onClick={() => handleRemoveLanguageFields(index)}
                                                                    //disabled={languageArray.length === 1}
                                                                    >
                                                                        Remove Other Language
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </Fragment>
                                                  :
                                                  <Fragment/>
                                                }
                                            </div>
                                        ))}
                                        <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                            <div className="row header_row">
                                                <div className="column">
                                                    <button
                                                        className="acceptNomination_button"
                                                        onClick={() => handleAddLanguageFields()}
                                                    >
                                                        Add Other Language
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </div>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Language Level is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>


                        </tr>
                    </table>



                    {/* Other fields*/}
                    <h4 style={{ paddingLeft: 10, paddingTop: 10 }}>Please check the boxes of the fields that are required for the Outgoing Students of your HEI and provide the relevant URLs and descriptions.</h4>
                    <table>
                        <tr>
                            <td style={{ paddingRight: 10, paddingTop: 20, width: 120, textAlign: 'right', verticalAlign: 'top' }}>
                                Other fields
                            </td>

                            {/* Reference Contact */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 200, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Reference Contact
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="ReferenceContact" checked={referenceContactRequired} onChange={(event) => setReferenceContactRequired(prevState => !prevState)} />
                                </td>

                                {referenceContactRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={referenceContactURL} placeholder="URL" onChange={(event) => setReferenceContactURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "refContact")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{refContactErrorMessage}</span>
                                                <p id="error_refContactUrl" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={referenceContactDescription} placeholder="Description" onChange={(event) => setReferenceContactDescription(event.target.value)} />
                                                </label>
                                                <p id="error_refContactDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Reference Contact is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Portfolio */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Portfolio
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="Portfolio" checked={portfolioRequired} onChange={(event) => setPortfolioRequired(prevState => !prevState)} />
                                </td>

                                {portfolioRequired ?
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                        <div className="row header_row">
                                            <div className="column">
                                                <input type="text" name="url" value={portfolioURL} placeholder="URL" onChange={(event) => setPortfolioURL(event.target.value)}
                                                    onInput={(event) => validateUrl(event.target.value, "portfolio")} />
                                                <br />
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{portfolioErrorMessage}</span>
                                                <p id="error_portfolioURL" />
                                            </div>
                                        </div>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                <label>
                                                    <textarea rows="8" cols="55" name="summary" value={portfolioDescription} placeholder="Description" onChange={(event) => setPortfolioDescription(event.target.value)} />
                                                </label>
                                                <p id="error_portfolioDescription" />
                                            </div>
                                        </div>
                                    </td>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Portfolio is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>

                            {/* Other Requirement */}
                            <tr>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 138, textAlign: 'right', verticalAlign: 'middle', borderLeft: 'solid', }}>
                                    Other Requirement
                                </td>
                                <td style={{ paddingRight: 10, paddingTop: 20, width: 20, textAlign: 'right', verticalAlign: 'middle' }}>
                                    <input type="checkbox" id="OtherRequirement" checked={otherRequirementRequired} onChange={(event) => setOtherRequirementRequired(prevState => !prevState)} />
                                </td>
                                {otherRequirementRequired ?
                                    <div>
                                        <br />
                                        <span style={{
                                            paddingLeft: 20,
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>{otherReqErrorMessage}</span>
                                        {otherRequirementArray.map((otherRequirement, index) => (
                                            <div>

                                                {otherRequirement.isRequired ?
                                                    <Fragment key={`${otherRequirement}~${index}`}>
                                                        <div>
                                                            <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                                                <div className="row header_row">
                                                                    <div className="column">
                                                                        <input type="text" name="url" value={otherRequirement.url} placeholder="URL" onChange={(event) => handleInputOtherRequirementChange(index, event)}
                                                                            onInput={(event) => validateUrl(event.target.value, "otherReq")} />

                                                                        <p id="error_otherDocURL" />
                                                                    </div>
                                                                </div>
                                                                <div className="row header_row">
                                                                    <div className="column_long">
                                                                        <label>
                                                                            <textarea rows="8" cols="55" name="summary" value={otherRequirement.description} placeholder="Description" onChange={(event) => handleInputOtherRequirementChange(index, event)} />
                                                                        </label>
                                                                        <p id="error_otherDocDescription" />
                                                                    </div>
                                                                    <div className="column">
                                                                        <button
                                                                            className="decline_button"
                                                                            onClick={() => handleRemoveOtherRequirementFields(index)}
                                                                        //disabled={otherRequirementArray.length === 1}
                                                                        >
                                                                            Remove Other Requirement
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </Fragment>
                                                  :
                                                  <Fragment/>
                                                }
                                            </div>
                                        ))}
                                        <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'top' }}>
                                            <div className="row header_row">
                                                <div className="column">
                                                    <button
                                                        className="acceptNomination_button"
                                                        onClick={() => handleAddOtherRequirementFields()}
                                                    >
                                                        Add Other Requirement
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </div>
                                    :
                                    <td style={{ paddingLeft: 15, paddingTop: 10, borderLeft: 'solid', verticalAlign: 'bottom' }}>
                                        <div className="row header_row">
                                            <div className="column_long">
                                                Other Requirement is not required
                                            </div>
                                        </div>
                                    </td>
                                }
                            </tr>



                        </tr>
                    </table>


                </div>
                <br /> <br />
                <div className="row" style={{ paddingBottom: 60 }}>
                    <div className="column_long" id="">
                        <button
                            onClick={e => {
                                e.preventDefault();
                                prepareAndUpdateApplicationRequirements();
                            }}
                        >Save & Publish Requirements for Outgoing Students on the Erasmus+ App
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};


export default NominationRequirements;
