import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Button from '../common/Button';

/**
 * This component manages the section of buttons that can be viewed in the footer.
 * Updates are made by the parent, storing the data in localStorage 
 * "iiaCreationAuxiliarInformation" and calling back the refreshPageNumber function 
 * passed as prop from IIACreation.
 */
class Buttons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reloadPage: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return (
            <div className={this.props.className} id={this.props.id} style={{overflowX: "auto", height: "auto"}}>
                <Button 
                    id="clearButton"
                    handleClick={this.handleClick}
                    text="Clear IIA"
                    disabled={this.props.auxInfo.clearDisabled}
                    refreshPage={this.props.clearAll}
                />
                <Button 
                    id="backButton"
                    handleClick={this.handleClick}
                    text="Previous Page"
                    disabled={this.props.auxInfo.backDisabled}
                    refreshPage={this.props.refreshPage}
                />
                <Button 
                    id="nextButton"
                    handleClick={this.handleClick}
                    text="Next Page"
                    disabled={this.props.auxInfo.nextDisabled}
                    refreshPage={this.props.refreshPage}
                />
                <Button 
                    id="submitButton"
                    handleClick={this.handleClick}
                    text="Submit IIA"
                    disabled={this.props.auxInfo.submitDisabled}
                    refreshPage={this.props.refreshPage}
                />
                |
                <Button 
                    id="addButton"
                    handleClick={this.handleClick}
                    text="Add Cooperation Condition"
                    disabled={this.props.auxInfo.addDisabled}
                    refreshPage={this.props.refreshPage}
                />
                <Button
                    id="deleteButton"
                    handleClick={this.handleClick}
                    text="Delete Cooperation Condition"
                    disabled={this.props.auxInfo.deleteDisabled}
                    refreshPage={this.props.refreshPage}
                />
            </div>
        );
    }

    /**********************/
    /* AUXILIAR FUNCTIONS */
    /**********************/
    handleClick(id) {
        if (id === "clearButton") {
            this.clearAll();
        }
        else if (id === "backButton") {
            this.previousPage();
        }
        else if (id === "nextButton") {
            this.nextPage();
        }
        else if (id === "addButton") {
            this.addNewCondition();
        }
        else if (id === "deleteButton") {
            this.deleteCondition();
        }
        else if (id === "submitButton") {
            this.submitIIA();
        }
    }

    clearAll() {
        let buttonsNames = ["backDisabled", "nextDisabled", "addDisabled", "submitDisabled"];

        this.props.disableButtons(buttonsNames);
        localStorage.removeItem("partnersDetails");
        // localStorage.removeItem("calendarDetails");
        localStorage.removeItem("coopConditions");
        localStorage.removeItem("iiaCreationAuxiliarInformation");
        sessionStorage.removeItem("tcCheckboxCreate");
    }

    previousPage() {
        this.props.refreshPageNumber(this.props.auxInfo.pageNumber - 1);
    }

    nextPage() {
        // this.props["partnerDefaultData"]({
        //     variables: {
        //         ErasmusCode: JSON.parse(localStorage.getItem("partnersDetails")),
        //     }
        // })
        // .then(() => {
        //     // this.setState({ isErasmusCodeValid: true });
        //     this.props.auxInfo.partnerErasmusCodeValid = true;
            this.props.refreshPageNumber(this.props.auxInfo.pageNumber + 1);
        // })
        // .catch(error => {
        //     // this.setState({ isErasmusCodeValid: false });
        //     this.props.auxInfo.partnerErasmusCodeValid = false;
        //     console.log("Partner Erasmus Code: " + JSON.stringify(error));
        // });
    }

    addNewCondition() {
        this.props.addNewCoopCondition();
        this.props.refreshPageNumber(this.props.auxInfo.pageNumber + 1);
        this.props.refreshNumberOfConditions(true);
    }

    deleteCondition() {
        if (window.confirm("Are you sure do you want to delete this condition?")) {
            this.props.deleteCondition(this.props.auxInfo.pageNumber);
            this.props.refreshPageNumber(this.props.auxInfo.pageNumber - 1);
            this.props.refreshNumberOfConditions(false);
        }
    }

    submitIIA() {
        this.props.submitIIA();
    }
}

const getIIADefaultData = gql`
    query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
        getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
            stuNomAutumnTerm
            stuNomSpringTerm
            appNomAutumnTerm
            appNomSpringTerm
            appProcedureEmail
            appProcedurePhone
            appProcedureWebsite
            addReqWeeks
            ToRWeeks
            additionalRequirements {
                id
                requirement
                ects
                isced
                eqf
                details
                website
            }
            inclusionInfrastructure {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            inclusionService {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            housing_email
            housing_phone
            housing_website
            visa_email
            visa_phone
            visa_website
            insurance_email
            insurance_phone
            insurance_website
            additionalInformation {
                id
                information
                email
                phone
                website
            }
        }
    }
`;

export default compose(
    // graphql(getIIADefaultData, { name: "partnerDefaultData" })
) (Buttons);