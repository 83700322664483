import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from './../../GlobalVar';

function ShortTermRow(props) {

    const parseStatus = (status) => {
      let newStatus = status.replace(/[_-]/g, ' ');
      if(status.includes("final")){
          newStatus = newStatus.replace("final", "changes");
      }
      if(status.includes("preliminary")){
          newStatus = newStatus.replace("preliminary", "").replace(/\s+/g, ' ');
      }
      if(status.includes("receiving")){
          newStatus = newStatus.replace("receiving", "").replace(/\s+/g, ' ');
          newStatus = newStatus.replace("sending", "student/sending/receiving");
          newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
      }
      if(status.includes("sending") && !status.includes("receiving")){
          newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
          newStatus = newStatus.replace("sending", "student/sending");
      }
      return newStatus;
    }

    const shortTerm = props.shortTerm;
    const academicYear = (props.firstYear && props.secondYear) ? props.firstYear+"-"+props.secondYear : null;
    const shortTermUrl = props.typeOfStudent === 1 ? `/outgoing-student/short-term/${shortTerm.shortTermID}` : `/incoming-student/short-term/${shortTerm.shortTermID}`;
    //only list preliminary_unsigned when uploaded from the dash and for outgoing students
    if(shortTerm.field_la_status != "preliminary_unsigned" || 
      (props.typeOfStudent == 1 && 
        shortTerm.field_dashboard_student_email != null)
      ) {
      return (
        <tr className={"tr_student row"} role="row">
          <td className={"td_student column"} role="cell">
            <Link className="black_link" to={{pathname: shortTermUrl, state: { academicYear: academicYear }}}>
              <p>{shortTerm.field_student_firstname}</p>
            </Link>
          </td>
          <td className={"td_student column"} role="cell">
            <Link className="black_link" to={{pathname: shortTermUrl, state: { academicYear: academicYear }}}>
              <p>{shortTerm.field_student_lastname}</p>
            </Link>
          </td>
          <td className={"td_student column"} role="cell">
            <p>{shortTerm.field_planned_period_from}</p>
          </td>
          <td className={"td_student column"} role="cell">
            <p>{shortTerm.field_planned_period_to}</p>
          </td>
          <td className={"td_student column status"} role="cell">
            <p className="capitalize">{parseStatus(shortTerm.field_la_status)}</p>
          </td>
          <td className={["td_student column", (props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution"].join(" ")} role="cell">
            <p>{(props.typeOfStudent === 1) ? ((shortTerm.receiving_institution != "") ? shortTerm.receiving_institution : "Unknown") : ((shortTerm.sending_institution != "") ? shortTerm.sending_institution : "Unknown")}</p>
          </td>
          <td className={"td_student column"} role="cell">
            <p>
              <Link className="button" to={{pathname: shortTermUrl, state: { academicYear: academicYear }}}>
                Open Short-Term Mobility
              </Link>
            </p>
          </td>
        </tr>
      )
    } else {
      return (null);
    }
}

export default ShortTermRow;