
import React, { Component } from 'react';

import { query } from '../../../queries/getDocuments';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import { Link } from 'react-router-dom';

import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

class DocumentList extends Component {
  constructor(props){
    super(props)
  }

  redirectUser(){
    const title = this.props.data.documents[0].title
    this.props.history.push(GlobalVar.generateLinkUrl(intl, "Support", "Documentation")+`/title/${title}`)  
  }
  render() { 

  	if(this.props.data.loading){
      return (
        <div className="document">
          <div className="card">
          </div>
        </div>
      )
    }
    else {
      console.log(this.props)
    this.redirectUser();
      return (
  		  <div>
  	    </div>
      );
    }
  }
}

export default graphql(query)(DocumentList);