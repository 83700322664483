import React, { Component } from 'react';
import { query } from '../../../queries/getVideoTutorials';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import SingleTutorial from './SingleTutorial';

class EUFtutorials extends Component {
	addTutorial(event){
		event.preventDefault();
		this.props.addTutorialVideo({
			refetchQueries: [{query}]
		})
	}
	generateSingleTutorials(){
		return this.props.data.tutorialVideos.map(data => {
			return <SingleTutorial key={data.id} data={data} />
		})
	}
	render() { 
		if(this.props.data.loading){
			return (
				<div className="content documentation">
				</div>
			)
		}
		return (
			<div className="content documentation">
				<div className="division_50">
					<div className="card">
						<h2>Tutorials edit mode</h2>
						<div className="inside_content">
							{this.generateSingleTutorials()}
						</div>
	                </div>	
		    		<form onSubmit={(event) => this.addTutorial(event)}>
		    		  <div className="round-button-circle"><button className="round-button">+</button></div>
	                </form> 
                </div>
			</div>
		);
	} 
}

const addTutorialVideo = gql`
  mutation{
    addTutorialVideo{
    	description
    }
  }
`

export default compose(
	graphql(query),
	graphql(addTutorialVideo, { name: 'addTutorialVideo'})
)(EUFtutorials);