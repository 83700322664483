import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import SingleOLA from './popup/SingleOLA';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from './../../GlobalVar';

class OlaRow extends Component {

    parseStatus = (status) => {
      let newStatus = status.replace(/[_-]/g, ' ');
      if(status.includes("final")){
        newStatus = newStatus.replace("final", "changes");
      }
      if(status.includes("preliminary")){
        newStatus = newStatus.replace("preliminary", "").replace(/\s+/g, ' ');
      }
      if(status.includes("receiving")){
        newStatus = newStatus.replace("receiving", "").replace(/\s+/g, ' ');
        newStatus = newStatus.replace("sending", "student/sending/receiving");
        newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
      }
      if(status.includes("sending") && !status.includes("receiving")){
        newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
        newStatus = newStatus.replace("sending", "student/sending");
      }
      return newStatus;
    }

    render() {
        const la = this.props.la;
        const academicYear = (this.props.firstYear && this.props.secondYear) ? this.props.firstYear+"-"+this.props.secondYear : null;
        const singleOLAUrl = this.props.typeOfStudent === 1 ? `/outgoing-student/ola/${la.olaID}` : `/incoming-student/ola/${la.olaID}`;
        //only list preliminary_unsigned when uploaded from the dash and for outgoing students
        if(la.field_la_status != "preliminary_unsigned" || (this.props.typeOfStudent == 1 && la.field_dashboard_student_email != null))
        {
        return (
          <tr className={"tr_student row"} role="row">
            <td className={"td_student column"} role="cell">
              <Link className="black_link" to={{pathname: singleOLAUrl, state: { academicYear: academicYear }}}>
                <p>{la.field_student_firstname}</p>
              </Link>
            </td>
            <td className={"td_student column"} role="cell">
              <Link className="black_link" to={{pathname: singleOLAUrl, state: { academicYear: academicYear }}}>
                <p>{la.field_student_lastname}</p>
              </Link>
            </td>
            <td className={"td_student column"} role="cell">
              <p>{la.field_planned_period_from}</p>
            </td>
            <td className={"td_student column"} role="cell">
              <p>{la.field_planned_period_to}</p>
            </td>
            <td className={"td_student column status"} role="cell">
              <p className="capitalize">{this.parseStatus(la.field_la_status)}</p>
            </td>
            <td className={["td_student column", (this.props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution"].join(" ")} role="cell">
              <div className={["column", (this.props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution"].join(" ")}>
                <p>{(this.props.typeOfStudent === 1) ? ((la.receiving_institution != "") ? la.receiving_institution : "Unknown") : ((la.sending_institution != "") ? la.sending_institution : "Unknown")}</p>
              </div>
            </td>
            <td className={"td_student column"} role="cell">
              <p>
                <Link className="button" to={{pathname: singleOLAUrl, state: { academicYear: academicYear }}}>
                  Open Learning Agreement
                </Link>
              </p>
            </td>
          </tr>
        )
      } else {
        return (null);
      }
    }
}

export default OlaRow;
