import gql from 'graphql-tag';

const query = gql`
  query ($ID: String, $VID: String, $eCode: String, $encryptedECode: String) {
    LApdf(ID: $ID, VID: $VID, eCode: $eCode, encryptedECode: $encryptedECode){
      file,
      error
  }
}`

export { query };
