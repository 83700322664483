import React, { Component } from 'react';


class HeiFilter extends Component {
    state={
        value:""
    }
	changeSelection(event){
		this.setState({
			value: event.target.value
        })
        this.props.onSelectedHEI(event.target.value)
			}
	renderΗΕΙ(){
        var unique = [...new Set(this.props.data.map(item => item.sending_institution_hei)),...new Set(this.props.data.map(item => item.receiving_institution_hei))]
        return [...new Set(unique)].sort().map((extractedName, index) => {
          if (extractedName !==null && extractedName !== "")
            return <option key={index} value={extractedName} >{extractedName}</option>;
        })
	}

	
	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
      return (
        <select id="filter-hei" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={""}>Select HEI Erasmus Code</option>
					{this.renderΗΕΙ()}
        </select>
      )
  	}
  }

};

export default HeiFilter;