import gql from 'graphql-tag';

const query = gql`
query ($homeErasmusCode: String!, $hostErasmusCode: String!, $academicYear: String!, $coopConditionsID: String!) {
    nominatedStudents(homeErasmusCode:$homeErasmusCode, hostErasmusCode:$hostErasmusCode,  academicYear:$academicYear)
    applicationCoopConditions(coopConditionsID:$coopConditionsID){
        totalMonths
        mobilitiesPerYear
		iia
    }
}
`;
export { query };