import gql from 'graphql-tag';
const query = gql`
	query {
	  allLanguages{
	    ID
        Name
        Code
	  }
	}
`;
export { query };
