import React, { Component } from 'react';
import SearchInstitutionBox from './SearchInstitutionBox';

import intl from 'react-intl-universal';
import xregexp from 'xregexp';
import mailValidator from 'email-validator';
import moment from 'moment';
import { compose,graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query } from '../../../queries/getTutorial';
//import HtmlTableToJson from 'html-table-to-json';



class InputColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			error: false,
			errorText: "",
			valid: false,
			searchValue: "",
			searchOpen: false
		}
		this.timeout = null;
		this.searchtimeout = null;
		this.takeSearchResult = this.takeSearchResult.bind(this);
		this.valid = true;
	}
	componentWillMount() {
		//
		if(this.props.value != ""){
			this.validation(this.props.value);
		}
		this.setState({
			value: this.props.value
		});
	}
	componentWillReceiveProps(nextProps) {
		let {pastedValue} = nextProps;

		if(Array.isArray(pastedValue) && this.props.pastedValue !== pastedValue){
			let mappedIndex = nextProps.index-nextProps.indexColumn;
			if(pastedValue[mappedIndex] !== undefined){
				//console.log("row", this.props.rowIndex + 1, "column", this.props.index + 1, "value", pastedValue[mappedIndex]);
				this.validation(pastedValue[mappedIndex]);
				this.setState({
					value: pastedValue[mappedIndex]
				})
				this.saveValue(pastedValue[mappedIndex]);
			}
		}
		else if(nextProps.indexColumn === nextProps.index && nextProps.pastedValue !== this.props.pastedValue){
			this.validation(pastedValue);
			this.setState({
				value: pastedValue
			})
			this.saveValue(pastedValue);
		}

		if(nextProps.uploading && !this.props.uploading){
			this.valid = true;
			this.validation(this.state.value);
			//console.log(this.valid, this.state.value);
			this.props.handleCheckingBeforeUpload(this.valid);
		}
	}
	generateDivision(){
		if(this.props.division){
			return " division";
		}
		else{
			return "";
		}
	}
	cleanEmptyRows(initialArray){
		let returnArray = [];
		initialArray.map((pastedValues) => {
			if(pastedValues !== ""){
				returnArray.push(pastedValues);
			}
		});
		return returnArray;
	}

	pasting(event){

		const valuePlain = event.clipboardData.getData('Text');
		const valueHTML = event.clipboardData.getData('text/html');

		if(valuePlain){
			let rowArray = valuePlain.split(/\r|\n/);
			rowArray = this.cleanEmptyRows(rowArray);

			const multiDimensionalArray = [];
			rowArray.forEach((dataRow) => {
				const columnData = dataRow.split("\t");
				if(columnData.length > 1){
					multiDimensionalArray.push(columnData);
				}
				else{
					multiDimensionalArray.push(dataRow);
				}
			})

			if(multiDimensionalArray.length > 1){
				event.preventDefault();
				this.props.handlePastingData(multiDimensionalArray, this.props.index);
			}
		}
		/*
		// TODO:  enable this code and try to build it. It's for excel to safari copy paste
		else if(valueHTML){
			this.setState({value: ""});
			let multiDimensionalArray = new HtmlTableToJson(valueHTML);
			multiDimensionalArray = multiDimensionalArray.results[0];

			if(multiDimensionalArray){
				multiDimensionalArray = multiDimensionalArray.map(row => {
					return Object.values(row);
				});
				if(multiDimensionalArray.length > 1){
					event.preventDefault();
					this.props.handlePastingData(multiDimensionalArray, this.props.index);
				}
			}
		}*/
	}
	typing(event){

		if(event.keyCode === 13){
			event.preventDefault();
		}
	}
	checkTyping(event){

		if(event.keyCode === 9 && this.state.value === ""){

		}
		else{
			clearTimeout(this.timeout);
		    const thisClass = this;
		    this.timeout = setTimeout(function () {
		        thisClass.validation(thisClass.state.value);
		        if(thisClass.props.selected){
			    	thisClass.props.handleMultiFillInData(thisClass.state.value, thisClass.props.index);
			    }
		    }, 500);
	    }

	}
	changeValue(event){
		const value = event.target.value;
		this.setState({
			value
		})
		this.saveValue(value);
	}

	saveValue(value){
		let studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
		studentUpload[this.props.rowIndex][this.props.name] = value;
		localStorage.setItem("studentUpload", JSON.stringify(studentUpload));
	}
	generatePlaceholder(){
		if(this.props.date){
			return intl.get('Fill in')+" (MM-YYYY)";
		}
		return intl.get('Fill in');
	}
	validateRegularNames(name, value, unicodeWord){
		if(name === "firstName" || name === "lastName" || name === "hostContactName" || name === "hostResponsibleName"){
			const unicodeValue = value.replace(/[\s-]/g, "");
			if(!unicodeWord.test(unicodeValue) || /^\s/.test(value) || /\s$/.test(value)){
				this.setState({error: true});
				if(value.length === 0){
					//
					this.setState({errorText: "This field may not be empty."});
					this.valid = false;
					return false;
				}
				if(!unicodeWord.test(unicodeValue)){
					this.setState({errorText: "This field may not contain any symbols or numbers."});
					this.valid = false;
					return false;
				}
				return this.validationSpaces(value);
			}
			this.setState({valid: true, error: false, errorText: ""})
		}
	}

	validateEmailFormat(name, value, unicodeWord){
		if(name === "email" || name === "hostContactEmail" || name === "hostResponsibleEmail"){
			//
			if(!mailValidator.validate(value) || /^\s/.test(value) || /\s$/.test(value)){
				this.setState({error: true});
				if(value.length === 0){
					this.setState({errorText: "This field may not be empty."});
					this.valid = false;
					return false;
				}
				if(!mailValidator.validate(value)){
					this.setState({errorText: "Wrong mail format"});
					this.valid = false;
					return false;
				}
				return this.validationSpaces(value);
			}
			this.setState({valid: true, error: false, errorText: ""})
		}
	}
	validateStudentEmailDouble(name, value){
		if(name === "email" && value !== ""){
			let studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
			let duplicateMail = false;
			let sameEmailAt = "";
			studentUpload.forEach((student, index) => {
				if(this.props.rowIndex !== index && student.email === value){
					const humanIndex = index+1;
					sameEmailAt = sameEmailAt + humanIndex + ", ";
					duplicateMail = true;
				}
			});
			if(duplicateMail){
				sameEmailAt = sameEmailAt.slice(0, -2);
				this.setState({
					error: true,
					errorText: `Two students can't have the same email. Same email at row ${sameEmailAt}`
				});
				this.valid = false;
				return false;
			}
		}
	}
	validateUniSpecificWords(name, value, unicodeWord){
		if(name === "faculty" || name === "fieldOfEducation"){
			const unicodeValue = value.replace(/[\d\s-\&]/g, "");

			if(!unicodeWord.test(unicodeValue) || /^\s/.test(value) || /\s$/.test(value)){
				this.setState({error: true});
				if(!unicodeWord.test(unicodeValue)){
					this.setState({errorText: "This field may not contain any symbols except of '-' and '&'."});
					this.valid = false;
					return false;
				}
				return this.validationSpaces(value);
			}

			this.setState({valid: true, error: false, errorText: ""})
		}
	}

	validateDates(name, value, unicodeWord){
		if(name === "beginDate" || name === "endDate"){
			if(!/^(0[1-9]|1[0-2])\-(19|2[0-1])\d{2}$/.test(value) || /^\s/.test(value) || /\s$/.test(value) || value.length === 0){
				this.setState({error: true});
				if(value.length === 0){
					this.setState({errorText: "This field may not be empty."});
					this.valid = false;
					return false;
				}
				if(!/^(0[1-9]|1[0-2])\-(19|2[0-1])\d{2}$/.test(value)){
					this.setState({errorText: "Wrong date format (MM-YYYY) or out of range."});
					this.valid = false;
					return false;
				}
				return this.validationSpaces(value);
				return false;
			}
		    if(name === 'beginDate' && new Date(value).getFullYear() < 2013){
			    this.setState({errorText: "date should be greater than 2012."});
				this.valid = false;
				return false;
		    }
		    if(name === 'endDate' && new Date(value).getFullYear() > 2050){
			    this.setState({errorText: "date should be less than 2050."});
				this.valid = false;
				return false;
		    }

		    this.setState({valid: true, error: false, errorText: ""})
		}
		if(name === "beginDate"){
			let studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
			let endDate = studentUpload[this.props.rowIndex]["endDate"];
			endDate = moment(endDate, "MM-YYYY");
			const beginDate = moment(value, "MM-YYYY");
			if(beginDate > endDate){
				this.setState({errorText: "The begin date can't be later than the end date", error: true});
				this.valid = false;
				return false;
			}
			if(beginDate.isSame(endDate)){
				this.setState({errorText: "The begin date can't be the same than the end date", error: true});
				this.valid = false;
				return false;
			}
			if(beginDate < moment("01-2013", "MM-YYYY")){
				this.setState({errorText: "The begin date can't be smaller than 2013", error: true});
				this.valid = false;
				return false;
			}
			if(beginDate > moment("12-2049", "MM-YYYY")){
				this.setState({errorText: "The begin date can't be later than 2049", error: true});
				this.valid = false;
				return false;
			}
			this.props.handleDateComparing(false);
		}
		if(name === "endDate"){
			let studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
			let beginDate = studentUpload[this.props.rowIndex]["beginDate"];
			beginDate = moment(beginDate, "MM-YYYY");
			const endDate = moment(value, "MM-YYYY");
			if(beginDate > endDate){
				this.setState({errorText: "The end date can't be sooner than the begin date", error: true});
				this.valid = false;
				return false;
			}
			if(beginDate.isSame(endDate)){
				this.setState({errorText: "The end date can't be the same than the begin date", error: true});
				this.valid = false;
				return false;
			}
			if(endDate < moment("01-2013", "MM-YYYY")){
				this.setState({errorText: "The end date can't be smaller than 2013", error: true});
				this.valid = false;
				return false;
			}
			if(endDate > moment("12-2049", "MM-YYYY")){
				this.setState({errorText: "The end date can't be later than 2049", error: true});
				this.valid = false;
				return false;
			}
			this.props.handleDateComparing(false);
		}
	}

	validateReceivingInstitution(name, value){
		if(name === "receivingInstitution"){
			if(value.length === 0){
				this.setState({error: true, errorText: "This field may not be empty."});
				this.valid = false;
				return false;
			}
			if(!/^[A-Z|\s]{1,3}[A-Z-.]{1,7}\d{2,3}\s?$/.test(value)){
				this.setState({error: true, errorText: "Invalid erasmus code"});
				this.valid = false;
				return false;
			}
			this.setState({valid: true, error: false, errorText: ""})
		}
	}

	canBeEmpty(name, value){
		if(name === "faculty" || name === "fieldOfEducation" || name === "hostContactName" || name === "hostContactEmail" || name === "hostResponsibleName" || name === "hostResponsibleEmail"){
			if(value.length === 0){
				this.setState({valid: false, error: false, errorText: ""});
				this.valid = true;
			}
		}
	}

	validation(value){
		const {name} = this.props;
		const unicodeWord = xregexp('^\\pL+$');

		this.validateRegularNames(name, value, unicodeWord);
		this.validateEmailFormat(name, value, unicodeWord);
		this.validateStudentEmailDouble(name, value);
		this.validateUniSpecificWords(name, value, unicodeWord);
		this.validateDates(name, value, unicodeWord);
		this.validateReceivingInstitution(name, value);
		this.canBeEmpty(name, value);
	}
	validationSpaces(value){
		if(/^\s/.test(value)){
			this.setState({errorText: "This field may not begin with a space."});
			this.valid = false;
			return false;
		}
		if(/\s$/.test(value)){
			this.setState({errorText: "This field may not end with a space."});
			this.valid = false;
			return false;
		}
	}
	checkError(){
		if(this.state.error){
			return "error_field";
		}
		else if(this.state.valid){
			return "valid_field";
		}
	}
	generateErrorMessage(){
		if(this.state.error){
			return <div className="error_message"><p>{this.state.errorText}</p></div>
		}
	}
	renderSearchBox(){
		if(this.state.searchValue.length > 0){
			if(this.state.searchOpen === false){
				this.setState({
					searchOpen: true
				}, function(){
					this.props.handlePopUpList(true);
				})
			}
			return <SearchInstitutionBox value={this.state.searchValue} takeSearchResult={this.takeSearchResult} />;
		}
		else if(this.state.searchOpen === true){
			this.props.handlePopUpList(false);
			this.setState({
				searchOpen: false
			})
		}
	}
	searchInstitution(){
		clearTimeout(this.searchtimeout);
	    const thisClass = this;
	    this.searchtimeout = setTimeout(function () {
	        thisClass.setState({
	        	searchValue: thisClass.state.value
	        })
	    }, 500);
	}
	takeSearchResult(newValue){
		this.setState({
			value: newValue,
			searchOpen: false,
			searchValue: ""
		}, function(){
			this.props.handlePopUpList(false);
		});
		this.validation(newValue);
		this.saveValue(newValue);
		this.props.handleMultiFillInData(newValue, this.props.index);
	}
	closeSearchBox(){
		this.setState({
			searchOpen: false
		}, () => {
			console.log("validate again", this.state.value);
			this.validation(this.state.value);
		});
	}


	render() {
		if(this.props.name === "receivingInstitution"){
			return(
				<div className={"column"+this.generateDivision()}>
					<textarea className={this.checkError()} placeholder={this.generatePlaceholder()} value={this.state.value} onKeyUp={() => this.searchInstitution()} onPaste={(event) => this.pasting(event)} onChange={(event) => this.changeValue(event)} onBlur={() => this.closeSearchBox()} rows="1" />
					{this.generateErrorMessage()}
					{this.renderSearchBox()}
				</div>
			)
		}
		return(
			<div className={"column"+this.generateDivision()}>
				<textarea className={this.checkError()} placeholder={this.generatePlaceholder()} value={this.state.value} onChange={(event) => this.changeValue(event)} onPaste={(event) => this.pasting(event)} onKeyUp={(event) => this.checkTyping(event)} onKeyDown={(event) => this.typing(event)} rows="1" />
				{this.generateErrorMessage()}
			</div>
		)
    }
};

export default InputColumn;
