import React, { Component } from 'react';
import GlobalVar from '../../../GlobalVar';
import RevisionsTable from "./RevisionsTable";
import intl from 'react-intl-universal';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { query as getSingleOLA } from '../../../queries/getSingleOLA';
import { query as accountInfo } from '../../../queries/getAccountData';
import Loader from 'react-loader-spinner';
import PreliminaryTable from './PreliminaryTable';
import FinalTable from './FinalTable';
import DeclineOLA from './DeclineOLA';
import AcceptOLA from './AcceptOLA';
import moment from 'moment';
import {query as isOLAManagedByEWP} from "../../../queries/isOLAManagedByEWP";
import NoAccess from "../NoAccess";

class SingleOLA extends Component{
    constructor(props) {
        super(props)
        this.state = {
            processingDownloadOLA: false,
            processingSignature: false,
            processingDelete: false,
            openDeleteAlert: false,
            deleteError: "",
            typeOfStudent: -1,
            autoRecognitionData: []
        }

        this.typeOfStudent = props.typeOfStudent;
        this.typeOfStudentName = props.typeOfStudentName;
        this.handleCallbackFromTable = this.handleCallbackFromTable.bind(this);
    }

    toPreviousPage(event){
        event.preventDefault();
        if(this.props.history.location.state != null){
            if(this.props.history.location.state.academicYear != null){
                if(this.props.location.pathname.includes("outgoing")) {
                    this.props.history.replace({pathname:"/ola3/outgoing-students/"+this.props.history.location.state.academicYear });
                } else {
                    this.props.history.replace({pathname:"/ola3/incoming-students/"+this.props.history.location.state.academicYear });
                }
            } else {
                if(this.props.location.pathname.includes("outgoing")) {
                    this.props.history.replace({pathname:"/ola3/outgoing-students/" });
                } else {
                    this.props.history.replace({pathname:"/ola3/incoming-students/" });
                }
            }
        } else {
            if(this.props.location.pathname.includes("outgoing")) {
                this.props.history.replace({pathname:"/ola3/outgoing-students/" });
            } else {
                this.props.history.replace({pathname:"/ola3/incoming-students/" });
            }
        }
    }

    handleCallbackFromTable = (dataFromTable) => {
        this.setState(prevState => ({
            autoRecognitionData: [...prevState.autoRecognitionData, dataFromTable]
        }));
    }

    handleSigning = (val) => {
        this.setState(() => ({
          processingSignature: val
        }));
    }

    renderSignDeclineBox(typeOfStudent, accountsNames){
        let autoRecognitionData = this.state.autoRecognitionData;
        if(GlobalVar.checkAccountFunction("managingStudents")){
            return (
              <div>
                <div className="sub_content sub_content_half">
                    <h3>Sign OLA</h3>
                    {<AcceptOLA
                        onProcessing={this.handleSigning}
                        accounts={accountsNames}
                        typeOfStudent={typeOfStudent}
                        autoRecognitionData={autoRecognitionData}
                        data={this.props} />}
                </div>
                <div className="sub_content sub_content_half">
                    <h3>Decline OLA</h3>
                    {<DeclineOLA
                        onProcessing={this.handleSigning}
                        accounts={accountsNames}
                        typeOfStudent={typeOfStudent}
                        autoRecognitionData={autoRecognitionData}
                        data={this.props}/>}
                </div>
              </div>
            )
        } else {
            return (null)
        }
    }

    findValue(value) {
        if(value == null || value === "" || value.includes("null")){
            return <p className="italic">Not found</p>;
        }
        return value;
    }

    parseStatus = (status) => {
        let newStatus = status.replace(/[_-]/g, ' ');
        if(status.includes("final")){
            newStatus = newStatus.replace("final", "changes");
        }
        if(status.includes("preliminary")){
            newStatus = newStatus.replace("preliminary", "").replace(/\s+/g, ' ');
        }
        if(status.includes("receiving")){
            newStatus = newStatus.replace("receiving", "").replace(/\s+/g, ' ');
            newStatus = newStatus.replace("sending", "student/sending/receiving");
            newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
        }
        if(status.includes("sending") && !status.includes("receiving")){
            newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
            newStatus = newStatus.replace("sending", "student/sending");
        }
        return newStatus;
    }

    generateDownloadButton(){
        return (
            <p>
                <a className="button" target="_blank" href={`/ola3/export/${this.props.data.learningAgreement.olaID}`}>
                    Download
                </a>
            </p>
        );
    }

    handleDeletePopup(e) {
        e.preventDefault();
        if(!this.state.openDeleteAlert){
            this.setState({
                openDeleteAlert: true
            });
        } else {
            this.setState({
                openDeleteAlert: false
            });
        }
    }

    deleteOLA = (e, ID, status) => {
        e.preventDefault();
        if(this.state.openDeleteAlert){
            this.setState({
              processingDelete: true
            });
            this.props.deleteOLA({
                variables: {
                    olaID: ID,
                    status: status
                }
            }).then((response) => {
                setTimeout(function() {
                    this.setState({
                        processingDelete: false
                    });
                    this.setState({
                        openDeleteAlert: false
                    });
                    this.toPreviousPage(e);
                }.bind(this), 300);
            }).catch(error => {
                this.setState({
                    openDeleteAlert: false
                });
                alert("Error on deleting the mobility");
                window.location.reload(false);
            });
        }
    }

    generateDeleteButton(ID, isOutgoing){
        const loginAccountName = localStorage.getItem("Name");
        const status = this.props.data.learningAgreement.field_la_status;
        const statusArray = ["preliminary_unsigned", "preliminary_signed-by-student"];

        if(loginAccountName != null && loginAccountName.localeCompare("undefined") !== 0 && loginAccountName != ""
            && statusArray.includes(status) && isOutgoing) {
            return (
                <div className="sub_content delete">
                    <p>
                        <a className="button button-danger" target="_blank" href="#" onClick={(e) => this.handleDeletePopup(e)}>
                            Delete LA
                        </a>
                    </p>
                    {this.state.openDeleteAlert ?
                        (<div>
                            <div onClick={(e) => this.handleDeletePopup(e)} className="black_background"></div>
                            <div className={`content popup single_popup delete_popup`}>
                                {this.state.processingDelete ? (<div className="white_background">
                                    <Loader
                                        className="spinner"
                                        type="TailSpin"
                                        color="#3986df"
                                        height={50}
                                        width={50} />
                                </div>) : null}
                                <div className="sub_content">
                                    <h3>Are you sure?</h3>
                                </div>
                                <div>
                                    <button className="confirm" onClick={(e) => this.deleteOLA(e, ID, status)}>Yes</button>
                                    <button className="cancel" onClick={(e) => this.handleDeletePopup(e)}>No</button>
                                </div>
                            </div>
                        </div>) : null
                    }
                </div>
            );
        } else {
            return null;
        }
    }

    setTypeOfStudent = (erasmusCode, sendingErasmusCode, receivingErasmusCode) => {
        let typeOfStudent = -1;
        if(receivingErasmusCode.localeCompare(sendingErasmusCode) === 0) {
            typeOfStudent = this.props.location.pathname.includes("outgoing") ? 1 : 2;
        } else {
            if(erasmusCode.localeCompare(sendingErasmusCode) === 0){
                typeOfStudent = 1;
            } else {
                typeOfStudent = 2;
            }
        }
        return typeOfStudent;
    }

    getAccountsNames(accounts) {
        let names = [];
        for (let key in accounts) {
           if (accounts.hasOwnProperty(key)) {
              names.push(accounts[key].["Name"]);
           }
        }
        return names;
    }

    render(){
        if(GlobalVar.checkAccountFunction("managingStudents,viewingStudents")){
            if (this.props.data.loading||this.props.isOLAManagedByEWP.loading) {
                return (
                    <div className={`content singleOLA`}>
                        <div className="card card_full_width">
                            <div className="white_background">
                                <Loader
                                    className="spinner"
                                    type="TailSpin"
                                    color="#3986df"
                                    height={70}
                                    width={70} />
                            </div>
                        </div>
                    </div>
                );
            }
            if (this.props.isOLAManagedByEWP.isOLAManagedByEWP.Success !== 2) {
                return (
                   <NoAccess/>
                );
            }

                const error = this.props.data.learningAgreement.error ? true : false;

            if(!error) {
                const accounts = this.props.accountInfo.accounts;
                const accountsNames = this.getAccountsNames(accounts);
                const learningAgreement = this.props.data.learningAgreement;
                const olaID = this.props.data.learningAgreement.olaID;
                const {
                    title, created, field_la_status, field_academic_year, field_planned_period_from, field_planned_period_to
                } = learningAgreement;

                const sending_institution = this.props.data.learningAgreement.sending_institution;
                const sending_erasmus_code = sending_institution ? sending_institution.field_hei_erasmus_code : "";
                const receiving_institution = this.props.data.learningAgreement.receiving_institution;
                const receiving_erasmus_code = receiving_institution ? receiving_institution.field_hei_erasmus_code : "";
                const userErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
                const typeOfStudent = this.setTypeOfStudent(userErasmusCode, sending_erasmus_code, receiving_erasmus_code);
                const isOutgoing = typeOfStudent === 1 ? true : false;

                const field_student_firstname = this.props.data.learningAgreement.field_student_firstname;
                const field_student_lastname = this.props.data.learningAgreement.field_student_lastname;
                const field_student_email = this.props.data.learningAgreement.field_student_email;
                const field_student_birthdate = this.props.data.learningAgreement.field_student_birthdate;
                const gender = this.props.data.learningAgreement.gender[0] ? this.props.data.learningAgreement.gender[0].name : "Unknown";
                const nationality = this.props.data.learningAgreement.nationality[0] ? this.props.data.learningAgreement.nationality[0].name : "Unknown";
                const field_student_education = this.props.data.learningAgreement.isced[0] ? this.props.data.learningAgreement.isced[0] : "Unknown";
                const isced = field_student_education.name ? field_student_education.name : "Unknown";
                const clarification = this.props.data.learningAgreement.field_of_education_comment;

                const sending_contact_person = this.props.data.learningAgreement.sending_contact_person;

                const receiving_contact_person = this.props.data.learningAgreement.receiving_contact_person;

                const receiving_resp_person = this.props.data.learningAgreement.receiving_resp_person;

                const sending_resp_person = this.props.data.learningAgreement.sending_resp_person;

                const send_contact_firstname = (sending_contact_person[0]) ? sending_contact_person[0].field_per_firstname : "Unknown";
                const send_contact_lastname = (sending_contact_person[0]) ? sending_contact_person[0].field_per_lastname : "Unknown";
                const send_contact_email = (sending_contact_person[0]) ? sending_contact_person[0].field_per_email : "Unknown";
                const send_contact_phone = (sending_contact_person[0]) ? sending_contact_person[0].field_per_phone: "Unknown";

                const send_resp_firstname = (sending_resp_person[0]) ? sending_resp_person[0].field_per_firstname : "Unknown";
                const send_resp_lastname = (sending_resp_person[0]) ? sending_resp_person[0].field_per_lastname : "Unknown";
                const send_resp_email = (sending_resp_person[0]) ? sending_resp_person[0].field_per_email : "Unknown";
                const send_resp_phone = (sending_resp_person[0]) ? sending_resp_person[0].field_per_phone: "Unknown";

                const rec_contact_firstname = (receiving_contact_person[0]) ? receiving_contact_person[0].field_per_firstname : "Unknown";
                const rec_contact_lastname = (receiving_contact_person[0]) ? receiving_contact_person[0].field_per_lastname : "Unknown";
                const rec_contact_email = (receiving_contact_person[0]) ? receiving_contact_person[0].field_per_email : "Unknown";
                const rec_contact_phone = (receiving_contact_person[0]) ? receiving_contact_person[0].field_per_phone: "Unknown";

                const rec_resp_firstname = (receiving_resp_person[0]) ? receiving_resp_person[0].field_per_firstname : "Unknown";
                const rec_resp_lastname = (receiving_resp_person[0]) ? receiving_resp_person[0].field_per_lastname : "Unknown";
                const rec_resp_email = (receiving_resp_person[0]) ? receiving_resp_person[0].field_per_email : "Unknown";
                const rec_resp_phone = (receiving_resp_person[0]) ? receiving_resp_person[0].field_per_phone: "Unknown";

                return (
                    <div>
                        <div className={`content singleOLA`}>
                            <div className="card card_full_width">
                                {this.state.processingSignature ?
                                    <div className="white_background">
                                        <Loader
                                            className="spinner"
                                            type="TailSpin"
                                            color="#3986df"
                                            height={80}
                                            width={80} />
                                    </div>
                                    : null}

                                <div className="header">
                                    <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                                        <button>
                                            {"<"} Go back
                                        </button>
                                    </form>
                                </div>

                                <div className="sub_content">
                                    <h3>Learning Agreement info</h3>
                                    <ul className="regular_list">
                                        <li><span className="label">LA-id</span><span className="value">{this.findValue(title)}</span></li>
                                        <li><span className="label">Status</span><span className="value capitalize">{this.parseStatus(field_la_status)}</span></li>
                                        <li><span className="label">Academic Year</span><span className="value">{this.findValue(field_academic_year)}</span></li>
                                        <li><span className="label">Planned Period From</span><span className="value">{this.findValue(field_planned_period_from)}</span></li>
                                        <li><span className="label">Planned Period To</span><span className="value">{this.findValue(field_planned_period_to)}</span></li>
                                        <li><span className="label">Created</span><span className="value">{moment(created).format("YYYY-MM-DD")}</span></li>
                                    </ul>
                                </div>
                                <div className="sub_content">
                                    <h3>Student Personal data</h3>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Firstname</span><span className="value">{this.findValue(field_student_firstname)}</span></li>
                                            <li><span className="label">Lastname</span><span className="value">{this.findValue(field_student_lastname)}</span></li>
                                            <li><span className="label">Email</span><span className="value">{this.findValue(field_student_email)}</span></li>
                                            <li><span className="label">Birth date</span><span className="value">{this.findValue(field_student_birthdate)}</span></li>
                                        </ul>
                                    </div>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Gender</span><span className="value">{gender}</span></li>
                                            <li><span className="label">Nationality</span><span className="value">{nationality}</span></li>
                                            <li><span className="label">Field of education (ISCED)</span><span className="value">{isced}</span></li>
                                            <li><span className="label">Field of education (clarification)</span><span className="value">{this.findValue(clarification)}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="sub_content">
                                    <h3>Sending institution info</h3>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Name</span><span className="value">{(sending_institution) ? this.findValue(sending_institution.field_hei_name) : "Unknown"}</span></li>
                                            <li><span className="label">Country</span><span className="value">{(sending_institution) ? this.findValue(sending_institution.field_hei_country_label) : "Unknown"}</span></li>
                                            <li><span className="label">Erasmus Code</span><span className="value">{(sending_institution) ? this.findValue(sending_institution.field_hei_erasmus_code) : "Unknown"}</span></li>
                                            <li><span className="label">Address</span><span className="value">{(sending_institution) ? this.findValue(sending_institution.field_hei_address) : "Unknown"}</span></li>
                                            <li><span className="label">Faculty</span><span className="value">{(sending_institution) ? this.findValue(sending_institution.field_hei_faculty) : "Unknown"}</span></li>
                                        </ul>
                                    </div>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Contact person</span><span className="value">{this.findValue(`${send_contact_firstname+" "+send_contact_lastname}`)}</span></li>
                                            <li><span className="label">Contact email</span><span className="value"><a href={`mailto:${send_contact_email}`}>{this.findValue(send_contact_email)}</a></span></li>
                                            <li><span className="label">Contact phone</span><span className="value">{this.findValue(send_contact_phone)}</span></li>
                                            <li><span className="label">Res. person</span><span className="value">{this.findValue(`${send_resp_firstname+" "+send_resp_lastname}`)}</span></li>
                                            <li><span className="label">Res. email</span><span className="value"><a href={`mailto:${send_resp_email}`}>{this.findValue(send_resp_email)}</a></span></li>
                                            <li><span className="label">Res. phone</span><span className="value">{this.findValue(send_resp_phone)}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="sub_content">
                                    <h3>Receiving institution info</h3>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Name</span><span className="value">{(receiving_institution) ? this.findValue(receiving_institution.field_hei_name) : "Unknown"}</span></li>
                                            <li><span className="label">Country</span><span className="value">{(receiving_institution) ? this.findValue(receiving_institution.field_hei_country_label) : "Unknown"}</span></li>
                                            <li><span className="label">Erasmus Code</span><span className="value">{(receiving_institution) ? this.findValue(receiving_institution.field_hei_erasmus_code) : "Unknown"}</span></li>
                                            <li><span className="label">Address</span><span className="value">{(receiving_institution) ? this.findValue(receiving_institution.field_hei_address) : "Unknown"}</span></li>
                                            <li><span className="label">Faculty</span><span className="value">{(receiving_institution) ? this.findValue(receiving_institution.field_hei_faculty) : "Unknown"}</span></li>
                                        </ul>
                                    </div>
                                    <div className="division_50">
                                        <ul className="regular_list">
                                            <li><span className="label">Contact person</span><span className="value">{this.findValue(`${rec_contact_firstname+" "+rec_contact_lastname}`)}</span></li>
                                            <li><span className="label">Contact email</span><span className="value"><a href={`mailto:${rec_contact_email}`}>{this.findValue(rec_contact_email)}</a></span></li>
                                            <li><span className="label">Contact phone</span><span className="value">{this.findValue(rec_contact_phone)}</span></li>
                                            <li><span className="label">Res. person</span><span className="value">{this.findValue(`${rec_resp_firstname+" "+rec_resp_lastname}`)}</span></li>
                                            <li><span className="label">Res. email</span><span className="value"><a href={`mailto:${rec_resp_email}`}>{this.findValue(rec_resp_email)}</a></span></li>
                                            <li><span className="label">Res. phone</span><span className="value">{this.findValue(rec_resp_phone)}</span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="sub_content">
                                    <h3>Learning Agreement Table A</h3>
                                    <PreliminaryTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={1}
                                        data={this.props} />
                                </div>
                                <br/>
                                <br/>
                                <div className="sub_content">
                                    <h3>Learning Agreement Table B</h3>
                                    <PreliminaryTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={2}
                                        data={this.props} />
                                </div>
                                <br/>
                                <br/>
                                <div className="sub_content">
                                    <h3>Learning Agreement Table C</h3>
                                    <PreliminaryTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={3}
                                        data={this.props} />
                                </div>
                                <br/>
                                <br/>
                                <div className="sub_content">
                                    <h3>Changes to Learning Agreement Table A</h3>
                                    <FinalTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={1}
                                        data={this.props} />
                                </div>
                                <br/>
                                <br/>
                                <div className="sub_content">
                                    <h3>Changes to Learning Agreement Table B</h3>
                                    <FinalTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={2}
                                        data={this.props} />
                                </div>
                                <br/>
                                <br/>
                                <div className="sub_content">
                                    <h3>Changes to Learning Agreement Table C</h3>
                                    <FinalTable
                                        parentCallback={this.handleCallbackFromTable}
                                        status={field_la_status}
                                        accounts={accountsNames}
                                        isOutgoing={isOutgoing}
                                        tableType={3}
                                        data={this.props} />
                                </div>
                                {this.renderSignDeclineBox(typeOfStudent, accountsNames)}
                                <div className="sub_content download">
                                    <h3>Download the latest version</h3>
                                    {this.generateDownloadButton()}
                                </div>
                                <div className="sub_content revisions">
                                    <h3>Revisions</h3>
                                    <RevisionsTable parseStatus={this.parseStatus} data={this.props}/>
                                </div>
                                {this.generateDeleteButton(olaID, isOutgoing)}
                            </div>
                        </div>
                    </div>);
            } else {
                return (
                    <div>
                        <div className={`content singleOLA`}>
                            <div className="card card_full_width">
                                <div className="header">

                                    <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                                        <button>
                                            <img src="/icons/remove.svg" alt="close button" />
                                        </button>
                                    </form>

                                </div>
                                <div className="sub_content no_access">
                                    <h2>This Learning Agreement does not belong to your institution</h2>
                                    <p>You are trying to access a Learning Agreement that doesn't belong to your Higher Education Institution</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <div onClick={(e) => this.toPreviousPage(e)} className="black_background"></div>
                    <div className={`content popup student_popup`}>
                        <div className="header">

                            <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                                <button>
                                    <img src="/icons/remove.svg" alt="close button" />
                                </button>
                            </form>

                        </div>
                        <div className="sub_content no_access">
                            <h2>You are not allowed to access the Learning Agreement page</h2>
                            <p>Dear Erasmus Dashboard user,</p>
                            <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const deleteOLA = gql` 
    mutation DeleteOLA($olaID: String, $status: String){
        deleteOLA(olaID: $olaID, status: $status)
    }`;

export default compose(
    graphql(getSingleOLA, {
        options: (props) => ({
            variables: {
                ID: props.match.params.olaID,
                eCode: localStorage.getItem("currentErasmusCode") ? JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode : '',
                encryptedECode: localStorage.getItem('ErasmusCode'),
                pathName: props.location.pathname
            }
        })
    }),
    graphql(isOLAManagedByEWP, {name: "isOLAManagedByEWP"
        ,
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    }),
    graphql(accountInfo, {
        name: "accountInfo"
    }),
    graphql(deleteOLA, { name: 'deleteOLA'}),
)(SingleOLA);
