import React, { Component } from 'react';
import intl from 'react-intl-universal';
import GlobalVar from '../../../../GlobalVar';

class RemoveOLA extends Component {
	constructor(props){
		super(props);
		this.state = {
			error: false,
			errorText: "",
			value: ""
		}
	}
	toPreviousPage(event){
		if(event){
			event.preventDefault();
		}
		this.props.history.goBack();
	}

  initiateRemovingOLA(event){
    event.preventDefault();
    this.props.removeOLAfunction();
    this.toPreviousPage();
  }

	render() {
		return (
			<div>
				<div onClick={() => this.toPreviousPage()} className="black_background"></div>
				<div className="content popup decline_ola_pop_up">
					<h3>Are you sure?</h3>
          <p className="warning_text">Please keep in mind that deleting the OLA from the list will be permanent. The respective student Learning Agreement data will be removed from the database. Yet, the account created will be still available for students.</p>
					<div className="button_list">
						<form onSubmit={(event) => this.initiateRemovingOLA(event)}>
							<button className="decline_button">Confirm</button>
						</form>
						<form onSubmit={(event) => this.toPreviousPage(event)} >
							<button>Cancel</button>
						</form>
					</div>
				</div>
			</div>
		);
	}
};

export default RemoveOLA;
