import React from "react";
import { CSSTransition } from "react-transition-group";
import ReactTransitionGroup from "react-addons-transition-group";

class Text extends React.Component {
  render() {
    return (
      <div style={{ ...this.props.style }}>
        <div className={`${this.props.text ? "open" : ""}`} style={{  overflow: "hidden",
			  transition: "height 8s",
			  padding: "0.2rem",
			  opacity: "0.7"}}>
          {this.props.text ? this.props.children : ""}
          {this.props.text
            ? this.props.render && this.props.render(this.props.text)
            : ""}
        </div>
      </div>
    );
  }
}

export default Text;