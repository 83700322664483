import React from 'react';

function LangTable(props) {

    const renderLangRows = () => {
        let lang = props.lang;
        let langFile = props.langFile;
        return lang.map((langData, index, arrayRef) => {
			if(arrayRef.length === 1)
			     return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}> Language Level: </span>
                            <a
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`/outgoing-student/export/${langData}`}
                                >
                                    { langFile[0]}
                            </a>
				</li>
				)
				return(
				<li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}> Language Level {index + 1}: </span>
								<a
									rel="noopener noreferrer" 
									target="_blank" 
									href={`/outgoing-student/export/${langData}`}
                                    >
										{ langFile[index]}
								</a>
				</li>
				)
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.lang !== undefined){
        return(
		<div>
                {renderLangRows()}
				</div>
        )
    }
}

export default LangTable;