import React, {Component} from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

class ResestPassword extends Component {
constructor(props) {
		super(props);
		this.state = {
			password: "",
			error: false,
			title: "",
			requestCompleted: false
		}
	}
	changeValue(event, nameState){
		this.setState({
			[nameState]: event.target.value
		})
	}

	passwordReset(event){
		event.preventDefault();
		if(this.validate()){
			this.props.mutate({
				variables: {
					id: this.props.match.params.ID,
					password: this.state.password
				}
			}).then((response) => {
				if(response.data.resetPasswordInstitutionOrAccount){
					this.setState({requestCompleted: true})
				}
				else{
					this.setState({error: true, title: "Account not found"})
				}
			})
		}
	}

	validate(){
		if(this.state.password === ""){
			this.setState({error: true, title: "The password can't be empty"})
			return false;
		}
		if(this.state.password.length < 8){
			this.setState({error: true, title: "The password needs at least 8 characters"})
			return false;
		}
		this.setState({error: false, title: "Valid input..."});
		return true;
	}

	getTitle(){
		if(this.state.error){
			return <h2 className="error">{this.state.title}</h2>;
		}
		else if(this.state.title !== ""){
			return <h2>{this.state.title}</h2>;
		}
		return <h2>Reset password</h2>;
	}

	render(){
		if(this.state.requestCompleted){
			return(
				<div className="intro">
		            <div className="intro_content">
		            	<div className="account_login card">
							<h2>Password reset successful</h2>
							<div className="inside_content">
								<p>Your password has successfully been changed. You can <Link to={GlobalVar.generateLinkUrl(intl, "Account", "Login")}>login</Link>.</p>
							</div>
						</div>
					</div>
		        </div>

			)
		}
		return(
			<div className="intro">
	            <div className="intro_content">
	            	<div className="account_login card">
						{this.getTitle()}
						<form onSubmit={(event) => this.passwordReset(event)} >
							<input autoFocus value={this.state.password} onChange={(event) => this.changeValue(event, "password")} type="password" placeholder="New password" />
							<button>Reset password</button>
						</form>
					</div>
				</div>
	        </div>

		)
	}

}

const mutation = gql`
	mutation ResetPasswordInstitutionOrAccount($id: ID, $password: String){
	  resetPasswordInstitutionOrAccount(id: $id, password: $password){
	    Email
	  }
	}
`;
export default graphql(mutation)(ResestPassword);
