import React, { Component } from 'react';
import GlobalVar from '../../../GlobalVar';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

class UploadSucces extends Component {
	render(){
		return(
			<div className="content upload">
	    		<div className="card scrollbar card_full_width card_full_height">
	    			<h2>The students have succesfully been uploaded</h2>
	    			<div className="inside_content">
		    			<p>It can take a minute or more before the <Link to={GlobalVar.generateLinkUrl(intl, "Outgoing students", "Student List")}>student list</Link> in the Dashboard is updated.
							<br/>Tip: if not all students are displaying in the list just reload the page.
							</p>
            	<Link to={GlobalVar.generateLinkUrl(intl, "Outgoing students", "Upload")}><button>New upload</button></Link>
          	</div>
	    		</div>
	    	</div>
		)
	}
}

export default UploadSucces;
