import React, { useState } from 'react';


function StatusFilter(props) {
	
    const [value, setValue] = useState("");

	const changeSelection = (event) => {
		setValue(event.target.value);
        props.onSelectedStatus(event.target.value)
    }

	const renderSignedBy = (printStatus, label) => {
        let doesExist = props.data.find((Status) => {
            return Status.field_la_status === label
        });

		if(doesExist){
			return <option value={label}>{printStatus}</option>;
		}

		return <option value={label} disabled>{printStatus}</option>;
    }

    if(props.data.loading){
        return <select><option value="">Loading...</option></select>
    }
    else if(props.typeOfStudent === 2 ){
        return (
            <select id="status-filter" value={value} onChange={(event) => changeSelection(event)} >
                <option value="">Select status</option>
                {renderSignedBy("Changes unsigned", "final_unsigned")}
                {renderSignedBy("Changes signed by student", "final_signed-by-student")}
                {renderSignedBy("Changes signed by student/sending", "final_signed-by-student-sending")}
                {renderSignedBy("Changes signed by student/sending/receiving", "final_signed-by-student-sending-receiving")}
                {renderSignedBy("Signed by student/sending", "preliminary_signed-by-student-sending")}
                {renderSignedBy("Signed by student", "preliminary_signed-by-student")}
                {renderSignedBy("Signed by student/sending/receiving", "preliminary_signed-by-student-sending-receiving")}
            </select>
        );
    } else {
        return (
            <select id="status-filter" value={value} onChange={(event) => changeSelection(event)} >
                <option value="">Select status</option>
                {renderSignedBy("Unsigned", "preliminary_unsigned")}
                {renderSignedBy("Changes unsigned", "final_unsigned")}
                {renderSignedBy("Changes signed by student", "final_signed-by-student")}
                {renderSignedBy("Changes signed by student/sending", "final_signed-by-student-sending")}
                {renderSignedBy("Changes signed by student/sending/receiving", "final_signed-by-student-sending-receiving")}
                {renderSignedBy("Signed by student/sending", "preliminary_signed-by-student-sending")}
                {renderSignedBy("Signed by student", "preliminary_signed-by-student")}
                {renderSignedBy("Signed by student/sending/receiving", "preliminary_signed-by-student-sending-receiving")}
            </select>
        );
    }

};

export default StatusFilter;
