import React, { Component } from 'react';
import { query as institutionInfo } from '../../queries/getInstitution';
import { query as isOLAManagedByEWP } from '../../queries/isOLAManagedByEWP';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import OlaData from './Data';
import GlobalVar from '../../GlobalVar';
import NoAccess from "./NoAccess";

class OlaList extends Component {

    constructor(props) {
        super(props)
        this.typeOfStudent = -1;
        this.typeOfStudentName = "";
        this.dashboardUserEmail = GlobalVar.decryptWithKey(localStorage.getItem("Email"));

        this.setStudentsType(props)
    }

    setStudentsType(props){
        const url = props.location.pathname;
        let outgoingStudent = intl.get("Outgoing students");
        outgoingStudent = outgoingStudent.replace(/\s/g, "-");
        outgoingStudent = outgoingStudent.toLowerCase();

        if(url.includes(outgoingStudent)) {
            this.typeOfStudent = 1;
            this.typeOfStudentName = "Outgoing";
        } else {
            let incomingStudent = intl.get("Incoming students");
            incomingStudent = incomingStudent.replace(/\s/g, "-");
            incomingStudent = incomingStudent.toLowerCase();
            if(url.includes(incomingStudent)){
                this.typeOfStudent = 2;
                this.typeOfStudentName = "Incoming";
            }
        }
    }

    render() {

        if (this.props.isOLAManagedByEWP.loading||this.props.institutionInfo.loading) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg"/>Loading...
                        </p>
                    </div>
                </div>
            );
        } else {
            if (this.props.isOLAManagedByEWP.isOLAManagedByEWP.Success == 2) {
                return (
                    <OlaData
                        typeOfStudent={this.typeOfStudent}
                        typeOfStudentName={this.typeOfStudentName}
                        institutionInfo={this.props.institutionInfo}
                    />
                )
            }
            else {
                return (
                    <NoAccess/>
                );
            }
        }

    }
}

export default compose(
    graphql(institutionInfo, {name: "institutionInfo"}),
    graphql(isOLAManagedByEWP, {
        name: "isOLAManagedByEWP",
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }
    })
)(OlaList);
