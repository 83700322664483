/**
 * Class with regular expressions.
 */
class RegExprs {
    constructor() {
        /**
         * Regular expression to validate dates in format dd/mm.
         */
        this.dateDDMM = /^(((0?[0-9]|[1-2][0-9]|3[0-1])\/(0?[13578]|1(0|2))|((0?[0-9]|[1-2][0-9]|30)\/(0?[469]|11))|((0?[0-9]|[1-2][0-9])\/0?2)))$/;
        
        /**
         *  Regular expression to validate emails.
         */
        this.email = /^[^@]+@[^\.@]+\..+$/;

        /**
         * Regular expression to validate only URLs with HTTPS or HTTP protocols.
         */
        this.websiteHTTPS = /^https?:\/\/.+$/;

        /**
         * Regular expression to validate phone numbers.
         */
        this.phone = /^\+[0-9]{1,15}$/;
    }
}

export default (new RegExprs);