import React, { Component } from 'react';
import { Button as ReactButton, Collapse } from 'reactstrap';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import axios from 'axios';
import querystring from 'querystring';
import gql from 'graphql-tag'
import intl from 'react-intl-universal';

import GlobalVar from '../../../../GlobalVar';
import Button from '../../common/Button';
import Label from '../../common/Label';

import { query as getIIA } from '../../../../queries/getIIA';
import '../../../../style/css/customIIA.css';
import NoAccess from "../NoAccess";

class ShowInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reloadActions: false,
            creatorDefaultOpen: false,
            partnerDefaultOpen: false,
            conditionsDefaultOpen: false,
            isCreatorInEWP: -10,
            isPartnerInEWP: -10,
            creatorOUlist: [],
            partnerOUlist: [],
            partnerStaticData: null,
            checkEWP: false,
            tcCheckboxView: false,
            errorMessage: "",
            fetchingPartnerData:true
        }

        this.handleButtonsClick = this.handleButtonsClick.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.toogleCreator = this.toogleCreator.bind(this);
        this.tooglePartner = this.tooglePartner.bind(this);
        this.toogleConditions = this.toogleConditions.bind(this);
        this.termsAndConditionsHandler = this.termsAndConditionsHandler.bind(this);
    }

    render() {
        if (this.props.data.loading || this.props.DefaultDataCreator.loading || this.props.DefaultDataPartner.loading || this.props.data.getIIA.loading || this.props.getIscedFCodesList.loading||this.props.isIIAManagedByEWPQuery.loading) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <p><img className="spinner" src="/icons/spinner.svg"/>Loading Inter-Institutional Agreements...
                        </p>
                    </div>
                </div>
            );
        }
        if (this.props.isIIAManagedByEWPQuery.isIIAManagedByEWP.Success !== 2) {
            return (
               <></>
            );
        } else {
            if (!this.props.data.getIIA) {
                return (
                    <div>
                        <h3 style={{color: "red"}}>An error occurred while loading the information regarding this
                            agreement. Please try later.</h3>
                        <p>If the problem persists, please contact the administrator.</p>
                    </div>
                );
            }
            return (
                <div>
                    <div onClick={() => this.toPreviousPage(true)} className="black_background"/>
                    <div className="content popup student_popup">
                        {this.showTitle()}
                        {this.showData()}
                    </div>
                </div>
            );
        }
    }

    toPreviousPage() {
        this.props.history.push(GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List"));
    }

    showTitle() {
        return (
            <div className="header" style={{ whiteSpace: 'nowrap', fontSize: '16pt', marginLeft: '10px' }}>
                <h2>Details of {this.props.location.state.data.iia_code_p1} Inter-Institutional Agreement</h2>
                <form onSubmit={() => this.toPreviousPage(false)} className="close_button_wrapper">
                    <button>
                        <img src="/icons/remove.svg" />
                    </button>
                </form>
            </div>
        );
    }

    showData() {
        if (!this.state.checkEWP) {
            this.checkIfPartnersIIAisManagedByEWP();

            this.setState({ checkEWP: true });
        }
        else {
            const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
            const iiaData = this.props.data.getIIA;
            let switchCreatorPartner = false;
            if(iiaData.typeOfCommunication === "EWP" && iiaData.iia_id_p1.startsWith("EWP-")) {
                switchCreatorPartner = true;
            }
            const defaultDataCreator = this.props.DefaultDataCreator.getIIADefaultData?this.props.DefaultDataCreator.getIIADefaultData:null; //check if creator default data exist or undefined
            const defaultDataPartner = this.props.DefaultDataPartner.getIIADefaultData?this.props.DefaultDataPartner.getIIADefaultData:null; //check if partner default data exist or undefined
            const creatorData = switchCreatorPartner ? defaultDataPartner : defaultDataCreator;
            if (this.state.isPartnerInEWP === 2) { this.state.partnerStaticData = switchCreatorPartner ? defaultDataCreator : defaultDataPartner; }

            let creatorDataCode = <div />
            if (!creatorData) {
                creatorDataCode = <React.Fragment>
                    <h3>No information was retrieved from the {switchCreatorPartner ? "partner" : "creator"}.</h3>
                </React.Fragment>
            }
            else {
                creatorDataCode = <React.Fragment>
                    {this.renderCalendar(creatorData)}
                    {this.renderAdditionalRequirements(creatorData)}
                    {this.renderInfrastructure(creatorData)}
                    {this.renderSupportServices(creatorData)}
                    {this.renderHousing(creatorData)}
                    {this.renderVisa(creatorData)}
                    {this.renderInsurance(creatorData)}
                    {this.renderAdditionalInformation(creatorData)}
                </React.Fragment>
            }

            let partnerDataCode = <div />
            if (this.state.partnerStaticData === null) {
                if (this.state.fetchingPartnerData){
                    partnerDataCode =
                        <React.Fragment>
                            <h3>Fetching the static data from EWP Partner. This will take just a few seconds.</h3>
                        </React.Fragment>
                }
                else if(this.state.errorMessage != "") {
                    partnerDataCode = 
                        <React.Fragment>
                            <h3>{this.state.errorMessage}</h3>
                        </React.Fragment>
                    
                }
                else {
                    partnerDataCode = 
                        <React.Fragment>
                            <h3>No information was retrieved from the {switchCreatorPartner ? "creator" : "partner"}.</h3>
                        </React.Fragment>
                    
                }
            } else if (this.state.isPartnerInEWP === 2 && this.props.DefaultDataPartner?.error?.message === "GraphQL error: static") {
                partnerDataCode = <React.Fragment>
                    <h3>The {switchCreatorPartner ? "creator" : "partner"} is not exchanging static information for inter-institutional agreements.</h3>
                </React.Fragment>
            } else {
                partnerDataCode = <React.Fragment>
                    {this.renderCalendar(this.state.partnerStaticData)}
                    {this.renderAdditionalRequirements(this.state.partnerStaticData)}
                    {this.renderInfrastructure(this.state.partnerStaticData)}
                    {this.renderSupportServices(this.state.partnerStaticData)}
                    {this.renderHousing(this.state.partnerStaticData)}
                    {this.renderVisa(this.state.partnerStaticData)}
                    {this.renderInsurance(this.state.partnerStaticData)}
                    {this.renderAdditionalInformation(this.state.partnerStaticData)}
                </React.Fragment>
            }

            return (
                <div className="popup_scroller">
                    {this.showInfoTitle()}
                    {switchCreatorPartner ? this.showPartnerData(iiaData) : this.showCreatorData(iiaData)}
                    {switchCreatorPartner ? this.showCreatorData(iiaData) : this.showPartnerData(iiaData)}
                    {this.showOtherData(iiaData)}
                    <div className="sub_content">
                        <ReactButton onClick={this.toogleCreator} style={subContentStyle}>Hide/Show {switchCreatorPartner ? this.props.location.state.data.partner_institution_code : this.props.location.state.data.creator_institution_code} default data</ReactButton>
                    </div>
                    <div className="sub_content">
                        <Collapse isOpen={this.state.creatorDefaultOpen}>
                            {creatorDataCode}
                        </Collapse>
                    </div>
                    <div className="sub_content">
                        <ReactButton onClick={this.tooglePartner} style={subContentStyle}>Hide/Show {switchCreatorPartner ? this.props.location.state.data.creator_institution_code : this.props.location.state.data.partner_institution_code} default data</ReactButton>
                    </div>
                    <div className="sub_content">
                        <Collapse isOpen={this.state.partnerDefaultOpen}>
                            {partnerDataCode}
                        </Collapse>
                    </div>
                    <hr />
                    <div className="sub_content">
                        <Label text="Terms of the agreement to be set for each agreement and approved by the institutions (Information only accessible to the relevant parties)." />
                        <h3>Mobility numbers per academic year</h3>
                        <p>&nbsp;</p>
                        <p style={{ fontWeight: "bold" }}>The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year.</p>
                    </div>
                    <div className="sub_content">
                        <ReactButton onClick={this.toogleConditions} style={subContentStyle}>Hide/Show Cooperations Conditions</ReactButton>
                    </div>
                    <div className="sub_content" style={{ width: "98%" }}>
                        <Collapse isOpen={this.state.conditionsDefaultOpen}>
                            {this.showConditionsData(iiaData)}
                        </Collapse>
                    </div>
                    {this.renderFinalInformation()}
                    {this.showButtons(iiaData)}
                </div>
            );
        }
    }

    showInfoTitle() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        const iiaData = this.props.data.getIIA;

        return (
            <div className="sub_content" style={subContentStyle}>
                {(iiaData.typeOfCommunication==="EWP"&&(iiaData.iia_id_p2===null||iiaData.iia_id_p2==="")&&!iiaData.iia_id_p1.startsWith("EWP-"))&&<h4 style={{color:"red"}}>The partner has not shared an ID for this IIA, therefore no actions are available at this point. Please notify your partner to respectively contact their provider.</h4>}
                <div className="sub_content" style={subContentStyle}>
                    <div>
                        <h2 style={{ textAlign: "center" }}>Key Action 1 - Mobility of learners and staff - Higher Education Student and Staff Mobility between Programme Countries</h2>
                        <h2 style={{ textAlign: "center" }}>Requirements for Inter-Institutional Agreements 2021-20[29]</h2>
                        <p>&nbsp;</p>
                    </div>

                    <div>
                        <h1>Static information applicable to all Inter-Institutional Agreements</h1>
                        <p>&nbsp;</p>
                        <p>*The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus programme. They commit to respect the quality requirements of the <a href="https://ec.europa.eu/programmes/erasmus-plus/resources/documents/applicants/higher-education-charter_en" target="_blank">Erasmus Charter for Higher Education</a> in all aspects related to the organisation and management of the mobility, in particular the <a href="https://ec.europa.eu/education/education-in-the-eu/proposal-for-a-council-recommendation-on-the-automatic-mutual-recognition-of-diplomas-and-learning-periods-abroad_en" target="_blank">automatic recognition</a> of the credits awarded to students by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript of Records, or according to the learning outcomes of the modules completed abroad, as described in the Course Catalogue, in line with the <a href="https://ec.europa.eu/education/resources-and-tools/european-credit-transfer-and-accumulation-system-ects_en" target="_blank">European Credit and Accumulation System</a>. The institutions agree on exchanging their mobility related data in line with the technical standards of <a href="https://ec.europa.eu/education/education-in-the-eu/european-student-card-initiative_en" target="_blank">the European Student Card Initiative</a>.</p>
                        <p>&nbsp;</p>
                    </div>
                    <div>
                        <p>&nbsp;</p>
                        <h3>Grading systems of the institutions</h3>
                        <p>It is recommended that receiving institutions provide a link to the statistical distribution of grades or makes the information available through <a href="https://egracons.eu/" target="_blank">EGRACONS</a> according to the descriptions in the <a href="https://ec.europa.eu/education/ects/users-guide/docs/ects-users-guide_en.pdf" target="_blank">ECTS users’ guide</a>. The information will facilitate the interpretation of each grade awarded to students and will facilitate the credit transfer by the sending institution.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="sub_content" style={subContentStyle}>
                    <h1>General information entered into the higher education institutions’ profile and updated by the higher education institution. The general institutional information about the institution is accessible to students.</h1>
                </div>
            </div>
        );
    }

    showCreatorData(iiaData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        let switchCreatorPartner = false;
        if(iiaData.typeOfCommunication === "EWP" && iiaData.iia_id_p1.startsWith("EWP-")) {
            switchCreatorPartner = true;
        }
        if (this.state.isCreatorInEWP === 1) {
            let ounitName = "";
            if (iiaData.creatorOU != null && this.state.creatorOUlist.length > 0) {
                ounitName = this.state.creatorOUlist.map((ounit) => {
                    if (ounit.ounit_id === iiaData.creatorOU.ounit_id) {
                        return ounit.name;
                    }
                });
            }
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>{switchCreatorPartner ? "Partner" : "Creator"} data</h3>
                    <div className="row header_row">
                        {this.showCreatorInEWP()}
                    </div>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Institution Name", (iiaData.creator_institution_data.name != null) ? iiaData.creator_institution_data.name : "")}
                        {this.generateLabelValueRow("Erasmus Code", (iiaData.creator_institution_data.erasmusCode != null) ? iiaData.creator_institution_data.erasmusCode : "")}
                        {this.generateLabelValueRow("Country", (iiaData.creator_institution_data.country != null) ? iiaData.creator_institution_data.country : "")}
                        {this.generateLabelValueRow("City", (iiaData.creator_institution_data.city != null) ? iiaData.creator_institution_data.city : "")}
                        {this.generateLabelValueRow("OUnit", ounitName)}
                    </ul>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Name", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].name : "")}
                        {this.generateLabelValueRow("Contact Email", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].email : "")}
                        {this.generateLabelValueRow("Contact Phone", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].phone : "")}
                        {this.generateLabelValueRow("General Website", (iiaData.creatorGeneral != null) ? iiaData.creatorGeneral : "")}
                        {this.generateLabelValueRow("Faculty Website", (iiaData.creatorFaculty != null) ? iiaData.creatorFaculty : "")}
                        {this.generateLabelValueRow("Course Website", (iiaData.creatorCourse != null) ? iiaData.creatorCourse : "")}
                    </ul>
                </div>
            );
        }
        else {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>{switchCreatorPartner ? "Partner" : "Creator"} data</h3>
                    <div className="row header_row">
                        {this.showCreatorInEWP()}
                    </div>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Institution Name", (iiaData.creator_institution_data.name != null) ? iiaData.creator_institution_data.name : "")}
                        {this.generateLabelValueRow("Erasmus Code", (iiaData.creator_institution_data.erasmusCode != null) ? iiaData.creator_institution_data.erasmusCode : "")}
                        {this.generateLabelValueRow("Country", (iiaData.creator_institution_data.country != null) ? iiaData.creator_institution_data.country : "")}
                        {this.generateLabelValueRow("City", (iiaData.creator_institution_data.city != null) ? iiaData.creator_institution_data.city : "")}
                        {this.generateLabelValueRow("OUnit", (iiaData.creatorOU != null) ? iiaData.creatorOU.name : "")}
                    </ul>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Name", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].name : "")}
                        {this.generateLabelValueRow("Contact Email", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].email : "")}
                        {this.generateLabelValueRow("Contact Phone", (iiaData.creator_institution_data.contacts[0] !== null) ? iiaData.creator_institution_data.contacts[0].phone : "")}
                        {this.generateLabelValueRow("General Website", (iiaData.creatorGeneral != null) ? iiaData.creatorGeneral : "")}
                        {this.generateLabelValueRow("Faculty Website", (iiaData.creatorFaculty != null) ? iiaData.creatorFaculty : "")}
                        {this.generateLabelValueRow("Course Website", (iiaData.creatorCourse != null) ? iiaData.creatorCourse : "")}
                    </ul>
                </div>
            );
        }
    }

    showCreatorInEWP() {
        if (this.state.isCreatorInEWP == -2) {
            return (
                <h3 style={{ color: "black" }}>Searching for your institution in EWP...</h3>
            );
        }
        if (this.state.isCreatorInEWP == -1) {
            return (
                <h3 style={{ color: "blue" }}>The IIAs of your institution are not managed by a node in the EWP network neither by the Dashboard so this agreement cannot be sent by the EWP network. If you want the Dashboard to be responsible for handling the IIAs through the EWP network, please check that you have enabled the Dashboard in the Settings page of the EWP menu on the left.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 0) {
            return (
                <h3 style={{ color: "orange" }}>It was not possible to get the information about your institution on the EWP Network. The IIA maybe saved in the Dashboard only. Please contact the administrator or try again later.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 1) {
            return (
                <h3 style={{ color: "red" }}>The IIAs of your institution are managed by a node in the EWP network which is not the Dashboard. You will not be able to send this agreement through the EWP Network.</h3>
            );
        }
        if (this.state.isCreatorInEWP == 2) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of your institution are managed by the Dashboard in the EWP network so you will be able to send this agreement through the EWP Network.</h3>
            );
        }
    }

    showPartnerData(iiaData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        let switchCreatorPartner = false;
        if(iiaData.typeOfCommunication === "EWP" && iiaData.iia_id_p1.startsWith("EWP-")) {
            switchCreatorPartner = true;
        }
        if (this.state.isPartnerInEWP === 1) {
            let ounitName = "";
            if (iiaData.partnerOU != null && this.state.partnerOUlist.length > 0) {
                ounitName = this.state.partnerOUlist.map((ounit) => {
                    if (ounit.ounit_id === iiaData.partnerOU.ounit_id) {
                        return ounit.name;
                    }
                });
            }
            console.log("ounitName: " + ounitName);
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>{switchCreatorPartner ? "Creator" : "Partner"} data</h3>
                    <div className="row header_row">
                        {this.showPartnerInEWP()}
                    </div>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Institution Name", (iiaData.partner_institution_data.name != null) ? iiaData.partner_institution_data.name : "")}
                        {this.generateLabelValueRow("Erasmus Code", (iiaData.partner_institution_data.erasmusCode != null) ? iiaData.partner_institution_data.erasmusCode : "")}
                        {this.generateLabelValueRow("Country", (iiaData.partner_institution_data.country != null) ? iiaData.partner_institution_data.country : "")}
                        {this.generateLabelValueRow("City", (iiaData.partner_institution_data.city != null) ? iiaData.partner_institution_data.city : "")}
                        {this.generateLabelValueRow("OUnit", ounitName)}
                    </ul>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Name", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].name : "")}
                        {this.generateLabelValueRow("Contact Email", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].email : "")}
                        {this.generateLabelValueRow("Contact Phone", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].phone : "")}
                        {this.generateLabelValueRow("General Website", (iiaData.partnerGeneral != null) ? iiaData.partnerGeneral : "")}
                        {this.generateLabelValueRow("Faculty Website", (iiaData.partnerFaculty != null) ? iiaData.partnerFaculty : "")}
                        {this.generateLabelValueRow("Course Website", (iiaData.partnerCourse != null) ? iiaData.partnerCourse : "")}
                    </ul>
                </div>
            );
        }
        else {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>{switchCreatorPartner ? "Creator" : "Partner"} data</h3>
                    <div className="row header_row">
                        {this.showPartnerInEWP()}
                    </div>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Institution Name", (iiaData.partner_institution_data.name != null) ? iiaData.partner_institution_data.name : "")}
                        {this.generateLabelValueRow("Erasmus Code", (iiaData.partner_institution_data.erasmusCode != null) ? iiaData.partner_institution_data.erasmusCode : "")}
                        {this.generateLabelValueRow("Country", (iiaData.partner_institution_data.country != null) ? iiaData.partner_institution_data.country : "")}
                        {this.generateLabelValueRow("City", (iiaData.partner_institution_data.city != null) ? iiaData.partner_institution_data.city : "")}
                        {this.generateLabelValueRow("OUnit", (iiaData.partnerOU != null) ? iiaData.partnerOU.name : "")}
                    </ul>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Name", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].name : "")}
                        {this.generateLabelValueRow("Contact Email", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].email : "")}
                        {this.generateLabelValueRow("Contact Phone", (iiaData.partner_institution_data.contacts[0] !== null) ? iiaData.partner_institution_data.contacts[0].phone : "")}
                        {this.generateLabelValueRow("General Website", (iiaData.partnerGeneral != null) ? iiaData.partnerGeneral : "")}
                        {this.generateLabelValueRow("Faculty Website", (iiaData.partnerFaculty != null) ? iiaData.partnerFaculty : "")}
                        {this.generateLabelValueRow("Course Website", (iiaData.partnerCourse != null) ? iiaData.partnerCourse : "")}
                    </ul>
                </div>
            );
        }
    }

    showPartnerInEWP() {
        if (this.state.isPartnerInEWP == -2) {
            return (
                <h3 style={{ color: "black" }}>Searching for partner in EWP...</h3>
            );
        }
        if (this.state.isPartnerInEWP == -1) {
            return (
                <h3 style={{ color: "blue" }}>The IIAs of the partner are not managed by any node in the EWP network. This agreement will not be sent through the EWP Network.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 0) {
            return (
                <h3 style={{ color: "orange" }}>It was not possible to get the information about the partner on the EWP Network. It may not be possible to send the IIA through the EWP Network. Please contact the Administrator.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 1) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of the partner are managed by a node in the EWP network. This agreement will be sent through the EWP Network.</h3>
            );
        }
        if (this.state.isPartnerInEWP == 2) {
            return (
                <h3 style={{ color: "green" }}>The IIAs of the partner are managed by the Dashboard in the EWP network. This agreement will not be sent through the EWP Network and treated internally in the Dashboard.</h3>
            );
        }
    }

    showOtherData(iiaData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <div className="sub_content" style={subContentStyle}>
                <h3>Other data</h3>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Academic Year (start)", iiaData.academicYearStart)}
                </ul>
                <ul className="regular_list">
                    {this.generateLabelValueRow("Academic Year (end)", iiaData.academicYearEnd)}
                </ul>
            </div>
        );
    }

    showConditionsData(iiaData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };
        let creatorECode = iiaData.creator_institution_data.erasmusCode;

        if (iiaData.conditions.length === 0) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>No conditions</h3>
                </div>
            );
        }

        return (
            iiaData.conditions.map((data, index) => {
                let iscedFCodesCounter = 0;
                data.iscedFCodes.map((iscedFCode) => { if (iscedFCode.iscedFCode !== "") { iscedFCodesCounter++; } });

                let iscedFCodesTitle = (iscedFCodesCounter === 0) ? <h2>No subject area selected</h2> : <h2>Subject area</h2>;
                let languagesCounter = 0;
                data.languages.map((language) => { if (language.language !== "") { languagesCounter++; } });

                let languagesTitle = (languagesCounter === 0) ? <h2>No recommended language skills</h2>
                    : (<React.Fragment><h2>Recommended language skills</h2><p>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p></React.Fragment>);

                let sendingOUName = (!data.sendingOUnit.length) ? "" : data.sendingOUnit[0].name;
                let receivingOUName = (!data.receivingOUnit.length) ? "" : data.receivingOUnit[0].name;
                if (data.sendingOUnit.length > 0) {
                    if (creatorECode === data.senderInstitution) {
                        this.state.creatorOUlist.map((ounit) => {
                            if (ounit.ounit_id === data.sendingOUnit[0].ounit_id && sendingOUName === ounit.ounit_id) {
                                sendingOUName = ounit.name;
                                return;
                            }
                        });
                    }
                    this.state.partnerOUlist.map((ounit) => {
                        if (ounit.ounit_id === data.sendingOUnit[0].ounit_id && sendingOUName === ounit.ounit_id) {
                            sendingOUName = ounit.name;
                            return;
                        }
                    });
                }
                if (data.receivingOUnit.length > 0) {
                    if (creatorECode === data.receiverInstitution) {
                        this.state.creatorOUlist.map((ounit) => {
                            if (ounit.ounit_id === data.receivingOUnit[0].ounit_id && receivingOUName === ounit.ounit_id) {
                                receivingOUName = ounit.name;
                                return;
                            }
                        });
                    }
                    this.state.partnerOUlist.map((ounit) => {
                        if (ounit.ounit_id === data.receivingOUnit[0].ounit_id && receivingOUName === ounit.ounit_id) {
                            receivingOUName = ounit.name;
                            return;
                        }
                    });
                }

                return (
                    <React.Fragment>
                        <div key={iiaData.iia_code_p1 + "_partOne_" + index} className="sub_content" style={subContentStyle}>
                            <h3>Condition {index + 1}/{iiaData.conditions.length}</h3>
                            <ul className="regular_list">
                                {this.generateLabelValueRow("Sender Erasmus Code", data.senderInstitution)}
                                {this.generateLabelValueRow("Receiver Erasmus Code", data.receiverInstitution)}
                                {this.generateLabelValueRow("Sending Organizational Unit", sendingOUName)}
                                {this.generateLabelValueRow("Receiving Organizational Unit", receivingOUName)}
                                {this.generateLabelValueRow("Mobilities per Year", data.mobilitiesPerYear)}
                                {this.generateLabelValueRow("Mobility Type", data.mobilityType)}
                                {this.generateLabelValueRow("Total Months Per Year Per Mobility", data.totalMonths)}
                                {this.generateLabelValueRow("Total Days Per Year Per Mobility", data.totalDays)}
                                {this.generateLabelMultiValueRow("EQF Levels", data.eqfLevels.sort(this.compare), "eqfLevel")}
                                {
                                    data.mobilityType.includes("Student") ? (
                                        <ul className="regular_list" style={{ width: "95%" }}>
                                            {this.generateLabelValueRow("Blended mobility", (data.blended === 1) ? "Yes" : "No")}
                                        </ul>
                                    ) : <div />
                                }
                            </ul>
                            <ul className="regular_list">
                                {iscedFCodesTitle}
                                {this.generateObjectRows("Subject Area", data.iscedFCodes)}
                            </ul>
                        </div>
                        <div key={iiaData.iia_code_p1 + "_partTwo_" + index} className="sub_content" style={subContentStyle}>
                            <ul className="regular_list" style={{ width: "95%" }}>
                                {languagesTitle}
                                {this.generateObjectRows("Recommended language skills", data.languages)}
                            </ul>
                        </div>
                        <div key={iiaData.iia_code_p1 + "_partThree_" + index} className="sub_content" style={subContentStyle}>
                            <ul className="regular_list" style={{ width: "95%" }}>
                                {this.generateLabelValueRow("Other Information", data.otherInformation)}
                            </ul>
                        </div>
                    </React.Fragment>
                );
            })
        );
    }

    renderCalendar(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.stuNomAutumnTerm === "" && data.stuNomSpringTerm === "" && data.appNomAutumnTerm === "" && data.appNomSpringTerm === "" &&
            data.appProcedureEmail === "" && data.appProcedurePhone === "" && data.appProcedureWebsite === "") {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <h3>No Calendar Information</h3>
                </div>
            );
        }

        let nomCode =
            <ul className="regular_list" style={{ width: "100%" }}>
                <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No student nominations information</p>
            </ul>
            ;
        if (data.stuNomAutumnTerm !== "" || data.stuNomSpringTerm !== "") {
            let autumnDate = data.stuNomAutumnTerm;
            let springDate = data.stuNomSpringTerm;

            if (data.stuNomAutumnTerm.includes('-')) { autumnDate = data.stuNomAutumnTerm.split('-')[2] + "/" + data.stuNomAutumnTerm.split('-')[1] + "/" + data.stuNomAutumnTerm.split('-')[0]; }
            if (data.stuNomSpringTerm.includes('-')) { springDate = data.stuNomSpringTerm.split('-')[2] + "/" + data.stuNomSpringTerm.split('-')[1] + "/" + data.stuNomSpringTerm.split('-')[0]; }

            nomCode =
                <React.Fragment>
                    <ul className="regular_list" style={{ width: "100%" }}>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Student nominations must reach the receiving institution by</p>
                        {this.generateLabelValueRow("Autumn Term", autumnDate)}
                        {this.generateLabelValueRow("Spring Term", springDate)}
                    </ul>
                </React.Fragment>
                ;
        }

        let appCode =
            <ul className="regular_list" style={{ width: "100%" }}>
                <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No applications from nominated students information</p>
            </ul>
            ;
        if (data.appNomAutumnTerm !== "" || data.appNomSpringTerm !== "") {
            let autumnDate = data.appNomAutumnTerm;
            let springDate = data.appNomSpringTerm;

            if (data.appNomAutumnTerm.includes('-')) { autumnDate = data.appNomAutumnTerm.split('-')[2] + "/" + data.appNomAutumnTerm.split('-')[1] + "/" + data.appNomAutumnTerm.split('-')[0]; }
            if (data.appNomSpringTerm.includes('-')) { springDate = data.appNomSpringTerm.split('-')[2] + "/" + data.appNomSpringTerm.split('-')[1] + "/" + data.appNomSpringTerm.split('-')[0]; }

            appCode =
                <React.Fragment>
                    <ul className="regular_list" style={{ width: "100%" }}>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Applications from nominated students must reach the receiving institution by:</p>
                        {this.generateLabelValueRow("Autumn Term", autumnDate)}
                        {this.generateLabelValueRow("Spring Term", springDate)}
                    </ul>
                </React.Fragment>
                ;
        }

        let procCode =
            <ul className="regular_list" style={{ width: "100%" }}>
                <p style={{ marginBottom: "3px", fontWeight: "bold" }}>No application procedure for nominated students information</p>
            </ul>
            ;
        if (data.appProcedureEmail !== "" || data.appProcedurePhone !== "" || data.appProcedureWebsite !== "") {
            procCode =
                <React.Fragment>
                    <ul className="regular_list" style={{ width: "100%" }}>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>Application procedure for nominated students</p>
                        {this.generateLabelValueRow("Contact Email", data.appProcedureEmail)}
                        {this.generateLabelValueRow("Contact Phone", data.appProcedurePhone)}
                        {this.generateLabelValueRow("Website for information", data.appProcedureWebsite)}
                    </ul>
                </React.Fragment>
                ;
        }

        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Calendar" />
                    {nomCode}
                    {appCode}
                    {procCode}

                </div>
            </React.Fragment>
        );
    }

    renderAdditionalRequirements(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.additionalRequirements.length === 0) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <ul className="regular_list">
                        <Label text="No Additional Requirements" />
                    </ul>
                    <p style={{ marginTop: "20px", fontWeight: "bold" }}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Additional Requirements" />
                    {this.generateTableRow(data.additionalRequirements, "Additional Requirement")}
                    <p style={{ marginTop: "20px", fontWeight: "bold" }}>The institution will send its decision within {data.addReqWeeks} weeks, and no later than 5 weeks.</p>
                </div>
            </React.Fragment>
        );
    }

    renderInfrastructure(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.inclusionInfrastructure.length === 0 && data.inclusionService.length === 0) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No Inclusion and Accessibility Information" />
                </div>
            );
        }
        if (data.inclusionInfrastructure.length === 0) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Inclusion and Accessibility" />
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    <Label text="No Infrastructure Information" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Inclusion and Accessibility" />
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    {this.generateTableRow(data.inclusionInfrastructure, "Infrastructure")}
                </div>
            </React.Fragment>
        );
    }

    renderSupportServices(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.inclusionService.length === 0) {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No Support Services Information" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    {this.generateTableRow(data.inclusionService, "Service")}
                </div>
            </React.Fragment>
        );
    }

    renderHousing(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.housing_email === "" && data.housing_phone === "" && data.housing_website === "") {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No Housing Information" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Housing" />
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Email", data.housing_email)}
                        {this.generateLabelValueRow("Contact Phone", data.housing_phone)}
                        {this.generateLabelValueRow("Website for information", data.housing_website)}
                    </ul>

                </div>
            </React.Fragment>
        );
    }

    renderVisa(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.visa_email === "" && data.visa_phone === "" && data.visa_website === "") {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No Visa Information" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Visa" />
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:</p>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Email", data.visa_email)}
                        {this.generateLabelValueRow("Contact Phone", data.visa_phone)}
                        {this.generateLabelValueRow("Website for information", data.visa_website)}
                    </ul>

                </div>
            </React.Fragment>
        );
    }

    renderInsurance(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.insurance_email === "" && data.insurance_phone === "" && data.insurance_website === "") {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No Insurance Information" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Insurance" />
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:</p>
                    <ul className="regular_list">
                        {this.generateLabelValueRow("Contact Email", data.insurance_email)}
                        {this.generateLabelValueRow("Contact Phone", data.insurance_phone)}
                        {this.generateLabelValueRow("Website for information", data.insurance_website)}
                    </ul>

                </div>
            </React.Fragment>
        );
    }

    renderAdditionalInformation(data) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (data.additionalInformation.length === 0) {
            return (
                <React.Fragment>
                    <div className="sub_content" style={subContentStyle}>
                        <Label text="No Additional Information" />
                    </div>
                    <hr />
                    <div className="sub_content" style={subContentStyle}>
                        <p style={{ marginTop: "10px", marginBottom: "3px", fontWeight: "bold" }}>A Transcript of Records will be issued by the institution no later than {data.ToRWeeks} weeks after the assessment period has finished.</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold" }}>[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]</p>
                        <p>&nbsp;</p>
                        <p style={{ marginBottom: "3px", fontWeight: "bold", fontStyle: "italic" }}>*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</p>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Additional Information" />
                    {this.generateTableRow(data.additionalInformation, "Additional Information")}
                </div>
                <hr />
                <div className="sub_content" style={subContentStyle}>
                    <p style={{ marginTop: "10px", marginBottom: "3px", fontWeight: "bold" }}>A Transcript of Records will be issued by the institution no later than {data.ToRWeeks} weeks after the assessment period has finished.</p>
                    <p style={{ marginBottom: "3px", fontWeight: "bold" }}>[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]</p>
                    <p>&nbsp;</p>
                    <p style={{ marginBottom: "3px", fontWeight: "bold", fontStyle: "italic" }}>*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</p>
                </div>
            </React.Fragment>
        );
    }

    renderOtherInformation(iiaData) {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        if (iiaData.otherInformation === "") {
            return (
                <div className="sub_content" style={subContentStyle}>
                    <Label text="No more information regarding the terms of the agreement" />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row" style={subContentStyle}>
                    <Label text="Other information regarding the terms of the agreement" />
                </div>
                <div className="row" style={subContentStyle}>
                    {<p>{iiaData.otherInformation}</p>}
                </div>
            </React.Fragment>
        );
    }

    renderFinalInformation() {
        const subContentStyle = { marginLeft: "10px", marginTop: "10px" };

        return (
            <React.Fragment>
                <div className="sub_content" style={subContentStyle}>
                    <Label text="Termination of the agreement" />
                </div>
                <div className="sub_content" style={subContentStyle}>
                    <p style={{ fontStyle: "italic", display: "block", width: "98%" }}>[It is up to the involved institutions to agree on the procedure for modifying or terminating the inter-institutional agreement. However, in the event of unilateral termination, a notice of at least one academic year should be given. This means that a unilateral decision to discontinue the exchanges notified to the other party by 1 September 20XX will only take effect as of 1 September 20XX+1. The termination clauses must include the following disclaimer: "Neither the European Commission nor the National Agencies can be held responsible in case of a conflict."]</p>
                </div>
                <div className="sub_content" style={subContentStyle}>
                    <p style={{ margin: "20px", fontSize: "20px", textAlign: "center", display: "block" }}>"Inter-Institutional Agreements are digitally signed"</p>
                </div>
            </React.Fragment>
        );
    }

    showButtons(iiaData) {
        /*DEL*/console.log("iiaData: " + JSON.stringify(iiaData));
        let currentErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;

        if (iiaData.inEffect === 4) {
            return (
                <div className="sub_content" style={{ textAlign: "center" }}>
                    <h1 id="statusFinished" style={{ textSize: "20px" }}>Signed by both parties</h1>
                </div>
            );
        }
        else if (iiaData.typeOfCommunication === "Dash") {
            if (iiaData.creator_institution_code === currentErasmusCode) {
                if (iiaData.inEffect === 0 || iiaData.inEffect === 1) {
                    return (
                        <div className="sub_content" style={{ textAlign: "center" }}>
                            <h1 id="statusReqSignature" style={{ textSize: "20px" }}>Waiting for partner review</h1>
                            <Button
                                id="editEWPNot"
                                handleClick={this.handleButtonsClick}
                                refreshPage={this.refreshPage}
                                text="Send New EWP Notification"
                            />
                        </div>
                    );
                }
                else if (iiaData.inEffect === 2 || iiaData.inEffect === 3) {
                    return (
                        <div className="sub_content" style={{ textAlign: "center" }}>
                            <p>&nbsp;</p>
                            <p style={{ textAlign: "center", display: "block" }}><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxView} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                            <p>&nbsp;</p>
                            <Button
                                id="editSign"
                                handleClick={this.handleButtonsClick}
                                refreshPage={this.refreshPage}
                                text="Sign Final Agreement"
                                disabled={!this.state.tcCheckboxView}
                            />
                        </div>
                    );
                }
            }
            else if (iiaData.partner_institution_code === currentErasmusCode) {
                if (iiaData.inEffect === 2 || iiaData.inEffect === 3) {
                    return (
                        <div className="sub_content" style={{ textAlign: "center" }}>
                            <h1 id="statusReqSignature" style={{ textSize: "20px" }}>Waiting for partner review</h1>
                            <Button
                                id="editEWPNot"
                                handleClick={this.handleButtonsClick}
                                refreshPage={this.refreshPage}
                                text="Send New EWP Notification"
                            />
                        </div>
                    );
                }
                else if (iiaData.inEffect === 0 || iiaData.inEffect === 1) {
                    return (
                        <div className="sub_content" style={{ textAlign: "center" }}>
                            <p>&nbsp;</p>
                            <p style={{ textAlign: "center", display: "block" }}><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxView} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                            <p>&nbsp;</p>
                            <Button
                                id="editSign"
                                handleClick={this.handleButtonsClick}
                                refreshPage={this.refreshPage}
                                text="Sign Final Agreement"
                                disabled={!this.state.tcCheckboxView}
                            />
                        </div>
                    );
                }
            }
        }
        else if (iiaData.typeOfCommunication === "EWP" && iiaData.creator_institution_code === currentErasmusCode) {
            if (iiaData.inEffect === 0 || iiaData.inEffect === 1) {
                return (
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        <h1 id="statusReqSignature" style={{ textSize: "20px" }}>Waiting for partner review</h1>
                        <Button
                            id="editEWPNot"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Send New EWP Notification"
                        />
                    </div>
                );
            }
            else if (iiaData.inEffect === 2 || iiaData.inEffect === 3) {
                return (
                    <div className="sub_content" style={{ textAlign: "center" }}>
                        <p>&nbsp;</p>
                        <p style={{ textAlign: "center", display: "block" }}><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxView} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
                        <p>&nbsp;</p>
                        <Button
                            id="editSign"
                            handleClick={this.handleButtonsClick}
                            refreshPage={this.refreshPage}
                            text="Sign Final Agreement"
                            disabled={!this.state.tcCheckboxView}
                        />
                    </div>
                );
            }
        }



        // if (iiaData.partner_signature_date != "" && iiaData.creator_signature_date != "") {
        //     return (
        //         <div className="sub_content" style={{textAlign: "center"}}>
        //             <h1 id="statusFinished" style={{textSize: "20px"}}>Signed by both parties</h1>
        //         </div>
        //     );
        // }
        // else if((currentErasmusCode == iiaData.creator_institution_code && iiaData.creator_signature_date != "") || (currentErasmusCode == iiaData.partner_institution_code && iiaData.partner_signature_date != "")) {
        //     if((currentErasmusCode == iiaData.creator_institution_code && this.state.isCreatorInEWP == 2 && this.state.isPartnerInEWP == 1) || 
        //        (currentErasmusCode == iiaData.partner_institution_code && this.state.isCreatorInEWP == 1 && this.state.isPartnerInEWP == 2)) {
        //         return (
        //             <div className="sub_content" style={{textAlign: "center"}}>
        //                 <h1 id="statusReqSignature" style={{textSize: "20px"}}>Requested partner signature</h1>
        //                 <Button
        //                     id="editEWPNot"
        //                     handleClick={this.handleButtonsClick}
        //                     refreshPage={this.refreshPage}
        //                     text="Send New EWP Notification"
        //                 />
        //             </div>
        //         );
        //     } else {
        //         return (
        //             <div className="sub_content" style={{textAlign: "center"}}>
        //                 <h1 id="statusReqSignature" style={{textSize: "20px"}}>Requested partner signature</h1>
        //             </div>
        //         );
        //     }
        // }
        // else if((currentErasmusCode == iiaData.creator_institution_code && iiaData.partner_signature_date != "") || (currentErasmusCode == iiaData.partner_institution_code && iiaData.creator_signature_date != "")) {
        //     if((currentErasmusCode == iiaData.creator_institution_code && this.state.isCreatorInEWP == 1) || 
        //        (currentErasmusCode == iiaData.partner_institution_code && this.state.isPartnerInEWP == 1)) {
        //         return (
        //             <div className="sub_content" style={{textAlign: "center"}}>
        //                 <h1 id="statusReqSignature" style={{textSize: "20px"}}>You have a node in the EWP network.<br/>To sign this agreement you have to do it in your platform.</h1>
        //             </div>
        //         );
        //     }
        //     else if((currentErasmusCode == iiaData.creator_institution_code && this.state.isCreatorInEWP == 2) || 
        //             (currentErasmusCode == iiaData.partner_institution_code && this.state.isPartnerInEWP == 2)) {
        //         return (
        //             <div className="sub_content" style={{textAlign: "center"}}>
        //                 <p>&nbsp;</p>
        //                 <p style={{textAlign: "center", display: "block"}}><input type="checkbox" name="termsAndConditions" onClick={(event) => this.termsAndConditionsHandler(event)} checked={this.state.tcCheckboxView} /> I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard <a href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions" target="_blank">Terms &amp; Conditions and Privacy Policy</a>.</p>
        //                 <p>&nbsp;</p>
        //                 <Button
        //                     id="editSign"
        //                     handleClick={this.handleButtonsClick}
        //                     refreshPage={this.refreshPage}
        //                     text="Sign Final Agreement"
        //                     disabled={!this.state.tcCheckboxView}
        //                 />
        //             </div>
        //         );
        //     }
        //     return (
        //         <div className="sub_content" style={{textAlign: "center"}}>
        //             <h1 id="statusReqSignature" style={{textSize: "20px"}}>Your institution are not represented in EWP network.<br/>So it is not possible to you manage Inter-Institutional Agreements.</h1>
        //         </div>
        //     );
        // }
    }

    compare(a, b) {
        let comparison = 0;

        if (a.eqfLevel > b.eqfLevel) { comparison = 1; }
        else if (a.eqfLevel < b.eqfLevel) { comparison = -1; }
        return comparison;
    }


    termsAndConditionsHandler(event) {
        const { name, value } = event.target;
        const isChecked = (document.getElementsByName(name)[0].checked) ? true : false;

        this.setState({ tcCheckboxView: isChecked });
    }

    handleButtonsClick(buttonId) {
        if (buttonId === "editSign") {
            this.signIIA();
        }
        if (buttonId === "editEWPNot") {
            this.sendNewEWPNotification();
        }
    }

    toogleCreator() {
        this.setState({
            creatorDefaultOpen: !this.state.creatorDefaultOpen
        });
    }
    tooglePartner() {
        this.setState({
            partnerDefaultOpen: !this.state.partnerDefaultOpen
        });
    }
    toogleConditions() {
        this.setState({
            conditionsDefaultOpen: !this.state.conditionsDefaultOpen
        });
    }

    refreshPage() {
        this.setState({
            reloadActions: !this.state.reloadActions
        });
    }

    generateLanguagesRows(label, values) {
        if (values == null || values.length === 0) {
            return;
        }
        if (values && values.length) {
            return (
                <li>
                    <p style={{ width: "100%", display: "block", fontWeight: "bold" }}>{label}</p>
                    <p style={{ width: "100%", display: "block" }}>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                    <ul className="value">
                        {values && values.length && values.map((item, key) => {
                            return (
                                <li key={key}>
                                    <span>{this.formatValue(item.language)} - {this.formatValue(item.cefrLevel)}</span>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
        }
        return (
            <li>
                <p style={{ width: "100%", display: "block", fontWeight: "bold" }}>{label}</p>
                <p style={{ width: "100%", display: "block" }}>The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended <a href="https://europass.cedefop.europa.eu/resources/european-language-levels-cefr" target="_blank">language skills</a> at the start of the study or teaching period:</p>
                {this.formatValue("")}
            </li>
        )
    }

    generateLabelMultiValueRow(label, values, tag) {
        if (values == null || values.length === 0) {
            return;
        }
        return (
            <li>
                <span className="label">{label}</span>
                <ul className="value">
                    {values && values.length && values.map((item, key) => {
                        if (tag === "") {
                            return (
                                <li key={key}>
                                    <span>{this.formatValue(item)}</span>
                                </li>
                            );
                        }
                        return (
                            <li key={key}>
                                <span>{this.formatValue(item[tag])}</span>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    generateTableRow(data, name) {
        if (data == null || data.length === 0) {
            return;
        }
        if (name == "Additional Requirement") {
            return (
                data.map((item, key) => {
                    let addReqCode =
                        <li>
                            <span className="label">Details</span>
                            <span className="value">{item.details}</span>
                        </li>
                        ;
                    if (item.requirement === "Academic requirements") {
                        let iscedFCodes = "";
                        item.isced.split(";").map(v => {
                            this.props.getIscedFCodesList.getIscedFCodesList.map(data => {
                                if (data.code === v) {
                                    iscedFCodes = iscedFCodes.concat(data.code + " - " + data.name + "\n");
                                    return;
                                }
                            });
                        });
                        addReqCode =
                            <React.Fragment>
                                <li>
                                    <span className="label">Number of ECTS completed</span>
                                    <span className="value">{item.ects}</span>
                                </li>
                                <li>
                                    <span className="label">Subject area code - ISCED</span>
                                    <textarea rows="5" style={{ height: "auto", width: "75%" }} disabled>{iscedFCodes}</textarea>
                                </li>
                                <li>
                                    <span className="label">EQF level</span>
                                    <span className="value">{item.eqf}</span>
                                </li>
                            </React.Fragment>
                            ;
                    }
                    return (
                        <ul className="regular_list" style={{ width: "100%" }} key={key}>
                            <h4>{name} {(key + 1)}</h4>
                            <li>
                                <span className="label">Requirement</span>
                                <span className="value">{item.requirement}</span>
                            </li>
                            {addReqCode}
                            <li>
                                <span className="label">Website for information</span>
                                <span className="value">{item.website}</span>
                            </li>
                        </ul>
                    );
                })
            );
        }
        if (name == "Infrastructure") {
            return (
                data.map((item, key) => {
                    return (
                        <ul className="regular_list" style={{ width: "100%" }} key={key}>
                            <h4>{name} {(key + 1)}</h4>
                            <li>
                                <span className="label">Infrastructure name</span>
                                <span className="value">{item.name}</span>
                            </li>
                            <li>
                                <span className="label">Available infrastructure adjusted for people with</span>
                                <span className="value">{item.specialNeeds}</span>
                            </li>
                            <li>
                                <span className="label">Description of infrastructure</span>
                                <span className="value">{item.description}</span>
                            </li>
                            <li>
                                <span className="label">Contact Email</span>
                                <span className="value">{item.email}</span>
                            </li>
                            <li>
                                <span className="label">Contact Phone</span>
                                <span className="value">{item.phone}</span>
                            </li>
                            <li>
                                <span className="label">Website for information</span>
                                <span className="value">{item.website}</span>
                            </li>
                        </ul>
                    );
                })
            );
        }
        if (name == "Service") {
            return (
                data.map((item, key) => {
                    return (
                        <ul className="regular_list" style={{ width: "100%" }} key={key}>
                            <h4>{name} {(key + 1)}</h4>
                            <li>
                                <span className="label">Service name</span>
                                <span className="value">{item.name}</span>
                            </li>
                            <li>
                                <span className="label">Available infrastructure adjusted for people with</span>
                                <span className="value">{item.specialNeeds}</span>
                            </li>
                            <li>
                                <span className="label">Description of support services</span>
                                <span className="value">{item.description}</span>
                            </li>
                            <li>
                                <span className="label">Contact Email</span>
                                <span className="value">{item.email}</span>
                            </li>
                            <li>
                                <span className="label">Contact Phone</span>
                                <span className="value">{item.phone}</span>
                            </li>
                            <li>
                                <span className="label">Website for information</span>
                                <span className="value">{item.website}</span>
                            </li>
                        </ul>
                    );
                })
            );
        }
        if (name == "Additional Information") {
            return (
                data.map((item, key) => {
                    return (
                        <ul className="regular_list" style={{ width: "100%" }} key={key}>
                            <h4>{name} {(key + 1)}</h4>
                            <li>
                                <span className="label">Information</span>
                                <span className="value">{item.information}</span>
                            </li>
                            <li>
                                <span className="label">Contact Email</span>
                                <span className="value">{item.email}</span>
                            </li>
                            <li>
                                <span className="label">Contact Phone</span>
                                <span className="value">{item.phone}</span>
                            </li>
                            <li>
                                <span className="label">Website for information</span>
                                <span className="value">{item.website}</span>
                            </li>
                        </ul>
                    );
                })
            );
        }
    }

    generateLabelValueRow(label, value) {
        if (value === undefined || value === null || value === "") {
            return;
        }
        if (label === "Total Months Per Year Per Mobility" || label === "Total Days Per Year Per Mobility") {
            if (value == 0) {
                return;
            }
        }
        return (
            <li>
                <span className="label">{label}</span>
                <span className="value">{this.formatValue(value)}</span>
            </li>
        );
    }

    generateObjectRows(label, object) {
        if (object === undefined || object === null || object.length === 0) {
            return;
        }

        if (label === "Subject Area") {
            return object.map((item) => {
                if (item.iscedFCode === "") {
                    return;
                }

                let iscedClarificationCode = (item.iscedClarification !== "" && item.iscedClarification !== null && item.iscedClarification !== undefined) ?
                    <React.Fragment><span className="label">ISCED-F Clarification</span><span className="value">{this.formatValue(item.iscedClarification)}</span></React.Fragment> : <div />;

                return (
                    <React.Fragment>
                        <li>
                            <span className="label">ISCED-F Code</span>
                            <span className="value">{this.formatValue(item.iscedFCode)}</span>
                            {iscedClarificationCode}
                            <hr />
                        </li>
                    </React.Fragment>
                );
            });
        }
        else if (label === "Recommended language skills") {
            return object.map((item) => {
                if (item.language === "") {
                    return;
                }

                let cefrLevelCode = (item.cefrLevel !== "" && item.cefrLevel !== null && item.cefrLevel !== undefined) ?
                    <React.Fragment><span className="label">CEFR Level</span><span className="value">{this.formatValue(item.cefrLevel)}</span></React.Fragment> : <div />;
                let iscedFCodeCode = (item.iscedFCode !== "" && item.iscedFCode !== null && item.iscedFCode !== undefined) ?
                    <React.Fragment><span className="label">ISCED-F Code</span><span className="value">{this.formatValue(item.iscedFCode)}</span></React.Fragment> : <div />;
                let iscedClarificationCode = (item.iscedClarification !== "" && item.iscedClarification !== null && item.iscedClarification !== undefined) ?
                    <React.Fragment><span className="label">ISCED-F Clarification</span><span className="value">{this.formatValue(item.iscedClarification)}</span></React.Fragment> : <div />;

                return (
                    <React.Fragment>
                        <li>
                            <span className="label">Language</span>
                            <span className="value">{this.formatValue(item.language)}</span>
                            {cefrLevelCode}
                            {iscedFCodeCode}
                            {iscedClarificationCode}
                            <hr />
                        </li>
                    </React.Fragment>
                );
            });
        }
    }

    formatValue(value) {
        if (value === undefined || value === null || value === "") {
            return "--------------";
        }
        if (value.length > 28) {
            return <p style={{ margin: "0px", width: "250px", overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap" }}>{value}</p>
        }
        return value;
    }

    signIIA() {
        if (window.confirm("Are you sure do you want to sign this agreement? This operation is irreversible and this agreement will be uneditable.")) {
            return this.props["SignIIA"]({
                variables: {
                    iiaCodeIn: this.props.data.getIIA.iia_code_p1,
                    ecodeIn: localStorage.getItem("ErasmusCode"),
                    currentSign: GlobalVar.decryptWithKey(localStorage.getItem("Email"))
                }
            }).then((x) => {
                this.sendNotificationFunction();
                console.log("Signing IIA " + this.props.data.getIIA.iia_code_p1 + "...\nResults: " + JSON.stringify(x));
                alert("IIA " + this.props.data.getIIA.iia_code_p1 + " signed successfully!");
            });
        }
    }

    sendNotificationFunction() {
        let contactsData = this.props.data.getIIA;

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": (contactsData.creator_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                    ? GlobalVar.encryptWithKey(contactsData.creator_institution_data.erasmusCode).toString() : contactsData.creator_institution_data.erasmusCode,
            },
        }).then((creatorSuccess) => {
            this.props.isIIAManagedByEWP({
                variables: {
                    "ErasmusCode": (contactsData.partner_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                        ? GlobalVar.encryptWithKey(contactsData.partner_institution_data.erasmusCode).toString() : contactsData.partner_institution_data.erasmusCode,
                },
            }).then((partnerSuccess) => {

                if (creatorSuccess.data.error) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the parter will receive a notification in their system regarding this agreement.\nPlease wait until this task is finished.");
                        this.sendEWPNotification();
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(3);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 1) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // SHOULD NOT ENTER HERE
                        // If one of the partners is managed by an EWP node, the other one must be managed by Dashboard to edit it
                        // So in this case, the creator is already managed by an EWP node
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("The creator will receive a notification in their system and the partner will receive an email notification regarding this agreement.\nPlease wait until this task is finished.");
                        this.sendEWPNotification();
                        this.sendMailFunction(3);
                    }
                    else {
                        alert("Only the creator will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 2) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will receive an email notification and the partner will receive a notification in their system regarding this agreement.\nPlease wait until this task is finished.");
                        this.sendEWPNotification();
                        this.sendMailFunction(1);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Both partners will receive an email notification regarding this agreement.");
                        this.sendMailFunction(2);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("Only the creator will receive an email notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if(partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the partner will receive a notification in their system regarding this agreement.\nPlease wait until this task is finished.");
                        this.sendEWPNotification();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(3);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
            });
        });
    }

    sendMailFunction(status) {
        let contactsData = this.props.data.getIIA;
        const action_url = GlobalVar.getServerLink();

        if (contactsData.creator_institution_data.contacts[0] != null) {

            // send to creator only
            if (status == 1) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                    receiver: contactsData.creator_institution_data.contacts[0].email,
                    institution_name: contactsData.creator_institution_data.name,
                    institution_ecode: contactsData.creator_institution_data.erasmusCode,
                    newiia_id: contactsData.iia_code_p1,
                    action_url: action_url
                }));
            }

            // send to both partners
            if (status == 2) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                    receiver: contactsData.creator_institution_data.contacts[0].email,
                    institution_name: contactsData.creator_institution_data.name,
                    institution_ecode: contactsData.creator_institution_data.erasmusCode,
                    newiia_id: contactsData.iia_code_p1,
                    action_url: action_url
                }));
                axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                    receiver: contactsData.partner_institution_data.contacts[0].email,
                    institution_name: contactsData.creator_institution_data.name,
                    institution_ecode: contactsData.creator_institution_data.erasmusCode,
                    newiia_id: contactsData.iia_code_p1,
                    action_url: action_url
                }));
            }

            // send to partner only
            if (status == 3) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailSignIIA`, querystring.stringify({
                    receiver: contactsData.partner_institution_data.contacts[0].email,
                    institution_name: contactsData.creator_institution_data.name,
                    institution_ecode: contactsData.creator_institution_data.erasmusCode,
                    newiia_id: contactsData.iia_code_p1,
                    action_url: action_url
                }));
            }
        }
        else {
            alert("There is no contact to send the notification by email.");
        }
    }

    sendEWPNotification() {
        let contactsData = this.props.data.getIIA;

        console.log("Signing")
        console.log(contactsData)

        this.props.sendEWPIIAChangeNotification({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(contactsData.creator_institution_data.erasmusCode).toString(),
                "PartnerErasmusCode": contactsData.partner_institution_data.erasmusCode,
                "IIAID": contactsData.iia_id_p2,
                "Signed": 1
            },
        }).then((x) => {
            if (x.data.error) {
                alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
            } else {
                if(x.data.sendEWPIIAChangeNotification.Success){
                    alert("Notification sent successfully to the partner. You will be redirect to the IIA List page.");
                } else {
                    alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                }
            }

            this.deleteIIAInformation();
        });
    }

    sendNewEWPNotification() {

        let contactsData = this.props.data.getIIA;

        if (contactsData.inEffect === 1) {
            this.sendEWPNotification();
        }
        else {
            this.props.isIIAManagedByEWP({
                variables: {
                    "ErasmusCode": (contactsData.creator_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                        ? GlobalVar.encryptWithKey(contactsData.creator_institution_data.erasmusCode).toString() : contactsData.creator_institution_data.erasmusCode,
                },
            }).then((creatorSuccess) => {
                if (creatorSuccess.data.error) {
                    console.log("An error has occurred while checking creator in EWP.");
                    alert("An error has occurred while checking creator in EWP.");
                } else {
                    if (creatorSuccess.data.isIIAManagedByEWP.Success == 2) {
                        this.props.isIIAManagedByEWP({
                            variables: {
                                "ErasmusCode": (contactsData.partner_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                    ? GlobalVar.encryptWithKey(contactsData.partner_institution_data.erasmusCode).toString() : contactsData.partner_institution_data.erasmusCode,
                            },
                        }).then((partnerSuccess) => {
                            if (partnerSuccess.data.error) {
                                console.log("An error has occurred while checking partner in EWP.");
                                alert("An error has occurred while checking partner in EWP.");
                            } else {
                                if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                                    this.props.sendEWPIIANotification({
                                        variables: {
                                            "CreatorErasmusCode": (contactsData.creator_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                                ? GlobalVar.encryptWithKey(contactsData.creator_institution_data.erasmusCode).toString() : contactsData.creator_institution_data.erasmusCode,
                                            "PartnerErasmusCode": (contactsData.partner_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                                ? GlobalVar.encryptWithKey(contactsData.partner_institution_data.erasmusCode).toString() : contactsData.partner_institution_data.erasmusCode,
                                            "IIAID": contactsData.iia_code_p1
                                        },
                                    }).then((x) => {
                                        if (x.data.error) {
                                            console.log("An error has occurred while sending EWP notification.");
                                            alert("An error has occurred while sending EWP notification.");
                                        } else {
                                            if (x.data.sendEWPIIANotification.Success) {
                                                alert("EWP notification successfully sent.");
                                            } else {
                                                console.log("Error checking EWP Network.");
                                                alert("Error checking EWP Network.");
                                            }
                                        }
                                    });
                                }
                                if (partnerSuccess.data.isIIAManagedByEWP.Success == 0) {
                                    alert("Error checking EWP Network.");
                                }
                                if (partnerSuccess.data.isIIAManagedByEWP.Success == -1) {
                                    alert("Partner institution is not managed by any node in EWP.");
                                }
                            }
                        });
                    }
                    if (creatorSuccess.data.isIIAManagedByEWP.Success == 0) {
                        alert("Error checking EWP Network.");
                    }
                    if (creatorSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("Your institution is managed by another EWP node.");
                    }
                    if (creatorSuccess.data.isIIAManagedByEWP.Success == -1) {
                        alert("Your institution is not managed by any node in EWP.");
                    }
                }
            });
        }
    }

    deleteIIAInformation() {
        sessionStorage.removeItem("iiaListPartnerDetails");
        sessionStorage.removeItem("iiaListCoopConditions");
        sessionStorage.removeItem("iiaListOriginalCoopConditions");
        sessionStorage.removeItem("iiaListAuxiliarInformation");
        this.props.history.push(GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List"));
        window.location.reload();
    }

    checkIfPartnersIIAisManagedByEWP() {
        let contactsData = this.props.data.getIIA;

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": (contactsData.creator_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces"))?.erasmusCode)
                    ? GlobalVar.encryptWithKey(contactsData.creator_institution_data.erasmusCode).toString() : contactsData.creator_institution_data.erasmusCode,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isCreatorInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isCreatorInEWP: 1,
                        });
                        this.getPartnerData(1, contactsData.creator_institution_code, true);
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isCreatorInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isCreatorInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isCreatorInEWP: 0
                        });
                        break;
                }
            }
        });

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": (contactsData.partner_institution_data.erasmusCode.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces"))?.erasmusCode)
                    ? GlobalVar.encryptWithKey(contactsData.partner_institution_data.erasmusCode).toString() : contactsData.partner_institution_data.erasmusCode,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isPartnerInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch (x.data.isIIAManagedByEWP.Success) {
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isPartnerInEWP: 1,
                        });
                        this.getPartnerData(1, contactsData.partner_institution_code, false);
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isPartnerInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isPartnerInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isPartnerInEWP: 0
                        });
                        break;
                }
            }
        });
    }

    getPartnerData(status, eCode, isCreator) {
        if (status == 1) {
            console.log("Get partner data");
            this.props.getEWPInstitutionData({
                variables: {
                    "CreatorErasmusCode": localStorage.getItem("ErasmusCode"),
                    "PartnerErasmusCode": eCode,
                },
            }).then((x) => {
                if (x.data.getEWPInstitutionData.Success) {
                    console.log(x);
                    // this.setState({
                    //     partnerOUlist: x.data.getEWPInstitutionData.OUList
                    // });
                    // aux.partnerOUnitList = x.data.getEWPInstitutionData.OUList;
                    // sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));

                    let ounitCodesList = [];
                    x.data.getEWPInstitutionData.OUList.map((ounit) => {
                        ounitCodesList.push(ounit.ounit_id);
                    });
                    this.getPartnerEWPOUnits(eCode, ounitCodesList, isCreator);
                    this.getEWPStaticData();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    getPartnerEWPOUnits(eCode, ouCodesList, isCreator) {
        if (eCode !== "" && ouCodesList.length > 0) {

            this.props.getEWPOUnits({
                variables: {
                    "ErasmusCode": eCode,
                    "clientHeiEC": localStorage.getItem("ErasmusCode")
                    // "ounitsList": ouCodesList
                }
            }).then((response) => {
                if (response.data.error) {
                    console.log("Error getting Organizational Units");
                } else {
                    if (response.data.getEWPOUnits.Success === 1) {
                        console.log("Successfully retrieved Organizational Units");

                        if (isCreator) {
                            this.setState({
                                creatorOUlist: response.data.getEWPOUnits.OUList
                            });
                        }
                        else {
                            this.setState({
                                partnerOUlist: response.data.getEWPOUnits.OUList
                            });
                        }
                    } else {
                        console.log("Error getting Organizational Units");
                    }
                }
            });
        }
    }

    getEWPStaticData() {
        this.props.getEWPStaticData({
            variables: {
                "PartnerErasmusCode": this.props.data.getIIA.partner_institution_code,
                "clientHeiEC": localStorage.getItem("ErasmusCode")
            },
        }).then((x) => {
            if (x.data.error || x.data.Success === 0) {
                this.setState({
                    partnerStaticData: null,
                    fetchingPartnerData:false
                });
            } else {
                console.log("(checkPartnerInEWPandGetData) Is partner in EWP?");
                console.log(x.data);
                switch (x.data.getEWPStaticData.Success) {
                    case 1:
                        //By a node in EWP
                        console.log(x.data.getEWPStaticData);
                        if (x.data.getEWPStaticData.StaticData != null) {
                            this.setState({
                                partnerStaticData: x.data.getEWPStaticData.StaticData,
                                fetchingPartnerData:false
                            });
                        }
                        break;
                    case 2:
                        //By dash
                        this.setState({
                            isPartnerInEWP:2
                        });

                        break;
                    default:
                        this.setState({
                            partnerStaticData: null,
                            fetchingPartnerData:false,
                            errorMessage: x.data.getEWPStaticData.Message
                        });
                        break;
                }
            }
        });
    }
}

const signIIA = gql`
    mutation signIIA($iiaCodeIn: String!, $ecodeIn: String, $currentSign: String) {
        signIIA(iiaID: $iiaCodeIn, ErasmusCode: $ecodeIn, currentSign: $currentSign) {
            iia_code_p1
            __typename
        }
    }
`;

const getIIADefaultData = gql`
    query getIIADefaultData($ErasmusCode: String!, $isToCreate: Boolean) {
        getIIADefaultData(ErasmusCode: $ErasmusCode, isToCreate: $isToCreate) {
            stuNomAutumnTerm
            stuNomSpringTerm
            appNomAutumnTerm
            appNomSpringTerm
            appProcedureEmail
            appProcedurePhone
            appProcedureWebsite
            addReqWeeks
            ToRWeeks
            additionalRequirements {
                id
                requirement
                ects
                isced
                eqf
                details
                website
            }
            inclusionInfrastructure {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            inclusionService {
                id
                name
                specialNeeds
                description
                email
                phone
                website
            }
            housing_email
            housing_phone
            housing_website
            visa_email
            visa_phone
            visa_website
            insurance_email
            insurance_phone
            insurance_website
            additionalInformation {
                id
                information
                email
                phone
                website
            }
        }
    }
`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const isIIAManagedByEWPQuery = gql`
    query isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const getEWPInstitutionData = gql`
    mutation getEWPInstitutionData($CreatorErasmusCode: String!, $PartnerErasmusCode: String!) {
        getEWPInstitutionData(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode) {
            Success
            Message
            ContactName
            ContactEmail
            ContactPhone
            WebsiteGeneral
            WebsiteFaculty
            WebsiteCourse
            OUList {
                ounit_id
                name
                institution_ecode
                active
            }
        }
    }
`;

const getEWPOUnits = gql`
    mutation getEWPOUnits($ErasmusCode: String!, $clientHeiEC: String!) {
        getEWPOUnits(ErasmusCode: $ErasmusCode, clientHeiEC: $clientHeiEC) {
            Success
            OUList {
                ounit_id
                name
                institution_ecode
                active
            }
        }
    }
`;

const sendEWPIIAChangeNotification = gql`
    mutation sendEWPIIAChangeNotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!, $Signed: Int) {
        sendEWPIIAChangeNotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID, Signed: $Signed) {
            Success
            Message
        }
    }
`;

const sendEWPIIANotification = gql`
    mutation sendEWPIIANotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!) {
        sendEWPIIANotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID) {
            Success
            Message
        }
    }
`;

const getIscedFCodesList = gql`
    query {
        getIscedFCodesList {
            code
            name
        }
    }
`;

const getEWPStaticData = gql`
    mutation getEWPStaticData($PartnerErasmusCode: String!, $clientHeiEC: String!) {
        getEWPStaticData(PartnerErasmusCode: $PartnerErasmusCode, clientHeiEC: $clientHeiEC) {
            Success
            Message
            StaticData {
                stuNomAutumnTerm
                stuNomSpringTerm
                appNomAutumnTerm
                appNomSpringTerm
                appProcedureEmail
                appProcedurePhone
                appProcedureWebsite
                addReqWeeks 
                ToRWeeks
                additionalRequirements {
                    id
                    requirement
                    details
                    website
                }
                inclusionInfrastructure {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                inclusionService {
                    id
                    name
                    specialNeeds
                    description
                    email
                    phone
                    website
                }
                housing_email
                housing_phone
                housing_website
                visa_email
                visa_phone
                visa_website
                insurance_email
                insurance_phone
                insurance_website
                additionalInformation {
                    id
                    information
                    email
                    phone
                    website
                }
            }
        }
    }
`;

export default compose(
    graphql(getIIA, {
        options: (props) => {
            return {
                variables: {
                    iiaCodeIn: props.match.params.iiaId
                }
            }
        }
    }),
    graphql(getIIADefaultData, {
        name: "DefaultDataCreator",
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: (props.location.state.data.creator_institution_code.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces"))?.erasmusCode)
                        ? GlobalVar.encryptWithKey(props.location.state.data.creator_institution_code).toString() : props?.location?.state.data.creator_institution_code,
                    isToCreate: false
                }
            }
        }
    }),
    graphql(getIIADefaultData, {
        name: "DefaultDataPartner",
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: (props.location.state.data.partner_institution_code.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces"))?.erasmusCode)
                        ? GlobalVar.encryptWithKey(props.location.state.data.partner_institution_code).toString() : props.location.state.data.partner_institution_code,
                    isToCreate: false
                }
            }
        }
    }),
    graphql(signIIA, { name: "SignIIA" }),
    graphql(isIIAManagedByEWP, { name: "isIIAManagedByEWP" }),
    graphql(isIIAManagedByEWPQuery, { name: "isIIAManagedByEWPQuery",
        options: (props) => {
            return {
                variables: {
                    ErasmusCode: localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : ""
                }
            }
        }}),
    graphql(getEWPInstitutionData, { name: "getEWPInstitutionData" }),
    graphql(getEWPOUnits, { name: "getEWPOUnits" }),
    graphql(sendEWPIIAChangeNotification, { name: "sendEWPIIAChangeNotification" }),
    graphql(sendEWPIIANotification, { name: "sendEWPIIANotification" }),
    graphql(getIscedFCodesList, { name: "getIscedFCodesList" }),
    graphql(getEWPStaticData, { name: "getEWPStaticData" })
)(ShowInfo);
