import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag'
import intl from 'react-intl-universal';
import axios from 'axios';
import querystring from 'querystring';

import GlobalVar from '../../../../GlobalVar';
import Button from '../../common/Button';
import Title from '../../common/Title';
import Partner from './Partner';
import Condition from './Condition';
import Info from './Info';
import Summary from './Summary';
import CalendarInfo from './CalendarInfo';

import { query as getIIA } from '../../../../queries/getIIA';
import { query as getLanguages } from '../../../../queries/getLanguages';

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reloadActions: false,
            value: "",
            isCreatorInEWP: -10,
            isPartnerInEWP: -10,
            creatorOUlist: [],
            partnerOUlist: [],
            ounitsFetched: false,
            checkEWP: false,
            errorsPartners: {},
            errorsConditions: {},
            tcCheckboxEdit: (sessionStorage.getItem("tcCheckboxEdit") !== null && sessionStorage.getItem("tcCheckboxEdit") === "true") ? true : false
        }

        this.handleButtonsClick = this.handleButtonsClick.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.setErrors = this.setErrors.bind(this);
        this.arrangeMobTypes = this.arrangeMobTypes.bind(this);

        this.updateEditStatus(true, false);
    }

    componentWillUnmount() {
        this.updateEditStatus(false, false);
    }

    render() {
        if (!this.props.data.loading && !this.props.allLanguages.loading) {
            if (JSON.parse(sessionStorage.getItem("iiaListPartnersDetails")) === null) {
                this.generateIIAListPartnersDetails();
            }
            if (JSON.parse(sessionStorage.getItem("iiaListOriginalPartnersDetails")) === null) {
                this.generateIIAListOriginalPartnersDetails();
            }
            if (JSON.parse(sessionStorage.getItem("iiaListCoopConditions")) === null) {
                this.generateIIAListCoopConditions();
            }
            if (JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions")) === null) {
                this.generateIIAListOriginalCoopConditions();
            }
            if (sessionStorage.getItem("tcCheckboxEdit") === null) {
                this.generateTCcheckbox();
            }

            return (
                <div className="content upload">
                    {this.renderIIAForm()}
                    {this.renderButtons()}
                </div>
            );
        }

        return (
            <div className="content upload">
                <Title text="Loading and setting up all forms..." />
                <h4>If loading is taking a while, please refresh the page.</h4>
            </div>
        );
    }

    updateEditStatus(toActivate, backFromCancel) {
        let iiaListAuxiliarInformation = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (toActivate) {
            iiaListAuxiliarInformation.editActivated = true;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(iiaListAuxiliarInformation));
        }
        else {
            iiaListAuxiliarInformation.editActivated = false;
            iiaListAuxiliarInformation.editPageNumber = -2;
            iiaListAuxiliarInformation.backDisabled = true;
            iiaListAuxiliarInformation.addDisabled = true;
            iiaListAuxiliarInformation.nextDisabled = !this.state.tcCheckboxEdit;
            iiaListAuxiliarInformation.updateDisabled = true;
            iiaListAuxiliarInformation.signDisabled = false;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(iiaListAuxiliarInformation));
            sessionStorage.removeItem("tcCheckboxEdit");

            if (backFromCancel) {
                this.props.history.push(GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List"));
            }
        }
    }

    generateIIAListPartnersDetails() {
        let partnersDetails = ({
            partnerInstitution: "",
            partnerErasmusCode: "",
            partnerCountry: "",
            partnerCity: "",
            partnerOUnit: "",
            partnerFactsheet: "",
            partnerContactName: "",
            partnerContactEmail: "",
            partnerContactPhone: "",
            partnerGeneralWebsite: "",
            partnerFacultyWebsite: "",
            partnerCourseWebsite: "",

            academicYearStart: "",
            academicYearEnd: "",
        });
        sessionStorage.setItem("iiaListPartnersDetails", JSON.stringify(partnersDetails));
    }

    generateIIAListOriginalPartnersDetails() {
        let data = this.props.data.getIIA;

        let partnersDetails = ({
            creatorInstitution: (data.creator_institution_data.name != null) ? data.creator_institution_data.name : "",
            creatorErasmusCode: (data.creator_institution_data.erasmusCode != null) ? data.creator_institution_data.erasmusCode : "",
            creatorCountry: (data.creator_institution_data.country != null) ? data.creator_institution_data.country : "",
            creatorCity: (data.creator_institution_data.city != null) ? data.creator_institution_data.city : "",
            creatorOUnit: (data.creatorOU != null) ? data.creatorOU.ounit_id : "",
            creatorFactsheet: data.creator_institution_data.factsheet,
            creatorContactName: (data.creator_institution_data.contacts[0] != null) ? data.creator_institution_data.contacts[0].name : "",
            creatorContactEmail: (data.creator_institution_data.contacts[0] != null) ? data.creator_institution_data.contacts[0].email : "",
            creatorContactPhone: (data.creator_institution_data.contacts[0] != null) ? data.creator_institution_data.contacts[0].phone : "",
            creatorGeneralWebsite: (data.creatorGeneral != null) ? data.creatorGeneral : "",
            creatorFacultyWebsite: (data.creatorFaculty != null) ? data.creatorFaculty : "",
            creatorCourseWebsite: (data.creatorCourse != null) ? data.creatorCourse : "",

            partnerInstitution: (data.partner_institution_data.name != null) ? data.partner_institution_data.name : "",
            partnerErasmusCode: (data.partner_institution_data.erasmusCode != null) ? data.partner_institution_data.erasmusCode : "",
            partnerCountry: (data.partner_institution_data.country != null) ? data.partner_institution_data.country : "",
            partnerCity: (data.partner_institution_data.city != null) ? data.partner_institution_data.city : "",
            partnerOUnit: (data.partnerOU != null) ? data.partnerOU.ounit_id : "",
            partnerFactsheet: data.partner_institution_data.factsheet,
            partnerContactName: (data.partner_institution_data.contacts[0] != null) ? data.partner_institution_data.contacts[0].name : "",
            partnerContactEmail: (data.partner_institution_data.contacts[0] != null) ? data.partner_institution_data.contacts[0].email : "",
            partnerContactPhone: (data.partner_institution_data.contacts[0] != null) ? data.partner_institution_data.contacts[0].phone : "",
            partnerGeneralWebsite: (data.partnerGeneralWebsite != null) ? data.partnerGeneralWebsite : "",
            partnerFacultyWebsite: (data.partnerFacultyWebsite != null) ? data.partnerFacultyWebsite : "",
            partnerCourseWebsite: (data.partnerCourseWebsite != null) ? data.partnerCourseWebsite : "",

            academicYearStart: data.academicYearStart,
            academicYearEnd: data.academicYearEnd,
            iiaCode: data.iia_code_p1,
        });
        sessionStorage.setItem("iiaListOriginalPartnersDetails", JSON.stringify(partnersDetails));
    }

    generateIIAListCoopConditions() {
        let data = this.props.data.getIIA.conditions;
        let coopConditions = [];

        for (let i = 0; i < data.length; i++) {
            let eqfLevels = [];
            if (data[i].eqfLevels) {
                for (let j = 0; j < data[i].eqfLevels.length; j++) {
                    eqfLevels[j] = data[i].eqfLevels[j].eqfLevel;
                }
            }
            let langs = [];
            if (data[i].languages) {
                for (let j = 0; j < data[i].languages.length; j++) {
                    if (data[i].languages[j].language !== "" && data[i].languages[j].language !== undefined && data[i].languages[j].language !== null) {
                        let allLangs = this.props.allLanguages.allLanguages;
                        let langWithCode = allLangs.find((item) => item.Name === data[i].languages[j].language.split(" - ")[0]);

                        if (langWithCode) {
                            langs.push({ language: langWithCode.Name + " - " + langWithCode.Code, cefrLevel: data[i].languages[j].cefrLevel, iscedFCode: data[i].languages[j].iscedFCode, iscedClarification: data[i].languages[j].iscedClarification });
                        }
                    }
                }
            }
            let iscedFCodes = [];
            if (data[i].iscedFCodes) {
                for (let j = 0; j < data[i].iscedFCodes.length; j++) {
                    if (data[i].iscedFCodes[j].iscedFCode !== "") {
                        iscedFCodes.push({ iscedFCode: data[i].iscedFCodes[j].iscedFCode, iscedClarification: data[i].iscedFCodes[j].iscedClarification });
                    }
                }
            }

            coopConditions.push({
                senderInstitution: "",
                receiverInstitution: "",
                sendingOUnit: "",
                receivingOUnit: "",
                mobilitiesPerYear: "",
                iscedFCode: iscedFCodes,
                languageSkill: langs,
                mobilityType: "",
                totalMonths: "",
                totalDays: "",
                eqfLevels: eqfLevels,
                blended: data[i].blended,
                otherInformation: ""
            });
        }
        sessionStorage.setItem("iiaListCoopConditions", JSON.stringify(coopConditions));
    }

    generateIIAListOriginalCoopConditions() {
        let data = this.props.data.getIIA.conditions;
        let coopConditions = [];

        for (let i = 0; i < data.length; i++) {
            let langs = [];
            if (data[i].languages) {
                for (let j = 0; j < data[i].languages.length; j++) {
                    if (data[i].languages[j].language !== "" && data[i].languages[j].language !== undefined && data[i].languages[j].language !== null) {
                        let allLangs = this.props.allLanguages.allLanguages;
                        let langWithCode = allLangs.find((item) => item.Name === data[i].languages[j].language.split(" - ")[0]);

                        if (langWithCode) {
                            langs.push({ cefrLevel: data[i].languages[j].cefrLevel, language: langWithCode.Name + " - " + langWithCode.Code, iscedFCode: data[i].languages[j].iscedFCode, iscedClarification: data[i].languages[j].iscedClarification });
                        }
                    }
                }
            }
            let eqfLevels = [];
            if (data[i].eqfLevels) {
                for (let j = 0; j < data[i].eqfLevels.length; j++) {
                    eqfLevels[j] = data[i].eqfLevels[j].eqfLevel;
                }
            }
            let iscedFCodes = [];
            if (data[i].iscedFCodes) {
                for (let j = 0; j < data[i].iscedFCodes.length; j++) {
                    if (data[i].iscedFCodes[j].iscedFCode !== "") {
                        iscedFCodes.push({ iscedFCode: data[i].iscedFCodes[j].iscedFCode, iscedClarification: data[i].iscedFCodes[j].iscedClarification });
                    }
                }
            }

            coopConditions.push({
                senderInstitution: data[i].senderInstitution,
                receiverInstitution: data[i].receiverInstitution,
                sendingOUnit: data[i].sendingOUnit,
                receivingOUnit: data[i].receivingOUnit,
                iscedFCode: iscedFCodes,
                mobilitiesPerYear: data[i].mobilitiesPerYear,
                senderInstitution: data[i].senderInstitution,
                receiverInstitution: data[i].receiverInstitution,
                eqfLevels: eqfLevels,
                mobilityType: data[i].mobilityType,
                totalDays: data[i].totalDays,
                totalMonths: data[i].totalMonths,
                languageSkill: langs,
                blended: data[i].blended,
                otherInformation: data[i].otherInformation
            });
        }
        sessionStorage.setItem("iiaListOriginalCoopConditions", JSON.stringify(coopConditions));
    }

    addNewCoopCondition() {
        let coopConditions = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));
        let originalCoopConditions = JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions"));

        coopConditions.push({
            senderInstitution: "",
            receiverInstitution: "",
            sendingOUnit: "",
            receivingOUnit: "",
            mobilitiesPerYear: "",
            iscedFCode: [{ iscedFCode: "", iscedClarification: "" }],
            languageSkill: [{ language: "", cefrLevel: "", iscedFCode: "", iscedClarification: "" }],
            mobilityType: "",
            totalMonths: "",
            totalDays: "",
            eqfLevels: [],
            blended: 0,
            otherInformation: ""
        });

        originalCoopConditions.push({
            senderInstitution: "",
            receiverInstitution: "",
            sendingOUnit: "",
            receivingOUnit: "",
            iscedFCode: [{ iscedFCode: "", iscedClarification: "" }],
            mobilitiesPerYear: "",
            senderInstitution: "",
            receiverInstitution: "",
            eqfLevels: [],
            mobilityType: "",
            totalDays: "",
            totalMonths: "",
            languageSkill: [{ language: "", cefrLevel: "", iscedFCode: "", iscedClarification: "" }],
            blended: 0,
            otherInformation: ""
        });

        sessionStorage.setItem("iiaListCoopConditions", JSON.stringify(coopConditions));
        sessionStorage.setItem("iiaListOriginalCoopConditions", JSON.stringify(originalCoopConditions));
    }

    generateTCcheckbox() {
        sessionStorage.setItem("tcCheckboxEdit", false);
    }

    toPreviousPage() {
        this.props.history.goBack();
        localStorage.removeItem("updateIIA");
    }

    checkError() {
        if (this.state.error) {
            return "error_field";
        }
        else if (this.state.valid) {
            return "valid_field";
        }
    }

    generateNotFoundValue(value) {
        if (value === null || value === undefined || value === "" || value == 0) {
            return "";
        }
        return value;
    }

    generateErrorMessage() {
        if (this.state.error) {
            return <div className="error_message"><p>{this.state.errorText}</p></div>
        }
    }

    typing(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    addInputField(label) {
        if (label === "header") {
            return (
                <li>
                    <span className="label_header">Field Name</span>
                    <span className="input_header">Original Value</span>
                    <span className="input_header">Last Update</span>
                    <span className="input_header">New Value</span>
                </li>
            );
        }
        return (
            <li>
                <span className="label">{label}</span>
                <textarea className={this.checkError()} value="" rows="1" readOnly />
                <textarea className={this.checkError()} value="" rows="1" readOnly />
                <textarea className={this.checkError()} value="" rows="1" />
            </li>
        );
    }

    sendNotificationFunction(creatingIIA) {

        console.log("Editing IIA")
        console.log(creatingIIA)

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": creatingIIA.creatorIn.erasmusCode,       // the erasmus code is already encrypted
            },
        }).then((creatorSuccess) => {
            this.props.isIIAManagedByEWP({
                variables: {
                    "ErasmusCode": creatingIIA.partnerIn.erasmusCode,    
                },
            }).then((partnerSuccess) => {

                if (creatorSuccess.data.error) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the parter will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        alert("IIA data submited.");
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 1) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        // SHOULD NOT ENTER HERE
                        // If one of the partners is managed by an EWP node, the other one must be managed by Dashboard to edit it
                        // So in this case, the creator is already managed by an EWP node
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("The creator will receive a notification in their system and the partner will receive an email notification regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 3);
                    }
                    else {
                        alert("Only the creator will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else if (creatorSuccess.data.isIIAManagedByEWP.Success == 2) {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to this partner regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will receive an email notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                        this.sendMailFunction(creatingIIA, 1);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Both partners will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 2);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("Only the creator will receive an email notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
                else {
                    if (partnerSuccess.data.error) {
                        alert("An error has occurred while checking partner in EWP. A notification will not be sent to both partners regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 1) {
                        alert("The creator will not receive any notification and the partner will receive a notification in their system regarding this agreement.");
                        this.sendEWPNotification(creatingIIA);
                    }
                    else if (partnerSuccess.data.isIIAManagedByEWP.Success == 2) {
                        alert("Only the partner will receive an email notification regarding this agreement.");
                        this.sendMailFunction(creatingIIA, 3);
                        this.deleteIIAInformation();
                    }
                    else {
                        alert("None of the partners will receive a notification regarding this agreement.");
                        alert("IIA data submited.");
                        this.deleteIIAInformation();
                    }
                }
            });
        });
    }

    sendMailFunction(data, status) {
        const action_url = GlobalVar.getServerLink();

        if (data.creatorIn.contact != null) {
            // send to creator only
            if (status == 1) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailUpdatenIIA`, querystring.stringify({
                    receiver: data.creatorIn.contact.email,
                    institution_name: data.creatorIn.name,
                    institution_ecode: data.creatorIn.erasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }

            // send to both partners
            if (status == 2) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailUpdateIIA`, querystring.stringify({
                    receiver: data.creatorIn.contact.email,
                    institution_name: data.creatorIn.name,
                    institution_ecode: data.creatorIn.erasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
                axios.post(`${GlobalVar.getServerLink()}/sendMailUpdateIIA`, querystring.stringify({
                    receiver: data.partnerIn.contact.email,
                    institution_name: data.partnerIn.name,
                    institution_ecode: data.partnerIn.erasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }

            // send to partner only
            if (status == 3) {
                axios.post(`${GlobalVar.getServerLink()}/sendMailUpdateIIA`, querystring.stringify({
                    receiver: data.partnerIn.contact.email,
                    institution_name: data.partnerIn.name,
                    institution_ecode: data.partnerIn.erasmusCode,
                    newiia_id: data.iiaCode,
                    action_url: action_url
                }));
            }
        }
        else {
            alert("There is no contact to send the notification by email.");
        }
    }

    sendEWPNotification(creatingIIA) {
        this.props.sendEWPIIAChangeNotification({
            variables: {
                "CreatorErasmusCode": creatingIIA.creatorIn.erasmusCode,    // the erasmus code is already encrypted
                "PartnerErasmusCode": creatingIIA.partnerIn.erasmusCode,    
                "IIAID": creatingIIA.iiaCode,
                "Signed": 0
            },
        }).then((x) => {
            if (x.data.error) {
                alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
            } else {
                if (x.data.sendEWPIIAChangeNotification.Success) {
                    alert("Notification sent successfully to the partner.");
                } else {
                    alert("An error has occurred while checking partner in EWP and it may not be possible to send this Agreement through the network to the partner. Please, notify the partner regarding this agreement by another way.");
                }
            }

            this.deleteIIAInformation();
        });
    }

    deleteIIAInformation() {
        sessionStorage.removeItem("iiaListPartnersDetails");
        sessionStorage.removeItem("iiaListOriginalPartnersDetails");
        sessionStorage.removeItem("iiaListCoopConditions");
        sessionStorage.removeItem("iiaListOriginalCoopConditions");
        sessionStorage.removeItem("tcCheckboxEdit");
        this.updateEditStatus(false, true);
        window.location.reload();
    }

    renderIIAForm() {
        let iiaListAuxiliarInformation = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        let newCC = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));

        if (!this.state.checkEWP) {
            this.checkIfPartnersIIAisManagedByEWP();
            this.setState({ checkEWP: true });
        }
        
        if (this.state.data !== null) {
            if (this.props.data.loading || this.state.isCreatorInEWP === -10 || this.state.isPartnerInEWP === -10 || !this.state.ounitsFetched) {//(this.state.isCreatorInEWP === 1 && !this.state.creatorOUlist.length) || (this.state.isPartnerInEWP === 1 && !this.state.partnerOUlist.length)) {
                return (
                    <div className="content upload">
                        <Title text="Loading and setting up all forms..." />
                        <h4>If loading is taking a while, please refresh the page.</h4>
                    </div>
                );
            }

            if (iiaListAuxiliarInformation.editPageNumber === -2) {
                return (
                    <Info
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPage}
                    />
                );
            }
            if (iiaListAuxiliarInformation.editPageNumber === -1) {
                return (
                    <Partner
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPage}
                        data={this.props.data.getIIA}
                        setErrors={this.setErrors}
                    />
                );
            }
            else if (iiaListAuxiliarInformation.editPageNumber === 0) {
                return (
                    <CalendarInfo
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPage}
                        setErrors={this.setErrors}
                    />
                );
            }
            else if (iiaListAuxiliarInformation.editPageNumber === newCC.length + 1) {

                return (
                    <Summary
                        className="card scrollbar card_full_width card_full_height"
                        refreshPage={this.refreshPage}
                    />
                );
            }
            else if (iiaListAuxiliarInformation.editPageNumber > 0) {
                return (
                    <Condition
                        className="card scrollbar card_full_width card_full_height"
                        currentPage={iiaListAuxiliarInformation.editPageNumber}
                        refreshPage={this.refreshPage}
                        data={this.props.data.getIIA}
                        arrangeMobTypes={this.arrangeMobTypes}
                        showMobFields={iiaListAuxiliarInformation.showMobFields}
                        setErrors={this.setErrors}
                    />
                );
            }
            return;
        }
    }

    renderButtons() {
        let auxInfo = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        return (
            <div className="card card_full_width actions" id="upload_card_create_iia" style={{ overflowX: "auto", height: "auto" }}>
                <Button
                    id="editCancel"
                    classNameButton="warning_button"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Cancel Edition"
                />
                <Button
                    id="editBack"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Previous Page"
                    disabled={auxInfo.backDisabled}
                />
                <Button
                    id="editNext"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Next Page"
                    disabled={auxInfo.nextDisabled}
                />
                <Button
                    id="editUpdate"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Submit Changes"
                    disabled={auxInfo.updateDisabled}
                />
                |
                <Button
                    id="editAdd"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Add Cooperation Condition"
                    disabled={auxInfo.addDisabled}
                />
                <Button
                    id="editDelete"
                    handleClick={this.handleButtonsClick}
                    refreshPage={this.refreshPage}
                    text="Delete Cooperation Condition"
                    disabled={auxInfo.deleteDisabled}
                />

            </div>
        );
    }

    /**
     * All possible values for 'showMobFields':
     *  - "": show all
     *  - "avgmonthsEQF": show average months and EQF levels (student studies)
     *  - "totalmonthsEQF": show total months and EQF levels (student studies)
     *  - "avgtotalmonthsEQF": show average and total months and EQF levels (student studies)
     *  - "avgmonths": show average months (student traineeship)
     *  - "totalmonths": show total months (student traineeship)
     *  - "avgtotalmonths": show average and total days (student traineeship)
     *  - "avgdays": show average days (staff training)
     *  - "totaldays": show total days (staff training)
     *  - "avgtotaldays": show average and total days (staff training)
     */
    arrangeMobTypes() {
        const newCC = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));
        const oldCC = JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions"));
        const aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (aux.editPageNumber > 0 && aux.editPageNumber < oldCC.length + 1) {
            if (newCC[aux.editPageNumber - 1].mobilityType === "") {
                if (oldCC[aux.editPageNumber - 1].mobilityType === "") {
                    aux.showMobFields = "";
                }
                else if (oldCC[aux.editPageNumber - 1].mobilityType === "Student studies" || oldCC[aux.editPageNumber - 1].mobilityType === "Student traineeship") {
                    aux.showMobFields = "avgtotalmonthsEQF";

                    if (newCC[aux.editPageNumber - 1].totalMonths !== "") {
                        aux.showMobFields = "totalmonthsEQF";
                    }
                }
                else if (oldCC[aux.editPageNumber - 1].mobilityType === "Staff teacher" || oldCC[aux.editPageNumber - 1].mobilityType === "Staff training") {
                    aux.showMobFields = "avgtotaldays";

                    if (newCC[aux.editPageNumber - 1].totalDays !== "") {
                        aux.showMobFields = "totaldays";
                    }
                }
            }
            else if (newCC[aux.editPageNumber - 1].mobilityType === "Student studies" || newCC[aux.editPageNumber - 1].mobilityType === "Student traineeship") {

                if (newCC[aux.editPageNumber - 1].totalMonths !== "") {
                    aux.showMobFields = "totalmonthsEQF";
                }
                else {
                    aux.showMobFields = "avgtotalmonthsEQF";
                }
            }
            else if (newCC[aux.editPageNumber - 1].mobilityType === "Staff teacher" || newCC[aux.editPageNumber - 1].mobilityType === "Staff training") {

                if (newCC[aux.editPageNumber - 1].totalDays !== "") {
                    aux.showMobFields = "totaldays";
                }
                else {
                    aux.showMobFields = "avgtotaldays";
                }
            }

            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
            this.handleEnableDisable(aux.showMobFields, "mobilityType");
        }
    }

    handleEnableDisable(value, name) {
        if (name === "mobilityType") {
            if (value === "") {
                this.hideElements(document.querySelectorAll('[groupname="sms"], [groupname="stms"], [groupname="eqf"]'));
            }
            else if (value === "avgmonthsEQF") {
                this.showElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"]'));
                this.hideElements(document.getElementsByName('totalMonths'));
                this.hideElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "totalmonthsEQF") {
                this.showElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"]'));
                this.showElements(document.getElementsByName('totalMonths'));
                this.showElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "avgtotalmonthsEQF") {
                this.showElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"]'));
                this.showElements(document.getElementsByName('totalMonths'));
                this.showElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "avgmonths") {
                this.showElements(document.querySelectorAll('[groupname="sms"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"], [groupname="eqf"]'));
                this.hideElements(document.getElementsByName('totalMonths'));
                this.hideElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "totalmonths") {
                this.showElements(document.querySelectorAll('[groupname="sms"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"], [groupname="eqf"]'));
                this.showElements(document.getElementsByName('totalMonths'));
                this.showElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "avgtotalmonths") {
                this.showElements(document.querySelectorAll('[groupname="sms"]'));
                this.hideElements(document.querySelectorAll('[groupname="stms"], [groupname="eqf"]'));
                this.showElements(document.getElementsByName('totalMonths'));
                this.showElements(document.getElementsByName('totalMonthsOld'));
            }
            else if (value === "avgdays") {
                this.showElements(document.querySelectorAll('[groupname="stms"]'));
                this.hideElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.hideElements(document.getElementsByName('totalDays'));
                this.hideElements(document.getElementsByName('totalDaysOld'));
            }
            else if (value === "totaldays") {
                this.showElements(document.querySelectorAll('[groupname="stms"]'));
                this.hideElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.showElements(document.getElementsByName('totalDays'));
                this.showElements(document.getElementsByName('totalDaysOld'));
            }
            else if (value === "avgtotaldays") {
                this.showElements(document.querySelectorAll('[groupname="stms"]'));
                this.hideElements(document.querySelectorAll('[groupname="sms"], [groupname="eqf"]'));
                this.showElements(document.getElementsByName('totalDays'));
                this.showElements(document.getElementsByName('totalDaysOld'));
            }
        }
        this.refreshPage();
    }

    handleButtonsClick(buttonId) {
        let iiaListOriginalCoopConditions = JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions"));
        let auxInfo = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (buttonId === "editCancel") {
            this.updateEditStatus(false, true);
        }
        else if (buttonId === "editBack") {
            auxInfo.editPageNumber = auxInfo.editPageNumber - 1;
            auxInfo.nextDisabled = false;
            auxInfo.addDisabled = true;
            auxInfo.updateDisabled = true;
            auxInfo.deleteDisabled = false;

            if (auxInfo.editPageNumber < 1 || auxInfo.editPageNumber > iiaListOriginalCoopConditions.length) {
                auxInfo.deleteDisabled = true;

                if (auxInfo.editPageNumber === -2) {
                    auxInfo.backDisabled = true;
                    auxInfo.nextDisabled = (sessionStorage.getItem("tcCheckboxEdit") === "true" ? false : true);
                }
            }
            else if (auxInfo.editPageNumber === iiaListOriginalCoopConditions.length) {
                auxInfo.addDisabled = false;
            }
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(auxInfo))
        }
        else if (buttonId === "editNext") {
            auxInfo.editPageNumber = auxInfo.editPageNumber + 1;
            auxInfo.backDisabled = false;
            auxInfo.deleteDisabled = false;
            auxInfo.addDisabled = true;

            if (auxInfo.editPageNumber === iiaListOriginalCoopConditions.length) {
                auxInfo.addDisabled = false;
            }
            else if (auxInfo.editPageNumber < 1) {
                auxInfo.deleteDisabled = true;
            }
            else if (auxInfo.editPageNumber === iiaListOriginalCoopConditions.length + 1) {
                auxInfo.nextDisabled = true;
                auxInfo.deleteDisabled = true;

                if ((auxInfo.hasChangesPartners || auxInfo.hasChangesConditions) && !this.checkErrors()) { auxInfo.updateDisabled = false; }
                else { auxInfo.updateDisabled = true; }
            }

            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(auxInfo))
        }
        else if (buttonId === "editDelete") {
            if (window.confirm("Are you sure do you want to delete this condition?")) {
                this.deleteCondition();
            }
        }
        else if (buttonId === "editAdd") {
            this.addNewCoopCondition();
            auxInfo.nextDisabled = true;
            auxInfo.editPageNumber = auxInfo.editPageNumber + 1;
            sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(auxInfo));
        }
        else if (buttonId === "editUpdate") {
            this.updateIIA();
        }

        if (auxInfo.editPageNumber > 0 && auxInfo.editPageNumber < iiaListOriginalCoopConditions.length + 1) {
            this.arrangeMobTypes();
        }
    }

    checkIfPartnersIIAisManagedByEWP() {
        let contactsData = this.props.data.getIIA;
        
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": (contactsData.creator_institution_code.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                ? GlobalVar.encryptWithKey(contactsData.creator_institution_code).toString() : contactsData.creator_institution_code,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isCreatorInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch(x.data.isIIAManagedByEWP.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isCreatorInEWP: 1,
                        });
                        this.getPartnerData(1, contactsData.creator_institution_code, true);
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isCreatorInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isCreatorInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isCreatorInEWP: 0
                        });
                        break;
                }
            }
        });

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": (contactsData.partner_institution_code.replace(/\s+/g, '') === JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode)
                                ? GlobalVar.encryptWithKey(contactsData.partner_institution_code).toString() : contactsData.partner_institution_code
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isPartnerInEWP: 0
                });
            } else {
                console.log("Is partner in EWP?");
                console.log(x.data);
                switch(x.data.isIIAManagedByEWP.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isPartnerInEWP: 1,
                        });
                        this.getPartnerData(1, contactsData.partner_institution_code, false);
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isPartnerInEWP: 2,
                            ounitsFetched: true
                        });
                        break;
                    case -1:
                        //Not in EWP
                        this.setState({
                            isPartnerInEWP: -1,
                            ounitsFetched: true
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isPartnerInEWP: 0,
                            ounitsFetched: true
                        });
                        break;
                }
            }
        });
    }

    getPartnerData(status, eCode, isCreator) {
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (status == 1) {
            console.log("Get partner data");
            this.props.getEWPInstitutionData({
                variables: {
                    "CreatorErasmusCode": localStorage.getItem("ErasmusCode"),
                    "PartnerErasmusCode": eCode
                },
            }).then((x) => {
                if (x.data.getEWPInstitutionData.Success) {
                    console.log(x);
                    let ounitsList = this.getOnlyActiveOUnits(x.data.getEWPInstitutionData.OUList);
                    // this.setState({
                    //     partnerOUlist: x.data.getEWPInstitutionData.OUList
                    // });
                    // aux.partnerOUnitList = x.data.getEWPInstitutionData.OUList;
                    // sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));

                    if (isCreator) {
                        this.setState({ creatorOUlist: ounitsList });
                        aux.creatorOUnitList = ounitsList;
                    }
                    else {
                        this.setState({ partnerOUlist: ounitsList });
                        aux.partnerOUnitList = ounitsList;
                    }
                    sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                    /*DEL*/console.log(aux.creatorOUnitList);
                    /*DEL*/console.log(aux.partnerOUnitList);
                    let ounitCodesList = [];
                    ounitsList.map((ounit) => {
                        ounitCodesList.push(ounit.ounit_id);
                    });
                    this.getPartnerEWPOUnits(eCode, ounitCodesList, isCreator);
                }
            }).catch(error => {
                console.log(error);
            });
        } 
    }

    getPartnerEWPOUnits(eCode, ouCodesList, isCreator) {
        let aux = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (eCode !== "" && ouCodesList.length > 0) {
            // this.props.listOU({
            //     variables: {
            //         "ErasmusCodeIn": eCode
            //     }
            // }).then((x) => {
            //     if (x.data.error || x.data.listOU == null || x.data.listOU.length == 0) {
            //         console.log("Error getting Organizational Units");

                    this.props.getEWPOUnits({
                        variables: {
                            "ErasmusCode": eCode,
                            "clientHeiEC": localStorage.getItem("ErasmusCode")
                            // "ounitsList": ouCodesList
                        }
                    }).then((response) => {
                        if (response.data.error) {
                            if (!isCreator) { this.setState({ ounitsFetched: true }); }
                            console.log("Error getting Organizational Units");
                        } else {
                            if (response.data.getEWPOUnits.Success === 1) {
                                console.log("Successfully retrieved Organizational Units");
                                
                                if (isCreator) {
                                    this.setState({
                                        creatorOUlist: response.data.getEWPOUnits.OUList
                                    });
                                    aux.creatorOUnitList = response.data.getEWPOUnits.OUList;
                                    sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                                }
                                else {
                                    this.setState({
                                        partnerOUlist: response.data.getEWPOUnits.OUList,
                                        ounitsFetched: true
                                    });
                                    aux.partnerOUnitList = response.data.getEWPOUnits.OUList;
                                    sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                                }
                            } else {
                                if (!isCreator) { 
                                    this.setState({ ounitsFetched: true }); 
                                }
                                console.log("Error getting Organizational Units");
                            }
                        }
                    });
                // } else {
                //     if (x.data.listOU) {
                //         console.log("Successfully retrieved Organizational Units");
                //         let ounitsList = this.getOnlyActiveOUnits(x.data.listOU);

                //         if (isCreator) {
                //             this.setState({
                //                 creatorOUlist: ounitsList
                //             });
                //             aux.creatorOUnitList = ounitsList;
                //             sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                //         }
                //         else {
                //             this.setState({
                //                 partnerOUlist: ounitsList,
                //                 ounitsFetched: true
                //             });
                //             aux.partnerOUnitList = ounitsList;
                //             sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(aux));
                //         }
                //     } else {
                //         console.log("Error getting Organizational Units");
                //         this.setState({ ounitsFetched: true });
                //     }
                // }
            // });
        }
        else if (!ouCodesList.length) {
            this.setState({ ounitsFetched: true }); 
        }
    }

    /**
     * Gets only the OUnits that are active, so user cannot select the OUnits not active.
     * 
     * @param {Object} ouList 
     */
    getOnlyActiveOUnits(ouList) {
        let ounitsList = [];

        if (ouList != null) {
            ouList.map(ou => {
                if (ou.active) {
                    ounitsList.push(ou);
                }
            });
        }

        console.log("ounitsList:", ounitsList);
        return ounitsList;
    }

    hideElements(elements) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
        }
    }

    showElements(elements) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "block";
        }
    }

    deleteCondition() {
        let oldCC = JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions"));
        let newCC = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));
        let auxInfo = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));

        if (auxInfo.editPageNumber - 1 > -1) {
            oldCC.splice(auxInfo.editPageNumber - 1, 1);
            newCC.splice(auxInfo.editPageNumber - 1, 1);
        }

        auxInfo.editPageNumber = auxInfo.editPageNumber - 1;
        if (auxInfo.editPageNumber === 0) {
            auxInfo.deleteDisabled = true;
        }
        if (auxInfo.editPageNumber === oldCC.length + 1) {
            auxInfo.nextDisabled = true;
        }
        else {
            auxInfo.nextDisabled = false;
        }

        if (newCC.length <= 1) {
            auxInfo.deleteDisabled = true;
        }

        sessionStorage.setItem("iiaListOriginalCoopConditions", JSON.stringify(oldCC));
        sessionStorage.setItem("iiaListCoopConditions", JSON.stringify(newCC));
        sessionStorage.setItem("iiaListAuxiliarInformation", JSON.stringify(auxInfo));
        this.refreshPage();
    }

    setErrors(name, hasError) {
        const auxInfo = JSON.parse(sessionStorage.getItem("iiaListAuxiliarInformation"));
        const cc = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));

        if (auxInfo.editPageNumber > 0 && auxInfo.editPageNumber <= cc.length) {
            this.state.errorsConditions[name + "" + auxInfo.editPageNumber] = hasError;
        }
        else {
            this.state.errorsPartners[name] = hasError;
        }

        this.refreshPage();
    }

    checkErrors() {
        for (let key in this.state.errorsPartners) {
            if (this.state.errorsPartners[key]) {
                return true;
            }
        }
        for (let key in this.state.errorsConditions) {
            if (this.state.errorsConditions[key]) {
                return true;
            }
        }
        return false;
    }

    refreshPageNumber(pageNumber) {
        let iiaCreationAuxiliarInformation = JSON.parse(localStorage.getItem("iiaCreationAuxiliarInformation"));
        iiaCreationAuxiliarInformation.pageNumber = pageNumber;
        localStorage.setItem("iiaCreationAuxiliarInformation", JSON.stringify(iiaCreationAuxiliarInformation));
    }

    refreshPage() {
        this.setState({
            reloadActions: !this.state.reloadActions
        });
    }

    updateIIA() {
        let pd = JSON.parse(sessionStorage.getItem("iiaListPartnersDetails"));
        let cc = JSON.parse(sessionStorage.getItem("iiaListCoopConditions"));
        let ccOrig = JSON.parse(sessionStorage.getItem("iiaListOriginalCoopConditions"));
        let toSend = null;
        let pdArray = [];
        pdArray.push(pd);

        if (window.confirm("Are you sure do you want to update this agreement? Both partners will receive an email notifying that an agreement was updated.")) {
            const promiseSendIIA = pdArray.map((
                { iiaCode, creatorInstitution, creatorErasmusCode, creatorCountry, creatorCity, partnerInstitution, partnerErasmusCode, partnerCountry, partnerCity, creatorContactName, creatorContactEmail, creatorContactPhone, creatorContactWebsite, creatorOUnit, partnerContactName, partnerContactEmail, partnerContactPhone, partnerContactWebsite, partnerOUnit, academicYearStart, academicYearEnd, creatorFactsheetData, partnerFactsheetData, creatorGeneralWebsite,creatorFacultyWebsite,creatorCourseWebsite, partnerGeneralWebsite,partnerFacultyWebsite,partnerCourseWebsite }
                , index) => {
                const uploadVariables = { creatorInstitution, creatorErasmusCode, creatorCountry, creatorCity, partnerInstitution, partnerErasmusCode, partnerCountry, partnerCity, creatorContactName, creatorContactEmail, creatorContactPhone, creatorContactWebsite, creatorOUnit, partnerContactName, partnerContactEmail, partnerContactPhone, partnerContactWebsite, partnerOUnit ,creatorGeneralWebsite,creatorFacultyWebsite,creatorCourseWebsite, partnerGeneralWebsite,partnerFacultyWebsite,partnerCourseWebsite }
                console.log("uploadVariables: " + JSON.stringify(uploadVariables));
                var ccsTosend = []

                cc.map((c, index) => {
                    let mobType = "";
                    if (c.mobilityType !== "" && c.mobilityType !== undefined && c.mobilityType !== null) { mobType = c.mobilityType; }
                    else if (ccOrig[index].mobilityType !== "" && ccOrig[index].mobilityType !== undefined && ccOrig[index].mobilityType !== null) { mobType = ccOrig[index].mobilityType; }

                    var clanguages = [];
                    c.languageSkill.map((item) => {
                        if (item.language !== "") {
                            clanguages.push({ language: item.language.split(' - ')[0], cefrLevel: item.cefrLevel, iscedFCode: item.iscedFCode, clarification: item.iscedClarification });
                        }
                    });

                    var cEqfLevels = [];
                    c.eqfLevels.map((eqfLevel) => {
                        cEqfLevels.push({ eqfLevel: parseInt(eqfLevel.replace(/EQF /g, "")) });
                    });

                    let cIscedFCode = [];
                    c.iscedFCode.map((item) => {
                        if (item.iscedFCode) {
                            cIscedFCode.push({ iscedFCode: item.iscedFCode, iscedClarification: item.iscedClarification });
                        }
                    });

                    let mobTypesFixed = {
                        totalMonths: 0,
                        totalDays: 0
                    };
                    if (mobType === "Student studies") {
                        mobTypesFixed.totalDays = 0;

                        if (c.totalMonths !== "") {
                            mobTypesFixed.totalMonths = (c.totalMonths !== "" ? parseFloat(c.totalMonths) : 0);
                        }
                        else {
                            mobTypesFixed.totalMonths = parseFloat(ccOrig[index].totalMonths);
                        }
                    }
                    else if (mobType === "Student traineeship") {
                        mobTypesFixed.totalDays = 0;
                        // cEqfLevels = [];

                        if (c.totalMonths !== "") {

                            mobTypesFixed.totalMonths = (c.totalMonths !== "" ? parseFloat(c.totalMonths) : 0);
                        }
                        else {
                            mobTypesFixed.totalMonths = parseFloat(ccOrig[index].totalMonths);
                        }
                    }
                    else if (mobType === "Staff teacher" || mobType === "Staff training") {
                        mobTypesFixed.totalMonths = 0;
                        cEqfLevels = [];

                        if (c.totalDays !== "") {

                            mobTypesFixed.totalDays = (c.totalDays !== "" ? parseFloat(c.totalDays) : 0);
                        }
                        else {
                            mobTypesFixed.totalDays = parseFloat(ccOrig[index].totalDays);
                        }
                    }

                    ccsTosend.push({
                        senderInstitution: (c.senderInstitution !== "" ? c.senderInstitution : ccOrig[index].senderInstitution),
                        receiverInstitution: (c.receiverInstitution !== "" ? c.receiverInstitution : ccOrig[index].receiverInstitution),
                        sendingOUnit: (c.sendingOUnit !== "") ? c.sendingOUnit : (!ccOrig[index].sendingOUnit.length ? "" : ccOrig[index].sendingOUnit[0].ounit_id),
                        receivingOUnit: (c.receivingOUnit !== "") ? c.receivingOUnit : (!ccOrig[index].receivingOUnit.length ? "" : ccOrig[index].receivingOUnit[0].ounit_id),
                        mobilitiesPerYear: (c.mobilitiesPerYear !== "" ? parseInt(c.mobilitiesPerYear) : parseInt(ccOrig[index].mobilitiesPerYear)),
                        mobilityType: mobType,
                        totalMonths: mobTypesFixed.totalMonths,
                        totalDays: mobTypesFixed.totalDays,
                        iscedFCodes: cIscedFCode,
                        eqfLevels: cEqfLevels,
                        languages: clanguages,
                        blended: (c.blended !== ccOrig[index].blended ? c.blended : ccOrig[index].blended),
                        otherInformation: (c.otherInformation !== "" && c.otherInformation != null) ? c.otherInformation : ccOrig[index].otherInformation
                    })
                })
                let origData = this.props.data.getIIA;
                let amICreator = (JSON.parse(localStorage.getItem("currentErasmusCodeNoSpaces")).erasmusCode === origData.creator_institution_code.replace(/\s+/g, '')) ? true : false;
                toSend = {
                    'author': localStorage.getItem("ErasmusCode"),
                    'conditionsIn': ccsTosend,
                    'creatorIn': {
                        website:{
                            general:(amICreator&& partnerGeneralWebsite!=="" && partnerGeneralWebsite!=null) ? partnerGeneralWebsite:origData.creatorGeneral,
                            course:(amICreator &&  partnerCourseWebsite!==""&& partnerCourseWebsite!=null)? partnerCourseWebsite:origData.creatorCourse,
                            faculty:(amICreator &&partnerFacultyWebsite!==""&&partnerFacultyWebsite!=null)? partnerFacultyWebsite:origData.creatorFaculty
                        },
                        name: ((creatorInstitution !== "" && creatorInstitution != null) ? creatorInstitution : origData.creator_institution_data.name),
                        erasmusCode: ((creatorErasmusCode !== "" && creatorErasmusCode != null) ? ((amICreator) ? GlobalVar.encryptWithKey(creatorErasmusCode).toString() : creatorErasmusCode) : ((amICreator) ? GlobalVar.encryptWithKey(origData.creator_institution_data.erasmusCode).toString() : origData.creator_institution_data.erasmusCode)),
                        country: ((creatorCountry !== "" && creatorCountry != null) ? creatorCountry : origData.creator_institution_data.country),
                        city: ((creatorCity !== "" && creatorCity != null) ? creatorCity : origData.creator_institution_data.city),
                        contact: {
                            name: ((amICreator && partnerContactName !== "" && partnerContactName != null) ? partnerContactName : (origData.creator_institution_data.contacts[0] != null) ? origData.creator_institution_data.contacts[0].name : ""),
                            email: ((amICreator && partnerContactEmail !== "" && partnerContactEmail != null) ? partnerContactEmail : (origData.creator_institution_data.contacts[0] != null) ? origData.creator_institution_data.contacts[0].email : ""),
                            phone: ((amICreator && partnerContactPhone !== "" && partnerContactPhone != null) ? partnerContactPhone : (origData.creator_institution_data.contacts[0] != null) ? origData.creator_institution_data.contacts[0].phone : ""),
                            website: ((amICreator && partnerContactWebsite !== "" && partnerContactWebsite != null) ? partnerContactWebsite : (origData.creator_institution_data.contacts[0] != null) ? origData.creator_institution_data.contacts[0].website : "")
                        },
                        ounit: ((amICreator && partnerOUnit != null && partnerOUnit !== "") ? partnerOUnit : (origData.creatorOU != null && origData.creatorOU.ounit_id !== "") ? origData.creatorOU.ounit_id : ""),
                    },
                    'partnerIn': {
                        website:{
                            general:(!amICreator && partnerGeneralWebsite!=="" && partnerGeneralWebsite!=null) ? partnerGeneralWebsite:origData.partnerGeneral,
                            course:(!amICreator &&  partnerCourseWebsite!==""&& partnerCourseWebsite!=null)? partnerCourseWebsite:origData.partnerCourse,
                            faculty:(!amICreator &&partnerFacultyWebsite!==""&&partnerFacultyWebsite!=null) ? partnerFacultyWebsite:origData.partnerFaculty
                        },
                        name: ((partnerInstitution !== "" && partnerInstitution != null) ? partnerInstitution : origData.partner_institution_data.name),
                        erasmusCode: ((partnerErasmusCode !== "" && partnerErasmusCode != null) ? ((!amICreator) ? GlobalVar.encryptWithKey(partnerErasmusCode).toString() : partnerErasmusCode) : ((!amICreator) ? GlobalVar.encryptWithKey(origData.partner_institution_data.erasmusCode).toString() : origData.partner_institution_data.erasmusCode)),
                        country: ((partnerCountry !== "" && partnerCountry != null) ? partnerCountry : origData.partner_institution_data.country),
                        city: ((partnerCity !== "" && partnerCity != null) ? partnerCity : origData.partner_institution_data.city),
                        contact: {
                            name: ((!amICreator && partnerContactName !== "" && partnerContactName != null) ? partnerContactName : (origData.partner_institution_data.contacts[0] != null) ? origData.partner_institution_data.contacts[0].name : ""),
                            email: ((!amICreator && partnerContactEmail !== "" && partnerContactEmail != null) ? partnerContactEmail : (origData.partner_institution_data.contacts[0] != null) ? origData.partner_institution_data.contacts[0].email : ""),
                            phone: ((!amICreator && partnerContactPhone !== "" && partnerContactPhone != null) ? partnerContactPhone : (origData.partner_institution_data.contacts[0] != null) ? origData.partner_institution_data.contacts[0].phone : ""),
                            website: ((!amICreator && partnerContactWebsite !== "" && partnerContactWebsite != null) ? partnerContactWebsite : (origData.partner_institution_data.contacts[0] != null) ? origData.partner_institution_data.contacts[0].website : "")
                        },
                        ounit: ((!amICreator && partnerOUnit != null && partnerOUnit !== "") ? partnerOUnit : (origData.partnerOU != null && origData.partnerOU.ounit_id !== "") ? origData.partnerOU.ounit_id : ""),
                    },
                    'iiaCode': origData.iia_code_p1,
                    'academicYearStartIn': ((academicYearStart !== "" && academicYearStart != null) ? academicYearStart : origData.academicYearStart),
                    'academicYearEndIn': ((academicYearEnd !== "" && academicYearEnd != null) ? academicYearEnd : origData.academicYearEnd),
                    'currentSign': GlobalVar.decryptWithKey(localStorage.getItem("Email")),
                }

                return this.props["UpdateIIA"]({
                    variables: toSend
                }).then(() => {
                    sessionStorage.removeItem("tcCheckboxEdit");
                    console.log("Updating IIA " + this.props.data.getIIA.iia_code_p1 + "...\nResults: " + JSON.stringify(this.props["UpdateIIA"]));
                    // alert("Updating IIA data...\n" + JSON.stringify(this.props["UpdateIIA"]));
                });
            });

            Promise.all(promiseSendIIA).then(() => {
                this.sendNotificationFunction(toSend);
                console.log("IIA " + this.props.data.getIIA.iia_code_p1 + " submited.");

            });
        }
    }
};

const updateIIA = gql`
    mutation updateIIA($author: String!, $conditionsIn: [CooperationInput]!, $creatorIn: InstituteInput!, $partnerIn:InstituteInput!, $iiaCode: String!, $academicYearStartIn: String!, $academicYearEndIn: String!, $currentSign: String!){
        updateIIAv2(author: $author, conditions: $conditionsIn, creator: $creatorIn, partner: $partnerIn, iiaCode: $iiaCode, academicYearStart: $academicYearStartIn, academicYearEnd: $academicYearEndIn, currentSign: $currentSign) {
            iia_code_p1
        }
    }
`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const getEWPInstitutionData = gql`
    mutation getEWPInstitutionData($CreatorErasmusCode: String!, $PartnerErasmusCode: String!) {
        getEWPInstitutionData(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode) {
            Success
            Message
            ContactName
            ContactEmail
            ContactPhone
            WebsiteGeneral
            WebsiteFaculty
            WebsiteCourse
            OUList {
                ounit_id
                name
                institution_ecode
                active
            }
        }
    }
`;

const listOU = gql`
    mutation listOU($ErasmusCodeIn: String!) {
        listOU(ErasmusCodeIn: $ErasmusCodeIn) {
            ounit_id
            institution_ecode
            abbreviation
            is_ewp_partner
            active
            lang
            name
        }
    }
`;

const getEWPOUnits = gql`
    mutation getEWPOUnits($ErasmusCode: String!, $clientHeiEC: String!) {
        getEWPOUnits(ErasmusCode: $ErasmusCode, clientHeiEC: $clientHeiEC) {
            Success
            OUList {
                ounit_id
                name
                institution_ecode
                active
            }
        }
    }
`;

const sendEWPIIAChangeNotification = gql`
    mutation sendEWPIIAChangeNotification($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $IIAID: String!, $Signed: Int) {
        sendEWPIIAChangeNotification(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, IIAID: $IIAID, Signed: $Signed) {
            Success
            Message
        }
    }
`;

export default compose(
    graphql(getIIA, {
        options: (props) => {
            return {
                variables: {
                    iiaCodeIn: props.match.params.iiaId
                }
            }
        }
    }),
    graphql(getLanguages, { name: "allLanguages" }),
    graphql(updateIIA, { name: "UpdateIIA" }),
    graphql(isIIAManagedByEWP, { name: "isIIAManagedByEWP" }),
    graphql(getEWPInstitutionData, { name: "getEWPInstitutionData" }),
    graphql(listOU, {name: "listOU"}),
    graphql(getEWPOUnits, { name: "getEWPOUnits" }),
    graphql(sendEWPIIAChangeNotification, { name: "sendEWPIIAChangeNotification" })
)(Edit);

