import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import intl from 'react-intl-universal';
import GlobalVar from '../../GlobalVar';

class NoAccess extends Component {

	render() {
        if(this.props.match.params.pageName){
            if(localStorage.getItem("unknownEmail")){
                return (
                    <div className="content">
                        <div className="card card_full_width card_full_height">
                            <h2 style={{"marginBottom": "10px"}}>You are not allowed to access the "{this.props.match.params.pageName}" page</h2>
                            <div className="inside_content">
                                <p>Dear Erasmus Dashboard user,</p>
                                <p>Unfortunately, this area is available only to the registered users.<br/>
                                Please <Link to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")} >register</Link> for the main institutional account of your Higher Education Institution or contact your institution to gain access on the platform.
                                For more information see <Link to={GlobalVar.generateLinkUrl(intl, "Support", "Tutorials")}>tutorial</Link> on 'Accounts' which explains the multi-user structure available on the ErasmusPlus Dashboard as well as our <Link to={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")}>FAQs</Link>.
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
        	return (
        		<div className="content">
        			<div className="card card_full_width card_full_height">
        				<h2 style={{"marginBottom": "10px"}}>You are not allowed to access the "{this.props.match.params.pageName}" page</h2>
        				<div className="inside_content">
        					<p>Dear Erasmus Dashboard user,</p>
                            <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
        				</div>
        			</div>
        		</div>
          	);
        } else {
            if(localStorage.getItem("unknownEmail")){
                return (
                    <div className="content">
                        <div className="card card_full_width card_full_height">
                            <h2 style={{"marginBottom": "10px"}}>You are not allowed to access the  page</h2>
                            <div className="inside_content">
                                <p>Dear Erasmus Dashboard user,</p>
                                <p>Unfortunately, this area is available only to the registered users.<br/>
                                Please <Link to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")} >register</Link> for the main institutional account of your Higher Education Institution or contact your institution to gain access on the platform.
                                For more information see <Link to={GlobalVar.generateLinkUrl(intl, "Support", "Tutorials")}>tutorial</Link> on 'Accounts' which explains the multi-user structure available on the ErasmusPlus Dashboard as well as our <Link to={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")}>FAQs</Link>.
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
        	return (
        		<div className="content">
        			<div className="card card_full_width card_full_height">
        				<h2>You are not allowed to access the page</h2>
        				<div className="inside_content">
        					<p>Dear Erasmus Dashboard user,</p>
                  <p>Your Higher Education Institution has not granted you access to view this page of the platform.</p>
        				</div>
        			</div>
        		</div>
          	);
        }
    }
};

export default NoAccess;
