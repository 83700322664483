import React, { Component } from 'react';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            focus: false
        }
        this.timeout =  0;
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event){
        this.setState({
            value: event.target.value
        })
    }

    stoppedTyping(){
        this.props.onSelectedSearch(this.state.value)
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            let filters = {};
            if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
                filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
            }
            filters["search"] = this.state.value;
            localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));

        }, 300);
    }


    generateSearchImg(){
        if(this.state.value !== ""){
            return <img src="/icons/search-focus.svg"/>;
        }
        return <img src="/icons/search.svg"/>;
    }



    render() {
        return (
        <div className="search_wrapper">
            {this.generateSearchImg()}

            <input
                    onKeyUp={() => this.stoppedTyping()}
                    value={this.state.value}
                    onChange={(event) => this.changeValue(event)}
                    onFocus={() => this.setState({focus: true})}
                    onBlur={() => this.setState({focus: false})}
                    type="text"
                    placeholder="Search"
                    id="filter-search"
                />

        </div>
    );
  }

};

export default Search;
