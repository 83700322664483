import React, { Component, useState } from 'react';


function CountryFilter(props) {

  const [value, setValue] = useState("");

	const changeSelection = (event) => {
    setValue(event.target.value);
    props.onSelectedCountry(event.target.value)
	}

	const renderCountry = () => {
    var unique = [...new Set(props.data.map(item => item.sending_institution_country)),...new Set(props.data.map(item => item.receiving_institution_country))]
    return [...new Set(unique)].sort().map((extractedName, index) => {
      if (extractedName !==null && extractedName !== "")
    return <option key={index} value={extractedName} >{extractedName}</option>;})
	}
		
  if(props.data.loading){
		return <select><option value="">Loading...</option></select>
	} else {
      return (
        <select id="filter-country" value={value} onChange={(event) => changeSelection(event)} >
          <option value={""}>Select country</option>
					{renderCountry()}
        </select>
      )
	}
}

export default CountryFilter;