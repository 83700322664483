import gql from 'graphql-tag';

const query = gql`
    query ($erasmusCode: String, $id: String,$uuid: String) {
        studentApplicationZip(uuid: $uuid){
            base64,
            error
        }
        incomingNomination (erasmusCode: $erasmusCode,id: $id) {
            field_student_name
        }
    }`

export { query };