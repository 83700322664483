import React, { useState } from 'react';

function Search(props) {
    
    const [value, setValue] = useState("");
    const [focus, setFocus] = useState(false);
    const [timeout, setTimeout] = useState(0);

    const changeValue = (event) => {
        setValue(event.target.value);
    }

    const stoppedTyping = () => {
        props.onSelectedSearch(value)
        if(timeout) clearTimeout(timeout);
        setTimeout(() => setTimeout(() => {
            let filters = {};
            if(localStorage.getItem("filters"+props.typeOfStudentName) != null){
                filters = JSON.parse(localStorage.getItem("filters"+props.typeOfStudentName));
            }
            filters["search"] = value;
            localStorage.setItem("filters"+props.typeOfStudentName, JSON.stringify(filters));

        }, 300));
    }


    const generateSearchImg = () => {
        if(value !== ""){
            return <img src="/icons/search-focus.svg"/>;
        }
        return <img src="/icons/search.svg"/>;
    }


    return (
        <div className="search_wrapper">
            {generateSearchImg()}

            <input
                onKeyUp={() => stoppedTyping()}
                value={value}
                onChange={(event) => changeValue(event)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                type="text"
                placeholder="Search"
                id="filter-search" />

        </div>
    );
}

export default Search;