import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query } from '../../../queries/getInstitution';
import GlobalVar from '../../../GlobalVar';
import { flowRight as compose } from 'lodash';

import validator from 'validator';

class TextInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			error: false,
			name : "",
			title: ""

		}
	}
	componentWillMount() {
		this.setState({
			value: this.props.value ,
			name:this.props.name
		})
	}
	changeValue(event){
		this.setState({
			value : event.target.value
		})
	}
	safeValue(){
		if(this.validate()){
			this.props[this.props.action]({
				variables: {
					"ErasmusCode": localStorage.getItem('ErasmusCode'),
	  				"Text": this.state.value
				},
			refetchQueries: [{query}]
		    })	
        }	
	}
	validate(){
	 	if(this.state.value !== ""){
			if(this.props.name === "Contact email"){
			 	if(!validator.isEmail(this.state.value)){
			 		this.setState({error: true, title: "Wrong email format"})
					return false;
			 	}
			} 	
			if(this.props.name === "Website"){
				if(!validator.isURL(this.state.value)){
			 		this.setState({error: true, title: "Wrong website input"})
					return false;
			 	}
			}
			if(this.props.name === "Course catalogues"){
				if(!validator.isURL(this.state.value)){
			 		this.setState({error: true, title: "Wrong Course catalogue input"})
					return false;	
			 	}
			}
			if(this.props.name === "Contact phone"){
                ///^\+?\d*$/
				if(!this.state.value.match(/^(?=.*[0-9])[- +()0-9]+$/)){
			 		this.setState({error: true, title: "Wrong Contact phone input"})
					return false;	
			 	}
			}
		}
		this.setState({
			error: false,
			title: "" 
		})
		return true;
	}
	generateLabel(){ 
		if(this.state.error){
			return <label className="error">{this.state.title}</label>
		}
		else {
			return <label className="active">{intl.get(this.props.name)}</label>
		}
	}

	renderReadValue(value){
		if(value === undefined || value === "" || value === null){
    		return <p className="italic">Not given</p>;
    	}
    	if(validator.isEmail(value)){
    		return <p><a className="black_link" href={"mailto:"+value} >{value}</a></p>;
    	}
    	if(validator.isURL(value)){
    		return <p><a className="black_link" target="_blank" href={value} >{value}</a></p>;
    	}
    	return <p>{value}</p>;
	}

	render() {
		if(GlobalVar.checkAccountFunction("editingGeneralInfo")){
	    	return (
	    		<li>
	    			<input onChange={(event) => this.changeValue(event)} placeholder={intl.get('Fill in')} type="text" value={this.state.value} onBlur={() => this.safeValue()}></input>
	    			{this.generateLabel()}
	    		</li>
	      	);
    	}

    	return (
    		<li>
    			{this.renderReadValue(this.props.value)}
    			<label className = "active" >{intl.get(this.props.name)}</label>
    		</li>
      	);
    }

};

const mutationSHAC = gql`
	mutation UpdateSHAC($ErasmusCode: String, $Text: String){
		updateSHAC(ErasmusCode: $ErasmusCode, Text: $Text){
			ErasmusCode
		}
	}
`;

const mutationWebsite = gql`
	mutation UpdateWebsite($ErasmusCode: String, $Text: String){
	  updateWebsite(ErasmusCode: $ErasmusCode, Text: $Text){
	    ErasmusCode
	  }
	}
`;

const mutationCourseCatalogue = gql`
	mutation UpdateCourseCatalogue($ErasmusCode: String, $Text: String){
	  updateCourseCatalogue(ErasmusCode: $ErasmusCode, Text: $Text){
	    ErasmusCode
	  }
	}
`;

const mutationEmail = gql`
	mutation UpdateEmail($ErasmusCode: String, $Text: String){
	  updateEmail(ErasmusCode: $ErasmusCode, Text: $Text){
	    ErasmusCode
	  }
	}
`;

const mutationPhone = gql`
	mutation UpdatePhone($ErasmusCode: String, $Text: String){
	  updatePhone(ErasmusCode: $ErasmusCode, Text: $Text){
	    ErasmusCode
	  }
	}
`;

export default compose(
	graphql(mutationSHAC, { name: 'UpdateSHAC'}),
	graphql(mutationWebsite, { name: 'UpdateWebsite'}),
	graphql(mutationCourseCatalogue, { name: 'UpdateCourseCatalogue'}),
	graphql(mutationEmail, { name: 'UpdateEmail'}),
	graphql(mutationPhone,{ name : 'UpdatePhone'})
)(TextInput);