import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { query } from '../../../queries/getInstitution';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import TextInput from './TextInput';
import gql from 'graphql-tag';
import GlobalVar from '../../../GlobalVar';

import moment from 'moment';
import Phone from 'react-phone-number-input';


class GeneralInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			description: "",
			phoneNumber : "",
			phone : ""
		}
	}
	componentWillReceiveProps(nextProps) {
		if(!nextProps.data.loading && nextProps.data.institution != null){
			if(nextProps.data.institution.InstitutionDetail !== null && nextProps.data.institution.InstitutionDetail !== undefined){
				this.setState({
					description: nextProps.data.institution.InstitutionDetail.Description
				})
			}
		}
	}
	changeValue(event){
		this.setState({
			description: event.target.value,
		})
	}
	safeValue(){
		this.props.UpdateDescription({
			variables: {
				"ErasmusCode": localStorage.getItem('ErasmusCode'),
  				"Text": this.state.description
			},
			refetchQueries: [{query}]
		})
	}
	generateValue(dataSource, dataName){
		if(dataSource === null){
			return "";
		}
		 else {
			return dataSource[dataName];
		}
	}
	makingDescriptionFunctionDepedent(){
		if(GlobalVar.checkAccountFunction("editingGeneralInfo")){
			return <textarea onBlur={() => this.safeValue()} onChange={(event) => this.changeValue(event)} value={this.state.description} placeholder={intl.get('Fill in')} ></textarea>;
		}
		if(this.state.description === undefined || this.state.description === ""){
			return <p className="italic">Not given</p>;
		}
		return <p>{this.state.description}</p>;
	}

	findValue(value) {
        if(value == null || value === "" || value.includes("null") || value.includes("Unknown")){
            return <p className="italic">Unknown</p>;
        }
        return value;
    }

	render() {
		if(this.props.data.loading) { 
			return <div>Loading...</div> 
		} else if(this.props.data.institution) {
			const {NameInLocalCharacterSet, NameInLatinCharacterSet, PIC, ErasmusCode, BrokenErasmusCodes, EndDate, City, Country, InstitutionDetail} = this.props.data.institution;
			let currentErasmusCode = ({ erasmusCode: ErasmusCode });
			localStorage.setItem("currentErasmusCode", JSON.stringify(currentErasmusCode));
			let currentErasmusCodeNoSpaces = ({ erasmusCode: ErasmusCode.split(' ').join('') });
			localStorage.setItem("currentErasmusCodeNoSpaces", JSON.stringify(currentErasmusCodeNoSpaces));
	    	return (
	    		<div className="content generalInfo">
		    		<div className="card card_full_width">
		    			<h2>{NameInLatinCharacterSet}</h2>
		    			<div className="sub_content">
		    				<h3>{intl.get('Fixed data')}</h3>
		    				<ul className="info_list">
		    					<li><span className="bold">{intl.get('Country')}:</span> {Country}</li>
		    					<li><span className="bold">{intl.get('City')}:</span> {City}</li>
		    					<li><span className="bold">{intl.get('Name in local character set')}:</span> {NameInLocalCharacterSet}</li>
		    					<li><span className="bold">{intl.get('Name in Latin character set')}:</span> {NameInLatinCharacterSet}</li>
		    					<li><span className="bold">{intl.get('PIC')}:</span> {PIC ? PIC.value : "Not specified"}</li>
		    					<li><span className="bold">{intl.get('Erasmus code')}:</span> {ErasmusCode}</li>
		    				</ul>
		    			</div>
		    			<div className="sub_content">
		    				<h3>{intl.get('Description')}</h3>
		    				{this.makingDescriptionFunctionDepedent()}

		    			</div>
		    			<div className="sub_content">
		    				<h3>{intl.get('Other general data')}</h3>
		    				<ul className="form_list">
								{/* <TextInput className="TextInput" action="UpdateSHAC" value={this.generateValue(InstitutionDetail, "SHAC")} name="SHAC code" /> */}
		    					<TextInput className="TextInput" action="UpdateWebsite" value={this.generateValue(InstitutionDetail, "Website")} name="Website" />
		    					<TextInput action="UpdateCourseCatalogue" value={this.generateValue(InstitutionDetail, "CourseCatalogue")} name="Course catalogues" />
		    					<TextInput action="UpdateEmail" value={this.generateValue(InstitutionDetail, "Email")} name="Contact email" />
		    					<TextInput action="UpdatePhone" value={this.generateValue(InstitutionDetail, "Phone")} name="Contact phone" />
							</ul>
		    			</div>
		    		</div>
	    		</div>
	      	);
      	} else {
      		const style = {"marginLeft": "10px", "marginTop": "12px"};
	    	return (
	    		<div className="content generalInfo">
	    			<div className="card card_full_width">
	    				<h2>Error loading institution data. Please logout and try again</h2>
	    				<p style={style}>If the problem persists, please contact the <a 
href="https://ewx.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">ESCI Service Desk</a></p>
	    			</div>
	    		</div>
    		);
	    }
    }
};

const mutation = gql`
	mutation UpdateDescription($ErasmusCode: String, $Text: String){
	  updateDescription(ErasmusCode: $ErasmusCode, Text: $Text){
	    ErasmusCode
	  }
	}
`;


export default compose(
	graphql(query),
	graphql(mutation, { name: 'UpdateDescription'})
)(GeneralInfo);
