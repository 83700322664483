import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {query as getNominations} from '../../../queries/getIncomingNominations';
import { graphql, useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import NominationFilter from './Filter';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from './../../../GlobalVar';


function NominationData(props) {
  // getData = getData.bind(this);
  let [optionalFiltersData, setOptionalFiltersData] = useState({});
    let [nameColumn,setNameColumn] = useState(true);
	  const handleOnChangeName = () => {
		setNameColumn(!nameColumn);
	  };
    let [emailColumn,setEmailColumn] = useState(false);
	  const handleOnChangeEmail = () => {
		setEmailColumn(!emailColumn);
	  };
    let [eqfColumn,setEqfColumn] = useState(false);
	  const handleOnChangeEqf = () => {
		setEqfColumn(!eqfColumn);
	  };
    let [iscedColumn,setIscedColumn] = useState(false);
	  const handleOnChangeIsced = () => {
		setIscedColumn(!iscedColumn);
	  };
    let [academicTermColumn,setAcademicTermColumn] = useState(true);
	  const handleOnChangeAcademicTerm = () => {
		setAcademicTermColumn(!academicTermColumn);
	  };
    let [academicYearColumn,setAcademicYearColumn] = useState(true);
	  const handleOnChangeAcademicYear = () => {
		setAcademicYearColumn(!academicYearColumn);
	  };
    let [statusColumn,setStatusColumn] = useState(true);
	  const handleOnChangeStatus = () => {
		setStatusColumn(!statusColumn);
	  };
    const [page, setPage] = useState(0);
    const [statusValue,setStatusValue] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const [CreatedByValue,setCreatedByValue] = useState("");
    const [academicYearValue,setAcademicYearValue] = useState("");
    const [heiValue,setHeiValue] = useState("");
    const [countryValue,setCountryValue] = useState("");
    const [sortName,setSortName] = useState("");
    const [getSortName,setGetSortName] = useState("");
    const [sortLastName,setSortLastName] = useState("");
    const [sortStatus,setSortStatus] = useState("");
    const [sortInstitution,setSortInstitution] = useState("");
    const [sortStartMobility,setSortStartMobility] = useState("");
    const [sortEndMobility,setSortEndMobility] = useState("");
    let [sortBy, setSortBy] = useState({
        "sortFirstName": "",
        "sortLastName": "",
        "sortStartMobility": "",
        "sortEndMobility": "",
        "sortStatus": "",
        "sortInstitution": ""
    });
    let [hasNext, setHasNext] = useState(true);
    let [loadingState, setLoadingState] = useState(true);
    const { firstYear, secondYear } = useParams();
    const history = useHistory();

    const renderHeader = () => {
        const institutionType = (props.typeOfStudent === 1) ? "Receiving Institution" : "Sending Institution";
        const institutionClass = (props.typeOfStudent === 1) ? "receivingInstitution" : "sendingInstitution";

        const headerColumns = [
            <div
              style={{display:"flex"}}
              onClick={() => {
                  if (getSortName === "" || sortName === "desc"){
                      setSortName("asc");
                      //setSortStartMobility("");
                      //setSortEndMobility("");
                      // setSortLastName("");
                      setSortStatus("");
                      //setSortInstitution("");
                  }

                  if(sortName === "asc"){
                      setSortName("desc");
                  }
              }}>
                <div>Name</div>
                <div>
                    {(sortName === 'asc') ?
                      (<img className="arrow" src="/icons/downArrow-blue.svg" />) :
                      ((sortName==="desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />):(<div/>))}
                </div>
            </div>,

            <div
              style={{display:"flex"}}
              onClick={() => {
                  if (sortStatus === "" || sortStatus === "desc"){
                      setSortStatus("asc");
                      //setSortStartMobility("");
                      //setSortEndMobility("");
                      setSortName("");
                      //setSortInstitution("");
                  }

                  if(sortStatus === "asc") {
                      setSortStatus("desc");
                  }
              }}>
                <div>Status</div>
                <div>
                    {(sortStatus === 'asc') ?
                      (<img className="arrow" src="/icons/downArrow-blue.svg" />) :
                      ((sortStatus === "desc") ? (<img className="arrow" src="/icons/upArrow-blue.svg" />) : (<div/>))
                    }
                </div>
            </div>]

        const renderedHeader = headerColumns.map((column, index) => (
          <th className={["th_student column media_links"].join(" ")}
            //(column === headerColumns[headerColumns.length-1] ? institutionClass : null),
            //(column === headerColumns[headerColumns.length-2]  ? "status" : null)].join(" ")}
              key={index} role="columnheader">
              <h3>{column}</h3>
          </th>
        ));
        return (
          renderedHeader
        );
    }

    const renderRows = (incomingNominations) => {
        if (incomingNominations[0]) {
            return incomingNominations.map(nomination =>

				          <tr className={"tr_student row"} role="row">
		  {nameColumn &&
            <td className={"td_student column bold"} role="cell">
                  <p>{nomination.field_student_name}</p>
		  </td>}
		  {emailColumn &&
			<td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_email}</p>
            </td>}
		  {eqfColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_eqf}</p>
            </td>}
		  {iscedColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_student_isced}</p>
            </td>}
		  {academicYearColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_academic_year}</p>
            </td>}
		  {academicTermColumn &&
            <td className={"td_student column"} role="cell">
                  <p>{nomination.field_academic_term}</p>
            </td>}
		  {statusColumn &&
        <td className={"td_student column"} role="cell">
        {nomination.field_nomination_status === "rejected_by_student" &&
				<p className="capitalize">Rejected by student</p>}
				{nomination.field_nomination_status === "accepted_by_student" &&
				<p className="capitalize">Accepted by student</p>}
				{nomination.field_nomination_status === "submitted" &&
				<p className="capitalize">Submitted</p>}
				{nomination.field_nomination_status === "rejected_by_receiving" &&
				<p className="capitalize">Rejected by receiving</p>}
				{nomination.field_nomination_status === "accepted_by_receiving" &&
				<p className="capitalize">Accepted by receiving</p>}
				{nomination.field_nomination_status === "rejected_by_sending" &&
				<p className="capitalize">Rejected by sending</p>}
				{nomination.field_nomination_status === "accepted_by_sending" &&
				<p className="capitalize">Accepted by sending</p>}
				{nomination.field_nomination_status === "waiting_list" &&
				<p className="capitalize">Waiting list</p>}
				{nomination.field_nomination_status === "cancelled" &&
				<p className="capitalize">Cancelled</p>}
            </td>}
            <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" to={{pathname:`/incoming-student/application/${nomination.id}`}}>
                    Open Application
                  </Link>
              </p>
            </td>
		{nomination.hasFiles ? (
			 <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" to={{pathname:`/outgoing-student/exportzip/${nomination.id}`}}>
                    Export documents
                  </Link>
              </p>
            </td>
			) : (
			 <td className={"td_student column"} role="cell">
              <p>
                  <Link className="button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}}>
                    Export documents
                  </Link>
              </p>
            </td>	
			)
		}	
          </tr>
            );

        }
        return <p>No results</p>;
    }

    const sortNameRows = (rows, property) => {
        if (sortName === 'asc' || sortName === '') {
            rows.sort(function (a, b) {
                if (a[property] !== null && b[property] !== null) {
                    return a[property].localeCompare(b[property]);
                }
            });
        } else if (sortName === 'desc') {
            rows.sort(function (a, b) {
                if (a[property] !== null && b[property] !== null) {
                    return b[property].localeCompare(a[property]);
                }
            });
        }
        return rows
    }

    const sortStatusRows = (rows, property) => {
        if (sortStatus === 'asc' || sortStatus === '') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                    return a[property].localeCompare(b[property]);}
            });
        } else if (sortStatus === 'desc') {
            rows.sort(function(a,b){
                if (a[property] !== null && b[property] !== null) {
                    return b[property].localeCompare(a[property]);}
            });
        }
        return rows
    }

  const { data, status, loading, error, fetchMore, refetch } = useQuery(
    getNominations, {
      keepPreviousData: true,
      variables: {
        erasmusCode: localStorage.getItem("ErasmusCode"),
        //typeOfStudent: props.typeOfStudent,
        page: page,
        optionalFilters: optionalFiltersData
      }
    });

  function getData(val){
    // do not forget to bind getData in constructor
    setOptionalFiltersData(val)
    setPage(0)
    console.log("optional filters value changed")
    console.log(JSON.stringify(optionalFiltersData));
    refetch({
      erasmusCode: localStorage.getItem("ErasmusCode"),
      //typeOfStudent: props.typeOfStudent,
      page: 0,
      optionalFilters: optionalFiltersData
    })
  }

    /*****************************/
    /******** RENDER PART ********/
    /*****************************/

    if (error) console.log(error);


    /*****************************/
    /****** END RENDER PART ******/
    /*****************************/


    /*****************************/
    /******** RENDER PART ********/
    /*****************************/


    const header = renderHeader();
    let rows = data ? data.incomingNominations.nominationList: null;
    if (rows !== undefined && rows !== null) {
        rows = Array.prototype.slice.call(rows);
        if (sortName) {
            rows = sortNameRows(rows, "field_student_name");
        }

        if (sortStatus) {
            rows = sortStatusRows(rows, "field_mobility_details.field_mobility_details_status");
        }

    }


    return (
      <div className={"content student_list"}>
          <div className="division_25">
              <div className="card card_full_width card_full_height">
                  <div className="card">
                    <NominationFilter sendData={getData}/>
						  <div className="sub_content">
							{/*<div className="row">*/}
							{/*	<div className="column_long" id=""><button onClick={() => refetch()}>Search</button></div>*/}
							{/*</div>*/}
						  </div>
                  </div>
                  <div className="card">
                      <div className="sub_content">
                          <h2 style={{ color: '#4a90e2' }}>Columns</h2>
							<table>
								<tr>
                                    <td>
										Email
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Email" checked={emailColumn} onChange={handleOnChangeEmail} />
                                    </td>
								</tr>
								<tr>
                                    <td>
										EQF
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Eqf" checked={eqfColumn} onChange={handleOnChangeEqf} />
                                    </td>
								</tr>
                                <tr>
                                    <td>
										ISCED
                                    </td>
                                    <td>
                                        <input type="checkbox" id="Isced" checked={iscedColumn} onChange={handleOnChangeIsced} />
                                    </td>
								</tr>
								<tr>
                                    <td>
										Academic Year
                                    </td>
                                    <td>
                                        <input type="checkbox" id="AcademicYear" checked={academicYearColumn} onChange={handleOnChangeAcademicYear} />
                                    </td>
								</tr>
                                <tr>
                                    <td>
										Academic Term
                                    </td>
                                    <td>
                                        <input type="checkbox" id="AcademicTerm" checked={academicTermColumn} onChange={handleOnChangeAcademicTerm} />
                                    </td>
								</tr>
							</table>
                      </div>
                  </div>
              </div>
          </div>
        {!loading
          ?
          <div className="card card_full_width card_full_height">
              <table className={"table1_student custom_table"} role="table">
                  <thead className={"thead_student"} role="rowgroup">
                  <tr className={"tr_student row"} role="row">
						  {nameColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>Name</h3>
						  </th>}
						  {emailColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>Email</h3>
							</th>}
						  {eqfColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>EQF</h3>
							</th>}
						  {iscedColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>ISCED</h3>
							</th>}
						  {academicYearColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>Academic Year</h3>
							</th>}
						  {academicTermColumn &&
							<th className={"th_student column"} role="cell">
								  <h3>Academic Term</h3>
							</th>}
						  {statusColumn &&
							<th className={"th_student column"} role="cell">
							  <h3>Status</h3>
							</th>}
                  </tr>
                  </thead>
                  <tbody className={"tbody_student"} role="rowgroup">
                  {renderRows(rows)}
				  <tr>
				  </tr>
				  <tr>
					<button className={"nominationPage_button"}
						onClick={() => setPage((old) => Math.max(old - 1, 0))}
						disabled={page === 0}
					  >
						  Previous page
					  </button>
					  <span>    </span>
					  <button className={"nominationPage_button"}
						onClick={() => setPage(page + 1)}
						disabled={data.incomingNominations.nextLink === ""}
					  >
						  Next page
					  </button>
					  <span>  Current Page: {page+1} </span>
				  </tr>
                  </tbody>

              </table>
          </div>
        :
        <div className="white_background">
          <Loader
            className="spinner"
            type="TailSpin"
            color="#3986df"
            height={70}
            width={70}/>
        </div>
        }
      </div>
    )
    /*****************************/
    /****** END RENDER PART ******/
    /*****************************/
}

export default NominationData;