import React, { Component } from 'react';
import Search from './Search'


class Options extends Component {

    render() {
        return (
            <div className="card card_full_width card_full_height">
                {/*<h4 style className={"row"} style={{color: "#4A90E2", marginLeft: "10px"}}>Select a filter for the table</h4>*/}
                <h4> {this.props.title}</h4>
                <br/>
                <div className="option_wrapper">
                    <h4 style={{margin:"7px"}}>Contact Email</h4>
                    <Search
                        setValue={(event) => {
                            this.props.changeContactEmail(event)
                        }}
                    />

                </div>
                <br/>
                <div className="option_wrapper">
                    <h4 style={{margin:"7px"}}>Institution Name</h4>
                    <Search
                        setValue={(event) =>  this.props.changeInstitutionName(event)}
                    />
                </div>
                <br/>
                <div className=" card_center">
                    <button onClick={()=>this.props.onChange()}>Appy</button>
                </div>
            </div>
        );
    }

};
export default Options;
