import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../../../queries/getUsefulInfo';
import UsefulInfoRow from './UsefulInfoRow';

class UsefulInfoTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key : ""
		}
    }


	generateList(){

		return this.props.data.map((usefulInfoLists,key) => {
			return <UsefulInfoRow key ={key} usefulInfoLists={usefulInfoLists}/>;

		})
	}

	render() {
		if(this.props.data.length > 0){
	    	return (
	    		<table>
	    			<thead>
		    			<tr>
		    				<th>Title</th>
		    				<th>Action</th>
							<th></th>
		    			</tr>
	    			</thead>
	    			<tbody>
	    				{this.generateList()}
	    			</tbody>
	    		</table> 
	      	);
      	}
      	else{
			return <table></table>;
      	}
    }
};



export default UsefulInfoTable;
