import React, { Component } from "react";

class Status extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }

        this.changeSelection = this.changeSelection.bind(this);
    }

    changeSelection(event) {
        this.setState({ value: event.target.value });
        this.props.handlerChange(event, "status");
    }

    render() {
        return (
            <select name="iia-status-filter" id="iia-status-filter" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                <option value="">Select status</option>
                <option value="changed_by_creator">Created or edited by you</option>
                <option value="signed_by_creator">Approved by you</option>
                <option value="changed_by_partner">Created or edited by partner</option>
                <option value="signed_by_partner">Approved by partner</option>
                <option value="signed_by_both">Approved by both parties</option>
            </select>
        );
    }
}

export default Status;