import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import moment from 'moment';
import Phone from 'react-phone-number-input';

import GlobalVar from '../../../GlobalVar';

import { query as getLanguages } from '../../../queries/getLanguages';

class OUnits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newLanguage: "",
			newOU: "",
			newAbb: "", 
			isEditOpen: [],
			editLanguage: [],
			editOU: [],
			editAbb: []
		}

		this.languages = [];

		this.togglePopup = this.togglePopup.bind(this);
		this.editValue = this.editValue.bind(this);
	}

	changeLanguage(event) {
		this.setState({
			newLanguage: event.target.value
		});
	}

	changeValue(event){
		this.setState({
			newOU: event.target.value
		});
	}

	changeAbbreviation(event) {
		this.setState({
			newAbb: event.target.value
		});
	}

	handleEditLang(event, row) {
		let newLang = this.state.editLanguage;
		newLang[row] = event.target.value;

		this.setState({ newLanguage: newLang });
	}

	handleEditOUnit(event, row){
		let newOUnit = this.state.editOU;
		newOUnit[row] = event.target.value;

		this.setState({ editOU: newOUnit });
	}

	handleEditAbbreviation(event, row) {
		let newAbb = this.state.editAbb;
		newAbb[row] = event.target.value;

		this.setState({ editAbb: newAbb });
	}

	generateLanguagesList() {
        if (!this.props.allLanguages.loading && !this.props.allLanguages.error) {
            this.languages.push("Most commonly spoken European languages - 00");

            GlobalVar.mostSpokenLanguages.map((data) => {
                this.languages.push(data.Name + " - " + data.Code);
            });

            this.languages.push("Other Languages - 00");

            this.props.allLanguages.allLanguages.map((data) => {
                if (!this.languages.includes(data.Name + " - " + data.Code)) {
                    this.languages.push(data.Name + " - " + data.Code);
                }
            });
        }

        // return languages;
    }

	addValue(){
		console.log("addValue");
		if (this.state.newOU != "") {
			this.props.AddOU({
				variables: {
					"ErasmusCode": localStorage.getItem("ErasmusCode"),
					"lang": this.state.newLanguage.split(' - ')[1].toLowerCase(),
					"ouName": this.state.newOU,
					"abbreviation": this.state.newAbb
				},
				refetchQueries: [{
					query: getOUList, variables: {ErasmusCodeIn: localStorage.getItem("ErasmusCode")}
				}],
				awaitRefetchQueries: true
			}).then((x) => {
				console.log("addValue then");
				if (this.props.data.error) {
					console.log("addValue error");
					alert("Error submiting Organizational Unit");
				} else {
					console.log("addValue else");
					if (x.data.addOU.Success) {
						console.log("addValue success");
						this.setState({ newLanguage: "", newOU: "", newAbb: "" });
						alert("Successfully submitted Organizational Unit");
					} else {
						alert("Error response on submiting Organizational Unit");
					}
				}
			});
			this.state.newOU = "";
		}
	}

	editValue(id, row) {
		this.props.UpdateOU({
			variables: {
				"ErasmusCode": localStorage.getItem("ErasmusCode"),
				"OUid": id,
				"lang": (this.state.editLanguage[row] !== "") ? this.state.editLanguage[row].split(' - ')[1].toLowerCase() : (this.props.data.listOU[row].lang !== "") ? this.props.data.listOU[row].lang : "",
				"ouName": (this.state.editOU[row] !== "") ? this.state.editOU[row] : (this.props.data.listOU[row].name !== "") ? this.props.data.listOU[row].name : "",
				"abbreviation": (this.state.editAbb[row] !== "") ? this.state.editAbb[row] : (this.props.data.listOU[row].abbreviation !== "") ? this.props.data.listOU[row].abbreviation : ""
			},
			refetchQueries:[{
				query: getOUList, 
				variables: {
					ErasmusCodeIn: localStorage.getItem("ErasmusCode"),
					OUid: id
				}
			}],
			awaitRefetchQueries: true
		})
		.then((response) => {
			if (this.props.data.error) {
				alert("Error updating Organizational Unit.");
			}
			else {
				if (response.data.updateOU.Success) {
					alert("Successfully updated Organizational Unit.");
				}
				else {
					alert("Error response on updating Organizational Unit.");
				}
			}
		});
	}

	togglePopup(row) {
		let updateIsEditOpen = this.state.isEditOpen;
		updateIsEditOpen[row] = !this.state.isEditOpen[row];
		
		this.setState({ isEditOpen: updateIsEditOpen });
	}

	removeValue(id){
		this.props.RemoveOU({
			variables: {
				"ErasmusCode": localStorage.getItem("ErasmusCode"),
				"OUid": id
			},
			refetchQueries: [{
				query: getOUList, variables: {
					ErasmusCodeIn: localStorage.getItem("ErasmusCode"),
					OUid: id
				}
			}],
			awaitRefetchQueries: true
		}).then((x) => {
			if (this.props.data.error) {
				alert("Error deleting Organizational Unit");
			} else {
				if (x.data.removeOU.Success) {
					alert("Successfully deleted Organizational Unit");
				} else {
					alert("Error response on deleting Organizational Unit");
				}
			}
		});
	}

	actValue(id){
		this.props.ActivateOU({
			variables: {
				"ErasmusCode": localStorage.getItem("ErasmusCode"),
				"OUid": id
			},
			refetchQueries: [{
				query: getOUList, variables: {
					ErasmusCodeIn: localStorage.getItem("ErasmusCode"),
					OUid: id
				}
			}],
			awaitRefetchQueries: true
		}).then((x) => {
			if (this.props.data.error) {
				alert("Error activating Organizational Unit");
			} else {
				if (x.data.activateOU.Success) {
					alert("Successfully activated Organizational Unit");
				} else {
					alert("Error response on activating Organizational Unit");
				}
			}
		});
	}

	deactValue(id){
		this.props.DeactivateOU({
			variables: {
				"ErasmusCode": localStorage.getItem("ErasmusCode"),
				"OUid": id
			},
			refetchQueries: [{
				query: getOUList, variables: {
					ErasmusCodeIn: localStorage.getItem("ErasmusCode"),
					OUid: id
				}
			}],
			awaitRefetchQueries: true
		}).then((x) => {
			if (this.props.data.error) {
				alert("Error deactivating Organizational Unit");
			} else {
				if (x.data.deactivateOU.Success) {
					alert("Successfully deactivated Organizational Unit");
				} else {
					alert("Error response on deactivating Organizational Unit");
				}
			}
		});
	}


	renderRows(data) {
		let stringHTML = ''
		if (data == undefined) {
			return (
			<div className="row"><h3>No data available</h3></div>
			);
		}

		return data.map((OU, index) => {
			let lang = this.languages.find((language) => { 
				return (OU.lang) ? language.includes(OU.lang.toUpperCase()) : ""; 
			});

			if (OU.active) {
				if (index >= this.state.isEditOpen.length) { 
					this.state.isEditOpen[index] = false;
					this.state.editLanguage[index] = "";
					this.state.editOU[index] = "";
					this.state.editAbb[index] = "";
				}
				return (
					<>
						<div className="row" key={index}>
							<div className="column"><p>{lang}</p></div>
							<div className="column"><p>{OU.name}</p></div>
							<div className="column"><p>{OU.abbreviation}</p></div>
							{/* <div className="column">{OU.ounit_id}</div> */}
							<div className="column">
								<button onClick={() => this.deactValue(OU.ounit_id)}>Deactivate</button>
								&nbsp;
								<button onClick={() => this.togglePopup(index)}>Edit</button>
								&nbsp;
								<button onClick={() => this.removeValue(OU.ounit_id)}>Remove</button>
							</div>
						</div>
						{this.state.isEditOpen[index] &&
							<div className="row" key={"edit" + index}>
								<div className="column">
									<select name={"language"} id={"language"} value={this.state.editLanguage[index]} onChange={(event) => this.handleEditLang(event, index)}>
										<option key="lang" value="">(OUnit name language)</option>
										{this.languages.map((value, index) => {
											if (value.includes("00")) {
												return <option key={"lang" + index} value={`${value}`} disabled>{`${value}`}</option>
											}
											return <option key={"lang" + index} value={`${value}`}>{`${value}`}</option>
										})}
									</select>
								</div>
								<div className="column"><input type="text" onChange={(event) => this.handleEditOUnit(event, index)} value={this.state.editOU[index]} placeholder="Organizational Unit Name" /></div>
								<div className="column"><input type="text" onChange={(event) => this.handleEditAbbreviation(event, index)} value={this.state.editAbb[index]} placeholder="Organizational Unit Abbreviation" /></div>
								<button onClick={() => this.editValue(OU.ounit_id, index)}>Update</button>
							</div>
						}
						{ index < data.length - 1  ? <hr/> : <></>}
					</>
				);
			} else {
				if (index >= this.state.isEditOpen.length) { 
					this.state.isEditOpen[index] = false; 
					this.state.editLanguage[index] = "";
					this.state.editOU[index] = "";
					this.state.editAbb[index] = "";
				}
				return (
					<>
						<div className="row" key={index}>
							<div className="column"><p>{lang}</p></div>
							<div className="column"><p>{OU.name}</p></div>
							<div className="column"><p>{OU.abbreviation}</p></div>
							{/* <div className="column">{OU.ounit_id}</div> */}
							<div className="column">
								<button onClick={() => this.actValue(OU.ounit_id)}>Activate</button>
								&nbsp;
								<button onClick={() => this.togglePopup(index)}>Edit</button>
								&nbsp;
								<button onClick={() => this.removeValue(OU.ounit_id)}>Remove</button>
							</div>
						</div>
						{this.state.isEditOpen[index] &&
							<div className="row" key={"edit" + index}>
								<div className="column">
									<select name={"language"} id={"language"} value={this.state.editLanguage[index]} onChange={(event) => this.handleEditLang(event, index)}>
										<option key="lang" value="">(OUnit name language)</option>
										{this.languages.map((value, index) => {
											if (value.includes("00")) {
												return <option key={"lang" + index} value={`${value}`} disabled>{`${value}`}</option>
											}
											return <option key={"lang" + index} value={`${value}`}>{`${value}`}</option>
										})}
									</select>
								</div>
								<div className="column"><input type="text" onChange={(event) => this.handleEditOUnit(event, index)} value={this.state.editOU[index]} placeholder="Organizational Unit Name" /></div>
								<div className="column"><input type="text" onChange={(event) => this.handleEditAbbreviation(event, index)} value={this.state.editAbb[index]} placeholder="Organizational Unit Abbreviation" /></div>
								<button onClick={() => this.editValue(OU.ounit_id, index)}>Update</button>
							</div>
						}
						{ index < data.length - 1 ? <hr/> : <></>}
					</>
				);
			}
		});
	}

	render() {
		if(this.props.data.loading) {
			return (
				<div className="card card_full_width card_full_height">
					<div className="custom_table">
						<p><img className="spinner" src="/icons/spinner.svg"/>Loading Organizational Units List...</p>
					</div>
				</div>
			);
		} else if(this.props.data.error) {
				const style = {"marginLeft": "10px", "marginTop": "12px"};
	    	return (
	    		<div className="content generalInfo">
	    			<div className="card card_full_width">
	    				<h2>Error on loading Organizational Units list. Please logout and try again</h2>
	    				<p style={style}>If the problem persists, please contact the <a 
href="https://ewx.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">ESCI Service Desk</a></p>
	    			</div>
	    		</div>
	  		);
		} else if(this.props.data.listOU) {
			if (!this.languages.length) { this.generateLanguagesList(); }

			return (
				<div className="content OUnits">
					<div className="card card_full_width">
						<h2>Organizational Units</h2>
						<div id="div_iia_list" className="division_75">
							<div className="card card_full_width card_full_height">
								<div className="custom_table">
									<div className="row header_row">
										<div className="column" id=""><h3>OUnit Name Language</h3></div>
										<div className="column" id=""><h3>Organizational Unit Name</h3></div>
										<div className="column" id=""><h3>Organizational Unit Abbreviation</h3></div>
										<div className="column" id=""><h3>Actions</h3></div>
									</div>
									{this.renderRows(this.props.data.listOU)}
								</div>
							</div>
						</div>
						<div id="div_iia_list" className="division_75">
							<div className="card card_full_width card_full_height">
								<h3>Add New</h3>
								<div className="custom_table">
									<div className="row header_row">
										<div className="column" id=""><h3>Organizational Unit Name</h3></div>
										<div className="column" id="">
											<select name={"language"} id={"language"} value={this.state.newLanguage} onChange={(event) => this.changeLanguage(event)}>
												<option key="lang" value="">(OUnit name language)</option>
												{this.languages.map((value, index) => {
													if (value.includes("00")) {
														return <option key={"lang" + index} value={`${value}`} disabled>{`${value}`}</option>    
													}
													return <option key={"lang" + index} value={`${value}`}>{`${value}`}</option>
												})}
                                    		</select>
											{/* <input type="select" onChange={(event) => this.changeLanguage(event)} placeholder="OUnit Name Language" /> */}
										</div>
										<div className="column" id=""><input type="text" onChange={(event) => this.changeValue(event)} value={this.state.newOU} placeholder="Organizational Unit Name" /></div>
										<div className="column" id=""><input type="text" onChange={(event) => this.changeAbbreviation(event)} value={this.state.newAbb} placeholder="Organizational Unit Abbreviation" /></div>
										<div className="column" id=""><button onClick={() => this.addValue()}>Add New</button></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
  		const style = {"marginLeft": "10px", "marginTop": "12px"};
    	return (
    		<div className="content generalInfo">
    			<div className="card card_full_width">
    				<h2>Error loading Organizational Units list. Please logout and try again</h2>
    				<p style={style}>If the problem persists, please contact the <a href="https://ewx.atlassian.net/servicedesk/customer/portals" 
target="_blank" rel="noopener noreferrer">ESCI Service Desk</a></p>
    			</div>
    		</div>
  		);
		}
  }
};

const mutationAddOU = gql`
	mutation AddOU($ErasmusCode: String!, $lang: String, $ouName: String!, $abbreviation: String){
		addOU(ErasmusCode: $ErasmusCode, lang: $lang, ouName: $ouName, abbreviation: $abbreviation){
			Success
	  }
	}
`;

const mutationUpdateOU = gql`
	mutation UpdateOU($ErasmusCode: String!, $OUid: String!, $lang: String, $ouName: String!, $abbreviation: String){
		updateOU(ErasmusCode: $ErasmusCode, OUid: $OUid, lang: $lang, ouName: $ouName, abbreviation: $abbreviation) {
			Success
		}
	}
`;

const mutationRemoveOU = gql`
	mutation RemoveOU($ErasmusCode: String!, $OUid: String!){
		removeOU(ErasmusCode: $ErasmusCode, OUid: $OUid){
			Success
	  }
	}
`;

const mutationDeactivateOU = gql`
	mutation DeactivateOU($ErasmusCode: String!, $OUid: String!){
		deactivateOU(ErasmusCode: $ErasmusCode, OUid: $OUid){
			Success
	  }
	}
`;

const mutationActivateOU = gql`
	mutation ActivateOU($ErasmusCode: String!, $OUid: String!){
		activateOU(ErasmusCode: $ErasmusCode, OUid: $OUid){
			Success
	  }
	}
`;

const getOUList = gql`
query listOU($searchCodeIn: String, $ErasmusCodeIn: String!) {
  listOU(SearchValue: $searchCodeIn, ErasmusCode: $ErasmusCodeIn) {
    ounit_id
    institution_ecode
    abbreviation
    is_ewp_partner
    active
    lang
    name
  }
}
`;

export default compose(
	graphql(getOUList, {
		options: (props) => {return {
			variables: {
				ErasmusCodeIn: localStorage.getItem("ErasmusCode")
			}
		}}
	}),
	graphql(getLanguages, { name: "allLanguages" }),
	graphql(mutationAddOU,{ name : 'AddOU'}),
	graphql(mutationUpdateOU, { name: 'UpdateOU' }),
	graphql(mutationRemoveOU,{ name : 'RemoveOU'}),
	graphql(mutationDeactivateOU,{ name : 'DeactivateOU'}),
	graphql(mutationActivateOU,{ name : 'ActivateOU'})
)(OUnits);
