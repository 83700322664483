import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button as ReactButton, Collapse } from 'reactstrap';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import axios from 'axios';
import querystring from 'querystring';
import gql from 'graphql-tag'
import intl from 'react-intl-universal';

import GlobalVar from '../../../../GlobalVar';
import Button from '../../common/Button';
import Label from '../../common/Label';

import { query as getIIA } from '../../../../queries/getIIA';
import Title from '../../common/Title';

class ShowEWPIIAList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // reloadActions: false,
            // creatorDefaultOpen: false,
            // partnerDefaultOpen: false,
            // conditionsDefaultOpen: false,
            // checkEWP: false
            partnerEC: "",
            optionEC: "",
            optionECValue: "",
            optionAcadYear: "0",
            optionModSince: "0",
            refreshPage: false,
            isCreatorInEWP: -2,
            isPartnerInEWP: -2,
            isPartnerInEWPMessage: "",
            iiaListFetched: 0,
            iiaList: [],
            iiaListLocalFetched: 0,
            iiaListLocal: [],
            instListFetched: 0,
            instList: [],
            iiaFetched: 0,
            iiaID: "",
            localIIAID: "",
            iiaInfo: "",
            iiaSignature: 0,
            downloading: 0,
            updating: 0,
            approval: 0,
            approved: 0,
            errorMessage: "",
            errorMessageList: ""
        }

        this.onChangeSCHACHandler = this.onChangeSCHACHandler.bind(this);
        this.onChangeAcadYearHandler = this.onChangeAcadYearHandler.bind(this);
        this.onChangeModSinceHandler = this.onChangeModSinceHandler.bind(this);
        this.onChangeECHandler = this.onChangeECHandler.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);

        this.getInstitutionsList();
        this.checkIfRequesterIIAisManagedByEWP();
    }

    onChangeSCHACHandler(event) {
        const targetValue = event.target.value;

        console.log(targetValue);

        var values = targetValue.split("/")
        if(values.length >= 2) {
            console.log(values);
            var finalValue = ""
            for(let i=0;i<values.length-1;i++) {
                finalValue = finalValue + values[i]
                if(i<values.length-2){
                    finalValue = finalValue + "-"
                }
            }
            this.setState({
                optionEC: finalValue,
                partnerEC: values[values.length-1],
                optionECValue: targetValue,
            });
    
            this.refreshPage();
        }
    }

    onChangeECHandler(event) {
        const value = event.target.value;

        this.setState({
            partnerEC: value.replace(/\s/g, "").toUpperCase()
        });

        this.refreshPage();
    }

    onChangeAcadYearHandler(event) {
        const value = event.target.value;

        this.setState({
            optionAcadYear: value
        });

        this.refreshPage();
    }

    onChangeModSinceHandler(event) {
        const value = event.target.value;
        //const name = event.target.name;

        this.setState({
            optionModSince: value
        });

        this.refreshPage();
        }

        refreshPage() {
        this.setState({
            refreshPage: !this.state.refreshPage
        });

        // this.props.refreshPage();
    }

    onSubmitHandler(event) {
        //Make EWP Network request and open popup
        if(this.state.partnerEC != "" && this.state.optionEC != "") {
            this.checkIfPartnerIIAisManagedByEWP();
        }
    }

    onClickIIABackHandler(event) {
        this.setState({
            iiaFetched: 0,
        });
    }

    onClickIIADownloadHandler(event) {
        this.setState({
            downloading: 1,
        });

        this.downloadEWPIIA(0);
    }

    onClickIIAUpdateHandler(event) {
        this.setState({
            updating: 1,
        });
        this.downloadEWPIIA(1);
    }

    onClickIIAApproveHandler(event) {
        this.setState({
            updating: 1,
        });
        this.props.getEWPIIAApproval({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode.replace(/\s/g, "").toUpperCase()).toString(),//props.location.state.data.creator_institution_code
                "PartnerErasmusCode": this.state.partnerEC,
                "PartnerSCHAC": this.state.optionEC,
                "IIAID": this.state.localIIAID,
                "PDF": false
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    approval: -1,
                    updating: 0,
                });
            } else {
                switch(x.data.getEWPIIAApproval.Success){
                    case 1:
                        this.setState({
                            approval: 1,
                            updating: 0,
                        });
                        break;
                    case 2:
                        this.setState({
                            approval: 2,
                            updating: 0,
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            approval: -1,
                            updating: 0,
                        });
                        break;
                }
            }
        });
    }

    downloadEWPIIA(update) {
        this.props.getEWPIIAandSave({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode.replace(/\s/g, "").toUpperCase()).toString(),//props.location.state.data.creator_institution_code
                "PartnerErasmusCode": this.state.partnerEC,
                "PartnerSCHAC": this.state.optionEC,
                "IIAID": this.state.iiaID,
                "PDF": false
            },
        }).then((x) => {
            if (x.data.error) {
                if(update) {
                    this.setState({
                        updating: -1
                    });
                } else {
                    this.setState({
                        downloading: -1
                    });
                }
            } else {
                switch(x.data.getEWPIIAandSave.Success){
                    case 1:
                        //By a node in EWP
                        if(update) {
                            this.setState({
                                updating: 2
                            });
                        } else {
                            this.setState({
                                downloading: 2
                            });
                        }
                        break;
                    case 0:
                        if(update) {
                            this.setState({
                                updating: -3,
                                errorMessage: x.data.getEWPIIAandSave.Message
                            });
                        } else {
                            this.setState({
                                downloading: -3,
                                errorMessage: x.data.getEWPIIAandSave.Message
                            });
                        }
                        break;
                    default:
                        //Some error
                        if(update) {
                            this.setState({
                                updating: -1
                            });
                        } else {
                            this.setState({
                                downloading: -1
                            });
                        }
                        break;
                }
            }
        });
    }

    onClickIIAHandler(event, value, localIIA, signature) {

        this.setState({
            iiaFetched: 0,
            iiaID: value,
            localIIAID: localIIA,
            iiaSignature: signature,
        });

        this.props.getEWPIIA({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode.replace(/\s/g, "").toUpperCase()).toString(),//props.location.state.data.creator_institution_code
                "PartnerErasmusCode": this.state.partnerEC,
                "PartnerSCHAC": this.state.optionEC,
                "IIAID": value,
                "PDF": false
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    iiaFetched: -1
                });
            } else {
                switch(x.data.getEWPIIA.Success){
                    case 1:
                        //By a node in EWP
                        if (x.data.getEWPIIA.Message === "fail"){
                            this.setState({
                                iiaFetched: 1,
                                updating: -2,
                                approved: 0,
                                approval: 0,
                                downloading: -2,
                                iiaInfo: JSON.parse(x.data.getEWPIIA.IIADataXML),
                            });
                        }
                        else {
                            this.setState({
                                iiaFetched: 1,
                                updating: 0,
                                approved: 0,
                                approval: 0,
                                downloading: 0,
                                iiaInfo: JSON.parse(x.data.getEWPIIA.IIADataXML),
                            });
                        }
                        break;
                    default:
                        //Some error
                        this.setState({
                            iiaFetched: -1,
                            errorMessage: x.data.getEWPIIA.Message
                        });
                        break;
                }
            }
        });
    }
    
    render() {
        return (
            <div>
                <div onClick={() => this.toPreviousPage(true)} className="black_background" />
                <div className="content popup student_popup">
                    {this.showTitle()}
                    {this.showData()}
                </div>
            </div>
        );
    }

    toPreviousPage() {
        this.props.history.push(GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List"));
    }

    showTitle() {
        return (
            <div className="header" style={{ whiteSpace: 'nowrap', fontSize: '16pt', marginLeft: '10px' }}>
                <h2>Search for IIAs in EWP</h2>
                <form onSubmit={() => this.toPreviousPage(false)} className="close_button_wrapper">
                    <button>
                        <img src="/icons/remove.svg" />
                    </button>
                </form>
            </div>
        );
    }

    showData() {

        return (
            <div className={"popup_scroller"}>
                <div class={"sub_content"} style={{marginLeft: "10px", marginTop: "10px", width: "auto"}}>
                    <div class={"sub_content"} style={{marginLeft: "10px", marginTop: "10px", width: "auto"}}>
                        <div>
                            {this.showEWPButtons()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showEWPButtons() {
        if(this.state.isCreatorInEWP == -2) {
            return (
                <h3 style={{color:"black"}}>Searching for your institution in EWP...</h3>
            );
        }
        if(this.state.isCreatorInEWP == -1) {
            return (
                <h3 style={{color:"orange"}}>The IIAs of your institution are not managed by Dashboard in the EWP network so we cannot fetch the information. If you want the Dashboard to be responsible for handling the IIAs through the EWP network, please check that you have enabled the Dashboard in the Settings page of the EWP menu on the left.</h3>
            );
        }
        if(this.state.isCreatorInEWP == 0) {
            return (
                <h3 style={{color:"orange"}}>It was not possible to get the information about your institution on the EWP Network. Please contact the administrator or try again later.</h3>
            );
        }
        if(this.state.isCreatorInEWP == 1) {
            return (
                <h3 style={{color:"red"}}>The IIAs of your institution are managed by a node in the EWP network which is not the Dashboard. We do not have the authority to fetch the information requested.</h3>
            );
        }
        if(this.state.isCreatorInEWP == 2) {
            return (
                <div className="card card_full_width card_full_height">
                    <div className="custom_table">
                        <div className="table_body">
                            <div className="row">
                                <div className={"column"}>
                                    <h4 style={{color: "#4A90E2"}}>Partner Erasmus Code</h4>
                                    <div className={"partnerEC"}>
                                        {/* <input type="text" name="partnerEC" placeholder="Partner Erasmus Code" value={this.state.partnerEC} onChange={(event) => this.onChangeECHandler(event)} /> */}
                                        {this.showInstList()}
                                    </div>
                                </div>
                                <div className={"column"}>
                                    <h4 style={{color: "#4A90E2"}}>Academic Year</h4>
                                    <div className={"acadYear"}>
                                        <select name={"optionAcadYear"} value={this.state.optionAcadYear} onChange={(event) => this.onChangeAcadYearHandler(event)}>
                                            <option value="0">All Years</option>
                                            <option value="2016">2016/2017</option>
                                            <option value="2017">2017/2018</option>
                                            <option value="2018">2018/2019</option>
                                            <option value="2019">2019/2020</option>
                                            <option value="2020">2020/2021</option>
                                            <option value="2021">2021/2022</option>
                                            <option value="2022">2022/2023</option>
                                            <option value="2023">2023/2024</option>
                                            <option value="2024">2024/2025</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"column"}>
                                    <h4 style={{color: "#4A90E2"}}>Modified Since</h4>
                                    <div className={"modSince"}>
                                        <select name={"optionModSince"} value={this.state.optionModSince} onChange={(event) => this.onChangeModSinceHandler(event)}>
                                            <option value="0">All Time</option>
                                            <option value="1">Last Day</option>
                                            <option value="2">Last Week</option>
                                            <option value="3">Last Month</option>
                                            <option value="4">Last Year</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <button onClick={(event) => this.onSubmitHandler(event)}>List Partner Agreements</button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="custom_table">
                        <div className="table_body">
                            <div className="row">
                                {this.showEWPPartnerInfo()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    showInstList() {
        if(this.state.instListFetched) {
            return (
                <select name={"optionEC"} value={this.state.optionECValue} onChange={(event) => this.onChangeSCHACHandler(event)}>
                    {this.state.instList.map((value, index) => {
                        return <option value={value.SCHAC + "/" + value.ECODE}>{value.ECODE} - {value.SCHAC}</option>
                    })}
                </select>
            )
        }
    }

    showEWPPartnerInfo() {
        if(this.state.isPartnerInEWP == -2) {
            //Mew request to be made.
            return (
                <h3 style={{color:"black"}}></h3>
            );
        }
        if(this.state.isPartnerInEWP == -1) {
            return (
                <h3 style={{color:"orange"}}>The IIAs of partner institution are not managed by any node in the EWP network, or do not have a compatible version, so we cannot fetch the information.</h3>
            );
        }
        if(this.state.isPartnerInEWP == 0) {
            return (
                // <h3 style={{color:"orange"}}>It was not possible to get the information about the partner institution on the EWP Network. Please contact the administrator or try again later.</h3>
                <h3 style={{color:"orange"}}>{this.isPartnerInEWPMessage}</h3>
            );
        }
        if(this.state.isPartnerInEWP == 2) {
            return (
                <h3 style={{color:"green"}}>The IIAs of the partner institution are managed by the Dashboard in the EWP network so all the IIAs are already in the list up-to-date.</h3>
            );
        }
        if(this.state.isPartnerInEWP == 1) {
            if(this.state.iiaFetched != 0) {
                return (
                    this.showIIAInfo()
                );
            } else {
                return (
                    this.showIIAListInfo()
                );
            }
        }
    }

    showIIAListInfo() {
        if(this.state.iiaListFetched == 0) {
            return (
                <h3 style={{color:"blue"}}>Fetching IIA List...</h3>
            );
        }

        if(this.state.iiaListFetched == -1) {
            return (
                <h3 style={{color:"blue"}}>{this.state.errorMessageList}</h3>
            );
        }

        if(this.state.iiaListFetched == 1) {
            if (!this.state.iiaList) {
                return (
                    <div>
                        <h2>No agreements found.</h2>
                    </div>
                );
            }

            return (
                <div>
                    {this.state.iiaList.map((value, index) => {
                        var sigText = "&nbsp;";
                        switch(value["Signature"]) {
                            case -1:
                                //No Siganture yet
                                sigText = "No signature present yet."
                                break;
                            case 0:
                                //Created/edited by Dashboard
                                sigText = "Created or edited by Dashboard."
                                break;
                            case 1:
                                //Approved by Dashboard
                                sigText = "Approved by Dashboard."
                                break;
                            case 2:
                                //Created/edited by EWP partner
                                sigText = "Created/edited by EWP partner."
                                break;
                            case 3:
                                //Approved by EWP partner
                                sigText = "Approved by EWP partner."
                                break;
                            case 4:
                                //Approved by both partners
                                sigText = "Approved by both partners."
                                break;
                        }
                         if(value["LocalIIA"] != "") {
                            return (
                                <div>
                                    <div className={"row"}>
                                        <div className={"column"}>
                                            <h4>EWP IIA ID</h4>
                                            <p>{value["EWPIIA"]}</p>
                                        </div>
                                        <div className={"column"}>
                                            <h4>Local ID</h4>
                                            <p>{value["LocalIIA"]}</p>
                                        </div>
                                        <div className={"column"}>
                                            <h4>Signature Status</h4>
                                            <p>{sigText}</p>
                                        </div>
                                        <div className={"column"}>
                                            <button onClick={(event) => this.onClickIIAHandler(event, value["EWPIIA"], value["LocalIIA"], value["Signature"])}>View EWP Partner Agreement</button>
                                        </div>
                                    </div>
                                    <div className={"row"}><hr/></div>
                                </div>
                            );
                        } else {
                            return (
                                <div>
                                    <div className={"row"}>
                                        <div className={"column"}>
                                            <h4>EWP IIA ID</h4>
                                            <p>{value["EWPIIA"]}</p>
                                        </div>
                                        <div className={"column"}>
                                            <h4>Local ID</h4>
                                            <p>No internal IIA ID match.</p>
                                        </div>
                                        <div className={"column"}>
                                            <button onClick={(event) => this.onClickIIAHandler(event, value["EWPIIA"], "", -1)}>View EWP Partner Agreement</button>
                                        </div>
                                    </div>
                                    <div className={"row"}><hr/></div>
                                </div>
                            );
                        }
                    })}
                </div>
            );
        }
        
    }

    showIIAInfo() {
        if(this.state.iiaFetched == -1) {
            return (
                <div>
                    <button onClick={(event) => this.onClickIIABackHandler(event)}>Back to List</button>
                    <h3 style={{color:"blue"}}>{this.state.errorMessage}</h3>
                </div>
            );
        }
        return (
            <div>
                <div>
                    <button onClick={(event) => this.onClickIIABackHandler(event)}><img alt="" src="/icons/leftArrow-blue.svg" /> Back to List</button>
                </div>
                <div><p>&nbsp;</p></div>
                {/* <h3 style={{color:"blue"}}>{JSON.stringify(this.state.iiaInfo)}</h3> */}
                {Object.entries(this.state.iiaInfo).map(([key,value]) => {
                    if(key.includes("partner")) {
                        return value.map((item) => {
                            return (
                                <div>
                                    <div className={"row"}>
                                        <h3>Partner</h3>
                                        {Object.entries(item).map(([partnerKey,partnerValue]) => {
                                            if(partnerKey.includes("hei-id")) {
                                                return (
                                                    <div className={"column"}>
                                                        <h4 style={{color: "#4A90E2"}}>Partner HEI SCHAC CODE</h4>
                                                        <div>
                                                            <p>{partnerValue.toString()}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(partnerKey.includes("ounit-id")) {
                                                return (
                                                    <div className={"column"}>
                                                        <h4 style={{color: "#4A90E2"}}>Partner OUnit SCHAC CODE</h4>
                                                        <div>
                                                            <p>{partnerValue.toString()}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(partnerKey.includes("iia-id")) {
                                                return (
                                                    <div className={"column"}>
                                                        <h4 style={{color: "#4A90E2"}}>Partner IIA ID</h4>
                                                        <div>
                                                            <p>{partnerValue.toString()}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(partnerKey.includes("iia-code")) {
                                                return (
                                                    <div className={"column"}>
                                                        <h4 style={{color: "#4A90E2"}}>Partner IIA Code</h4>
                                                        <div>
                                                            <p>{partnerValue.toString()}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(partnerKey.includes("signing-contact")) {
                                                return this.renderContact(partnerValue, "Partner Signing Contact");
                                            }
                                            if(partnerKey.includes("signing-date")) {
                                                return (
                                                    <div className={"column"}>
                                                        <h4 style={{color: "#4A90E2"}}>Partner Signing Date</h4>
                                                        <div>
                                                            <p>{partnerValue.toString()}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(partnerKey.includes("contact") && !key.includes("signing-contact")) {
                                                return this.renderContact(partnerValue, "Partner Contact");
                                            }
                                        })}
                                    </div>
                                    <hr/>
                                </div>
                            )
                        })
                    }
                    if(key.includes("in-effect")) {
                        return (
                            <div>
                                <div className={"row"}>
                                    <div className={"column"}>
                                        <h4 style={{color: "#4A90E2"}}>In Effect?</h4>
                                        <div>
                                            <p>{value.toString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}><hr/></div>
                            </div>
                        );
                    }
                    if(key.includes("cooperation-conditions")) {
                        return (
                            <div className={"row"}>
                                <h3 style={{color: "#4A90E2"}}>Cooperation Conditions</h3>
                                {value.map((coopCondItem) => {
                                    return Object.entries(coopCondItem).map(([coopCondKey,coopCondValue]) => {
                                        if(coopCondKey.includes("student-studies-mobility-spec")) {
                                            return this.renderCoopConditions(coopCondValue, "Student Studies");
                                        }
                                        if(coopCondKey.includes("student-traineeship-mobility-spec")) {
                                            return this.renderCoopConditions(coopCondValue, "Student Traineeship");
                                        }
                                        if(coopCondKey.includes("staff-teacher-mobility-spec")) {
                                            return this.renderCoopConditions(coopCondValue, "Staff Teacher");
                                        }
                                        if(coopCondKey.includes("staff-training-mobility-spec")) {
                                            return this.renderCoopConditions(coopCondValue, "Staff Training");
                                        }
                                    });
                                })}
                            </div>
                        );
                    }
                    if(key.includes("conditions-hash")) {
                        return (
                            <div>
                                <div className={"row"}>
                                    <div className={"column"}>
                                        <h3 style={{color: "#4A90E2"}}>Conditions Hash</h3>
                                        <div>
                                            <p>{value.toString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}><hr/></div>
                            </div>
                        );
                    }
                    if(key.includes("PDF")) {
                        return (
                            <div>
                                <div className={"row"}>
                                    <div className={"column"}>
                                        <h3 style={{color: "#4A90E2"}}>Base64 PDF (Beta)</h3>
                                        <div>
                                            <p>{value.toString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}><hr/></div>
                            </div>
                        );
                    }
                })}
                {this.showIIAInfoButtons()}
            </div>
        );
    }

    showIIAInfoButtons() {

        var buttonUpdate = 0;
        var buttonDownload = 0;

        // switch(this.state.iiaSignature) {
        //     case -1:
        //         //signed by dashboard locally
        //         buttonUpdate = 1;
        //         break;
        //     case 0:
        //         //no signature present
        //         buttonDownload = 1;
        //         break;
        //     case 1:
        //         //signed by partner locally
        //         buttonUpdate = 1;
        //         break;
        //     case 2:
        //         return (
        //             <div>
        //                 <p>Already Signed by both parties.</p>  
        //             </div>
        //         )
        //         //signed by both parties locally
        //         break;
        // }
        switch(this.state.iiaSignature) {
            case -1:
                //No Siganture yet
                buttonDownload = 1;
                break;
            case 0:
                //Created/edited by Dashboard
                // buttonUpdate = 1;
                return (
                    <div>
                        <p>The latest version of this IIA is already in the Dashboard.</p>
                    </div>
                )
                break;
            case 1:
                //Approved by Dashboard
                // buttonUpdate = 1;
                return (
                    <div>
                        <p>The latest version of this IIA is already in the Dashboard.</p>
                    </div>
                )
                break;
            case 2:
                //Created/edited by EWP partner
                buttonUpdate = 1;
                break;
            case 3:
                //Approved by EWP partner
                buttonUpdate = 1;
                break;
            case 4:
                //Approved by both partners
                return (
                    <div>
                        <p>Already Signed by both parties.</p>
                    </div>
                )
                break;
        }

        if(buttonDownload) {
            switch(this.state.downloading) {
                case -3:
                    return (
                        <div>
                            <p>{this.state.errorMessage}</p>
                        </div>
                    )
                case -2:
                    return (
                        <div>
                            <p>The IIA shared by the partner does not contain valid XML, therefore download is not available. Please notify the partner to respectively contact their provider.</p>
                        </div>
                    )
                case -1:
                    return (
                        <div>
                            <p>Error Downloading IIA from EWP partner...</p>
                            <p><button onClick={(event) => this.onClickIIADownloadHandler(event)}><img alt="" src="/icons/download.svg" /> Download to Dashboard</button></p>
                        </div>
                    )
                case 0:
                    return (
                        <div>
                            <p><button onClick={(event) => this.onClickIIADownloadHandler(event)}><img alt="" src="/icons/download.svg" /> Download to Dashboard</button></p>  
                        </div>
                    )
                case 1:
                    return (
                        <div>
                            <p>Downloading IIA from EWP partner...</p>
                        </div>
                    )
                case 2:
                    return (
                        <div>
                            <p>IIA successfully downloaded from EWP partner... You can now check it in your IIA List.</p>
                        </div>
                    )
            }
        } 

        if(buttonUpdate) {
            switch(this.state.updating) {
                case -3:
                    return (
                        <div>
                            <p>{this.state.errorMessage}</p>
                        </div>
                    )
                case -2:
                    return (
                        <div>
                            <p>The IIA shared by the partner does not contain valid XML, therefore update is not available. Please notify the partner to respectively contact their provider.</p>
                        </div>
                    )
                case -1:
                    return (
                        <div>
                            <p>Error Updating IIA with EWP partner information...</p>
                            <p><button onClick={(event) => {if(window.confirm("This will replace your IIA Data with Partner's Data. Are you sure you want to proceed?")){this.onClickIIAUpdateHandler(event)};}}><img alt="" src="/icons/download.svg" /> Update local IIA</button></p>
                            <p>&nbsp;</p>
                            {this.renderApprovalButton()}
                        </div>
                    )
                case 0:
                    return (
                        <div>
                            <p><button onClick={(event) => {if(window.confirm("This will replace your IIA Data with Partner's Data. Are you sure you want to proceed?")){this.onClickIIAUpdateHandler(event)};}}><img alt="" src="/icons/download.svg" /> Update local IIA</button></p>
                            <p>&nbsp;</p>
                            {this.renderApprovalButton()}
                       </div>
                    )
                case 1:
                    return (
                        <div>
                            <p>Updating Local IIA with EWP partner information...</p>
                        </div>
                    )
                case 2:
                    return (
                        <div>
                            <p>IIA successfully updated with EWP partner data... You can now check it in your IIA List.</p>
                        </div>
                    )
            }
        }

    }

    renderApprovalButton() {
        if(this.state.iiaSignature == -1 && this.state.iiaID != "") {
            if(this.state.approval == 0) {
                return <p><button onClick={(event) => this.onClickIIAApproveHandler(event)}><img alt="" src="/icons/requirements.svg" /> Check partner approval</button></p>;
            }
            if(this.state.approval == 1) {
                return <p>The IIA is not yet approved by partner.</p>;
            }
            if(this.state.approval == 2) {
                return <p>The IIA is approved by the partner.</p>;
            }
        }
    }

    renderContact(contactStruct, contactType){
        return contactStruct.map((contactItem) => {
            return Object.entries(contactItem).map(([contactKey,contactValue]) => {
                if(contactKey.includes("contact-name")) {
                    return contactValue.map((contactNameItem) => {
                        return Object.entries(contactNameItem).map(([contactNameKey,contactNameValue]) => {
                            if(contactNameKey.includes("_")) {
                                return (
                                    <div className={"column"}>
                                        <h4 style={{color: "#4A90E2"}}>{contactType} Name</h4>
                                        <div>
                                            <p>{contactNameValue.toString()}</p>
                                        </div>
                                    </div>
                                );
                            }
                        });
                    });
                }
                if(contactKey.includes("person-given-names")) {
                    return contactValue.map((contactNameItem) => {
                        return Object.entries(contactNameItem).map(([contactNameKey,contactNameValue]) => {
                            if(contactNameKey.includes("_")) {
                                return (
                                    <div className={"column"}>
                                        <h4 style={{color: "#4A90E2"}}>{contactType} Given Names</h4>
                                        <div>
                                            <p>{contactNameValue.toString()}</p>
                                        </div>
                                    </div>
                                );
                            }
                        });
                    });
                }
                if(contactKey.includes("person-family-name")) {
                    return contactValue.map((contactNameItem) => {
                        return Object.entries(contactNameItem).map(([contactNameKey,contactNameValue]) => {
                            if(contactNameKey.includes("_")) {
                                return (
                                    <div className={"column"}>
                                        <h4 style={{color: "#4A90E2"}}>{contactType} Family Names</h4>
                                        <div>
                                            <p>{contactNameValue.toString()}</p>
                                        </div>
                                    </div>
                                );
                            }
                        });
                    });
                }
                if(contactKey.includes("person-gender")) {
                    // return (
                    //     <div className={"column"}>
                    //         <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                    //         <div>
                    //             {contactValue.toString()}
                    //         </div>
                    //     </div>
                    // );
                }
                if(contactKey.includes("phone-number")) {
                    // return (
                    //     <div className={"column"}>
                    //         <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                    //         <div>
                    //             {contactValue.toString()}
                    //         </div>
                    //     </div>
                    // );
                }
                if(contactKey.includes("fax-number")) {
                    // return (
                    //     <div className={"column"}>
                    //         <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                    //         <div>
                    //             {contactValue.toString()}
                    //         </div>
                    //     </div>
                    // );
                }
                if(contactKey.includes("email")) {
                    return contactValue.map((contactEmailItem) => {
                        return (
                            <div className={"column"}>
                                <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                                <div>
                                    <p>{contactEmailItem.toString()}</p>
                                </div>
                            </div>
                        );
                    });
                }
                if(contactKey.includes("street-address")) {
                    // return (
                    //     <div className={"column"}>
                    //         <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                    //         <div>
                    //             {contactValue.toString()}
                    //         </div>
                    //     </div>
                    // );
                }
                if(contactKey.includes("mailing-address")) {
                    // return (
                    //     <div className={"column"}>
                    //         <h4 style={{color: "#4A90E2"}}>{contactType} Email</h4>
                    //         <div>
                    //             {contactValue.toString()}
                    //         </div>
                    //     </div>
                    // );
                }
                if(contactKey.includes("role-description")) {
                    return contactValue.map((contactEmailItem) => {
                        return (
                            <div className={"column"}>
                                <h4 style={{color: "#4A90E2"}}>{contactType} Role Description</h4>
                                <div>
                                    <p>{contactEmailItem.toString()}</p>
                                </div>
                            </div>
                        );
                    });
                }
            })
        });
    }

    renderCoopConditions(coopStruct, coopType){
        return coopStruct.map((coopCondMobItem) => {
            return (
                <div>
                    <div className={"row"}>
                        <h4 style={{color: "#4A90E2"}}>{coopType}</h4>
                        <div>
                            {Object.entries(coopCondMobItem).map(([coopCondMobKey,coopCondMobValue]) => {
                                if(coopCondMobKey.includes("sending-hei-id")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Sending HEI ID</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    );
                                }
                                if(coopCondMobKey.includes("sending-ounit-id")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Sending OUnit ID</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                // if(coopCondMobKey.includes("sending-contact")) {
                                //     return (
                                //         <div className={"column"}>
                                //             <h4 style={{color: "#4A90E2"}}>Sending Contact</h4>
                                //             <div>
                                //                 {coopCondMobValue.toString()}
                                //             </div>
                                //         </div>
                                //     )
                                // }
                                if(coopCondMobKey.includes("receiving-hei-id")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Receiving HEI ID</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("receiving-ounit-id")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Receiving OUnit ID</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                // if(coopCondMobKey.includes("receiving-contact")) {
                                //     return (
                                //         <div className={"column"}>
                                //             <h4 style={{color: "#4A90E2"}}>Receiving Contact</h4>
                                //             <div>
                                //                 {coopCondMobValue.toString()}
                                //             </div>
                                //         </div>
                                //     )
                                // }
                                if(coopCondMobKey.includes("receiving-academic-year-id")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Receiving Academic Year ID</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("mobilities-per-year")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Mobilities Per Year</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("recommended-language-skill")) {
                                    return (
                                        coopCondMobValue.map((coopCondMobItem2) => {
                                            return (
                                                <div className={"column"}>
                                                    <h4 style={{color: "#4A90E2"}}>Recommended Language Skill</h4>
                                                    {Object.entries(coopCondMobItem2).map(([coopCondMob2Key,coopCondMob2Value]) => {
                                                        if(coopCondMob2Key.includes("language")) {
                                                            return <p style={{ textTransform: "uppercase" }}>{coopCondMob2Value}</p>
                                                        }
                                                        if(coopCondMob2Key.includes("cefr-level")) {
                                                            return coopCondMob2Value.map((coopCondMobItem3) => {
                                                                return <p>-{coopCondMobItem3}</p>
                                                            })
                                                        }
                                                    })}
                                                </div>
                                            )
                                                
                                        })
                                    )
                                }
                                if(coopCondMobKey.includes("isced-f-code")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>ISCED-F Code</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("subject-area-name")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Subject Area Name</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("total-months")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Total Months Per Year</h4>
                                            <div><p>{coopCondMobValue}</p></div>
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("total-days")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Total Days Per Year</h4>
                                            <div><p>{coopCondMobValue}</p></div>
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("blended")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>Blended?</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                                if(coopCondMobKey.includes("eqf-level")) {
                                    return (
                                        <div className={"column"}>
                                            <h4 style={{color: "#4A90E2"}}>EQF Level</h4>
                                            {coopCondMobValue.map((coopCondMobItem2) => {
                                                return <div><p>{coopCondMobItem2}</p></div>
                                            })}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className={"row"}><hr/></div>
                </div>
            );
        });
    }

    checkIfRequesterIIAisManagedByEWP() {
        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": localStorage.getItem("ErasmusCode"),
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isCreatorInEWP: 0
                });
            } else {
                switch(x.data.isIIAManagedByEWP.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isCreatorInEWP: 1,
                        });
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isCreatorInEWP: 2
                        });
                        break;
                    case -1:
                        //Not in EWP or not the same version
                        this.setState({
                            isCreatorInEWP: -1
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isCreatorInEWP: 0
                        });
                        break;
                }
            }
        });
    }

    checkIfPartnerIIAisManagedByEWP() {

        this.setState({
            isPartnerInEWP: -2
        });

        this.props.isIIAManagedByEWP({
            variables: {
                "ErasmusCode": this.state.partnerEC,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    isPartnerInEWP: 0
                });
            } else {
                switch(x.data.isIIAManagedByEWP.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            isPartnerInEWP: 1,
                            isPartnerInEWPMessage: x.data.isIIAManagedByEWP.Message
                        });
                        this.getPartnerIIAList();
                        break;
                    case 2:
                        //By Dashboard
                        this.setState({
                            isPartnerInEWP: 2,
                            isPartnerInEWPMessage: x.data.isIIAManagedByEWP.Message
                        });
                        break;
                    case -1:
                        //Not in EWP or not the same version
                        this.setState({
                            isPartnerInEWP: -1,
                            isPartnerInEWPMessage: x.data.isIIAManagedByEWP.Message
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            isPartnerInEWP: 0,
                            isPartnerInEWPMessage: x.data.isIIAManagedByEWP.Message
                        });
                        break;
                }
            }
        });
    }

    getInstitutionsList() {
        this.props.getEWPInstitutionsList({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode.replace(/\s/g, "").toUpperCase()).toString(),//props.location.state.data.creator_institution_code
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    instListFetched: -1
                });
            } else {
                switch(x.data.getEWPInstitutionsList.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            instListFetched: 1,
                            instList: x.data.getEWPInstitutionsList.InstList,
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            instListFetched: -1
                        });
                        break;
                }
            }
        });
    }

    getPartnerIIAList() {

        this.setState({
            iiaListFetched: 0,
            iiaFetched: 0,
        });

        this.props.getEWPIIAList({
            variables: {
                "CreatorErasmusCode": GlobalVar.encryptWithKey(JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode.replace(/\s/g, "").toUpperCase()).toString(),//props.location.state.data.creator_institution_code
                "PartnerErasmusCode": this.state.partnerEC,
                "PartnerSCHAC": this.state.optionEC,
                "RecAcadYear": this.state.optionAcadYear,
                "ModifiedSince": this.state.optionModSince,
            },
        }).then((x) => {
            if (x.data.error) {
                this.setState({
                    iiaListFetched: -1,
                    errorMessageList: "An error has occurred while fetching IIA List. If the problem persists please contact the administrator."
                });
            } else {
                switch(x.data.getEWPIIAList.Success){
                    case 1:
                        //By a node in EWP
                        this.setState({
                            iiaListFetched: 1,
                            iiaList: x.data.getEWPIIAList.IIAList,
                        });
                        break;
                    default:
                        //Some error
                        this.setState({
                            iiaListFetched: -1,
                            errorMessageList: x.data.getEWPIIAList.Message
                        });
                        break;
                }
            }
        });
    }

}



const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

const getEWPIIAList = gql`
    mutation getEWPIIAList($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $PartnerSCHAC: String!, $RecAcadYear: Int, $ModifiedSince: Int) {
        getEWPIIAList(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, PartnerSCHAC: $PartnerSCHAC, RecAcadYear: $RecAcadYear, ModifiedSince: $ModifiedSince) {
            Success
            Message
            IIAList {
                EWPIIA
                LocalIIA
                Signature
            }  
        }
    }
`;

const getEWPIIA = gql`
    mutation getEWPIIA($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $PartnerSCHAC: String!, $IIAID: String!, $PDF: Boolean) {
        getEWPIIA(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, PartnerSCHAC: $PartnerSCHAC, IIAID: $IIAID, PDF: $PDF) {
            Success
            Message
            IIADataXML
        }
    }
`;

const getEWPIIAandSave = gql`
    mutation getEWPIIAandSave($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $PartnerSCHAC: String!, $IIAID: String!, $PDF: Boolean) {
        getEWPIIAandSave(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, PartnerSCHAC: $PartnerSCHAC, IIAID: $IIAID, PDF: $PDF) {
            Success
            Message
        }
    }
`;

const getEWPIIAApproval = gql`
mutation getEWPIIAApproval($CreatorErasmusCode: String!, $PartnerErasmusCode: String!, $PartnerSCHAC: String!, $IIAID: String!, $PDF: Boolean) {
    getEWPIIAApproval(CreatorErasmusCode: $CreatorErasmusCode, PartnerErasmusCode: $PartnerErasmusCode, PartnerSCHAC: $PartnerSCHAC, IIAID: $IIAID, PDF: $PDF) {
        Success
        Message
    }
}
`;

const getEWPInstitutionsList = gql`
    mutation getEWPInstitutionsList($CreatorErasmusCode: String!) {
        getEWPInstitutionsList(CreatorErasmusCode: $CreatorErasmusCode) {
            Success
            Message
            InstList {
                SCHAC
                ECODE
            }
        }
    }
`;

export default compose(
    graphql(getEWPIIAList, {name: "getEWPIIAList"}),
    graphql(getEWPIIA, {name: "getEWPIIA"}),
    graphql(getEWPIIAandSave, {name: "getEWPIIAandSave"}),
    graphql(getEWPIIAApproval, {name: "getEWPIIAApproval"}),
    graphql(isIIAManagedByEWP, {name: "isIIAManagedByEWP"}),
    graphql(getEWPInstitutionsList, {name: "getEWPInstitutionsList"})
)(ShowEWPIIAList);
