import React, { Component } from 'react'
import FunctionRow from './FunctionRow'
class FunctionTable extends Component {
	generateFunction(){
		return this.props.data.map(({Name, id, AccountAbility}) => {
			return <FunctionRow key={id} FunctionName={Name} id={id} AccountAbility={AccountAbility} />
		})
	}

	render() {
    	return (
    		<table>
    			<thead>
	    			<tr>
	    				<th>Role name</th>
	    				<th>Permissions</th>
	    			</tr>
    			</thead>
    			<tbody>
    				{this.generateFunction()}

    			</tbody>
    		</table>
      	);
    }
};

export default FunctionTable;
