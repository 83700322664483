import React, { Component } from 'react';


export default class RevisionsTable extends Component{
    parseDate(date) {
        const newDate = new Date(date);
        let year = newDate.getFullYear();
        let mon = newDate.getMonth()+1;
        let day = newDate.getDate();
        let hour = newDate.getHours();
        let min = newDate.getMinutes();
        let sec = newDate.getSeconds();
        if (day < 10) {
          day = '0' + day;
        }
        if (mon < 10) {
          mon = '0' + mon;
        }
        if (hour < 10) {
          hour = '0' + hour;
        }
        if (min < 10) {
          min = '0' + min;
        }
        if (sec < 10) {
          sec = '0' + sec;
        }
        return year+'-'+mon+'-'+day+' '+hour+':'+min+':'+sec;
    }

    renderRevisions() {
        let classNameTable = "table_without_changes table_body";

        if(this.props.data.data.learningAgreement.revisions !== undefined){
            if(this.props.data.data.learningAgreement.revisions.length === 0){
                return <p className="italic">No revisions to show</p>;
            }
            return(
                <div className={classNameTable}>
                    <div className="row header_row">
                        <div className="column id"><p>ID</p></div>
                        <div className="column date"><p>Date</p></div>
                        <div className="column status"><p>Status</p></div>
                    </div>
                    {this.renderRevisionsRows()}
                </div>
            )
        }
    }

    renderRevisionsRows() {
        let revisions = this.props.data.data.learningAgreement.revisions;
        return revisions.map((revisionData, index) => {

            return(
                <div className="row" key={index}>
                    <div className="column id"><p>{revisionData.vid}</p></div>
                    <div className="column date"><p>{this.parseDate(revisionData.revision_timestamp)}</p></div>
                    <div className="column status"><p className="capitalize">{this.props.parseStatus(revisionData.status)}</p></div>
                    <div className="column download">
                        <p><a className="button" rel="noopener noreferrer" target="_blank" href={`/ola3/export/${this.props.data.data.learningAgreement.olaID}/${revisionData.vid}`}>
                            Download Revision
                        </a></p>
                    </div>
                </div>
            )
        })
    }
    render(){
        return(this.renderRevisions())
    }
}