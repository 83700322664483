import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query as getOutgoingNomination } from '../../../queries/getOutgoingNomination';
import OutgoingDestination from './OutgoingDestination';

class OutgoingHEI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key : ""
		}
    }


	generateList(){

		return this.props.data.map((nominations,key) => {
			return <OutgoingDestination key ={key} nominations={nominations} status={this.props.nominationstatus}/>;

		})
	}

	render() {
	    	return (
			<div>
				{this.generateList()} 
			</div>
	      	);
    }
};



export default OutgoingHEI;
