import gql from 'graphql-tag';

const query = gql`
  query ($ID: String, $eCode: String, $encryptedECode: String, $pathName: String) {
    learningAgreement (ID: $ID, eCode: $eCode, encryptedECode: $encryptedECode, pathName: $pathName) {
      olaID
      title
      created
      field_academic_year
      field_la_status
      field_planned_period_from
      field_planned_period_to
      field_student_firstname
      field_student_lastname
      field_student_email
      field_student_birthdate
      gender
      nationality
      isced
      field_of_education_comment
      sending_institution
      receiving_institution
      preliminary_table_a
      preliminary_table_b
      preliminary_table_c
      final_table_a2
      final_table_b2
      final_table_c2
      semester_map
      receiving_contact_person
      sending_contact_person
      receiving_resp_person
      sending_resp_person
      revisions
      error
    }
  }`

export { query };
