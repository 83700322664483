import React, { Component } from 'react';
import moment from 'moment';
import { decode } from 'he';
import { query } from '../../../queries/getTutorial';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';
import { Link } from 'react-router-dom';
import escapeStringRegexp from 'escape-string-regexp';

import Courses from './Courses';

class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openCourses: false,
			checked: false,
			names : [],
			openTutorial : false
		}
		this.overlay = true;
		this.tutorialID = "";
		this.x = 0;
		this.y = 0;
		this.openOrCloseCoursesEvent = this.openOrCloseCoursesEvent.bind(this);

	}

	componentWillMount() {
		this.setChecker(this.props, true);
	}
	componentWillReceiveProps(nextProps) {
		this.setChecker(nextProps, false);
	}
	setChecker(props, notMountedYet){
		if(notMountedYet || props.selectionMinimum != this.props.selectionMinimum || props.selectionMaximum != this.props.selectionMaximum){
			if(props.index > props.selectionMinimum && props.index < props.selectionMaximum){
				this.setState({
					checked: true
				})
			}
			else if(props.index == props.selectionMinimum || props.index == props.selectionMaximum){
				this.setState({
					checked: true
				})
			}
			else{
				this.setState({
					checked: false
				})
			}
		}
	}
	showOtherchecker(data){
		if(data.StudentDetails.OLA.length > 1){
			return <p className="showMore">Show other</p>;
		}
	}

	renderInstitutionName(data, sending){
		if(sending){
			if(data.SendingInstitution != null){
				return data.SendingInstitution.NameInLatinCharacterSet;
			}
		}
		else{
			if(data.ReceivingInstitution != null){
				return data.ReceivingInstitution.NameInLatinCharacterSet;
			}
		}
		return <p className="italic">Not found</p>;
	}

	//renderInstitutionErasmusCode(lastOLAdata, sending){


	renderInstitutionErasmusCode(data, sending){

		if(sending){
			if(data.SendingInstitution != null){
				return data.SendingInstitution.ErasmusCode;
			}
		}
		else{
			if(data.ReceivingInstitution != null){
				return data.ReceivingInstitution.ErasmusCode;
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCityName(data, sending){
		if(sending){
			if(data.SendingInstitution != null){
				if(data.SendingInstitution.City != null){
					return data.SendingInstitution.City.CityName
				}
			}
		}
		else{
			if(data.ReceivingInstitution != null){
				if(data.ReceivingInstitution.City != null){
					return data.ReceivingInstitution.City.CityName
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCountryName(data, sending){
		if(sending){
			if(data.SendingInstitution != null){
				if(data.SendingInstitution.City != null){
					if(data.SendingInstitution.City.Country != null){
						return data.SendingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		else{
			if(data.ReceivingInstitution != null){
				if(data.ReceivingInstitution.City != null){
					if(data.ReceivingInstitution.City.Country != null){
						return data.ReceivingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	openOrCloseCoursesEvent(event){
		event.preventDefault();
		this.setState({
			openCourses: !this.state.openCourses
		})
	}

	renderRowColumn(value, uniqueName){
		if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
			let columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
			if(columnValues.includes(uniqueName)){
				return <div className={"column "+uniqueName}>{value}</div>;
			}
		}
	}

	findValue(value){
		if(value == undefined || value==""){
			return <p className="italic">Not found</p>;
		}
		return <p>{value}</p>;
	}

	showSearchResult(value){
		const {search} = this.props;
		if(search === undefined || search === ""){
			return this.findValue(value);
		}
		const searchRegex = new RegExp(escapeStringRegexp(search), 'i');
		const searchResult = value.search(searchRegex);
		//console.log(searchResult, search.length)
		if(searchResult !== -1){
			const filteredResult = value.substring(searchResult, searchResult+search.length);
			let newValue = value.replace(filteredResult, `<span class="blue_text">${filteredResult}</span>`);
			return <p dangerouslySetInnerHTML={{__html: newValue}} ></p>
		}
		return this.findValue(value);
	}

	renderSignedBy(status){
		if(status === null){
			return <p className="italic">Not applicable</p>;
		}
		if(status < 1){
			return <p>Nobody</p>;
		}
		else if(status == 1){
			return <p>Student</p>;
		}
		else if(status == 2){
			return <p>Student + Home institution</p>;
		}
		else if(status == 3){
			return <p>Everyone</p>;
		}
	}

    generateTutorial(){
    	if(this.props.tutorial){
    		for(let data of this.props.tutorial){
             	if(data.didShiftClickList == 0 && this.state.openTutorial && this.overlay){
		    		this.overlay = false;
		    		this.tutorialID = data.id
			        return (
				        <div style={{"left":this.x,"right":this.y}} className="overlay" >
				            <h1 className="Header">Select Options : </h1>
				            <p className="textTutorial">You can select multiple students by clicking in the select box, then pressing “shift” key and then choosing the range of students you want to select.</p>
				            <form className="button" onSubmit={(event) => this.handleTutorial(event)}><button>Skip</button></form>
				        </div>
			        )
		        }
            }
        }
    }

    handleTutorial(event){
    	event.preventDefault();
        this.setState({
			openTutorial : false
		})

		this.props.updateTutorial({
			variables:{
			   "id"	: this.tutorialID,
			   "didShiftClickList" : 1
			},
		    refetchQueries: [{query}]
		})
    }

	checkBoxClick(event){
		if(!this.state.checked){
			//

			if(event.shiftKey){
				this.props.handleMultipleSelecting(undefined, this.props.index);
			}
			else{
				this.props.handleMultipleSelecting(this.props.index, undefined);
			}
		}
		else{
			console.log("unclick");
			this.props.handleMultipleSelecting(undefined, undefined, true);
		}
		this.setState({
			checked: !this.state.checked,
			openTutorial :true,
		})
		this.x = 20;
		this.y = event.clientY;
	}

	generateCorrectDateFormat(month, year){
		const date = moment(GlobalVar.fillNumber(month, 2)+"-"+year, "MM-YYYY");
		if(date.format("MM-YYYY") === "Invalid date"){
			return <p className="italic">Invalid date</p>;
		}
		return <p>{date.format("MMM YYYY")}</p>;
	}

	generateBooleanValue(value){
		if(value){
			return <p>Yes</p>;
		}
		return <p>No</p>;
	}

	convertStudyCycle(value){
		if(GlobalVar.OlaStudyCycle[value]){
			return this.findValue(GlobalVar.OlaStudyCycle[value]);
		}
		return this.findValue(value);
	}

	generateSubjectArea(subjectAreaCode, subjectArea){
		if(subjectAreaCode === ""){
			return this.findValue(subjectArea);
		}
		return this.findValue(`${subjectAreaCode} - ${subjectArea}`);
	}


	render() {
		const data = this.props.data;

		let gender = "";
		if(data.sex == "F"){
			gender = "Female";
		}
		if(data.sex == "M"){
			gender = "Male";
		}
		const popupLink = GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List")+"/student-detail/"+encodeURIComponent(data.encryptedID);
		return (
  		<div className="row">
				<div className="column bulk"><input  onClick={(event) => this.checkBoxClick(event)} checked={this.state.checked}  type="checkbox" /></div>
				{this.generateTutorial()}
				<div className="column nr"><p>{this.props.realIndex}</p></div>
				{this.renderRowColumn(<Link className="black_link" to={popupLink} >{this.showSearchResult(data.firstname)}</Link>, "firstname")}
				{this.renderRowColumn(<Link className="black_link" to={popupLink} >{this.showSearchResult(data.name)}</Link>, "lastname")}

				{this.renderRowColumn(<a className="black_link" href={"mailto:"+data.email}>{this.showSearchResult(data.email)}</a>, "email")}
				{this.renderRowColumn(this.generateCorrectDateFormat(data.mobilityFromMonth, data.mobilityFromYear), "startMobility")}
				{this.renderRowColumn(this.generateCorrectDateFormat(data.mobilityToMonth, data.mobilityToYear), "endMobility")}
				{this.renderRowColumn(this.generateBooleanValue(data.uploadedByInstitution), "uploaded")}
				{this.renderRowColumn(this.showSearchResult(data.nationality), "nationality")}
				{this.renderRowColumn(this.findValue(data.phone), "phone")}
				{this.renderRowColumn(this.findValue(gender), "gender")}
				{this.renderRowColumn(this.findValue(data.academicYear), "academicYear")}
				{this.renderRowColumn(this.showSearchResult(data.language), "language")}
				{this.renderRowColumn(this.showSearchResult(data.languagelevel), "languagelevel")}

				{this.renderRowColumn(this.generateSubjectArea(data.subjectAreaCode, data.subjectArea), "subjectArea")}
				{this.renderRowColumn(this.showSearchResult(data.facultySend), "facultySend")}
				{this.renderRowColumn(this.findValue(data.departmentSend), "departmentSend")}
				{this.renderRowColumn(this.showSearchResult(data.facultyRecive), "facultyRecive")}
				{this.renderRowColumn(this.findValue(data.departmentRecive), "departmentRecive")}
				{this.renderRowColumn(this.convertStudyCycle(data.cycle), "cycle")}

				{this.renderRowColumn(this.showSearchResult(data.receiveContactname), "receiveContactname")}
				{this.renderRowColumn(<a href={"mailto:"+data.receiveEmail}>{this.showSearchResult(data.receiveEmail)}</a>, "receiveEmail")}
				{this.renderRowColumn(this.findValue(data.receivePhone), "receivePhone")}
				{this.renderRowColumn(this.findValue(data.receiveFunction), "receiveFunction")}

				{this.renderRowColumn(this.showSearchResult(data.recResName), "receiveResponsibleContactname")}
				{this.renderRowColumn(<a href={"mailto:"+data.recResEmail}>{this.showSearchResult(data.recResEmail)}</a>, "receiveResponsibleEmail")}
				{this.renderRowColumn(this.findValue(data.recResPhone), "receiveResponsiblePhone")}
				{this.renderRowColumn(this.findValue(data.recResFun), "receiveResponsibleFunction")}

				{this.renderRowColumn(this.showSearchResult(data.sendContactname), "sendingContactname")}
				{this.renderRowColumn(<a href={"mailto:"+data.sendEmail}>{this.showSearchResult(data.sendEmail)}</a>, "sendingEmail")}
				{this.renderRowColumn(this.findValue(data.sendPhone), "sendingPhone")}
				{this.renderRowColumn(this.findValue(data.sendFunction), "sendingFunction")}

				{this.renderRowColumn(this.showSearchResult(data.sendResName), "sendingResponsibleName")}
				{this.renderRowColumn(<a href={"mailto:"+data.sendResEmail}>{this.showSearchResult(data.sendResEmail)}</a>, "sendingResponsibleEmail")}
				{this.renderRowColumn(this.findValue(data.sendResPhone), "sendingResponsiblePhone")}
				{this.renderRowColumn(this.findValue(data.sendResFun), "sendingResponsibleFunction")}


				{this.renderRowColumn(this.showSearchResult(data.erasmusCodeReceiving), "receivingErasmusCode")}
				{this.renderRowColumn(this.showSearchResult(data.uniNameRecive), "receivingInstitution")}
				{this.renderRowColumn(this.showSearchResult(data.countryRecive), "country")}

				{this.renderRowColumn(this.showSearchResult(data.erasmusCodeSending), "sendingErasmusCode")}
				{this.renderRowColumn(this.showSearchResult(data.uniNameSend), "sendingInstitution")}
				{this.renderRowColumn(this.showSearchResult(data.countrySend), "sendingCountry")}

				{this.renderRowColumn(this.renderSignedBy(data.status), "signed")}
				{this.renderRowColumn(<Courses type="HomeCourses" OlaID={data.olaID} openOrCloseCoursesEvent={this.openOrCloseCoursesEvent} openCourses={this.state.openCourses} />, "homeCourses")}
				{this.renderRowColumn(<Courses type="AbroadCourses" OlaID={data.olaID} openOrCloseCoursesEvent={this.openOrCloseCoursesEvent} openCourses={this.state.openCourses} />, "hostCourses")}

				{this.renderRowColumn(this.renderSignedBy(data.changesStatus), "signedChanges")}
				{this.renderRowColumn(<Courses type="HomeCourses" changes={true} OlaID={data.olaID} ChangesOlaID={data.changesOlaID} openOrCloseCoursesEvent={this.openOrCloseCoursesEvent} openCourses={this.state.openCourses} />, "homeCoursesChanges")}
				{this.renderRowColumn(<Courses type="AbroadCourses" changes={true} OlaID={data.olaID} ChangesOlaID={data.changesOlaID} openOrCloseCoursesEvent={this.openOrCloseCoursesEvent} openCourses={this.state.openCourses} />, "hostCoursesChanges")}

			</div>
    	);
    }

};
const updateTutorial = gql`
  mutation updateTutorial($id: ID!, $didShiftClickList: Int!){
    updateTutorialList(id: $id , didShiftClickList: $didShiftClickList){
      erasmusCode
    }
  }
`

export default compose(
    graphql(updateTutorial, {name: "updateTutorial"})
)(Row);
