import React, { Component } from 'react';
import intl from 'react-intl-universal';
import SignatureCanvas from 'react-signature-canvas'
import GlobalVar from '../../../../GlobalVar';

class DeclineOLA extends Component {
	constructor(props){
		super(props);
		this.state = {
			error: false,
			errorText: "",
			value: ""
		}
	}
	toPreviousPage(event){
		if(event){
			event.preventDefault();
		}
		this.props.history.goBack();
	}



	generateErrorMessage(){
		if(this.state.error){
			return <p className="error">{this.state.errorText}</p>;
		}
	}

	validate(signatureAsDataString){
		if(this.refs.signPad.isEmpty()){
			this.setState({
				error: true,
				errorText: "Please give a signature inside the box (use your mouse)"
			})
			return false;
		}
		else if(signatureAsDataString.length < 5500){
			this.setState({
				error: true,
				errorText: "Please give a larger signature"
			});
			return false;
		}
		this.setState({
			error: false,
			errorText: ""
		})
		return true;
	}
	confirmSignature(event){
		event.preventDefault();
		let signatureAsDataString = this.refs.signPad.getCanvas().toDataURL();
		if(this.validate(signatureAsDataString)){
			signatureAsDataString = signatureAsDataString.replace("data:image/png;base64,", "");
			//signatureAsDataString = signatureAsDataString.replace(" ", "+");

			//const buffer = new Buffer(signatureAsDataString, 'base64');
			//signatureAsDataString = buffer.toString();

			this.props.signOLAfunction(signatureAsDataString, GlobalVar.getParameterByName("documentType"));
			this.toPreviousPage();
		}
	}
	clearSignature(event){
		event.preventDefault();
		this.refs.signPad.clear();
	}
	render() {
		return (
			<div>
				<div onClick={() => this.toPreviousPage()} className="black_background"></div>
				<div className="content popup sign_ola_pop_up">
					<h3>Please sign the document</h3>
					<SignatureCanvas penColor='black' ref="signPad" canvasProps={{width: 400, height: 200, className: 'signingCanvas'}} />
					{this.generateErrorMessage()}
					<div className="button_list">
						<form onSubmit={(event) => this.confirmSignature(event)}>
							<button>Confirm</button>
						</form>
						<form onSubmit={(event) => this.clearSignature(event)} >
							<button>Clear</button>
						</form>
					</div>
					<p className="signing_text">Commitment
						<br/>
						By signing this document, the student, the sending institution and the receiving institution confirm that they approve the proposed Learning Agreement and that they will comply with all the arrangements agreed by all parties. Sending and receiving institutions undertake to apply all the principles of the Erasmus Charter for Higher Education relating to mobility for studies (or the principles agreed in the inter-institutional agreement for institutions located in partner countries).
						<br/>
						The receiving institution confirms that the educational components listed in Table A are in line with its course catalogue.
						<br/>
						The sending institution commits to recognise all the credits gained at the receiving institution for the successfully completed educational components and to count them towards the student’s degree as described in Table B. Any exceptions to this rule are documented in an annex of this Learning Agreement and agreed by all parties.
						<br/>
						The student and receiving institution will communicate to the sending institution any problems or changes regarding the proposed mobility programme, responsible persons and/or study period.
					</p>
				</div>
			</div>
		);
	}
};

export default DeclineOLA;
