import gql from 'graphql-tag';

const query = gql`
  query{
    terms{
      id
      terms
    }
  }
` 
export { query };