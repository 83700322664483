import gql from 'graphql-tag';
const query = gql`
	query{
	  countries{
	    ID
	    CountryName
	    CountryLink
	  }
	}
`;
export { query };