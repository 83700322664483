import gql from 'graphql-tag';

const email = localStorage.getItem("Email");
const query = gql`
  query {
    singleAccountEmail(email: "${email}"){
      AcceptedNewestTerms
    }
    singleInstitutionRegistrationByEmail(email: "${email}"){
      AcceptedNewestTerms
    }
    singleUnkownAccountEmail(email: "${email}"){
      AcceptedNewestTerms
    }
  }
`
export { query };
