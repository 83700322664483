import React, { Component } from "react";

class Label extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.className} name={this.props.name} groupname={this.props.groupname}>
                <h3>{this.props.newLine ? <br/> : <React.Fragment/>}{this.props.text}</h3>
            </div>
        );
    }
}

export default Label;

/*
Props attributes:
    * div:
        - className
        - name
    * h3:
        - text
*/