import React, { Component } from 'react';

class HelpBox extends Component {
	render() {
		const {id, title, description} = this.props;
		if(this.props.top == "true"){
			return (
				<div className="top_wrapper">
					<h4>{title}</h4>
					<p>{description}</p>
				</div>
			)
		} else {
			return (
				<div className="help_wrapper" id={id}>
					<h4>{title}</h4>
					<p>{description}</p>
				</div>
		    )
		}    
	}
};

export default HelpBox;
