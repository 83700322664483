import gql from 'graphql-tag';

let erasmusCode = "G THESSAL01";
if (localStorage.getItem("currentErasmusCode")){
  erasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
}

const query = gql`

query{
  usefulInfoList(erasmusCode: "${erasmusCode}"){
    externalID
    usefulInfoID
    title
  }
}
`
export { query };