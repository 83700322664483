import React, { Component } from 'react';

import { query } from '../../../queries/getDocuments';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import ReactQuill from 'react-quill';

import GenerateDocumentRow from './GenerateDocumentRow'

class Documentation extends Component { 
	constructor(props) {
		super(props);
		this.state = {
			description : "",
			title : ""
		}
	}
      
    AddDocument(event){
    	event.preventDefault();
    	const {title, description} = this.state;
        this.props.addDocument({
			variables:{
				title,
				description
			},
            refetchQueries: [{query}]
		})
		this.setState({
			title : "",
			description : ""
		})
	}

	generateDocuments(){
	    return this.props.data.documents.map(doc =>{
		    return <div key={doc.id}><GenerateDocumentRow doc={doc}/></div>;
		})
	}
	render() {
		if(this.props.data.loading){
			return (
				<div className="documentation">
			    </div>   
		    );
		}
		if(!this.props.data.loading){
		    return (
		       <div className="documentation">
		       		<div className="card">
		       			{this.generateDocuments()}
					</div>
		            
		            <div className="card NewDiscussion">
			            <div className = "opinion">
				            <input
					          type="text"
					          className="titleInput"
					          placeholder={'Document title...'}
					          value={this.state.title}
					          onChange={(event) => { this.setState({title: event.target.value}) }}
					        />
				            <ReactQuill 
			                theme={'snow'} value={this.state.description} onChange={(value) => this.setState({description: value})}/>
		                    <form  onSubmit={(event) => this.AddDocument(event)}><button className = "fullwidth font">post</button></form>
				        </div>
			        </div> 
			    </div>   
		    );
		}
	}
}
const addDocument = gql`
  mutation addDocument($title: String!,$description: String!){
    addDocument(title: $title, description: $description){
      id
    }
  }
`

export default compose(
  graphql(query),
  graphql(addDocument, { name: 'addDocument'})
)(Documentation);