import gql from 'graphql-tag';

const query = gql`
  query{
    institutionEmail(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      ErasmusCode
      Title
      BodyText
    }
  }
`
export { query };
