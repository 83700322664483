import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentCountries';
import { graphql } from 'react-apollo';

class SelectCountry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}

	renderCountries(){
		return this.props.data.getStudentCountries.map(({CountryName, count}, index) => {
			let extractedCountryName = CountryName;
			if(extractedCountryName == ""){
				extractedCountryName = "Not found";
			}
			return <option key={index} value={extractedCountryName} >{extractedCountryName} ({count})</option>;
		})
	}

	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};

		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		//console.log(filters);
		filters["country"] = event.target.value;
		filters["institution"] = 0;

		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}

	componentWillMount() {
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			if(filters["country"]){
				this.setState({
					value: filters["country"]
				})
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if(localStorage.getItem("filters"+nextProps.typeOfStudentName) === '{"signedBefore":"-1","signedDuring":"-1"}' && this.state.value != ""){
			this.setState({
				value: ""
			})
		}
	}

	render() {

		if(this.props.data.loading){
	    	return (
	    		<select><option>Loading...</option></select>
	      );
    	}
    	else{
    		return (
					<select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
						<option value="">All</option>
						{this.renderCountries()}
					</select>
	      );
    	}
    }

};

export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			Uploaded: props.Uploaded,
			AcademicYear: props.AcademicYear,
			StudyCycle: props.StudyCycle,
			SubjectArea: props.SubjectArea,
			SignedBefore: Number(props.SignedBefore),
			SignedDuring: Number(props.SignedDuring)
		}
	}}
})(SelectCountry);
