import React, { Component } from 'react';
import { query } from '../../../../queries/getDefaultEmail';
import { graphql } from 'react-apollo';
import PreMadeEmail from './PreMadeEmail';
import intl from 'react-intl-universal';
import GlobalVar from '../../../../GlobalVar';
import { Link } from 'react-router-dom';
import Help from '../../../general/Help';

class SendMail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMail: 0,
			emailSubject: "",
			emailText: ""
		}
		this.setSelectedMail = this.setSelectedMail.bind(this);
	}

	toPreviousPage(){
		this.props.history.goBack();
	}

	setSelectedMail(selectedMail){
		this.setState({selectedMail})
	}

	sendPreMadeEmail(event){
		event.preventDefault();
		const selectedMail = JSON.parse(this.state.selectedMail);
		let sendPushNotification = false;
		if(localStorage.getItem("unknownEmail") == null){
			// sendPushNotification = this.refs.sendPushNotificationDefaultEmail.checked;
		}
		this.props.sendMailFunction(selectedMail.title, selectedMail.bodyText, sendPushNotification);
		this.props.history.push(GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List"));
	}

	sendNewEmail(event){
		event.preventDefault();
		let sendPushNotification = false;
		if(localStorage.getItem("unknownEmail") == null){
			// sendPushNotification = this.refs.sendPushNotificationNewEmail.checked;
		}
		this.props.sendMailFunction(this.state.emailSubject, this.state.emailText, sendPushNotification);
		this.props.history.push(GlobalVar.generateLinkUrl(intl, this.props.typeOfStudentName+" students", "Student List"));
	}

	renderSendPreMadeEmailButton(){
		if(this.state.selectedMail !== 0){
			return(
				<form onSubmit={(event) => this.sendPreMadeEmail(event)} >
					<button>Send mail</button>
				</form>
			)
		}
		return (
			<form onSubmit={(event) => event.preventDefault()} >
				<button className="disabled">Send mail</button>
			</form>
		)
	}

	renderNewEmailButton(){
		if(this.state.emailSubject != "" && this.state.emailText != ""){
			return(
				<form onSubmit={(event) => this.sendNewEmail(event)} >
					<button>Send mail</button>
				</form>
			)
		}
		return (
			<form onSubmit={(event) => event.preventDefault()} >
				<button className="disabled">Send mail</button>
			</form>
		)
	}

	renderPreMadeEmail(){
		if(this.props.data.loading){
			return "loading...";
		}
		return this.props.data.institutionEmail.map((emailData) => {
			return <PreMadeEmail setSelectedMail={this.setSelectedMail} emailData={emailData} key={emailData.id} />;
		})
	}

	generatePushNotificationRequest(inputRef){
		if(localStorage.getItem("unknownEmail") == null){
			return(
				<p className="push_notification_request">
					<input
						type="checkbox"
						ref={inputRef}
					/>
					Also send push notification
					<Help
						id="send_push_notification"
						title="Push notification"
						description ="Please bear in mind that students can turn-off push notifications on their phones, therefore we advice you not to overuse this feature. It also means we cannot guarantee that all students will receive the push-notification alongside the email (the message will anyways be delivered to their App accounts and emails)."
					/>
				</p>
			)
		}
		else{
			return(
				<p className="push_notification_request">
					Push notifications disabled
					<Help
						id="send_push_notification"
						title="Push notification"
						description ="You have to register in order to send push notifications. To register, logout and follow the instructions"
					/>
				</p>
			)
		}
	}


	render() {
		return (
			<div>
				<div onClick={() => this.toPreviousPage()} className="black_background"></div>
				<div className="content popup send_mail_pop_up">
					<div className="sub_content">
						<h3>Send default email</h3>
						<p className="p">Default emails can be made <Link to={GlobalVar.generateLinkUrl(intl, "Other", "My settings")}>here</Link></p>
						<ul>
							{this.renderPreMadeEmail()}
						</ul>
						{/* {this.generatePushNotificationRequest("sendPushNotificationDefaultEmail")} */}
						{this.renderSendPreMadeEmailButton()}
					</div>
					<div className="sub_content">
						<h3>Send new email</h3>
						<form>
							<input value={this.state.emailSubject} onChange={(event) => this.setState({emailSubject:event.target.value})} placeholder="Email subject" type="text"/>
							<textarea value={this.state.emailText} onChange={(event) => this.setState({emailText:event.target.value})} placeholder="Email text" />
						</form>
						{/* {this.generatePushNotificationRequest("sendPushNotificationNewEmail")} */}
						{this.renderNewEmailButton()}
					</div>
				</div>
			</div>
		);
	}
};

export default graphql(query)(SendMail);
