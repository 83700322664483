import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import GlobalVar from '../../../../../GlobalVar';
import RegExps from '../../../../../RegExps';
import FileBase64 from 'react-file-base64';
import DatePicker from "react-datepicker";
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
}from 'react-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import Help from '../../../../general/Help';

class DefaultEventData extends Component {
	constructor(props) {
		super(props);

		this.state = {
			//new Event
			title: "",
			body:{
				summary: "",
				value: ""
			},
			organisations: "",
			image: [],
			programmes: "",
			mobilityPhases: "",
			tags: [],
			eventStartDate: "",
			eventEndDate: "",
			website: "",
			address: "",
			geolocation: ""
		}

	}

	  // Callback~
	  getFiles(image){
		this.setState({ image: image })
	  }	  

	handleInputChange(event) {
		console.log(event);
		if (event.keyCode == 13 && !event.shiftKey) {
			event.preventDefault();
		}

		if(event.target != null) {
			let value = event.target.value;
			let name = event.target.name;


			let isValid = false;
			let message = "";

			if (value != "") {
				if (name == "website" ) {
					message = this.hasValidFormat("websiteHTTPS", value);
				}
			}
			if (message == "") {
				isValid = true;
			}

			if (isValid) {
				document.getElementById("error_" + name).innerHTML = "";
			}
			else {
				document.getElementById("error_" + name).innerHTML = message;
				document.getElementById("error_" + name).style.color = "red";
			}
			this.setState({ [name]: value }, () => { console.log(this.state) });
		}
	}
	handleSelectChange(selectName) {
		console.log("select " + selectName);
		let select1 = document.getElementById(selectName);
		let selected1 = [];

		for (let i = 0; i < select1.length; i++) {
			if (select1.options[i].selected) selected1.push(select1.options[i].value);
		}
		this.setState({ [selectName]: selected1 }, () => { console.log(this.state[selectName]); });
	}

	handleTextareaChange(event) {
		const { body } = this.state;
		body[event.target.name] = event.target.value;
		this.setState({
		  body
		});
	}
    handleCheckboxChange = event => {
		let newArray = [...this.state.tags, event.target.id];
		if (this.state.tags.includes(event.target.id)) {
			newArray = newArray.filter(tag => tag !== event.target.id);
		}
		this.setState({
		tags: newArray
		});
    };
	
	handleStartChange(date) {
		this.setState({eventStartDate: date});
	// this.setState({
    //   eventStartDate: date.toLocaleString()
    // })
	}
	handleEndChange(date) {
		this.setState({eventEndDate: date });
	 // this.setState({
	//   eventEndDate: date.toLocaleString()
	// })
	}
	handleChangeAddress = address => {
	this.setState({ address });
	//this.setState({location: Object.assign({}, this.state.location, { address: address} });
	};
	handleSelectAddress = address => {
	geocodeByAddress(address)
	  .then(results => getLatLng(results[0]))
	  .then(latLng => this.setState({address: address, geolocation: latLng}))
	  //.then(latLng => console.log('Success', latLng))
	  //.then(latLng => this.setState({location: Object.assign({}, this.state.location, { address: address} })
	  //.then(latLng => this.setState({location: Object.assign({}, this.state.location, { geolocation: latLng} })
	  .catch(error => console.error('Error', error));
	};

	handleClick(address) {
	geocodeByAddress(address.description)
		.then(results => getLatLng(results[0]))
		.then(latLng => this.setState({address: address.description, geolocation: latLng}))
		.catch(error => console.error('Error', error));
	}

	createEventData(){
		var newWebsite = this.state.website;
		var newTitle =  this.state.title;
		// var newbody = this.state.body; 			not needed
		var newSummary =  this.state.body.summary;
		var newValue =  this.state.body.value;
		var newImage = this.state.image.base64;
		console.log("The image base64 is : " + newImage);
		var newImageSize = this.state.image.size;
		var imagecontrol = this.state.image;
		//console.log(Object.keys(imagecontrol).length);
		if (Object.keys(imagecontrol).length > 0) {
		newImageSize = newImageSize.replace(/\D/g,'');
		newImageSize = parseInt(newImageSize);
		//console.log(typeof newImageSize);
		//console.log(newImageSize);
		}
		var newProgrammes = this.state.programmes;
		var newmobilityPhases = this.state.mobilityPhases;
		var newtags = this.state.tags;
		// TODO time
		var neweventStartDate = this.state.eventStartDate.toLocaleDateString('en-GB');
		var neweventEndDate = this.state.eventEndDate.toLocaleDateString('en-GB');
		console.log(neweventStartDate);
		var newAddress = this.state.address;
		var newGeolocation = this.state.geolocation.lat + "," + this.state.geolocation.lng;
		console.log("The geostring is : " + newGeolocation);
		
		if (this.hasValidFormat("websiteHTTPS", newWebsite) == "" || this.state.website == "") {
			if(Object.keys(imagecontrol).length > 0 && newImageSize > 3300){
				alert("The form will not be submitted. Image size exceeds 3 MB limit.") 
			} else {
			console.log("in CreateEvent")
					this.props.CreateEvent({
						variables: {
							"title": newTitle,
							"body":{
								"summary": newSummary,
								"value": newValue
							},
							"organisations": JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode,
							"image": newImage,
							"programmes": newProgrammes,
							"mobilityPhases": newmobilityPhases,
							"tags": newtags,
							"eventStartDate": neweventStartDate,
							"eventEndDate": neweventEndDate,
							"website": newWebsite,
							"location": {
								"address": newAddress,
								"geolocation": newGeolocation
							}
						}
					}).then((response) => {
						console.log("in CreateEvent2");

						if (response.data.createEvent.success) {
							alert('Successfully submitted New Event')
							console.log('event created')
						} else {
							alert('Error response on submitting New Event: '+ response.data.createEvent.errorMsg)
							console.log('error response with event:' + response.data.createEvent.errorMsg)
						}
					});
			}
				//}
			//);
		}
		else {
			alert("Please make sure all values inserted are valid.");
		}
	}




	/**
	 * Check if value has the correct format for the type of input.
	 *
	 * @param {String} type
	 * @param {String} value
	 *
	 * @return {String} message with error or empty if there is no error
	 */
	hasValidFormat(type, value) {
		let valueTestRExp = [];

		switch (type) {
			/*case "dateDDMM":
          valueTestRExp = RegExps.dateDDMM.exec(value);
          break;
      case "email":
          valueTestRExp = RegExps.email.exec(value);
          break;*/
			case "websiteHTTPS":
				valueTestRExp = RegExps.websiteHTTPS.exec(value);
				break;
			/*case "phone":
          valueTestRExp = RegExps.phone.exec(value);
          break;*/
		}

		if (!valueTestRExp) {
			let message = "";

			switch (type) {
				/* case "dateDDMM":
             message = "Please enter a date in dd/mm format.";
             break;
         case "email":
             message = "Please enter a valid email (ex: user@example.com).";
             break;*/
				case "websiteHTTPS":
					message = "Please enter an URL that start with http or https.";
					break;
				/*case "phone":
            message = "Please enter a phone number that start with your country code (ex: +000)."
            break;*/
				default:
					message = "Please insert a valid value.";
			}
			return message;
		}
		return "";
	}


	render() {
		// const {NameInLocalCharacterSet, NameInLatinCharacterSet, PIC, ErasmusCode, EndDate, City, InstitutionDetail} = this.props.data.institution;
		// let currentErasmusCode = ({ erasmusCode: ErasmusCode })
		// localStorage.setItem("currentErasmusCode", JSON.stringify(currentErasmusCode));
		//console.log("props from genral info",this.props,typeof this.state.phoneNumber)

		return (
			<div className="content createEvent">
				<div className="card card_full_width card_full_height">
					<div className="custom_table">
						<h2>Add a New Event</h2>

						<table>
						  <tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right'}}>
							What <a style={{color:'red'}}>*</a>
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
							<div className="row header_row">
								<div className="column_long">
									<input type="text" name="title" value={this.state.title} placeholder="Title" onChange={(event) => this.handleInputChange(event)} />
									<p id="error_title"/>
								</div>
							</div>
							<div className="row header_row">
								<div className="column_long">
									<label>
										<textarea rows="8" cols="55" name="summary" value={this.state.body.summary} placeholder="Short summary" onChange={(event) => this.handleTextareaChange(event)} />
									</label>
									<p id="error_summary"/>
								</div>
							</div>
							<div className="row header_row">
								<div className="column_long" >
									<label>
										<textarea rows="12" cols="55" name="value" value={this.state.body.value} placeholder="More information for the student" onChange={(event) => this.handleTextareaChange(event)} />
									</label>
									<p id="error_value"/>
								</div>
							</div>
							</td>
						  </tr>
						  <tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right', verticalAlign: 'middle'}}>
						  Where
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
							<div className="row header_row">
								<div className="column">
									<PlacesAutocomplete value={this.state.address} onChange={this.handleChangeAddress} onSelect={this.handleSelectAddress}  >
										{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										  <div>
											<input
											  {...getInputProps({
												placeholder: 'Address',
												className: 'location-search-input',
											  })}
											/>
											<div className="autocomplete-dropdown-container">
											  {loading && <div>Loading...</div>}
											  {suggestions.map(suggestion => {
												const className = suggestion.active
												  ? 'suggestion-item--active'
												  : 'suggestion-item';
												// inline style for demonstration purpose
												const style = suggestion.active
												  ? { backgroundColor: '#fafafa',  border: '2px solid', cursor: 'pointer' }
												  : { backgroundColor: '#ffffff',  border: '2px solid', cursor: 'pointer' };
												return (
												  <div
													{...getSuggestionItemProps(suggestion, {
													  className,
													  style,
													})} onClick={(e) => {this.handleClick(suggestion)}}
												  >
													<span>{suggestion.description}</span>
												  </div>
												);
											  })}
											</div>
										  </div>
										)}
									  </PlacesAutocomplete>
									<p id="error_addAdress"/>
								</div>
							</div>
							</td>
						  </tr>
						  <tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right', verticalAlign:'top', paddingTop:14}}>
						  When <a style={{color:'red'}}>*</a>
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
						  <div className="row header_row">
							<div className="column">
								Start Date
								<DatePicker dateFormat="dd/MM/yyyy" selected={this.state.eventStartDate} onChange={ (date) =>this.handleStartChange(date) } />
								<p id="error_eventStartDate"/>
							</div>
							<div className="column">
								End Date
								<DatePicker dateFormat="dd/MM/yyyy" selected={this.state.eventEndDate} onChange={(date) =>this.handleEndChange(date)}/>
								<p id="error_eventEndDate"/>
							</div>
						  </div>
						  </td>
						  </tr>
						  <tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right', verticalAlign:'top', paddingTop:14}}>
						  <div>
						  For whom <a style={{color:'red'}}>*</a>
						  </div>
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
						  <div className="row">
							<div className="column">
								<label>
									<select name="programmes" value={this.state.programmes} placeholder="Programme" onChange={(event) => this.handleInputChange(event)}>
										<option value="" selected="selected">Select programme</option>
										<option value="Higher Education Study Mobility">Higher Education Study Mobility</option>
										<option value="Higher Education Traineeship Mobility">Higher Education Traineeship Mobility</option>
										<option value="Erasmus Mundus">Erasmus Mundus</option>
									</select>
								</label>
								<p id="error_programmes"/>
							</div>
							<div className="column">
								<label>
									<select name="mobilityPhases" value={this.state.mobilityPhases} placeholder="Mobility" onChange={(event) => this.handleInputChange(event)}>
										<option value="" selected="selected">Select mobility phase</option>
										<option value="Before application">Before application</option>
										<option value="During application">During application</option>
										<option value="Before you go">Before you go</option>
										<option value="During mobility">During mobility</option>
										<option value="After mobility">After mobility</option>

									</select>
								</label>
								<p id="error_mobilityPhases"/>
							</div>
						  </div>
						  <div className="row">
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Accessibility" value="Accessibility" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Accessibility">Accessibility</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Safety" value="Safety" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Safety">Safety</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Accommodation" value="Accommodation" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Accommodation">Accommodation</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Volunteering & Social outreach" value="Volunteering & Social outreach" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Volunteering & Social outreach">Volunteering & Social outreach</label>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Culture & Free time" value="Culture & Free time" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Culture & Free time">Culture & Free time</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Food & Drinks" value="Food & DrinksFood & Drinks" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Food & Drinks">Food & Drinks</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Travel insurance" value="Travel insurance" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Travel insurance">Travel insurance</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Student Visa" value="Student Visa" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Student Visa">Student Visa</label>
								</div>
							</div>
							<div className="row">
							<div className="column">
									<input type="checkbox" id="Education & Training" value="Education & Training" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Education & Training">Education & Training</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Health & Well-being" value="Health & Well-being" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Health & Well-being">Health & Well-being</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Managing your money" value="Managing your money" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Managing your money">Managing your money</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Going green & Environment" value="Going green & Environment" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Going green & Environment">Going green & Environment</label>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Technology and Communications" value="Technology and Communications" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Technology and Communications">Technology & Communications</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Travel & Transport" value="Travel & Transport" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Travel & Transport">Travel & Transport</label>
								</div>
								
								<div className="column">
									<input type="checkbox" id="Paperwork & Documents" value="Paperwork & Documents" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Paperwork & Documents">Paperwork & Documents</label>
								</div>
								<div className="column">
									<input type="checkbox" id="General" value="General" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="General">General</label>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Check the deadlines" value="Check the deadlines" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Check the deadlines">Check the deadlines</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Information sessions" value="Information sessions" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Information sessions">Information sessions</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Application for Erasmus" value="Application for Erasmus" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Application for Erasmus">Application for Erasmus</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Learning Agreement" value="Learning Agreement" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Learning Agreement">Learning Agreement</label>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Grant Agreement" value="Grant Agreement" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Grant Agreement">Grant Agreement</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Recognition" value="Recognition" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Recognition">Recognition</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Transcript of Records" value="Transcript of Records" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Transcript of Records">Transcript of Records</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Traineeships" value="Traineeships" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Traineeships">Traineeships</label>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<input type="checkbox" id="Shopping" value="Shopping" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Shopping">Shopping</label>
								</div>
								<div className="column">
									<input type="checkbox" id="Sports" value="Sports" onChange={(event) => this.handleCheckboxChange(event)}/>
									<label for="Sports">Sports</label>
								</div>
							</div>
							<p id="error_tags"/>
						</div>
						 </td>
						</tr>
						<tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right', verticalAlign:'top'}}>
						  Website
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
						  
						  <div className="row header_row">
							<div className="column_long">
								<input type="text" name="website" value={this.state.website} placeholder="Website" onChange={(event) => this.handleInputChange(event)} />
								<p id="error_website"/>
							</div>
						  </div>
						  </td>
						</tr>
						<tr>
						  <td style={{paddingRight:10, width:85, textAlign: 'right', verticalAlign:'middle'}}>
						  <div>
						  Image
						  </div>
						  </td>
						  <td style={{paddingLeft:15, borderLeft:'solid'}}>
						  <div className="row header_row">
						    <div className="column">
								<FileBase64 multiple={ false } onDone={ this.getFiles.bind(this) } />
								<p id="error_image"/>
							</div>
							<div className="column">
								<Help title={"Image Size Limit"} description={"The image size should not exceed 3 MB"} />
							</div>
						  </div>
						  </td>
					    </tr>
					</table>
					<div className="row">
					<br/>
					<p style={{width:900, textAlign:'justify'}}>Only submit content that you own. By submitting an update, you warrant that you have all the necessary rights to use and license the content and that this does not violate, misappropriate or infringe any law or any third parties' rights, including, without limitation, privacy rights and copyright. See more information in Terms and Conditions and Privacy Policy.</p>
					<br/>
					</div>
					<div className="row">
						<div className="column_long" id=""><button onClick={() => this.createEventData()}>Submit & Publish on the Erasmus+ App</button></div>
					</div>
				</div>
				</div>
			</div>
		);
	}
}

const mutationCreateEvent = gql`
	mutation createEvent($title: String, $body: BodyInput, $organisations: [String], $image: [String], $programmes: [String], $mobilityPhases: [String], $tags: [String], $eventStartDate: String, $eventEndDate: String, $location: [LocationInput], $website: [String]){
		createEvent(title: $title, body: $body, organisations: $organisations, image: $image, programmes: $programmes, mobilityPhases: $mobilityPhases, tags: $tags, eventStartDate: $eventStartDate, eventEndDate: $eventEndDate, location: $location, website: $website){
			success
			errorMsg
		}
	}
`;


export default compose(
	graphql(mutationCreateEvent,{ name : 'CreateEvent'})
)(DefaultEventData);