import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query } from '../../../queries/getAccountData';
import AccountRow from './AccountRow';
import Help from '../../general/Help';

class AccountTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key : "",
            sortName: "",
            sortEmail: "",
            sortRole: "",
		}
    }


	generateAccounts(data){
		return data.map((account, key) => {
			return <AccountRow key ={key} account={account} accountFunctions={this.props.accountFunctions}/>;

		})
	}

	onClickName() {
		if(this.state.sortName === "" || this.state.sortName === "desc") {
			this.setState({
				sortName:"asc",
				sortEmail:"",
				sortRole:""
			});
		}
		if(this.state.sortName === "asc") {
			this.setState({
				sortName:"desc"
			});
		}
	}

	onClickEmail() {
		if(this.state.sortEmail === "" || this.state.sortEmail === "desc") {
			this.setState({
				sortEmail:"asc",
				sortName:"",
				sortRole:""
			});
		}
		if(this.state.sortEmail === "asc") {
			this.setState({
				sortEmail:"desc"
			});
		}
	}

	onClickRole() {
		if(this.state.sortRole === "" || this.state.sortRole === "desc") {
			this.setState({
				sortRole:"asc",
				sortName:"",
				sortEmail:""
			});
		}
		if(this.state.sortRole === "asc") {
			this.setState({
				sortRole:"desc"
			});
		}
	}

	sortRows(rows, property, sortBy){
        if(this.state[sortBy] === 'asc') {
            rows.sort(function(a,b){
            	if(sortBy === "sortRole" && a[property] != null && b[property] != null){
            		return a["AccountFunction"].Name.localeCompare(b["AccountFunction"].Name);
            	} else if(a[property] != null && b[property] != null) {
                	return a[property].localeCompare(b[property]);
            	}
            })
        }
        if(this.state[sortBy] === 'desc') {
            rows.sort(function(a,b){
            	if(sortBy === "sortRole" && a[property] != null && b[property] != null){
            		return b["AccountFunction"].Name.localeCompare(a["AccountFunction"].Name);
            	} else if(a[property] != null && b[property] != null) {
                	return b[property].localeCompare(a[property]);
            	}
            })
        }
        return rows
    }

	render() {
		if(this.props.data.length > 0) {
			let data = this.props.data;
			if(this.state.sortName) {
	            data = this.sortRows(data, "Name", "sortName");
	        }
	        if(this.state.sortEmail) {
	            data = this.sortRows(data, "Email", "sortEmail");
	        }
	        if(this.state.sortRole) {
	            data = this.sortRows(data, "AccountFunction", "sortRole");
	        }
	    	return (
	    		<table>
	    			<thead>
		    			<tr>
		    				<th onClick={() => this.onClickName()} style={{"cursor": "pointer"}}>
		    					Name
		    					<span>
		    						{(this.state.sortName==='asc') ? (<img className="arrow" src="/icons/downArrow-blue.svg" />):((this.state.sortName==="desc")?(<img className="arrow" src="/icons/upArrow-blue.svg" />):(<span/>))}
		    					</span>
	    					</th>
		    				<th onClick={() => this.onClickEmail()} style={{"cursor": "pointer"}}>
		    					Email
		    					<span>
		    						{(this.state.sortEmail==='asc') ? (<img className="arrow" src="/icons/downArrow-blue.svg" />):((this.state.sortEmail==="desc")?(<img className="arrow" src="/icons/upArrow-blue.svg" />):(<span/>))}
		    					</span>
		    				</th>
		    				<th>Phone nr</th>
		    				<th onClick={() => this.onClickRole()} style={{"cursor": "pointer"}}>
		    					Role
		    					<span>
		    						{(this.state.sortRole==='asc') ? (<img className="arrow" src="/icons/downArrow-blue.svg" />):((this.state.sortRole==="desc")?(<img className="arrow" src="/icons/upArrow-blue.svg" />):(<span/>))}
		    					</span>
		    				</th>
							<th>Action</th>
		    			</tr>
	    			</thead>
	    			<tbody>
	    				{this.generateAccounts(data)}
	    			</tbody>
	    		</table>
	      	);
      	} else {
      		return <table></table>;
      	}
    }
};



export default AccountTable;
