import gql from 'graphql-tag';

const query = gql`
	query getStudentInstitutions($Sending:Int, $CountryName:String, $SearchQuery: String, $StartMobility: String, $EndMobility: String, $SignedBefore: Int, $SignedDuring: Int, $Uploaded: String, $AcademicYear: String, $StudyCycle: String, $SubjectArea: String){
		getStudentInstitutions(UnKnownEmail:"${localStorage.getItem('unknownEmail')}",ErasmusCode: "${localStorage.getItem('ErasmusCode')}", Sending:$Sending, CountryName: $CountryName, SearchQuery: $SearchQuery, StartMobility: $StartMobility, EndMobility: $EndMobility, SignedBefore: $SignedBefore, SignedDuring: $SignedDuring, Uploaded: $Uploaded, AcademicYear: $AcademicYear, StudyCycle: $StudyCycle, SubjectArea: $SubjectArea){
			InstitutionName
			count
		}
	}
`
export { query };
