import gql from 'graphql-tag';
const query = gql`
query singleInstitutionRegistration($id: ID){
  singleInstitutionRegistration(id: $id){
    Institution{
      NameInLatinCharacterSet
      ErasmusCode
    }
    Valid
    id
    Phone
    Email
    Website
  }
}
`;
export { query };