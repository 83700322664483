import React, { Component } from 'react';
import GlobalVar from '../../../GlobalVar';
// import RevisionsTable from "./RevisionsTable";
import intl from 'react-intl-universal';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { query as getIncomingNomination } from '../../../queries/getIncomingNomination';
import Loader from 'react-loader-spinner';
import IncomingHei from './IncomingHEI';
import Confirm from './Confirm';
import LangTable from '../ExportTable';
import OtherdocTable from '../ExportOtherTable';
import OtherreqTable from '../ExportReqTable';
import OtherLink from '../ExportOtherLink';
import LangLink from '../ExportLangLink';
import { query as getManagedByEWP } from '../../../queries/getManagedByEWP';

class IncomingNomination extends Component{
	    constructor(props) {
        super(props);
        this.state = {
            key : ""
		}
    }
    toPreviousPage(event){
        event.preventDefault();
        if(this.props.location.pathname.includes("outgoing")) {
            this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
        } else {
            this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
        }
		window.location.reload();
    }



    findValue(value) {
        if(value == null || value === "" || value.includes("null")){
            return <p className="italic">Not found</p>;
        }
        return value;
    }

    checkNullValue(value) {
        if(value == null || value === "" || value.includes("null")){
            return "";
        }
        return value;
    }

	 //comment in remove function
	  acceptNomination(event){
			//event.preventDefault();
			this.props.AcceptNomination({
				variables:{
					"id": this.props.data.incomingNomination.id
				}
			})
			if(this.props.location.pathname.includes("outgoing")) {
				this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
			} else {
				this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
			}
			setTimeout(function () {
			window.location.reload();
			}, 1000);
		}

	  rejectDestination(event){
			console.log(JSON.stringify(this.props.data.incomingNomination))
			//event.preventDefault();
			this.props.RejectDestination({
				variables:{
					"id": this.props.data.incomingNomination.field_mobility_details[0].id
				}
			})
			if(this.props.location.pathname.includes("outgoing")) {
				this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
			} else {
				this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
			}
			setTimeout(function () {
			window.location.reload();
			}, 1000);
		}

    generateDownloadCV(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_cv);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadMotivation(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_motivation_letter);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }

    generateDownloadTor(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_tor);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }	

    generateDownloadRecom(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_recom_letter);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadLangLevel(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_lang_level);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadExternal(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_external_cert);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadOthers(){
		const pdfUrl = encodeURIComponent(this.props.data.incomingNomination.field_other_document);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	generateDownloadZIP(){
		const zipUrl = this.props.data.incomingNomination.id;
		return (
			<a target="_blank" href={`/incoming-student/exportzip/${zipUrl}`}>
				<button>Export all documents</button>
			</a>
		);
	}
	// 	downloadZIP(){
  //   let zip = this.props.data.studentApplicationZip ? this.props.data.studentApplicationZip.base64 : "";
  //   if (zip) {
  //     let byteCharacters = atob(zip);
  //     let byteNumbers = new Array(byteCharacters.length);
	//
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
	//
  //     let byteArray = new Uint8Array(byteNumbers);
  //     let file = new Blob([byteArray], { type: "application/zip" });
  //     const fileURL = URL.createObjectURL(file);
	// 	let zipNameNoSpace =this.props.data.incomingNomination.field_student_name;
	// 	let zipName = zipNameNoSpace.replace(/\s+/g,"_");
	// 	  let a = document.createElement('a');
	// 	  a.href = fileURL;
	// 	  a.download = zipName + '.zip';
	// 	  a.click();
  //   }
  // }

    render(){
		// style for span
		const spanStyle = {
			display: 'inline-block',
			marginBottom: '5px'
		}
		const flexStyleCenter = {
			display: 'flex',
			justifyContent: 'center'
		}

		const WaitingListButton = {
			backgroundColor: '#4CAF50',
			color: '#4CAF50',
			border: '#4CAF50'
		}

		const divisionStyle = {
			height: '135%',
			width: '50%',
			float: 'left'
		}		
		
        /*console.log(this.props.history.location.state.academicYear);*/
        if(GlobalVar.checkAccountFunction("nominations", "Nominations")){
					if (!this.props.managedEWP.managedByEWP) {
            if (this.props.data.loading) {
                return (
                    <div className={`content incomingNomination`}>
                        <div className="card card_full_width">
                            <div className="white_background">
                                <Loader
                                    className="spinner"
                                    type="TailSpin"
                                    color="#3986df"
                                    height={70}
                                    width={70} />
                            </div>
                        </div>
                    </div>
                );
            }
			window.onpopstate = function(event) {
				window.location.reload();
			};

					const error = this.props.data.incomingNomination.error;
					if(error === '403') {
						return (
							<div>
								<div className={`content incomingNomination`}>
									<div className="header">
										<form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
											<button>
												{"<"} Go back
											</button>
										</form>

									</div>
									<div className="card ">
										<div className="no_access">
											<h2>This Application does not belong to your institution</h2>
											<p>You are trying to access a Application that doesn't belong to your Higher Education Institution</p>
										</div>
									</div>
								</div>
							</div>
						);
					} else {
                // const accounts = this.props.accountInfo.accounts;
                // const accountsNames = this.getAccountsNames(accounts);
                const incomingNomination = this.props.data.incomingNomination;
                const id = this.props.data.incomingNomination.id;
                const {
                    title, field_nomination_status
                } = incomingNomination;

                const field_student_name = this.props.data.incomingNomination.field_student_name;
                const field_student_email = this.props.data.incomingNomination.field_student_email;
                const field_student_isced = this.props.data.incomingNomination.field_student_isced;
				const field_student_esi = this.props.data.incomingNomination.field_student_esi;
				const field_student_eqf = this.props.data.incomingNomination.field_student_eqf;
				const field_cv = this.props.data.incomingNomination.field_cv;
				const field_cv_link = this.props.data.incomingNomination.field_cv_link;
				const field_motivation_letter = this.props.data.incomingNomination.field_motivation_letter;
				const field_motiv_letter_link = this.props.data.incomingNomination.field_motiv_letter_link;
				const field_tor = this.props.data.incomingNomination.field_tor;
				const field_tor_link = this.props.data.incomingNomination.field_tor_link;
				const field_recom_letter = this.props.data.incomingNomination.field_recom_letter;
				const field_recom_letter_link = this.props.data.incomingNomination.field_recom_letter_link;
				const field_lang_level = this.props.data.incomingNomination.field_lang_level;
				const field_external_cert = this.props.data.incomingNomination.field_external_cert;
				const field_lang_level_link = this.props.data.incomingNomination.field_lang_level_link;
				const field_external_cert_link = this.props.data.incomingNomination.field_external_cert_link;
				const field_other_document = this.props.data.incomingNomination.field_other_document;
				const field_other_document_link = this.props.data.incomingNomination.field_other_document_link;
				const field_academic_year = this.props.data.incomingNomination.field_academic_year;
				const field_academic_term = this.props.data.incomingNomination.field_academic_term;
				const field_reference_contact = this.props.data.incomingNomination.field_reference_contact;
				const field_portfolio = this.props.data.incomingNomination.field_portfolio;
				const field_other_requirement = this.props.data.incomingNomination.field_other_requirement;
				const field_cv_required = this.props.data.incomingNomination.field_mobility_details[0].field_cv_required;
				const field_external_cert_required = this.props.data.incomingNomination.field_mobility_details[0].field_external_cert_required;
				const field_lang_level_required = this.props.data.incomingNomination.field_mobility_details[0].field_lang_level_required;
				const field_motivation_letter_required = this.props.data.incomingNomination.field_mobility_details[0].field_motivation_letter_required;
				const field_other_document_required = this.props.data.incomingNomination.field_mobility_details[0].field_other_document_required;
				const field_recom_letter_required = this.props.data.incomingNomination.field_mobility_details[0].field_recom_letter_required;
				const field_tor_required = this.props.data.incomingNomination.field_mobility_details[0].field_tor_required;
				const field_other_requirement_required = this.props.data.incomingNomination.field_mobility_details[0].field_other_requirement_required;
				const field_portfolio_required = this.props.data.incomingNomination.field_mobility_details[0].field_portfolio_required;
				const field_reference_contact_required = this.props.data.incomingNomination.field_mobility_details[0].field_reference_contact_required;
				const isNominationStatus = this.props.data.incomingNomination.field_nomination_status;
				const createdDate = new Date(this.props.data.incomingNomination.created).toLocaleDateString('en-GB',{
				  year: "numeric",
				  month: "2-digit",
				  day: "2-digit",
				  hour: '2-digit', 
				  minute: '2-digit',
				  timeZoneName: 'short'
				 });
				 
                return (
                        <div className={`content incomingNomination`}>
								<div className="header">
                                    <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                                        <button>
                                            {"<"} Go back
                                        </button>
                                    </form>
                                </div>
                               	<div className="divisionPart" style={divisionStyle}>
									<div className="card">
										<div className="sub_content">
											<h2 style={{ color: '#4a90e2' }}>Student Info</h2>
											<br />
											<div className="nominationsInfo">
												<ul className="info_list">
													<li><span className="bold" style={spanStyle}>Name: </span> <span className="value">{field_student_name}</span></li>
													<li><span className="bold" style={spanStyle}>Email: </span> <span className="value">{field_student_email}</span></li>
													<li><span className="bold" style={spanStyle}>Study Field: </span> <span className="value">{field_student_isced}</span></li>
													<li><span className="bold" style={spanStyle}>Study Level: </span> <span className="value">{field_student_eqf}</span></li>
													<li><span className="bold" style={spanStyle}>Academic Year: </span> <span className="value">{field_academic_year}</span></li>
													<li><span className="bold" style={spanStyle}>Academic Term: </span> <span className="value">{field_academic_term}</span></li>
													<li><span className="bold" style={spanStyle}>Application submission time: </span> <span className="value">{createdDate}</span></li>
												</ul>
											</div >
										</div>
									</div>
									<div className="card">
										<div className="sub_content">
											<h2 style={{ color: '#4a90e2' }}>Application Documents</h2>
											<br />
											<div className="applicationDocs">
												<ul className="info_list">
													{(field_reference_contact && field_reference_contact_required) &&
													<li><span className="bold" style={spanStyle}>Reference Contact: </span> <span className="value">{field_reference_contact}</span></li>
													}
													{(field_portfolio && field_portfolio_required) &&
													<li><span className="bold" style={spanStyle}>Portfolio: </span> <span className="value">{field_portfolio}</span></li>
													}
												{(field_cv && field_cv_required) &&	
													<li><span className="bold" style={spanStyle}>CV (file): </span> {this.generateDownloadCV()}</li>
												}
												{(field_cv_link && field_cv_required) &&
													<li><span className="bold" style={spanStyle}>CV (link): </span> <a href={field_cv_link} target="_blank">Download CV Link</a></li>
												}
												{(field_motivation_letter && field_motivation_letter_required) &&
												    <li><span className="bold" style={spanStyle}>Motivation Letter (file): </span>{this.generateDownloadMotivation()}</li>
												}
												{(field_motiv_letter_link && field_motivation_letter_required) &&
													<li><span className="bold" style={spanStyle}>Motivation Letter (link): </span> <a href={field_motiv_letter_link} target="_blank">Download Motivation Letter Link</a></li>
												}
												{(field_tor && field_tor_required) &&
												    <li><span className="bold" style={spanStyle}>Transcript of Records (file): </span> {this.generateDownloadTor()}</li>
												}
												{(field_tor_link && field_tor_required) &&
												    <li><span className="bold" style={spanStyle}>Transcript of Records (link): </span> <a href={field_tor_link} target="_blank">Download Transcript of Records Link</a></li>
												}
												{(field_recom_letter && field_recom_letter_required) &&
												    <li><span className="bold" style={spanStyle}>Recommendation Letter (file): </span> {this.generateDownloadRecom()}</li>
												}
												{(field_recom_letter_link && field_recom_letter_required) &&
												    <li><span className="bold" style={spanStyle}>Recommendation Letter (link): </span> <a href={field_recom_letter_link} target="_blank">Download Recommendation Letter Link</a></li>
												}
												{(field_lang_level && field_lang_level_required) &&
													   <LangTable lang={this.props.data.incomingNomination.field_lang_level} langFile={this.props.data.incomingNomination.field_lang_level_filename} />
												}
												{(field_lang_level_link && field_lang_level_required) &&
												<LangLink langLink={this.props.data.incomingNomination.field_lang_level_link}/>
												}
												{(field_external_cert && field_external_cert_required) &&
												    <li><span className="bold" style={spanStyle}>External Certificates (file): </span> {this.generateDownloadExternal()}</li>
												}
												{(field_external_cert_link && field_external_cert_required) &&
												    <li><span className="bold" style={spanStyle}>External Certificates (link): </span> <a href={field_external_cert_link} target="_blank">Download External Certificates</a></li>
												}
												{(field_other_document && field_other_document_required) &&
													   <OtherdocTable otherdoc={this.props.data.incomingNomination.field_other_document} />
												}
												{(field_other_document_link && field_other_document_required) &&
															<OtherLink otherLink={this.props.data.incomingNomination.field_other_document_link}/>
												}
												{(field_other_requirement && field_other_requirement_required) &&
															<OtherreqTable otherreq={this.props.data.incomingNomination.field_other_requirement} />
												}
												</ul>
												{((field_cv!=="") || (field_motivation_letter!=="") || (field_tor!=="") || (field_recom_letter!=="") || (Object.keys(field_lang_level).length !== 0) || (field_external_cert!=="") || (Object.keys(field_other_document).length !== 0)) &&
												<div> {this.generateDownloadZIP()}</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="divisionPart" style={divisionStyle}>
									<div className="card">
									<div className="card_full_width card_full_height no_left_margin">
										<h2 style={{ color: '#4a90e2' }}>Home HEI</h2>
										<br />
										<div className="destinationHEI">
											<div className="card card_full_width card_full_height">
												<div className="custom_table">
													<IncomingHei data={this.props.data.incomingNomination.field_mobility_details}  />
															{(isNominationStatus === 'accepted_by_receiving' || isNominationStatus === 'rejected_by_receiving' || isNominationStatus === 'rejected_by_student'|| isNominationStatus === 'cancelled') ? (
													   <div className="row" style={flexStyleCenter}>
														<div className="column" id="" style={flexStyleCenter}>
															<form onSubmit={(event) => this.acceptNomination(event)}>
																<button className="acceptNomi_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Accept</button>
															</form>
														</div>
														<div className="column" id="" style={flexStyleCenter}>
															<form onSubmit={(event) => this.rejectDestination(event)}>
																<button className="rejectNomination_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Reject</button>
															</form>
														</div>
													   </div>) : (
													   <div className="row" style={flexStyleCenter}>
														<div className="column" id="" style={flexStyleCenter}>
															<Confirm title="Confirm" description="Are you sure you want to accept this? The change will be permanent.">
															  {confirm => (
															<form onSubmit={(event) => this.acceptNomination(event)}>
																<button className="acceptNomination_button">Accept</button>
															</form>	
															)}
															</Confirm>															
														</div>
														<div className="column" id="" style={flexStyleCenter}>
															<Confirm title="Confirm" description="Are you sure you want to reject this? The change will be permanent.">
															  {confirm => (
															<form onSubmit={confirm((event) => this.rejectDestination(event))}>
																<button className="rejectNomination_button">Reject</button>
															</form>
															)}
															</Confirm>
														</div>
													   </div>
														)}
												</div>
											</div>
										</div>
									</div>
									</div>
								</div>								
                        </div>);
				}
				} else {
					return (
						<div className={`content nomNoAccess`}>
							<div className="card card_full_height card_full_width">
								<div className="no_access nAccess">
									<h2>Access is denied</h2>
									<p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
								</div>
							</div>
						</div>
					);
				}
        }
    }
}

 const acceptNomination = gql`
    mutation AcceptNomination($id: String){
         acceptNomination(id: $id)
    }`;
 // const deleteNomination = gql`
    // mutation DeleteNomination($id: String){
         // deleteNomination(id: $id)
    // }`;
 const rejectDestination = gql`
    mutation RejectDestination($id: String){
         rejectDestination(id: $id)
    }`;
export default compose(
    graphql(getIncomingNomination, {
        options: (props) => ({
            variables: {
                id: props.match.params.id,
								erasmusCode: localStorage.getItem("ErasmusCode"),
								uuid: props.match.params.id
            }
        })
    }),
		graphql(getManagedByEWP, {
			name: "managedEWP",
			options: (props) => ({
				variables: {
					erasmusCode: localStorage.getItem("ErasmusCode")
				}
			})
		}),
    graphql(acceptNomination, { name: 'AcceptNomination'}),
    // graphql(deleteNomination, { name: 'DeleteNomination'}),
    graphql(rejectDestination, { name: 'RejectDestination'}),	
)(IncomingNomination);
