import React, { Component } from 'react';
import Information from './Information';
import DefaultEmail from './DefaultEmail';
import DefaultNotification from './DefaultNotification';

class MyAccount extends Component {
	renderInformation(){
    if(localStorage.getItem("accountID") === null){
        return;
    }
    return <Information />;
  }
	
  render() {
  	return (
  		<div className="content my_account">
        {this.renderInformation()}
  			<DefaultEmail />
  		</div>
    	);
  }
};

export default MyAccount;
