import React, { Component } from 'react';
import { query } from '../../../queries/getFrequentlyAskedQuestions';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ReactQuill , { Quill, Mixin, Toolbar } from 'react-quill';



class FaqRow extends Component {
    constructor(props) {
		super(props);
		this.state = {
            questionInput: this.props.faq.Question,
            answerInput: this.props.faq.Answer
		}
		this.emptyRec = 0;
	}
  
	changeValue(event, stateName){  
        this.setState({
            [stateName]: event.target.value
        })   
    }

    changeQuillValue(event,stateName){
       	this.setState({
            [stateName]: event
        })
    }
    
	EditFaq(event){
        this.props.UpdateFaq({
			variables:{
				"id": this.props.faq.id,
				"Question": this.state.questionInput,
				"Answer": this.state.answerInput
			},
            refetchQueries: [{query}]
		})
	}
    render() {
    	return(
    		<div className="maindiv" onBlur={(event) => this.EditFaq(event)}>
		       <input value={this.state.questionInput} placeholder="Question?" className="qtextarea" onChange={(event) => this.changeValue(event, "questionInput")} onBlur={(event) => this.EditFaq(event)}></input>
			   <ReactQuill className="atextarea"
		          theme={'snow'}  placeholder="Answer:"value={this.state.answerInput}onChange={(event) => this.changeQuillValue(event,"answerInput")}/>
			</div>
		)		
    }
};
const mutationFaq= gql`
	mutation UpdateFaq($id: ID!,  $Question: String!, $Answer: String!){
	  updateFaq(id: $id, Question:$Question, Answer: $Answer){
	  	id
	  	Question
	    Answer
	  }
	}
`;
export default compose(
	graphql(mutationFaq, { name: 'UpdateFaq'})
)(FaqRow);