import React from 'react';

function OtherLink(props) {

    const renderLangRows = () => {
		let otherLink = props.otherLink;
		        return otherLink.map((otherData, index, arrayRef) => {
			if(arrayRef.length === 1)
			     return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other Documents (link): </span>
                            <a
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`${otherData}`}>
                                    Open Other Documents Link
                            </a>
				</li>
				)
				return(
				<li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other Documents (link) {index + 1}: </span>
								<a
									rel="noopener noreferrer" 
									target="_blank" 
									href={`${otherData}`}>
										Open Other Documents Link
								</a>
				</li>
				)
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.otherLink !== undefined){
        return(
		<div>
                {renderLangRows()}
				</div>
        )
    }
}

export default OtherLink;