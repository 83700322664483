import React, { Component } from 'react';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';

class Faqs extends Component {

    render() {
    	return (
    		<div className="content faqs">
                <div className="division_50">
                    <FrequentlyAskedQuestions />
                </div>
    		</div>
      	);
    }

};

export default Faqs;