import React, { Component } from 'react';


class CreatedByFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}
	changeSelection(event){
		this.setState({
			value: event.target.value
        })
        this.props.onSelectedCreatedBy(event.target.value)

    }

	renderCreatedBy(printStatus){
        let doesExist = this.props.data.find((Status) => {
            if(printStatus==="Created By Student"){
                if ( Status.field_dashboard_student_email === null){
                    return true
                }
            }
            if(printStatus==="Created By Institution"){

                if ( Status.field_dashboard_student_email !== null){
                    return true

                }
            }
        })
		if(doesExist){
			return <option >{printStatus}</option>;
		}
		return <option disabled>{printStatus}</option>;
    }


	render() {
            if(this.props.data.loading){
                return <select><option value="">Loading...</option></select>
            }
            else{
                return (
                    <select id="created-by" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                        <option value="">Select Created By</option>

                        {this.renderCreatedBy("Created By Student")}
                        {this.renderCreatedBy("Created By Institution")}

                    </select>
                  );
            }


    }

};

export default CreatedByFilter;
