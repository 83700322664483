import React, { Component } from 'react';

class Button extends Component {
    constructor(props) {
        super(props);   
        
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    render() {
        if (this.props.disabled) {
            return (
                <form className={this.props.className}>
                    <fieldset disabled style={{ verticalAlign: "bottom" }}>
                        <button className={this.props.classNameButton}>{this.props.text}</button>
                    </fieldset>
                </form>
            );
        }
        return (
            <form className={this.props.className} onSubmit={(event) => this.handleOnSubmit(event)}>
                <button>{this.props.text}</button>
            </form>
        );
    }

    handleOnSubmit(event) {
        event.preventDefault();
        
        this.props.handleClick(this.props.id);
        this.props.refreshPage();
    }
}

export default Button;

/* 
Props attributes:
    * form:
        - className
        - handleClick
    * button:
        - text
    - refreshPage
    - disabled
*/