
import React, { Component } from 'react';
import { query } from '../../../queries/getDefaultNotification';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

class DefaultNotification extends Component {
	constructor(props) {
        super(props);
        this.state = {
        	id : "",
        	title : "",
        	bodytext : "",
        	buttonName : "Create"
        }
    }
    displayEmail(id,title,body){
    	this.setState({
	       	id : id, 
	       	title : title, 
	       	bodytext : body,
	       	buttonName : "Update"
    	})
    }

    editMailInfo(event){
    	event.preventDefault();
        if(this.state.buttonName === "Update"){
        	this.props.updateDefaultNotification({
				variables:{
				   "id"	: this.state.id,
				   "Title" : this.state.title,
				   "BodyText" : this.state.bodytext
				},
			    refetchQueries: [{query}]		
		    })
		    this.setState({
	        	title : "",
	        	bodytext : "",
	        	buttonName : "Create"
            }) 	
        }
        else{     
	        this.props.insertDefaultNotification({
				variables:{
			        "ErasmusCode"	: localStorage.getItem('ErasmusCode'),
			        "Title" : this.state.title,
			        "BodyText" : this.state.bodytext
				},
			    refetchQueries: [{query}]
		    }) 
		    this.setState({
	        	title : "",
	        	bodytext : ""
            })
		}             
    }

    handleTextInput(event){
    	event.preventDefault();
    	this.setState({title: event.target.value});
    }

    handleTextAreaInput(event){
    	event.preventDefault();
    	this.setState({bodytext: event.target.value});
    }
    
    generateEmails(){
    	return this.props.data.defaultNotification.map(({ id, Title, BodyText }) => {
    		return(
	    		<li><a key={id}  onClick={this.displayEmail.bind(this, id, Title, BodyText)} >{Title}</a></li>
    		)
		})
    }

    clearInputData(event){
    	event.preventDefault();
    	this.setState({
        	title : "",
        	bodytext : "",
        	buttonName : "Create"
        })
    }

	render() {
    	if(this.props.data.loading){	    
			return (
	    		<div className="card card_half_width card_fixed_height">
	    			<h2>Information</h2>
	    		</div>
	      	);
		}
		else{
	    	return (
	    		<div className="card card_half_width card_fixed_height mail_wrapper">
	    			<h2>Default Notifications</h2>
	    			<div className="sub_content">
	    				<h3>Notification list</h3>
	    				<ul>
	    					{this.generateEmails()}
	    				</ul>
	    				<button onClick={this.clearInputData.bind(this)}>New Mail</button>
	    			</div>
	    			<div className="sub_content">
		    			<h3>Notification</h3>
		    			<form onSubmit = {this.editMailInfo.bind(this)}>
							<input onChange= {this.handleTextInput.bind(this)} type="text" placeholder="Title" value={this.state.title}/>
							<textarea onChange= {this.handleTextAreaInput.bind(this)} type="text" placeholder="BodyText"rows="7" value={this.state.bodytext}/>
							<button type="submit">{this.state.buttonName}</button>
							<button onClick={this.clearInputData.bind(this)} className="clear_btn">Clear</button>
						</form>
		    		</div>
	    		</div>
	      	);
	    }		
	}
};



const mutationUpdateNotification = gql`
    mutation updateDefaultNotification( $id: ID!, $Title: String!, $BodyText: String!){
      updateDefaultNotification(id:$id, Title:$Title,BodyText: $BodyText){
        Title 
        BodyText
      }
    }
`;

const mutationCreateNotification = gql`
	mutation insertDefaultNotification($ErasmusCode: String!, $Title: String! , $BodyText: String!){
	  insertDefaultNotification(ErasmusCode: $ErasmusCode, Title: $Title , BodyText : $BodyText){
	    Title
	    BodyText
	  }
	}
`;

export default compose(
	graphql(query),
	graphql(mutationUpdateNotification, { name: 'updateDefaultNotification' }),
	graphql(mutationCreateNotification , { name: 'insertDefaultNotification' })
)(DefaultNotification);