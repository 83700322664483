import React, { Component } from 'react';
import Row from './Row.js';
import { query } from '../../../queries/getAccounts';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query as studentList } from '../../../queries/getStudent';
import { ApolloClient } from 'react-apollo';
import GlobalVar from '../../../GlobalVar';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import HelpBox from '../../general/HelpBox';
import Help from '../../general/Help';

class Upload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countSelect: "",
			pasteArray: [],
			indexColumn: undefined,
			indexRow: undefined,
			selectionArray: [],
			selectionMinimum: undefined,
			selectionMaximum: undefined,
			multiFillInValue: "",
			countryArray: [],
			uploading: false,
			uploadSuccesfull: false,
			countOfUploadedStudents: 0,
			totalOfUploadedStudents: 0,
			unvalidUpload: false,
			showHelpErasmusCode: false
		}
		this.handlePastingData = this.handlePastingData.bind(this);
		this.handleMultipleSelecting = this.handleMultipleSelecting.bind(this);
		this.handleMultiFillInData = this.handleMultiFillInData.bind(this);
		this.handleCheckingBeforeUpload = this.handleCheckingBeforeUpload.bind(this);

		this.readyToUpload = true;
		this.countOfValidation = 0;
		this.countOfErrors = 0;

	}

	renderTable(){
		if(localStorage.getItem("studentUpload") == null){

			return(
				<div className="upload_wrapper">
					<select onChange={(event) => this.changeCountValue(event)} value={this.state.countSelect}>
						<option value="">Select number</option>
						{this.renderCountOptions()}
					</select>
					{this.renderCreateButton()}
				</div>
			)
		}
		else{
			return(
				<div className="custom_table">
					<div className="row header_row">
						<div className="column bulk"><h3>Select</h3></div>
						<div className="column nr"><h3>Nr</h3></div>
						<div className="column"><h3>First name*</h3></div>
						<div className="column"><h3>Last name*</h3></div>
						<div className="column"><h3>Email*</h3></div>

						<div className="column division"><h3>Start of mobility*</h3></div>
						<div className="column"><h3>End of mobility*</h3></div>

						<div className="column division"><h3>Host faculty</h3></div>
						<div className="column"><h3>Host institution* <Help title="Host institution :" description="To search for a specific HEI, either just paste the Erasmus Codes in the needed column or use the search option based on HEI or city name of the ECHE holders. Please check that you have selected the correct Erasmus Code for the receiving institution. The system will validate the data format only." /></h3></div>

						<div className="column division"><h3>Host contact name</h3></div>
						<div className="column"><h3>Host contact email</h3></div>

						<div className="column"><h3>Host responsible name</h3></div>
						<div className="column"><h3>Host responsible email</h3></div>
						<div className="column"><h3>Field of education</h3></div>

						<div className="column division"><h3>Home contact <Help title="Contacts" description="To avoid confusion in case “Contact” and “Responsible” is the same person, the students will have both fields pre-filled in their Online Learning Agreement template with the same data." /></h3></div>

						<div className="column"><h3>Home responsible</h3></div>
					</div>
					<div className="table_body scrollbar">
						{this.generateRows()}
					</div>
				</div>
			)
		}
	}

	initiateUploadStudents(event){
		event.preventDefault();
		this.setState({
			uploading: true
		});
	}
	addStudent(event){
		event.preventDefault();
		if(localStorage.getItem("studentUpload") !== null){
			let studentData = JSON.parse(localStorage.getItem("studentUpload"));
			studentData.push({
				firstRowindex: Math.random().toString(32),
				firstName: "",
				lastName: "",
				email: "",
				faculty: "",
				fieldOfEducation: "",
				receivingInstitution: "",
				sendingCoordinator: "",
				sendingResponsible: "",
				hostContactName: "",
				hostContactEmail: "",
				hostResponsibleName: "",
				hostResponsibleEmail: "",
				beginDate: "",
				endDate: ""
			})
			localStorage.setItem("studentUpload", JSON.stringify(studentData));
			this.forceUpdate();
		}
	}
	removeSelectedStudent(event){
		event.preventDefault()
		if(localStorage.getItem("selectedRows") !== null && localStorage.getItem("studentUpload") !== null && localStorage.getItem("selectedRows") !== "[]"){

			const selectedRows = JSON.parse(localStorage.getItem("selectedRows"));
			let studentData = JSON.parse(localStorage.getItem("studentUpload"));
			let studentDataToDelete = [];
			selectedRows.forEach((selectedIndexRow) => {
				studentDataToDelete.push(studentData[selectedIndexRow]);
			});
			studentDataToDelete.forEach((rowToDelete) => {
				studentData.splice(studentData.indexOf(rowToDelete), 1);
			});
			//


			localStorage.setItem("studentUpload", JSON.stringify(studentData));
			localStorage.setItem("selectedRows", JSON.stringify([]));

			if(studentData.length === 0){
				localStorage.removeItem("studentUpload");
				localStorage.removeItem("selectedRows");
			}

			window.location.reload(); //HAS TO CHANGE SINCE THIS IS NOT GOOD UX
			//this.forceUpdate();
		}
	}
	renderAddStudentButton(){
		if(JSON.parse(localStorage.getItem("studentUpload")).length > 199){
			return <button className="disabled">Add students</button>;
		}
		return (
			<form onSubmit={(event) => this.addStudent(event)} >
				<button>Add students</button>
			</form>
		)
	}
	renderButtons(){
		if(localStorage.getItem("studentUpload") !== null){
			return(
				<div className="card card_full_width actions">
          <form onSubmit={(event) => this.initiateUploadStudents(event)} >
          	<button>Upload all</button>
          </form>
					{this.renderAddStudentButton()}
          <form onSubmit={(event) => this.removeSelectedStudent(event)} >
     				<button className="warning_button">Remove selected student</button>
     			</form>
	     		<form onSubmit={(event) => this.resetList(event)} >
	     			<button className="warning_button">Reset</button>
	     		</form>
        </div>
			)
		}
	}
	renderCreateButton(){
		if(this.state.countSelect === ""){
			return(
				<form>
					<fieldset disabled>
						<button>Create</button>
					</fieldset>
				</form>
			)
		}
		return(
			<form onSubmit={(event) => this.generateStudents(event)} >
				<button>Create</button>
			</form>
		)
	}
	changeCountValue(event){
		const countSelect = event.target.value;
		this.setState({
			countSelect
		})
	}
	renderCountOptions(){
		const countArray = [];
		for(let i = 0; i < 200; i++){
			countArray.push(i);
		}
		return countArray.map((data) => {
			const humanIndex = data + 1;
			return <option value={humanIndex} key={humanIndex}>{humanIndex}</option>;
		})
	}

	generateStudents(event){
		event.preventDefault();
		let studentUpload = [];
		for(let i = 0; i < this.state.countSelect; i++){
			studentUpload.push({
				firstRowindex: Math.random().toString(32),
				firstName: "",
				lastName: "",
				email: "",
				faculty: "",
				fieldOfEducation: "",
				receivingInstitution: "",
				sendingCoordinator: "",
				sendingResponsible: "",
				hostContactName: "",
				hostContactEmail: "",
				hostResponsibleName: "",
				hostResponsibleEmail: "",
				beginDate: "",
				endDate: ""
			})
		}
		localStorage.setItem("studentUpload", JSON.stringify(studentUpload));
		this.forceUpdate();
	}

	handlePastingData(pasteArray, indexColumn, indexRow){
		this.setState({
			pasteArray,
			indexColumn,
			indexRow
		});
		console.log("handlePastingData");
	}



	handleMultipleSelecting(indexNormalClick, indexShiftClick){
		let selectionArray = this.state.selectionArray;

		if(indexNormalClick !== undefined){
			if(selectionArray.length > 0){
				selectionArray = [];
			}
			selectionArray.push(indexNormalClick);
		}
		else if(indexShiftClick !== undefined){
			selectionArray.push(indexShiftClick);
		}
		const selectionMinimum = Math.min.apply(null, selectionArray);
		const selectionMaximum = Math.max.apply(null, selectionArray);
		this.setState({
			selectionArray,
			selectionMinimum,
			selectionMaximum
		});
	}
	handleMultiFillInData(multiFillInValue, indexColumn){
		this.setState({
			multiFillInValue,
			indexColumn
		})
	}

	handleCheckingBeforeUpload(correctData){
		const studentUpload = JSON.parse(localStorage.getItem("studentUpload"));
		const countOfInputFieldsPerRow = 11;
		const countOfTotalFields = studentUpload.length*countOfInputFieldsPerRow;

		this.countOfValidation = this.countOfValidation + 1;


		if(!correctData){
			this.readyToUpload = false;
			this.countOfErrors = this.countOfErrors + 1;
			if(!this.state.unvalidUpload){
				this.setState({unvalidUpload: true});
			}
		}
		console.log(this.countOfValidation, countOfTotalFields);
		if(this.countOfValidation === countOfTotalFields){

			if(!this.readyToUpload){
				this.setState({
					uploading: false
				})
			}
			else{
				this.setState({unvalidUpload: false});
				this.countOfErrors = 0;
				this.uploadStudents(studentUpload);
			}
			this.countOfValidation = 0;
			this.readyToUpload = true;
		}

	}


	uploadStudents(studentData){
		this.setState({
			totalOfUploadedStudents: studentData.length
		})

		const promiseUploadStudents = studentData.map(({firstName, lastName, email, beginDate, endDate, faculty, fieldOfEducation, receivingInstitution, hostContactName, hostContactEmail, hostResponsibleName, hostResponsibleEmail, sendingCoordinator, sendingResponsible}, index) => {

			let sendingCoordinatorName = ""; let sendingCoordinatorEmail = ""; let sendingCoordinatorFunction = ""; let sendingResponsibleName = ""; let sendingResponsibleEmail = ""; let sendingResponsibleFunction = ""; let sendingCoordinatorPhone = ""; let sendingResponsiblePhone = "";



			if(sendingCoordinator.length !== 0){
				sendingCoordinator = JSON.parse(sendingCoordinator);
				sendingCoordinatorName = sendingCoordinator.Name
				sendingCoordinatorEmail = sendingCoordinator.Email
				sendingCoordinatorFunction = sendingCoordinator.function;
				if(sendingCoordinator.Phone){
					sendingCoordinatorPhone = sendingCoordinator.Phone;
				}
			}
			if(sendingResponsible.length !== 0){
				sendingResponsible = JSON.parse(sendingResponsible);
				sendingResponsibleName = sendingResponsible.Name;
				sendingResponsibleEmail = sendingResponsible.Email;
				sendingResponsibleFunction = sendingResponsible.function;
				if(sendingResponsible.Phone){
					sendingResponsiblePhone = sendingResponsible.Phone;
				}
			}

			const uploadVariables = {
				sendingInstitution: localStorage.getItem("ErasmusCode"),
				firstName,
				lastName,
				email,

				beginDate,
				endDate,
				faculty,
				fieldOfEducation,
				receivingInstitution,

				hostContactName,
				hostContactEmail,
				hostResponsibleName,
				hostResponsibleEmail,

				sendingCoordinatorName,
				sendingCoordinatorEmail,
				sendingCoordinatorFunction,
				sendingCoordinatorPhone,

				sendingResponsibleName,
				sendingResponsibleEmail,
				sendingResponsibleFunction,
				sendingResponsiblePhone
			}
			console.log(uploadVariables);

			return this.props.mutate({
				variables: uploadVariables
			}).then(() => {
				this.setState({
					countOfUploadedStudents: this.state.countOfUploadedStudents+1
				})
			});

		})

		Promise.all(promiseUploadStudents).then(() => {
			localStorage.removeItem("studentUpload");
			localStorage.removeItem("selectedRows");
	    window.location.replace(GlobalVar.generateLinkUrl(intl, "Outgoing students", "Upload succes"));
	  });
	}


	generateRows(){
		const studentUpload = JSON.parse(localStorage.getItem("studentUpload"));

		const selectedRows = JSON.parse(localStorage.getItem("selectedRows"));
		//

		return studentUpload.map((data, index) => {
			return <Row
				key={data.firstRowindex}
				random={data.firstRowindex}
				accountData={this.props.data.accounts}
				institutionAccountData={this.props.data.InstitutionRegistrationsByErasmusCode}
				selectionMinimum={this.state.selectionMinimum}
				selectionMaximum={this.state.selectionMaximum}
				pasteArray={this.state.pasteArray}
				indexColumn={this.state.indexColumn}
				indexRow={this.state.indexRow}
				handleMultipleSelecting={this.handleMultipleSelecting} 
				handlePastingData={this.handlePastingData} 
				index={index} 
				multiFillInValue={this.state.multiFillInValue}
				handleMultiFillInData={this.handleMultiFillInData} 
				handleCheckingBeforeUpload={this.handleCheckingBeforeUpload} 
				data={data} 
				uploading={this.state.uploading}
				tutorial={this.props.data.tutorial} />;
		})
	}
	renderTitle(){
		if(localStorage.getItem("studentUpload") === null){
			return <h2>How many students would you like to upload? <Help title="Uploading students" description="Please select the number of students you want to upload to the system. Please note that by uploading the list of students, the information you enter is automatically transformed into pre-filled Learning Agreements; students listed will receive an automatic inviation to complete their LA at the Online Learning Agreement. For more information, please consult support section." /></h2>;
		}
		if(this.state.unvalidUpload){
			return <h2 className="error">Upload failed, count of wrong fields: {this.countOfErrors}</h2>
		}
		return <h2>Student upload (copy from e.g. MS Excel file. <Link to="/support/tutorials">More info</Link>)</h2>;
	}

	resetList(event){
		event.preventDefault();
		localStorage.removeItem("studentUpload");
		localStorage.removeItem("selectedRows");

		this.setState({unvalidUpload: false, uploading: false});
		this.countOfErrors = 0;
		this.forceUpdate();
	}

	render() {
		if(this.state.countOfUploadedStudents > 0){
			return (
	    		<div className="content upload">
	    			<div className="card scrollbar card_full_width card_full_height">
	    				<div className="loading_div"><img className="spinner" src="/icons/spinner.svg"/><p>{this.state.countOfUploadedStudents} out of {this.state.totalOfUploadedStudents} students uploaded</p></div>
	    			</div>
	    		</div>
	      	);
      	}
		else if(this.props.data.loading){
			return <div className="content upload"></div>;
		}
		else{
	    	return (
	    		<div className="content upload">
		    		<div className="card scrollbar card_full_width card_full_height">
		    			{this.renderTitle()}
		    			{this.renderTable()}
					</div>
					{this.renderButtons()}
		    	</div>
	      	);
    	}
    }

};

const mutation = gql`
	mutation UploadStudent($sendingInstitution: String, $firstName: String, $lastName: String, $email: String, $beginDate: String, $endDate: String, $faculty: String, $fieldOfEducation: String, $receivingInstitution: String, $hostContactName: String, $hostContactEmail: String, $hostResponsibleName: String, $hostResponsibleEmail: String, $sendingCoordinatorName: String, $sendingCoordinatorEmail: String, $sendingCoordinatorFunction: String, $sendingResponsibleName: String, $sendingResponsibleEmail: String, $sendingResponsibleFunction: String, $sendingCoordinatorPhone: String, $sendingResponsiblePhone: String){
	  uploadStudent(sendingInstitution: $sendingInstitution, firstName: $firstName, lastName: $lastName, email: $email, beginDate: $beginDate, endDate: $endDate, faculty: $faculty, fieldOfEducation: $fieldOfEducation, receivingInstitution: $receivingInstitution, hostContactName: $hostContactName, hostContactEmail: $hostContactEmail, hostResponsibleName: $hostResponsibleName, hostResponsibleEmail: $hostResponsibleEmail, sendingCoordinatorName: $sendingCoordinatorName, sendingCoordinatorEmail: $sendingCoordinatorEmail, sendingCoordinatorFunction: $sendingCoordinatorFunction, sendingResponsibleName: $sendingResponsibleName, sendingResponsibleEmail: $sendingResponsibleEmail, sendingResponsibleFunction: $sendingResponsibleFunction, sendingCoordinatorPhone: $sendingCoordinatorPhone, sendingResponsiblePhone: $sendingResponsiblePhone){
	    studentID
	  }
	}
`;


export default graphql(mutation)(
	graphql(query)(Upload)
);
