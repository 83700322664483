import React, {Component} from 'react';

import SelectCity from "./SelectCity";
import SelectInstitution from "./SelectInstitution";

import validator from 'validator';
/*import Select from 'react-select';*/

import Phone from 'react-phone-number-input';
/*import rrui from 'react-phone-number-input/rrui.css';*/
/*import style from 'react-responsive-ui/style.css';*/
import 'react-phone-number-input/style.css';

import { query } from '../../../queries/getCountries';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';

import Terms from './popup/Terms';
import FAQs from "../../general/popup/Faqs"
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';

var validateURL = require('url-validator')

class InstitutionRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			phone: "",
			website: "",
			countryISO: "0",
			cityLink: "0",
			countryLink: "0",
			cityID: "0",
			institutionID: "0",
			error: false,
			title: "",
			checkedPrivacyPolicy: false,
			checkedConsent: false,
			succesfullRegistration: false,
			institutionalAccount: false,
		}
		this.getCityInfo = this.getCityInfo.bind(this);
		this.getInstitution = this.getInstitution.bind(this);
		this.changeCountry = this.changeCountry.bind(this);
	}

	renderCountries(){
		if(this.props.data.loading){
			return;
		} else {
			let countriesList = this.props.data.countries;
			// sort list alphabetically
			countriesList.sort(function(a, b){
			    if(a.CountryName < b.CountryName) { return -1; }
			    if(a.CountryName > b.CountryName) { return 1; }
			    return 0;
			});
			return countriesList.map((data) => {
				return <option key={data.ID} data-link={data.CountryLink} value={data.ID} >{data.CountryName}</option>;
			});
		}
	}

	register(event){
		event.preventDefault();
		if(this.validate()){
			this.props.RegisterInstitution({
				variables:{
					Website: this.state.website,
					Email: this.state.email,
					Phone: this.state.phone,
					ErasmusCode: this.state.institutionID
				}
			}).then((response) => {
				if(response.data.registerInstitution != null){
					if(response.data.registerInstitution.Email === "Erasmus Code already exists"){
						this.setState({
							error: true, 
							title: "This institution has already an institutional account registered.",
							institutionalAccount: true,
						});
					} else if(response.data.registerInstitution.Email === "Already exists"){
						this.setState({error: true, title: "This email is already in use"});
					} else {
						this.setState({
							succesfullRegistration: true,
							error: false,
						});
					}
				} /*else {
					this.setState({
						succesfullRegistration: true,
						error: false,
					});
				}*/
			})
		}
	}

	checkPrivacyPolicy(event){
		this.setState({ checkedPrivacyPolicy : !this.state.checkedPrivacyPolicy });
	}

	checkConsent(event){
		this.setState({ checkedConsent : !this.state.checkedConsent });
	}

	validate(){
		if(this.state.checkedConsent === false){
			this.setState({error: true, title: "Please give consent on the treatment of data"})
			return false;
		}
		if(this.state.checkedPrivacyPolicy === false){
			this.setState({error: true, title: "Please accept the Privacy Policy and Terms and Conditions"})
			return false;
		}
		if(this.state.phone === ""){
			this.setState({error: true, title: "The phone number can't be empty"})
			return false;
		}
		if(this.state.email === ""){
			this.setState({error: true, title: "The email can't be empty"})
			return false;
		}
		if(!validator.isEmail(this.state.email)){
			this.setState({error: true, title: "Wrong email format"})
			return false;
		}
		if(this.state.website === ""){
			this.setState({error: true, title: "The website can't be empty"})
			return false;
		}
		if(!validator.isURL(this.state.website)){
			this.setState({error: true, title: "Wrong website input"})
			return false;
		}
		if(this.state.countryLink === "0"){
			this.setState({error: true, title: "Please, select a country"})
			return false;
		}
		if(this.state.cityLink === "0"){
			this.setState({error: true, title: "Please, select a city"})
			return false;
		}

		if(this.state.institutionID === "0"){
			this.setState({error: true, title: "Please, select an institution"})
			return false;
		}
		this.setState({error: false, title: "Performing registration..."});
		return true;
	}

	getCityInfo(cityID, cityLink){
		this.setState({cityLink});
	}

	getInstitution(institutionID){
		this.setState({institutionID});
	}

	getTitle(){
		if(this.state.error){
			return <h2 className="error">{this.state.title}</h2>;
		}
		else if(this.state.title !== ""){
			return <h2>{this.state.title}</h2>;
		}
		return <h2>Institution registration</h2>;
	}

	changeCountry = (e) => {
		this.setState({ 
			countryLink: e.target.selectedOptions[0].dataset.link,
			countryISO: e.target.value,
			cityID: 0
		});
	}

	render(){
		if(this.state.succesfullRegistration){
			return(
				<div className="intro">
	            	<div className="intro_content">
						<div className="institution_registration card">
							<h2>Succesfull registration</h2>
							<p className="succes_text">We have received your registration request to the Erasmus Dashboard. We will review your application shortly and inform you on results of the validation procedure.<br/>Should you have any further questions, please consult the FAQs on the ErasmusPlus Dashboard page or contact us at <a href="mailto:dashboard@uni-foundation.eu">dashboard@uni-foundation.eu</a>.</p>
						</div>
					</div>
	        	</div>
			)
		}
		return(
			<div className="intro">
        		<div className="intro_content">
        			<div className="institution_registration card">
        				<Link className="back_link" to={"/intro"}>{"<"}Go back to Intro</Link>
						{this.getTitle()}
						<form onSubmit={(event) => this.register(event)} >
							<Phone placeholder="Contact phone - institutional" value={ this.state.phone } onChange={ phone => this.setState({ phone }) } />
							<input type="email" name="email" placeholder="Contact email - institutional" value={ this.state.email } onChange={ (event) => this.setState({ email:event.target.value }) }></input>
							<input type="website" name="website" placeholder="Institutional website" value={ this.state.website } onChange={ (event) => this.setState({ website:event.target.value }) }></input>
							{this.props.data.loading 
								? <select><option>Loading...</option></select>						
								: <select id="country_select" value={this.state.countryISO} onChange={this.changeCountry} >
									<option value={0}>Select country of institution</option>
									{this.renderCountries()}
								</select>
							}
							<SelectCity getCityInfo={this.getCityInfo} countryLink={this.state.countryLink} />
							<SelectInstitution getInstitution={this.getInstitution} cityLink={this.state.cityLink} />
							<div className="checkbox-wrapper">
								<input className="checkbox" type="checkbox" checked={this.state.checked} onChange={(event) => this.checkConsent(event)}></input>
								<label>I confirm, also on behalf of my HEI, that I have all the authorisations, 
                                including the consent of the relevant natural persons, necessary to upload the 
                                personal data and information I am providing, pursuant to the Dashboard {' '} 
                                <Link to={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")+"/termsandconditions"}>
                                Terms & Conditions and Privacy Policy</Link>.
                                </label>
							</div>
							<div className="checkbox-wrapper">
								<input className="checkbox" type="checkbox" checked={this.state.checked} onChange={(event) => this.checkPrivacyPolicy(event)}></input>
								<label>I have read and agree to the <Link to={GlobalVar.generateLinkUrl(intl,  "Institution", "Registration")+"/termsandconditions"}>Privacy Policy and Terms and Conditions</Link></label>
							</div>
							{this.state.institutionalAccount ? 
								<p className="response">Thank you for your interest in the Erasmus Dashboard. 
								The Higher Education Institution you indicate in the registration data already has an Erasmus Dashboard account.<br/>
								If you are trying to access the Dashboard as a staff member to sign/validate a Learning Agreement, 
								please get in touch with your International Relations Office or the main institutional account holder, 
								who can give you the necessary access to the system. Please also see more information 
								{" "}<a target="_blank" href="//wiki.uni-foundation.eu/display/DASH/Registration+to+EWP+Dashboard">here</a></p> :
								<button type="submit">Register</button>
							}
						</form>
					</div>
					<div className="institution_registration card">
						<div className="inside_content">
							<p>Already have an account?{" "}
								<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "Account", "Login")}}>Sign in</Link>.
							</p>
						</div>
					</div>
					<div className="institution_registration institution_registration_info card">
						<div className="inside_content">
							<p>Please bear in mind that only institutional email addresses are accepted (e.g. no “hotmail” or “gmail” services).<br/>The registration process should be carried out by a manager from the International Relations Office, as this person will be the main institutional account holder and later on give access to other colleagues within the HEI so that they are able to use the Erasmus Dashboard using their own individual accounts.</p>
						</div>
					</div>

					<Route exact path={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")+"/termsandconditions"} render={() => <Terms history={this.props.history}/>}/>
				  	<Route exact path={GlobalVar.generateLinkUrl(intl,  "Institution", "Registration")+"/faqs"} render={() => <FAQs history={this.props.history}/>}/>
				</div>
	    	</div>
		)
	}
}


const RegisterInstitution = gql`
	mutation RegisterInstitution($Website:String, $Email:String, $Phone:String, $ErasmusCode:String){
	  registerInstitution(Website: $Website, Email: $Email, Phone: $Phone, ErasmusCode: $ErasmusCode) {
	  	Email
	    id
	  }
	}
`;


export default compose(
	graphql(query),
	graphql(RegisterInstitution, {name: "RegisterInstitution"}),
)(InstitutionRegistration);
