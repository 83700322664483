import React, { Component } from 'react';
import GlobalVar from '../../../GlobalVar';
import intl from 'react-intl-universal';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom';
import { graphql, useQuery, useMutation } from 'react-apollo';
import { query as getOutgoingNomination } from '../../../queries/getOutgoingNomination';
import Loader from 'react-loader-spinner';
import OutgoingHei from './OutgoingHEI';
import Confirm from './Confirm';
import LangTable from '../ExportTable';
import OtherdocTable from '../ExportOtherTable';
import OtherreqTable from '../ExportReqTable';
import LangLink from '../ExportLangLink';
import OtherLink from '../ExportOtherLink';
import { query as getManagedByEWP } from '../../../queries/getManagedByEWP';

class OutgoingNomination extends Component{
	constructor(props) {
        super(props);
        this.state = {
            key : "",

		}
    }

    toPreviousPage(event){
        event.preventDefault();
        if(this.props.location.pathname.includes("outgoing")) {
            this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
        } else {
            this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
        }
		window.location.reload();
    }

    findValue(value) {
        if(value == null || value === "" || value.includes("null")){
            return <p className="italic">Not found</p>;
        }
        return value;
    }

    checkNullValue(value) {
        if(value == null || value === "" || value.includes("null")){
            return "";
        }
        return value;
    }

    parseStatus = (status) => {
        let newStatus = status.replace(/[_-]/g, ' ');
        if(status.includes("final")){
            newStatus = newStatus.replace("final", "changes");
        }
        if(status.includes("preliminary")){
            newStatus = newStatus.replace("preliminary", "").replace(/\s+/g, ' ');
        }
        if(status.includes("receiving")){
            newStatus = newStatus.replace("receiving", "").replace(/\s+/g, ' ');
            newStatus = newStatus.replace("sending", "student/sending/receiving");
            newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
        }
        if(status.includes("sending") && !status.includes("receiving")){
            newStatus = newStatus.replace("student", "").replace(/\s+/g, ' ');
            newStatus = newStatus.replace("sending", "student/sending");
        }
        return newStatus;
    }

  //comment in remove function
  rejectNomination(event){
		//event.preventDefault();
		this.props.RejectNomination({
			variables:{
				"id": this.props.data.outgoingNomination.id
			}
		})
        if(this.props.location.pathname.includes("outgoing")) {
            this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
        } else {
            this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
        }
		setTimeout(function () {
		window.location.reload();
		}, 1000);
	}
 
  waitlistNomination(event){
		//event.preventDefault();
		this.props.WaitlistNomination({
			variables:{
				"id": this.props.data.outgoingNomination.id
			}
		})
        if(this.props.location.pathname.includes("outgoing")) {
            this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
        } else {
            this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
        }
		setTimeout(function () {
		window.location.reload();
		}, 1000);
	}
	
  deleteNomination(event){
		//event.preventDefault();
		this.props.DeleteNomination({
			variables:{
				"id": this.props.data.outgoingNomination.id
			}
		})
        if(this.props.location.pathname.includes("outgoing")) {
            this.props.history.replace({pathname:"/applications-for-erasmus+/outgoing-students" });
        } else {
            this.props.history.replace({pathname:"/applications-for-erasmus+/incoming-students" });
        }
		setTimeout(function () {
		window.location.reload();
		}, 1000);
	}

    generateDownloadCV(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_cv);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadMotivation(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_motivation_letter);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }

    generateDownloadTor(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_tor);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }	

    generateDownloadRecom(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_recom_letter);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadLangLevel(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_lang_level);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadExternal(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_external_cert);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
	
    generateDownloadOthers(){
		const pdfUrl = encodeURIComponent(this.props.data.outgoingNomination.field_other_document);
        return (
                <a target="_blank" href={`/outgoing-student/export/${pdfUrl}`}>
                    Download File
                </a>
        );
    }
  generateDownloadZIP(){
    const zipUrl = this.props.data.outgoingNomination.id;
    //const zipName = this.props.data.outgoingNomination.field_student_name;
    //if ((this.props.data.field_cv!==null) || (this.props.data.field_motivation_letter!==null) || (this.props.data.field_tor!==null) || (this.props.data.field_recom_letter!==null) || (this.props.data.field_lang_level!==null) || (this.props.data.field_external_cert!==null) || (this.props.data.field_other_document!==null) || (this.props.data.field_other_requirement!==null))
      return (
        <a target="_blank" href={`/outgoing-student/exportzip/${zipUrl}`}>
          <button>Export all documents</button>
      </a>
      );
  }
	// downloadZIP(){
  //
  //   let zip = this.props.data.studentApplicationZip ? this.props.data.studentApplicationZip.base64 : "";
  //   if (zip) {
  //     let byteCharacters = atob(zip);
  //     let byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //
  //     let byteArray = new Uint8Array(byteNumbers);
  //     let file = new Blob([byteArray], { type: "application/zip" });
  //     const fileURL = URL.createObjectURL(file);
	//   let zipNameNoSpace =this.props.data.outgoingNomination.field_student_name;
	//   let zipName = zipNameNoSpace.replace(/\s+/g,"_");
	//   let a = document.createElement('a');
  //     a.href = fileURL;
  //     a.download = zipName + '.zip';
  //     a.click();
  //
  //   }
  // }
    render(){
		// style for span
		const spanStyle = {
			display: 'inline-block',
			marginBottom: '5px'
		}
		const flexStyleCenter = {
			display: 'flex',
			justifyContent: 'center'
		}

		const WaitingListButton = {
			backgroundColor: '#4CAF50',
			color: '#4CAF50',
			border: '#4CAF50'
		}

		const divisionStyle = {
			height: '135%',
			width: '50%',
			float: 'left'
		}

		
        if(GlobalVar.checkAccountFunction("nominations", "Nominations")){
          if (!this.props.managedEWP.managedByEWP) {
            if (this.props.data.loading) {
                return (
                    <div className={`content outgoingNomination`}>
                        <div className="card card_full_width">
                            <div className="white_background">
                                <Loader
                                    className="spinner"
                                    type="TailSpin"
                                    color="#3986df"
                                    height={70}
                                    width={70} />
                            </div>
                        </div>
                    </div>
                );
            }
			window.onpopstate = function(event) {
				window.location.reload();
			};
            // get erasmus code from receiving and sending institution
            // const sending_institution = this.props.data.learningAgreement.sending_institution;
            // const sending_erasmus_code = sending_institution.field_hei_erasmus_code;
            // const receiving_institution = this.props.data.learningAgreement.receiving_institution;
            // /*console.log(receiving_institution);*/
            // const receiving_erasmus_code = receiving_institution.field_hei_erasmus_code;

            // perform permissions check
            // var permission = false;
            // var erasmusCodeEncrypted = localStorage.getItem("ErasmusCode");
            // var erasmusCodeDecrypt = GlobalVar.decryptWithKey(erasmusCodeEncrypted);
            // var userErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
            // var userErasmusCodeNoSpace = userErasmusCode.replace(/\s/g, '');
            // var typeOfStudent = this.setTypeOfStudent(erasmusCodeDecrypt, sending_erasmus_code, receiving_erasmus_code);
            // const isOutgoing = typeOfStudent === 1 ? true : false;
            // const isIncoming = typeOfStudent === 2 ? true : false;
            
            // if(erasmusCodeDecrypt != "" && erasmusCodeDecrypt.localeCompare(userErasmusCode) === 0){
                // if(receiving_erasmus_code.localeCompare(sending_erasmus_code) === 0){
                    // if(erasmusCodeDecrypt.localeCompare(sending_erasmus_code) === 0){
                        // permission = true;
                    // }
                // } else {
                    // if(isOutgoing && erasmusCodeDecrypt.localeCompare(sending_erasmus_code) === 0){
                        // permission = true;
                    // }
                    // if(isIncoming && erasmusCodeDecrypt.localeCompare(receiving_erasmus_code) === 0){
                        // permission = true;
                    // }
                // }
            // }
                const error = this.props.data.outgoingNomination.error;

          if(error === '403') {
            return (
              <div>
                <div className={`content outgoingNomination`}>
                  <div className="header">
                    <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                      <button>
                        {"<"} Go back
                      </button>
                    </form>

                  </div>
                  <div className="card ">
                    <div className="no_access">
                      <h2>This Application does not belong to your institution</h2>
                      <p>You are trying to access a Application that doesn't belong to your Higher Education Institution</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
                // const accounts = this.props.accountInfo.accounts;
                // const accountsNames = this.getAccountsNames(accounts);
                const outgoingNomination = this.props.data.outgoingNomination;
                const id = this.props.data.outgoingNomination.id;
                const {
                    title, field_nomination_status
                } = outgoingNomination;

                const field_student_name = this.props.data.outgoingNomination.field_student_name;
                // const field_student_lastname = this.props.data.outgoingNomination.field_student_lastname;
                const field_student_email = this.props.data.outgoingNomination.field_student_email;
                // const field_student_birthdate = this.props.data.outgoingNomination.field_student_birthdate;
                // const gender = this.props.data.outgoingNomination.gender[0] ? this.props.data.outgoingNomination.gender[0].name : "Unknown";
                // const nationality = this.props.data.outgoingNomination.nationality[0] ? this.props.data.outgoingNomination.nationality[0].name : "Unknown";
                const field_student_isced = this.props.data.outgoingNomination.field_student_isced;
                const field_student_esi = this.props.data.outgoingNomination.field_student_esi;
                const field_student_eqf = this.props.data.outgoingNomination.field_student_eqf;
                const field_cv = this.props.data.outgoingNomination.field_cv;
                const field_cv_link = this.props.data.outgoingNomination.field_cv_link;
                const field_motivation_letter = this.props.data.outgoingNomination.field_motivation_letter;
                const field_motiv_letter_link = this.props.data.outgoingNomination.field_motiv_letter_link;
                const field_tor = this.props.data.outgoingNomination.field_tor;
                const field_tor_link = this.props.data.outgoingNomination.field_tor_link;
                const field_recom_letter = this.props.data.outgoingNomination.field_recom_letter;
                const field_recom_letter_link = this.props.data.outgoingNomination.field_recom_letter_link;
                const field_lang_level = this.props.data.outgoingNomination.field_lang_level;
                const field_external_cert = this.props.data.outgoingNomination.field_external_cert;
                const field_lang_level_link = this.props.data.outgoingNomination.field_lang_level_link;
                const field_external_cert_link = this.props.data.outgoingNomination.field_external_cert_link;
                const field_other_document = this.props.data.outgoingNomination.field_other_document;
                const field_other_document_link = this.props.data.outgoingNomination.field_other_document_link;
                const field_academic_year = this.props.data.outgoingNomination.field_academic_year;
                const field_academic_term = this.props.data.outgoingNomination.field_academic_term;
                const field_reference_contact = this.props.data.outgoingNomination.field_reference_contact;
                const field_portfolio = this.props.data.outgoingNomination.field_portfolio;
                const field_other_requirement = this.props.data.outgoingNomination.field_other_requirement;
                const isNominationStatus = this.props.data.outgoingNomination.field_nomination_status;
                const createdDate = new Date(this.props.data.outgoingNomination.created).toLocaleDateString('en-GB',{
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short'
                 });
                //console.log(Object.keys(field_lang_level).length);
                return (
				
                        <div className={`content outgoingNomination`}>
								<div className="header">
                                    <form onSubmit={(e) => this.toPreviousPage(e)} className="close_button_wrapper">
                                        <button>
                                            {"<"} Go back
                                        </button>
                                    </form>
                                </div>
                               	<div className="divisionPart" style={divisionStyle}>
									<div className="card">
										<div className="sub_content">
											<h2 style={{ color: '#4a90e2' }}>Student Info</h2>
											<br />
											<div className="nominationsInfo">
												<ul className="info_list">
													<li><span className="bold" style={spanStyle}>Name: </span> <span className="value">{field_student_name}</span></li>
													<li><span className="bold" style={spanStyle}>Email: </span> <span className="value">{field_student_email}</span></li>
													<li><span className="bold" style={spanStyle}>Study Field: </span> <span className="value">{field_student_isced}</span></li>
													<li><span className="bold" style={spanStyle}>Study Level: </span> <span className="value">{field_student_eqf}</span></li>
													<li><span className="bold" style={spanStyle}>Academic Year: </span> <span className="value">{field_academic_year}</span></li>
													<li><span className="bold" style={spanStyle}>Academic Term: </span> <span className="value">{field_academic_term}</span></li>
													<li><span className="bold" style={spanStyle}>Application submission time: </span> <span className="value">{createdDate}</span></li>
												</ul>
											</div >
										</div>
									</div>
									<div className="card">
										<div className="sub_content">
											<h2 style={{ color: '#4a90e2' }}>Application Documents</h2>
											<br />
											<div className="applicationDocs">
												<ul className="info_list">
												{(field_reference_contact) &&
												<li><span className="bold" style={spanStyle}>Reference Contact: </span> <span className="value">{field_reference_contact}</span></li>
												}
												{(field_portfolio) &&
												<li><span className="bold" style={spanStyle}>Portfolio: </span> <span className="value">{field_portfolio}</span></li>
												}
												{(field_cv) &&	
													<li><span className="bold" style={spanStyle}>CV (file): </span> {this.generateDownloadCV()}</li>
												}
												{(field_cv_link) &&
													<li><span className="bold" style={spanStyle}>CV (link): </span> <a href={field_cv_link} target="_blank">Open CV Link</a></li>
												}
												{(field_motivation_letter) &&
												    <li><span className="bold" style={spanStyle}>Motivation Letter (file): </span>{this.generateDownloadMotivation()}</li>
												}
												{(field_motiv_letter_link) &&
													<li><span className="bold" style={spanStyle}>Motivation Letter (link): </span> <a href={field_motiv_letter_link} target="_blank">Open Motivation Letter Link</a></li>
												}
												{(field_tor) &&
												    <li><span className="bold" style={spanStyle}>Transcript of Records (file): </span> {this.generateDownloadTor()}</li>
												}
												{(field_tor_link) &&
												    <li><span className="bold" style={spanStyle}>Transcript of Records (link): </span> <a href={field_tor_link} target="_blank">Open Transcript of Records Link</a></li>
												}
												{(field_recom_letter) &&
												    <li><span className="bold" style={spanStyle}>Recommendation Letter (file): </span> {this.generateDownloadRecom()}</li>
												}
												{(field_recom_letter_link) &&
												    <li><span className="bold" style={spanStyle}>Recommendation Letter (link): </span> <a href={field_recom_letter_link} target="_blank">Open Recommendation Letter Link</a></li>
												}
												{(field_lang_level) &&
													   <LangTable lang={this.props.data.outgoingNomination.field_lang_level}  langFile={this.props.data.outgoingNomination.field_lang_level_filename}/>
												}
												{(field_lang_level_link) &&
                             <LangLink langLink={this.props.data.outgoingNomination.field_lang_level_link}/>
												}
												{(field_external_cert) &&
												    <li><span className="bold" style={spanStyle}>External Certificates (file): </span> {this.generateDownloadExternal()}</li>
												}
												{(field_external_cert_link) &&
												    <li><span className="bold" style={spanStyle}>External Certificates (link): </span> <a href={field_external_cert_link} target="_blank">Open External Certificates Link</a></li>
												}
												{(field_other_document) &&
													   <OtherdocTable otherdoc={this.props.data.outgoingNomination.field_other_document} />
												}
												{(field_other_document_link) &&
                              <OtherLink otherLink={this.props.data.outgoingNomination.field_other_document_link}/>
												}
												{(field_other_requirement) &&
												<OtherreqTable otherreq={this.props.data.outgoingNomination.field_other_requirement} />
												}
                        </ul>
                        {((field_cv!=="") || (field_motivation_letter!=="") || (field_tor!=="") || (field_recom_letter!=="") || (Object.keys(field_lang_level).length !== 0) || (field_external_cert!=="") || (Object.keys(field_other_document).length !== 0)) &&
                            <div> {this.generateDownloadZIP()}</div>
                          }
											</div>
										</div>
									</div>
									{(isNominationStatus === 'accepted_by_sending' || isNominationStatus === 'accepted_by_student' || isNominationStatus === 'rejected_by_sending' || isNominationStatus === 'cancelled' ) ? (
									<div key={this.props.data.outgoingNomination.id} className="card">
										<div className="custom_table">									
											<div className="row" style={flexStyleCenter}>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to add this to the Waiting list? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.waitlistNomination(event))}>
														<button style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Waiting List</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to reject this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.rejectNomination(event))}>
														<button className="rejectNomination_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Reject</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to delete this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.deleteNomination(event))}>
														<button className="decline_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Delete</button>
													</form>
													  )}
													</Confirm>
												</div>
											</div>
										</div>
									</div>
								) : (isNominationStatus === 'waiting_list') ? (
									<div key={this.props.data.outgoingNomination.id} className="card">
										<div className="custom_table">									
											<div className="row" style={flexStyleCenter}>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to add this to the Waiting list? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.waitlistNomination(event))}>
														<button style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Waiting List</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to reject this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.rejectNomination(event))}>
														<button className="rejectNomination_button">Reject</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to delete this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.deleteNomination(event))}>
														<button className="decline_button">Delete</button>
													</form>
													  )}
													</Confirm>
												</div>
											</div>
										</div>
									</div>
								) : (isNominationStatus === 'rejected_by_student') ? (
									<div key={this.props.data.outgoingNomination.id} className="card">
										<div className="custom_table">									
											<div className="row" style={flexStyleCenter}>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to add this to the Waiting list? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.waitlistNomination(event))}>
														<button style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Waiting List</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to reject this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.rejectNomination(event))}>
														<button className="rejectNomination_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Reject</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to delete this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.deleteNomination(event))}>
														<button className="decline_button">Delete</button>
													</form>
													  )}
													</Confirm>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div key={this.props.data.outgoingNomination.id} className="card">
										<div className="custom_table">									
											<div className="row" style={flexStyleCenter}>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to add this to the Waiting list? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.waitlistNomination(event))}>
														<button>Waiting List</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to reject this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.rejectNomination(event))}>
														<button className="rejectNomination_button">Reject</button>
													</form>
													  )}
													</Confirm>
												</div>
												<div className="column" id="" style={flexStyleCenter}>
													<Confirm title="Confirm" description="Are you sure you want to delete this? The change will be permanent.">
													  {confirm => (
													<form onSubmit={confirm((event) => this.deleteNomination(event))}>
														<button className="decline_button">Delete</button>
													</form>
													  )}
													</Confirm>
												</div>
											</div>
										</div>
									</div>								
								)}
								</div>
								<div className="divisionPart" style={divisionStyle}>
									<div className="card">
										<div className="card_full_width card_full_height no_left_margin">
											<h2 style={{ color: '#4a90e2' }}>Destination HEIs (the list is shown by preference)</h2>
											<br />
											<div className="destinationHEI">
												<OutgoingHei data={this.props.data.outgoingNomination.field_mobility_details} nominationstatus={this.props.data.outgoingNomination.field_nomination_status}  />
											</div>
										</div>
									</div>
								</div>
                        </div>);
        }
        } else {
          return (
            <div className={`content nomNoAccess`}>
              <div className="card card_full_height card_full_width">
                <div className="no_access nAccess">
                  <h2>Access is denied</h2>
                  <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
                </div>
              </div>
            </div>
          );
        }
        }
    }
}
const rejectNominationMutation = gql`
    mutation RejectNomination($id: String){
			rejectNomination(id: $id)
    }
`;
const deleteNominationMutation = gql`
    mutation DeleteNomination($id: String){
			deleteNomination(id: $id)
    }
`;
const waitlistNominationMutation = gql`
    mutation WaitlistNomination($id: String){
			waitlistNomination(id: $id)
    }
`;
export default compose(
    graphql(getOutgoingNomination, {
        options: (props) => ({
            variables: {
                id: props.match.params.id,
                erasmusCode: localStorage.getItem("ErasmusCode")
            }
        })
    }),
    graphql(getManagedByEWP, {
      name: "managedEWP",
      options: (props) => ({
        variables: {
          erasmusCode: localStorage.getItem("ErasmusCode")
        }
      })
    }),
   graphql(rejectNominationMutation,{ name: 'RejectNomination'}),
   graphql(deleteNominationMutation,{ name: 'DeleteNomination'}),
   graphql(waitlistNominationMutation,{ name: 'WaitlistNomination'}),
)(OutgoingNomination);
