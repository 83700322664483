import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import DealTable from './DealTable';
import { query } from '../../../../../queries/getDeal';
import createDeal from './createNewDeal';
import { Link } from 'react-router';
import intl from 'react-intl-universal';
import GlobalVar from '../../../../../GlobalVar';

class Deals extends Component {
  redirectUser(){
    this.props.history.push(GlobalVar.generateLinkUrl(intl, "Erasmus-App", "Create a New Deal"))  
  }
	render() {
        if(this.props.data.loading){
            return (<div className="content deals"></div>)
        }
        console.log(this.props.data);
    	return (
    		<div className="content deals">
    				<div className="card card_full_width card_full_height">
   				<div className="custom_table">
		    		<div className="row">
    					<div className="column" id=""><button onClick={() => this.redirectUser()}>Add a New Deal</button></div>
						<br/>
					</div>
					<div className="row">
						<h2>Deal List</h2>
    					<DealTable data={this.props.data.deals}  />
					</div>
    			</div>
				</div>
			</div>
      	);
    }

};


export default graphql(query)(Deals);
