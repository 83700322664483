import React, { Component } from 'react';
import { query } from '../../../queries/getTermsAndConditions';
import { query as getAllTypeOfAccounts } from '../../../queries/getAllTypeOfAccounts';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import GlobalVar from '../../../GlobalVar';

class AcceptNewConditions extends Component {
  acceptNewConditions(event){
    event.preventDefault();
    this.props.acceptNewConditionsMutation({
      variables: {
        Email: localStorage.getItem("Email")
      },
      refetchQueries: [
        {
          query: getAllTypeOfAccounts
        }
      ]
    }).then(({data}, error) => {
      if(error) alert("something went wrong");
      setTimeout(() => { this.props.history.goBack() }, 200);
    });
  }
  logout(event){
    event.preventDefault();
    GlobalVar.logout(this.props.history);
  }
	render() {
    if(this.props.data.loading){
      return <div></div>
    }
  	return (
      <div className="accept_new_conditions">
        <div className="division_40">
        	<div className="card">
            <h2>We have update our terms and conditions</h2>
            <div className="inside_content">
              <p>Like a lot of other organisations we have updated our terms and conditions in order to comply with the new GDPR regulations</p>
              <h3>What did we exactly change?</h3>
              <ul>
                <li>Point 1</li>
                <li>Point 2</li>
                <li>Point 3</li>
              </ul>
              <form onSubmit={(event) => this.acceptNewConditions(event)}>
                <button>
                  I accept
                </button>
              </form>
              <form onSubmit={(event) => this.logout(event)}>
                <button>
                  Logout
                </button>
              </form>
            </div>
    			</div>
        </div>

        <div className="division_60">
          <div className="card terms_and_conditions">
            <h2>Terms and conditions</h2>
            <div className="inside_content">
              <div dangerouslySetInnerHTML={{__html:this.props.data.terms[0].terms}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const acceptNewConditionsMutation = gql`
	mutation acceptNewConditions($Email: String){
	  acceptNewConditions(Email: $Email){
	    Email
	  }
	}
`;

export default compose(
	graphql(query),
	graphql(acceptNewConditionsMutation, { name: 'acceptNewConditionsMutation'}),
)(AcceptNewConditions);
