import React, { Component } from 'react';
import { query } from '../../../queries/getTermsAndConditions';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ReactQuill , { Quill, Mixin, Toolbar } from 'react-quill';

class Terms extends Component {
  constructor(props) {
		super(props);
		this.state = {
			tac : this.props.term.terms
		}
	}
   
    changeQuillValue(event){
        console.log("Quill event")
        this.setState({
            tac: event
        }) 
    }

    EditTerm(event){
        this.props.UpdateTerms({
			variables:{
				"id": this.props.term.id,
				"terms":this.state.tac
			},
            refetchQueries: [{query}]
		})
	}

    render() { 
    	return (
    		<div className="card" onBlur={(event) => this.EditTerm(event)}>
            	   <ReactQuill className="atextarea setheight"
	               theme={'snow'} value={this.state.tac} onChange={(value) => this.changeQuillValue(value)}/>
            </div>
      	);  
    }
};

const updateTerms = gql`
  mutation UpdateTerms( $id: ID!,$terms: String!){
    updateTerms(id: $id,, terms: $terms){
      id
      terms
    }
  }
`
export default compose(
	graphql(query),
	graphql(updateTerms, { name: 'UpdateTerms'})
)(Terms);
