export const SET_EMERGENCY_SHELTER = "SET_EMERGENCY_SHELTER";
export const SET_ACCESS_HEALTH_SUPPORT = "SET_ACCESS_HEALTH_SUPPORT";
export const SET_FAST_TRACK_APPLICATION = "SET_FAST_TRACK_APPLICATION";
export const SET_SPECIAL_SCHOLARSHIPS = "SET_SPECIAL_SCHOLARSHIPS";
export const SET_MORE_INFO_URL = "SET_MORE_INFO_URL";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";
export const SET_URL_ERROR_ACTION = "SET_URL_ERROR_ACTION";
export const SET_URL_ERROR_MESSAGE_ACTION = "SET_URL_ERROR_MESSAGE_ACTION";
export const SET_SAVE_BUTTON_DISABLED_ACTION = "SET_SAVE_BUTTON_DISABLED_ACTION";

export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}