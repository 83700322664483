import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class StartMobility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null
		}
	}

	handleChangeStart(date) {
		let dateParsed = this.props.parseDate(date);
	    this.setState({
	      startDateFilter: dateParsed
	    });
	    let filters = {};
	    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
	      filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
	    }
	    filters["startMobility"] = dateParsed;
	    localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
	    this.props.filterIsApplied();
  	}

  	typeInDate(event){
    	event.preventDefault();
  	}

  	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){

	    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			if(filters["startMobility"] !== undefined && filters["startMobility"] !== null){
				this.setState({
					value : moment(filters["startMobility"], "DD-MM-YYYY").toDate()
				})
			}
	    }
	    else{
	    	this.setState({
				value : null
			})
	    }
	}

	calculateOpenDate(){
		if(this.state.value === null){
			return moment("01/01/2018", "DD/MM/YYYY").toDate();
		}
		return this.state.value;
	}

	render() {
		return (
			<DatePicker
                dateFormat="MMMM yyyy"
                selected={this.state.value}
                onChange={(event) => this.handleChangeStart(event)}
                onKeyDown={(event) => this.typeInDate(event)}
                placeholderText="Select date"
                openToDate={this.calculateOpenDate()}
            />
		)
    }

};

export default StartMobility;
