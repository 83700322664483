import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import GlobalVar from '../../../GlobalVar';
import * as EmailValidator from 'email-validator';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

class RequestPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			error: false,
			title: "",
			requestCompleted: false
		}
	}
	changeValue(event, nameState){
		this.setState({
			[nameState]: event.target.value
		})
	}

	passwordForgotten(event){
		event.preventDefault();
		if(this.validate()){
			this.props.mutate({
				variables: {
					"Email": this.state.email
				}
			}).then((response) => {
				this.setState({
					requestCompleted: true
				});
			})
		}
	}

	validate(){
		if(this.state.email === ""){
			this.setState({error: true, title: "The email can't be empty"})
			return false;
		}
		if(!EmailValidator.validate(this.state.email)){
			this.setState({error: true, title: "Wrong email format"})
			return false;
		}
		this.setState({error: false, title: "Checking if mail exists..."});
		return true;
	}

	getTitle(){
		if(this.state.error){
			return <h2 className="error">{this.state.title}</h2>;
		}
		else if(this.state.title !== ""){
			return <h2>{this.state.title}</h2>;
		}
		return <h2>Forgot password</h2>;
	}

	render(){
		if(this.state.requestCompleted){
			return(
				<div className="intro">
    			<div className="intro_content">
      			<div className="account_login card">
							<h2>Forgot password</h2>
							<div className="inside_content" style={{"marginTop" : "12px"}}>
								<p>Password reset sent to <a href={"mailto:"+this.state.email}>{this.state.email}</a> if the email exists in our database</p>
							</div>
							<div className="inside_content" style={{"marginTop" : "8px"}}>
								<p>If you are not receiving any email, please check your spam folder and this {" "}
									<a 
										rel="noopener noreferrer"
                  	target="_blank"
                    href="//wiki.uni-foundation.eu/display/DASH/I+cannot+reset+my+password">
                      FAQ
                  </a>
                </p>
							</div>
						</div>
    			</div>
  			</div>
			)
		}
		return(
			<div className="intro">
      	<div className="intro_content">
        	<div className="account_login card">
        		<Link className="back_link" to={{ pathname: GlobalVar.generateLinkUrl(intl, "Account", "Login")}}>{"<"}Go back</Link>
						{this.getTitle()}
						<form onSubmit={(event) => this.passwordForgotten(event)} >
							<input autoFocus value={this.state.email} onChange={(event) => this.changeValue(event, "email")} type="email" placeholder="Email" />
							<button>Request new password</button>
						</form>
					</div>
				</div>
  		</div>
		)
	}

}

const mutation = gql`
mutation ForgotPasswordInstitutionOrAccount($Email:String){
  forgotPasswordInstitutionOrAccount(email: $Email){
    Email
  }
}
`;
export default graphql(mutation)(RequestPassword);
