import gql from 'graphql-tag';

const query = gql`
  query{
    defaultNotification(ErasmusCode: "${localStorage.getItem('ErasmusCode')}"){
      id
      ErasmusCode
      Title
      BodyText
    }
  }
` //remove erasmuscode and use localstorage
export { query };