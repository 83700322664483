import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import escapeStringRegexp from 'escape-string-regexp';

import GlobalVar from '../../../GlobalVar';

import { query } from '../../../queries/getTutorial';

class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openCourses: false,
			checked: false,
			names: [],
			openTutorial: false,
			ewpStatus: -10,
			handledByEWP: false
		}
		this.overlay = true;
		this.tutorialID = "";
		this.x = 0;
		this.y = 0;
		this.openOrCloseCoursesEvent = this.openOrCloseCoursesEvent.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		Object.entries(this.props).forEach(([key, val]) =>
			prevProps[key] !== val && console.log(`Row Prop '${key}' changed`)
		);
		if (this.state) {
			Object.entries(this.state).forEach(([key, val]) =>
				prevState[key] !== val && console.log(`Row State '${key}' changed`)
			);
		}
	}

	componentWillMount() {
		this.setChecker(this.props, true);
		const data = this.props.data;
		
		if(this.props.data.typeOfCommunication === "EWP" && this.props.data.iia_code_p1.startsWith("EWP-")) {
			this.setState({ handledByEWP: true});
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setChecker(nextProps, false);
	}
	setChecker(props, notMountedYet) {
		if (notMountedYet || props.selectionMinimum != this.props.selectionMinimum || props.selectionMaximum != this.props.selectionMaximum) {
			if (props.index > props.selectionMinimum && props.index < props.selectionMaximum) {
				this.setState({
					checked: true
				})
			}
			else if (props.index == props.selectionMinimum || props.index == props.selectionMaximum) {
				this.setState({
					checked: true
				})
			}
			else {
				this.setState({
					checked: false
				})
			}
		}
	}
	showOtherchecker(data) {
		if (data.StudentDetails.OLA.length > 1) {
			return <p className="showMore">Show other</p>;
		}
	}

	renderInstitutionName(data, sending) {
		if (sending) {
			if (data.SendingInstitution != null) {
				return data.SendingInstitution.NameInLatinCharacterSet;
			}
		}
		else {
			if (data.ReceivingInstitution != null) {
				return data.ReceivingInstitution.NameInLatinCharacterSet;
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderInstitutionErasmusCode(data, sending) {

		if (sending) {
			if (data.SendingInstitution != null) {
				return data.SendingInstitution.ErasmusCode;
			}
		}
		else {
			if (data.ReceivingInstitution != null) {
				return data.ReceivingInstitution.ErasmusCode;
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCityName(data, sending) {
		if (sending) {
			if (data.SendingInstitution != null) {
				if (data.SendingInstitution.City != null) {
					return data.SendingInstitution.City.CityName
				}
			}
		}
		else {
			if (data.ReceivingInstitution != null) {
				if (data.ReceivingInstitution.City != null) {
					return data.ReceivingInstitution.City.CityName
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	renderCountryName(data, sending) {
		if (sending) {
			if (data.SendingInstitution != null) {
				if (data.SendingInstitution.City != null) {
					if (data.SendingInstitution.City.Country != null) {
						return data.SendingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		else {
			if (data.ReceivingInstitution != null) {
				if (data.ReceivingInstitution.City != null) {
					if (data.ReceivingInstitution.City.Country != null) {
						return data.ReceivingInstitution.City.Country.CountryName;
					}
				}
			}
		}
		return <p className="italic">Not found</p>;
	}

	openOrCloseCoursesEvent(event) {
		event.preventDefault();
		this.setState({
			openCourses: !this.state.openCourses
		})
	}

	renderRowColumn(value, uniqueName, title) {
		if (uniqueName === "inEffect") {
			const data = this.props.data;
			let currentErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;

			if (currentErasmusCode === data.creator_institution_code && !(data.typeOfCommunication === "EWP" && data.iia_code_p1.startsWith("EWP-"))) {
				switch (value) {
					case 0: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by you</p></div>
					case 1: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by you</p></div>
					case 2: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusNotFinished">Changed and signed by partner</p></div>
					case 3: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusNotFinished">Approved by partner</p></div>
					case 4: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusFinished">Approved by both parties</p></div>
				}
			}
			else if (currentErasmusCode === data.partner_institution_code && !(data.typeOfCommunication === "EWP" && data.iia_code_p1.startsWith("EWP-"))) {
				switch (value) {
					case 0: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by creator</p></div>
					case 1: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by creator</p></div>
					case 2: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed  by you</p></div>
					case 3: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by you</p></div>
					case 4: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by both parties</p></div>
				}
			}
			else if (currentErasmusCode === data.creator_institution_code && (data.typeOfCommunication === "EWP" && data.iia_code_p1.startsWith("EWP-"))) {
				switch (value) {
					case 0: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by creator</p></div>
					case 1: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by creator</p></div>
					case 2: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by you</p></div>
					case 3: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by you</p></div>
					case 4: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by both parties</p></div>
				}
			}
			else if (currentErasmusCode === data.partner_institution_code && (data.typeOfCommunication === "EWP" && data.iia_code_p1.startsWith("EWP-"))) {
				switch (value) {
					case 0: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by you</p></div>
					case 1: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by you</p></div>
					case 2: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Changed and signed by partner</p></div>
					case 3: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by partner</p></div>
					case 4: return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Approved by both parties</p></div>
				}
			}
			
			// if (value == 1) { return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusReqSignature">Requested partner signature</p></div> }
			// if (value == 2) { return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusNotFinished">Need to sign or edit</p></div> }
			// if (value == 0) { return <div className={"column " + uniqueName}><h3>{title}</h3><p id="statusFinished">Signed by both parties</p></div> }
		}
		return <div className={"column " + uniqueName}><h3>{title}</h3><p>{value}</p></div>;
	}

	findValue(value) {
		if (value == undefined || value == "") {
			return <p className="italic">Not found</p>;
		}
		return <p>{value}</p>;
	}

	showSearchResult(value) {
		const { search } = this.props;

		if (search === undefined || search === "") {
			return this.findValue(value);
		}

		const searchRegex = new RegExp(escapeStringRegexp(search), 'i');
		const searchResult = value.search(searchRegex);
		if (searchResult !== -1) {
			const filteredResult = value.substring(searchResult, searchResult + search.length);
			let newValue = value.replace(filteredResult, `<span class="blue_text">${filteredResult}</span>`);
			return <p dangerouslySetInnerHTML={{ __html: newValue }} ></p>
		}
		return this.findValue(value);
	}

	renderSignedBy(status) {
		if (status === null) {
			return <p className="italic">Not applicable</p>;
		}
		if (status == 0) {
			return <p>Nobody</p>;
		}
		else if (status == 1) {
			return <p>Student</p>;
		}
		else if (status == 2) {
			return <p>Student + Home institution</p>;
		}
		else if (status == 3) {
			return <p>Everyone</p>;
		}
	}

	generateTutorial() {
		if (this.props.tutorial) {
			for (let data of this.props.tutorial) {
				if (data.didShiftClickList == 0 && this.state.openTutorial && this.overlay) {
					this.overlay = false;
					this.tutorialID = data.id
					return (
						<div style={{ "left": this.x, "right": this.y }} className="overlay" >
							<h1 className="Header">Select Options : </h1>
							<p className="textTutorial">You can select multiple students by clicking in the select box, then pressing “shift” key and then choosing the range of students you want to select.</p>
							<form className="button" onSubmit={(event) => this.handleTutorial(event)}><button>Skip</button></form>
						</div>
					)
				}
			}
		}
	}

	handleTutorial(event) {
		event.preventDefault();
		this.setState({
			openTutorial: false
		})

		this.props.updateTutorial({
			variables: {
				"id": this.tutorialID,
				"didShiftClickList": 1
			},
			refetchQueries: [{ query }]
		})
	}

	buttonClick(event) {
		event.preventDefault();
		return (
			<Link to={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/edit"}>
				<button>Edit IIA</button>
			</Link>
		);
	}

	generateCorrectDateFormat(month, year) {
		const date = moment(GlobalVar.fillNumber(month, 2) + "-" + year, "MM-YYYY");
		if (date.format("MM-YYYY") === "Invalid date") {
			return <p className="italic">Invalid date</p>;
		}
		return <p>{date.format("MMM YYYY")}</p>;
	}

	generateBooleanValue(value) {
		if (value) {
			return <p>Yes</p>;
		}
		return <p>No</p>;
	}

	convertStudyCycle(value) {
		if (GlobalVar.OlaStudyCycle[value]) {
			return this.findValue(GlobalVar.OlaStudyCycle[value]);
		}
		return this.findValue(value);
	}

	generateSubjectArea(subjectAreaCode, subjectArea) {
		if (subjectAreaCode === "") {
			return this.findValue(subjectArea);
		}
		return this.findValue(`${subjectAreaCode} - ${subjectArea}`);
	}

	formatDataAsCSV() {
		let origData = this.props.getIIA.getIIA;
		let data = [["Creator EC", "Creator OUnit", "Creator Contact Name", "Creator Contact Email", "Creator Contact Phone", "Creator General Website", "Creator Faculty Website",
			"Creator Course Catalogue Website", "Partner EC", "Partner OUnit", "Partner Contact Name", "Partner Contact Email", "Partner Contact Phone", "Partner General Website",
			"Partner Faculty Website", "Partner Course Catalogue Website", "Start Academic Year", "End Academic Year", "Other Information"]];

		for (let i = 0; i < origData.conditions.length; i++) {
			data[0].push(["Sender EC", "Receiver EC", "Mobilities Per Year", "ISCED-F Codes", "ISCED-F Clarification", "Mobility Type", "Total Months", "Study Cycle", "Blended", "Total Days", "Languages"]);
		}

		data.push([
			(origData.creator_institution_data.erasmusCode != null ? origData.creator_institution_data.erasmusCode.replace(/\s+/g, '') : ""),
			(origData.creatorOU != null ? origData.creatorOU.name : ""),
			(origData.creator_institution_data.contacts[0].name != null ? origData.creator_institution_data.contacts[0].name : ""),
			(origData.creator_institution_data.contacts[0].email != null ? origData.creator_institution_data.contacts[0].email : ""),
			(origData.creator_institution_data.contacts[0].phone != null ? origData.creator_institution_data.contacts[0].phone : ""),
			(origData.creatorGeneral != null ? origData.creatorGeneral : ""),
			(origData.creatorFaculty != null ? origData.creatorFaculty : ""),
			(origData.creatorCourse != null ? origData.creatorCourse : ""),

			(origData.partner_institution_data.erasmusCode != null ? origData.partner_institution_data.erasmusCode.replace(/\s+/g, '') : ""),
			(origData.partnerOU != null ? origData.partnerOU.name : ""),
			(origData.partner_institution_data.contacts[0].name != null ? origData.partner_institution_data.contacts[0].name : ""),
			(origData.partner_institution_data.contacts[0].email != null ? origData.partner_institution_data.contacts[0].email : ""),
			(origData.partner_institution_data.contacts[0].phone != null ? origData.partner_institution_data.contacts[0].phone : ""),
			(origData.partnerGeneral != null ? origData.partnerGeneral : ""),
			(origData.partnerFaculty != null ? origData.partnerFaculty : ""),
			(origData.partnerCourse != null ? origData.partnerCourse : ""),

			(origData.academicYearStart != null ? origData.academicYearStart : ""),
			(origData.academicYearEnd != null ? origData.academicYearEnd : ""),
		]);

		origData.conditions.map((condition, i) => {
			data[1].push([
				(condition.senderInstitution != null ? condition.senderInstitution : ""),
				(condition.receiverInstitution != null ? condition.receiverInstitution : ""),
				(condition.mobilitiesPerYear != null ? condition.mobilitiesPerYear : "")
			]);

			let codes = (condition.iscedFCodes.length > 0 ? condition.iscedFCodes[0].iscedFCode.substring(0, condition.iscedFCodes[0].iscedFCode.indexOf(" - ")) : "");
			for (let j = 1; j < condition.iscedFCodes.length; j++) {
				codes += ";" + condition.iscedFCodes[j].iscedFCode.substring(0, condition.iscedFCodes[j].iscedFCode.indexOf(" - "));
			};
			data[1].push([
				codes,
				(condition.iscedClarification != null ? "\"" + condition.iscedClarification + "\"" : ""),
				(condition.mobilityType != null ? condition.mobilityType : ""),
				(condition.totalMonths != null ? condition.totalMonths : "")
			]);

			let cycles = (condition.eqfLevels.length > 0 ? condition.eqfLevels[0].eqfLevel.substring(condition.eqfLevels[0].eqfLevel.indexOf(" ") + 1) : "");
			for (let j = 1; j < condition.eqfLevels.length; j++) {
				cycles += ";" + condition.eqfLevels[j].eqfLevel.substring(condition.eqfLevels[j].eqfLevel.indexOf(" ") + 1);
			};
			data[1].push([
				cycles,
				(condition.blended != null ? condition.blended : ""),
				(condition.totalDays != null ? condition.totalDays : "")
			]);

			let langCode = "";
			this.props.allLanguages.allLanguages.map((data) => {
				if (condition.languages[0]) {
					if (condition.languages[0].language === data.Name) {
						langCode = data.Code;
						return;
					}
				}
			});
			let langs = (condition.languages.length > 0 ? langCode + "-" + condition.languages[0].cefrLevel : "");
			for (let j = 1; j < condition.languages.length; j++) {
				this.props.allLanguages.allLanguages.map((data) => {
					if (condition.languages[j]) {
						if (condition.languages[j].language === data.Name) {
							langCode = data.Code;
							return;
						}
					}
				});
				langs += ";" + langCode + "-" + condition.languages[j].cefrLevel;
			};
			data[1].push([langs]);
		});

		return data;
	}

	exportCSV() {
		let data = this.formatDataAsCSV();

		let csvContent = "data:text/csv;charset=utf-8,";
		data.forEach(function (d, i) {
			let dataString = d.join(",");
			csvContent += i < data.length ? dataString + "\n" : dataString;
		});

		let encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");
		let docName = this.props.data.iia_code_p1.replace(/-/g, '_') + "_IIA.csv";
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", docName);
		link.click();
	}

	deleteIIA() {

		if (window.confirm("Are you sure you want to delete this agreement? Please, note that the other partner will NOT receive any notification regarding this deletion and you have to use another form to communicate it.")) {
			let index = this.props.index;
			let data = this.props.data;
			console.log(index);
			console.log(data.iia_code_p1);

			this.props["DeleteIIA"]({
				variables: { iiaCode: data.iia_code_p1 }
			}).then(() => {
				if (this.props.data.iia_code_p1) {
					alert("The agreement " + data.iia_code_p1 + " was deleted. The page will refresh automatically.");
					console.log("Reloading...\n");
					window.location.reload();
				}
			});
		}
	}

	render() {
		const data = this.props.data;
		/*DEL*/console.log("Row Data: " + JSON.stringify(this.props.data));
		let editButtonCode = <button><img height="19px" src="/icons/edit.png" /> Edit</button>;
		let visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View and Sign</button>;
		let deleteButtonCode = <button style={{ backgroundColor: "#ff00008a", color: "black" }} onClick={() => this.deleteIIA()}><img height="19px" src="/icons/remove.svg" /> Delete</button>;
		let statusValue = data.inEffect;
		let currentErasmusCode = JSON.parse(localStorage.getItem("currentErasmusCode")).erasmusCode;
		let handledByEWP = this.state.handledByEWP;
		/* Do not delete this. It will be used to check if the current user is managed by Dashboard. If not, the actions will be disabled for this user. */
		// this.props.isIIAManagedByEWP({
		// 	variables: {
		// 		"ErasmusCode": localStorage.getItem("ErasmusCode") ? localStorage.getItem("ErasmusCode") : "",
		// 	},
		// }).then((x) => {
		// 	if (x.data.error) { this.setState({ ewpStatus: 0 }); }
		// 	else { this.setState({ ewpStatus: x.data.isIIAManagedByEWP.Success }); }
		// });

		// if (statusValue === 4 || this.state.ewpStatus !== 2) {

		if (statusValue === 4) {
			editButtonCode = "";
			visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
			deleteButtonCode = "";
			if(data.typeOfCommunication === "EWP" && data.iia_id_p1.startsWith("EWP-")) {
				let creator_id = data.iia_id_p1;
				let creator_ec = data.creator_institution_code;
				let creator_contact = data.creator_contact;
				let creator_signature_date = data.creator_signature_date;
				let creator_signature_contact = data.creator_signature_contact;

				data.iia_id_p1 = data.iia_id_p2;
				data.iia_id_p2 = creator_id;
				data.creator_institution_code = data.partner_institution_code;
				data.partner_institution_code = creator_ec;
				data.creator_contact = data.partner_contact;
				data.partner_contact = creator_contact;
				data.creator_signature_date = data.partner_signature_date;
				data.partner_signature_date = creator_signature_date;
				data.creator_signature_contact = data.partner_signature_contact;
				data.partner_signature_contact = creator_signature_contact;
				handledByEWP= true;
			}
		}
		else if (data.typeOfCommunication === "Dash") {
			if (data.creator_institution_code === currentErasmusCode) {
				if (statusValue === 0 || statusValue === 1) {
					editButtonCode = "";
					visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
				}
			}
			else if (data.partner_institution_code === currentErasmusCode) {
				if (statusValue === 2 || statusValue === 3) {
					editButtonCode = "";
					visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
				}
			}
		}
		else if (data.typeOfCommunication === "EWP") {
			if (statusValue === 0 || statusValue === 1) {
				editButtonCode = "";
				visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
			}
			if (data.iia_id_p1.startsWith("EWP-")) {
				let creator_id = data.iia_id_p1;
				let creator_ec = data.creator_institution_code;
				let creator_contact = data.creator_contact;
				let creator_signature_date = data.creator_signature_date;
				let creator_signature_contact = data.creator_signature_contact;

				data.iia_id_p1 = data.iia_id_p2;
				data.iia_id_p2 = creator_id;
				data.creator_institution_code = data.partner_institution_code;
				data.partner_institution_code = creator_ec;
				data.creator_contact = data.partner_contact;
				data.partner_contact = creator_contact;
				data.creator_signature_date = data.partner_signature_date;
				data.partner_signature_date = creator_signature_date;
				data.creator_signature_contact = data.partner_signature_contact;
				data.partner_signature_contact = creator_signature_contact;
				handledByEWP = true;
			}
		}


		// if (data.creator_signature_date != "" && data.partner_signature_date != "") {
		// 	editButtonCode = "";
		// 	visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
		// 	deleteButtonCode = "";
		// } else {
		// 	if ((currentErasmusCode == data.creator_institution_code && data.creator_signature_date != "") || (currentErasmusCode == data.partner_institution_code && data.partner_signature_date != "")) {
		// 		statusValue = 1;
		// 		editButtonCode = "";
		// 		visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
		// 	}
		// 	if ((currentErasmusCode == data.creator_institution_code && data.partner_signature_date != "") || (currentErasmusCode == data.partner_institution_code && data.creator_signature_date != "")) {
		// 		statusValue = 2;
		// 		if (this.props.ewpStatus != 2) {
		// 			editButtonCode = "";
		// 			visualiseButtonCode = <button><img height="19px" src="/icons/eye-show.png" /> View</button>;
		// 			deleteButtonCode = "";
		// 		}
		// 	}
		// }

		return (
			<div className="row">
				{data.typeOfCommunication === "EWP" && (data.iia_id_p2 === null || data.iia_id_p2 === "") && <h4 style={{ color: "red" }}>The partner has not shared an ID for this IIA, therefore no actions are available at this point. Please notify your partner to respectively contact their provider.</h4>}
				<div className="row">
					{handledByEWP ? this.renderRowColumn(this.findValue(data.iia_id_p1), "iia_id_p1", "Creator IIA ID") : this.renderRowColumn(this.findValue(data.iia_id_p1), "iia_id_p1", "IIA ID")}
					{handledByEWP && this.renderRowColumn(this.findValue(data.iia_id_p2), "iia_id_p2", "Partner IIA ID")}
					{this.renderRowColumn(statusValue, "inEffect", "Status")}
				</div>
				<div className="row">
					{this.renderRowColumn(this.findValue(data.creator_institution_code), "creator_institution_code", "Creator EC")}
					{this.renderRowColumn(this.findValue(data.partner_institution_code), "partner_institution_code", "Partner EC")}
					{this.renderRowColumn(this.findValue(data.creator_contact), "creator_contact", "Creator Contact")}
					{this.renderRowColumn(this.findValue(data.partner_contact), "partner_contact", "Partner Contact")}
				</div>
				<div className="row">
					{this.renderRowColumn(this.findValue(data.creator_signature_date), "creator_signature_date", "Creator Sign Date")}
					{this.renderRowColumn(this.findValue(data.creator_signature_contact), "creator_signature_contact", "Creator Sign Contact")}
					{this.renderRowColumn(this.findValue(data.partner_signature_date), "partner_signature_date", "Partner Sign Date")}
					{this.renderRowColumn(this.findValue(data.partner_signature_contact), "partner_signature_contact", "Partner Sign Contact")}
				</div>
				<div className="row">
					<h3>Actions</h3>
					<p>
						<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/edit/" + data.iia_code_p1, state: { index: this.props.index, data: this.props.data } }}>
							{editButtonCode}
						</Link>
						&nbsp;
						<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/details/" + data.iia_code_p1, state: { index: this.props.index, data: this.props.data } }}>
							{visualiseButtonCode}
						</Link>
						&nbsp;
						{deleteButtonCode}
						&nbsp;
					</p>
				</div>
				<hr />
			</div>
		);
	}
};

const deleteIIA = gql`
mutation deleteIIAv2($iiaCode: String!){
    deleteIIAv2(iiaCode: $iiaCode) 
}`;

const updateTutorial = gql`
  mutation updateTutorial($id: ID!, $didShiftClickList: Int!){
    updateTutorialList(id: $id , didShiftClickList: $didShiftClickList){
      erasmusCode
    }
  }
`;

const isIIAManagedByEWP = gql`
    mutation isIIAManagedByEWP($ErasmusCode: String!) {
        isIIAManagedByEWP(ErasmusCode: $ErasmusCode) {
            Success
            Message
        }
    }
`;

export default compose(
	graphql(deleteIIA, { name: "DeleteIIA" }),
	graphql(updateTutorial, { name: "updateTutorial" }),
	graphql(isIIAManagedByEWP, {name: "isIIAManagedByEWP"})
)(Row);
