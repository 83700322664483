import React from 'react';

function OtherdocTable(props) {

    const renderOtherdocRows = () => {
        let otherdoc = props.otherdoc;
        return otherdoc.map((otherdocData, index, arrayRef) => {
			if(arrayRef.length === 1)
			     return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other documents: </span>
                            <a
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`/outgoing-student/export/${otherdocData}`}>
                                    Download File
                            </a>
				</li>
				)
			    return(
                <li key={index}><span className="bold" style={{display: 'inline-block', marginBottom: 5}}>Other documents {index + 1}: </span>
                            <a
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={`/outgoing-student/export/${otherdocData}`}>
                                    Download File
                            </a>
				</li>
				)
        })
    }

    let classNameTable = "table_without_changes table_body";

    if(props.otherdoc !== undefined){
        return(
		<div>
                {renderOtherdocRows()}
				</div>
        )
    }
}

export default OtherdocTable;