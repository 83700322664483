import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class EndMobility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null
		}
	}

	handleChangeStart(date) {
		let dateParsed = this.props.parseDate(date);
	    this.setState({
	      startDateFilter: dateParsed
	    });
	    let filters = {};
	    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
	      filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
	    }
	    filters["endMobility"] = dateParsed;
	    localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
	    this.props.filterIsApplied();
	}

	typeInDate(event){
  		event.preventDefault();
	}

	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
	    if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
				const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
				if(filters["endMobility"] !== undefined && filters["endMobility"] !== null){
					this.setState({
						value : moment(filters["endMobility"], "DD-MM-YYYY").toDate()
					})
				}
		    }
		    else{
		    	this.setState({
					value : null
				})
	    }
	}

	calculateOpenDate(){
		if(this.state.value === null){
			return moment("30/06/2018", "DD/MM/YYYY").toDate();
		}
		return this.state.value;
	}

	render() {
		return (
			<DatePicker
        dateFormat="MMMM yyyy"
        selected={this.state.value}
        onChange={(this.handleChangeStart.bind(this))}
        onKeyDown={(event) => this.typeInDate(event)}
        placeholderText="Select date"
        openToDate={this.calculateOpenDate()}
      />
		)
    }

};

export default EndMobility;
