import React, { Component } from "react";

class Modified extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }
        this.changeSelection = this.changeSelection.bind(this);
    }

    changeSelection(event){
        this.setState({ value: event.target.value });
        this.props.handlerChange(event, "modifiedSince");
    }

    render() {
        // if(this.props.data.loading){
        //     return <select><option value="">Loading...</option></select>
        // }
        return (
            <select name="iia-modified-filter" id="iia-modified-filter" value={this.state.value} onChange={(event) => this.changeSelection(event)} >
                <option value="">All time</option>
                <option value="last_day">Last day</option>
                <option value="last_week">Last week</option>
                <option value="last_month">Last month</option>
            </select>
        );
    }
}

export default Modified;