import React, { Component } from 'react';

class Notification extends Component {
	generateTitle(){
		if(this.props.type == "error"){
			return "An error has occured";
		}
	}
	closeNotification(event){
		event.preventDefault();
		this.props.closeNotification(this.props.notificationStateName);
		console.log("close");
	}
	render() {
    	return (
    		<div className={`notification_wrapper notification_wrapper_${this.props.type}`}>
				<div className="header_notification">
					<h2>{this.generateTitle()}</h2>
					<form onSubmit={(event) => this.closeNotification(event)} >
						<input className="image_button" type="image" src="/icons/remove.svg" />
					</form>
				</div>
				<p>{this.props.description}</p>
			</div>
      	);
    }

};

export default Notification;