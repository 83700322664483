import React, { Component } from 'react';
import { query as institutionInfo } from '../../../queries/getInstitution';
/*import { query as accountInfo } from '../../queries/getAccountData';*/
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import intl from 'react-intl-universal';
import NominationData from './Data';
import GlobalVar from '../../../GlobalVar';
import { query as getManagedByEWP } from '../../../queries/getManagedByEWP';

class NominationList extends Component {

    constructor(props) {
        super(props)
        this.typeOfStudent = -1;
        this.typeOfStudentName = "";
        this.dashboardUserEmail = GlobalVar.decryptWithKey(localStorage.getItem("Email"));

        this.setStudentsType(props)
    }

    setStudentsType(props){
        const url = props.location.pathname;
        let outgoingStudent = intl.get("Outgoing students");
        outgoingStudent = outgoingStudent.replace(/\s/g, "-");
        outgoingStudent = outgoingStudent.toLowerCase();
  
        if(url.includes(outgoingStudent)){
            this.typeOfStudent = 1;
            this.typeOfStudentName = "Outgoing";
        }
        else{
            let incomingStudent = intl.get("Incoming students");
            incomingStudent = incomingStudent.replace(/\s/g, "-");
            incomingStudent = incomingStudent.toLowerCase();
            if(url.includes(incomingStudent)){
                this.typeOfStudent = 2;
                this.typeOfStudentName = "Incoming";
            }
        }
    }

    render() {
        if (!this.props.managedEWP.managedByEWP) {

            if (this.props.institutionInfo.loading) {
            return <p>Loading...</p>
        }
        return (
            <NominationData />
                    
        )
        } else {
            return (
              <div className={`content nomNoAccess`}>
                  <div className="card card_full_height card_full_width">
                      <div className="no_access nAccess">
                          <h2>Access is denied</h2>
                          <p>The Inter-institutional Agreements data is managed by another party in the EWP Network.</p>
                      </div>
                  </div>
              </div>
            );
        }
    }   
}

export default compose(
  graphql(institutionInfo, {name: "institutionInfo"}),
  graphql(getManagedByEWP, {
      name: "managedEWP",
      options: (props) => ({
          variables: {
              erasmusCode: localStorage.getItem("ErasmusCode")
          }
      })
  })
)(NominationList);