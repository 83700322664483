import React, { Component } from 'react';

class CheckBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false
		}
	}
	checkBoxAction(event, value){
		this.setState({
			checked: event.target.checked
		})
		if(event.target.checked){
			let columnValues = [];
			if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
				columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
			}
			if(!columnValues.includes(value)){
				columnValues.push(value);
				localStorage.setItem("columnValues"+this.props.typeOfStudentName, JSON.stringify(columnValues));
			}
		}
		else{
			if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
				let columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
				if(columnValues.includes(value)){
					columnValues = columnValues.filter(item => item != value)
					localStorage.setItem("columnValues"+this.props.typeOfStudentName, JSON.stringify(columnValues));
				}
			}
		}
		this.props.filterIsApplied();
	}

	componentWillMount() {
		this.setChecker(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.setChecker(nextProps);
	}

	setChecker(props){
		if(localStorage.getItem("columnValues"+this.props.typeOfStudentName) != null){
			let columnValues = JSON.parse(localStorage.getItem("columnValues"+this.props.typeOfStudentName));
			if(columnValues.includes(props.uniqueName)){
				this.setState({
					checked: true
				})
			}
		}
		else{
			this.setState({
				checked: false
			})
		}
	}

	renderDivision(){
		if(this.props.division){
			return "division_li"
		}
	}

	render() {
		if(this.props.InstitutionStatus != undefined){
			if(this.props.typeOfStudentName != this.props.InstitutionStatus){
				return <div></div>;
			}
		}

    	return (
    		<li className={this.renderDivision()}><label htmlFor={this.props.uniqueName} >{this.props.title}</label><input checked={this.state.checked} onChange={(event) => this.checkBoxAction(event, this.props.uniqueName)} id={this.props.uniqueName} name={this.props.uniqueName} type="checkbox" /></li>
      	);
    }

};

export default CheckBox;