import React, { Component } from 'react';
import Select from 'react-select';
import { graphql, useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../queries/getOutgoingNomination';
// import { query as getNomination} from '../../../queries/getOutgoingNomination';
import Confirm from './Confirm';
import IIAInfo from './IIAInfo';
import {query as getCoopConditions} from '../../../queries/getCoopConditions';

class OutgoingDestination extends Component {
	constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      idValue: props.nominations.id
  }
}
  //comment in remove function
  activateDestination(event){
		//event.preventDefault();
		this.props.ActivateDestination({
			variables:{
				"id": this.props.nominations.id
			}
		})
		setTimeout(function () {
		window.location.reload();
		}, 1000);
	}

  //comment in remove function
  rejectDestination(event){
		//event.preventDefault();
		this.props.RejectDestination({
			variables:{
				"id": this.props.nominations.id
			}
		})
	}


	render() {
			// style for span
		const spanStyle = {
			display: 'inline-block',
			marginBottom: '5px'
		}
		const flexStyleCenter = {
			display: 'flex',
			justifyContent: 'center'
		}
		const flexStyleRight = {
			display: 'flex',
			justifyContent: 'right'
		}
		const WaitingListButton = {
			backgroundColor: '#4CAF50',
			color: '#4CAF50',
			border: '#4CAF50'
		}
	  const canBeAccepted = this.props.nominations.canBeAccepted;
	  const isDestinationStatus = this.props.nominations.field_mobility_details_status;
		const isNominationStatus = this.props.status;

	return(
		<div key={this.props.nominations.id} className="card card_full_width card_full_height">
			<div className="custom_table">
			{ (this.props.coopConditions.loading) ? (
				<div className="row" id="" style={flexStyleCenter}><h4>Loading ...</h4></div>
			) : (
									(this.props.coopConditions.nominatedStudents && this.props.coopConditions.applicationCoopConditions.totalMonths && this.props.coopConditions.applicationCoopConditions.mobilitiesPerYear) ?
									<div>	<div className="row" id="" style={flexStyleRight}><h4>{this.props.coopConditions.applicationCoopConditions.mobilitiesPerYear} Available spots</h4></div>
										<div className="row" id="" style={flexStyleRight}><h4>{this.props.coopConditions.applicationCoopConditions.totalMonths} Available months</h4></div>
										<div className="row" id="" style={flexStyleRight}><h4>{this.props.coopConditions.nominatedStudents.nominatedStudentsCount} Students already nominated</h4></div></div>
										:
										// case where query has returned no cooperation conditions data
										(<div className="row" id="" style={flexStyleRight}><h4>No cooperation conditions data</h4></div>)
			)}
				<div className="row" style={flexStyleCenter}>
					<div className="column" id="" style={flexStyleCenter}><h2>{this.props.nominations.field_host_name}</h2></div>
				</div>
				<div className="row" style={flexStyleCenter}>
					<div className="column" id="" style={flexStyleCenter}><h4 style={{ color: '#000000' }}>{this.props.nominations.field_host_org_unit}</h4></div>
				</div>
			{ (this.props.coopConditions.loading) ? (
				<div className="row" id="" style={flexStyleCenter}><h4>Loading ...</h4></div>
			) : (
				(!this.props.coopConditions.applicationCoopConditions) ? (
					<div><div className="row">
						<IIAInfo erasmusHost={this.props.nominations.field_host_erasmus_code}/>
						</div>
					</div>
					) : (
					<div><div className="row">
						<IIAInfo erasmusHost={this.props.nominations.field_host_erasmus_code} iiaLink={this.props.coopConditions.applicationCoopConditions.iia}/>
						</div>
					</div>
					)
			)}
				<div className="row" style={flexStyleCenter}>
					<div className="column" id="" style={flexStyleCenter}>
						{ canBeAccepted ? (
						<Confirm title="Confirm" description="Are you sure you want to accept this? The change will be permanent.">
						{confirm => (
						<form onSubmit={confirm((event) => this.activateDestination(event))}>
							<button className="acceptNomination_button">Accept</button>
						</form>
						)}
						</Confirm>
							) : (
						<form onSubmit={(event) => this.activateDestination(event)}>
							<button className="acceptNomi_button" style={{backgroundColor: '#C5C5C5', color: '#808080', border: '#808080'}} disabled>Accept</button>
						</form>
						)}
					</div>
				</div>
				{(isDestinationStatus === 'active' && isNominationStatus === 'accepted_by_sending') ? (
				<div>				
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been accepted by sending HEI.</a></div>
				</div>
					) :
				(isDestinationStatus === 'active' && isNominationStatus === 'accepted_by_student')  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Application has been accepted by student.</a></div>
				</div>
					) :
				(isDestinationStatus === 'active' && isNominationStatus === 'accepted_by_receiving')  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been accepted by receiving HEI.</a></div>
				</div>
					) :
					((isDestinationStatus === 'rejected' && isNominationStatus === 'rejected_by_receiving') || (isDestinationStatus === 'rejected' && isNominationStatus === 'submitted'))  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been recently rejected by receiving HEI.</a></div>
				</div>
					) :
				(isDestinationStatus === 'old_rejected' && isNominationStatus === 'rejected_by_sending')  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been rejected by sending HEI.</a></div>
				</div>
					) :
				(isNominationStatus === 'cancelled')  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been cancelled by the student.</a></div>
				</div>
					) :
				(isNominationStatus === 'rejected_by_student')  ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Application has been rejected by student.</a></div>
				</div>
					) :
					((isDestinationStatus === 'old_rejected' && isNominationStatus === 'submitted') || (isDestinationStatus === 'old_rejected' && isNominationStatus === 'waiting_list') || (isDestinationStatus === 'old_rejected' && isNominationStatus === 'accepted_by_sending')|| (isDestinationStatus === 'old_rejected' && isNominationStatus === 'accepted_by_student')|| (isDestinationStatus === 'old_rejected' && isNominationStatus === 'accepted_by_receiving')|| (isDestinationStatus === 'old_rejected' && isNominationStatus === 'rejected_by_receiving')) ? (
				<div>
					<div className="column" id="" style={flexStyleCenter}><a>Student application has been rejected by receiving HEI.</a></div>
				</div>
					) : (<div> </div>)}
			</div>
		</div>
	)
  }
}

const activateDestinationMutation = gql`
    mutation ActivateDestination($id: String){
			activateDestination(id: $id)
    }
`;

export default compose(
    graphql(activateDestinationMutation,{ name: 'ActivateDestination'}),
	graphql(getCoopConditions, {
		name: "coopConditions",
		options: (props) => ({
			variables: {
				homeErasmusCode: props.nominations.field_home_erasmus_code,
				hostErasmusCode: props.nominations.field_host_erasmus_code,
				academicYear:"2022/2023",
				coopConditionsID: props.nominations.field_coop_condition_id
			}
		})
	})
)(OutgoingDestination);
