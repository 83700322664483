import React, { Component } from 'react';
import Select from 'react-select';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { query } from '../../../queries/getAccountData';
import Loader from 'react-loader-spinner';

class AccountRow extends Component {

	constructor(props) {
    super(props);
    this.state = {
    	editMode: false,
    	nameValue: props.account.Name,
    	emailValue: props.account.Email,
			phoneValue: props.account.Phone,
			defaultContact: "",
  		functionValue: "",
			errorTitle: "",
			error: false,
			showDeletePopup: false,
			processingDelete: false,
		}

		this.escKeyFunction = this.escKeyFunction.bind(this);
	}

	escKeyFunction(e){
	  if(e.keyCode === 27) {
	    this.setState({
	      showDeletePopup: false
	    });
	  }
	}

	componentDidMount(){
    document.addEventListener("keydown", this.escKeyFunction, false);
  }

	handleDeletePopup(e) {
		e.preventDefault();
  	if(!this.state.showDeletePopup){
      this.setState({
        showDeletePopup: true
      });
    } else {
      this.setState({
        showDeletePopup: false
      });
    }
	}
  	
	removeAccount(event){
		event.preventDefault();
		this.props.RemoveAccount({
			variables:{
				"id": this.props.account.id,
				"Email": this.props.account.Email
			},
    	refetchQueries: [{query}]
		}).then((response) => {
        setTimeout(function() {
          this.setState({
            processingDelete: false
          });
          this.setState({
            showDeletePopup: false
          });
      }.bind(this), 300);
    }).catch(error => {
        this.setState({
          showDeletePopup: false
        });
        alert("Error on deleting the account");
        window.location.reload(false);
    });
	}
  	
	changeValue(event, stateName){
  	this.setState({
  		[stateName]: event.target.value
  	})
	}
  	
	changeSingleSelect(functionValue){
  	this.setState({ functionValue });
	}
	
	editAccount(event){
		event.preventDefault();
		this.setState({
			editMode: true
		})
  	
  	this.setState({
			functionValue : ({
				value: this.props.account.AccountFunction.id, 
				label: this.props.account.AccountFunction.Name
			})
		})
	}
	
	validate(){
		if(!this.state.phoneValue.match(/^(?=.*[0-9])[- +()0-9]+$/)) {
				this.setState({
				error : true,
				errorTitle: "Wrong phone format"
			})
			return false;
		}
		if(this.state.defaultContact.value == "in" && 
			document.getElementsByClassName('defaultContact in').length > 0) {
			this.setState({
				errorTitle: "There's already an incoming default contact defined",
				error : true
			})
			return false;
		}
		if(this.state.defaultContact.value == "out" && 
			document.getElementsByClassName('defaultContact out').length > 0) {
			this.setState({
				errorTitle: "There's already an outoing default contact defined",
				error : true
			})
			return false;
		}
		this.setState({
			errorTitle: "",
			error : false
		})
  	return true;
	}
  	
	saveEditAccount(event){
		if(this.validate()){
    	this.props.UpdateAccount({
				variables:{
					"id": this.props.account.id,
					"Name": this.state.nameValue,
					"Email": this.state.emailValue,
					"FunctionID": this.state.functionValue.value,
					"Phone": this.state.phoneValue,
					"DefaultContact": this.state.defaultContact ? this.state.defaultContact.value : ""
				},
	    		refetchQueries: [{query}]
			})
			this.setState({
  			editMode: false
  		})
		}
	}
  
	renderSaveButton(){
  	if(this.state.functionValue === ""  || this.state.emailValue === "" || this.state.nameValue === ""){
  		return (
				<fieldset disabled>
					<button className="top_fix">Save</button>
				</fieldset>
			);
  	}
  	else return	<button  onClick = {(event) => this.saveEditAccount(event)} className="top_fix">Save</button>
	}

	generateError(){
  	if(this.state.error){
  		return <p className = "error">{this.state.errorTitle}</p>
		}
	}

	render() {
		if(this.props.account.AccountFunction === null) {
			this.props.RemoveAccount({
				variables:{
					"id": this.props.account.id
				},
    		refetchQueries: [{query}]
			})
			
			return(
				<tr key={this.props.account.id} >
					<td></td>
				</tr>
    	)
		} else {
			let functions = [];
  		this.props.accountFunctions.map(({Name, id}) => {
    		functions.push({value: id, label: Name})
			})
			
			let options = [];
			options.push({value: "", label: "None"});
  		options.push({value: "in", label: "Incoming"});
  		options.push({value: "out", label: "Outgoing"});
			
			if(this.state.editMode){
				return(
					<tr>
						<td>
							<input style={{width:'95px',height:'22px'}} id="name_input" type="text" value={this.state.nameValue} onChange={(event) => this.changeValue(event, "nameValue")} placeholder="Name" className="top_fix" />
								{this.generateError()}
						</td>
						<td>
							{this.state.emailValue}
						</td>
						<td>
							<input style={{width:'95px',height:'22px'}} id="number_input" type="text" value={this.state.phoneValue} onChange={(event) => this.changeValue(event, "phoneValue")} placeholder="Phone number" className="top_fix" />
						</td>
						<td>
							<Select style={{width:'165px',height:'20px'}} placeholder="function" value={this.state.functionValue} onChange={(value) => this.changeSingleSelect(value)} name="form-field-name" options={functions} className="single_select" />
						</td>
						<td>
							{this.renderSaveButton()}
						</td>
					</tr>
    		)
	    } else {
				return(
					<tr key={this.props.account.id} >
						<td>{this.props.account.Name}</td>
						<td><a href={`mailto:${this.props.account.Email}`}>{this.props.account.Email}</a></td>
						<td>{this.props.account.Phone}</td>
						<td>{this.props.account.AccountFunction.Name}</td>
						<td>
							<form onSubmit={(e) => this.handleDeletePopup(e)} className="text_button_wrapper">
								<button>Remove</button>
							</form>
						</td>
				    <td>
							<form onSubmit={(event) => this.editAccount(event)} className="text_button_wrapper">
								<button>Edit</button>
							</form>
						</td>
						{this.state.showDeletePopup ? 
							(<div>
				          <div onClick={(e) => this.handleDeletePopup(e)} className="black_background"></div>
				          <div className={`content popup single_popup delete_popup`}>
					          {this.state.processingDelete ? 
					          	(<div className="white_background">
						            <Loader
						              className="spinner"
						              type="TailSpin"
						              color="#3986df"
						              height={50}
						              width={50} />
						            </div>) : null}
					        	<div className="sub_content">
					            <h3>Are you sure you want to remove this account?</h3>
					          </div>
					          <div>
					            <button className="confirm" onClick={(e) => this.removeAccount(e)}>Yes</button>
					            <button className="cancel" onClick={(e) => this.handleDeletePopup(e)}>No</button>
					          </div>
				          </div>
				      	</div>
			      	) : null
			    	}
					</tr>
		  	)
			}
		}
	};
}

const mutation = gql`
  mutation RemoveAccount($id: ID!, $Email: String){
    removeAccount(id: $id, Email: $Email){
  		Name
    }
  }
`;
const updateAccountMutation = gql`
  mutation UpdateAccount($id: ID! , $Name: String!, $Email: String!, $FunctionID: ID!, $Phone: String!, $DefaultContact: String!){
    updateAccount(id: $id , Name: $Name, Email: $Email, FunctionID: $FunctionID, Phone: $Phone, DefaultContact: $DefaultContact){
			Name
    }
  }
`
export default compose(
    graphql(mutation,{ name: 'RemoveAccount'}),
    graphql(updateAccountMutation,{ name: 'UpdateAccount'})
)(AccountRow);
