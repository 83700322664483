import React, { Component } from 'react';

class IIANominationInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // style for span
    const spanStyle = {
      display: 'inline-block',
      marginBottom: '5px'
    }
    const flexStyleCenter = {
      display: 'flex',
      justifyContent: 'center'
    }

    const WaitingListButton = {
      backgroundColor: '#4CAF50',
      color: '#4CAF50',
      border: '#4CAF50'
    }
    const isRequired = this.props.iiaNominations.isRequired;
    const descriptionOther = this.props.iiaNominations.description;

    return(
	<div key={this.props.iiaNominations.uuid} >
		<div className="row" style={{ color: '#000000' }}>
		  <div className="column" style={{padding: '3px 0'}}>
			<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>Other requirements: </span>
		  </div>
		  <div className="column"
			   style={{ padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
				display: 'inline-block',
				marginBottom: '5px'
			  }}>{descriptionOther}</span>
		  </div>
		</div>
		<div className="row" style={{ color: '#000000' }}>
		  <div className="column" style={{padding: '3px 0'}}>
			<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}></span>
		  </div>
		  {isRequired ? (
			<div className="column"
				 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
				display: 'inline-block',
				marginBottom: '5px'
			  }}>Required</span>
			</div>) :(
			<div className="column"
				 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
				display: 'inline-block',
				marginBottom: '5px'
			  }}>Not Required</span>
			</div>
		  )}
		</div>
     </div>  
    )
  }
}

export default IIANominationInfo;
