import React, { Component, useState, useEffect } from 'react'
import {gql, graphql, useQuery} from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Accordion from './Accordion';
import Text from "./text";
import Heading from "./heading";
import IIAOther from './IIAOther'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styleIcon.css";
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import GlobalVar from '../../../GlobalVar';
import {query as getManagedByEWP} from "../../../queries/getManagedByEWP";
import {query as getIIA} from "../../../queries/getIIA";

function IIAInfo (props) {

	const GET_IIA_INFO = gql`
		query ($erasmusCode: String){
			incomingApplicationRequirements(erasmusCode:$erasmusCode){
				otherRequirementArray{
					url
					uuid
					description
					isRequired
				}
				cvRequired
				cvURL
				cvDescription
				motivLetterRequired
				motivLetterURL
				motivLetterDescription
				ectsRequired
				ectsURL
				isced
				iscedURL
				eqfLevel
				eqfLevelURL
			}
		}
	`;

  const { loading, error, data } = useQuery(GET_IIA_INFO, {
    variables: { erasmusCode: props.erasmusHost },
  });
  if (loading) return <p>Loading ...</p>;
  
const Accord = Accordion(1);
const Icon = <FontAwesomeIcon icon={faChevronDown} color={"#4a90e2"} />;
// console.log(props.iiaInfo);
// 	setTimeout(() => console.log(props.iiaInfo), 30000);
  return (
		(data.incomingApplicationRequirements) ? (
  <div className="app">
    <Accord>
	      <Heading render={icon => <div className={`icon ${icon && "open"}`}><FontAwesomeIcon icon={faChevronDown} color={"#4a90e2"}/></div>}>
        <div className="heading-box" style={{display: "flex", justifyContent: "space-between", padding: "0.8rem", alignItems: "center", width: "100%", fontSize: "1.1rem", cursor: "pointer"}}>
          <h3 className="heading" style={{display: 'inline-block', marginBottom: '5px'}} >IIA - Requirements</h3>
        </div>
				</Heading>

        <Text>
					<div className="row" style={{ color: '#000000' }}>
						<div className="column" style={{padding: '3px 0'}}>
								<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>ECTS Requirement: </span>
						</div>
						<div className="column" style={{width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular'}}>
								<span className="value" style={{display: 'inline-block', marginBottom: '5px'}}>{data.incomingApplicationRequirements.ectsRequired}</span>
						</div>
					</div>
					<div className="row" style={{ color: '#000000' }}>
						<div className="column" style={{padding: '3px 0'}}>
								<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>ISCED: </span>
						</div>
						<div className="column" style={{width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular'}}>
							<span className="value" style={{display: 'inline-block', marginBottom: '5px'}}>{data.incomingApplicationRequirements.isced}</span>
						</div>
					</div>
					<div className="row" style={{ color: '#000000' }}>
						<div className="column" style={{padding: '3px 0'}}>
							<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>EQF Level: </span>
						</div>
						<div className="column" style={{width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular'}}>
							<span className="value" style={{display: 'inline-block', marginBottom: '5px'}}>{data.incomingApplicationRequirements.eqfLevel}</span>
						</div>
					</div>
					<div className="row" style={{ color: '#000000' }}>
						<div className="column" style={{padding: '3px 0'}}>
							<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>CV: </span>
						</div>
						{data.incomingApplicationRequirements.cvRequired ? (
						<div className="column"
								 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
								display: 'inline-block',
								marginBottom: '5px'
							}}>Required</span>
						</div>) :(
							<div className="column"
									 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
								display: 'inline-block',
								marginBottom: '5px'
							}}>Not Required</span>
							</div>
						)}
					</div>
					<div className="row" style={{ color: '#000000' }}>
						<div className="column" style={{padding: '3px 0'}}>
							<span className="bold" style={{display: 'inline-block', marginBottom: '5px'}}>Motivation letter: </span>
						</div>
						{data.incomingApplicationRequirements.motivLetterRequired ? (
							<div className="column"
									 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
								display: 'inline-block',
								marginBottom: '5px'
							}}>Required</span>
							</div>) :(
							<div className="column"
									 style={{ width: '150px', padding: '3px 0', fontFamily: 'ubuntuRegular' }}>
							<span className="value" style={{
								display: 'inline-block',
								marginBottom: '5px'
							}}>Not Required</span>
							</div>
						)}
					</div>
					{(data.incomingApplicationRequirements.otherRequirementArray) &&
					<IIAOther data={data.incomingApplicationRequirements.otherRequirementArray} />
					}
					{props.iiaLink && (
						(props.iiaInfo.loading) ? (
						<div className="row" style={{ color: '#000000' }}>
							<Link >
								<button> Loading ...</button>
							</Link>
						</div>
						) : (
							(props.iiaInfo.getIIA) && (
					<div className="row" style={{ color: '#000000' }}>
						<Link to={{ pathname: GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/details/" + props.iiaInfo.getIIA.iia_code_p1, state:{ data: props.iiaInfo.getIIA } }}>
							<button> Details of Inter-Institutional Agreement </button>
						</Link>
					</div>)
						)
					)}
				</Text>
    </Accord>
  </div>
		) : (
			<div className="no_access">
				<p style={{color:'#4a90e2', fontWeight:'bold'}}>No IIA data provided for this destination.</p>
			</div>
		)
);
}

export default compose (
	graphql(getIIA, {
		name: "iiaInfo",
		options: (props) => ({
			variables: {
				iiaCodeIn: props.iiaLink
			}
		})
	}),
)(IIAInfo);