import React, { Component } from 'react';
import { query } from '../../../../queries/getStudentAcademicYear';
import { graphql } from 'react-apollo';

class AcademicYear extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		}
	}

	changeSelection(event){
		this.setState({
			value: event.target.value
		})
		let filters = {};

		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null){
			filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
		}
		filters["academicYear"] = event.target.value;
		localStorage.setItem("filters"+this.props.typeOfStudentName, JSON.stringify(filters));
		this.props.filterIsApplied();
	}
	renderAcademicYear(){
		return this.props.data.getStudentAcademicYear.map(({academicYear, count}, index) => {
			let extractedName = academicYear;
			if(extractedName == ""){
				extractedName = "Not found";
			}
			return <option key={index} value={extractedName} >{extractedName} ({count})</option>;
		})
	}

	componentWillMount() {
		this.setSelection();
	}

	componentWillReceiveProps(nextProps) {
		this.setSelection();
	}

	setSelection(){
		if(localStorage.getItem("filters"+this.props.typeOfStudentName) != null && localStorage.getItem("filters"+this.props.typeOfStudentName) !== '{"signedBefore":"-1","signedDuring":"-1"}'){
			const filters = JSON.parse(localStorage.getItem("filters"+this.props.typeOfStudentName));
			this.setState({
				value: filters["academicYear"]
			})
		}
		else{
			this.setState({
				value: ""
			})
		}
	}
	render() {
		if(this.props.data.loading){
			return <select><option value="">Loading...</option></select>
		}
		else{
      return (
        <select value={this.state.value} onChange={(event) => this.changeSelection(event)} >
          <option value={""}>Not selected</option>
					{this.renderAcademicYear()}
        </select>
      )
  	}
  }
};

//export default AcademicYear;

export default graphql(query, {
	options: (props) => {return {
		variables: {
			Sending: props.typeOfStudents,
			CountryName: props.CountryID,
			InstitutionName: props.InstitutionID,
			SearchQuery: props.SearchQuery,
			StartMobility: props.StartMobility,
			EndMobility: props.EndMobility,
			Uploaded: props.Uploaded,
			StudyCycle: props.StudyCycle,
			SubjectArea: props.SubjectArea,
			TypeOfSigning: props.typeOfSigning,
			SignedBefore:  Number(props.SignedBefore),
			SignedDuring:  Number(props.SignedDuring)
		}
	}}
})(AcademicYear);
