import React, { Component, Fragment } from 'react';
import GlobalVar from '../../../GlobalVar';
import Loader from 'react-loader-spinner';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import FinalTableRow from './FinalTableRow';

class FinalTable extends Component {

    constructor(props) {
        super(props);
    }

    automaticRecognitionCheck = (dataFromRow) => {
        this.props.parentCallback(dataFromRow);
    }

    render(){
        let tableType=this.props.tableType
        let classNameTable = "table_without_changes";
        const isOutgoing = this.props.isOutgoing != null ? this.props.isOutgoing : false;

        if(tableType === 1){
            let final_table_a2 = this.props.data.data.learningAgreement.final_table_a2;
            if(final_table_a2 !== undefined){

                if(final_table_a2.length === 0){
                    return <p className="italic">No courses to show</p>;
                }

                //remove next lines due to bug: LA's Table components are not displayed in Dashboard if the component's los-code doesn't exist
                // if(final_table_a2.length === 1 &&
                //     final_table_a2[0].attributes.field_final_table_a2_code == null){
                //     return <p className="italic">No courses to show</p>;
                // }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="ects">ECTS</th>
                                <th className="semester">Semester</th>
                                <th className="status">Status</th>
                                <th className="reason">Reason</th>
                                <th className="reason_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderFinalTableRows(tableType)}
                            {this.calculateFinalECTStotal(final_table_a2, 1)}
                        </tbody>
                    </table>
                )
            }
        } else if(tableType === 2) {
            let final_table_b2 = this.props.data.data.learningAgreement.final_table_b2;
            if(final_table_b2 !== undefined){

                if(final_table_b2.length === 0){
                    return <p className="italic">No courses to show</p>;
                }

                //remove next lines due to bug: LA's Table components are not displayed in Dashboard if the component's los-code doesn't exist
                // if(final_table_b2.length === 1 &&
                //     final_table_b2[0].attributes.field_final_table_b2_code == null){
                //     return <p className="italic">No courses to show</p>;
                // }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="ects">ECTS</th>
                                <th className="semester">Semester</th>
                                <th className="status">Status</th>
                                <th className="reason">Reason</th>
                                <th className="reason_description">Description</th>
                                <th className="automatic_recognition">Automatic Recognition</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderFinalTableRows(tableType)}
                            {this.calculateFinalECTStotal(final_table_b2, 2)}
                        </tbody>
                    </table>
                )
            }
        } else {
            let final_table_c2 = this.props.data.data.learningAgreement.final_table_c2;
            if(final_table_c2 !== undefined){

                if(final_table_c2.length === 0){
                    return <p className="italic">No courses to show</p>;
                }

                //remove next lines due to bug: LA's Table components are not displayed in Dashboard if the component's los-code doesn't exist
                // if(final_table_c2.length === 1 &&
                //     final_table_c2[0].attributes.field_final_table_c2_code == null){
                //     return <p className="italic">No courses to show</p>;
                // }
                return(
                    <table className={classNameTable}>
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="title">Subject title</th>
                                <th className="ects">ECTS</th>
                                <th className="status">Status</th>
                                <th className="reason">Reason</th>
                                <th className="reason_description">Description</th>
                                <th className="automatic_recognition">Automatic Recognition</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderFinalTableRows(tableType)}
                            {this.calculateFinalECTStotal(final_table_c2, 3)}
                        </tbody>
                    </table>
                )
            }
        }
    }

    renderFinalTableRows(tableType){
        let reasonIndex = 0;
        let semesterMap = this.props.data.data.learningAgreement.semester_map;
        let semesterName = "";
        const isOutgoing = this.props.isOutgoing != null ? this.props.isOutgoing : false;

        if(tableType === 1){

            return this.props.data.data.learningAgreement.final_table_a2.map((courseData, index) => {
                let semesterId = courseData.semester.data != undefined ? courseData.semester.data.id : 0;

                semesterMap.forEach((element, i) => {
                    if(semesterId === element.id){
                        semesterName = element.name;
                    }
                });

                return(
                    <FinalTableRow 
                        courseData={courseData}
                        accounts={this.props.accounts}
                        status={this.props.status}
                        key={index} 
                        tableType={1} 
                        semesterName={semesterName} 
                        isOutgoing={isOutgoing} />
                )
            })
        } else if(tableType === 2) {
            return this.props.data.data.learningAgreement.final_table_b2.map((courseData, index) => {
                let semesterId = courseData.semester.data != undefined ? courseData.semester.data.id : 0;
                let semesterName = "";

                semesterMap.forEach((element, i) => {
                    if(semesterId === element.id){
                        semesterName = element.name;
                    }
                });

                return(
                    <FinalTableRow 
                        parentCallback={this.automaticRecognitionCheck} 
                        courseData={courseData}
                        accounts={this.props.accounts}
                        status={this.props.status}
                        key={index} 
                        tableType={2} 
                        semesterName={semesterName} 
                        isOutgoing={isOutgoing} />
                )
            });
        } else {
            return this.props.data.data.learningAgreement.final_table_c2.map((courseData, index) => {
                let semesterName = "";

                return(
                    <FinalTableRow 
                        parentCallback={this.automaticRecognitionCheck} 
                        courseData={courseData}
                        accounts={this.props.accounts}
                        status={this.props.status}
                        key={index} 
                        tableType={3} 
                        isOutgoing={isOutgoing} />
                )
            });
        }
    }

    calculateFinalECTStotal(data, tableType){
        let ectsRemoved = 0;
        let ectsAdded = 0;
        data.forEach((courseData) => {
            if(tableType === 1) {
                if(courseData.attributes.field_final_table_a2_ects) {
                    if(courseData.attributes.field_component_added_or_deleted === "1"){
                        const ects = courseData.attributes.field_final_table_a2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_a2_ects != null && !isNaN(ects)) {
                            ectsAdded += parseFloat(ects, 10);
                        } else {
                            ectsAdded += 0;
                        }
                } else {
                        const ects = courseData.attributes.field_final_table_a2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_a2_ects != null && !isNaN(ects)) {
                            ectsRemoved += parseFloat(ects, 10);
                        } else {
                            ectsRemoved += 0;
                        }
                    }
                }
            } else if(tableType === 2) {
                if(courseData.attributes.field_final_table_b2_ects) {
                    if(courseData.attributes.field_component_added_or_deleted === "1"){
                        const ects = courseData.attributes.field_final_table_b2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_b2_ects != null && !isNaN(ects)) {
                            ectsAdded += parseFloat(ects, 10);
                        } else {
                            ectsAdded += 0;
                        }

                } else {
                        const ects = courseData.attributes.field_final_table_b2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_b2_ects != null && !isNaN(ects)) {
                            ectsRemoved += parseFloat(ects, 10);
                        } else {
                            ectsRemoved += 0;
                        }
                    }
                }
            } else {
                if(courseData.attributes.field_final_table_c2_ects) {
                    if(courseData.attributes.field_final_table_c2_added_or_de === "1") {
                        const ects = courseData.attributes.field_final_table_c2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_c2_ects != null && !isNaN(ects)) {
                            ectsAdded += parseFloat(ects, 10);
                        } else {
                            ectsAdded += 0;
                        }
                } else {
                        const ects = courseData.attributes.field_final_table_c2_ects.replace(',', '.').replace(/\s+/g, '');
                        if(courseData.attributes.field_final_table_c2_ects != null && !isNaN(ects)) {
                            ectsRemoved += parseFloat(ects, 10);
                        } else {
                            ectsRemoved += 0;
                        }
                    }
                }
            }
        });

        let ectsDifference = ectsAdded - ectsRemoved;
        return(
            <tr>
                <td></td>
                <td className="italic">Total ECTS credits</td>
                <td className="italic">
                    <span className="succes">{ectsAdded}</span>
                    <span className="error"> - {ectsRemoved} </span>
                    = {ectsDifference}
                </td>
            </tr>
        )
    }
}

export default FinalTable;