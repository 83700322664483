import React, { Component } from 'react';
import { compose,graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { query } from '../../../queries/getFrequentlyAskedQuestions';

class Faqs extends Component {
	constructor(props) {
		super(props);
	}
	gotoPreviousPage(){
        this.props.history.goBack();
	}
    
	generateQuestions(){
		return this.props.data.faq.map((faq) => {
		    return( 
		    	<div  key={faq.id} className="inside_content">
                <br/>
		    	<h3>{faq.Question} </h3>
	            <div dangerouslySetInnerHTML={{__html:faq.Answer}}></div>
	            </div>
		    )        
		})
		
	}
	render() {
		console.log("test");
    	if(this.props.data.loading){	    
			return (
	    		<div className="card card_half_width card_fixed_height">
	    			<h2>Information</h2>
	    		</div>
	      	);
		}
		else {  	
			return( 
				<div className="institution_registration">
					<div onClick={() => this.gotoPreviousPage()} className="black_background"/>
					<div className="content popup terms">
					    <h2>Frequently asked questions</h2>
		    			<ol>
		    			    {this.generateQuestions()}
		    			</ol>    
					    <button className="button" onClick={() => this.gotoPreviousPage()}>close</button>
					</div>
				</div>
			)  
	    }		 
	}
};

export default graphql(query)(Faqs);
